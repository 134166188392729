import { render, staticRenderFns } from "./VvMultipleSwitchs.vue?vue&type=template&id=4b99b926&"
import script from "./VvMultipleSwitchs.vue?vue&type=script&lang=js&"
export * from "./VvMultipleSwitchs.vue?vue&type=script&lang=js&"
import style0 from "./VvMultipleSwitchs.vue?vue&type=style&index=0&id=4b99b926&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VSwitch,VTab,VTabItem,VTabsItems})

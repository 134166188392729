export default {
    name: 'inceptionInvestmentOrderDeleteModal',
    props: {
        epicList: {
            type: Array,
            default: () => [],
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onContinue() {
            this.$emit('onConfirm');
        },
        onCancel() {
            this.$emit('onClose');
        },
    }
}
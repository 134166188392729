<template>
  <v-dialog v-model="errorsModal.show" width="500" persistent>
    <v-card>
      <div class="title-modal-error">
        <h3>Atenção</h3>
        <p>Uma ou mais correções são necessárias</p>
        <v-btn icon @click="closeModalError()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <div class="status-step" :class="errorsModal.project ? 'error' : 'success'">
          <button>
            <template v-if="errorsModal.project"><v-icon color="#FF502D">close-circle-outline</v-icon></template>
            <template v-else><v-icon color="#699F10">check-circle-outline</v-icon></template>
            Cadastro
          </button>
          <span v-if="!errorsModal.project">Salvo com sucesso</span>
          <span v-else>Correção necessária</span>
        </div>
        <div class="status-step" :class="errorsModal.discovery.error > 0 ? 'error' : 'success'">
          <button>
            <template v-if="errorsModal.discovery.error > 0"><v-icon color="#FF502D">close-circle-outline</v-icon></template>
            <template v-else><v-icon color="#699F10">check-circle-outline</v-icon></template>
            Gestão de macro temas
          </button>
          <span v-if="errorsModal.discovery.error <= 0">Salvo com sucesso</span>
          <span v-else>Correção necessária</span>
          <div class="discovery-resume" v-if="errorsModal.discovery.error > 0">
            Dos {{ errorsModal.discovery.total }} macro temas alterados:
            <ul>
              <li>{{ errorsModal.discovery.success }} macro temas foram salvos com sucesso</li>
              <li>{{ errorsModal.discovery.error }} macro tema necessita de correção</li>
            </ul>
          </div>
        </div>
        <div class="status-step" :class="errorsModal.inception ? 'error' : 'success'">
          <button>
            <template v-if="errorsModal.inception"><v-icon color="#FF502D">close-circle-outline</v-icon></template>
            <template v-else><v-icon color="#699F10">check-circle-outline</v-icon></template>
            Inception
          </button>
          <span v-if="!errorsModal.inception">Salvo com sucesso</span>
          <span v-else>Correção necessária</span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalError',
  props: {
    errorsModal: {
      type: Object
    }
  },
  methods: {
    closeModalError() {
      this.$emit('closeModalError', true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-dialog {
    .title-modal-error {
      position: relative;
      padding: 24px 30px 36px;

      h3 {
        font-weight: 500;
        font-size: 18px;
        color: #5D5D5D;
        margin-bottom: 0px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: #929497;
        margin-bottom: 0px;
      }

      button {
        position: absolute;
        top: 24px;
        right: 30px;
      }
    }

    .v-card__text {
      padding-bottom: 40px;

      .status-step {
        padding: 28px 0px 17px 18.5px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-weight: 500;
        font-size: 16px;
        background-color: #ffffff !important;

        &.error {
          color: #FF502D;
          border-bottom: 1px solid #FF502D;
        }

        &.success {
          color: #699F10;
          border-bottom: 1px solid #699F10;
        }

        button {
          .v-icon {
            margin-right: 18.5px;
          }
        }

        span {
          font-style: italic;
          font-weight: 400;
          font-size: 12px;
          color: #929497;
        }

        .discovery-resume {
          width: 100%;
          color: #929497;
          font-weight: 400;
          font-size: 14px;
          padding-left: 23px;
          margin-top: 12px;

          ul {
            margin-top: 16px;

            li {
              padding: 6px 0px;

              &:first-child {
                padding-top: 0px;
              }
            }
          }
        }
      }
    }
  }
</style>
import ListApprovers from '@/domains/shared/ListApprovers';
import RefusedAlert from '@/domains/shared/RefusedAlert';
import Message from '@/domains/shared/Message';
import closuresServiceFactory from "../../services/closureService";

const closureService = closuresServiceFactory();

export default {
  name: 'ClosureDetails',
  components: {
    ListApprovers,
    RefusedAlert,
    Message
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    messageData: {}
  }),
  watch: {
    messageData(value) {
      this.sendMessage(value);
    }
  },
  methods: {
    async sendMessage(data) {
      try {
        this.$showLoading();
        const message = await closureService.sendMessage(this.$route.params.id, this.item.id, data);
        this.$showSuccess(message.data.message);
      } catch(error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    }
  }
}
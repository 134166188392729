<template>
  <div class="text-center">
    <v-dialog
        v-model="displayModal"
        width="80%"
    >
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          Inativação de Árvore
        </v-card-title>

        <v-card-text v-if="this.nivel > 0">
          <div
              v-if="models.old !== 0"
          >
            <v-row>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    label="Jornada Inativada"
                    :disabled="true"
                    v-model="models.journey.name"
                />
              </v-col>
              <v-col
                  cols="12"
                  md="6"
                  v-if="!isDisabledJorneyByNivel()"
              >
                <v-autocomplete
                    label="Nova Jornada"
                    v-model="models.journey.new"
                    :items="loadJourneysOptions()"
                    item-value="id"
                    item-text="name"
                />
              </v-col>
            </v-row>
            <div
                v-if="models.journey.new !== 0"
            >
              <div
                  v-for="(tribe, tribeIndex) in models.journey.tribes"
                  :key="tribeIndex"
              >
                <v-row>
                  <v-col cols="12" md="1"></v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                        label="Tribo Inativada"
                        :disabled="true"
                        v-model="tribe.name"
                    />
                  </v-col>
                  <v-col cols="12" md="1"></v-col>
                  <v-col
                      cols="12"
                      md="5"
                      v-if="!isDisabledTribeByNivel()"
                  >
                    <v-autocomplete
                        label="Nova Tribo"
                        v-model="tribe.new"
                        :items="loadTribesByJoyrney(models.journey.new)"
                        item-value="id"
                        item-text="name"
                    />
                  </v-col>
                </v-row>

                <div
                    v-if="tribe.new"
                >
                  <div
                      v-for="(squad, squadIndex) in tribe.squads"
                      :key="squadIndex"
                  >
                    <v-row>
                      <v-col cols="12" md="2"></v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                            label="Squad Inativada"
                            :disabled="true"
                            v-model="squad.name"
                        />
                      </v-col>
                      <v-col cols="12" md="2"></v-col>
                      <v-col cols="12" md="4">
                        <v-autocomplete
                            label="Nova Squad"
                            v-model="squad.new"
                            :items="loadSquadsByTribeJourney(tribe.new, models.journey.new)"
                            item-value="id"
                            item-text="name"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              @click="endComponent()"
          >
            Fechar
          </v-btn>

          <v-btn
              color="primary"
              @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'TreeTechDisabledModal',
  props: {
    data: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    show: function (newVal) {
      if (!newVal) {
        this.endComponent();
        this.$parent.closeInativateModal();
      }
      this.displayModal = newVal;
    },
    data: function (newVal) {
      this.form = this.deepCopy(newVal.form);
      this.entity = newVal.entity;
      this.nivel = newVal.nivel;
      this.options = newVal.options;
      this.load();
    },
    displayModal: function (newVal) {
      if (!newVal) {
        this.endComponent();
        this.$parent.closeInativateModal();
      }
    }
  },
  data() {
    return {
      displayModal: false,
      isLoading: false,
      form: {},
      entity: {},
      nivel: 0,
      options: {},
      models: {
        journey: {
          old: 0,
          name: 'NDA',
          new: 0,
          tribes: []
        },
        tribe: {
          old: 0,
          name: 'NDA',
          new: 0,
          squads: []
        },
        squad: {
          old: 0,
          name: 'NDA',
          new: 0
        },
        joyneyOptions: [],
      }
    }
  },
  mounted() {

  },
  methods: {
    load() {
      this.isLoading = true;
      switch (this.nivel) {
        case this.CONST_NIVEL_TREE_TECH_DISABLE.JORNEY:
          this.loadJorney();
          break;
        case this.CONST_NIVEL_TREE_TECH_DISABLE.TRIBE:
          this.loadTribe();
          break;
        case this.CONST_NIVEL_TREE_TECH_DISABLE.SQUAD:
          this.loadSquad();
          break;
        default:
          console.error('Sem Nivel');
      }
      this.isLoading = false;
    },
    loadJorney() {
      let journeyEntity = this.entity;

      this.models.journey.old = journeyEntity.id;
      this.models.journey.name = journeyEntity.name;

      for (let tribesIndex = 0; tribesIndex < journeyEntity.tribes.length; tribesIndex++) {
        let tribeEntity = this.deepCopy(this.models.tribe);
        tribeEntity.old = journeyEntity.tribes[tribesIndex].id;
        tribeEntity.name = journeyEntity.tribes[tribesIndex].name;
        for (let squadIndex = 0; squadIndex < journeyEntity.tribes[tribesIndex].squads.length; squadIndex++) {
          let squadEntity = this.deepCopy(this.models.squad);
          squadEntity.old = journeyEntity.tribes[tribesIndex].squads[squadIndex].id;
          squadEntity.name = this.getNameBySquadKey(journeyEntity.tribes[tribesIndex].squads[squadIndex].squad_key);
          tribeEntity.squads.push(squadEntity);
        }
        this.models.journey.tribes.push(tribeEntity);
      }
    },
    loadJourneysOptions() {
      this.isLoading = true;
      let journeyEntity = this.entity;
      for (let journeyIndex = 0; journeyIndex < this.form.journeys.length; journeyIndex++) {
        if (this.form.journeys[journeyIndex].id === journeyEntity.id || this.form.journeys[journeyIndex].active === false) {
          this.form.journeys.splice(journeyIndex, 1);
        }
      }

      this.isLoading = false;
      return this.form.journeys;
    },
    loadTribesByJoyrney(journeySelectedId) {
      this.isLoading = true;
      let tribes = [];
      for (let journeyIndex = 0; journeyIndex < this.form.journeys.length; journeyIndex++) {
        if (journeySelectedId === this.form.journeys[journeyIndex].id) {
          for (let tribeIndex = 0; tribeIndex < this.form.journeys[journeyIndex].tribes.length; tribeIndex++) {
            if (this.form.journeys[journeyIndex].tribes[tribeIndex].active === true) {
              tribes.push(this.form.journeys[journeyIndex].tribes[tribeIndex]);
            }
          }
        }
      }

      this.isLoading = false;
      return tribes;
    },
    loadSquadsByTribeJourney(tribeSelectedId, journeySelectedId) {
      let squadsCollection = [];
      let tribesCollection = this.loadTribesByJoyrney(journeySelectedId);
      for (let tribeIndex = 0; tribeIndex < tribesCollection.length; tribeIndex++) {
        if (tribesCollection[tribeIndex].id === tribeSelectedId) {
          for (let squadIndex = 0; squadIndex < tribesCollection[tribeIndex].squads.length; squadIndex++) {
            if (tribesCollection[tribeIndex].squads[squadIndex].active === true) {
              let squadEntity = {
                id: tribesCollection[tribeIndex].squads[squadIndex].id,
                name: this.getNameBySquadKey(tribesCollection[tribeIndex].squads[squadIndex].squad_key)
              }
              squadsCollection.push(squadEntity);
            }
          }
        }
      }
      return squadsCollection;
    },
    loadTribe() {
      let journeyEntity = this.getJourneyByTribeId(this.entity.id);

      this.models.journey.new = journeyEntity.id;
      this.models.journey.old = journeyEntity.id;
      this.models.journey.name = journeyEntity.name;

      let tribeEntity = this.deepCopy(this.models.tribe);
      tribeEntity.old = this.entity.id;
      tribeEntity.name = this.entity.name;

      for (let indexSquad = 0; indexSquad < this.entity.squads.length; indexSquad++) {
        let squadEntity = this.deepCopy(this.models.squad);
        squadEntity.old = this.entity.squads[indexSquad].id;
        squadEntity.name = this.getNameBySquadKey(this.entity.squads[indexSquad].squad_key);
        tribeEntity.squads.push(squadEntity);
      }

      this.models.journey.tribes.push(tribeEntity);
    },
    getJourneyByTribeId(tribeId) {
      for (let journeyIndex = 0; journeyIndex < this.form.journeys.length; journeyIndex++) {
        for (let tribeIndex = 0; tribeIndex < this.form.journeys[journeyIndex].tribes.length; tribeIndex++) {
          if (this.form.journeys[journeyIndex].tribes[tribeIndex].id === tribeId) {
            let journeyEntity = {
              id: this.form.journeys[journeyIndex].id,
              name: this.form.journeys[journeyIndex].name
            };
            return journeyEntity;
          }
        }
      }
      return {
        id: 1,
        name: 'Não Aplicável'
      };
    },
    loadSquad() {
      let journeyTribeEntity = this.getJourneyTribeBySquadId(this.entity.id);
      this.models.journey.new = journeyTribeEntity.journey.id;
      this.models.journey.old = journeyTribeEntity.journey.id;
      this.models.journey.name = journeyTribeEntity.journey.name;
      let tribeEntity = this.deepCopy(this.models.tribe);
      tribeEntity.new = journeyTribeEntity.tribe.id;
      tribeEntity.old = journeyTribeEntity.tribe.id;
      tribeEntity.name = journeyTribeEntity.tribe.name;
      let squadEntity = this.deepCopy(this.models.squad);
      squadEntity.old = this.entity.id;
      squadEntity.name = this.getNameBySquadKey(this.entity.squad_key);
      tribeEntity.squads.push(squadEntity);
      this.models.journey.tribes.push(tribeEntity);
    },
    getJourneyTribeBySquadId(squadId) {
      for (let journeyIndex = 0; journeyIndex < this.form.journeys.length; journeyIndex++) {
        for (let tribeIndex = 0; tribeIndex < this.form.journeys[journeyIndex].tribes.length; tribeIndex++) {
          for (let squadIndex = 0; squadIndex < this.form.journeys[journeyIndex].tribes[tribeIndex].squads.length; squadIndex++) {
            if (this.form.journeys[journeyIndex].tribes[tribeIndex].squads[squadIndex].id === squadId) {
              let entitys = {
                'journey': {
                  id: this.form.journeys[journeyIndex].id,
                  name: this.form.journeys[journeyIndex].name
                },
                'tribe': {
                  id: this.form.journeys[journeyIndex].tribes[tribeIndex].id,
                  name: this.form.journeys[journeyIndex].tribes[tribeIndex].name
                }
              };
              return entitys;
            }
          }
        }
      }
      return {
        'journey': {
          id: 1,
          name: 'Não Aplicável'
        },
        'tribe': {
          id: 1,
          name: 'Não Aplicável'
        }
      }
    },
    isDisabledJorneyByNivel() {
      if (this.nivel >= 2) {
        return true;
      }
      return false;
    },
    isDisabledTribeByNivel() {
      if (this.nivel >= 3) {
        return true;
      }
      return false;
    },
    save() {
      this.axios
          .put('tree/tecnologic/disabledTree', this.models.journey)
          .then((response) => {
            this.endComponent();
            this.$parent.textMessage = response.data.message;
            this.$parent.typeMessage = "success";
            this.$parent.dialogMessage = true;
            this.$parent.resetFields();
          })
          .catch((error) => {
            this.$parent.typeMessage = "warning"
            this.$parent.dialogMessage = true
            this.$parent.textMessage = 'Não foi possível inativar o item, verifique os campos'

            console.error(error);
          })
    },
    endComponent() {
      this.$parent.enableFields();

      this.models = {
        journey: {
          old: 0,
          name: 'NDA',
          new: 0,
          tribes: []
        },
        tribe: {
          old: 0,
          name: 'NDA',
          new: 0,
          squads: []
        },
        squad: {
          old: 0,
          name: 'NDA',
          new: 0
        },
        joyneyOptions: [],
      };
      this.displayModal = false;
    },
    getNameBySquadKey(squadKey) {
      let name = 'Squad não encontrado';
      this.options.squads.forEach(function (squad) {
        if (squad.squad_key == squadKey) {
          name = squad.name;
          return;
        }
      });
      return name;
    }
  }
}
</script>

import MultipleSelect from "@/components/common/VvMultipleSelect.vue";
import migrateProjectsServiceFactory from "@/domains/project/services/migrateProjectsService";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";
const migrateProjectsService = migrateProjectsServiceFactory();

export default {
    components: {
        MultipleSelect,
        VvConfirmationDialog
    },
    props: {
        iniciativeName: {
            type: String,
            default: ''
        },
        currentStep: {
            type: Number,
            default: 0
        },
        permissionMigrate: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        dialog: false,
        form: {
            projectsToBeMigrated: [],
            destinationInitiative: null
        },
        options: {
            projects: [],
            initiatives: []
        },
        confirmationDialog: {
            value: false,
            title: '',
            message: ''
        },
        successDialog: {
            value: false,
            title: '',
            message: ''
        }
    }),
    computed: {
        disableMigrationButton() {
            const buttonDisablers = [
                this.CONST_PROJECT_STEPS.CONCLUIDO,
                this.CONST_PROJECT_STEPS.CANCELADO,
                this.CONST_PROJECT_STEPS.ENCERRAMENTO
            ];
            if (buttonDisablers.includes(this.currentStep)) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        async openModal() {
            try {
                this.$showLoading();
                this.options = await migrateProjectsService.getOptions(this.$route.params.id);
                this.options.initiatives.map((initiative, index) => {
                    this.options.initiatives[index].name = initiative.id+' - '+initiative.name;
                });
                this.dialog = true;
            } catch(error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        async migrateProjects() {
            this.confirmationDialog.value = false;
            try {
                this.$showLoading();
                let projects = {
                    projectsToBeMigrated: this.form.projectsToBeMigrated
                };
                await migrateProjectsService.migrateProjects(this.form.destinationInitiative, projects);
                this.dialog = false;
                this.successDialog.title = "Migração concluída";
                this.successDialog.message = 'Os macro temas foram migrados com sucesso. O modal de impactos estratégicos será aberto para que possa redistribuir os valores.';
                this.successDialog.value = true;
            } catch(error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        confirmationModal() {
            this.confirmationDialog.title = "Tem certeza que deseja migrar este(s) macro tema(s)?";
            this.confirmationDialog.message = 'Se houver dados preenchidos em campos críticos da iniciativa, eles serão perdidos e será necessário  preenchê-los novamente na nova iniciativa';
            this.confirmationDialog.value = true;
        },
        openStrategicImpacts() {
            this.$router.push(`/projeto/${this.$route.params.id}?step=2&openModalImpacts=true`);
            this.$router.go();
        }
    }
}
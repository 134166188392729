import VvSwitch from '@/components/common/VvSwitch';

export default {
    components: {
        VvSwitch
    },
    props: {
        areas: {
            type: Array,
            default: () => []
        },
        options: {
            type: Object,
            default: () => ({})
        },
        areaDetail: {
            type: Object,
            default: () => ({})
        },
        areaIndexProp: {
            type: Number,
            default: null
        },
        canCreate: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialog: false,
            areaIndex: null,
            payload: {},
            itemDialog: {
                value: false,
                title: "Editar árvore",
                update: true,
                data: {}
            },
        }
    },
    methods: {
        openAreaEditModal(area) {
            this.$emit('areaEditModal', area);
        },
        closeAreas() {
            this.areaIndex = null;
        },
        openSteakHolderModal(stakeholder, area) {
            this.$emit('stakeholderEditModal', stakeholder, area);
        },
        openDetails(area) {
            this.$emit('openDetails', area);
        },
        transformationManagerName(index) {
            if (!this.isEmpty(this.options)) {
                let name;
                this.options.transformationManagers.map(et => {
                    if (et.id === this.areas[index].transformationManagersId) {
                        name = et.name;
                    }
                });
                return name;
            }
        },
        openNewStakeholderModal(area) {
            this.$emit('openNewStakeholderModal', area);
        }
    },
    watch: {
        areaIndex(val) {
            this.$emit('changeAreaIndexProp', val);
        },
        areaIndexProp(val) {
            this.areaIndex = val;
        }
    }
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vv-loading',{attrs:{"is-loading":_vm.isLoading}}),_c('h3',[_vm._v("Macro Temas")]),_c('div',{staticClass:"function-space"},[_c('div',{staticClass:"search-space"},[_c('text-input',{staticClass:"text-input-project-list",attrs:{"label":"Busca","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"buttons-space"},[_c('migrate-projects',{attrs:{"iniciativeName":_vm.iniciativeName,"currentStep":_vm.currentStep,"permissionMigrate":_vm.permissionMigrate}}),(_vm.createPermission)?_c('modal-strategic-impacts',{attrs:{"options-status":_vm.statusOption,"auto-open-modal-impacts":_vm.autoOpenModalImpacts,"errors-impacts":_vm.errorChangeStep},on:{"reload-projects":_vm.loadSubprojects}}):_vm._e(),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.createPermission},on:{"click":_vm.createNewProject}},[_vm._v(" Novo macro tema ")])],1)]),(_vm.errorChangeStep.subprojects ? _vm.errorChangeStep.subprojects : false)?_c('div',[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(_vm._s(_vm.errorChangeStep.subprojects[0]))])],1):_vm._e(),_c('v-data-table',{staticClass:"data-table-list-project elevation-1",attrs:{"headers":_vm.header,"items":_vm.subprojects,"mobile-breakpoint":0,"search":_vm.search,"expanded":_vm.expandedProject,"no-data-text":"Nenhum macro tema cadastrado","item-key":"id","hide-default-footer":"","disable-pagination":"","show-expand":""},on:{"update:expanded":function($event){_vm.expandedProject=$event},"item-expanded":_vm.loadSubprojectDetails},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var id = ref.item.id;
return [_c('div',{staticClass:"slot-id",class:{
          'error-border': _vm.errorIds.includes(id),
        }},[_vm._v(" "+_vm._s(id)+" ")])]}},{key:"item.value.statusId",fn:function(ref){
        var item = ref.item;
return [_c('v-autocomplete',{staticClass:"select-table",attrs:{"items":_vm.statusOption,"disabled":!item.value.editPermission,"item-text":"name","item-value":"id","append-icon":"mdi-chevron-down","no-data-text":"Nenhum status encontrado...","solo":""},on:{"change":function($event){return _vm.changeStatus(item.id)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var attrs = ref.attrs;
        var item = ref.item;
        var select = ref.select;
        var selected = ref.selected;
return [_c('span',_vm._b({class:(item.id === _vm.CONST_SUBPROJECT_STATUS.APROVADO) ? 'green-status' :
                      ((item.id === _vm.CONST_SUBPROJECT_STATUS.REPROVADO) ? 'red-status' : ''),attrs:{"input-value":selected,"label":""},on:{"click":select}},'span',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(item.value.statusId),callback:function ($$v) {_vm.$set(item.value, "statusId", $$v)},expression:"item.value.statusId"}})]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [(item.new)?_c('v-icon',{attrs:{"size":"19","color":"#FF502D"},on:{"click":function($event){return _vm.modalRemoveNewProject(item.value)}}},[_vm._v("mdi-trash-can-outline")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
                      var headers = ref.headers;
                      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('form-sub-project',{attrs:{"options-status":_vm.statusOption,"options-tags":_vm.tagsOption,"options-products":_vm.productsOption,"options-quarters":_vm.quartersOption,"origin-subproject":_vm.getOriginSubproject(item.id),"error-list":_vm.errorList[_vm.indexOf(item)]},on:{"update:errorList":function (value) { return _vm.updateErrorList(value, item); },"hasChange":function (change) { return (_vm.$emit('hasChange', change)); }},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('guide-objectives',{attrs:{"items":_vm.optionsDrivers,"groups":_vm.options.driversCategory,"disabled":!item.value.editPermission},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)]}}])}),_c('confirmation-dialog',{attrs:{"value":_vm.confirmationDialog.value,"title":_vm.confirmationDialog.title,"message":_vm.confirmationDialog.message},on:{"confirmed":_vm.removeNewProject,"denied":function($event){_vm.confirmationDialog.value=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="main-app">
    <v-card class="mx-auto overflow-hidden" height="400">
      <v-app>
        <Header />
        <v-main :class="$store.getters.isEmulated ? 'v-main-emulated' : ''">
          <router-view :key="$route.path"></router-view>
        </v-main>
        <Footer />
        <alert
          v-model="alert.show"
          :message="alert.message"
          :type="alert.type"
          :dismissible="alert.dismissible"
        />
        <loading :is-loading="loading" />
      </v-app>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import Header from "@/template/components/Header/Header.vue";
import Footer from "@/template/components/Footer/Footer.vue";
import Alert from "@/components/common/VvAlert";
import Loading from "@/components/common/VvLoading";
import "@/assets/css/main.css";

const newAlert = {
  show: false,
  message: "",
  type: "info",
  dismissible: false,
};

export default {
  components: {
    Header,
    Footer,
    Alert,
    Loading,
  },
  data: () => ({
    alert: { ...newAlert },
    loading: false,
  }),
  methods: {
    logoff: function() {
      this.$store.commit("logoff");
      this.$router.push("login");
    },
    showSuccess(message) {
      this.alert = {
        show: true,
        message,
        type: "success",
        dismissible: true,
      };
      this.hideAlert();
    },
    showError(error) {
      const {
        message: errorMessage,
        response: { data: { message } = {} } = {},
      } = error;
      this.alert = {
        show: true,
        message: message || errorMessage || error,
        type: "error",
        dismissible: true,
      };
      this.hideAlert();
    },
    hideAlert() {
      setTimeout(() => {
        this.alert = { ...newAlert };
      }, 3000);
    },
    showLoading() {
      this.loading = true;
    },
    hideLoading() {
      this.loading = false;
    },
  },
  mounted() {
    Vue.prototype.$showSuccess = this.showSuccess;
    Vue.prototype.$showError = this.showError;
    Vue.prototype.$hideAlert = this.hideAlert;

    Vue.prototype.$showLoading = this.showLoading;
    Vue.prototype.$hideLoading= this.hideLoading;
  },
};
</script>

<template>
  <div>
    <v-data-table
        class="data-table"
        :headers="headers"
        :items="data"
        hide-default-header
        hide-default-footer
        dense
        :class="vvErrors && !errors ? 'border-empty-table' : ''"
    >
      <template v-slot:body="{ items, headers }">
        <thead class="v-data-table-header">
          <tr>
            <th v-for="(header, index) in headers" class="text-start" :key="index">
              <span>{{ header.text }}</span>
              <v-tooltip right v-if="header.tooltip" max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(item,idx) in items" :key="idx">
          <td v-for="(header,key) in headers" :key="key" :style="header.width ? header.width : ''" :id="name + '-' + header.value + '-' + idx" :class="name">
            <div v-if="disabled">
              {{ item[header.value] }}
            </div>
            <div v-else-if="(header.type === 'actions')||(header.type === 'actions_plus')" style="text-align: center">
              <v-icon v-if="header.type === 'actions_plus'" color="warning" @click="showItem(item)">
                mdi-magnify
              </v-icon>
              <v-icon color="indigo" @click="removeItem(item)">
                mdi-close
              </v-icon>
            </div>
            <div v-else>
              <v-edit-dialog
                  save-text="Salvar"
                  cancel-text="Cancelar"
                  large
                  :return-value.sync="item[header.value]"
                  @cancel="cancel"
                  @open="open(item[header.value], header, idx)"
                  @close="close(item[header.value], header, idx)"
                  v-if="validateEdition(header.edit, item.isEdit)"
              >

                {{ displayValue(header.type, item[header.value]) }}

                <template v-slot:input>
                  <div v-if="header.type === 'select'">
                    <v-select
                        @change="change($event)"
                        v-model="item[header.value]"
                        :items="header.items"
                        item-text="name"
                        item-value="id"
                        append-icon="mdi-chevron-down"
                    >
                    </v-select>
                  </div>
                  <div v-else-if="header.type === 'month'">
                      <v-date-picker
                        format
                        locale="pt-br"
                        type="month"
                        no-title
                        scrollable
                        @input="change($event)"
                        v-model="item[header.value]"
                        single-line
                        :rules="(header.rule !== undefined ) ? [rules[header.rule]] : []"
                      >
                      </v-date-picker>
                  </div>
                  <div v-else-if="header.type === 'number'">
                    <v-text-field
                        min="1"
                        max="999"
                        @keypress="isNumber($event, item[header.value], 3)"
                        :type="header.type"
                        @input="change($event)"
                        v-model="item[header.value]"
                        single-line
                        :rules="(header.rule !== undefined ) ? [rules[header.rule]] : []"
                    ></v-text-field>
                  </div>
                  <div v-else-if="header.type === 'text'">
                    <v-text-field
                        :type="header.type"
                        @input="change($event)"
                        v-model="item[header.value]"
                        single-line
                        :rules="(header.rule !== undefined ) ? [rules[header.rule]] : []"

                    ></v-text-field>
                  </div>
                </template>
              </v-edit-dialog>
              <div v-else>
                {{ displayValue(header.type, item[header.value]) }}
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-dialog v-model="snack" hide-overlay persistent width="400">
      <v-alert dense :type="snackColor" style="margin: 0">
        <div style="display: flex; justify-content: space-between;">
          <span>{{snackText}}</span>
          <v-button @click="snack = false">
              <v-icon>
                  mdi-window-close
              </v-icon>
          </v-button>
        </div>
      </v-alert>
    </v-dialog>

    <v-row v-if="errors || vvErrors" style="margin-top: 2px">
      <v-col cols="12">
        <div class="v-text-field__details">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message" v-if="errors">{{ (errorMessages == "") ? 'Preencha os campos sinalizados' :  errorMessages}}</div>
              <div class="v-messages__message" v-else>{{ (errorMessages == "") ? 'Preencha os campos da ' + label :  errorMessages}}</div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!disabled" class="table-footer">
      <v-col align="end" cols="12" class="align-end table-footer-icon">
        <v-btn class="mx-2"
               fab
               small
               dark
               outlined
               color="indigo"
               @click="addLine()">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogError"
      max-width="300"
    >
      <v-card class="dialogError" color="snackbarColor">
        <v-card-title>Erro</v-card-title>
        <v-card-text class="px-6 py-3">
          <h3>Não é possível excluir todos os Indicadores.</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            text
            @click="dialogError = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogIndicators.open"
      max-width="768"
    >
      <v-card>
        <v-card-title>Mais detalhes</v-card-title>
        <v-card-text class="pa-6">
        <v-row v-if="dialogIndicators.kpi">
          <v-col cols="4"><strong>KPI</strong></v-col>
          <v-col cols="8">{{ dialogIndicators.kpi }}</v-col>
        </v-row>
        
        <v-row v-if="dialogIndicators.unit_measure">
          <v-col cols="4"><strong>Unidade de medida</strong></v-col>
          <v-col cols="8">{{ dialogIndicators.unit_measure }}</v-col>
        </v-row>

        <v-row v-if="dialogIndicators.expected_behavior">
          <v-col cols="4"><strong>Comportamento Esperado</strong></v-col>
          <v-col cols="8">{{ dialogIndicators.expected_behavior }}</v-col>
        </v-row>  
          
        <v-row v-if="dialogIndicators.baseline">
          <v-col cols="4"><strong>Baseline (Atual)</strong></v-col>
          <v-col cols="8">{{ dialogIndicators.baseline }}</v-col>
        </v-row>
          
        <v-row v-if="dialogIndicators.expectation">
          <v-col cols="4"><strong>Expectativa (Final do macro tema)</strong></v-col>
          <v-col cols="8">{{ dialogIndicators.expectation }}</v-col>
        </v-row>
          
        <v-row v-if="dialogIndicators.source_information">
          <v-col cols="4"><strong>Fonte da Informação (Sistema)</strong></v-col>
          <v-col cols="8">{{ dialogIndicators.source_information }}</v-col>
        </v-row>
          
        <v-row v-if="dialogIndicators.responsible_updating">
          <v-col cols="4"><strong>Responsável pela atualização</strong></v-col>
          <v-col cols="8">{{ dialogIndicators.responsible_updating }}</v-col>
        </v-row>
          
        <v-row v-if="dialogIndicators.revised_expectation">
          <v-col cols="4"><strong>Expectativa revisada</strong></v-col>
          <v-col cols="8">{{ dialogIndicators.revised_expectation }}</v-col>
        </v-row>

        <v-row v-if="dialogIndicators.reason_review">
          <v-col cols="4"><strong>Motivo da Revisão</strong></v-col>
          <v-col cols="8">{{ dialogIndicators.reason_review }}</v-col>
        </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeDialogIndicators"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    headers: Array,
    columns: Array,
    data: Array,
    disabled: Boolean,
    vvErrors: Boolean,
    label: String,
    name: String,
    errorMessages: String,
    numberColumnsRequired: Number
  },
  data() {
    return {
      errors: false,
      tmpData: "",
      snack: false,
      snackColor: '',
      snackText: '',
      pagination: {},
      errorText: "",
      rules: {
        required: v => v.length == 0 || 'O campo é obrigatório',
        date: v => this.validDate(v) || 'Data Inválida'
      },
      dialogIndicators: {
        open: false,
        kpi: "",
        unit_measure: "",
        expected_behavior: "",
        baseline: "",
        expectation: "",
        source_information: "",
        responsible_updating: "",
        revised_expectation: "",
        reason_review: ""
      },
      dialogError: false
    }
  },
  methods: {
    addLine: function () {
      let newLine = {
        id: 0
      };
      this.data.push(newLine);

      this.validatesTableFields()
    },
    change: function () {
      this.$emit("input", this.data);
      this.validatesTableFields()
      this.$emit('valid', this.name)
      this.$emit('change', this)
    },
    validDate: function (date) {
      if (date) {
        return !!date.match(/(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/);
      }
    },
    showError: function (errorMessenge) {
      this.snack = true;
      this.snackColor = 'error';
      this.snackText = errorMessenge
    },
    cancel() {
    },
    open(item, headers, idx) {
      if (this.data[idx][headers.value]) {
        for (let header in headers) {
          this.convertInputs(headers, header, idx, '/', '-', true)
        }
      }

      this.tmpData = item;
    },
    close(item, headers, idx) {
      for (let header in headers) {
        if (header == "rule") {
          let rule = headers[header];
          if (rule == "date") {
            if (!this.validDate(item)) {
              this.data[idx][headers.value] = this.tmpData;
              this.showError("Valor Inválido");
            }
          }
        }

        if (item) {
          this.convertInputs(headers, header, idx, '-', '/', false)
        }
      }

      this.validatesTableFields()
    },
    convertInputs(headers, header, idx, split, concat, byId) {
      if (header == "type") {
        let name_header = headers[header];

        if (name_header == "date") {
          let parts = this.data[idx][headers.value].split(split);

          if (parts.length == 3) {
            this.data[idx][headers.value] = parts[2] + concat + parts[1] + concat + parts[0];
          }
        }

        if (name_header == "select") {
          if (byId) {
            let filter = headers.items.filter(x => x.name == this.data[idx][headers.value])[0]

            this.data[idx][headers.value] = filter.id
          } else {
            let filter = headers.items.filter(x => x.id == this.data[idx][headers.value])[0]

            if (filter) {
              this.data[idx][headers.value] = filter.name
            }
          }
        }

        if (name_header == 'month') {
          const [year, month] = this.data[idx][headers.value].split('-');

          this.data[idx][headers.value] = `${year}-${month}`;
        }
      }
    },
    removeItem(item) {
      if (this.name == 'project_indicators' && this.data.length === 1) {
        this.dialogError = true
        return false
      }
      this.data.splice(this.data.indexOf(item), 1)
      this.validatesTableFields()
      this.$emit('valid', this.name)
    },
    showItem(item) {
      this.dialogIndicators.open = true
      this.dialogIndicators.kpi = item.kpi
      this.dialogIndicators.unit_measure = item.unit_measure
      this.dialogIndicators.expected_behavior = item.expected_behavior
      this.dialogIndicators.baseline = item.baseline
      this.dialogIndicators.expectation = item.expectation
      this.dialogIndicators.source_information = item.source_information
      this.dialogIndicators.responsible_updating = item.responsible_updating
      this.dialogIndicators.revised_expectation = item.revised_expectation
      this.dialogIndicators.reason_review = item.reason_review
    },
    closeDialogIndicators() {
      this.dialogIndicators.open = false
      this.dialogIndicators.kpi = ""
      this.dialogIndicators.unit_measure = ""
      this.dialogIndicators.expected_behavior = ""
      this.dialogIndicators.baseline = ""
      this.dialogIndicators.expectation = ""
      this.dialogIndicators.source_information = ""
      this.dialogIndicators.responsible_updating = ""
      this.dialogIndicators.revised_expectation = ""
      this.dialogIndicators.reason_review = ""
    },
    validatesTableFields() {
      let that = this

      that.errors = false
      that.data = that.data ?? [];

      that.removeBorders()

      let newObj = this.removeEmpty(that.data)

      newObj.map(function (item, idx) {
        let keys = Object.keys(item)

        keys = that.removeFieldsEditVvTableEditor(keys)

        if (that.name !== 'project_indicators') {
          if (keys.length) {
            if (keys.length < that.numberColumnsRequired) {
                that.addBorder(keys, idx)
            }
          }
        }
      })
    },
    addBorder(keys, idx) {
      let that = this
      let difference = that.columns.filter(x => !keys.includes(x))

      difference.map(function (item) {
        let element = document.getElementById(that.name + '-' + item + '-' + idx)

        if (element) {
          element.classList.remove('border-default')
          element.classList.add('border-empty')
        }
      })

      that.errors = true
    },
    removeBorders() {
      let elements = document.querySelectorAll('.' + this.name + '.border-empty');

      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('border-empty');
      }
    },
    displayValue(type, value) {
      if (type === 'month') {
         return this.convertMonthToDisplay(value);
      }

      return value;
    },

    validateEdition(header, isedit) {
      if(this.name !== 'project_indicators') return true;
      if(isedit) return !header;
      return header;
    },
  },
}
</script>

<style lang="scss" scoped>
.border-default {
  border: 1px solid #EEEEEE !important;
}

.border-empty,
.border-empty-table {
  border: 1px solid var(--text-error) !important;
}

.data-table td {
  border: 1px solid #EEEEEE;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}

.data-table th {
  background: #EEE
}

.data-table tr:nth-child(even) {
  background: #EFEFEF
}

.table-footer {
  margin-top: 1px;
}

.table-footer-error {
  padding-left: 15px;
  margin-top: 5px;
  padding-top: 0px;
  color: #cf1e1e;
}

.table-error-border {
  border: 1px solid #cf1e1e;
  border-radius: 0px;
}

.disabled {
  border-radius: 3px;
  margin: 3px;
  position: absolute;
  z-index: 50;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  background-color: #5D5D5D33
}

.error-messagens {
  color: #cf1e1e !important;
  border-top: 1px solid ;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  p {
    font-size: 12px!important;
    padding-bottom: 0px;
    margin: 0px;
  }
}

.dialogError {
  .v-card__title {
    color: white;
    font-weight: bold !important;
  }

  .v-card__text {
    h3 {
      color: white;
    }
  }
}
</style>

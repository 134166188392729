<template>
  <v-dialog
    :value="value"
    persistent
    width="500"
  >
    <v-card>
      <v-card-text class="pt-6">
        <h2>{{ title }}</h2>
        <div class="mt-2" v-if="hasEmptyIdJira && epicType === CONST_TYPE_EPICS.TECNOLOGIA">
          <strong class="text-uppercase">
            <p class="pt-2 mb-1">
              Você está salvando entregável(is) sem ID do JIRA!
            </p>
            <p>
              Um novo épico será criado no Jira e associado a esse(s) entregável(is). Tem certeza que deseja continuar?
            </p>
          </strong>
        </div>
        <div class="mt-2;" style="white-space:pre-wrap;" v-else>
          <p v-if="boudMessage != ''"><strong>{{ boudMessage }}</strong></p>
          <p>{{ message }}</p>
        </div>
        <slot name="form"></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions v-if="$slots.actions">
        <v-spacer></v-spacer>
        <v-btn text v-if="hasDanied" @click="cancel">
          Cancelar
        </v-btn>
        <slot name="actions"></slot>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn text v-if="hasDanied" @click="cancel">
          Não
        </v-btn>
        <v-btn color="primary" depressed @click="confirm" :disabled="disableOk">
          {{ hasDanied ? 'Sim' : 'Ok' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VvConfimationDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: "Você confirma sua ação?",
    },
    message: {
      type: String,
      default: "",
    },
    boudMessage: {
      type: String,
      default: "",
    },
    hasEmptyIdJira: {
      type: Boolean,
      default: false,
    },
    disableOk: {
      type: Boolean,
    },
    hasDanied: {
      type: Boolean,
      default: true
    },
    epicType: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    confirm() {
      this.$emit("confirmed", true);
      this.$emit("input", false);
    },
    cancel() {
      this.$emit("denied", true);
      this.$emit("input", false);
    },
  },
};
</script>

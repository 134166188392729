<template>
  <v-data-table
    :headers="headers"
    :items="data"
    hide-default-footer
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="!disabled" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Novo Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" lazy-validation>
                  <v-row v-if="name === 'delivery_expectations'">
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        min="1"
                        max="999"
                        type="number"
                        v-model="editedItem.priority"
                        label="Prioridade"
                        :rules="fieldPriorityRules"
                        required
                        @keypress="isNumber($event, editedItem.priority, 3)"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.activity"
                        label="Entrega de Valor"
                        :rules="fieldRules"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            label="Data"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="fieldRules"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          format
                          v-model="editedItem.date"
                          type="month"
                          locale="pt-br"
                          no-title
                          scrollable
                          max-width="240"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancelar
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(editedItem.date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                      <v-col cols="12" sm="6" md="4" v-for="(header,key) in headers" :key="key">
                        <v-text-field v-if="header.type === 'text'"
                          v-model="editedIndicator[header.value]"
                          :label="header.text"
                          :rules="fieldRules"
                          required
                        ></v-text-field>
                          
                        <v-select v-else-if="header.type === 'select'"
                          v-model="editedIndicator.expected_behavior"
                          :items="header.items"
                          :label="header.text"
                          item-text="name"
                          item-value="id"
                          append-icon="mdi-chevron-down"
                        ></v-select>
                      </v-col>
                    
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="close"> Cancelar </v-btn>
              <v-btn color="primary" text @click="save"> Salvar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Você tem certeza que deseja excluir este item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="closeDelete">Não</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">Sim</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.date="{ item }">
      <p>{{ moment(item.date).locale('pt-BR').format("MMMM/YYYY") }}</p>
    </template>
    <template v-if="!disabled" v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <h4>Sem dados</h4>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: Array,
    columns: Array,
    data: Array,
    disabled: Boolean,
    vvErrors: Boolean,
    label: String,
    name: String,
    errorMessages: String,
  },
  data() {
    return {
      atualPriority: null,
      dialog: false,
      moment: require("moment"),
      dialogDelete: false,
      sortable: false,
      editedIndex: -1,
      editedItem: {
        priority: "",
        activity: "",
        date: "",
      },
      defaultItem: {
        priority: "",
        activity: "",
        date: "",
      },
      editedIndicator: {
        kpi: "",
        unit_measure: "",
        expected_behavior: "",
        baseline: "",
        expectation: "",
        source_information: "",
        responsible_updating: "",
        revised_expectation: "",
        reason_review: ""
      },
      defaultIndicator: {
        kpi: "",
        unit_measure: "",
        expected_behavior: "",
        baseline: "",
        expectation: "",
        source_information: "",
        responsible_updating: "",
        revised_expectation: "",
        reason_review: ""
      },
      errors: false,
      tmpData: "",
      snack: false,
      snackColor: "",
      snackText: "",
      pagination: {},
      errorText: "",
      menu: false,
      fieldRules: [
        (v) => !!v || "Este campo é obrigatório!"
      ],
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Item" : "Editar Item";
    },
    computedDateFormatted() {
      return this.formatDate(this.editedItem.date);
    },
    fieldPriorityRules() {
      let rules = []
      rules.push(this.validPriority(this.data, this.editedItem.priority) || "Não pode haver duas prioridades iguais")
      rules.push((v) => !!v || "Este campo é obrigatório!")
      return rules
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      if (this.name === 'delivery_expectations') {
        this.atualPriority = item.priority
        this.editedItem = Object.assign({}, item);
      } else {
        this.editedIndicator = Object.assign({}, item);
      }
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      if (this.name === 'delivery_expectations') {
        this.editedItem = Object.assign({}, item);
      } else {
        this.editedIndicator = Object.assign({}, item);
      }
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.atualPriority = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndicator = Object.assign({}, this.defaultIndicator);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndicator = Object.assign({}, this.defaultIndicator);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form.validate();
      if (this.name === 'delivery_expectations') {
        if (
          this.editedItem.date != "" &&
          this.editedItem.date != "Invalid date" &&
          this.editedItem.priority != "" &&
          this.editedItem.activity != "" &&
          this.validPriority(this.data, this.editedItem.priority)
        ) {
          if (this.editedIndex > -1) {
            Object.assign(this.data[this.editedIndex], this.editedItem);
          } else {
            this.data.push(this.editedItem);
          }
          this.close();
        }
      } else {
        if (
          this.editedIndicator.kpi != "" &&
          this.editedIndicator.unit_measure != "" &&
          this.editedIndicator.expected_behavior != "" &&
          this.editedIndicator.baseline != "" &&
          this.editedIndicator.expectation != "" &&
          this.editedIndicator.source_information != "" &&
          this.editedIndicator.responsible_updating != ""
        ) {
          if (this.editedIndex > -1) {
            Object.assign(this.data[this.editedIndicator], this.editedIndicator);
          } else {
            this.data.push(this.editedIndicator);
          }
          this.close();
        }
      }
    },

    validPriority(data, atualPriority) {
      let array = []
      if(Array.isArray(data)){
        for (let priority in data) {
          if(atualPriority != this.atualPriority){
            array.push(data[priority].priority)
          }
        }
        return array.indexOf(atualPriority) > -1 ? false : true
      } else {
        return true
      }
    },

    change: function () {
      this.$emit("input", this.data);
      this.validatesTableFields();
      this.$emit("valid", this.name);
    },

    formatDate(date) {
      if (!date) return '';
      const [year, month] = date.split("-");
      return this.monthMap(month) + "/" + year;
    },

    monthMap(month) {
      switch (month) {
        case "01":
          return "janeiro";
        case "02":
          return "fevereiro";
        case "03":
          return "março";
        case "04":
          return "abril";
        case "05":
          return "maio";
        case "06":
          return "junho";
        case "07":
          return "julho";
        case "08":
          return "agosto";
        case "09":
          return "setembro";
        case "10":
          return "outubro";
        case "11":
          return "novembro";
        case "12":
          return "dezembro";
        default:
          return "sem dado";
      }
    },
  },
};
</script>

<style lang="scss" scope>
@media screen and (max-width: 374px) {
  div.v-picker__body.v-picker__body--no-title {
    max-width: 240px;
  }
}
.v-data-table-header-mobile {
  th {
    height: 0px !important;

    .v-data-table-header-mobile__wrapper {
      height: 0px;
    }
  }
}

</style>
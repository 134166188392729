import axios from "@/plugins/axios";

export default function changeRequestServiceFactory() {
  return {
    loadCROptions() {
      return axios
        .get(`v2/options/change-request`)
        .then(res => res.data);
    },
    loadImpactedEpics(projectId, epicId) {
      return axios
        .get(`v2/project/${projectId}/epic/${epicId}/cr/impacted-epics`)
        .then(res => res.data)
        .catch(res => res.response.data);
    },
    createChangeRequest(projectId, epicId, payload) {
      return axios
        .post(`v2/project/${projectId}/epic/${epicId}/cr`, payload)
        .then(res => res)
        .catch(err => err.response);
    },
    loadList(projectId, epicId) {
      return axios
        .get(`v2/project/${projectId}/epic/${epicId}/cr/summary`)
        .then(res => res)
        .catch(err => err.response);
    },
    loadEpicsList(projectId) {
      return axios
        .get(`v2/project/${projectId}/epic/summary?activeEpics=1`)
        .then(res => res)
        .catch(err => err.response);
    },
    loadChangeRequest(projectId, epicId, changeId) {
      return axios
        .get(`v2/project/${projectId}/epic/${epicId}/cr/${changeId}`)
        .then(res => res)
        .catch(err => err.response);
    },
    editChangeRequest(projectId, epicId, changeId, payload) {
      return axios
        .put(`v2/project/${projectId}/epic/${epicId}/cr/${changeId}`, payload)
        .then(res => res)
        .catch(err => err.response)
    },
    sendMessage(payload) {
      return axios
        .post(`v2/message/change-request/${payload.changeId}`, payload)
        .then(res => res)
        .catch(err => err.response);
    }
  }
}
export default function DataTableConfigs() {
    return {
        headersTable() {
            return [
                {
                    text: "ID épico Jira",
                    align: "center",
                    value: "idJira",
                    class: "cabecalho",
                    width: "124px"
                },
                {
                    text: "ID épico",
                    align: "center",
                    value: "epicId",
                    class: "cabecalho",
                    width: "99px"
                },
                {
                    text: "Nome do Entregável",
                    value: "epicName",
                    align: "center",
                    class: "cabecalho",
                    width: "114px"
                },
                {
                    text: "% conclusão épico Jira",
                    value: "progress",
                    align: "center",
                    valign: "center",
                    width: "187px",
                    class: "cabecalho",
                },
                {
                    text: "Estágio",
                    value: "stageId",
                    align: "center",
                    valign: "center",
                    class: "cabecalho",
                    width: "94px"
                },
                {
                    text: "Status Atual",
                    value: "statusId",
                    align: "center",
                    class: "cabecalho",
                    width: "123px",
                },
                {
                    text: "Quarter",
                    value: "quarter",
                    align: "center",
                    class: "cabecalho",
                    width: "155px"
                },
                {
                    text: "Jornada",
                    value: "journeyName",
                    align: "center",
                    class: "cabecalho",
                    width: "109px"
                },
                {
                    text: "Tribo",
                    value: "tribesName",
                    align: "center",
                    class: "cabecalho",
                    width: "109px"
                },
                {
                    text: "Squad",
                    value: "squadName",
                    align: "center",
                    class: "cabecalho",
                    width: "109px"
                },
            ]
        }
    }
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"IndicatorsTable"}},[_c('h3',{staticClass:"title-form"},[_vm._v("• Tabela de indicadores")]),_c('v-data-table',{staticClass:"data-table-indicators-table",attrs:{"headers":_vm.headers,"items":_vm.value,"mobile-breakpoint":0,"no-data-text":'Não há indicadores cadastrados',"itemsPerPage":_vm.value.length,"hide-default-footer":"","item-key":"id","dense":""},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(!item.new)?[(_vm.planningsErrors[_vm.value.indexOf(item)])?_c('v-btn',{attrs:{"icon":"","color":"#FF502D"},on:{"click":function($event){_vm.openDriverModal(_vm.value.indexOf(item))}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-magnify")])],1):_c('v-btn',{attrs:{"icon":"","color":"#6D6E70"},on:{"click":function($event){_vm.openDriverModal(_vm.value.indexOf(item))}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-magnify")])],1)]:_vm._e(),(item.new)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.canEditInception,"color":"#FF502D"},on:{"click":function($event){_vm.deleteDriver(_vm.value.indexOf(item))}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()],2)]}},{key:"item.driversCategoryId",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.indicatorsOptions,"item-value":"id","item-text":"name","no-data-text":"Selecione um KPI estratégico na tabela KPIs estratégicos","disabled":_vm.canEditInception,"error-messages":_vm.getErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.driversCategoryId')},on:{"input":function($event){_vm.clearErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.driversCategoryId')}},model:{value:(item.driversCategoryId),callback:function ($$v) {_vm.$set(item, "driversCategoryId", $$v)},expression:"item.driversCategoryId"}})]}},{key:"item.kpi",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":_vm.canEditInception,"error-messages":_vm.getErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.kpi')},on:{"input":function($event){_vm.clearErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.kpi')}},model:{value:(item.kpi),callback:function ($$v) {_vm.$set(item, "kpi", $$v)},expression:"item.kpi"}})]}},{key:"item.unitMeasure",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":_vm.canEditInception,"error-messages":_vm.getErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.unitMeasure')},on:{"input":function($event){_vm.clearErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.unitMeasure')}},model:{value:(item.unitMeasure),callback:function ($$v) {_vm.$set(item, "unitMeasure", $$v)},expression:"item.unitMeasure"}})]}},{key:"item.sourceInformation",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":_vm.canEditInception,"error-messages":_vm.getErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.sourceInformation')},on:{"input":function($event){_vm.clearErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.sourceInformation')}},model:{value:(item.sourceInformation),callback:function ($$v) {_vm.$set(item, "sourceInformation", $$v)},expression:"item.sourceInformation"}})]}},{key:"item.projectIndicatorsFrequencyId",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.options.indicatorFrequency,"item-value":"id","item-text":"name","disabled":_vm.canEditInception,"error-messages":_vm.getErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.projectIndicatorsFrequencyId')},on:{"input":function($event){_vm.clearErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.projectIndicatorsFrequencyId')}},model:{value:(item.projectIndicatorsFrequencyId),callback:function ($$v) {_vm.$set(item, "projectIndicatorsFrequencyId", $$v)},expression:"item.projectIndicatorsFrequencyId"}})]}},{key:"item.responsibleUpdating",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"disabled":_vm.canEditInception,"error-messages":_vm.getErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.responsibleUpdating')},on:{"input":function($event){_vm.clearErrorInception('projectIndicators.'+_vm.value.indexOf(item)+'.responsibleUpdating')}},model:{value:(item.responsibleUpdating),callback:function ($$v) {_vm.$set(item, "responsibleUpdating", $$v)},expression:"item.responsibleUpdating"}})]}}])}),_c('modal-indicators-plannings',{attrs:{"dialog":_vm.dialog,"canEditIndicators":_vm.canEditIndicators,"canEditInception":_vm.canEditInception,"data":_vm.indicatorsDataForModal,"plannings":_vm.plannings},on:{"closeModal":_vm.closeModal}}),_c('v-btn',{staticClass:"mt-1 px-0",attrs:{"disabled":_vm.canEditInception,"plain":"","large":"","color":"#5A6BFF"},on:{"click":function($event){return _vm.addDriver()}}},[_vm._v(" + Novo KPI ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="vv-pagination">
    <div>
      <p>{{footerProps.itemsPerPageText}}</p>
    </div>
    <div>
      <v-select
        v-model="size"
        :items="footerProps.itemsPerPageOptions"
        dense
      ></v-select>
    </div>
    <div>
      <p>
        {{(atualPage * pageSize) + 1}}-{{toPages}} de {{totalItems}}
      </p>
    </div>
    <v-btn
      icon
      class="ml-4"
      @click="page -= 1"
      :disabled="atualPage < 1"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn
      icon
      @click="page += 1"
      :disabled="totalPages === totalItems || toPages >= totalItems "
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>
<script>
  export default {
    name: 'VvPagination',
    props: {
      footerProps: {
        type: Object,
        default: () => ({
          itemsPerPageText: 'Linhas por página',
          itemsPerPageOptions: [5, 10, 15, 30, 50, 100]
        })
      },
      pageSize: {
        type: Number,
        default: 5
      },
      atualPage: {
        type: Number,
        default: 0
      },
      totalItems: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        size: 5,
        page: 0
      }
    },
    computed: {
      totalPages() {
        return (this.totalItems / this.pageSize) < 1 ? this.totalItems : this.totalItems / this.pageSize;
      },
      toPages() {
        let toPages = (this.pageSize * this.atualPage) + this.pageSize;
        if (toPages > this.totalItems) {
          return this.totalItems;
        } else {
          return toPages;
        }
      }
    },
    watch: {
      pageSize(value) {
        this.size = value;
      },
      size(value) {
        this.$emit('pageSize', value);
      },
      atualPage(value) {
        this.page = value;
      },
      page(value) {
        this.$emit('atualPage', value);
      }
    }
  }
</script>
<style lang="scss">
  .vv-pagination {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;

    p {
      font-weight: 400;
      font-size: 11px;
      color: #6D6E70;
      margin-bottom: 0px;
    }

    .v-select {
      width: 48px;
      margin-left: 24px;
      margin-right: 24px;

      .v-input__control {
        .v-input__slot {
          .v-select__slot {
            .v-select__selections {
              .v-select__selection {
                  font-size: 11px;
                  color: #6D6E70;
                  margin: 0px 0px -18px 0px;
              }
            }

            .v-input__append-inner {
              margin-top: 14px;
            }
          }
        }
      }
    }
  }
</style>
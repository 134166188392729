var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex",staticStyle:{"gap":"8px"}},_vm._l((_vm.sorted),function(item,index){return _c('div',{key:index,staticClass:"d-flex pl-5 pr-4 py-3 step",class:{
      selected: index + 1 === _vm.value,
      outfocus: index + 1 !== _vm.value,
      open: item.active && index + 1 !== _vm.value,
      'cursor-select': item.active,
      'cursor-blocked': !item.active,
    },on:{"click":function($event){return _vm.select(item, index + 1)}}},[_c('div',{staticClass:"mr-2",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"font-weight-black",staticStyle:{"font-size":"1.1em","color":"black"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',[_vm._v(_vm._s(item.description))])]),_c('v-chip',{staticClass:"chip",class:{
        'cursor-select': item.active,
        'cursor-blocked': !item.active,
      },attrs:{"color":item.active ? 'primary' : 'grey',"disabled":!item.active}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(index + 1))])])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
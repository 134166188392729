<template>
    <v-container>
        <vv-loading :is-loading="isLoading"></vv-loading>
        <download-message :loading="isLoading" :removed="removed" :type="type" :date="date" :expire_date="expire_date"></download-message>
    </v-container>
</template>

<script>
import DownloadMessage from '../../components/reports/DownloadMessage.vue'
import VvLoading from "@/components/VvLoading";

export default {
    components: {
        DownloadMessage,
        VvLoading
    },
    data() {
        return {
            isLoading: false,
            removed: false,
            type: "",
            date: "",
            expire_date: ""
        }
    },
    created() {
        this.export()
    },
    methods: {
        export() {
            this.isLoading = true;
            this.axios
                .get("v2/report/download/"+this.$route.params.id)
                .then((response) => {
                    this.removed = false
                    this.type = response.data.type;
                    this.date = response.data.date;
                    this.expire_date = response.data.expire_date;
                    let filename = response.data.filename
                    this.createAndDownloadBlobFile(this.base64ToArrayBuffer(response.data.file), filename);
                })
                .catch((error) => {
                    this.removed = true
                    if (error.response) {
                      this.type = error.response.data.type;
                      this.date = error.response.data.date;
                      this.expire_date = error.response.data.expire_date;
                    }
                })
                .finally(() => {
                  this.isLoading = false;
                });
        },
    }
}
</script>
export default {
  methods: {
    getError(param) {
      return this.errorList[param];
    },
    clearError(param) {
      const errors = { ...this.errorList };
      delete errors[param];
      this.$emit('update:errorList', errors);
    },
    clearErrorModalInception(param) {
      delete this.errorList[param];
    },
    setErrorList(error) {
      const {
        response: { data: errors },
      } = error;
      this.errorList = errors;
    },
    getErrorInception(param) {
      return this.errorListInception[param];
    },
    clearErrorInception(param) {
      const errors = { ...this.errorListInception };
      delete errors[param];
      this.$emit('update:errorListInception', errors);
    },
    setErrorListInception(error) {
      const {
        response: { data: errors },
      } = error;
      this.errorListInception = errors;
    },
    getErrorSubproject(param) {
      const errorList = this.errorList?.error;
      return errorList && errorList[param];
    },
    cleanErrorSubproject(param) {
      const errorList = { ...this.errorList };
      if (typeof errorList.error !== "undefined") delete errorList.error[param];
      this.$emit("update:errorList", errorList);
    },
  },
};

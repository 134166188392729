<template>
  <label class="vv-disable-input">
    {{ label }}
    <div class="edit-disabled" v-if="value">
      <v-chip>{{ value }}</v-chip>
    </div>
    <div v-else>
      <div class="v-input v-text-field v-text-field--is-booted">
        <div class="v-input__control">
          <div class="v-input__slot">
            <div class="v-text-field__slot">
              <input disabled id="id" name="id" type="text" />
              <v-icon style="cursor: default">mdi-lock</v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  name: "VvDisableInput",
  props: ["value", "label"],
};
</script>

<style scoped>
.vv-disable-input {
  margin-bottom: 21px;
}
.v-input__slot {
  height: 40px;
  margin-bottom: 1px;
}
.v-input__slot::before,
.v-input__slot::after {
  border-color: rgba(0, 0, 0, 0.4) !important;
}
</style>

<template>
  <v-overlay style="z-index: 9999;" :value="vvIsLoading">
    <v-progress-circular
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'VvLoading',
  props: {
    isLoading: Boolean
  },
  data: () => ({
    vvIsLoading: false,
  }),
  watch: {
    isLoading: function(newVal, oldVal) {
      this.vvIsLoading = oldVal

      setTimeout(() => {
        this.vvIsLoading = newVal
      }, 1000)
    }
  }
}
</script>

import axios from "@/plugins/axios";

const map = (obj) => ({
    from: map => Object.fromEntries(Object.entries(obj).map(el => {
        const key = el[0]
        const newKey = map
            .find(item => Array.isArray(item) && (item[0] === key || item[1] === key))
            ?.reduce((acc,curr) => acc = curr !== key ? curr : acc)
        if(newKey) {
            el[0] = newKey
        }
        return el
    }))
})

const toParams = obj => {
    if(!obj) return ''
    return Object.entries(obj)
    .filter(el => !!el[1])
    .reduce((acc,curr) => {
        if(Array.isArray(curr[1])) {
            const key = `${curr[0]}[]`
            curr[1].forEach(el => acc.push([key,el]))
        }
        else {
            acc.push(curr)
        }
        return acc
    },[])
    .map((el,id) => id > 0 ? `&${el[0]}=${el[1]}`: `${el[0]}=${el[1]}`)
    .join('')
    .replace (/^/,'?')
}

const listsMap = [
    ['impactedAreas', 'area'],
    ['mainImpacted', 'main_impact'],
    ['frequencyProblem', 'problem_frequency'],
    ['problemImpact', 'problem_impact'],
    ['statusProblem', 'problem_status'],
    ['urgentResolution', 'urgent_resolution'],
]
const problemMap = [
    'id',
    'ideas',
    'title',
    'description',
    ['impactDescription', 'description_impact'],
    ['rootCause', 'root_cause'],
    ['rootCauseDetail', 'description_root_cause'],
    ['frequencyProblem', 'problem_frequency_id'],
    ['urgentResolution', 'urgent_resolution_id'],
    ['mainImpacted', 'main_impacted_id'],
    ['areaId', 'area_id'],
    ['correctionSuggestion', 'suggestion'],
    ['statusProblem', 'problem_status_id'],
    ['impactProblem', 'problem_impact_id'],
    ['userId', 'user'],
]

export default function problemServiceFactory(){
    return {
        loadLists() {
            return axios
                .get("problem/options")
                .then(res => res.data)
                .then(res => map(res).from(listsMap));
        },
        create(payload) {
            return axios
                .post("problem", map(payload).from(problemMap));
        },
        edit(payload) {
            return axios
                .put(`problem/${payload.id}`, map(payload).from(problemMap));
        },
        show(id) {
            return axios
                .get(`problem/${id}`)
                .then(res => res.data)
                .then(res => map(res).from(problemMap));
        },
        list(payload) {
            const params = toParams(payload) || '';
            return axios
                .get(`problem${params}`)
                .then(res => res.data);
        },
        loadListIdeas() {
            return axios
                .get(`idea/list`)
                .then(res => res.data);
        },
        ListIdeasLinkedToProblem(id) {
            return axios
                .get(`problem/listIdeasLinkedProblem/${id}`)
                .then(res => res.data);
        },
        linkIdeasToTheProblem(problemId, ideasId) {
            const data = { 
                problem_id: problemId,
                idea_id: ideasId
            };
            
            return axios
                .post(`problem/linkIdeasToTheProblem`, data)
                .then(res => res.data)
        },
        verifyPermissionToCreate() {
            return axios
                .get(`problem/permissions`)
                .then(res => res.data)
        },
        listProblems() {
            return axios
                .get(`problem/list`)
                .then(res => res.data);
        },
    }
}
import axios from "@/plugins/axios";

// TODO: Fazer os 'catchs' e 'finallys'
export default function epicServiceFactory(){
  return {
    loadSummary(projectId) {
      return axios
          .get(`v2/project/${projectId}/summary`)
          .then(res => res.data);
    },
    loadEpicsSummary(projectId, activeEpics = this.CONST_TIPO_LISTAGEM_EPICOS.CONCLUIDOS) {
      return axios
          .get(`v2/project/${projectId}/epic/summary?activeEpics=${activeEpics}`)
          .then(res => res.data)
          .catch(err => err.response.data);
    },
    loadEpicsOptions(projectId) {
      return axios
          .get(`v2/project/${projectId}/epic/0/options`)
          .then(res => res.data);
    },
    loadPmPoBySquad(squadKey) {
      return axios
          .get(`v2/pmpo/squad/${squadKey}`)
          .then(res => res.data);
    },
    loadDataEpic(projectId, epicId) {
      return axios
          .get(`v2/project/${projectId}/epic/${epicId}`)
          .then(res => res.data);
    },
    loadGuideObjectives(projectId, subprojectId) {
      return axios
          .get(`/v2/project/${projectId}/subproject/${subprojectId}/drivers`)
          .then(res => res.data);
    },
    async saveEpics(projectId, newEpics) {
      const payload = newEpics.map((epic) => {
        const id = epic.id;
        const data = {...epic};
        const {deliveryPlanned, deliveryDone} = data;
        if (data.new) {
          delete data.id;
        }

        if (typeof deliveryPlanned !== 'undefined') {
          return {
            id,
            payload: {
              ...data,
              deliveryPlanned: deliveryPlanned.value,
              deliveryDone: deliveryDone.value,
            }
          }
        } else {
          return {
            id,
            payload: {
              ...data,
            }
          }
        }
      });
      payload.forEach((item) => {
        let editUrl = `v2/project/${projectId}/epic/${item.payload.id}`;

        // Usa o VSM ID para identificar se é um payload completo ou parcial e dai chamar uma rota que atualiza apenas Status e Estagio
        if (typeof item.payload.vsmId === 'undefined') {
          editUrl = `v2/project/${projectId}/epic/${item.payload.id}/status-stages`;
        }

        item.promise = (item.payload.new) ? axios.post(`v2/project/${projectId}/epic`, item.payload) : axios.put(editUrl, item.payload);
        item.promise
            .then(res => item.response = res)
            .catch(err => item.error = err);
      });
      await Promise.allSettled(payload.map(({promise}) => promise));
      return payload;
    },
    permissionTypeEpic(projectId, epicId = null) {
      epicId = epicId === null ? '' : `?epicId=${epicId}`; 
      return axios
          .get(`/v2/project/${projectId}/epic/permission-type-epic/${epicId}`)
          .then(res => res.data);
    },
    updatePriority(projectId, newEpicIds) {
      return axios
          .post(`v2/project/${projectId}/epic/priority/reprioritize`, {newEpicIds})
          .then(res => res.data);
    },
    getModernizationOptions(subProjectId) {
      return axios
        .get(`v2/options/subproject-products/${subProjectId}`)
        .then(res => res.data)
    }
  }
}
<template>

    <multiple-switchs
      v-if="items.length > 0 || loadGroups"
      v-model="project.drivers" 
      :items="items" 
      :groups="groups" 
      :disabled="disabled"
    />
    <v-progress-linear v-else indeterminate color="primary" class="mb-4 mt-6" />

</template>

<script>
import MultipleSwitchs from "@/components/common/VvMultipleSwitchs";

export default {
  name: "GuideObjectives",
  components: {
    MultipleSwitchs,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
  },
  data: () => ({
    expanded: true,
  }),
  computed: {
    project: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    loadGroups() {
      return (typeof this.groups !== 'undefined');
    },
  },
};
</script>

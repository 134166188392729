<template>
  <div class="filter-div">
    <vv-loading :is-loading="isLoading"></vv-loading>
    <v-expansion-panels accordion v-model="panelCr">
      <v-expansion-panel>
        <v-expansion-panel-header class="filter-expansion">
          <h2>Filtros</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" :sm="field.sm" :md="field.md" v-for="(field, index) in fields" :key="index">
                <label v-if="field.type !== 'sliders'" :for="field.name" class="filter-label">
                  {{ field.label }}
                  <v-tooltip right max-width="450" v-if="typeof field.tooltip !== 'undefined'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-3" color="primary" v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                    {{ field.tooltip }}
                  </span>
                  </v-tooltip>
                </label>
                <div v-if="field.type === 'select'">
                  <v-select
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :items="field.items"
                      :disabled="field.disabled"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-chevron-down"
                  ></v-select>
                </div>

                <div v-else-if="field.type === 'autocompletechange'">
                  <v-autocomplete
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :items="field.items"
                      :disabled="field.disabled"
                      item-text="name"
                      item-value="id"
                      @input="field.onChange"
                      append-icon="mdi-chevron-down"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          @click="select"
                          label
                          class="chip-green"
                      >
                        {{ item.id }}
                        <v-icon
                            @click="deleteItem(field)"
                            size="16"
                            class="remove-chip"
                        >mdi-close
                        </v-icon
                        >
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </div>

                <div v-else-if="field.type === 'multiselect'">
                  <v-select
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :items="field.items"
                      :disabled="field.disabled"
                      item-text="name"
                      item-value="id"
                      multiple
                      append-icon="mdi-chevron-down"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          @click="select"
                          label
                          class="chip-green"
                      >
                        {{ item.name }}
                        <v-icon
                            @click="removeItem(field,item)"
                            size="16"
                            class="remove-chip"
                        >mdi-close
                        </v-icon
                        >
                      </v-chip>
                    </template>
                  </v-select>
                </div>

                <div v-else-if="field.type === 'autocomplete'">
                  <v-autocomplete
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :items="field.items"
                      :disabled="field.disabled"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-chevron-down"
                      no-data-text="Nenhum item encontrado"
                  ></v-autocomplete>
                </div>

                <div v-else-if="field.type === 'multiautocomplete'">
                  <v-autocomplete
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :items="field.items"
                      :disabled="field.disabled"
                      item-text="name"
                      item-value="id"
                      multiple
                      append-icon="mdi-chevron-down"
                      no-data-text="Nenhum item encontrado"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          @click="select"
                          label
                          class="chip-green"
                      >
                        {{ item.name }}
                        <v-icon
                            @click="removeItem(field,item)"
                            size="16"
                            class="remove-chip"
                        >mdi-close
                        </v-icon
                        >
                        <!-- Adicione esse atributo caso não queira que na edição seja possível remover o item 'v-if="!isEdition"' -->
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </div>

                <div v-else-if="field.type === 'date'">
                  <vv-date-pick
                      v-model="field.value"
                      :disabled="field.disabled"
                      :clear-field="true"
                  >
                  </vv-date-pick>
                </div>

                <div v-else-if="field.type === 'number'">
                  <v-text-field
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :disabled="field.disabled"
                      type="number"
                      @keypress="isNumber($event, field.value, 9)"
                  ></v-text-field>
                </div>

                <div v-else-if="field.type === 'switch'">
                  <v-switch
                      v-model="field.value"
                      class="manual-line switch"
                      color="secondary"
                      :label="field.value ? 'Sim' : 'Não'"
                      inset
                      :disabled="field.disabled"
                  />
                </div>

                <div v-else-if="field.type === 'sliders'">
                  <vv-multiple-sliders
                      v-model="field.value"
                      :id="field.id"
                      :items="field.items"
                      :disabled="field.disabled"
                      :label="field.label"
                      step="5"
                  />
                </div>

                <div v-else>
                  <v-text-field
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :type="field.type"
                      :disabled="field.disabled"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="12" class="d-flex justify-end">
                <v-btn color="primary" @click="filter(false)" class="btn-save-project">
                  Filtrar
                </v-btn>
                <v-btn color="secondary" @click="clearFilter" class="btn-save-project">
                  Limpar
                </v-btn>
                <v-btn color="info" @click="filter(true)" class="btn-save-project" v-if="acceptExport">
                  Exportar listagem
                </v-btn>
                <v-tooltip right max-width="250" v-if="acceptExport">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-3" color="primary" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    Para extrair toda a base do sistema, não aplicar nenhum filtro na tela
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <modal-confirm-export :visible="dialogExport" />
  </div>
</template>

<script>
import VvDatePick from "@/components/VvDatePick";
import VvLoading from "@/components/VvLoading";
import VvMultipleSliders from "@/components/common/VvMultipleSliders";
import { isObject, flatten } from "@/services/utils";
import ModalConfirmExport from "@/components/reports/ModalConfirmExport";

import filterServiceFactory from "@/services/filterService";
const FilterService = filterServiceFactory();

export default {
  name: 'VvFilter',
  components: {VvDatePick, VvLoading, VvMultipleSliders, ModalConfirmExport},
  props: {
    fields: Array,
    exportApi: {
      type: String,
      default: 'project'
    },
    filterName: {
      type: String,
      required: true
    },
    acceptExport: {
      type: Boolean,
      default: true,
    },
    notificationExport: {
      type: Boolean,
      default: false
    },
    panelCr: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    isLoading: false,
    fieldsComputed: [],
    dialogExport: false,
  }),
  methods: {

    filter(isExport) {
      let payload = {};

      this.fields.map(field => {
        if (typeof field.value !== "undefined") {
          payload[field.payloadBackend] = (field.value) ? field.value : null
        }
      });

      this.pushGlobalFilters(this.filterName, this.fields);

      if (!isExport) {
        if (Object.keys(payload).length) {
          payload.page = 1;
          this.$emit('filter', payload);
        }
      }
      if (isExport) {
        if (this.notificationExport) {
          this.exportNotification(payload);
        } else {
          this.export(payload);
        }
      }
    },
    async exportNotification(payload) {
      try {
        await FilterService.exportNotification(payload, this.exportApi);
        this.dialogExport = true;
      } catch (error) {
        this.$showError(error);
      }
    },
    async export(payload) {
      payload.isExport = 1;

      const newPayload = Object.entries(payload)
          .reduce((acc, [ key ,value ]) => {
            if(isObject(value)){
              Object.entries(flatten(value))
                  .forEach(([elKey, elValue]) => acc.push([`${key}[${elKey}]`, elValue]))
            }
            else {
              acc.push([key, value])
            }
            return acc
          },[])

      payload = Object.fromEntries(newPayload)

      this.isLoading = true;
      await this.axios.get(this.exportApi, {
        params: payload
      }).then((response) => {
        this.createAndDownloadBlobFile(this.base64ToArrayBuffer(response.data.file), response.data.filename);
        this.isLoading = false;
      })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
    clearFilter() {
      this.$refs.form.reset();
      this.clearGlobalFilters(this.filterName, this.fields);
      this.fields.forEach((field) => {
        if (field.type == 'date') {
          field.value = null;
        }
      });
      this.$emit('reset');
    },
    removeItem(campo, item) {
      campo.value.splice(campo.value.indexOf(item.id), 1);
    },
    deleteItem(campo) {
      campo.value = null;
    }
  },
  mounted(){

    let fieldsStore = this.getGlobalFilters(this.filterName);

    if (typeof fieldsStore !== 'undefined') {
      this.panelCr= 0;
    }
  },
  clearFilter() {
    this.$refs.form.reset();

    this.fields.forEach((field) => {
      if (field.type == 'date') {
        field.value = null;
      }
    });

    this.$emit('reset');
  }
}
</script>

<style scoped>
.filter-div {
  border-top: 10px solid #00D3C3;
  border-radius: 4px 4px 0 0;
  background-color: white;
  padding: 0 30px;
}

.filter-expansion {
  padding: 0;
}

.filter-btn {
  padding-top: 45px;
}

.btn-save-project.v-btn {
  margin-left: 0px;
  margin-right: 20px;
}

.btn-save-project.v-btn:last-child {
  margin-right: 0px;
}
</style>

<template>
    <v-dialog persistent v-model="visible" width="560">
      <v-card>
        <v-card-text class="export">
          <h1>Seu arquivo está sendo gerado!</h1>
          <p>Aguarde alguns minutos que você será notificado quando o download estiver disponível.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="export">
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" @click="visible = false; $router.go()">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    .v-card__text {
      &.export {
        padding: 30px 30px 40px;

        h1 {
          color: #0A437A;
          font-size: 20px;
          margin-bottom: 16px;
          font-weight: 500;
        }

        p {
          color: #6D6E70;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }

    .v-card__actions {
      &.export {
        padding: 16px;

        button {
          height: 39px;
        }
      }
    }
  }
}
</style>
import VvTextInput from "../../../../components/common/VvTextInput";

export default {
    name: 'InvestmentOrder',
    components: {VvTextInput},
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        canDeleteInvestmentOrder: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        newitem: { id: 0, investmentOrder: null, active: true, hasDelete: false, new: true },
        isErrorMessage: false,
    }),
    computed: {
      investmentOrder: {
          get() {
              return this.value
          },
          set(value) {
              this.$emit('update', value)
          }
      },
      curretErrorMessage() {
          if (Object.keys(this.errorMessages).length > 0) {
              if (typeof this.errorMessages.investmentOrder !== 'undefined') {
                  if (typeof this.errorMessages.investmentOrder[0].investmentOrder === 'undefined') {
                      return this.errorMessages.investmentOrder[0];
                  }
              }
          }
          return '';
      },
    },
    watch: {
        errorMessages(newValue) {
            if (Object.keys(newValue).length > 0) {
                this.isErrorMessage = true;
            }
        }
    },
    methods: {
        getLineError(param) {
            if (this.isErrorMessage === true) {
                if (typeof this.errorMessages[param] !== 'undefined') {
                    return this.errorMessages[param];
                }
            }
            return [];
        },
        addNewItem() {
            this.value.push({...this.newitem});
        },
        showTrash(item) {
            if (typeof item.new !== 'undefined' && item.new === true) {
                return true;
            }
            return this.canDeleteInvestmentOrder;
        },
        deleteItem(item, index) {
            this.onDisabled(item, 'delete');
            this.value.splice(index, 1);
        },
        onDisabled(item, action) {
            if (action == 'delete' && item.id > 0) {
                this.$emit('onDisabled', item, action);
            }
            if (action == 'disabled') {
                this.$emit('onDisabled', item, action);
            }
        },
        labelInfo(larbelInfo) {
            if (larbelInfo === true) {
                return 'Ativo';
            }
            return 'Inativo';
        },
        clearErrorMessage() {
            this.isErrorMessage = false;
        }
    }
}
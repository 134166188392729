<template>
  <v-dialog
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card :id="modalId" :class="modalClass">
      <v-card-text class="pt-6 modal-title">
        <div>
          <h2>{{ title }}</h2>
        </div>
        <div>
          <slot name="subtitle" />
        </div>
        <v-btn
          @click="$emit('input', false)"
          class="close-button"
          color="white"
          fab
          x-small
          depressed
        >
          <v-icon size="26">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text style="max-height: calc(100vh - 160px); overflow-y: auto;" :class="modalClassText">
        <slot />
      </v-card-text>
      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VvModal",
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    modalId: {
      type: String,
      default: "",
    },
    modalClass: {
      type: String,
      default: "",
    },
    modalClassText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.close-button {
  position: absolute;
  right: 12px;
  top: 12px;
}
.v-card__actions {
  display: inherit;
}
.modal-title {
  overflow-y: hidden !important;
}
</style>

<template>
  <div class="details-change-request">
    <div class="grid-3 gap-md">
      <div class="g-col-1">
        <label>Id Épico</label>
        <h2>{{item.epicId}}</h2>
      </div>
      <div class="g-col-2">
        <label>Nome do Épico</label>
        <h2>{{item.epicName}}</h2>
      </div>
    </div>

    <h2>
      <v-icon>mdi-circle-medium</v-icon>
      Workflow
    </h2>

    <list-approvers 
      :approvers="item.approvals" />

    <refused-alert
      v-if="item.statusId == CONST_CHANGE_REQUEST_STATUS.REPROVADO"
      :text="'Essa Change Request foi rejeitada'"
      :refusedCause="item.refusedCause"
      :refusedDate="item.refusedDate"
      :refusedUser="item.refusedUser" />

    <form-change-request
      v-if="typeof item.epicId === 'number'"
      :epicId="item.epicId"
      :edit="true"
      :changeRequest="item"
      :deliveryPlanned="deliveryPlanned"
      :permissions="permissions"
      :focal-point-approver="project.focalPoints"
      @changeRequestSaved="updateChangeRequest"
      @sendMessage="sendMessage"
      ref="formCR" />

    <template v-if="lengthMessage">
      <h2 class="mt-12">
        <v-icon>mdi-circle-medium</v-icon>
        Dúvidas
      </h2>
      <message 
        class="mb-15"
        :messages="item.message"
        :disableResponse="!permissions.CanSendMessage"
        @messageData="messageData" />
    </template>

    <v-card-actions v-if="permissions.CanEditChangeRequest">
      <v-spacer></v-spacer>
      <v-btn color="primary" class="cancel-button" elevation="0" outlined @click="$router.go()">
        Cancelar
      </v-btn>
      <v-btn color="primary" class="save-button" elevation="0" @click="$refs.formCR.confirmationDialog.value = true">
        Submeter Aprovação
      </v-btn>
    </v-card-actions>

    <vv-alert 
      v-model="alert.show" 
      :message="alert.message"  
      :type="alert.type" 
      :dismissible="alert.dismissible" />
  </div>
</template>

<script>
import VvAlert from "@/components/common/VvAlert";
import ListApprovers from '../../shared/ListApprovers.vue';
import RefusedAlert from '../../shared/RefusedAlert.vue';
import FormChangeRequest from './FormChangeRequest.vue';
import Message from '../../shared/Message.vue';
import changeRequestServiceFactory from "@/domains/changeRequest/services/changeRequestServices";

const ChangeRequestService = changeRequestServiceFactory();

export default {
  props: {
    item: Object,
    permissions: Object,
    project: Object,
    deliveryPlanned: String
  },
  components: {
    VvAlert,
    ListApprovers,
    RefusedAlert,
    FormChangeRequest,
    Message
  },
  watch: {
    item(newValue) {
      return newValue
    },
    errorAlert(event) {
      this.$emit('error', event);
    },
    loading(event) {
      this.$emit('loading', event);
    },
  },
  computed: {
    lengthMessage() {
      return this.item.message?.length > 0;
    },
  },
  data() {
    return {
      originalDate: false,
      newDate: false,
      errorAlert: false,
      loading: false,
      alert: {
        show: false,
        message: '',
        type: 'info',
        dismissible: false,
      },
    }
  },
  methods: {
    updateChangeRequest() {
      this.$emit('updateChangeRequest', this.item);
    },
    messageData(messageData) {
      this.$refs.formCR.confirmationDialog.value = true;
      this.$refs.formCR.confirmationDialog.sendMessage = true;
      this.$refs.formCR.confirmationDialog.messageData = messageData;
      this.$refs.formCR.confirmationDialog.title = 'Enviar mensagem e submeter para aprovação?';
      this.$refs.formCR.confirmationDialog.message = 'Você realmente deseja enviar a mensagem e submeter para aprovação?';
    },
    async sendMessage(messageData) {      
      this.loading = true;

      const send = await ChangeRequestService.sendMessage({
        changeId: this.item.id,
        message: messageData.message,
        parentId: messageData.parentId
      });

      if (send.status === 200) {
        this.alert = {
          show: true,
          message: send.data.message,
          type: 'success',
        };

        setTimeout(() => {
          this.alert.show = false;
        }, 3000);

        this.updateChangeRequest();
      } else {
        this.alert = {
          show: true,
          message: send.data.message,
          type: 'error',
        };

        setTimeout(() => {
          this.alert.show = false;
        }, 3000);
      }

      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
.details-change-request {
  width: 100%;

  h2 {
    font-weight: 600;
    font-size: 14px;
    color: #6D6E70;
    margin-bottom: 16px;

    .v-icon {
      font-size: 16px;
    }
  }

  .switch {
    margin-left: 4px;
  }

  .cost {
    margin-top: 22px;
  }

  .cancel-button, .save-button {
    padding: 11px 20px !important;
    margin-left: 30px !important;

    span.v-btn__content {
      font-size: 14px;
      letter-spacing: 0px;
    }
  }

  @media (max-width: 2004px) {
    width: 1858px;
  }

  @media (max-width: 1799px) {
    width: 1642px;
  }

  @media (max-width: 1606px) {
    width: 1449px;
  }

  @media (max-width: 1431px) {
    width: 1274px;
  }

  @media (max-width: 1274px) {
    width: 1121px;
  }
}
</style>
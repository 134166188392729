import Tabs from "@/components/common/VvTabs.vue";
import DeliveryVision from "../../components/DeliveryVision/DeliveryVision.vue";
import RoadMap from "../../components/RoadMap/RoadMap.vue";

export default {
    name: "Dashboard",
    components: {
        Tabs,
        DeliveryVision,
        RoadMap
    },
    data: () => ({
        tabs: null,
        view: true,
        items: [
            "Dashboard",
            "Integração Power BI"
        ],
    }),
    methods: {
        openModal() {
            this.modalOpen = true;
        },

        turnView(value) {
            this.view = value
        }
    }
}
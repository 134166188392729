import axios from "@/plugins/axios";

export default function messageServiceFactory() {
  return {
    sendMessage({ message, model, modelId: model_id, parentId: parent_id }) {
      return axios
        .post("message/generic", { message, model, model_id, parent_id })
        .then((res) => res.data);
    },
  };
}

<template>
  <v-container>
    <vv-loading :is-loading="isLoading"></vv-loading>
    <v-row class="text-center">
    </v-row>
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading.vue";
export default {
  name: "Home",
  components: { VvLoading },
  data: () => ({
    isLoading: false,
    logado: false,
    validandoToken: ''
  }),
  mounted() {
    this.isLoading = false;
    this.$router.push("dashboard");

  },
};
</script>

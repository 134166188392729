<template>
  <div>
    <div>
      <v-btn
        @click="openModal"
        outlined
        :color="errorsImpacts.impacts ? 'error' : 'primary'"
        class="open-canvas"
        v-if="!viewMode">
        <v-tooltip left max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" color="#DBDCDC" v-bind="attrs" v-on="on" small>
              mdi-information-outline
            </v-icon>
          </template>
          <span>
            É necessário salvar os macro temas para ajustar os impactos
          </span>
        </v-tooltip>
        Impactos estratégicos
      </v-btn>
      <v-btn
        large
        outlined
        color="#5D4EE8"
        class="mt-6"
        @click="openModal"
        v-else
      >Abrir impactos estratégicos</v-btn>
    </div>
    <modal
        v-model="showModal"
        title="Impactos estratégicos"
        modal-id="modalStrategicImpact"
        modal-class-text="hide-scrollbar"
        width="1200"
        height="800"
        scrollable
    >
      <template v-slot:subtitle>
        <p>Distribua os valores do esforço e de cada um dos impactos estratégicos, entre os macro temas até chegar a 100% do total usado.</p>
      </template>
      <div v-if="errorsImpacts.impacts">
        <v-alert
            dense
            outlined
            type="error"
        >{{ errorsImpacts.impacts[0] }}</v-alert>
      </div>
      <div class="grid-3 gap-md">
        <text-input
            v-model="search"
            label="Buscar macro tema"
            append-icon="mdi-magnify"
        />
      </div>
      <div class="grid-1 gap-md" style="max-height: 87%">
        <v-data-table
            :headers="headers"
            :items="strategicImpacts"
            :mobile-breakpoint="0"
            :search="search"
            no-data-text="Nenhum macro tema cadastrado"
            item-key="id"
            hide-default-header
            hide-default-footer
            disable-pagination
            fixed-header
            height="520"
            class="data-table-strategic-impacts"
        >
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th
                    v-for="header in headers"
                    :key="header.id"
                    scope="col"
                >
                  <span class="title-table">{{ header.text }}</span>
                  <span class="subtitle-table">
                    <v-tooltip bottom max-width="250" v-if="header.id !== 0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-0 mb-1 mt-1" color="#DBDCDC" v-bind="attrs" v-on="on" small>
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>
                        Somatória de todos os percentuais dos macro temas
                      </span>
                    </v-tooltip>
                    <span :class="(header.total > 100) ? 'total-error' : ((header.total == 100) ? 'total-ok' : '')">{{ header.subtext }} {{ header.total }}</span>
                  </span>
                </th>
              </tr>
            </thead>
          </template>
          <template #item.name="{ item }">
            <p class="project-name-modal mb-0 mt-3">{{ item.name }}</p>
            <v-autocomplete
                v-model="item.status"
                :items="optionsStatus"
                item-value="id"
                item-text="name"
                append-icon="mdi-chevron-down"
                small
                class="mt-2"
                @change="changeStatus(item)"
                :disabled="viewMode"
            ></v-autocomplete>
          </template>
          <template #item.Esforçodeimplantação="{ item }">
            <TextInput
                v-model="item.Esforçodeimplantação"
                label="Valor"
                class="text-field-table"
                placeholder="INFORME O %"
                :disabled="(item.status === CONST_SUBPROJECT_STATUS.REPROVADO) || viewMode"
                :class="(totals.Esforçodeimplantação > 100) ? 'text-input-error' : ((totals.Esforçodeimplantação == 100) ? 'text-input-ok' : '')"
                @keyup="sumStrategicImpacts(8, 'Esforçodeimplantação')"
                @keypress="isNumber($event, item.Esforçodeimplantação, 3, true)"
            ></TextInput>
          </template>
          <template #item.Impactoembaseativadeclientes="{ item }">
            <TextInput
                v-model="item.Impactoembaseativadeclientes"
                label="Valor"
                class="text-field-table"
                placeholder="INFORME O %"
                :disabled="(item.status === CONST_SUBPROJECT_STATUS.REPROVADO) || viewMode"
                :class="(totals.Impactoembaseativadeclientes > 100) ? 'text-input-error' : ((totals.Impactoembaseativadeclientes == 100) ? 'text-input-ok' : '')"
                @keyup="sumStrategicImpacts(1, 'Impactoembaseativadeclientes')"
                @keypress="isNumber($event, item.Impactoembaseativadeclientes, 3, true)"
            ></TextInput>
          </template>
          <template #item.Impactoemrecorrênciadecompras="{ item }">
            <TextInput
                v-model="item.Impactoemrecorrênciadecompras"
                label="Valor"
                class="text-field-table"
                placeholder="INFORME O %"
                :disabled="(item.status === CONST_SUBPROJECT_STATUS.REPROVADO) || viewMode"
                :class="(totals.Impactoemrecorrênciadecompras > 100) ? 'text-input-error' : ((totals.Impactoemrecorrênciadecompras == 100) ? 'text-input-ok' : '')"
                @keyup="sumStrategicImpacts(2, 'Impactoemrecorrênciadecompras')"
                @keypress="isNumber($event, item.Impactoembaseativadeclientes, 3, true)"
            ></TextInput>
          </template>
          <template #item.Impactoemexperiênciadocliente="{ item }">
            <TextInput
                v-model="item.Impactoemexperiênciadocliente"
                label="Valor"
                class="text-field-table"
                placeholder="INFORME O %"
                :disabled="(item.status === CONST_SUBPROJECT_STATUS.REPROVADO) || viewMode"
                :class="(totals.Impactoemexperiênciadocliente > 100) ? 'text-input-error' : ((totals.Impactoemexperiênciadocliente == 100) ? 'text-input-ok' : '')"
                @keyup="sumStrategicImpacts(3, 'Impactoemexperiênciadocliente')"
                @keypress="isNumber($event, item.Impactoembaseativadeclientes, 3, true)"
            ></TextInput>
          </template>
          <template #item.ImpactoemGMV="{ item }">
            <TextInput
                v-model="item.ImpactoemGMV"
                label="Valor"
                class="text-field-table"
                placeholder="INFORME O %"
                :disabled="(item.status === CONST_SUBPROJECT_STATUS.REPROVADO) || viewMode"
                :class="(totals.ImpactoemGMV > 100) ? 'text-input-error' : ((totals.ImpactoemGMV == 100) ? 'text-input-ok' : '')"
                @keyup="sumStrategicImpacts(4, 'ImpactoemGMV')"
                @keypress="isNumber($event, item.Impactoembaseativadeclientes, 3, true)"
            ></TextInput>
          </template>
          <template #item.ImpactoemresultadoMargem="{ item }">
            <TextInput
                v-model="item.ImpactoemresultadoMargem"
                label="Valor"
                class="text-field-table"
                placeholder="INFORME O %"
                :disabled="(item.status === CONST_SUBPROJECT_STATUS.REPROVADO) || viewMode"
                :class="(totals.ImpactoemresultadoMargem > 100) ? 'text-input-error' : ((totals.ImpactoemresultadoMargem == 100) ? 'text-input-ok' : '')"
                @keyup="sumStrategicImpacts(5, 'ImpactoemresultadoMargem')"
                @keypress="isNumber($event, item.Impactoembaseativadeclientes, 3, true)"
            ></TextInput>
          </template>
          <template #item.Impactoemaceleraçãoemsoluçõeslogísticas="{ item }">
            <TextInput
                v-model="item.Impactoemaceleraçãoemsoluçõeslogísticas"
                label="Valor"
                class="text-field-table"
                placeholder="INFORME O %"
                :disabled="(item.status === CONST_SUBPROJECT_STATUS.REPROVADO) || viewMode"
                :class="(totals.Impactoemaceleraçãoemsoluçõeslogísticas > 100) ? 'text-input-error' : ((totals.Impactoemaceleraçãoemsoluçõeslogísticas == 100) ? 'text-input-ok' : '')"
                @keyup="sumStrategicImpacts(6, 'Impactoemaceleraçãoemsoluçõeslogísticas')"
                @keypress="isNumber($event, item.Impactoembaseativadeclientes, 3, true)"
            ></TextInput>
          </template>
          <template #item.Impactoemaceleraçãodesoluçõesfinanceiras="{ item }">
            <TextInput
                v-model="item.Impactoemaceleraçãodesoluçõesfinanceiras"
                label="Valor"
                class="text-field-table"
                placeholder="INFORME O %"
                :disabled="(item.status === CONST_SUBPROJECT_STATUS.REPROVADO) || viewMode"
                :class="(totals.Impactoemaceleraçãodesoluçõesfinanceiras > 100) ? 'text-input-error' : ((totals.Impactoemaceleraçãodesoluçõesfinanceiras == 100) ? 'text-input-ok' : '')"
                @keyup="sumStrategicImpacts(7, 'Impactoemaceleraçãodesoluçõesfinanceiras')"
                @keypress="isNumber($event, item.Impactoembaseativadeclientes, 3, true)"
            ></TextInput>
          </template>
        </v-data-table>
      </div>
      <template #actions v-if="!viewMode">
        <div class="grid-10 gap-md pb-6 rtl">
          <v-btn
            color="primary"
            @click="saveStrategicImpacts"
            class="ml-6"
            :disabled="canApply"
          >Aplicar</v-btn>
          <v-btn
            text
            color="#929497"
            @click="showModal=false"
            :disabled="false"
          >Cancelar</v-btn>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/common/VvModal";
import TextInput from "@/components/common/VvTextInput";
import subprojectServiceFactory from "@/domains/subProject/services/subProjectService";

const subprojectService = subprojectServiceFactory();

export default {
  name: "ModalStrategicImpacts",
  components: {
    Modal,
    TextInput,
  },
  props: {
    optionsStatus: {
      type: Array,
      default: () => [],
    },
    autoOpenModalImpacts: {
      type: Boolean,
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    errorsImpacts: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    showModal: false,
    canApply: false,
    headers: [],
    search: "",
    strategicImpacts: [],
    totals: {},
  }),
  watch: {
    autoOpenModalImpacts() {
      if (this.autoOpenModalImpacts) {
        this.$showLoading();
        this.openModal();
        this.$hideLoading();
      }
    },
  },
  methods: {
    sumAllStrategicImpacts() {
      this.sumStrategicImpacts(1, 'Impactoembaseativadeclientes');
      this.sumStrategicImpacts(2, 'Impactoemrecorrênciadecompras');
      this.sumStrategicImpacts(3, 'Impactoemexperiênciadocliente');
      this.sumStrategicImpacts(4, 'ImpactoemGMV');
      this.sumStrategicImpacts(5, 'ImpactoemresultadoMargem');
      this.sumStrategicImpacts(6, 'Impactoemaceleraçãoemsoluçõeslogísticas');
      this.sumStrategicImpacts(7, 'Impactoemaceleraçãodesoluçõesfinanceiras');
      this.sumStrategicImpacts(8, 'Esforçodeimplantação');
    },
    async openModal() {
      await this.loadHeaderTable();
      this.sumAllStrategicImpacts();
      this.showModal = true;
    },
    async loadHeaderTable() {
      this.$showLoading();
      const resultHeader = await subprojectService.loadStrategicImpacts(this.$route.params.id);
      this.headers = resultHeader.strategicImpactsHeaders;
      const header = this.headers.map((head) => {
        let newHeader = {};
        if (head.id === 0) {
          newHeader = {
            id: head.id,
            text: head.text,
            subtext: "Status",
            value: head.value,
          };
          return newHeader;
        }
        newHeader = {
          id: head.id,
          text: head.text,
          subtext: "Total usado:",
          value: head.value,
          total: 0,
        };
        return newHeader;
      });
      this.headers = header;
      this.strategicImpacts = resultHeader.strategicImpactsValues;
      this.$hideLoading();
    },
    sumStrategicImpacts(id, module) {
      let sum = 0;
      this.strategicImpacts.forEach((si) => {
        if (typeof si[module] !== 'undefined') {
          const parseNumber = (si[module] === "") ? 0 : parseInt(si[module]);
          sum += parseNumber;
        }
      });
      this.headers.forEach((header) => {
        if (header.id === id) {
          header.total = sum;
          this.totals[header.value] = sum;
        }
      })
    },
    async saveStrategicImpacts() {
      const payload = this.strategicImpacts.map((impact) => {
        let impacts = [
          {
            id: 1,
            value: parseInt(impact.Impactoembaseativadeclientes)
          },
          {
            id: 2,
            value: parseInt(impact.Impactoemrecorrênciadecompras)
          },
          {
            id: 3,
            value: parseInt(impact.Impactoemexperiênciadocliente)
          },
          {
            id: 4,
            value: parseInt(impact.ImpactoemGMV)
          },
          {
            id: 5,
            value: parseInt(impact.ImpactoemresultadoMargem)
          },
          {
            id: 6,
            value: parseInt(impact.Impactoemaceleraçãoemsoluçõeslogísticas)
          },
          {
            id: 7,
            value: parseInt(impact.Impactoemaceleraçãodesoluçõesfinanceiras)
          },
          {
            id: 8,
            value: parseInt(impact.Esforçodeimplantação)
          },
        ];

        let filtredImpacts = [];
        for (let impactIndex = 0; impactIndex < impacts.length; impactIndex++) {
          for (let headerIndex = 0; headerIndex < this.headers.length; headerIndex++) {
            if (this.headers[headerIndex].id === impacts[impactIndex].id) {
              filtredImpacts.push(impacts[impactIndex]);
            }
          }
        }

        const arrPayload =
          {
            projectId: impact.id,
            status: impact.status,
            impacts: filtredImpacts,
          };
        return arrPayload;
      });

      this.$showLoading();
      try {
        const resultSavaImpacts = await subprojectService.saveStrategicImpacts(this.$route.params.id, payload);
        this.$showSuccess(resultSavaImpacts.message);
        this.$router.go();
      } catch (error) {
        const { response: { data: { message } = {} } = {} } = error;
        this.$showError(message || "Verifique e preencha todos os campos!");
      } finally {
        this.$hideLoading();
      }
    },
    changeStatus(project) {
      if (project.status === this.CONST_SUBPROJECT_STATUS.REPROVADO) {
        project.ImpactoemGMV = 0;
        project.Impactoemaceleraçãodesoluçõesfinanceiras = 0;
        project.Impactoemaceleraçãoemsoluçõeslogísticas = 0;
        project.Impactoembaseativadeclientes = 0;
        project.Impactoemexperiênciadocliente = 0;
        project.Impactoemrecorrênciadecompras = 0;
        project.ImpactoemresultadoMargem = 0;
        project.Esforçodeimplantação = 0;
        this.sumAllStrategicImpacts();
      }
    },
  },
  mounted() {
    if (this.autoOpenModalImpacts) {
      this.$showLoading();
      this.openModal();
      this.$hideLoading();
    }
  },
}
</script>

<style lang="scss">
#modalStrategicImpact {
  height: 800px;
}

.hide-scrollbar {
  overflow-y: hidden !important;
}

.data-table-strategic-impacts {
  border-radius: 0;
  padding-right: 20px;
  min-height: 150px;
  max-height: 70%;

  .v-data-table-header th {
    min-width: 230px;
  }

  .title-table {
    font-size: 14px;
    font-weight: 400;
    color: #202121;
    display: block;
    min-height: 41px;
  }

  .subtitle-table {
    font-size: 12px;
    font-weight: 400;
    color: #929497;
  }

  .text-field-table {
    background-color: #ffffff;
    position: relative;
    margin-left: 8px;
    z-index: 1;
    top: 15px;
    .v-text-field__slot {
      border: 1px solid #929497;
      box-sizing: border-box;
      border-radius: 4px;
      input {
        padding: 8px;
      }
    }
    .v-input {
      position: relative;
      top: -9px;
    }

    .theme--light.v-text-field > .v-input__control > .v-input__slot:before,
    .v-text-field > .v-input__control > .v-input__slot:after {
      border: none;
    }
  }
  .v-autocomplete {
    width: 120px;
    border-radius: 2px;

    .v-input__slot {
      background-color: #DBDCDC;
      padding: 0 10px;
      min-height: 24px;
      height: 24px;
    }

    input {
      font-size: 12px;
    }
    .v-input__append-inner {
      margin-top: 0;
      padding: 8px 0 0;
      margin-right: -8px;
    }
  }

  .project-name-modal {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #929497;
  }

  .total-ok {
    color: #699F10;
  }

  .text-input-ok {
    color: #699F10;
    .v-text-field__slot {
      border-color: #699F10;
      input {
        color: #699F10;
      }
    }
  }

  .total-error {
    color: #B71816;
  }

  .text-input-error {
    color: #B71816;
    .v-text-field__slot {
      border-color: #B71816;
      input {
        color: #B71816;
      }
    }
  }

  &.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: inherit;
  }
  .v-text-field > .v-input__control > .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after{
    border: none;
  }
}
</style>
<template>
  <v-data-table
    :headers="headers"
    :items="changeRequests"
    :single-expand="true"
    :expanded.sync="expandedChange"
    :mobile-breakpoint="0"
    :no-data-text="noDataText"
    :no-results-text="noResultsText"
    :search="search"
    :custom-filter="customSearch"
    :itemsPerPage="changeRequests.length"
    hide-default-footer
    item-key="id"
    dense
    class="data-table-change-requests"
    @click:row="openDetails"
  > 

    <template v-slot:item.id="{ item }">
      <span :id="'cr-' + item.id">
        {{ item.id }}
      </span>
    </template>

    <template v-slot:item.created_at="{ item }">
        {{ item.created_at }}
    </template>

    <template v-slot:item.description="{ item }">
      <p class="reason mb-0" :id="'cr-' + item.id">
        {{ item.description }}
      </p>
    </template>

    <template v-slot:item.status="{ item }">
      <div
        class="workflow-status"
        :class="parseStatusChangeRequest(item.statusId)"
      >
        {{ item.status }}
      </div>
    </template>

    <template v-slot:item.transformationManagers="{ item }">
      <p
        v-for="(transformationManager, i) in item.transformationManagers"
        :key="i"
        class="approver transformation-manager"
        :class="transformationManager.status.toLowerCase()"
      >
        {{ transformationManager.name }}
      </p>
    </template>

    <template v-slot:item.vsm="{ item }">
      <p
        v-for="(vsm, i) in item.vsm"
        :key="i"
        class="approver vsm"
        :class="vsm.status.toLowerCase()"
      >
        {{ vsm.name }}
      </p>
    </template>

    <template v-slot:item.focalPoints="{ item }">
      <p
        v-for="(focalPoint, i) in item.focalPoints"
        :key="i"
        class="approver focal-point"
        :class="focalPoint.status.toLowerCase()"
      >
        {{ focalPoint.name }}
      </p>
    </template>

    <template v-slot:item.stakeholder="{ item }">
      <p
        v-for="(stakeholder, i) in item.stakeholder"
        :key="i"
        class="approver stakeholder"
        :class="stakeholder.status.toLowerCase()"
      >
        {{ stakeholder.name }}
      </p>
    </template>

    <template v-slot:item.expandButton="{ item }">
      <v-btn icon color="#6D6E70">
        <v-icon v-if="item.id === toggleById">mdi-chevron-up</v-icon>
        <v-icon v-else>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <template v-slot:expanded-item="{ headers }">
      <td :colspan="headers.length">
        <details-change-request 
          :item="details"
          :permissions="permissions"
          :project="project"
          :deliveryPlanned="deliveryPlanned"
          @updateChangeRequest="updateChangeRequest"
          @error="errorAlert = $event"
          @loading="loading = $event" />
      </td>
    </template>
  </v-data-table>
</template>,

<script>
import DetailsChangeRequest from "./DetailsChangeRequest.vue";
import changeRequestServiceFactory from "@/domains/changeRequest/services/changeRequestServices";

const ChangeRequestService = changeRequestServiceFactory();

export default {
  components: {
    DetailsChangeRequest
  },
  props: {
    changeRequests: {
      type: Array,
      required: true
    },
    project: Object,
    search: String,
    loadingChangeRequests: Boolean,
    deliveryPlanned: String
  },
  data() {
    return {
      headers: [
        {
          text: "ID épico",
          align: "start",
          value: "epicId",
          width: '113px'
        },
        {
          text: "ID Change",
          align: "start",
          value: "id",
          width: '113px'
        },
        {
          text: "Data Solicitação",
          align: "start",
          value: "created_at",
          width: '149px'
        },
        {
          text: "Motivo",
          align: "start",
          value: "description",
          width: '217px'
        },
        {
          text: "Status",
          align: "start",
          value: "status",
          width: '175px'
        },
        {
          text: "VSM",
          align: "start",
          value: "vsm",
          width: "213px",
          sort: (a, b) =>
            a.map(({ name }) => name) < b.map(({ name }) => name) ? -1 : 1,
        },
        {
          text: "Pontos Focais",
          align: "start",
          value: "focalPoints",
          width: "213px",
          sort: (a, b) =>
            a.map(({ name }) => name) < b.map(({ name }) => name) ? -1 : 1,
        },
        {
          text: "Stakeholder",
          align: "start",
          value: "stakeholder",
          width: "213px",
          sort: (a, b) =>
            a.map(({ name }) => name) < b.map(({ name }) => name) ? -1 : 1,
        },
        {
          text: "Entrega de Novo Épico?",
          align: "start",
          value: "deliveryNewEpic",
          width: '191px'
        },
        {
          text: "Épico a ser entregue",
          align: "start",
          value: "newEpicId",
          width: '175px'
        },
        {
          text: "",
          value: "expandButton",
        },
      ],
      details: {},
      loading: false,
      expandedChange: [],
      deliveryFormalizationId: null,
      changeId: null,
      noDataText: "nenhuma change request encontrada",
      noResultsText: "nenhuma change request encontrada",
      toggleById: null,
      errorAlert: false,
      permissions: {}
    };
  },
  watch: {
    loading(event) {
      this.$emit("loading", event);
    },
    errorAlert(event) {
      this.$emit("error", event);
    },
    loadingChangeRequests(value) {
      this.statusListChangeRequest(value);
    },
    async changeRequests() {
      const isOnExpandedChangeRequests = this.expandedChange
        .map(({ id }) => id)
        .includes(this.changeId);

      const change = this.changeRequests
        .find(({ id }) => id === this.changeId);

      if (isNaN(this.changeId) || isOnExpandedChangeRequests === true)
        return;

      this.expandedChange.push(change);
      this.load(this.changeId, this.$route.params.epicId, true);
    },
  },
  created() {
    if (typeof this.$route.params.changeId !== 'undefined') {
      this.changeId = parseInt(this.$route.params.changeId);
    }
  },
  methods: {
    customSearch(value, search, item) {
      if (Array.isArray(value)) {
        return value.some(item=>Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search)))
      }
      return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
    },
    openDetails(row, slot) {
      if (!slot.isExpanded) {
        this.load(row.id, row.epicId);
      }
      this.toggleButton(row.id);
      slot.expand(!slot.isExpanded);
    },
    async load(id, epicId, byRoute = false) {
      this.details = {}
      if (typeof epicId !== 'undefined' && typeof id !== 'undefined') {
        this.$emit("loading", true);
        const result = await ChangeRequestService.loadChangeRequest(this.$route.params.id, epicId, id);
        try {
          this.details = result.data.changeRequest;
          this.permissions = result.data.permissions;
          if (byRoute === true) {
            await this.toggleButton(id);
            this.$vuetify.goTo("#cr-" + id, { offset: 55 });
          }
        } catch {
          this.errorAlert = true;
        }
        this.$emit("loading", false);
      }
    },
    statusListChangeRequest() {
      if (
        this.changeRequests.length === 0 &&
        this.loadingChangeRequests === false
      ) {
        this.noDataText = "nenhuma formalização encontrada";
      }
    },
    parseStatusChangeRequest(id) {
      let status = "";
      switch (id) {
        case this.CONST_CHANGE_REQUEST_STATUS.PENDENTE_APROVACAO:
          status = "pendente";
          break;
        case this.CONST_CHANGE_REQUEST_STATUS.APROVADO:
          status = "aprovado";
          break;
        case this.CONST_CHANGE_REQUEST_STATUS.REPROVADO:
          status = "recusado";
          break;
        case this.CONST_CHANGE_REQUEST_STATUS.PENDENTE_ALTERACAO:
          status = "pendente";
          break;
      }

      return status;
    },
    toggleButton(id) {
      if (this.toggleById === id) {
        this.toggleById = null;
      } else {
        this.toggleById = id;
      }
    },
    updateChangeRequest(changeRequest) {
      if (typeof this.$route.params.changeId !== 'undefined') {
        let url = `/projeto/${this.$route.params.id}/epicos/${changeRequest.epicId}/change-request/${changeRequest.id}`;
        this.$router.push(url);
      }
      this.$emit('updateChangeRequest', changeRequest);
      this.load(changeRequest.id, changeRequest.epicId, true);
    }
  },
};
</script>

<style lang="scss">
.data-table-change-requests {
  cursor: pointer;

  &.v-data-table {
    .v-data-table__wrapper {
      table {
        thead {
          background-color: #f4f5f5;
          height: 34px;

          tr {
            th {
              span {
                font-weight: 400;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              height: 83px;
              color: #6d6e70;
              font-weight: 500;
            }

            &.v-data-table__expanded__content {
              box-shadow: none;
              cursor: auto;

              td {
                padding: 32px 0px;
              }
            }
          }
        }
      }
    }
  }

  .approver {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 0px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &.pendente {
      color: #929497;
    }

    &.aprovado {
      color: #6da151;
    }

    &.reprovado {
      color: #ff502d;
    }

    @media (max-width: 1903px) {
      &.transformation-manager {
        max-width: 213px;
      }

      &.vsm {
        max-width: 213px;
      }

      &.focal-point {
        max-width: 213px;
      }

      &.stakeholder {
        max-width: 213px;
      }
    }
  }

  .workflow-status {
    padding: 8px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    border-radius: 34px;
    width: fit-content;

    &.pendente {
      color: #6d6e70;
      background-color: #dbdcdc;
    }

    &.aprovado {
      color: #6da151;
      background-color: #b2deb2;
    }

    &.recusado {
      color: #ff502d;
      background: rgba(255, 80, 45, 0.25);
    }
  }

  .reason {   
    width: 217px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
<template>
  <label>
    Iniciativa Jira
    <v-text-field v-model="value" disabled>
      <template #append-outer>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                outlined
                color="primary"
                :disabled="disabled"
                @click="value ? (dialog.showUnlink = true) : showLinkDialog()"
              >
                {{ value ? "Desvincular Jira" : "Vincular Jira" }}
              </v-btn>
            </div>
          </template>
          <span v-if="disabled">
            Para vincular ao jira é necessário salvar a iniciativa.
          </span>
          <span>
            Clique para vincular
          </span>
        </v-tooltip>
      </template>
    </v-text-field>
    <confirmation-dialog
      v-model="dialog.showUnlink"
      title="Desvincular do Jira"
      message="Você tem certeza que deseja desvincular?"
      @denied="dialog.showUnlink = false"
      @confirmed="onUnlink"
    />
    <confirmation-dialog
      v-model="dialog.showLink"
      title="Vincular Jira"
      message="Selecione a Iniciativa do Jira"
      @denied="onCancel"
    >
      <template #form>
        <v-form ref="idJira">
          <single-select
            v-model="jira"
            label="Iniciativas do Jira"
            :items="options.idJiraList"
            item-text="summary"
            item-value="idJira"
            return-object
            :loading="loading.idJiraList"
            no-data-text="Problema na conexão com o Jira"
            placeholder="Selecione a iniciativa"
            :rules="[
              (val) =>
                !!val ||
                'Selecione uma Iniciativa do Jira para fazer o vínculo',
            ]"
          />
        </v-form>
      </template>
      <template #actions>
        <v-btn
          color="primary"
          depressed
          :loading="loading.idJiraList"
          @click="onSave"
        >
          Salvar
        </v-btn>
      </template>
    </confirmation-dialog>
  </label>
</template>

<script>
import ConfirmationDialog from "@/components/common/VvConfirmationDialog.vue";
import SingleSelect from "@/components/common/VvSingleSelect.vue";

import ProjectService from "../services/projectService";
const { loadJiraIds, linkJiraId, unlinkJiraId } = ProjectService();

export default {
  name: "IdJira",
  components: {
    ConfirmationDialog,
    SingleSelect,
  },
  props: ["value", "project", "disabled"],
  data: () => ({
    jira: null,
    dialog: {
      showUnlink: false,
      showLink: false,
    },
    options: {
      idJiraList: [],
    },
    loading: {
      idJiraList: false,
    },
  }),
  computed: {
    idJira: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async showLinkDialog() {
      this.dialog.showLink = true;
      this.loadJiraIds();
    },
    async loadJiraIds() {
      if (this.options.idJiraList.length > 0) return;
      try {
        this.loading.idJiraList = true;
        const result = await loadJiraIds(this.project);
        this.options.idJiraList = result;
      } catch (error) {
        this.$showError(error);
      } finally {
        this.loading.idJiraList = false;
      }
    },
    async onUnlink() {
      this.$showLoading();
      try {
        const { message } = await unlinkJiraId(this.project);
        this.$showSuccess(message);
        this.jira = null;
        this.idJira = "";
        this.$refs.idJira?.resetValidation();
        this.dialog.showUnlink = false;
      } catch (error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    },
    async onSave() {
      const { idJira: form } = this.$refs;
      const isValid = form.validate();
      if (!isValid) return;
      form.resetValidation();
      this.dialog.showLink = false;
      this.$showLoading();
      try {
        const { idJira, summary } = this.jira;
        const { message } = await linkJiraId(this.project, { idJira, summary });
        this.$showSuccess(message);
        this.idJira = idJira+' - '+summary;
      } catch (error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    },
    onCancel() {
      this.$refs.idJira.resetValidation();
      this.idJira = "";
    },
  },
};
</script>

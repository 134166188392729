<template>
  <expand
    title="Dados da Iniciativa"
    inside-class="grid-12 md:grid-2 sm:grid-1 gap-md items-end"
    v-model="expanded"
  >
    <single-select
      v-model="project.ideaId"
      label="ID ideia"
      :items="options.ideas"
      no-data-text="Não existe ideias disponíveis no momento"
      item-text="title"
      label-value
      class="g-col-2"
      :disabled="disabled"
      :error-messages="getError('ideaId')"
      @input="clearError('ideaId')"
    />
    <disabled-input v-model="project.id" label="ID Iniciativa" />
    <text-input
      v-model="project.name"
      label="Nome da Iniciativa"
      class="g-col-5 md:g-col-2"
      maxlength="255"
      :disabled="disabled"
      :error-messages="getError('name')"
      @input="clearError('name')"
    />
    <id-jira
      v-model="project.idJira"
      :project="value"
      class="g-col-4 md:g-col-2"
      :disabled="!isEditing || disabled"
      :error-messages="getError('idJira')"
      @input="clearError('idJira')"
    />
    <single-select
      v-model="project.projectType"
      label="Tipo da Iniciativa"
      :items="options.projectTypes"
      no-data-text="Não tem opções disponíveis no momento"
      class="g-col-4"
      :disabled="disabled"
      :error-messages="getError('projectType')"
      @input="clearError('projectType')"
    />
    <multiple-select
      v-model="project.specialProject"
      label="Iniciativa Especial"
      :items="options.specialProjects"
      :to-disable-all="CONST_PROJECT_ITEM_DISABLE_OTHERS.SPECIAL_PROJECT"
      no-data-text="Não tem opções disponíveis no momento"
      class="g-col-4"
      :disabled="disabled"
      :error-messages="getError('specialProject')"
      @input="clearError('specialProject')"
    />
    <single-select
      v-model="project.areasId"
      label="Área Responsável"
      :items="options.responsibleAreas"
      no-data-text="Não tem opções disponíveis no momento"
      class="g-col-4"
      :disabled="disabled"
      :error-messages="getError('areasId')"
      @input="
        project.focalPoints = [];
        clearError('areasId');
      "
    />
    <single-select
      v-model="project.stakeholdersId"
      label="Responsável pelo Negócio/Stakeholder Negócio"
      :items="stakeholders"
      no-data-text="Selecione uma opção em Área Responsável"
      class="g-col-4"
      :disabled="disabled"
      :error-messages="getError('stakeholdersId')"
      @input="
        project.focalPoints = [];
        clearError('stakeholdersId');
      "
    />
    <multiple-select
      v-model="project.focalPoints"
      label="Pontos Focais da Área Responsável"
      :items="focalPoints"
      no-data-text="Selecione uma Opção em Responsável pelo Negócio / Steakholder Negócio"
      class="g-col-4"
      :disabled="disabled"
      :error-messages="getError('focalPoints')"
      @input="clearError('focalPoints')"
    />
    <multiple-select
      v-model="project.impactedAreas"
      label="Outras Áreas Envolvidas/Impactadas"
      :items="options.otherInvolvedAreas"
      no-data-text="Não tem opções disponíveis no momento"
      :to-disable-all="CONST_DEFAULT_OPTIONS_ITENS.AREA"
      class="g-col-4"
      :disabled="disabled"
      :error-messages="getError('impactedAreas')"
      :auto-order="false"
      @input="clearError('impactedAreas')"
    />
    <textarea-input
      v-model="project.problemSolved"
      label="Problema a ser Resolvido/Oportunidade identificada"
      class="g-col-6"
      :disabled="disabled"
      :error-messages="getError('problemSolved')"
      @input="clearError('problemSolved')"
    />
    <textarea-input
      v-model="project.objective"
      label="Objetivo da Iniciativa"
      class="g-col-6"
      :disabled="disabled"
      :error-messages="getError('objective')"
      @input="clearError('objective')"
    />
    <textarea-input 
      v-model="project.scope" 
      label="Escopo" 
      class="g-col-6"
      :error-messages="getError('scope')"
      :disabled="disabled"
    />
    <textarea-input
      v-model="project.premises"
      label="Premissas desta Iniciativa"
      class="g-col-6"
      :disabled="disabled"
      :error-messages="getError('premises')"
      @input="clearError('premises')"
    />
    <switch-input
      v-model="project.processChange"
      label="Envolve Mudança de Processo"
      class="g-col-4"
      :disabled="disabled"
      :error-messages="getError('processChange')"
      @input="clearError('processChange')"
    />
    <label for="envolve-ti" class="g-col-4">
      Envolve TI
      <v-tooltip right max-width="250" >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-icon small>mdi-information-outline</v-icon>
          </span>
        </template>
        <span>
          Caso não envolva TI, não será possivel criar um épico de Tecnologia.
        </span>
      </v-tooltip>
      <multiple-select
        v-model="project.itArea"
        :items="options.itAreas"
        :to-disable-all="CONST_PROJECT_ITEM_DISABLE_OTHERS.IT_AREAS"
        no-data-text="Não tem opções disponíveis no momento"
        :disabled="disabled"
        :error-messages="getError('itArea')"
        @input="clearError('itArea')"
      />
    </label>
    <single-select
      v-model="project.strategicIndicatorsId"
      label="Principal Direcionador Estratégico"
      :items="options.strategicIndicators"
      :to-disable-all="CONST_PROJECT_ITEM_DISABLE_OTHERS.STRATEGIC_INDICATORS"
      no-data-text="Não tem opções disponíveis no momento"
      class="g-col-4"
      :disabled="disabled"
      :error-messages="getError('strategicIndicatorsId')"
      @input="clearError('strategicIndicatorsId')"
    />
  </expand>
</template>

<script>
import Expand from "../../../components/common/VvExpand.vue";
import TextInput from "@/components/common/VvTextInput.vue";
import TextareaInput from "@/components/common/VvTextareaInput.vue";
import DisabledInput from "@/components/common/VvDisabledInput.vue";
import SingleSelect from "@/components/common/VvSingleSelect.vue";
import MultipleSelect from "@/components/common/VvMultipleSelect.vue";
import SwitchInput from "@/components/common/VvSwitch.vue";
import IdJira from "./IdJira.vue";
import ErrorMessageHandler from "../mixins/ErrorMessageHandler";

export default {
  name: "ProjectData",
  components: {
    Expand,
    TextInput,
    TextareaInput,
    DisabledInput,
    SingleSelect,
    MultipleSelect,
    SwitchInput,
    IdJira,
  },
  mixins: [ErrorMessageHandler],
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    errorList: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    }
  },
  data: () => ({
    expanded: true,
  }),
  computed: {
    project: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    stakeholders() {
      const { areasId } = this.value;
      return (
        this.options.responsibleAreas.find(({ id }) => id === areasId)
          ?.stakeholders_id || []
      );
    },
    focalPoints() {
      const { stakeholdersId } = this.value;
      return (
        this.stakeholders?.find(({ id }) => id === stakeholdersId)
          ?.focalPoints || []
      );
    },
  },
  created() {
    const {
      query: {
        ideia
      }
    } = this.$route;
    const ideaId = parseInt(ideia)
    if(!isNaN(ideaId)) this.project.ideaId = ideaId;
  }
};
</script>

<style>
.items-end {
  align-items: end;
}
</style>

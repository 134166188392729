<template>
  <v-container>
    <vv-loading :is-loading="isLoading"></vv-loading>

    <header-view :url-create="urlCreate"></header-view>

    <vv-carousel
      class="mt-4"
      item-title="name"
      item-subtitle="objective"
      v-if="campaignList.length > 0"
      v-model="campaignList"
      @click="({id}) => $router.push({path: `/ideia/criar/campanha/${id}`})"
    />

    <vv-filter
      :filter-name="filter.name"
      :fields="filter.fields"
      export-api="idea"
      @filter="onFilter"
      @reset="reset"
    />

    <div class="ideias-vision-card">
      <h2 class="title-form">Listagem ideias</h2>

      <v-data-table
        sort-by="id"
        :headers="headers"
        :items="ideas"
        :search="search"
        :mobile-breakpoint="0"
        :header-props="headerProps"
        :footer-props="footerProps"
        class="cursor icon-down"
        @click:row="rowClicked"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        :page.sync="currentPage"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>

        <template #item.likes="{ item, index }">
          <div @click.stop="onLike(item.likes, index)">
            <v-icon
              :color="item.likes.userLiked ? 'red' : 'grey'"
              class="mr-1"
            >
              {{ item.likes.userLiked ? 'mdi-cards-heart' : 'mdi-cards-heart-outline' }}
            </v-icon>
            {{ item.likes.count }}
          </div>
        </template>

        <template #item.score="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                {{ item.score }}
              </div>
            </template>
            <div>
              <div 
                style="border: 1px solid #ddd; border-radius: 8px; overflow: hidden;"
                class="my-2"
                v-for="classification in item.classifications"
                :key="classification.id">
                <div 
                  style="color: #222; background: #ddd"
                  class="px-2 py-1"
                >
                  {{ classification.name }}
                </div>
                <div 
                  class="px-2 py-1"
                >
                  {{ classification.criteria.name }} - Pontos: {{ classification.point }}
                </div>
              </div>
            </div>
          </v-tooltip>
        </template>

        <template #item.weightedScore="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                {{ item.weightedScore }}
              </div>
            </template>
            <div>
              <div 
                style="border: 1px solid #ddd; border-radius: 8px; overflow: hidden;"
                class="my-2"
                v-for="classification in item.classifications"
                :key="classification.id">
                <div 
                  style="color: #222; background: #ddd"
                  class="px-2 py-1"
                >
                  {{ classification.name }}
                </div>
                <div 
                  class="px-2 py-1"
                >
                  {{ classification.criteria.name }} - Pontos: {{ classification.weighted_points }}
                </div>
              </div>
            </div>
          </v-tooltip>
        </template>

        <template v-slot:no-data>
          <v-btn
            class="ma-2"
            outlined
            color="primary"
            :loading="isLoading"
            @click="list(payload)"
          >
            Recarregar
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <vv-alert
      v-model="alert.show"
      :message="alert.message"
      :type="alert.type"
      :dismissible="alert.dismissible"
    />

    <vv-confirmation-dialog
      v-model="dialog.showRedirectTapEpic"
      title="Redirecionamento para cadastro"
      message="Selecione a iniciativa em que o épico deve ser criado"
    >
      <template v-if="dialog.isEpic" #form>
        <v-col sm="12" md="12" cols="12">
          <label>Iniciativas</label>
          <v-autocomplete
              v-model="redirectProjectId"
              :items="listOptions.projects"
              item-text="name"
              item-value="id"
          >
          </v-autocomplete>
        </v-col>
      </template>
      <template #actions>
        <v-btn 
          color="primary" 
          depressed 
          @click="redirectEpic()"
        >
          Criar entregável
        </v-btn>
        <v-btn
          color="primary"
          depressed
          v-if="!dialog.isEpic"
          @click="$router.push(`/projeto/criar/?ideia=${item.id}`)"
        >
          Criar Iniciativa
        </v-btn>
      </template>
    </vv-confirmation-dialog>
    <v-dialog v-model="modalInterativo" width="800" :loading="isLoading">
      <v-card>
        <v-card-title class="my-2 mx-0 px-10 font-weight-bold"> Opções da ideia </v-card-title>
        <div v-if="fullLoad">
          <div class="mx-10 mt-2 mb-6">
            <v-row class="tap-epic">
              <v-col sm="6" md="6" cols="6">
                <label>Iniciativa</label>
                <v-autocomplete
                    v-model="item.projectId"
                    :items="listOptions.projects"
                    :disabled="isEditingLink || !isItApproved"
                    no-data-text="Selecione uma iniciativa existente"
                    item-text="name"
                    item-value="id"
                    @change="changeEpic(true)"
                >
                  <template v-slot:selection="{ attrs, item, selected }">
                    <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            label
                    >
                      {{ item.id }} - {{ item.name }}
                    </v-chip>
                  </template>
                  <template #item="{item}">
                    <v-list-item-content>
                      {{ item.id }} - {{ item.name }}
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col sm="6" md="6" cols="6">
                <label>Épico</label>
                <v-autocomplete
                    v-model="item.epicId"
                    :items="listOptions.epics"
                    :disabled="isEditingLink || !isItApproved"
                    :no-data-text="item.projectId === null ? `Selecione uma iniciativa` : `Selecione um épico existente`"
                    item-text="name"
                    item-value="id"
                >
                  <template v-slot:selection="{ attrs, item, selected }">
                    <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            label
                    >
                      {{ inputEpicIdJiraAndEpic(item.id_jira, item.name) }} 
                    </v-chip>
                  </template>
                  <template #item="{item}">
                    <v-list-item-content>
                      {{ inputEpicIdJiraAndEpic(item.id_jira, item.name) }} 
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" cols="12">
                <vv-multiple-select
                  label="Lista de dores"
                  v-model="item.problems"
                  :items="listOptions.problems"
                  item-text="title"
                  item-value="id"
                  :disabled="isEditingLink"
                  no-data-text="Selecione uma dor existente"
                >
                  <template #chip="scope">
                    {{ scope.id }} - {{ scope.problem_title }}
                  </template>
                  <template #cell="scope">
                    <vv-problem-cell :value="scope"/>
                  </template>
                </vv-multiple-select>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="openDialogRedirectTapOrEpic" :loading="isLoading" v-if="checkIdeaHasEpicOrProject"> Criar iniciativa / épico </v-btn>
          <v-btn color="primary" @click="goToIdea" :loading="isLoading"> Acessar registro </v-btn>
          <v-btn color="primary" @click="openModalLikes" :loading="isLoading"> Ver curtidores </v-btn>
          <v-btn color="primary" v-if="isEditingLink" @click="releaseButton" :loading="isLoading"> Editar vínculos </v-btn>
          <v-btn color="primary" v-else @click="linkProblemsToTheIdea" :loading="isLoading"> Salvar vínculo </v-btn>
          <v-btn color="primary" @click="closeModal" :loading="isLoading"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalLikes" width="800" :loading="isLoading">
      <v-card>
        <v-card-title class="font-weight-bold">Lista de usuários que curtiram a ideia </v-card-title>
        <div v-if="fullLoad">
          <div v-if="listUserslikeIdea.length > 0"> 
            <h4 class="my-2 mx-0 px-10 font-weight-medium">Usuários curtidores</h4>
            <div class="mx-10 mt-2 mb-6 scroll" 
              style="border: 1px solid #ddd; border-radius: 8px;" >

              <div class="pa-2" :style="{borderTop: id > 0 ? '1px solid #ddd' : ''}" v-for="(item, id) in listUserslikeIdea" :key="item.id">
                <div>
                  <v-chip
                    class="mr-2"
                  >
                    <span  class="font-weight-bold mr-1">Id </span>{{ item.id }}
                  </v-chip>
                  <span  class="font-weight-bold mr-1"> </span>{{ item.user_name }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="pt-4 pb-6 px-10">
            Nenhum usuário curtiu a ideia
          </div>
        </div>
        <v-divider></v-divider>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeModalLikes" :loading="isLoading"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<style lang="scss" scoped>
  .ideias-vision-card {
    background-color: white;
    padding: 24px 30px;
    border-radius: 6px;
    margin-top: 20px;

    h2 {
      color: #5d5d5d;
      font-size: 18px;
      font-weight: bold;
    }

    .title_colunm {
      font-weight: bold !important;
      text-align: center !important;
    }
  }

  .scroll {
    overflow-y: auto;
    max-height: 400px;
  }

  .tap-epic {
    margin-bottom: -20px;
  }
</style>

<script>
import ideaServiceFactory from "@/services/ideaService";
import campaignServiceFactory from "@/services/campaignService";
import HeaderView from "@/components/HeaderView";
import VvLoading from "@/components/VvLoading";
import VvFilter from "@/components/VvFilter";
import VvCarousel from "@/components/common/VvCarousel";
import VvAlert from "@/components/common/VvAlert";
import VvMultipleSelect from "@/components/common/VvMultipleSelect";
import VvProblemCell from "@/components/problems/VvProblemCell";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog.vue";

const ideaService = ideaServiceFactory();
const campaignService = campaignServiceFactory();

export default {
  components: {
    HeaderView,
    VvAlert,
    VvLoading,
    VvFilter,
    VvCarousel,
    VvMultipleSelect,
    VvProblemCell,
    VvConfirmationDialog
  },
  data: () => ({
    isItApproved: false,
    listOptions: {
      impacteprojectTypesdAreas: [],
      targetAudiences: [],
      estimatedDurations: [],
      mainStrategicDirected: [],
      areas: [],
      criteria: [],
      problems: [],
      projects: [],
      epics: []
    },
    item: {},
    redirectProjectId: null,
    projectId: null,
    epicId: null,
    checkIdeaHasEpicOrProject: Boolean,
    campaignList: [],
    modalInterativo: false,
    fullLoad: false,
    listProblemsLinkedIdea: [],
    listUserslikeIdea: [],
    listIdeaLinks: [],
    modalLikes: false,
    isEditingLink: true,
    alert: { 
      show: false,
      message: '',
      type: 'info',
      dismissible: false
    },
    dialog: {
      showRedirectTapEpic: false,
      isEpic: false
    },
    payload: {},
    isLoading: true,
    urlCreate: "/ideia/criar",
    search: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    footerProps: {
      "items-per-page-text": "Linhas por página",
      "items-per-page-options": [5, 10, 15, 30, 50, 100],
    },
    options: {
      itemsPerPage: 15,
    },
    serverItemsLength: 0,
    headers: [
      { text: "ID Ideia", value: "id", align: "center", class: "cabecalho" },
      { text: "ID Dor", value: "problems", align: "center", class: "cabecalho" },
      { text: "ID Iniciativa", value: "projectId", align: "center", class: "cabecalho" },
      { text: "ID Épico", value: "epicId", align: "center", class: "cabecalho" },
      {
        text: "Ideia",
        value: "title",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Campanha",
        value: "campaignName",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Status workflow",
        value: "statusWorkflow",
        class: "cabecalho",
        align: "center"
      },
      { text: "Área responsável", value: "responsibleArea", align: "center", class: "cabecalho" },
      {
        text: "Pontuação",
        value: "score",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Pontuação ponderada",
        value: "weightedScore",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Curtidas",
        value: "likes",
        align: "center",
        class: "cabecalho",
      }
    ],
    ideas: [],
    objectIndex: -1,
    objectItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
    filter: {
      fields: [],
      name: "idea"
    },
    currentPage: 1,
  }),

  watch: {
    options: {
      handler() {
        this.fetchDatatable();
      },
      deep: true,
    },
  },

  async created() {
    const allCampaignActive = 0;
    const campaignList = await campaignService.list();
    this.campaignList = campaignList;
    const criteriaList = await ideaService.listCriteria();
    await this.loadLists(allCampaignActive); 
    const loadListAreas = this.listOptions.areas;
    this.filter.fields = [
        {
          type: "number",
          label: "ID Ideia",
          sm: "3",
          id: "idea_id",
          name: "idea_id",
          payloadBackend: "id[eq]",
          items: [],
        },
        {
          type: "number",
          label: "ID Dor",
          sm: "3",
          id: "problems",
          name: "problems",
          payloadBackend: "problems[like]",
          items: [],
        },
        {
          type: "number",
          label: "ID Iniciativa",
          sm: "3",
          id: "project_id",
          name: "project_id",
          payloadBackend: "project_id[eq]",
          items: [],
        },
        {
          type: "number",
          label: "ID Épico",
          sm: "3",
          id: "epic_id",
          name: "epic_id",
          payloadBackend: "epic_id[eq]",
          items: [],
        },
        {
          type: "text",
          label: "Ideia",
          sm: "4",
          id: "title",
          name: "title",
          payloadBackend: "title[like]",
          items: [],
        },
        {
          type: "multiautocomplete",
          label: "Campanha",
          sm: "4",
          id: "id",
          name: "name",
          payloadBackend: "campaign_id[or]",
          items: campaignList,
        },
        {
          type: "multiautocomplete",
          label: "Status Workflow",
          sm: "4",
          disabled: true,
          id: "status_id",
          name: "status_id",
          payloadBackend: "status_id[or]",
          items: [],
        },
        {
          type: "multiautocomplete",
          label: "Área responsável",
          sm: "6",
          id: "responsible_area_id",
          name: "responsible_area_id",
          payloadBackend: "responsible_area_id[or]",
          items: loadListAreas,
        },
        {
          type: "text",
          label: "Pontuação",
          sm: "6",
          id: "points",
          name: "points",
          payloadBackend: "points[like]",
          items: [],
        },
        {
          type: "sliders",
          label: "Ponderação de Critérios",
          id: "id",
          name: "name",
          payloadBackend: "classifications",
          items: criteriaList,
        }
      ]
  },

  methods: {
    async loadLists(id) {
      try {
        const result = await ideaService.loadLists(id);
        this.listOptions = {
          ...this.listOptions,
          ...result
        };
        this.isLoading = false;
        this.createFilterFields();
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      }
    },

    async loadListProjects(projectId) {
      this.listOptions.projects = [];
      try {
        const result = await ideaService.listProjects(projectId === null ? 0 : projectId);
        this.listOptions.projects = result;
        this.isLoading = false;
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      }
    },

    async list(payload) {
      this.isLoading = true;
      try {
        const { data, total } = await ideaService.list(payload)
        this.ideas = data
        this.serverItemsLength = total
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.isLoading = false;
      }
    },

    async listLinkedProblems(id) {
      this.isLoading = true;
      try {
        const result = await ideaService.listLinkedProblems(id);
        const { problems_linked_to_the_idea } = result

        this.item.problems = [...problems_linked_to_the_idea.map(({ id }) => id)]
        this.listProblemsLinkedIdea = result.problems_linked_to_the_idea
        this.isLoading = false;
      } catch ({message}) {
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.fullLoad = true
      }
    },

    async listLinkedTapEpicToIdea(id) {
      this.isLoading = true;
      try {
        const result = await ideaService.listIdeaLinks(id);
        this.listIdeaLinks = result.project_epic_linked_to_idea
        this.projectId = this.listIdeaLinks.projectId
        this.item.projectId = this.projectId
        
        const projectId = this.item.projectId === null ? this.listIdeaLinks.projectId : this.item.projectId;
        
        if(this.item.projectId !== null || this.listIdeaLinks.projectId !== null){
          this.changeEpic(false)
        }
        
        this.loadListProjects(projectId);
        this.isLoading = false;
      } catch ({message}) {
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.fullLoad = true
      }
    },

    async listWholikedIdea() {
      this.isLoading = true;
      this.fullLoad = false;
      try {
        const result = await ideaService.listWholikedIdeia(this.item.id);
        this.listUserslikeIdea = result.users_like_idea_list
        this.fullLoad = true
      } catch ({message}) {
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.isLoading = false;
        this.fullLoad = true
      }
    },

    async linkProblemsToTheIdea() {
      this.isLoading = true;
      const { id, problems, projectId, epicId } = this.item;
      
      try {
        if(epicId === null && projectId !== null){
          await ideaService.linkProject(id, projectId);
        }
        if(epicId !== null){
          await ideaService.linkEpic(id, epicId);
        }
        await ideaService.linkProblems(id, problems);
        
        this.listLinkedProblems(id);
        this.list(this.payload); 
        this.isEditingLink = true;
        this.alert = {
          show: true,
          message: 'Vínculos salvos com sucesso',
          type: "success",
          dismissible: true,
        };
        
      } catch (error) {
        const {
          response: {
            data: {
              message,
            }
          }
        } = error
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.isLoading = false;
      }
    },

    async onLike(likes, index) {
      const id = this.ideas[index].id
      const like = await ideaService.like(id)
      
      if(like?.status) {
        likes.count += 1
        likes.userLiked = true
      }
      else {
        likes.count -= 1
        likes.userLiked = false
      }
    },

    editItem(item) {
      this.$router.push(`/ideia/${item.id}`);
    },

    releaseButton() {
      this.isEditingLink = false;
    },

    rowClicked(item) {
      const projectId = 0;
      this.modalInterativo = true
      this.item = {...item}
      this.listLinkedProblems(item.id);
      this.loadListProjects(projectId);
      this.checkIdeaHasEpicOrProject = this.verifyIdeaHasEpicOrProject(item.id);
      this.listLinkedTapEpicToIdea(item.id);
      this.item.status_workflow_id === this.CONST_WORKFLOW_IDEA.APROVADO_COMITE ? this.isItApproved = true : this.isItApproved = false;
    },

    createFilterFields() {
      let fieldsStore = this.getGlobalFilters(this.filter.name);
      if (fieldsStore !== undefined) {
        this.filter.fields = fieldsStore;

        let payload = {};

        this.filter.fields.map((field) => {
          if (field.value) {
            payload[field.payloadBackend] = field.value;
          }
        });
        if (Object.keys(payload).length) {
          payload.page = 1;
          this.onFilter(payload);
        }
      }

    },

    onFilter(payload) {
      this.payload = payload;
      this.setPagination(payload.page);
      this.list(payload);
    },

    onConfirmation() {
      if(!this.isEditing) {
        this.dialog.show = true;
        return;
      }
    },

    openModalProblems() {
      this.modalVincular = true;
    },

    openModalLikes() {
      this.modalLikes = true;
      this.listWholikedIdea();
    },

    goToIdea() {
      this.$router.push(`/ideia/${this.item.id}`);
    },

    reset() {
      this.payload = {};
      this.setPagination();
      this.list(this.payload);
    },

    fetchDatatable() {
      this.setPagination(this.payload.page);
      this.list(this.payload);
    },

    closeModal() {
      this.modalInterativo = false;
      this.listIdeasLinkedProblem = [];
      this.fullLoad = false;
      this.isEditingLink = true;
      this.isEditingLink = true;
      this.listOptions.projects = [];
      this.listOptions.epics = [];
      this.projectId = null;
      this.epicId = null;
    },

    closeModalLikes() {
      this.modalLikes = false;
      this.listUserslikeIdea = [];
    },

    setPagination() {
      this.payload["page"] = this.options.page;
      this.payload["page_size"] = this.options.itemsPerPage;
      this.payload["sortBy"] = this.options.sortBy[0];
      this.payload["sortDesc"] = 0;
      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.payload["sortDesc"] = this.options.sortDesc[0] ? 1 : 0;
      }
    },

    async changeEpic(isUptadeEpic) {
      this.isLoading = true;
      this.listOptions.epics = [];
      if(isUptadeEpic === true){
        this.item.epicId = null;
      }
      try {
        const project = this.item.projectId === null ? this.listIdeaLinks.projectId : this.item.projectId;
        const epic = this.item.epicId === null ? 0 : this.item.epicId;
        
        const listEpics = await ideaService.listEpisByProject(project, epic);
        this.listOptions.epics = listEpics;
        this.isLoading = false;
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.isLoading = false;
      }
      
    },

    openDialogRedirectTapOrEpic() {
      this.dialog.isEpic = false;
      this.dialog.showRedirectTapEpic = true;
    },

    verifyIdeaHasEpicOrProject(id) {
      const idea = this.ideas.find((idea) => idea.id === id);
      const {epicId, projectId, status_workflow_id} = idea || {};
      return (epicId === null && projectId === null && status_workflow_id === this.CONST_WORKFLOW_IDEA.APROVADO_COMITE);
    },

    redirectEpic() {
        this.dialog.isEpic = true
        if(this.redirectProjectId){
            this.$router.push(`/projeto/${this.redirectProjectId}/epicos?ideia=${this.item.id}`)
        }
    },

    inputEpicIdJiraAndEpic(idJira, nameEpic) {
      return idJira !== "" ? idJira + ' - ' + nameEpic : nameEpic;
    },
  },
};
</script>

<template>
  <v-container>

    <tap></tap>

  </v-container>
</template>

<script>
import Tap from "@/components/forms/Tap";

export default {
  components: {Tap},
  data: () => ({}),
}
</script>

<style>
</style>

<template>
  <div class="workflow-details">
    <v-btn color="primary" class="mt-sm-5" outlined @click="load">
      Histórico Workflow <v-icon class="ml-1"> mdi-history </v-icon>
    </v-btn>

    <modal v-model="dialog.workflow" title="workflow" :width="700">
      <div v-if="hasWorkflow">
        <div>Status</div>
        <h3 class="mb-4 blue-grey--text text--darken-3">{{ workflow.name }}</h3>
        <div>Atualizado por:</div>
        <h3 class="mb-4 blue-grey--text text--darken-3">
          <span
            :style="{
              'font-style': hasOriginalUser ? 'italic' : 'normal',
            }"
          >
            {{ workflow.user }}
            <v-tooltip v-if="hasOriginalUser" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on" class="ml-1">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                Aprovação delegada por:
                {{ workflow.user_original_name }}
              </span>
            </v-tooltip>
          </span>
          <span class="font-weight-light">
            - {{ workflow.date.replaceAll("-", "/") }}
          </span>
        </h3>

        <v-divider v-if="hasMessages" />

        <div v-if="hasMessages" class="mt-4">
          <div
            v-for="(message, id) in workflow.messages"
            :key="message.id"
            class="mb-4 blue-grey--text text--darken-3"
            :style="{
              'border-bottom':
                id !== workflow.messages.length - 1
                  ? '1px solid rgba(0, 0, 0, 0.2)'
                  : 'none',
            }"
          >
            <div
              class="grey lighten-5 mx-auto px-6 py-3 mb-3"
              style="font-size: var(--font-size-p); border-left: 2px solid #ccd7dd !important;"
            >
              <span class="font-weight-bold blue-grey--text text--darken-4">
                {{ message.userName }}
              </span>
              perguntou em
              <span>{{ message.updatedAt }}</span>
              <div class="mt-2">{{ message.message }}</div>
            </div>
            <template v-if="message.answer.length > 0">
              <div
                v-for="answer in message.answer"
                :key="answer.id"
                class="grey lighten-5 mx-auto ml-10 px-6 py-3 mb-3"
                style="font-size: var(--font-size-p); border-left: 2px solid #ccd7dd !important"
              >
                <span class="font-weight-bold blue-grey--text text--darken-4">
                  {{ answer.userName }}
                </span>
                perguntou em
                <span>{{ answer.updatedAt }}</span>
                <div class="mt-2">{{ answer.message }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <v-progress-linear v-else indeterminate color="primary" />
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/common/VvModal.vue";

import ProjectService from "../services/projectService";
const { loadWorkflow } = ProjectService();

export default {
  name: "HistoyWorkflow",
  components: {
    Modal,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    dialog: {
      workflow: false,
    },
    workflow: {},
  }),
  methods: {
    async load() {
      this.dialog.workflow = true;
      if (this.hasWorkflow) return;
      try {
        const result = await loadWorkflow(this.value);
        this.workflow = result;
      } catch (error) {
        this.$showError(error);
      }
    },
  },
  computed: {
    hasWorkflow() {
      return Object.keys(this.workflow).length > 0;
    },
    hasMessages() {
      const { messages } = this.workflow;
      return this.hasWorkflow && messages && messages.length > 0;
    },
    hasOriginalUser() {
      return !!this.workflow.user_original_name;
    },
  },
};
</script>

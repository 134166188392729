<template>
  <div id="formCR">
    <vv-loading :is-loading="isLoading"></vv-loading>
    <div class="grid-3 gap-md">
      <div class="g-col-3">
        <label>Motivo</label>
        <v-textarea
            counter
            :disabled="canEdit(false)"
            v-model="form.values.description"
            class="scroll-style"
            :error-messages="form.message.description"
            :rules="[
                val => !!val || 'Campo obrigatório',
                val => (!!val && val.length <= 255 ) || 'Limite de caracteres de 255 excedido'
              ]"
        ></v-textarea>
      </div>
      <div>
        <label>Origem da solicitação</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            :items="form.options.origin"
            v-model="form.values.originId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :error-messages="form.message.originId"
            :rules="[
                val => !!val || 'Campo obrigatório'
              ]"
        ></v-autocomplete>
      </div>
      <div>
        <label>Classificação</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            :items="form.options.classification"
            v-model="form.values.classificationId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            @change="changeClassifications()"
            :error-messages="form.message.classificationId"
            :rules="[
                val => !!val || 'Campo obrigatório'
              ]"
        ></v-autocomplete>
      </div>
      <div>
        <label>Impactos</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            :items="form.options.impact"
            v-model="form.values.impactsId"
            item-text="name"
            item-value="id"
            item-disabled="disabled"
            multiple
            append-icon="mdi-chevron-down"
            @change="changeImpacts()"
            :error-messages="form.message.impactsId"
            :rules="[
                val => !!val || 'Campo obrigatório'
              ]"
        >
          <template #selection="scope">
            <v-chip
                v-bind="scope.attrs"
                :input-value="scope.selected"
                :disabled="canEdit(false)"
                label
                class="chip-green"
            >
              {{ scope.item['name'] }}
            </v-chip>
          </template>
          <template #item="scope">
            <div class="d-flex" style="border-bottom: 1px solid #ddd; width: 100%;">
              <div class="ml-4 d-flex itens-center">
                <v-icon v-if="form.values.impactsId.includes(scope.item['id'])">
                  mdi-checkbox-marked
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-outline
                </v-icon>
              </div>
              <v-list-item-content style="max-width: 480px">
                <v-tooltip right content-class="tooltip-in-option-on-autocomplete">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <template v-slot:default="{ active }">
                        <v-list-item-title :input-value="active">{{ scope.item['name'] }}</v-list-item-title>
                      </template>
                    </v-list-item>
                  </template>
                  <span v-html="scope.item['description']"></span>
                </v-tooltip>
              </v-list-item-content>
            </div>
          </template>
        </v-autocomplete>
      </div>
      <div class="g-col-3" v-if="form.values.classificationId == CONST_CHANGE_REQUEST_CLASSIFICATION.CANCELAMENTO">
        <label>Tipo de cancelamento</label>
        <v-autocomplete
            id="cancelTypes"
            :disabled="canEdit(false)"
            :items="form.options.cancelTypes"
            v-model="form.values.cancelTypeId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :error-messages="form.message.cancelType"
            :rules="[
                val => !!val || 'Campo obrigatório'
              ]"
        ></v-autocomplete>
      </div>
      <div>
        <label>{{item.epicTypeId === CONST_TYPE_EPICS.NEGOCIO ? 'Ponto focal iniciativa': 'Aprovador - Ponto Focal'}}</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            :items="focalPointApprover"
            v-model="form.values.focalPointApprover"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :error-messages="form.message.focalPointApprover"
            :rules="[
                val => !!val || 'Campo obrigatório'
              ]"
        ></v-autocomplete>
      </div>
      <div v-if="item.epicTypeId === CONST_TYPE_EPICS.NEGOCIO">
        <label>Ponto focal épico</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            :items="item.focalPointsOptions"
            v-model="form.values.epicFocalPointId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :error-messages="form.message.epicFocalPointId"
        ></v-autocomplete>
      </div>
      <div class="g-col-1" v-if="form.values.multipleEpics || !this.edit">
        <label>Múltiplos épicos impactados</label>
        <v-switch
            :disabled="canEdit(false)"
            v-model="form.values.multipleEpics"
            color="secondary"
            class="switch manual-line-list-item-icon"
            inset
            :error-messages="form.message.multipleEpics"
        ></v-switch>
      </div>
      <div class="g-col-3" v-if="form.values.multipleEpics">
        <label>Épicos impactados</label>
        <v-autocomplete
            :disabled="canEdit(!form.values.multipleEpics)"
            :items="form.multipleEpicsImpacted.epicImpactedCollection"
            v-model="form.values.multipleEpicsImpacted"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            multiple
            :error-messages="form.message.multipleEpicsImpacted"
        ></v-autocomplete>
      </div>
    </div>
    <div class="grid-3 gap-md" v-if="form.values.classificationId === CONST_CHANGE_REQUEST_CLASSIFICATION.REPRIOZICAO_AREA_RESPONSAVEL">
      <div>
        <label>Iniciativa prioritária</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            v-model="form.values.classificationProjectId"
            :items="form.options.projects"
            :error-messages="form.message.classificationProjectId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            @change="getEpicsOption"
        ></v-autocomplete>
      </div>
      <div>
        <label>Épico prioritário</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            v-model="form.values.classificationEpicId"
            :items="form.options.epics"
            :error-messages="form.message.classificationEpicId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
        ></v-autocomplete>
      </div>
    </div>
    <div class="grid-3 gap-md" v-if="form.values.classificationId === CONST_CHANGE_REQUEST_CLASSIFICATION.REPRIOZICAO_IMPACTO_AREA">
      <div>
        <label>Área envolvida</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            v-model="form.values.classificationAreaId"
            :items="form.options.areas"
            :error-messages="form.message.classificationAreaId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
        ></v-autocomplete>
      </div>
    </div>
    <div class="grid-12 gap-md" v-if="form.values.impactsId.includes(CONST_CHANGE_REQUEST_IMPACT.CUSTO)">
      <h3 class="g-col-12">Custo</h3>
      <div class="g-col-1">
        <label>CAPEX</label>
        <v-switch
            :disabled="canEdit(false)"
            v-model="form.values.capex"
            id="capex"
            name="capex"
            color="secondary"
            class="switch"
            inset
        ></v-switch>
      </div>
      <div class="g-col-4">
        <label>Valor do impacto</label>
        <v-text-field
            :disabled="canEdit(!form.values.capex)"
            v-model="form.values.capexValue"
            :error-messages="form.message.capexValue"
            type="number"
        ></v-text-field>
      </div>
      <div class="g-col-7">
        <label>Descrição dos gastos</label>
        <v-text-field
            :disabled="canEdit(!form.values.capex)"
            v-model="form.values.capexDescription"
            :error-messages="form.message.capexDescription"
        ></v-text-field>
      </div>
      <div class="g-col-12 mt-n7">
        <span class="error-label">{{ (typeof form.message.capex !== 'undefined') ? form.message.capex[0] : '' }}</span>
      </div>
      <div class="g-col-1">
        <label>OPEX</label>
        <v-switch
            :disabled="canEdit(false)"
            v-model="form.values.opex"
            id="opex"
            name="opex"
            color="secondary"
            class="switch"
            inset
        ></v-switch>
      </div>
      <div class="g-col-4">
        <label>Valor do impacto</label>
        <v-text-field
            :disabled="canEdit(!form.values.opex)"
            v-model="form.values.opexValue"
            type="number"
            :error-messages="form.message.opexValue"
        ></v-text-field>
      </div>
      <div class="g-col-7">
        <label>Descrição dos gastos</label>
        <v-text-field
            v-model="form.values.opexDescription"
            :disabled="canEdit(!form.values.opex)"
            :error-messages="form.message.opexDescription"
        ></v-text-field>
      </div>
      <div class="g-col-12 mt-n7">
        <span class="error-label">{{ (typeof form.message.opex !== 'undefined') ? form.message.opex[0] : '' }}</span>
      </div>
    </div>
    <div class="grid-1 gap-md" v-if="form.values.impactsId.includes(CONST_CHANGE_REQUEST_IMPACT.ESCOPO)">
      <h3>Escopo</h3>
      <div>
        <label>Mudança Detalhada</label>
        <v-textarea
            counter
            :disabled="canEdit(false)"
            v-model="form.values.impactDescription"
            class="scroll-style"
            :error-messages="form.message.impactDescription"
        ></v-textarea>
      </div>
    </div>
    <div class="grid-3 gap-md" v-if="form.values.impactsId.includes(CONST_CHANGE_REQUEST_IMPACT.NOVAS_DEPENDENCIAS)">
      <h3 class="g-col-3">Novas dependências</h3>
      <div>
        <label>Área envolvida</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            :items="form.options.areas"
            v-model="form.values.areaId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :error-messages="form.message.areaId"
            @change="getFocalPoint"
        ></v-autocomplete>
      </div>
      <div>
        <label>Ponto focal</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            :items="form.options.focalPoints"
            v-model="form.values.focalPointId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :error-messages="form.message.focalPointId"
        ></v-autocomplete>
      </div>
      <div>
        <label>Jornada</label>
        <v-autocomplete
            :disabled="canEdit(false)"
            :items="form.options.journeys"
            v-model="form.values.journeyId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :error-messages="form.message.journeyId"
        ></v-autocomplete>
      </div>
      <div>
        <label>Entrega de novo épico?</label>
          <v-tooltip right max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-3" color="#DBDCDC" small v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span>
            Se sim, é importante que o épico já tenha sido cadastrado para fazer o vínculo na Change Request
          </span>
        </v-tooltip>
        <v-switch
            :disabled="canEdit(false)"
            v-model="form.values.deliveryNewEpic"
            color="secondary"
            class="switch manual-line-list-item-icon"
            inset
            :error-messages="form.message.deliveryNewEpic"
        ></v-switch>
      </div>
      <div class="g-col-2" v-if="form.values.deliveryNewEpic">
        <label>Novo épico a ser entregue</label>
        <v-autocomplete
            :disabled="canEdit(!form.values.deliveryNewEpic)"
            :items="form.multipleEpicsImpacted.epicImpactedCollection"
            v-model="form.values.newEpicId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :error-messages="form.message.newEpicId"
        ></v-autocomplete>
      </div>
      <div class="g-col-3">
        <label>Detalhe dependência</label>
        <v-textarea
            :disabled="canEdit(false)"
            v-model="form.values.dependencyDescription"
            class="scroll-style"
            :error-messages="form.message.dependencyDescription"
        ></v-textarea>
      </div>
    </div>
    <div class="grid-12 gap-md" v-if="form.values.impactsId.includes(CONST_CHANGE_REQUEST_IMPACT.PRAZO)">
      <h3 class="g-col-12">Prazo</h3>
      <div class="g-col-4">
        <label>Data original</label>
        <v-text-field
            :disabled="true"
            v-model="impactDeadlineOriginal"
            append-icon="mdi-calendar"
        ></v-text-field>
      </div>
      <div class="g-col-3">
        <label>Nova data</label>
        <vv-date-pick
            :disabled="canEdit(false)"
            v-model="form.values.impactDeadline"
            :clear-field="true"
            :using-button="false"
            :min-date="orignalData"
            :error-messages="form.message.impactDeadline"
        ></vv-date-pick>
      </div>
    </div>
    <vv-confirmation-dialog
        :value="confirmationDialog.value"
        :title="confirmationDialog.title"
        :message="confirmationDialog.message"
        @confirmed="saveChangeRequest()"
        @denied="confirmationDialog.value=false"
    ></vv-confirmation-dialog>
    <vv-alert
        v-model="alert.show"
        :message="alert.message"
        :type="alert.type"
        :dismissible="alert.dismissible"
    ></vv-alert>
  </div>
</template>

<script>
import VvDatePick from "@/components/VvDatePick";
import VvLoading from "@/components/VvLoading";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";
import VvAlert from "@/components/common/VvAlert";
import changeRequestServiceFactory from "@/domains/changeRequest/services/changeRequestServices";

const changeRequestService = changeRequestServiceFactory();

export default {
  name: "FormChangeRequest",
  components: {
    VvDatePick,
    VvLoading,
    VvConfirmationDialog,
    VvAlert,
  },
  props: {
    epicId: {
      type: Number,
      required: true,
    },
    orignalData: {
      type: String,
    },
    item: {
      type: Object,
      default: () => ({})
    },
    focalPointApprover: {
      type: Array,
    },
    edit: {
      type: Boolean,
      default: false
    },
    changeRequest: {
      type: Object,
      required: false
    },
    permissions: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    disabled: false,
    isLoading: false,
    form: {
      values: {
        description: null,
        impactsId: [],
        deliveryPlanned: null,
        deliveryBaseline: null,
        message: null,
        deliveryNewEpic: false,
        focalPointApprover: null,
        multipleEpics: false,
        multipleEpicsImpacted: [],
        originId: null,
        classificationId: null,
        impactDescription: null,
        areaId: null,
        focalPointId: null,
        journeyId: null,
        newEpicId: null,
        dependencyDescription: null,
        impactDeadline: null,
        capex: false,
        opex: false,
        capexValue: null,
        capexDescription: null,
        opexValue: null,
        opexDescription: null,
        classificationProjectId: null,
        classificationEpicId: null,
        classificationAreaId: null,
        epicFocalPointId: null,
        cancelTypeId: null,
      },
      options: {
        areas: [],
        focalPoints: [],
        epics: [],
        impact: [],
        cancelTypes: []
      },
      multipleEpicsImpacted: {
        epicActiveCollection: [],
        epicImpactedCollection: [],
      },
      approverOptions: {
        focalPoints: [],
      },
      message: [],
      errors: {},
      rules: {},
    },
    confirmationDialog: {
      value: false,
      sendMessage: false,
      title: 'Submeter aprovação',
      message: 'Você realmente deseja submeter para aprovação?',
    },
    alert: {
      show: false,
      message: '',
      type: 'info',
      dismissible: false,
    },
  }),
  watch: {
    watch_description() { this.form.message.description = []; },
    watch_originId() { this.form.message.originId = []; },
    watch_classificationId() { this.form.message.classificationId = []; },
    watch_impactsId() { this.form.message.impactsId = []; },
    watch_focalPointApprover() { this.form.message.focalPointApprover = []; },
    watch_cancelType() { this.form.message.cancelType = []; },
    watch_multipleEpics() { this.form.message.multipleEpics = []; },
    watch_multipleEpicsImpacted() { this.form.message.multipleEpicsImpacted = []; },
    watch_capex() { this.form.message.capex = []; },
    watch_capexValue() { this.form.message.capexValue = []; },
    watch_capexDescription() { this.form.message.capexDescription = []; },
    watch_opex() { this.form.message.opex = []; },
    watch_opexValue() { this.form.message.opexValue = []; },
    watch_opexDescription() { this.form.message.opexDescription = []; },
    watch_impactDescription() { this.form.message.impactDescription = []; },
    watch_areaId() { this.form.message.areaId = []; },
    watch_focalPointId() { this.form.message.focalPointId = []; },
    watch_journeyId() { this.form.message.journeyId = []; },
    watch_deliveryNewEpic() { this.form.message.deliveryNewEpic = []; },
    watch_newEpicId() { this.form.message.newEpicId = []; },
    watch_dependencyDescription() { this.form.message.dependencyDescription = []; },
    watch_impactDeadline() { this.form.message.impactDeadline = []; },
    watch_classificationProjectId() { this.form.message.classificationProjectId = []; },
    watch_classificationEpicId() { this.form.message.classificationEpicId = []; },
    watch_classificationAreaId() { this.form.message.classificationAreaId = []; },
    watch_focalPointEpic() { this.form.message.focalPointEpic = []; },
    changeRequest() { this.loadCROptions(); },
    edit(value) { return value; },
    permissions(value) { return value; },
  },
  computed: {
    watch_description() { return this.form.values.description },
    watch_originId() { return this.form.values.originId },
    watch_classificationId() { return this.form.values.classificationId },
    watch_impactsId() { return this.form.values.impactsId },
    watch_focalPointApprover() { return this.form.values.focalPointApprover },
    watch_cancelType() { return this.form.values.cancelType },
    watch_multipleEpics() { return this.form.values.multipleEpics },
    watch_multipleEpicsImpacted() { return this.form.values.multipleEpicsImpacted },
    watch_capex() { return this.form.values.capex },
    watch_capexValue() { return this.form.values.capexValue },
    watch_capexDescription() { return this.form.values.capexDescription },
    watch_opex() { return this.form.values.opex },
    watch_opexValue() { return this.form.values.opexValue },
    watch_opexDescription() { return this.form.values.opexDescription },
    watch_impactDescription() { return this.form.values.impactDescription },
    watch_areaId() { return this.form.values.areaId },
    watch_focalPointId() { return this.form.values.focalPointId },
    watch_journeyId() { return this.form.values.journeyId },
    watch_deliveryNewEpic() { return this.form.values.deliveryNewEpic },
    watch_newEpicId() { return this.form.values.newEpicId },
    watch_dependencyDescription() { return this.form.values.dependencyDescription },
    watch_impactDeadline() { return this.form.values.impactDeadline },
    watch_classificationProjectId() { return this.form.values.classificationProjectId },
    watch_classificationEpicId() { return this.form.values.classificationEpicId },
    watch_classificationAreaId() { return this.form.values.classificationAreaId },
    watch_focalPointEpic() { return this.form.values.focalPointEpic },
    impactDeadlineOriginal() {
      if (typeof this.form.values.impactDeadlineOriginal == 'undefined') {
        return this.form.values.deliveryPlanned;
      }
      return this.formattedDateForBr(this.form.values.impactDeadlineOriginal);
    },
  },
  mounted() {
    this.loadCROptions();
  },
  methods: {
    async loadCROptions() {
      try {
        this.isLoading = true;
        const resultOptionsCR = await changeRequestService.loadCROptions();
        resultOptionsCR.options.impact.map((impact, i) => {
          resultOptionsCR.options.impact[i].disabled = !impact.active
        })
        this.form.options = resultOptionsCR.options;
        const resultImpactedEpics = await changeRequestService.loadImpactedEpics(this.$route.params.id, this.epicId);
        this.form.multipleEpicsImpacted = resultImpactedEpics;
        if (this.edit) {
          this.resetFormData();
          this.form.values = this.changeRequest;
          this.getFocalPoint();
        }
        this.form.values.deliveryPlanned = this.formattedDateForBr(this.orignalData);
      } catch (error) {
        this.alert.show = true;
        this.alert.message = error;
        this.alert.type = 'error';
        this.alert.dismissible = false;
      } finally {
        this.isLoading = false;
      }
    },
    getFocalPoint() {
      if (this.form.values.areaId !== null && this.form.options.areas !== null) {
        let areaSelected = this.searchSelectedInOptions(this.form.values.areaId, this.form.options.areas);
        this.form.options.focalPoints = areaSelected[0].focal_points;
      }
    },
    getEpicsOption() {
      let tapSelected = this.searchSelectedInOptions(this.form.values.classificationProjectId, this.form.options.projects);
      this.form.options.epics = tapSelected[0].epics;
    },
    async saveChangeRequest() {
      this.confirmationDialog.value = false;
      this.form.values.deliveryPlanned = this.orignalData;
      this.isLoading = true;
      let resultSaveCR;
      if (this.edit) {
        resultSaveCR = await changeRequestService.editChangeRequest(this.$route.params.id, this.epicId, this.changeRequest.id, this.form.values);
        if (typeof this.confirmationDialog.sendMessage !== 'undefined' && this.confirmationDialog.sendMessage) {
          this.$emit('sendMessage', this.confirmationDialog.messageData);
        }
      } else {
        resultSaveCR = await changeRequestService.createChangeRequest(this.$route.params.id, this.epicId, this.form.values);
      }
      this.form.values.deliveryPlanned = this.formattedDateForBr(this.orignalData);
      if (resultSaveCR.status === 200 || resultSaveCR.status === 202) {
        this.alert = {
          show: true,
          message: resultSaveCR.data.message,
          type: "success",
          dismissible: false,
        };
        setTimeout(() => {
          this.alert.show = false;
          this.$emit('changeRequestSaved');
          this.isLoading = false;
          if (this.edit === false) {
            this.resetFormData();
            this.$router.push(`/projeto/${this.$route.params.id}/epicos/${this.epicId}/change-request/${resultSaveCR.data.changeRequestId}`);
          }
        },3000);
      } else {
        this.form.message = resultSaveCR.data
        let error = typeof resultSaveCR.data.message !== 'undefined' ? resultSaveCR.data.message : 'Preencha todos os campos obrigatórios';
        
        this.alert = {
          show: true,
          message: error,
          type: "error",
          dismissible: false,
        };
        
        setTimeout(() => {
          this.alert.show = false;
          this.isLoading = false;
        },3000);
      }
    },
    resetFormData() {
      this.form.values = {
        description: null,
        impactsId: [],
        deliveryPlanned: null,
        deliveryBaseline: null,
        message: null,
        deliveryNewEpic: false,
        focalPointApprover: null,
        multipleEpics: false,
        multipleEpicsImpacted: [],
        originId: null,
        classificationId: null,
        impactDescription: null,
        areaId: null,
        focalPointId: null,
        journeyId: null,
        newEpicId: null,
        dependencyDescription: null,
        impactDeadline: null,
        capex: false,
        opex: false,
        capexValue: null,
        capexDescription: null,
        opexValue: null,
        opexDescription: null,
        epicFocalPointId: null,
        cancelType: null,
      };
      this.form.values.deliveryBaseline = this.formattedDateForBr(this.orignalData);
    },
    searchSelectedInOptions(id, collection) {
      return collection.filter(obj => {
        return obj.id === id
      });
    },
    canEdit(originalValue) {
      return this.edit ? !this.permissions.CanEditChangeRequest : originalValue
    },
    changeImpacts() {
      let impactsId = this.form.values.impactsId
      if (!impactsId.includes(this.CONST_CHANGE_REQUEST_IMPACT.NOVAS_DEPENDENCIAS)) {
        this.form.values.areaId = null
        this.form.values.focalPointId = null
        this.form.values.journeyId = null
        this.form.values.deliveryNewEpic = false
        this.form.values.newEpicId = null
        this.form.values.dependencyDescription = null
      }

      if (impactsId.includes(this.CONST_CHANGE_REQUEST_IMPACT.CANCELAMENTO)) {
        this.form.values.classificationId = this.CONST_CHANGE_REQUEST_CLASSIFICATION.CANCELAMENTO;
        this.changeClassifications();
      }
    },
    changeClassifications() {
      let disabled = this.form.values.classificationId === this.CONST_CHANGE_REQUEST_CLASSIFICATION.CANCELAMENTO ? true : false;
      this.form.values.impactsId = disabled ? [this.CONST_CHANGE_REQUEST_IMPACT.CANCELAMENTO] : this.form.values.impactsId;
      this.form.options.impact.map((impact, i) => {
        if (impact.id !== this.CONST_CHANGE_REQUEST_IMPACT.CANCELAMENTO) {
          this.form.options.impact[i].disabled = disabled;
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
#formCR {
  h3 {
    font-size: 15px;
    color: #6D6E70;
    &::before {
      content: "\2022";
      margin-right: 5px;
      font-size: 18px;
      position: relative;
      top: 1px;
    }
  }
  .error-label {
    color: #cf1e1e;
    font-size: 12px;
  }
}
.tooltip-in-option-on-autocomplete {
  max-width: 300px;
}
.v-list-item--disabled {
  .v-list-item__content {
    .v-list-item {
      .v-list-item__title {
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }
}
</style>
<template>
  <v-form ref="form">
    <v-card flat>
      <div v-if="isEditing">
        <v-card>
          <v-toolbar class="vv-ht-115" flat color="toolbarColor" height="75px" dark>
            <v-row class="sizeFont">
              <v-col cols="4" md="2">
                <label>ID Ideia</label>
                <h2>{{ idea.id }}</h2>
              </v-col>
              <v-col cols="5" md="6">
                <label>Título ideia</label>
                <h2>{{ idea.title }}</h2>
              </v-col>
            </v-row>
          </v-toolbar>
        </v-card>
      </div>

      <div class="form-container">
        <h2 class="title-form">Cadastro Ideia</h2>
        <v-row>
          <v-col sm="3" md="2" cols="12">   
            <label for="id">ID Ideia</label>
            <div class="edit-disabled" v-if="idea.id">
              <v-chip label>{{ idea.id }}</v-chip>
            </div>
            <div v-else>
              <div class="v-input v-text-field v-text-field--is-booted">
                <div class="v-input__control">
                  <div class="v-input__slot">
                    <div class="v-text-field__slot">
                      <input disabled id="id" name="id" type="text" />
                      <v-icon style="cursor: default">mdi-lock</v-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col sm="3" md="2" cols="12">   
            <label for="project-id">ID Iniciativa</label>
            <v-autocomplete
              v-model="idea.projectId"
              :items="listProjects"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              no-data-text="Selecione uma iniciativa existente"
              :disabled="isNotEditable || !isCreating || !isItApproved"
              class="fixed-label"
              @change="changeEpic()"
            >
              <template #selection="{ item }">
                <v-chip>{{ item.id }}</v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col sm="3" md="2" cols="12">   
            <label for="epic-id">ID Épico</label>
            <v-autocomplete
              v-model="idea.epicId"
              :items="listEpics"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              :no-data-text="idea.projectId === null ? `Selecione uma iniciativa` : `Selecione um épico existente`"
              :disabled="isNotEditable || !isCreating || !isItApproved"
              class="fixed-label"
            >
              <template #selection="{ item }">
                <v-chip>{{ item.id }}</v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          
          <v-col sm="6" md="6" cols="12">
            <vv-multiple-select
              label="ID Dor"
              v-model="idea.problems"
              :items="listOptions.problems"
              item-text="problem_title"
              item-value="id"
              :disabled="isNotEditable || createWithProblem"
            >
              <template #chip="scope">
                {{ scope.id }} - {{ scope.problem_title }}
              </template>
              <template #cell="scope">
                <vv-problem-cell :value="scope"/>
              </template>
            </vv-multiple-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="6" cols="12">
            <label>Título ideia</label>
            <v-text-field 
              v-model="idea.title"
              counter="255"
              :disabled="isNotEditable"
              required
              :rules="[
                val => !!val || 'Campo obrigatório',
                val => (!!val && val.length <= 255 ) || 'Limite de caracteres de 255 excedido'
              ]"
              class="fixed-label"
            />
          </v-col>
          <v-col sm="3" md="3" cols="12">
            <div class="grow">
              <label for="area-negocio">Tipo de Ideia</label>
              <v-autocomplete
                v-model="idea.projectTypeId"
                :items="listOptions.projectTypes"
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                :disabled="isNotEditable"
                :rules="[
                    val => draft || (!!val || 'Campo obrigatório')
                ]"
                class="fixed-label"
              />
            </div>
          </v-col>
          <v-col sm="3" md="3" cols="12">
            <label for="detalhe-causa-raiz">Principal direcionador estratégico</label>
            <div class="grow">
              <v-autocomplete
                v-model="idea.mainStrategicDirected"
                :items="listOptions.mainStrategicDirected"
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                :disabled="isNotEditable"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="6" cols="12">
            <div class="idea-problem-description">
              <label for="description">Descrição problema/objetivo</label>
              <v-textarea
                  name="description"
                  v-model="idea.descriptionProblem"
                  filled
                  :disabled="isNotEditable"
                  :rules="[
                      val => draft || (!!val || 'Campo obrigatório'),
                    ]"
                  class="scroll-style fixed-label"
              />
            </div>
          </v-col>
          <v-col sm="6" md="6" cols="12">
            <div class="idea-description">
              <label>Descrição ideia</label>
              <v-textarea
                  name="description"
                  v-model="idea.descriptionIdea"
                  filled
                  :disabled="isNotEditable"
                  :rules="[
                      val => draft || (!!val || 'Campo obrigatório'),
                    ]"
                  class="scroll-style fixed-label"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="12" md="12" cols="12">
            <div class="idea-description">
              <label>Benefícios financeiros esperados</label>
              <v-textarea
                  name="description"
                  v-model="idea.expectedFinancialBenefit"
                  filled
                  :disabled="isNotEditable"
                  class="scroll-style fixed-label"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="3" md="3" cols="12"> 
            <div class="grow">
              <label for="area-negocio">Público Alvo</label>
              <v-autocomplete
                v-model="idea.ideasTargetAudiences"
                :items="listOptions.targetAudiences"
                item-text="name"
                item-value="id"
                chips
                multiple
                append-icon="mdi-chevron-down"
                :disabled="isNotEditable"
                :rules="[
                    val => draft || ((!!val && val.length > 0) || 'Campo obrigatório')
                ]"
                class="fixed-label"
              />
            </div>
          </v-col>
          <v-col sm="3" md="3" cols="12">   
            <div class="grow">
              <label for="area-negocio">Duração estimada</label>
              <v-autocomplete
                v-model="idea.estimatedDurationId"
                :items="listOptions.estimatedDurations"
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                :disabled="isNotEditable"
                :rules="[
                    val => draft || (!!val || 'Campo obrigatório')
                ]"
                class="fixed-label"
              />
            </div>
          </v-col>
          <v-col sm="3" md="3" cols="12"> 
            <label>Área responsável</label>
            <v-autocomplete
              v-model="idea.responsibleArea"
              :items="listOptions.areas"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              :disabled="isNotEditable"
              :rules="[
                  val => draft || (!!val || 'Campo obrigatório')
              ]"
              class="fixed-label"
            />
          </v-col>
          <v-col sm="3" md="3" cols="12">
            <label>Outras áreas envolvidas</label>
            <v-autocomplete
              v-model="idea.areasIdeas"
              :items="listOptions.areas"
              chips
              multiple
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              :disabled="isNotEditable"
              class="fixed-label"
            />
          </v-col>
        </v-row>
        
        <v-row>
          <v-col sm="2" md="2" cols="12">   
            <label for="root_cause_id">Ideia coletiva?</label>
            <v-switch
              v-model="idea.collectiveIdea"
              class="manual-line switch"
              color="secondary"
              :label="idea.collectiveIdea ? 'Sim' : 'Não'"
              inset
              :disabled="isNotEditable"
              @change="() => idea.ideasUsers = null"
            />
          </v-col>
          <v-col sm="10" md="10" cols="12">
            <div class="grow">
              <label for="area-negocio">Autores adicionais</label>
              <v-autocomplete
                v-model="idea.ideasUsers"
                :items="listOptions.users"
                item-text="name"
                item-value="id"
                chips
                multiple
                append-icon="mdi-chevron-down"
                :disabled="isNotEditable || !idea.collectiveIdea"
                :rules="[
                    val => (!idea.collectiveIdea || (idea.collectiveIdea && !!val)) || 'Campo obrigatório',
                    val => (!idea.collectiveIdea || (idea.collectiveIdea && !!val && val.length <= 255)) || 'Limite de caracteres de 255 excedido'
                ]"
                class="fixed-label"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="2" md="2" cols="12">   
            <label for="root_cause_id">Possível aceleração por startups ou parceiros?</label>
            <v-switch
              v-model="idea.possibleAccelerationByStartups"
              class="manual-line switch"
              color="secondary"
              :label="idea.possibleAccelerationByStartups ? 'Sim' : 'Não'"
              inset
              :disabled="isNotEditable"
              @change="() => idea.recommendation = ''"
            />
          </v-col>
          <v-col sm="6" md="6" cols="12">
            <label for="detalhe-causa-raiz">Recomendação</label>
            <v-text-field
              v-model="idea.recommendation"
              :counter="255"
              :disabled="isNotEditable || !idea.possibleAccelerationByStartups"
              :rules="[
                  val => (!idea.possibleAccelerationByStartups || (idea.possibleAccelerationByStartups && !!val)) || 'Campo obrigatório',
                  val => (!idea.possibleAccelerationByStartups || (idea.possibleAccelerationByStartups && !!val && val.length <= 255)) || 'Limite de caracteres de 255 excedido'
              ]"
              class="fixed-label"
            />
          </v-col>
          <v-col sm="4" md="4" cols="12">
            <div class="grow">
              <label for="area-negocio">Campanha</label>
              <v-autocomplete
                :items="listOptions.campaigns"
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                :disabled="isNotEditable"
                class="fixed-label"
                v-model="idea.campaignId"
              />
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-10"></v-divider>
        <h2 class="pb-4">Critério e Classificação</h2>
        <v-row v-for="(criteria, index) in listOptions.criteria" v-bind:key="criteria.id">
          <v-col sm="3" md="3" cols="12">  
            <label for="root_cause_id">{{ criteria.name }}</label>
            <v-switch
              v-model="criteria.isEditable"
              class="manual-line switch"
              color="secondary"
              :label="criteria.isEditable ? 'Sim' : 'Não'"
              inset
              :disabled="isNotEditable"
              @change="() => idea.classificationsIdeas[index] = null"
            />
          </v-col>
          <v-col sm="9" md="9" cols="12">
            <div class="grow">
              <label for="area-negocio">Classificação</label>
              <v-autocomplete
                v-model="idea.classificationsIdeas[index]"
                :items="criteria.classifications"
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                :disabled="isNotEditable || !criteria.isEditable"
                :rules="[
                    val => (!criteria.isEditable || (criteria.isEditable && !!val)) || 'Campo obrigatório',
                ]"
                class="fixed-label"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col cols="12">
            <label for="id">Anexos Relevantes</label>
            <v-file-input 
              multiple
              chips
              show-size
              counter
              :disabled="isNotEditable"
              v-model="idea.attachmentsUpload"
              truncate-length="15">
            </v-file-input>
            <div v-for="(file, index) in idea.attachments" :key="index" class="file-evidence margin20">
              <a @click="download(file.pivot.attachment_id)">{{ file.url }}</a>
              <a @click="() => idea.attachments.splice(index, 1)" v-if="!isNotEditable">
                <v-icon color="red">mdi-close</v-icon>
              </a>
            </div>
          </v-col>
          <v-col cols="12" align="end">
            <v-btn
              v-if="permissionViewSaveButton"
              @click="onConfirmation(true)"
              color="secondary"
              class="btn-save-project"
            >
              Salvar
            </v-btn>
            <v-btn
              v-if="permissionViewSubmitButton"
              @click="onConfirmation(false)"
              color="primary"
              class="btn-save-project mx-5"
            >
              Submeter à Aprovação
            </v-btn>
          </v-col>
        </v-row>
        <v-progress-linear
          v-if="isLoadingWorkflow"
          indeterminate
          color="primary"
          style="z-index: 2; margin: 7px -12px; width: calc(100% + 24px);"
        ></v-progress-linear>
        <v-row
          v-if="isEditing"
          style="border-top: 1px solid rgba(0, 0, 0, 0.42)">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Histórico Workflow
              </v-expansion-panel-header> 
              <v-expansion-panel-content v-if="workflow.history.length > 0">
                <div v-for="item in workflow.history" :key="item.id">
                  <v-card class="mx-auto px-6 py-3 mb-3" style="font-size: var(--font-size-p);" outlined>
                    Status: <span class="font-weight-bold">{{ item.status.name }}</span> 
                    atualizado por <span class="font-weight-bold">{{ item.user_name }}</span> 
                    em <span class="font-weight-bold">{{ item.updated_at }}</span>
                    <div v-if="item.cause_refused != null">
                      Motivo: <span class="font-weight-bold">{{ item.cause_refused }}</span>
                    </div>
                  </v-card>
                </div>
                <div 
                  class="mb-2"
                  v-if="workflow.messages.length > 0"
                >
                  Mensagens:
                </div>
                <div v-if="workflow.messages.length > 0">
                  <div 
                    v-for="(message, id) in workflow.messages" 
                    :key="message.id" 
                    class="mb-4"
                    :style="{'border-bottom': id !== workflow.messages.length - 1 ? '1px solid rgba(0, 0, 0, 0.2)' : 'none'}">
                    <v-card
                      class="mx-auto px-6 py-3 mb-3" 
                      style="font-size: var(--font-size-p);" 
                      outlined
                    >
                      <span class="font-weight-bold">{{ message.user_name }}</span> 
                      perguntou em <span class="font-weight-bold">{{ message.updated_at }}</span>
                      <div>{{ message.message }}</div>
                    </v-card>
                    <template v-if="message.answer.length > 0">
                      <v-card 
                        v-for="answer in message.answer" 
                        :key="answer.id" 
                        class="mx-auto ml-10 px-6 py-3 mb-3" 
                        style="font-size: var(--font-size-p);"
                        outlined
                      >
                        <span class="font-weight-bold">{{ answer.user_name }}</span> 
                        perguntou em <span class="font-weight-bold">{{ answer.updated_at }}</span>
                        <div>{{ answer.message }}</div>
                      </v-card>
                    </template>
                  </div>
                </div>
                <div 
                  v-if="showWorkflowForm" 
                  class="mt-6"
                >
                  <v-form ref="workflowform" @submit.prevent="submitWorkflowMessage">
                    <label>
                      Resposta
                      <v-textarea 
                        filled 
                        dense 
                        v-model="workflow.response"
                        :rules="[val => !!val || 'Campo obrigatório',]"/>
                    </label>
                    <v-btn color="success" type="submit">Responder Pergunta</v-btn>
                  </v-form>
                </div>
              </v-expansion-panel-content>
              <v-expansion-panel-content v-else>
                <div class="text-center"> Histórico está vazio... </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>  
        </v-row>
      </div>
    </v-card>
    <vv-alert 
      v-model="alert.show" 
      :message="alert.message"  
      :type="alert.type" 
      :dismissible="alert.dismissible" />

    <vv-confirmation-dialog
    v-model="dialog.show"
    :title="dialog.title"
    :message="dialog.message"
    @confirmed="submit(dialog.draft)" />

    <vv-loading :is-loading="isLoading" />
  </v-form>
</template>

<script>
import ideaServiceFactory from "@/services/ideaService";
import problemServiceFactory from "@/services/problemService";
import VvLoading from "@/components/common/VvLoading";
import VvAlert from "@/components/common/VvAlert";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";
import campaignServiceFactory from "@/services/campaignService";
import messageServiceFactory from "@/services/messageService";
import VvMultipleSelect from "@/components/common/VvMultipleSelect";
import VvProblemCell from "@/components/problems/VvProblemCell";

const campaignService = campaignServiceFactory();
const ideaService = ideaServiceFactory();
const problemService = problemServiceFactory();
const messageService = messageServiceFactory();


const newIdea = {
  id: undefined,
  projectId: undefined,
  epicId: null,
  ideas: [],
  problems: [],
  title: "",
  projectTypeId: undefined,
  ideasTargetAudiences: [],
  responsibleArea: undefined,
  estimatedDurationId: undefined,
  areasIdeas: [],
  ideasUsers: undefined,
  recommendation: "",
  expectedFinancialBenefit: "",
  possibleAccelerationByStartups: false,
  collectiveIdea: false,
  classificationsIdeas: [],
  mainStrategicDirected: undefined,
  attachments: [],
  attachmentsUpload: [],
  campaignId: undefined,
  descriptionProblem: ""
};

export default {
  name: "Idea",
  components: {
    VvAlert,
    VvLoading,
    VvConfirmationDialog,
    VvMultipleSelect,
    VvProblemCell,
  },
  data: () => ({
    createWithProblem: false,
    isNotEditable: false,
    isLoading: true,
    isLoadingWorkflow: false,
    isEditing: false,
    draft: true,
    attachmentsUpload: [],
    createWithId: false,

    workflow: {
      history: [],
      messages: [],
      lastMessageId: null,
      response: ""
    },

    editedFields: [],
    dialog: {
      show: false,
      title: '',
      message: ''
    },

    alert: {
      show: false,
      message: "",
      type: "info",
      dismissible: false,
    },

    cacheIdea: {},
    idea: { ...newIdea },

    listOptions: {
      projectTypes: [],
      mainStrategicDirected: [],
      targetAudiences: [],
      users: [],
      estimatedDurations: [],
      areas: [],
      criteria: [],
      problems: [],
      campaigns: [],
    },
    listProjects: [],
    listEpics: [],
    ideaLinks: [],
    campaignList: [],
    isCreating: false,
    isItApproved: false,
  }),

  async created() {
    const {isEditing, createWithProblem, createWithCampaign} = this.$router.currentRoute.meta;
    this.createWithProblem = createWithProblem;
    this.isEditing = isEditing;
    const id = Number(this.$route.params.id);
    const allCampaignActive = 0;

    if(!this.isEditing){
      await this.loadLists(allCampaignActive);
      this.isCreating = false
    }

    if(!this.isEditing && Number.isInteger(id) && createWithProblem){
      const problem = await problemService.show(id);
      const {mainImpacted, description} = problem;
      this.idea.ideasTargetAudiences = mainImpacted;
      this.idea.descriptionProblem = description;
      this.idea.problems = [id];
    }

    if(!this.isEditing && createWithCampaign){
      this.idea.campaignId = Number(this.$router.currentRoute.params.id);
    }

    if (this.isEditing && !Number.isInteger(id)) {
      this.isNotEditable = true;
    }

    if (this.isEditing && Number.isInteger(id)) {
      await this.loadLists(id);  
      this.isCreating = true
      this.show(id);
    }
  },
  computed: {
    isMaster() {
      const loginUserTypeId = this.$store.getters.type;
      const containMaster = loginUserTypeId.includes(this.CONST_USER_TYPE.USUARIO_MASTER)
      const containEt =  loginUserTypeId.includes(this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO)

      return containMaster || containEt
    },
    permissionViewSaveButton() {
      const { EM_PREENCHIMENTO } = this.CONST_WORKFLOW_IDEA;
      return (this.idea?.workflow?.id == EM_PREENCHIMENTO || !this.isEditing) && !this.isNotEditable;
    },
    permissionViewSubmitButton() {
      const { EM_PREENCHIMENTO, AGUARDANDO_ALTERACAO, REJEITADO_CURADOR, REJEITADO_COMITE } = this.CONST_WORKFLOW_IDEA;
      
      return ([EM_PREENCHIMENTO, AGUARDANDO_ALTERACAO, REJEITADO_CURADOR, REJEITADO_COMITE].includes(this.idea?.workflow?.id)
              || !this.isEditing) && !this.isNotEditable;
    },
    showWorkflowForm() {
      const {
        AGUARDANDO_ALTERACAO
      } = this.CONST_WORKFLOW_IDEA;
      const history = this.workflow.history;
      const isOwner = this.idea.ownerUserId === this.$store.getters.id;
      return AGUARDANDO_ALTERACAO === history[history.length - 1].status.id && (this.isMaster || isOwner);
    }
  },
  methods: {
    async loadLists(id) {
        this.campaignList = await campaignService.list();
        this.listOptions = await ideaService.loadLists(id);
        this.isLoading = false;
    },

    async loadListsProblems() {
        const result = await problemService.loadLists();
        this.listOptions = result;
        this.isLoading = false;
    },

    onConfirmation(draft) {
      this.draft = draft;
      if (this.draft) {
        this.dialog.title = 'Salvar ideia';
        this.dialog.message = 'Você realmente deseja salvar essa ideia? ela irá ser salva como rascunho';
      } else {
        this.dialog.title = 'Submeter à Aprovação';
        this.dialog.message = 'Você realmente deseja submeter à aprovação essa ideia?';
      }
      if (!this.isEditing) {
        this.dialog.show = true;
        return;
      }
      this.submit();
    },

    async submit() {
      if (!this.draft || this.idea.title == '') {
        if (!this.$refs.form.validate()) {
          this.alert = {
            show: true,
            message: 'Verifique todos os Campos do Formulário',
            type: 'error',
            dismissible: true
          };
          return;
        }
      }
      const method = this.idea.id ? 'edit' : 'create'
      try {
        this.isLoading = true;
        const response = await ideaService[method](this.idea, this.draft);
        this.alert = {
          show: true,
          message: response.data.message,
          type: 'success',
          dismissible: true
        };

        if (!this.isEditing) {
          setTimeout(() => {
            this.$router.push({ path: `/ideia/${response.data.idea.id}` });
          }, 3000);
        } else {
          const id = this.idea.id;
          this.show(id);
          this.loadWorkflowList(id);
        }
      } catch(error) {
          const {
            response: {
              data: {
                epic_id
              }
            }
          } = error
          const message = epic_id[0]
          this.alert = {
            show: true,
            message: message || 'Aconteceu algo de errado no Servidor...',
            type: 'error',
            dismissible: true
          };
      } finally {
        this.isLoading = false;
      }
    },
    async show(id) {
      try {
        this.isLoading = true
        this.listIdeaLinks(id);
        const idea = await ideaService.show(id);
        if ((idea.ownerUserId !== this.$store.getters.id) && (!this.isMaster)) {
          this.isNotEditable = true 
        }
        const { classificationsIdeas } = idea;
        const criteriaInputsMap = this.listOptions.criteria
          .map(({classifications}) => {
            return classifications
              .reduce((acc, {id}) => {
                if(classificationsIdeas.includes(id)) {
                  acc = id;
                }
                return acc;
              }, 0);
          })
          .map(el => typeof el !== 'object' ? el : undefined);

        this.listOptions.criteria = this.listOptions.criteria
          .map((el, index) => {
            const {classifications} = el
            const classificationsId = classifications.map(({id}) => id)
            const isEditable = classificationsId.includes(criteriaInputsMap[index])

            const newEl = {
              ...el,
              isEditable
            }
            return newEl
        })

        this.idea = { 
          ...idea,
          classificationsIdeas: criteriaInputsMap,
        }
        this.idea.workflow.id === this.CONST_WORKFLOW_IDEA.APROVADO_COMITE ? this.isItApproved = true : this.isItApproved = false;
        this.loadListsProject(this.idea.projectId)
        this.loadWorkflowList(id);
      } catch (error) {
        this.alert = {
          show: true,
          message: 'Parece que não encontramos esta ideia!',
          type: 'error',
          dismissible: true
        };
      } finally {
        this.isLoading = false
      }
    },
    clear() {
      if (this.isEditing) {
        this.idea = { ...this.cacheIdea };
      }
      if (!this.isEditing) {
        this.idea = { ...newIdea };
      }
      this.$refs.form.resetValidation();
    },
    async download(id) {
      try {
        this.isLoading = true;
        const data = await ideaService.getFile(id);
        this.createAndDownloadBlobFile(this.base64ToArrayBuffer(data.file), data.filename, data.extension);
      } catch (error) {
        this.alert = {
          show: true,
          message: 'Parece que não encontramos este arquivo!',
          type: 'error',
          dismissible: true
        };
      } finally {
        this.isLoading = false;
      }
    },

    async loadWorkflowList(id) {
      this.isLoadingWorkflow = true;
      const { history, messages, last_message_id: lastMessageId} = await ideaService.listWorkflowHistory(id);
      this.workflow.history = history;
      this.workflow.messages = messages;
      this.workflow.lastMessageId = lastMessageId;
      this.isLoadingWorkflow = false;

    },

    async submitWorkflowMessage() {
      const isValid = this.$refs.workflowform.validate()
      if(!isValid) return
      try {
        this.isLoading = true
        const result = await messageService.sendMessage({
          message: this.workflow.response,
          model: "Idea",
          modelId: this.idea.id,
          parentId: this.workflow.lastMessageId
        })
        this.alert = {
          show: true,
          message: result.message,
          type: "success",
          dismissible: true
        };
        this.$refs.workflowform.reset();
        this.loadWorkflowList(this.idea.id);
      } catch(error) {
        const message = error.message || error;
        this.alert = {
          show: true,
          message,
          type: "error",
          dismissible: true
        };
      } finally {
        this.isLoading = false
        this.$refs.workflowform.resetValidation();
      }
    },

    async changeEpic() {
      this.isLoading = true;
      try {
        const project = this.idea.projectId === null ? this.ideaLinks.projectId : this.idea.projectId;
        const epic = this.idea.epicId === null ? 0 : this.idea.epicId;
        const listEpics = await ideaService.listEpisByProject(project, epic);
        this.listEpics = listEpics;
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.isLoading = false;
      }
      
    },

    async loadListsProject(projectId) {
        this.isLoading = true;  
        if(this.isEditing && this.ideaLinks.projectId !== null){
          projectId = this.ideaLinks.projectId;
          this.idea.projectId = projectId;
        }
        if(projectId === undefined || projectId === null){
            projectId = 0;
        }
        const result = await ideaService.listProjects(projectId);
        this.listProjects = result;
        if(projectId !== 0 && projectId !== null){
          this.changeEpic()
        }
        if(!this.isEditing){
          this.isLoading = false;  
        }
    },

    async listIdeaLinks(id) {
      const result = await ideaService.listIdeaLinks(id);
      this.ideaLinks = result.project_epic_linked_to_idea
    }
  },
};
</script>

<style lang="scss">
.fixed-label {
  label {
    max-width: 100%;
    transform: translateY(-18px) scale(0.75);
  }
}

.grow {
  flex: 1;
}

.fixed-label {
  label {
    max-width: 100%;
    transform: translateY(-18px) scale(0.75);
  }
}

.grow {
  flex: 1;
}

.vv-label {
  font-size: 12px;
}

.vv-mt-22 {
  margin-top: 22px !important;
}

.vv-ht-115 {
  height: 115px !important;
}

.form-container {
  padding: 40px;
}

.sizeFont {
  h2 {
    font-size: 14px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
  }
}

import axios from "@/plugins/axios";

export default function notificationServiceFactory() {
  return {
    loadNotifications(params) {
      return axios
        .get(`v2/notifications`, { params: params })
        .then(res => res.data)
    },
    changeNotificationStatus(payload) {
      return axios
        .put(`v2/notifications/status`, payload)
        .then(res => res.data)
    },
    deleteNotifications(payload) {
      return axios
        .put(`v2/notifications`, payload)
        .then(res => res.data)
    }
  }
}
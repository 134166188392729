import HeaderView from "@/components/HeaderView";
import VvLoading from "@/components/VvLoading";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";
import mixin from "@/plugins/mixin";
import closuresServiceFactory from "../../services/closureService";

const closureService = closuresServiceFactory();

export default {
  components: {
    HeaderView,
    VvBreadcrumbs,
    VvLoading
  },
  mixins: {
    mixin
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: "ID Iniciativa",
          align: "start",
          value: "projectId"
        },
        {
          text: "Nome Iniciativa",
          align: "start",
          value: "projectName"
        },
        {
          text: "Solicitante",
          align: "start",
          value: "requesterUser"
        },
        {
          text: "Área responsável",
          align: "start",
          value: "area"
        },
        {
          text: "Data da solicitação",
          align: "start",
          value: "date"
        },
        {
          text: "Status ponto focal",
          align: "start",
          value: "focalPointStatus"
        },
        {
          text: "Status stakeholder",
          align: "start",
          value: "stakeholderStatus"
        },
        {
          text: "Status workflow",
          align: "start",
          value: "status",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "20%",
          align: "center",
        }
      ],
      closures: [],
      dialog: {
        projectId: null,
        closureId: null,
        doubt: false,
        approve: false,
        refuse: false,
        question: '',
        refusedCause: ''
      },
      breadcrumb: [
        {
          text: 'Workflow',
          disabled: true,
        },
        {
          text: 'Encerramento',
          disabled: false,
        },
      ],
      isLoading: false
    }
  },
  methods: {
    goToClosure(item) {
      this.$router.push(`/projeto/${item.projectId}?step=4&closureId=${item.finalizationId}`);
    },
    async loadClosures() {
      try {
        this.isLoading = true;
        const result = await closureService.listClosures();
        this.closures = result.data.finalizationsWorkflows;
        this.closures.map((item, index) => {
          this.closures[index].date = this.formattedDateForBr(item.date);
        });
      } catch(error) {
        this.$showError(error);
      } finally {
        this.isLoading = false;
      }
    },
    modalMessage(open, item = null) {
      this.dialog.doubt = open;
      this.dialog.projectId = open ? item.projectId : null;
      this.dialog.closureId = open ? item.finalizationId : null;
      this.dialog.question = '';
    },
    modalApprove(open, item = null) {
      this.dialog.approve = open;
      this.dialog.projectId = open ? item.projectId : null;
      this.dialog.closureId = open ? item.finalizationId : null;
    },
    modalRefuse(open, item = null) {
      this.dialog.refuse = open;
      this.dialog.projectId = open ? item.projectId : null;
      this.dialog.closureId = open ? item.finalizationId : null;
      this.dialog.refusedCause = '';
    },
    async sendMessage() {
      try {
        this.isLoading = true;
        const message = await closureService.sendMessage(this.dialog.projectId, this.dialog.closureId, { 'message': this.dialog.question });
        this.modalMessage(false);
        this.$showSuccess(message.data.message);
      } catch (error) {
        this.$showError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async approve() {
      try {
        this.isLoading = true;
        const approve = await closureService.approve(this.dialog.projectId, this.dialog.closureId);
        this.modalApprove(false);
        this.$showSuccess(approve.data.message);
        setTimeout(() => {
          this.$router.go();
        }, 1000);
      } catch (error) {
        this.$showError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async refuse() {
      try {
        this.isLoading = true;
        const refuse = await closureService.refuse(this.dialog.projectId, this.dialog.closureId, { 'refusedCause': this.dialog.refusedCause });
        this.modalRefuse(false);
        this.$showSuccess(refuse.data.message);
        setTimeout(() => {
          this.$router.go();
        }, 1000);
      } catch (error) {
        this.$showError(error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.loadClosures();
    this.closures.map((item, index) => {
      this.closures[index].date = this.formattedDateForBr(item.date);
    });
  }
}
<template>
  <v-expansion-panels v-model="show" :class="openAccordion ? 'expansion-panels openPanel' : 'expansion-panels closePanel'">
    <v-expansion-panel @click="onExpansionPanelClick">
      <v-expansion-panel-header class="expansion-panel-header" expand-icon="">
        <template v-slot:default="{ open }">
          <div class="d-inline-flex">
            <v-icon class="px-3">
                <template v-if="open">mdi-chevron-up</template>
                <template v-else>mdi-chevron-down</template>
            </v-icon>
            <v-icon
              v-if="sessionWithError"
              color="red"
              dark
              class="mr-1"
            >
              mdi-alert-outline
            </v-icon>
            <h3 class="title-form pt-1" :class="sessionWithError?'session_error':''">{{ title }}</h3>
            <slot name="header"/>
          </div>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
    name: "VvExpandInner",
    props: {
      value: {
        type: Boolean,
      },
      title: {
        type: String,
      },
      sessionWithError:{
        type: Boolean,
        default: false,
      },
      openAccordion: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      show: {
        get() {
            return this.value ? 0 : undefined;
        },
        set(value) {
            this.$emit("input", value?.length > 0);
        },
      }
    },
    methods: {
      onExpansionPanelClick(event) {
        if(event.currentTarget.classList.contains('v-expansion-panel-header--active')) {
          return this.openAccordion = false
        } else {
          return this.openAccordion = true
        }
      }
    }
};
</script>

<style lang="scss">
.expansion-panel-header {
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.expansion-panels {
  border: solid #DBDCDC 1px;
  padding: 16px;
  border-radius: 0px;
  background: #fff;

  &:last-child {
    border-radius: 0px 0px 8px 8px !important;
  }
}

.closePanel {
  margin: 0px 0px;
  &:first-child {
    border-radius: 8px 8px 0px 0px;
  }
}

.openPanel {
  border-radius: 8px !important;
  margin: 20px 0px;
}

.v-expansion-panels:first-child {
  border-radius: 8px 8px 0px 0px !important;
}

.session_error {
  color:red
}
</style>

import VvDatePick from "@/components/VvDatePick";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";
import epicRulesFactory from "@/domains/epic/rules/epicRules";
import ErrorMessageHandler from "../../mixins/ErrorMessageHandler";
import ideaServiceFactory from "@/services/ideaService";
import epicServiceFactory from "../../services/epicService";

const epicRules = epicRulesFactory();
const ideaService = ideaServiceFactory(); 
const epicService = epicServiceFactory();

export default {
  name: "AboutForm",
  components: {
    VvDatePick,
    VvConfirmationDialog,
  },
  mixins: [
    ErrorMessageHandler
  ],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    errorList: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    project: {
      type: Object,
      default: () => ({}),
    },
    originItem: {
      type: Object,
      default: () => ({}),
    },
    completedEpic: {
      type: Boolean,
      default: () => ({}),
    },
    permissionTypeEpic: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    addPPA: true,
    issues: [],
    confirmationDialog: {
      value: false,
      title: '',
      message: '',
      id: null,
    },
    optionIdea: [],
    optionModernization: [],
    canEditStatus: false
  }),
  methods: {
    statusAtualColor(id, css, type, disable) {
      let color = '';
      if (type == 'fc') {
        color = css ? "rgba(93, 93, 93, 0.8) " : "gray";
        if (id == 5 || id == 2 || id == 9) {
          color = css ? "rgba(20, 146, 0, 0.8)" : "secondary";
        } else if (id == 1 || id == 3 || id == 7 || id == 8|| id == 11 || id == 13 || id == 14) {
          color = css ? "rgba(157, 157, 157, 0.8) " : "grayLight";
        }else if (id == 4 || id == 7 || id == 12) {
          color = css ? "rgba(207, 30, 30, 0.8) " : "error";
        }else if (id == 10 || id == 6) {
          color = css ? "rgba(255, 183, 0, 0.8) " : "warningLight";
        }
        if(disable){
          color = css ? "rgba(0, 0, 0, 0.2) " : "grayLight";
        }
      } else {
        color = css ? "rgba(255, 255, 255, 0.8) " : "white";
        if (id == 5 || id == 2 || id == 9) {
          color = css ? "rgba(20, 146, 0, 0.3)" : "secondary";
        } else if (id == 1 || id == 3 || id == 7 || id == 8 || id == 11 || id == 13 || id == 14) {
          color = css ? "rgba(157, 157, 157, 0.3) " : "grayLight";
        }else if (id == 4 || id == 12) {
          color = css ? "rgba(207, 30, 30, 0.3) " : "error";
        }else if (id == 10 || id == 6) {
          color = css ? "rgba(255, 183, 0, 0.3) " : "warningLight";
        }
        if(disable){
          color = css ? "rgba(255, 255, 255, 0.8) " : "white";
        }
      }
      return color;
    },
    removeTags(tags) {
      this.item.tags.splice(this.item.tags.indexOf(tags.id), 1)
      this.item.tags = [...this.item.tags]
    },
    addIssue() {
      let objNewIssue = {
        problem: null,
        actionPlan: null,
        owner: null,
        date: null,
      }
      this.item.issues.push(objNewIssue);
    },
    modalRemoveIssue(issue, idIssue) {
      this.confirmationDialog.id = idIssue;
      if (this.validCallDialog(issue)) {
        this.confirmationDialog.title = 'Excluir ponto de atenção';
        this.confirmationDialog.message = 'Você realmente deseja excluir esse ponto de atenção?';
        this.confirmationDialog.value = true;
      } else {
        this.removeIssue()
      }
    },
    removeIssue() {
      this.item.issues.splice(this.confirmationDialog.id, 1);
      this.confirmationDialog.value = false;
      this.confirmationDialog.id = null;
      this.confirmationDialog.title = '';
      this.confirmationDialog.message = '';
    },
    checkIssueUnlock(statusId, newEpic) {
      return epicRules.checkIssueUnlock(statusId, newEpic);
    },
    lastIssue(issues, index) {
      return epicRules.lastIssue(issues, index);
    },
    validCallDialog(issue) {
      const issueEmpty = {
        problemDescription: null,
        actionPlan: null,
        userToActionPlan: null,
        plannedDate: null
      };
      return JSON.stringify(issue) !== JSON.stringify(issueEmpty);
    },
    tagsValidate() {
      const NOT_INVOLVE_IT = 1;
      this.item.tags = this.checksIfOnlyOneOption(
          NOT_INVOLVE_IT,
          this.item.tags
      );
    },
    async getListIdeas(id) {
      if(!id) return
      const resultIdea = await ideaService.loadListIdeas(id);
      this.optionIdea = resultIdea;
    },
    checkSystemsValues() {
      let systems = []

      if (typeof this.item.systems !== 'undefined' && this.item.systems.length > 0) {
        this.item.systems.map(system => {
          this.optionModernization.map(options => {
            options.systems.map(optionsSystems => {
              if (optionsSystems.id === system) {
                this.item.products.map(product => {
                  if (product === options.id) {
                    systems.push(optionsSystems.id)
                  }
                })
              }
            })
          })
        })

        this.item.systems = systems.length > 0 ? systems : []
        this.checkComponentsValues()
      }
    },
    checkComponentsValues() {
      let components = []

      if (typeof this.item.systems !== 'undefined' && this.item.systems.length > 0) {
        this.item.systems.map(system => {
          this.optionModernization.map(option => {
            option.systems.map(optionSystem => {
              if (system === optionSystem.id) {
                if (typeof this.item.components !== 'undefined' && this.item.components.length > 0) {
                  this.item.components.map(component => {
                    optionSystem.components.map(optionComponent => {
                      if (component === optionComponent.id) {
                        components.push(component)
                      }
                    })
                  })
                }
              }
            })
          })
        })
      }

      this.item.components = components.length > 0 ? components : []
    },
    async getModernizationOptions() {
      if (typeof this.item.subprojectId !== 'undefined' && this.item.subprojectId !== null) {
        try {
          this.$showLoading()
          const result = await epicService.getModernizationOptions(this.item.subprojectId)
          this.optionModernization = result.products
        } catch(error) {
          this.$showError(error)
        } finally {
          this.$hideLoading()
        }
      }
    },
    setDefaultItemOfSelects(){
      if (this.item.stageId === null && this.item.statusId === null) {
        this.item.epicTypeId = this.options.epicTypes.find(item => item.disabled === false)?.id;
 
        this.item.stageId = this.options.stages.find(item => item.id === this.CONST_STAGE.BACKLOG)?.id;
        
        this.item.statusId = this.statusList.find(item => item.id ===this.CONST_STATUS.NAO_APLICAVEL)?.id;
      }
    },

    setConditionalSelects(stageId) {
      let itens = []
      itens = this.options.stages.find(item => stageId === item.id)?.status
      if (itens.length > 0) {
        this.item.statusId = itens[0]
        this.canEditStatus = this.options.status.find(item => stageId === item.id)?.disabled
      } else {
        this.canEditStatus = false
      }
      if (!this.canEditStatus) {
        this.item.statusId = ''
      }
      this.$emit('canEditStatus', this.canEditStatus)
    },
  },
  computed: {
    systemOptions() {
      let options = []
      if (typeof this.item.products !== 'undefined' && this.item.products.length > 0) {
        this.optionModernization.map(option => {
          this.item.products.map(product => {
            if (option.id === product) {
              option.systems.map(system => {
                options.push(system);
              })
            }
          })
        })
      }
      return options;
    },
    componentsOptions() {
      let options = []
      if (typeof this.item.systems !== 'undefined' && this.item.systems.length > 0) {
        this.item.systems.map(system => {
          this.optionModernization.map(option => {
            option.systems.map(optionSystem => {
              if (optionSystem.id === system) {
                optionSystem.components.map(optionComponents => {
                  options.push(optionComponents)
                })
              }
            })
          })
        })
      }
      return options;
    },
    statusList() {
      if (this.item.new) {
        return this.options.status;
      }
      if (typeof this.item.options == 'undefined') {
        return [];
      }
      return this.item.options.status;
    },
    canDisableDeleteProblem() {
      return epicRules.checkDisableDeleteProblem(this.item.statusId, this.item.issues);
    },
    canEditFieldsPermission() {
      return this.item.permissions?.canEditFieldsPermission;
    },
    canEditSpecialsFields() {
      return this.item.permissions?.canEditSpecialsFields;
    },
    canEditStakeholderFieldsPermission() {
      return this.item.permissions?.canEditStakeholderFieldsPermission;
    },
    canEditCapexFieldPermission() {
      if (this.canEditFieldsPermission) {
        return this.item.permissions?.canEditCapexField;
      }
      return false;
    },
    canEditOpexFieldPermission() {
      if (this.canEditFieldsPermission) {
        return this.item.permissions?.canEditOpexField;
      }
      return false;
    },
    hasChange() {
      return JSON.stringify(this.item) !== JSON.stringify(this.originItem);
    },
    watch_statusId() { return this.item.statusId },
    ideaList() {
      const {
        new: isNew,
        listIdeasApproved,
      } = this.item;
      const {
        ideaList
      } = this.options;
      if(isNew){
        return ideaList;
      }
      return listIdeasApproved;
    },
  },
  watch: {
    watch_statusId(value) {
      if(epicRules.checkHasProblem(value) && this.item.issues.length === 0) {
        this.addIssue();
      }
    },
    item(epic) {
      const { ideaId } = epic
      this.getListIdeas(ideaId)
      if(epic.new){
        this.setDefaultItemOfSelects();
      }
    },
    hasChange(value) {
      this.$emit('hasChange', {id: this.item.id, hasChange: value});
    }
  },
  created() {
    this.getListIdeas();
    this.getModernizationOptions();
    if(this.item.new){
      this.setDefaultItemOfSelects();
    }
  }
}
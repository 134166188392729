<template>
  <v-container :fluid="true">
    <vv-loading :is-loading="isLoading"></vv-loading>

    <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />

    <vv-filter :filter-name="filterName" :fields="fields" @filter="filter" @reset="reset" export-api="project_indicators/filter"></vv-filter>

    <div class="indicators-vision-card">
      <h2 class="title-form">Listagem de Indicadores</h2>

      <v-data-table
        sort-by="id"
        :headers="headers"
        :items="projects"
        :search="search"
        :mobile-breakpoint="0"
        :header-props="headerProps"
        :footer-props="footerProps"
        class="cursor icon-down"
        @click:row="editItem"
        :options.sync="options"
        :server-items-length="serverItemsLength"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>

        <template v-slot:no-data>
          <v-btn
            class="ma-2"
            outlined
            color="primary"
            :loading="isLoading"
            @click="initialize"
          >
            Recarregar
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialogMessage" hide-overlay persistent width="500">
      <v-alert dense :type="typeMessage" style="margin: 0">
        <span v-html="textMessage"></span>
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import VvFilter from "@/components/VvFilter";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

export default {
  name: "Indicators",
  components: {
    VvBreadcrumbs,
    VvLoading,
    VvFilter,
  },
  data: () => ({
    payload: {},
    isLoading: true,
    fields: [],
    headers: [
      { text: "ID Iniciativa", value: "project_id", align: "center" },
      { text: "Nome da Iniciativa", value: "name", width: 250, class: "cabecalho" },
      { text: "Área responsável", value: "area", class: "cabecalho" },
      { text: "KPI", value: "kpi", class: "cabecalho" },
      { text: "Unidade de Medida", value: "unit_measure", class: "cabecalho" },
      { text: "Fonte da Informação (Sistema)", value: "source_information", class: "cabecalho" },
      { text: "Responsável pela atualização", value: "responsible_updating", class: "cabecalho" },
      { text: "Direcionador", value: "drivers_category", class: "cabecalho" },
      { text: "Frequência Atualização", value: "project_indicators_frequency", class: "cabecalho" },
    ],
    projects: [],
    search: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    footerProps: {
      "items-per-page-text": "Linhas por página",
      "items-per-page-options": [5, 10, 15, 30, 50, 100],
    },
    options: {
      itemsPerPage: 15,
    },
    serverItemsLength: 0,
    dialogMessage: false,
    typeMessage: "success",
    textMessage: "",
    breadcrumb: [
      {
        text: 'Listagens',
        disabled: true,
      },
      {
        text: 'Indicadores',
        disabled: false,
      },
    ],
    filterName: 'indicators'
  }),

  created() {
    this.mountValuesFilterFields();
  },

  watch: {
    dialogMessage(val) {
      if (!val) return;
      setTimeout(
        () => (
          (this.dialogMessage = false),
          (this.textMessage = ""),
          (this.typeMessage = "success")
        ),
        2500
      );
    },

    options: {
      handler() {
        this.fetchDatatable();
      },
      deep: true,
    },
  },

  methods: {
    initialize() {
      this.isLoading = true;
      if (this.$store.getters.userToken) {
        this.axios
          .get("project_indicators/filter", {
            params: this.payload,
          })
          .then((response) => {
            this.projects = response.data.data;

            this.serverItemsLength = response.data.total;
          })
          .catch((error) => {
            let messagesError = "<ul>";
            Object.keys(error.response.data).forEach((key) => {
              messagesError += `<li>${error.response.data[key]} </li>`;
            });
            messagesError += "</ul>";

            this.dialogMessage = true;
            this.textMessage = messagesError;
            this.typeMessage = "error";
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        setTimeout(() => {
          this.initialize();
        }, 100);
      }
    },

    filter(payload) {
      this.payload = payload;
      this.setPagination();
      this.initialize();
    },

    reset() {
      this.payload = {};
      this.setPagination();
      this.initialize();
    },

    editItem(item) {
      let url = `/projeto/${item.project_id}?step=3`;
      this.$router.push(url);
    },

    mountValuesFilterFields() {
      this.axios
        .get("v2/options/tap")
        .then((response) => {
          this.project_types = response.data.options.project_types;
          this.responsible_areas = response.data.options.responsible_areas;
          this.workflow_status = response.data.options.workflow_id;
          this.special_projects = response.data.options.special_projects;
          this.drivers_category = response.data.options.drivers_category;
          this.inception_drivers = response.data.options.inception_drivers;
          this.indicator_frequency = response.data.options.indicator_frequency;
          this.startNoApply(this.responsible_areas, 1);
          this.createFilterFields(); // seta os valores para o Json
        })
        .catch((error) => {
          this.dialogMessage = true;
          this.textMessage = error;
          this.typeMessage = "error";
        });
    },

    createFilterFields() {
      this.fields = [
        { type: "number", label: "ID Iniciativa", sm: "2", id: "project_id", name: "project_id", payloadBackend: "project[id][eq]", items: [] },
        { type: "text", label: "Nome Iniciativa", sm: "6", id: "name", name: "name", payloadBackend: "project[name][like]", items: [] },
        { type: "multiautocomplete", label: "Área Responsável", sm: "4", id: "areas_id", name: "areas_id", payloadBackend: "project[areas_id][or]", items: this.responsible_areas, },
        { type: "text", label: "KPI", sm: "3", id: "kpi", name: "kpi", payloadBackend: "kpi[like]", items: [] },
        { type: "text", label: "Unidade de Medida", sm: "3", id: "unit_measure", name: "unit_measure", payloadBackend: "unit_measure[like]", items: [] },
        { type: "text", label: "Fonte da Informação (Sistema)", sm: "3", id: "source_information", name: "source_information", payloadBackend: "source_information[like]", items: [] },
        { type: "text", label: "Responsável pela atualização", sm: "3", id: "responsible_updating", name: "responsible_updating", payloadBackend: "responsible_updating[like]", items: [] },
        { type: "multiautocomplete", label: "Direcionador", sm: "3", id: "drivers_category_id", name: "drivers_category_id", payloadBackend: "drivers_category_id[eq]", items: this.drivers_category },
        { type: "multiautocomplete", label: "Frequência Atualização", sm: "3", id: "project_indicators_frequency_id", name: "project_indicators_frequency_id", payloadBackend: "project_indicators_frequency_id[eq]", items: this.indicator_frequency },
      ];

      let fieldsStore = this.getGlobalFilters(this.filterName);

      if (fieldsStore !== undefined) {
        this.fields = fieldsStore;

        let payload = {};

        this.fields.map(field => {
          if (field.value) {
            payload[field.payloadBackend] = field.value
          }
        });
        if (Object.keys(payload).length) {
          payload.page = 1;
          this.filter(payload);
        }
      }
    },

    fetchDatatable: async function () {
      this.isLoading = true;
      this.setPagination();
      this.initialize();
    },

    setPagination() {
      this.payload["page"] = this.options.page;
      this.payload["page_size"] = this.options.itemsPerPage;
      this.payload["sortBy"] = this.options.sortBy[0];
      this.payload["sortDesc"] = 0;
      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.payload["sortDesc"] = this.options.sortDesc[0] ? 1 : 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.indicators-vision-card {
  background-color: white;
  padding: 24px 30px;
  border-radius: 6px;
  margin-top: 20px;

  h2 {
    color: #5d5d5d;
    font-size: 18px;
    font-weight: bold;
  }

  .title_colunm {
    font-weight: bold !important;
    text-align: center !important;
  }
}
</style>
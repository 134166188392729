<template>
    <v-form ref="form">
        <vv-loading :is-loading="isLoading"></vv-loading>
        <v-row>
            <v-col sm="6" md="6" cols="12">   
                <label for="project-id">Título da campanha</label>
                <v-text-field 
                    v-model="campaign.name"
                    counter="150"
                    :disabled="verifyIsActiveOrInactive || verifyEtOrMaster()"
                    required
                    :rules="[
                        val => !!val || 'Campo obrigatório',
                        val => (!!val && val.length <= 150 ) || 'Limite de caracteres de 150 excedido'
                    ]"
                    class="fixed-label"
                />
            </v-col>
        
            <v-col sm="6" md="6" cols="12">
                <label for="detalhe-causa-raiz">Responsável</label>
                <v-autocomplete
                    v-model="campaign.responsibleUser"
                    :items="userList"
                    item-text="name"
                    item-value="id"
                    :disabled="verifyIsActiveOrInactive || verifyEtOrMaster()"
                    no-data-text="Selecione um responsável existente"
                    required
                    :rules="[
                        val => !!val || 'Campo obrigatório'
                    ]"
                    append-icon="mdi-chevron-down"
                />
            </v-col>
        
            <v-col sm="12" md="12" cols="12">   
                <label for="project-id">Objetivo da campanha</label>
                <vv-rich-text 
                    v-model="campaign.objective" 
                    required
                    :disabled="verifyIsActiveOrInactive || verifyEtOrMaster()"
                    :error-messages="errorList.objective"
                    :rules="[
                        val => !!val || 'Campo obrigatório',
                    ]"
                    @errorChange="errorChange"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <label for="id">Anexo banner</label>
                <v-tooltip right max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-3 icon" color="primary" v-bind="attrs" v-on="on" style="margin-left: 5px;">
                            mdi-information-outline
                        </v-icon>
                    </template>
                    <span>
                    A imagem ideal para upload do banner é de 1280 * 480px. Extensões permitidas (jpeg, jpg, gif, png).
                    </span>
                </v-tooltip>
                
                <v-file-input 
                chips
                show-size
                counter
                v-model="campaign.bannerUpload"
                :disabled="verifyIsActiveOrInactive || verifyEtOrMaster()"
                truncate-length="15"
                accept=".png,.jpeg,.jpg,.gif">
                </v-file-input>
                    <div class="file-evidence margin20" v-if="campaign.banner !== null">
                        <a @click="downloadBanner(dataBanner)" v-if="dataBanner.filename !== undefined">{{ dataBanner.filename }}</a>
                        <a @click="removeBanner()" v-if="!verifyIsActiveOrInactive || !verifyEtOrMaster()">
                            <v-icon v-if="dataBanner.file !== '' && !verifyEtOrMaster() && !verifyIsActiveOrInactive" color="red">mdi-close</v-icon>
                        </a>
                    </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="4" md="4" cols="12">   
                <label>Campanha sem data final definida</label>
                <v-switch
                    v-model="campaign.noEndDate"
                    class="manual-line switch"
                    color="secondary"
                    :label="campaign.noEndDate ? 'Sim' : 'Não'"
                    inset
                    @change="verifyIsNoEndDate(true)"
                    :disabled="verifyIsActiveOrInactive || verifyEtOrMaster()"
                />
            </v-col>
            <v-col cols="12" lg="2" md="3">
                <label>Vigência (de)</label>
                <vv-date-pick
                    v-model="campaign.validityOf"
                    :disabled="verifyIsActiveOrInactive || verifyEtOrMaster()"
                    :clear-field="true"
                    :error-messages="errorList.validityOf"
                    required
                    @change="clearError('validityOf')"
                    :min-date="rulesDate.today"
                ></vv-date-pick>
            </v-col>
            <v-col cols="12" lg="2" md="3">
                <label>Vigência (até)</label>
                <vv-date-pick
                    v-model="campaign.validityUntil"
                    :disabled="isNoEndDate || verifyIsActiveOrInactive || verifyEtOrMaster()"
                    required
                    :clear-field="true"
                    :error-messages="errorList.validityUntil"
                    :min-date="rulesDate.rangeDate"
                    @change="clearError('validityUntil')"
                ></vv-date-pick>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <label for="id">Anexar documentos</label>
                <v-tooltip right max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-3 icon" color="primary" v-bind="attrs" v-on="on" style="margin-left: 5px;">
                            mdi-information-outline
                        </v-icon>
                    </template>
                    <span>
                    Extensões permitidas (xls, xlsx, PDF, DOC, JPEG, PNG, MP4).
                    </span>
                </v-tooltip>
                <v-file-input 
                multiple
                chips
                show-size
                counter
                v-model="campaign.anexos"
                :disabled="verifyIsActiveOrInactive || verifyEtOrMaster()"
                truncate-length="15"
                accept=".doc,.docx,.pdf,.xls,.xlsx,.jpeg,.png,.mp4">
                </v-file-input>
                <div v-for="(file, index) in campaign.attachments" :key="index" class="file-evidence margin20">
                    <a @click="download(file.pivot.attachment_id)">{{ file.url }}</a>
                    <a @click="() => campaign.attachments.splice(index, 1)" v-if="!verifyIsActiveOrInactive && !verifyEtOrMaster()">
                        <v-icon color="red">mdi-close</v-icon>
                    </a>
                </div>
            </v-col>
        </v-row>
        
        <v-row class="mb-2">
            <v-col cols="12" align="end">
                <v-btn
                color="secondary"
                class="btn-save-project"
                :loading="isLoading"
                :disabled="verifyIsActiveOrInactive || verifyEtOrMaster()"
                @click="validFields()"
                >
                {{ veriryIsEditing(campaign.id) }}
                </v-btn>
            </v-col>
        </v-row>
        <hr>
        <vv-alert 
            v-model="alert.show" 
            :message="alert.message"  
            :type="alert.type" 
            :dismissible="alert.dismissible" />
    </v-form>
</template>

<script>
import campaignServiceFactory from "@/services/campaignService";
import VvLoading from "@/components/VvLoading";
import VvDatePick from "@/components/VvDatePick";
import VvRichText from "@/components/common/VvRichText";
import VvAlert from "@/components/common/VvAlert";

const campaignService = campaignServiceFactory();

export default {
    name: "Campaign",
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    created() {
        this.getUserList();
        this.verifyIsNoEndDate(false);
        this.verifyBanner();
        this.userEt = this.userIsTransformationManager();
        this.userMaster = this.userIsMaster();
        this.rulesDate.rangeDate = this.campaign.validityOf;
    },
    components: {
        VvDatePick,
        VvRichText,
        VvLoading,
        VvAlert,
    },
    computed: {
        campaign: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value)
            }
        },
        verifyIsActiveOrInactive() {
            return !this.campaign.active;
        },
        startDate() {
            return this.campaign.validityOf;
        }
    },
    watch: {
        startDate() {
            this.rulesDate.rangeDate = this.getToday();
            if (this.campaign.validityOf !== null && this.campaign.validityOf !== '') {
                this.rulesDate.rangeDate = this.campaign.validityOf;
            } 
            this.campaign.validityUntil = null;
        }
    },
    data: () => ({
        isLoading: false,
        isEditing: false,
        userList: [],
        isNoEndDate: false,
        alert: {
            show: false,
            message: "",
            type: "info",
            dismissible: false,
        },
        fieldSubmit: "",
        dataBanner: {
            filename: "",
            file: "",
            extension: "",
        },
        disabledByInactive: false,
        errorList: [],
        rulesDate: {
            rangeDate: '',
            today: '',
        },
        deleteBanner: false,
        userEt: false,
        userMaster: false,
    }),
    methods: {
        getUserList() {
            this.isLoading = true;
            this.axios.get('user')
            .then(response => {
                this.userList = response.data
                this.isLoading = false;
            })
            .catch(error => {
                console.error(error);
                this.isLoading = false;
            });
        },
        async download(id) {
            this.isLoading = true;
            try {
                const data = await campaignService.getFileAttachment(id);
                this.createAndDownloadBlobFile(this.base64ToArrayBuffer(data.file), data.filename, data.extension);
                this.isLoading = false;
            } catch (error) {
                this.alert = {
                    show: true,
                    message: 'Parece que não encontramos este arquivo!',
                    type: 'error',
                    dismissible: true
                };
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        async downloadBanner(banner) {
            this.isLoading = true;
            try {
                this.createAndDownloadBlobFile(this.base64ToArrayBuffer(banner.file), banner.filename, banner.extension);
            } catch (error) {
                this.alert = {
                show: true,
                message: 'Parece que não encontramos este arquivo!',
                type: 'error',
                dismissible: true
                };
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
            this.isLoading = false;
        },
        verifyIsNoEndDate(status) {
            this.campaign.noEndDate === true ? this.isNoEndDate = true : this.isNoEndDate = false;
            if (status){
                this.campaign.validityUntil = null;
                this.errorList = [];
            }
        },
        validFields() {
            this.isLoading = true;
        
            if (!this.$refs.form.validate()) {
                this.alert = {
                    show: true,
                    message: 'Verifique todos os Campos do Formulário',
                    type: 'error',
                    dismissible: true
                };
                this.isLoading = false;
                return;
            } else {
                this.submit();
            }
        },
        async submit() {
            this.isLoading = true;
            const payload = this.campaign;
            const method = this.campaign.id ? 'edit' : 'create'
            if (this.verifyFieldObjectivetoEmpty(payload.objective)){
                this.errorList.objective = ['Campo obrigatório'];
                this.alertVerifyForm()
            } else if (!payload.noEndDate && !payload.validityUntil){
                this.errorList.validityUntil = ['Campo obrigatório'];
                this.alertVerifyForm()
            } else {
                try {
                    if(!(payload.bannerUpload) && !payload.banner) {
                        payload.banner = "";
                    } else {
                        if(payload.banner){
                            delete payload.banner;
                        }
                    }
                    payload.noEndDate === true ? payload.validityUntil = null : payload.validityUntil;
                    const response = await campaignService[method](payload);
                    this.campaign.attachments = response.data.campaign.attachments;
                    response.data.campaign.banner !== null ? this.dataBanner = response.data.campaign.banner : this.dataBanner;
                    this.campaign.id = response.data.campaign.id;
                    this.campaign.bannerUpdate = null;
                    this.campaign.anexos = null;
                    this.$emit('updateCampaigns');
                    this.alert = {
                        show: true,
                        message: response.data.message,
                        type: 'success',
                        dismissible: true
                    };

                    this.isLoading = false;
                    
                } catch(error) {
                    const message = "Verifique o preenchimento do formulário" || error;
                    this.errorList = error.response.data;
                    this.alert = {
                        show: true,
                        message,
                        type: 'error',
                        dismissible: true
                    };
                } finally {
                    setTimeout(() => {
                        this.alert.show = false;
                    }, 6000)
                    this.isLoading = false;
                    
                }
            }
        },
        veriryIsEditing(id) {
            id === undefined ? this.fieldSubmit = "Salvar" : this.fieldSubmit = "Editar"
            return this.fieldSubmit;
        },
        verifyBanner() {
            if(this.campaign.banner === undefined || this.campaign.banner === null){
                this.dataBanner = {
                    filename: "",
                    file: "",
                    extension: ""
                }
            } else {
                this.dataBanner = this.campaign.banner
            }
        },
        clearError(field) {
            this.errorList[field] = null;
        },
        removeBanner() {
            this.campaign.banner = "";
            this.dataBanner = {
                filename: "",
                file: "",
                extension: ""
            }
            this.deleteBanner = true;
        },
        alertVerifyForm() {
            this.alert = {
                show: true,
                message: 'Verifique o preenchimento do formulário',
                type: 'error',
                dismissible: true
            };
            this.isLoading = false;
        },
        errorChange(errors) {
            this.errorList.objective = errors;
        },
        verifyFieldObjectivetoEmpty(objective) {
            if (objective === "<p></p>" || objective === "<h1></h1>" || objective === "<h2></h2>" || objective === "<h3></h3>"){
                return true;
            }
            return false;
        },
        verifyEtOrMaster() {
            return !(this.userMaster || this.userEt);
        },
    }
}

</script>

<style scoped>
    .icon {
    margin-right: 5px;
    }
</style>

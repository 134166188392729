import axios from "@/plugins/axios";

export default function usersServiceFactory() {
    return {
        listUsers(payload, url) {
            return axios
                .get(url, {
                    params: payload
                }).then(res => res.data);
        },
        loadOptions() {
            return axios
                .get('v2/options/user-management')
                .then(res => res.data);
        },
        updateTypeUser(userId, typeId, value) {
            return axios
                .put(`v2/user/management/${userId}/type?typeId=${typeId}&value=${value}`)
                .then(res => res.data);
        },
        loadTree(userId, treeId) {
            return axios
                .get(`v2/user/management/${userId}/hierarchy?treeId=${treeId}`)
                .then(res => res.data);
        }
    }
}
<template>
  <v-container>

    <vv-loading :is-loading="isLoading"></vv-loading>

    <v-card>
      <v-toolbar flat color="toolbarColor" height="75px" dark>
        <v-row class="sizeFont">
          <v-col cols="4" md="2">
            <label>ID Iniciativa</label>
            <h2>{{ projectData.project_id }}</h2>
          </v-col>
          <v-col cols="5" md="6">
            <label>Nome da Iniciativa</label>
            <h2>{{ projectData.name }}</h2>
          </v-col>
          <v-col cols="3" md="4" v-if="loadingTab == 1">
            <v-btn @click="changeLoadAll()">{{ loadAllText }}</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-tabs centered color="#2A5F50" background-color="toolbarColor"  v-model="loadingTab">
        <v-tab :disabled="!ableEpicTab" @click="changeUri(projectData.project_id)">Iniciativa</v-tab>
        <v-tab :disabled="!ableEpicTab">Entregáveis</v-tab>
        <v-tab :disabled="!ableEpicTab">Change Requests</v-tab>
<!--        <v-tab>Dados Financeiros</v-tab>-->

<!--        TAP-->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <tap
                :SavedDemandingAreas="SavedDemandingAreas">
              </tap>
            </v-card-text>
          </v-card>
        </v-tab-item>

<!--        Entregáveis-->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row justify="center">
                <v-expansion-panels accordion v-model="panel">
                  <v-expansion-panel
                      v-for="(epic, index) in projectData.epics"
                      :key="index"
                      :id="'panel-'+index"
                      @click="changeUri(projectData.project_id, epic.id)"
                  >
                  <v-expansion-panel-header>
                    <div class="master-collapse">
                      <v-row justify-space-between v-if="epic.id">
                        <v-col cols="6" sm="1">
                          <span class="header-epic-fields">Épico</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ epic.id }}
                              </p>
                            </template>
                            <span>{{ epic.id }}</span>
                          </v-tooltip>
                        </v-col>

                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Estágio:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on"
                                v-if="optionsData.stages && epic.stage_id && optionsData.stages[epic.stage_id - 1]"
                                >
                                {{ getNameOptionByStatus(epic.stage_id, optionsData.stages) }}
                              </p>
                              <p v-else></p>
                            </template>
                            <span v-if="optionsData.stages && epic.stage_id">{{ getNameOptionByStatus(epic.stage_id, optionsData.stages) }}</span>
                            <span v-else></span>
                          </v-tooltip>
                        </v-col>

                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Jira:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ epic.id_jira }}
                              </p>
                            </template>
                            <span>{{ epic.id_jira }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Nome:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ epic.name }}
                              </p>
                            </template>
                            <span>{{ epic.name }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6" sm="1">
                          <span class="header-epic-fields">Prioridade:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ epic.priority }}
                              </p>
                            </template>
                            <span>{{ epic.priority }}</span>
                          </v-tooltip>
                        </v-col>

                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Status Atual:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on"
                                v-if="optionsData.stages && epic.stage_id && optionsData.stages[epic.stage_id - 1]"
                                >
                                {{ getNameOptionByStatus(epic.status_id, optionsData.status) }}
                              </p>
                              <p v-else></p>
                            </template>
                            <span v-if="optionsData.stages && epic.stage_id">{{ getNameOptionByStatus(epic.status_id, optionsData.status) }}</span>
                            <span v-else></span>
                          </v-tooltip>
                        </v-col>

                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">% Conclusão Épico:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <v-progress-linear
                                  class="header-epic-fields-value d-block text-truncate"
                                  :value="epic.progress"
                                  color="accent"
                                  height="25"
                                  v-bind="attrs"
                                  v-on="on"
                                  disabled
                              >
                                <span>{{ epic.progress }}% </span>
                              </v-progress-linear>
                            </template>
                            <span>
                                {{ epic.progress }}% 
                                <span v-if="epic.date_jira_updated"><br>Em: {{moment(epic.date_jira_updated).format('DD/MM/YYYY HH:mm')}}</span>
                            </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col>
                          <span class="header-epic-fields">Épico</span>
                          <p class="header-epic-fields-value">{{ index + 1 }}</p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <epic-form
                          :data="epic"
                          :index="index"
                          :demandingArea="DemandingArea"
                          :focal-point-or-stakeholder="focalPointOrStakeholder"
                          @callSetClickAddEpic="setClickAddEpic"
                          @changePriority="changePriority"
                          @createChangeRequest="createCr"
                          @showChangeRequests="showCr"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>

              <v-row v-if="clickAddEpic" class="container">
                <v-col cols="12">
                  <v-btn color="gray" outlined @click="newEpic()" v-show="!disableTabEpic">
                    <v-icon dark left>mdi-plus-circle-outline</v-icon>
                    Adicionar
                    Épico
                  </v-btn>
                  <vv-priority-epic v-if="projectData.epics && projectData.epics.length && projectData.epics.length > 1 && !disableTabEpic && projectData.workflow_id != 10"
                                    @changePriority="changePriority"
                                    :project_name="projectData.name"
                                    :project_id="projectData.project_id"
                  ></vv-priority-epic>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

<!--        Change Requests-->
        <v-tab-item>
          <!-- Busca CRs -->
          <v-card flat>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" lg="3" md="4">
                  <label>Épicos</label>
                  <v-autocomplete
                    v-model="searchEpic"
                    :items="sortEpicName"
                    :item-text="getItemText"
                    item-value="id"
                    append-icon="mdi-chevron-down"
                    @change="verificaPermissaoCriarChangeRequest(item)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" align="center">
                  <v-btn 
                    class="my-md-5" 
                    color="info" 
                    @click="getChangeRequests(searchEpic)"
                    :disabled="searchEpic==''"
                  >
                    <v-icon dark left>mdi-magnify</v-icon>
                    Buscar
                  </v-btn>
                </v-col>
                <v-col cols="6" md="2">
                  <v-btn 
                    v-if ="enableCR"
                    class="my-md-5" 
                    color="primary"
                    @click="createCr(searchEpic)"
                    :disabled="searchEpic==''"
                  >
                    <v-icon dark left>mdi-plus</v-icon>
                    Adicionar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Collapse CRs  -->
          <v-card flat>
            <v-card-text>
              <v-row justify="center">
                <v-expansion-panels accordion v-model="panelCr">
                  <v-expansion-panel
                      v-for="(changeRequest, index) in changeRequestData"
                      :key="changeRequest.id"
                      :id="'panelCr-'+index"
                  >
                  <v-expansion-panel-header>
                    <div class="master-collapse">
                      <v-row justify-space-between v-if="changeRequest.id">
                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Change Request ID</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ changeRequest.id }}
                              </p>
                            </template>
                            <span>{{ changeRequest.id }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Data Solicitação:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ formattedDateForBr(changeRequest.created_at) }}
                              </p>
                            </template>
                            <span>{{ changeRequest.created_at }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Status:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ getNameOptionByStatus(changeRequest.status_id, optionsCRData.status) }}
                              </p>
                            </template>
                            <span>{{ getNameOptionByStatus(changeRequest.status_id, optionsCRData.status) }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Motivo Change Request:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ changeRequest.description }}
                              </p>
                            </template>
                            <span>{{ changeRequest.description }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Existe Entrega de Novo Épico?:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ typeof changeRequest.delivery_new_epic !== 'undefined' ? "Sim" : "Não" }}
                              </p>
                            </template>
                            <span>{{ typeof changeRequest.delivery_new_epic !== 'undefined' ? "Sim" : "Não" }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6" sm="2">
                          <span class="header-epic-fields">Novo Épico a ser Entregue:</span>
                          <v-tooltip left max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="header-epic-fields-value d-block text-truncate"
                                v-bind="attrs"
                                v-on="on">
                                {{ changeRequest.delivery_new_epic ? changeRequest.new_epic_name : '-' }}
                              </p>
                            </template>
                            <span>{{ changeRequest.delivery_new_epic ? changeRequest.new_epic_name : '-' }}</span>
                          </v-tooltip>
                        </v-col>                    
                      </v-row>
                      <v-row v-else>
                        <v-col>
                          <span class="header-epic-fields">Change Request</span>
                          <p class="header-epic-fields-value">{{ index + 1 }}</p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <change-request-form 
                        :data="changeRequest" 
                        :epics-list="projectData.epics"
                        @updateSuccess="updateChangeRequest" 
                        @failed="showFailMessage"
                      ></change-request-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

<!--        Dados Financeiros-->
<!--        <v-tab-item>-->
<!--          <v-card flat>-->
<!--            <v-card-text>-->
<!--              <pre>{{ JSON.stringify(projectData, null, 4) }}</pre>-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-tab-item>-->
      </v-tabs>
    </v-card>
    <v-dialog
      v-model="dialogCr"
      width="1200"
      :fullscreen="mobileScreen"      
    >
    <v-card>
      <v-toolbar flat>            
        <v-toolbar-title>Adicionar Change Request</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <v-btn
              icon
              @click="dialogCr = false"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>            
      </v-toolbar>
      <v-card-text>
        <change-request-form 
          :epic-id="searchEpic" 
          :epics-list="projectData.epics"
          @createSuccess="createChangeRequests" 
          @failed="showFailMessage"
        ></change-request-form>
      </v-card-text>
    </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMessage" hide-overlay persistent width="400">
      <v-alert dense type="success" style="margin: 0">
        {{ textMessage }}
      </v-alert>
    </v-dialog>

    <v-dialog v-model="dialogMessageError" hide-overlay width="500">
      <v-alert prominent type="error" style="margin: 0">        
        {{ textMessageError }}        
      </v-alert>      
    </v-dialog>
  </v-container>
</template>

<script>
import Tap from "@/components/forms/Tap";
import EpicForm from "../../components/forms/Epic";
import ChangeRequestForm from "../../components/forms/ChangeRequest";
import VvLoading from "@/components/VvLoading";
import VvPriorityEpic from "@/components/VvPriorityEpic";

export default {
  components: {
    Tap,
    EpicForm,
    ChangeRequestForm,
    VvLoading,
    VvPriorityEpic,
  },
  created: function () {
    if ( this.$route.query.id || this.$route.query.panel ) {
      this.loadingTab = 1;
      this.startEpic = true;
    }    
  },
  mounted() {
    this.isLoading = true;
    this.loadOptions();
    this.fullModal();

    if ( this.$route.query.crId && this.$route.query.epicId ) {
      this.loadingTab = 2;
      this.searchEpic = parseInt(this.$route.query.epicId);
      setTimeout(() => {
        this.getChangeRequests(this.searchEpic, true)
      },2000)
      this.isLoading = false;
    }
  },
  data: () => ({
    loadAll: false,
    loadAllText: 'Exibir todos os Entregaveis',
    moment: require("moment"),
    loadingTab: 0,
    DemandingArea: [],
    SavedDemandingAreas: [],
    isLoading: true,
    clickAddEpic: true,
    projectData: {},
    optionsData: {},
    optionsCRData: {},
    changeRequestData: {},
    changeRequest:{},
    disableTabEpic: true,
    startEpic: false,
    ableEpicTab: false,
    panel: -1,
    panelCr: -1,
    dialogCr: false,
    mobileScreen: false,
    searchEpic: '',
    dialogMessage: false,
    textMessage: false,
    dialogMessageError: false,
    textMessageError: '',
    initialObj: {
      delivery_planned: {
        value: ''
      },
      delivery_done: {
        value: ''
      },
    },
    focalPointOrStakeholder: false,
    user_is_transformation_manager: false,
    user_is_itArea: false,
    user_is_master: false,
    sortEpicName: {},
    enableCR: false
  }),
  methods: {
    formLoad: function () {
      this.user_is_transformation_manager = this.userIsTransformationManager();

      this.enableCR = (this.user_is_transformation_manager || this.user_is_itArea) ? true :false;

      let project_id = this.$route.params.id;
      let selectedEpicId = (typeof this.$route.query.id == 'undefined') ? 0 : this.$route.query.id;
      this.isLoading = true;
      this.axios
          .get('/project/' + project_id + '/epic?epic_id=' + selectedEpicId + '&loadall=' + this.loadAll)
          .then((response) => {
            this.projectData = response.data

            let workflow_id = response.data.workflow_id;
            let stakeholders_id = response.data.stakeholders_id;
            let focal_points = response.data.focal_points;
            let auxSortEpicName = JSON.parse(JSON.stringify(response.data));
            this.sortEpicName = this.orderName(auxSortEpicName.epics);

            this.DemandingArea = response.data.impacted_areas;
            Object.keys(this.DemandingArea).forEach((key) => {
              if (this.DemandingArea[key].id != response.data.area_id) {
                this.DemandingArea.push({
                  "id":response.data.area_id,
                  "name":response.data.area_name
                })
              }
              if (this.DemandingArea[key].id == 1) {
                this.DemandingArea.splice(key, 1)
              } 
            })
            Object.keys(response.data.epics).forEach((key) => {
              this.SavedDemandingAreas.push(response.data.epics[key].demanding_area)
            })

            if ([
              this.CONST_WORKFLOW.APROVADO_DIRETOR,
              this.CONST_WORKFLOW.BACKLOG,
              this.CONST_WORKFLOW.EM_PLANEJAMENTO,
              this.CONST_WORKFLOW.EM_DESENVOLVIMENTO,
              this.CONST_WORKFLOW.CONCLUIDO,
              this.CONST_WORKFLOW.CANCELADO
            ].includes(workflow_id)) {
              this.checkFocalPointOrStakeholder(stakeholders_id, focal_points);

              if (
                  this.focalPointOrStakeholder || (Array.isArray(this.$store.getters.type) && this.inTree())
              ) {
                this.disableTabEpic = false;
              }
              this.ableEpicTab = true;
            }

            if (!this.projectData.epics.length) {
              const clone = Object.assign({}, this.initialObj);
              this.projectData.epics.push(clone);
            }

            let openEpic = 0
            this.projectData.epics.forEach(element => {
              if (this.startEpic && (element.id == this.$route.query.id)) {
                if(this.$route.query.df) {
                  let df = ''
                  df = this.$route.query.df
                  this.$router.replace({query: {panel: openEpic, df: df}})
                } else {
                  this.$router.replace({query: {panel: openEpic}})
                }
              }
              openEpic++
            });

            if (this.$route.query.panel) {
              this.panel = parseInt(this.$route.query.panel);

              let panelScroll = this.panel - 2;

              setTimeout(function () {
                let el = document.getElementById('panel-' + panelScroll);

                if (el) {
                  el.scrollIntoView();
                } else {
                  window.scrollTo(0, 0);
                }

              }, 500);

            }
            this.isLoading = false;
            this.$store.commit('setEpics', response.data.epics);
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
    },
    newEpic: function () {
      this.isLoading = true;

      this.setClickAddEpic(false);

      const clone = Object.assign({}, this.initialObj);
      this.projectData.epics.push(clone);

      this.panel = this.projectData.epics.length - 1;

      let panelScroll = this.panel - 2;

      let that = this;

      setTimeout(function () {
        let el = document.getElementById('panel-' + panelScroll);

        if (el) {
          el.scrollIntoView();
        } else {
          window.scrollTo(0, 0);
        }

        that.isLoading = false;
      }, 500);
    },
    setEnableCr() {
      if (this.user_is_transformation_manager || this.user_is_master || this.user_is_itArea) {
        this.enableCR = true;
      } else {
        this.enableCR = false;
        return;
      }

      this.projectData.epics.forEach((epic) => {
        if (epic.id == this.searchEpic &&
            (epic.stage_id == this.CONST_STAGE.CANCELADO ||
            epic.stage_id == this.CONST_STAGE.CONCLUIDO ||
            epic.status_id == this.CONST_STATUS.CANCELADO ||
            epic.status_id == this.CONST_STATUS.CONCLUIDO)) {
          this.enableCR = false;
          return;
        }
      });
    },
    setClickAddEpic(value) {
      this.clickAddEpic = value;
    },
    loadOptions() {
      this.axios
        .get("v2/options/epic")
        .then((response) => {
          this.optionsData = response.data.options;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.formLoad();
        });

      this.axios
        .get("options/cr")
        .then((response) => {
          this.optionsCRData = response.data.options;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changePriority() {
      window.location.reload()
    },
    checkFocalPointOrStakeholder(stakeholderId, focalPoints) {
      let userId = this.$store.getters.id;
      let userType = this.$store.getters.type;


      if ((Array.isArray(userType) && userType.indexOf(this.CONST_USER_TYPE.STAKEHOLDER) > -1) || (Array.isArray(userType) && userType.indexOf(this.CONST_USER_TYPE.PONTOS_FOCAIS) > -1)) {
        if (
            userType === this.CONST_USER_TYPE.STAKEHOLDER ||
            userType === this.CONST_USER_TYPE.PONTOS_FOCAIS
        ) {
          if (userId === stakeholderId || focalPoints.includes(userId)) {
            this.focalPointOrStakeholder = true;
          }
        }
      }
    },
    inTree(){
      if(this.$store.getters.type.indexOf(this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO) > -1 ||
        this.$store.getters.type.indexOf(this.CONST_USER_TYPE.USUARIO_MASTER) > -1 ||
        this.$store.getters.type.indexOf(this.CONST_USER_TYPE.STAKEHOLDER) > -1 ||
        this.$store.getters.type.indexOf(this.CONST_USER_TYPE.PONTOS_FOCAIS) > -1 ||
        this.$store.getters.type.indexOf(this.CONST_USER_TYPE.VSM) > -1 ||
        this.$store.getters.type.indexOf(this.CONST_USER_TYPE.GPM) > -1 ||
        this.$store.getters.type.indexOf(this.CONST_USER_TYPE.PM_PO) > -1){
          return true;
        }
      return false;
    },
    fullModal() {
      if(window.screen.width <= 600) {
        this.mobileScreen = true;
      }
    },
    createCr(value) {
      this.searchEpic = value;
      this.dialogCr = true;
    },
    showCr(value) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      },500);
      this.searchEpic = value;
      this.loadingTab = 2;
    },
    getItemText(item) {
      return `${item.id_jira} | ${item.name}`;
    },
    getChangeRequests(epic_id, autoLoad = false) {
      let project_id = this.projectData.project_id;
      this.panelCr = -1;

      if (project_id === undefined) {
        project_id = this.$route.params.id;
      }

      this.isLoading = true;

      this.axios
          .get(`/project/${project_id}/epic/${epic_id}/cr`)
          .then((response) => {
            this.changeRequestData = response.data.change_requests;
            this.changeRequestData.project_id = project_id;

            Object.keys(this.changeRequestData).forEach((key) => {
              Object.keys(this.projectData.epics).forEach((index) => {
                if (this.changeRequestData[key].epic_id == this.projectData.epics[index].id) {
                  this.changeRequestData[key].new_epic_name = this.projectData.epics[index].name
                }
              });
            });

            if(autoLoad) {
              let counter = 0;
              Object.keys(this.changeRequestData).forEach((key) => {
                if(this.changeRequestData[key].id == this.$route.query.crId) {
                  this.panelCr = counter;
                }
                counter++;
              });
            } else if(JSON.stringify(this.changeRequestData) === '[]') {
              this.textMessageError = "Nenhuma Change Request cadastrada neste épico";
              this.dialogMessageError = true;
              setTimeout(() => {
                this.dialogMessageError = false;
                this.textMessageError = '';
              }, 3000);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    createChangeRequests(data) {
      this.dialogCr = false;
      this.textMessage = data.message;
      this.dialogMessage = true;
      this.showCr(data.epicId);
      this.getChangeRequests(data.epicId);
      setTimeout(() => {
        this.dialogMessage = false;
        window.location.reload();
      }, 2000);
    },

    updateChangeRequest(data) {
      this.textMessage = data.message;
      this.dialogMessage = true;            
      setTimeout(() => {
        this.dialogMessage = false;
        this.$router.push(`/projeto/${data.projectId}/epicos?epicId=${data.epicId}&crId=${data.crId}`);
        window.location.reload();
      }, 2000);
    },

    showFailMessage(data) {
      if (data.user_type) {
        this.textMessageError = data.user_type;
      } else {
        this.textMessageError = data;
      }
      this.dialogMessageError = true;            
      setTimeout(() => {
        this.dialogMessageError = false;
      }, 4000);
    },

    changeUri(project_id, epic_id = null) {
        if (epic_id === null) {
          this.$router.push(`/projeto/${project_id}/epicos`);
        } else {
          this.$router.push(`/projeto/${project_id}/epicos?id=${epic_id}`);
        }
    },

    verificaPermissaoCriarChangeRequest() {
      if (this.user_is_transformation_manager) {
        this.enableCR = this.user_is_transformation_manager;
        return;
      }
      let epicId = this.searchEpic;
      let userTI = [];
      this.projectData.epics.forEach(function(item) {
        if (item.id === epicId) {
          userTI.push(item.vsm_id);
          userTI.push(item.gpm_id);
          item.pmpo.forEach(function(itemPmPo) {
            userTI.push(itemPmPo.users_id);
          });
        }
      });
      this.enableCR = this.userIsItArea(userTI);
    },
    changeLoadAll() {
      this.loadAll = !this.loadAll;
      if (this.loadAll) {
        this.loadAllText = 'Ocultar todos os Entregaveis';
      } else {
        this.loadAllText = 'Exibir todos os Entregaveis';
      }
      this.formLoad();
    },
    orderName(item) {
      return item.sort((a, b) => (a.name > b.name) ? 1 : -1);
    }
  }
};
</script>

<style lang="scss" scoped>
.toolbarColor {
  .v-toolbar__content {
    border-bottom: 1px solid #ffffff;
    color: #2A5F50;
    padding: 10px 16px;
  }
}

.master-collapse {
  width: 95%;
}

.v-tabs-slider-wrapper {
  display: none;
}

.v-tabs .v-tabs-bar {
  height: 58px;
  padding-top: 10px;

  &:after {
    content: "";
    height: 11px;
    background-image: linear-gradient(0deg, #7e6106 -100%, #00D3C3);
    width: 100%;
    position: absolute;
    bottom: -5px;
    z-index: 0;
  }
}

.v-slide-group__content .v-tab {
  text-transform: capitalize;
  width: 175px;
  font-size: 14px;
  padding: 0 10px;

  &.v-tab--active {
    background-color: #ffffff;
    font-weight: bold;
    border-radius: 4px 4px 0 0;
  }

  @media(max-width: 380px){
    width: 40%;
  }
}

button.btnAddEpic {
  border: 1px solid #5d5d5d;
  background-color: #ffffff !important;
  color: #5d5d5d !important;
  box-shadow: none;
}

.v-application--is-ltr .v-expansion-panel-header {
  color: #5d5d5d;
  font-size: 18px;
  font-weight: bold;
}

.v-tabs-bar.v-item-group > * {
  z-index: 1;
}

.theme--light.v-chip:hover::before {
  opacity: 0;
}

.header-epic-fields {
  font-size: 12px;
  font-weight: 100;
}

.header-epic-fields-value {
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .sizeFont {
    h2 {
      font-size: 14px;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
    }
  }
}

</style>

export default {
    name: 'Footer',
    data() {
        return {
            url: '',
        }
    },
    methods: {
        searchUrl () {
            let str = window.location.host;

            let envs = {
                localhost: "LOCAL",
                sit: "DESENVOLVIMENTO",
                stg: "STAGE",
                hlg: "HOMOLOGAÇÃO"
            }

            for (let env in envs) {
                if (str.includes(env)) {
                    this.url = envs[env];
                }
            }
        }
    },
    mounted() {
        this.searchUrl()
    }
};
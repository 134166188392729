import VvLoading from "@/components/VvLoading";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";
import VvNewFilter from "@/components/common/VvNewFilter";
import Modal from "@/components/common/VvModal";
import usersServiceFactory from "../../services/usersService";

const userService = usersServiceFactory();

export default {
    name: "Users",
    components: {
        VvLoading,
        VvBreadcrumbs,
        VvNewFilter,
        Modal,
    },
    data: () => ({
        isLoading: false,
        isMaster: false,
        payload: {},
        filterName: 'users',
        breadcrumb: [
            {
                text: 'Gestão',
                disabled: true,
            },
            {
                text: 'Usuários',
                disabled: false,
            },
        ],
        fields: [],
        filterOption: [],
        allTypesUsers: [],
        headers: [
            {text: 'Nome', value: 'name'},
            {text: 'E-mail', value: 'email'},
            {text: 'Árvore Negócios', value: 'businessTree'},
            {text: 'Árvore Tecnologia', value: 'techTree'},
            {text: 'Tipos', value: 'types', sortable: false},
            {text: '', value: 'data-table-expand'},
        ],
        users: [],
        formDisabled: true,
        currentPage: 1,
        serverItemsLength: 15,
        search: '',
        headerProps: {
            sortByText: "Ordenar por",
        },
        footerProps: {
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [5, 10, 15, 30, 50, 100],
        },
        options: {
            itemsPerPage: 15,
        },
        expanded: [],
        dialogMessage: false,
        textMessage: "",
        typeMessage: "success",
        treeModalBusiness: {
            showModal: false,
            areas: {},
        },
        treeModalTech: {
            showModal: false,
            journeys: {},
        },
    }),
    watch: {
        options: {
            handler() {
                this.fetchDatatable();
            },
            deep: true,
        }
    },
    methods: {
        async loadOptionsFilter() {
            this.isLoading = true;
            try {
                const resultOption = await userService.loadOptions();
                this.filterOption = resultOption.options.filterTypes;
                this.allTypesUsers = resultOption.options.allTypes;
                this.isMaster = resultOption.options.permissions.isMaster;
                this.loadFilter();
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false;
            }
        },
        loadFilter() {
            this.fields = [
                {
                    type: "text",
                    label: "Nome",
                    sm: "4",
                    col: "4",
                    id: "name",
                    name: "name",
                    value: "",
                    payloadBackend: "name[like]",
                    items: [],
                },
                {
                    type: "text",
                    label: "E-Mail",
                    sm: "4",
                    col: "4",
                    id: "email",
                    name: "email",
                    value: "",
                    payloadBackend: "email[like]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Tipos",
                    sm: "4",
                    col: "4",
                    id: "types",
                    name: "types",
                    value: "",
                    payloadBackend: "types",
                    items: this.filterOption,
                },
            ];

            let fieldsStore = this.getGlobalFilters(this.filterName);
            if (typeof fieldsStore !== 'undefined') {
                this.fields = fieldsStore;
                let payload = {};
                this.fields.map(field => {
                    if (field.value) {
                        payload[field.payloadBackend] = field.value;
                    }
                });
                if (Object.keys(payload).length) {
                    payload.page = 1;
                    this.filter(payload);
                }
            } else {
                this.resetFilter();
            }
        },
        filter(payload) {
            this.payload = payload;
            this.setPagination(payload.page);
            this.initialize();
        },
        setPagination(page) {
            this.payload['page'] = (page) ? page : this.options.page;
            this.payload['page_size'] = this.options.itemsPerPage;
            this.payload['sortBy'] = this.options.sortBy[0];
            this.payload['sortDesc'] = 0;

            if (this.options.sortBy.length && this.options.sortDesc.length) {
                this.payload['sortDesc'] = this.options.sortDesc[0] ? 1 : 0;
            }
        },
        resetFilter() {
            this.payload = {};
            this.setPagination(1);
            this.initialize();
        },
        async initialize() {
            if (this.$store.getters.userToken) {
                this.isLoading = true;
                try {
                    this.payload['isExport'] = 0;
                    const resultUsers = await userService.listUsers(this.payload, 'v2/user/management');
                    this.users = resultUsers.data;
                    this.currentPage = resultUsers.current_page;
                    this.serverItemsLength = resultUsers.total;
                } catch (error) {
                    const { response: { data: { message } = {} } = {} } = error;
                    this.$showError(message || "Erro ao carregar os usuários do sistema!");
                } finally {
                    this.isLoading = false;
                }
            }
        },
        nameTypeUser(typeId) {
            const nameType = this.allTypesUsers.filter((typeUser) => {
                return typeUser.id === typeId;
            });
            if (nameType.length > 0) {
                if (nameType[0].id === this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO) {
                    return 'E.T.';
                }
                if (nameType[0].id === this.CONST_USER_TYPE.USUARIO_MASTER) {
                    return 'Master';
                }
                return nameType[0].name;
            }
            return '';
        },
        fetchDatatable() {
            this.setPagination();
            this.initialize();
        },
        async updateUserType(userId, typeId, value) {
            this.isLoading = true;
            try {
                const numberValue = (value) ? 1 : 0;
                const result = await userService.updateTypeUser(userId, typeId, numberValue);
                this.initialize();
                this.$showSuccess(result.message);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false;
            }
        },
        async showModalTree(userId, treeType) {
            this.isLoading = true;
            try {
                const resultTree = await userService.loadTree(userId, treeType)
                if (treeType === this.CONST_REF_TREE.BUSINESS) {
                    if (typeof resultTree.hierarchy.area !== 'undefined') {
                        this.treeModalBusiness.areas = resultTree.hierarchy.area;
                        this.treeModalBusiness.showModal = true;
                    } else {
                        this.$showError("Este usuário não está cadastrado em nenhuma Árvore de Negócio.")
                    }
                } else {
                    if (typeof resultTree.hierarchy.journey !== 'undefined') {
                        this.treeModalTech.journeys = resultTree.hierarchy.journey;
                        this.treeModalTech.showModal = true;
                    } else {
                        this.$showError("Esse usuário não está cadastrado em nenhuma Árvore de Tecnologia.")
                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },
        nameTypeBusiness(type) {
            const nameType = this.allTypesUsers.filter((name) => {
                return name.id === type;
            })
            if (nameType.length > 0) {
                return nameType[0].name;
            }
        },
        nameTypeTech(type) {
            const nameType = this.allTypesUsers.filter((name) => {
                return name.id === type
            })
            return nameType[0].name
        },
    },
    mounted() {
        this.loadOptionsFilter();
    },
}
<template>
  <div class="grid-12 gap-md">
    <label class="g-col-12">
      {{ label }}
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon 
            small 
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>O tamanho máximo por arquivo é de 8MB. <br>A somatória de todos os arquivos não deve ultrapassar 50MB</span>
      </v-tooltip>
    </label>
    <div class="subtitle g-col-12">
      Formatos {{ showFormats }}
    </div>
    <div class="file-error g-col-12" v-if="hasError">
      *{{ errors[0] }}
    </div>
    <div
        v-for="({name},id) in files"
        :key="id"
        :class="`g-col-${columns}`"
    >
      <div class="fileBorder pa-2">
        <v-icon>mdi-file-outline</v-icon>
        <span class="text-truncate">{{ name }}</span>
        <button @click="clearFile(id)">
          <v-icon>mdi-close</v-icon>
        </button>
      </div>
    </div>
    <label for="inputFile" :class="`uploadButton g-col-${columns}`">
      <input
          style="display: none"
          id="inputFile"
          :accept="acceptFormats"
          multiple
          type="file"
          @change="fileChangeHandler"
      />
      <span>Novo arquivo</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "VvFileInput",
  props: {
    label: {
      type: String,
    },
    formats: {
      type: Array,
    },
    columns: {
      type: Number,
      default: 3,
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    files: [],
  }),
  computed: {
    acceptFormats() {
      return this.formats
          .map(format => `.${format}`)
          .join(', ');
    },
    showFormats() {
      return this.formats
          .map(format => `.${format.toUpperCase()}`)
          .join(' ');
    },
    hasError() {
      return this.errors.length > 0 ? true : false;
    }
  },
  methods: {
    fileChangeHandler(e) {
      const names = this.files.map(({name}) => name);
      Array.from(e.target.files)
        .forEach(file => {
          if (!names.includes(file.name)) {
            this.files.push(file);
          }
        });
      this.$emit('input', this.files);
    },
    clearFile(id) {
      this.files.splice(id, 1);
    },
    clearAllFiles() {
      this.files = [];
    }
  },
}
</script>

<style lang="scss" scoped>
label {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #5d5d5d;
}

.subtitle {
  font-family: Poppins;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #929497;
}

.fileBorder {
  border: 1px solid #dbdcdc;
  border-radius: 6px;
  font-size: 12px;
  color: #929497;
  i {
    color: #dbdcdc;
  }
  button > i {
    color: #929497;
  }

  .text-truncate {
    width: 74%;
    display: inline-grid;
  }
}

.uploadButton {
  border: 1px dashed #5d4ee8;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  color: #5d4ee8;
}

.file-error {
  color: #FF502D;
  font-weight: 500;
  font-size: 14px;
}
</style>
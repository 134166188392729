<template>
  <v-responsive :aspect-ratio="8/3">
    <v-carousel
      cycle
      hide-delimiter-background
      show-arrows
      class="rounded-lg"
      style="
        box-sizing: border-box;
        border: 3px solid #00d3c3;
        cursor: pointer;
        user-select: none;
      "
      height="100%"
    >
      <v-carousel-item
        v-for="(slide, i) in value"
        :key="i"
        v-ripple
        @click.stop="$emit('click', slide)"
      >
        <div v-if="slide.banner === null"
          class="
            grey
            lighten-2
            fill-height
            d-flex
            justify-center
            align-center
            text-center
          "
          style="color: rgba(0,0,0,.6);"
        >
          <div class="d-flex flex-column">
            <div class="text-h4 mb-2 title">
              {{ slide[itemTitle] }}
            </div>
            <div class="text-h5 sub-title" v-html="slide[itemSubtitle]"></div>
          </div>
        </div>
        <div v-else class="banner-image" :style="`background-image:url(data:image/${slide.banner.extension};base64,${slide.banner.file})`">
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-responsive>
</template>

<script>
export default {
  name: "VvCarousel",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    itemTitle: {
        type: String,
        default: 'title'
    },
    itemSubtitle: {
        type: String,
        default: 'subtitle'
    }
  },
};
</script>

<style>
  .title, 
  .sub-title {
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    margin: 0 70px;
  }
  .sub-title * {
    font-size: 22px;
  }
  .banner-image {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100%;
  }
</style>

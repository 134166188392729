<template>
  <div id="guideObjectives">
    <guide-objectives
      v-model="drivers"
      :items="items"
      :groups="groups"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import GuideObjectives from "@/domains/project/components/GuideObjectives";
export default {
  name: "GuideObjectivesForm",
  components: {
    GuideObjectives,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    projectId: {
      type: Number,
    },
    subprojectId: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => ([])
    },
    groups: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    drivers: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:drivers', value);
      },
    },
  },
}
</script>

<style scoped>

</style>
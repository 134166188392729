var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"data-table-delivery-formalizations",attrs:{"headers":_vm.headers,"items":_vm.deliveryFormalizations,"single-expand":true,"mobile-breakpoint":0,"no-data-text":_vm.noDataText,"no-results-text":_vm.noResultsText,"search":_vm.search,"custom-filter":_vm.customSearch,"expanded":_vm.expandedFormalization,"itemsPerPage":_vm.deliveryFormalizations.length,"hide-default-footer":"","item-key":"id","dense":""},on:{"update:expanded":function($event){_vm.expandedFormalization=$event},"click:row":_vm.openDetails},scopedSlots:_vm._u([{key:"item.epicName",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"epic-name",attrs:{"id":'formalization-'+item.id}},[_vm._v(_vm._s(item.epicName))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"workflow-status",class:_vm.parseStatusFormalization(item.statusId)},[_vm._v(_vm._s(item.status))])]}},{key:"item.vsm",fn:function(ref){
var item = ref.item;
return _vm._l((item.vsm),function(vsm,i){return _c('p',{key:i,staticClass:"approver vsm",class:vsm.status.toLowerCase()},[_vm._v(" "+_vm._s(vsm.name)+" ")])})}},{key:"item.focalPoints",fn:function(ref){
var item = ref.item;
return _vm._l((item.focalPoints),function(focalPoint,i){return _c('p',{key:i,staticClass:"approver focal-point",class:focalPoint.status.toLowerCase()},[_vm._v(" "+_vm._s(focalPoint.name)+" ")])})}},{key:"item.expandButton",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"#6D6E70"}},[(item.id === _vm.toggleById)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('details-delivery-formalizations',{attrs:{"formalization":item,"items":_vm.details,"disable-response":!_vm.canResponse(item)},on:{"update-formalization":_vm.updateFormalization,"error":function($event){_vm.errorAlert = $event},"loading":function($event){_vm.loading = $event}}}),(_vm.details.length > 0 && _vm.details[0].hasHistory)?_c('history-delivery-formalization',{attrs:{"id":_vm.deliveryFormalizationId,"formalizationId":_vm.details.length > 0 && _vm.details[0].id,"openHistory":_vm.openHistory},on:{"loading":function($event){_vm.loading = $event}}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <footer class="vv-general-footer" padless :style="bottomDistance">
    <div class="container px-4 relative-position">
      <div class="on-left">
        <slot name="left" />
      </div>
      <div>
        <slot name="center" />
      </div>
      <div class="on-right">
        <slot name="right" />
      </div>
      <div v-if="!$scopedSlots.right" class="on-right">
        <div v-if="isEditing">
          <v-btn
            color="primary"
            @click="$emit('save')"
            :disabled="disabled"
            depressed
          >
            Salvar
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            @click="$emit('cancel')"
            :disabled="disabled"
            outlined
            class="mr-4"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="$emit('create')"
            :disabled="disabled"
            depressed
          >
            Criar
          </v-btn>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "VvGeneralFooter",
  props: {
    value: {
      type: Object,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
  },
  data: () => ({
    bottomDistance: "0px",
  }),
  mounted() {
    const footerPage = document.querySelector("#footerPage");
    const setBottom = () => {
      const { innerHeight } = window;
      const { y } = footerPage.getBoundingClientRect();
      const bottom = innerHeight - y;
      this.bottomDistance = `bottom: ${bottom < 0 ? 0 : bottom}px`;
    };
    document.addEventListener("scroll", setBottom);
    const resizeObserver = new ResizeObserver(setBottom);
    resizeObserver.observe(document.querySelector("#app"));
  },
};
</script>

<style>
.vv-general-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 12px;
  box-shadow: 0 -10px 20px -14px rgba(0, 0, 0, 0.5);
}
.vv-general-footer .container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
}
.relative-position {
  position: relative;
}
.on-right {
  position: absolute;
  margin-right: 16px;
  right: 0;
}
.on-left {
  position: absolute;
  margin-right: 16px;
  left: 0;
}
</style>

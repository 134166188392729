<template>
    <v-form ref="form" lazy-validation id="new-business" v-scroll="fixFooter">
    

    <vv-loading :is-loading="isLoading"></vv-loading>

      <v-row>
        <v-col  cols="12" sm="6">
          <h2>Nova Árvore de Negócios</h2> 
        </v-col>
        <v-col cols="12" sm="6" align="end">
            <v-btn text color="#929497" @click="closeOrClear('close')">
                Cancelar
            </v-btn>
        </v-col>

      </v-row>
            
      <v-row>
        <v-col sm="12" md="5" cols="12">
            <label for="area">Área Responsável</label>
            <v-text-field
                type="text"
                placeholder="Digite a Nova Área"
                id="area"
                name="area"
                v-model="form.area.name"
                required
            ></v-text-field>
          </v-col>

      </v-row>

      <v-row>
          <v-col sm="12" md="5" cols="12">
            <label for="transformation_manager">Gerente Escritório Transformação</label>
            <div>
              <v-autocomplete
                id="transformation_manager"
                name="transformation_manager"
                item-text="name"
                item-value="id"
                no-data-text = "Selecione uma opção de Área Responsável"
                v-model="form.transformation_manager.id"
                :items="usersDeliveryOffice"
                append-icon="mdi-chevron-down"
                clearable
              ></v-autocomplete>
            </div>
          </v-col>
      </v-row>

      <v-row>

        <v-expansion-panels>
          <template v-for="(stakeholder, indexStakeholder) in form.stakeholders" cols="12" sm="12">
            <v-expansion-panel :key="indexStakeholder" class="mw-98 mb-3" cols="12" sm="12" :style="(!stakeholder.active) ? 'background-color: #DBDCDC' : ''">
              <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters>
                  <v-col cols="12" class="mb-4">
                    <strong>Responsável pelo Negócio/Stakeholder Negócio</strong>
                  </v-col>
                  <v-col
                      cols="12"
                      class="text--secondary"
                  >
                    <v-fade-transition leave-absolute>
                      <v-row
                          v-if="!open"
                          no-gutters
                          style="width: 100%"
                      >
                        <v-col cols="9" sm="10">
                          {{ stakeholder.name }}
                        </v-col>
                        <v-col cols="3" sm="2" class="mt-sm-n4" v-if="!stakeholder.clone">
                                      <span :class="(stakeholder.active) ? 'secondary--text' : 'error--text'">
                                        <strong>{{ (stakeholder.active) ? 'ATIVO' : 'INATIVO' }}</strong>
                                      </span>
                        </v-col>
                      </v-row>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row
                    justify="space-around"
                    no-gutters
                >
                  <v-col cols="12">
                    <v-col :key="indexStakeholder" cols="12" sm="12">
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <label class="d-block">Nome do responsável</label>
                        </v-col>
                        <v-col cols="10" sm="10" class="list pt-0">
                          <v-autocomplete
                              id="stakeholders"
                              name="stakeholders"
                              item-text="name"
                              item-value="id"
                              item-disabled="disabled"
                              no-data-text="Selecione uma opção de Área Responsável"
                              v-model="stakeholder.id"
                              :items="usersStakeholders"
                              append-icon="mdi-chevron-down"
                              required
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2">
                          <template v-if="stakeholder.clone">
                            <v-btn class="delete" @click="delete_block_stakeholder(indexStakeholder)">
                              <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                        </v-col>

                        <v-expansion-panels>
                          <v-expansion-panel :style="(!stakeholder.active) ? 'background-color: #DBDCDC' : ''">
                            <v-expansion-panel-header class="remove-space-icon">
                              <v-col sm="12" md="2" cols="12">
                                <div class="focalpoints_title">
                                  <label>Pontos Focais:</label>
                                </div>
                              </v-col>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row>
                                <template v-for="(focalpoint, indexFocalpoint) in stakeholder.focalPoints">
                                  <v-col :key="indexFocalpoint" :sm="(focalpoint.clone) ? 12 : 6" :lg="(focalpoint.clone) ? 12 : 4" cols="11" class="mb-3">
                                    <v-row :class="focalpoint.clone ? 'mr-3' : ''">
                                      <template v-if="focalpoint.clone">
                                        <v-col sm="11" md="12" cols="12" class="tree-row-list mr-sm-0 mr-3">
                                          <div class="line">
                                            <v-col cols="9" sm="10" md="11">
                                              <label for="new_focalpoints">Nome:</label>
                                              <template>
                                                <v-autocomplete
                                                    id="new_focalpoints"
                                                    name="new_focalpoints"
                                                    item-text="name"
                                                    item-value="id"
                                                    item-disabled="disabled"
                                                    no-data-text="Selecione um Stakeholder"
                                                    v-model="form.stakeholders[indexStakeholder].focalPoints[indexFocalpoint].id"
                                                    :items="usersFocalPoints"
                                                    append-icon="mdi-chevron-down"
                                                    required
                                                >
                                                </v-autocomplete>
                                              </template>
                                            </v-col>
                                            <v-col cols="2" md="1">
                                              <template>
                                                <v-btn class="delete mt-9" @click="delete_block_new_focalpoint(indexStakeholder, indexFocalpoint)">
                                                  <v-icon small>mdi-delete</v-icon>
                                                </v-btn>
                                              </template>
                                            </v-col>
                                          </div>
                                        </v-col>
                                      </template>
                                    </v-row>
                                  </v-col>
                                </template>
                              </v-row>
                              <v-row>
                                <v-col sm="6" md="4" cols="11" class="py-0">
                                  <v-btn
                                      outlined
                                      color="primary"
                                      class="ml-2 my-2 add-focal-point btn-add-focal-point"
                                      @click="clone_block_new_focalpoint(indexStakeholder)"
                                  >
                                    Novo Ponto Focal
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>

      </v-row>

      <v-row>
        <v-col sm="12" md="12" cols="12">
          <v-btn outlined color="primary" class="add-focal-point" @click="clone_block_stakeholder()">
            Novo Stakeholder
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mb-2">
        <v-col sm="12" md="12" cols="12" align="end">
          <v-btn color="secondary" @click="closeOrClear('clear')" class="btn-edit" outlined>
            Limpar
          </v-btn>
          <v-btn color="primary" class="btn-edit" @click="validFields()">
            Salvar
          </v-btn>
        </v-col>
      </v-row>

      <div class="footerFixed" v-if="!isDisabledForm" :class="footerFixed ? 'show' : ''">
        <v-container>
          <v-row>
            <v-col sm="12" md="12" cols="12" align="end" >
              <v-btn color="secondary" @click="closeOrClear('clear')" class="btn-edit" outlined>
                Limpar
              </v-btn>
              <v-btn color="primary" class="btn-edit mr-6" @click="editTree()">
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-dialog v-model="dialogMessage" hide-overlay persistent width="400">
          <v-alert dense :type="typeMessage" style="margin: 0">
              <div style="display: flex; justify-content: space-between;">
                  <span>{{textMessage}}</span>
                  <v-button @click="dialogMessage = false">
                      <v-icon>
                          mdi-window-close
                      </v-icon>
                  </v-button>
              </div>
          </v-alert>
      </v-dialog>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="modalCloseOrClear.status"
            persistent
            width="500"
        >
          <v-card>
            <v-card-title class="text-h5">
                {{ modalCloseOrClear.title }}
            </v-card-title>
            <v-card-text>{{ modalCloseOrClear.text }}</v-card-text>
            <v-card-actions style="border-top: 0.5px solid #A1A1A1">
              <v-spacer></v-spacer>
              <v-btn
              color="#A1A1A1"
              text
              @click="modalCloseOrClear.status = false"
              >
              Não
              </v-btn>
              <v-btn
              color="primary"
              @click="modalCloseOrClear.status = false; backToEdit()"
              v-if="modalCloseOrClear.type = 'close'"
              >
                Sim
              </v-btn>
              <v-btn
              color="primary"
              @click="modalCloseOrClear.status = false; reset()"
              v-else-if="modalCloseOrClear.type = 'clear'"
              >
                Sim
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-form>
</template>

<style lang="scss" scoped>
#new-business {
  .delete {
    min-width: 30px !important;
    height: 30px !important;
    padding: 0px !important;
    background-color: transparent;
    color: #FF0000;
    margin-top: 2px;
    margin-left: 5px;
    box-shadow: none;

    &.last{
      @media(max-width:599px){
        margin-top: 0px;
      }
    }
  }

  .spotlight {
  font-weight: bold;
    font-size: 16px;
  }

  .tree-div {
    margin-top: 20px;
    border: 1px solid #9e9c9c;
    border-radius: 4px 4px 0 0;
    background-color: white;
    padding: 30px;
  }

  .tree-row {
    margin-top: 20px;
    border-top: 10px solid #027B02;
    border-bottom: 1px solid #9e9c9c;
    border-left: 1px solid #9e9c9c;
    border-right: 1px solid #9e9c9c;
    border-radius: 4px 4px 0 0;
    background-color: white;
    padding: 30px;
  }
  .tree-row-list {
    border-radius: 6px;
    background-color: white;
    padding: 0px;
    margin: 0px 0px 0px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 0px #5D4EE8;
  }

  .btn-add-focal-point {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 0px #C6BEFF;
    border-radius: 6px;
    height: 50px !important;
  }

  .btn-edit.v-btn {
    margin-left: 0px;
    margin-right: 20px;
  }

  .btn-edit.v-btn:last-child {
    margin-right: 0px;
  }

  .focalpoints_title{
    padding: 0px;
    label {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 500px) {
      display: block;
    }
    .focalpoints {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 210px;
    }
  }
  .line{
    display: flex;
    flex-direction: row;
  }

  .manual-line :nth-child(2n){
    @media (max-width: 500px) {
      border:none !important;
      display: block;
    }
  }

  .v-expansion-panel {
    border: 1px solid #e2deff;
    border-radius: 5px;
    .v-expansion-panel {
      border: none;
      button {
        padding-left: 0;
      }
      .v-expansion-panel-content__wrap {
        padding-left: 0 !important;
      }
    }
  }

  button.add-focal-point {
    text-transform: uppercase;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    width: 100%;
    height: 46px;
  }

  .mw-98 {
    max-width: 98%;
  }

  .footerFixed {
    position: fixed;  
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 200;
    background-color: #ffffff;
    display: none;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);

    .save-btn-footer-fixed {
        margin-right: 24px;
    }

    &.show {
        display: block;
    }
  }
}
</style>

<script>
import VvLoading from '@/components/VvLoading';

export default {
    name: 'Business',
  components: {
    VvLoading
  },

  data: () => ({
    footerFixed: false,
    isLoading: true,
    urlCreate: 'CrateTreeBusiness',
    dialogMessage: false,
    typeMessage: "success",
    textMessage: '',
    objectItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
    modalCloseOrClear: {
      status: false,
      title: '',
      text: '',
      type: ''
    },
    values: [],
    fields:[],
    items:[],
    active_stakeholder:null,
    active_focalpoint:null,
    active:null,
    focalPoints: [],
    focalPoint: null,
    users: [],
    usersDeliveryOffice: [],
    usersStakeholders: [],
    usersFocalPoints: [],
    areas: [],
    responseAreas:[],
    block_new_focalpoint:[],
    new_focalpoint:[],
    new_focalpoints:[],
    new_stakeholders:[],
    form:{
        area:{
          name:""
        },
        transformation_manager:{
          id:null
        },
        stakeholders: [],
     },
    clearModal: false,
    backModal: false,
  }),

  directives: {
    scroll: {
      inserted: function(el, binding) {
        let f = function(evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f);
          }
        };
        window.addEventListener('scroll', f);
      }
    }
  },

  dialogMessage(val) {
    if (!val) return;

    setTimeout(function () {
      this.dialogMessage = false;
      this.typeMessage = "success";
      this.textMessage = "";
    }, 2000);
  },

watch: {
  },

  created() {
    this.mountValuesTreeFields()
    this.getUsers()
  },

  methods: {
    fixFooter: function() {
      let endOfPage = document.body.scrollHeight > window.innerHeight ? document.body.scrollHeight - window.innerHeight : window.innerHeight
      this.footerFixed = window.scrollY < (endOfPage - 125) ? true : false;
    },

    closeOrClear(status = null) {
      this.modalCloseOrClear.status = true
      this.modalCloseOrClear.type = status

      if (status == 'close') {
        this.modalCloseOrClear.title = "Sair da criação"
        this.modalCloseOrClear.text = "Você realmente deseja sair da criação da árvore?"
      } else if (status == 'clear') {
        this.modalCloseOrClear.title = "Limpar dados preenchidos"
        this.modalCloseOrClear.text = "Você realmente deseja limpar os dados preenchidos?"
      } else {
        this.modalCloseOrClear.status = false
      }
    },

    backToEdit(){
      let state = false
      this.backModal = false
      this.$emit('Edit', state)
    },

    initialize() {
      this.isLoading = true;
    },

    newTree(){
      let state = true
      this.$emit('Create', state)
    },

    clone_block_stakeholder(){
      this.form.stakeholders.push({
        active: true,
        focalPoints: [],
        id: 0,
        name: "",
        clone: true
      })
    },

    delete_block_stakeholder(index){
      this.form.stakeholders.splice(index, 1)
    },

    clone_block_new_focalpoint(i){
      this.form.stakeholders[i].focalPoints.push({
        id: 0,
        name: "",
        active: true,
        clone: true
      })
    },

    delete_block_new_focalpoint(index_stakeholder, index_focalPoint){
      this.form.stakeholders[index_stakeholder].focalPoints.splice(index_focalPoint, 1);
    },

    mountValuesTreeFields() {
      this.isLoading = true;

      this.axios
          .get("tree/business")
          .then((response) => {
            this.responseAreas = response.data;
            Object.keys(response.data).forEach((key) => {

              this.areas.push(response.data[key].area);

            });
            
          })
          .catch((error) => {
            this.dialogMessage = true
            this.typeMessage = "warning";
            this.textMessage = error
          });
    },

    integrationAreas(){
      
      Object.keys(this.responseAreas).forEach((key) => {
        let ArrayFull = this.responseAreas[key]

        if(ArrayFull.area.id == this.form.area.id){

          this.form.transformation_manager.id = ArrayFull.transformation_manager.id  // Gerente de Transformação

          Object.keys(ArrayFull.stakeholders).forEach((key2) => {
            this.form.stakeholders.push(ArrayFull.stakeholders[key2])  // Stakeholders (com os respectivos Pontos Focais)

          })

        }

      });

    },

    disabledSwitch(focalpoint) {
      return (focalpoint.active);
    },

    getUsers(){
      this.isLoading = true;

      this.axios.get("user")
        .then((response) => {
          this.users = response.data;
        })
        .catch((error) => {
              this.dialogMessage = true
              this.typeMessage = "warning"
              this.textMessage = error
      });

      this.axios
          .get("tree/business/users-option")
          .then((response) => {
            this.usersDeliveryOffice = response.data;
          })
          .catch((error) => {
            this.typeMessage = "warning";
            this.textMessage = error;
            this.dialogMessage = true;
          });

      this.axios.get("user")
          .then((response) => {
            this.users = response.data;
          })
          .catch((error) => {
            this.dialogMessage = true
            this.typeMessage = "warning"
            this.textMessage = error
          });

        this.axios
            .get("tree/business/users-options")
            .then((response) => {
                this.usersStakeholders = response.data.optionsStakeholders;
                this.usersFocalPoints = response.data.optionsFocalPoints;
            })
            .catch((error) => {
                this.typeMessage = "warning";
                this.textMessage = error;
                this.dialogMessage = true;
            });
    },


    saveTree() {
      let submitData = this.form;

        this.axios.post("tree/business/", submitData)
              .then(response => {
                this.textMessage = response.data.message
                this.typeMessage = "success"
                this.dialogMessage = true
                this.reset()
                    
              })
              .catch(error => {
                this.typeMessage = "warning"
                this.dialogMessage = true
                this.textMessage = error.response.data.message
              }) 
      
    },

    validFields() {

      if(this.form.area.name == ""){
        this.typeMessage = "warning";
        this.dialogMessage = true
        this.textMessage = "Digite um nome da Área"
        return false
      }
      else if(this.form.transformation_manager.id == null){
        this.typeMessage = "warning";
        this.dialogMessage = true
        this.textMessage = "Selecione um Gerente de Transformação"  
        return false
      }
      else if(this.form.stakeholders.length == 0){
        this.typeMessage = "warning";
        this.dialogMessage = true
        this.textMessage = "Selecione um Stakeholder"  
        return false
      }
      else if(this.form.stakeholders.length > 0){
        let errox = false
        Object.keys(this.form.stakeholders).forEach((key) => {
          if(this.form.stakeholders[key].focalPoints.length == 0){
              errox = true
              this.typeMessage = "warning";
              this.dialogMessage = true
              this.textMessage = "Selecione um Focalpoint"  
              return
            } 
        })

        if(!errox) { this.saveTree() }
      }   
      
    },

    resetFields(){
      this.form.stakeholders = []
      this.focalPoints = []
      this.new_focalpoint = []
    },

    reset() {      
      this.form.area = []
      this.form.transformation_manager = []
      this.form.stakeholders = []
      this.focalPoints = []
      this.new_focalpoint = []
      this.clearModal = false
      this.initialize();
    },
    

  },
}
</script>

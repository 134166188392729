import JourneyDetail from "../JourneyDetail/JourneyDetail.vue";

export default {
    props: {
        journeys: {
            type: Array,
            default: () => ([])
        },
        journeyDetail: {
            type: Object,
            default: () => ({})
        },
        canCreate: {
            type: Boolean,
            default: false
        }
    },
    components: {
        JourneyDetail
    },
    data() {
        return {
            panel: null
        }
    },
    methods: {
        openJourneyByUrl(id) {
            this.panel = 0;
            this.openJourney(id);
        },
        closeJourneys() {
            this.panel = null;
        },
        openJourney(id) {
            this.$emit('openJourney', id);
        },
        openTribeEditModal(journeyDetail, index) {
            this.$emit('openTribeEditModal', journeyDetail, index)
        },
        openJourneyEditModal(journey) {
            this.$emit('openJourneyEditModal', journey)
        },
        openTribeCreateModal(tribeIndex) {
            this.$emit('openTribeCreateModal', tribeIndex)
        }
    },
}
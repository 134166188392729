import axios from "@/plugins/axios";

export default function closuresServiceFactory() {
  return {
    listClosures(project_id) {
      return axios
        .get(`v2/project/${project_id}/finalization`)
        .then(res => res);
    },
    detaiClosures(project_id, closure_id) {
      return axios
        .get(`v2/project/${project_id}/finalization/${closure_id}`)
        .then(res => res);
    },
    sendMessage(project_id, closure_id, data) {
      return axios
        .post(`v2/project/${project_id}/finalization/${closure_id}/message`, data)
        .then(res => res);
    }
  }
}
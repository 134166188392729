<template>
  <v-container :fluid="true">
    <vv-loading :is-loading="isLoading"></vv-loading>

    <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />

    <vv-filter :accept-export="false" :fields="fields" :filter-name="filterName" @filter="filter"
               @reset="reset"></vv-filter>
    <div class="monthly-reports-card">
      <h2 class="title-form">Relatórios mensais</h2>

      <v-data-table
          :footer-props="footerProps"
          :header-props="headerProps"
          :headers="headers"
          :items="reports"
          :mobile-breakpoint="0"
          :options.sync="options"
          :search="search"
          :server-items-length="serverItemsLength"
          class="cursor icon-down"
          sort-by="id"
          @click:row="rowClicked"
      >
        <template v-slot:item.month="{ item }">
          {{ parseMonth(item.month) }}
        </template>
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
          <v-btn
              :loading="isLoading"
              class="ma-2"
              color="primary"
              outlined
              @click="initialize"
          >
            Recarregar
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialogMessage" hide-overlay persistent width="500">
      <v-alert :type="typeMessage" dense style="margin: 0">
        <span v-html="textMessage"></span>
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import VvFilter from "@/components/VvFilter";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

export default {
  name: "MonthlyReports",
  components: {
    VvBreadcrumbs,
    VvLoading,
    VvFilter,
  },
  watch: {
    dialogMessage(val) {
      if (!val) return;
      setTimeout(() => {
        this.dialogMessage = false;
        this.textMessage = '';
        this.dialog = false;
      }, 3000);
    },
    options: {
      handler() {
        this.fetchDatatable();
      },
      deep: true,
    },
  },
  data: () => ({
    payload: {},
    isLoading: false,
    fieldsComputed: [],
    panelCr: -1,
    fields: [],
    reports: [],
    monthList: [
      {"id": "1", "name": "Janeiro"},
      {"id": "2", "name": "Fevereiro"},
      {"id": "3", "name": "Março"},
      {"id": "4", "name": "Abril"},
      {"id": "5", "name": "Maio"},
      {"id": "6", "name": "Junho"},
      {"id": "7", "name": "Julho"},
      {"id": "8", "name": "Agosto"},
      {"id": "9", "name": "Setembro"},
      {"id": "10", "name": "Outubro"},
      {"id": "11", "name": "Novembro"},
      {"id": "12", "name": "Dezembro"}
    ],
    yearList :[],
    headers: [
      {text: "ID", value: "id", align: "center", class: "cabecalho"},
      {text: "Tipo relatório", value: "type", align: "center", class: "cabecalho"},
      {text: "Mês", value: "month", align: "center", class: "cabecalho"},
      {text: "Ano", value: "year", align: "center", class: "cabecalho"},
      {text: "Nome arquivo", sortable: false, value: "file_name", align: "center", class: "cabecalho"},
    ],
    filterName: 'monthlyReports',
    search: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    footerProps: {
      "items-per-page-text": "Linhas por página",
      "items-per-page-options": [5, 10, 15, 30, 50, 100],
    },
    options: {
      itemsPerPage: 15,
    },
    serverItemsLength: 0,
    typeReports: [],
    dialogMessage: false,
    typeMessage: "success",
    textMessage: "",
    breadcrumb: [
      {
        text: 'Listagens',
        disabled: true,
      },
      {
        text: 'Relatórios Mensais',
        disabled: false,
      },
    ],
  }),
  methods: {
    initialize() {
      this.isLoading = true;
      if (this.$store.getters.userToken) {
        this.axios
            .get("monthly_reports", {
              params: this.payload,
            })
            .then((response) => {
              this.reports = response.data.data;
              this.serverItemsLength = response.data.total;
            })
            .catch((error) => {
              let messagesError = "<ul>";
              Object.keys(error.response.data).forEach((key) => {
                messagesError += `<li>${error.response.data[key]} </li>`;
              });
              messagesError += "</ul>";

              this.dialogMessage = true;
              this.textMessage = messagesError;
              this.typeMessage = "error";
            })
            .finally(() => {
              this.isLoading = false;
            });
      } else {
        setTimeout(() => {
          this.initialize();
        }, 100);
      }
    },

    reset() {
      this.payload = {};
      this.setPagination();
      this.initialize();
    },

    setPagination() {
      this.payload["page"] = this.options.page;
      this.payload["page_size"] = this.options.itemsPerPage;
      this.payload["sortBy"] = this.options.sortBy[0];
      this.payload["sortDesc"] = 0;
      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.payload["sortDesc"] = this.options.sortDesc[0] ? 1 : 0;
      }
    },

    filter(payload) {
      this.payload = payload;
      this.setPagination();
      this.initialize();
    },

    fetchDatatable: async function () {
      this.isLoading = true;
      this.setPagination();
      this.initialize();
    },

    rowClicked(report) {
      this.downloadReport(report);
    },

    mountValuesFilterFields() {
      this.yearList = this.generateArrayOfYears();
      this.axios
          .get("monthly_reports/options")
          .then((response) => {
            let response_data = response.data;
            this.typeReports = response_data.type;
            this.createFilterFields();
          })
          .catch((error) => {
            this.dialogMessage = true;
            this.textMessage = error;
            this.typeMessage = "error";
          });
    },

    createFilterFields() {
      this.fields = [
        {
          type: "multiautocomplete",
          label: "Tipo relatório",
          sm: "3",
          id: "type_id",
          name: "type_id",
          payloadBackend: "type_id[or]",
          items: this.typeReports
        },
        {
          type: "multiautocomplete",
          label: "Mês",
          sm: "3",
          id: "id",
          name: "name",
          payloadBackend: "month[or]",
          items: this.monthList
        },
        {
          type: "multiautocomplete",
          label: "Ano",
          sm: "3",
          id: "id",
          name: "name",
          payloadBackend: "year[or]",
          items: this.yearList
        },
      ];

      let fieldsStore = this.getGlobalFilters(this.filterName);
      if (fieldsStore !== undefined) {
        this.fields = fieldsStore;

        let payload = {};

        this.fields.map(field => {
          if (field.value) {
            payload[field.payloadBackend] = field.value
          }
        });
        if (Object.keys(payload).length) {
          payload.page = 1;
          this.filter(payload);
        }
      }
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = '2020'
      var years = []

      for (var i = min; i <= max; i++) {
        years.push({id: i, name : i})
      }
      return years
    },
    downloadReport(item) {
      this.isLoading = true;
      this.axios
          .get(`/monthly_reports/download/${item.id}`)
          .then((response) => {
            this.createAndDownloadBlobFile(this.base64ToArrayBuffer(response.data.file), response.data.filename, response.data.extension);
          })
          .catch((error) => {
            this.modalEvidence = false;
            this.dialogMessage = true;
            console.log(error);
            this.textMessage = 'Ocorreu um erro inesperado';
            this.typeMessage = "error";
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
  created() {
    this.mountValuesFilterFields();
  },
}
</script>

<style lang="scss" scoped>
.monthly-reports-card {
  background-color: white;
  padding: 24px 30px;
  border-radius: 6px;
  margin-top: 20px;

  h2 {
    color: #5d5d5d;
    font-size: 18px;
    font-weight: bold;
  }

  .title_colunm {
    font-weight: bold !important;
    text-align: center !important;
  }

  td span.readmore {
    font-size: 14px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
  }
}
</style>
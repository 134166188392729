<template>
  <form>
    <vv-loading :is-loading="isLoading"></vv-loading>

    <v-card flat>
      <v-card-text class="py-8 px-6">
        <h2 class="title-form">Cadastro Iniciativa</h2>

        <v-row v-if="project.workflow_id == 5">
          <v-col cols="12">
            <v-alert dense icon="mdi-close-circle-outline" type="error">
              <p>
                <strong>Atenção!</strong> Esse macro tema foi rejeitado por
                {{ project.refused_user }} em
                {{ moment(project.refused_date).format("DD/MM/YYYY") }}.
              </p>
              <p><strong>Motivo:</strong> {{ project.refused_cause }}</p>
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="2" md="2" lg="1" cols="12">
            <label for="id">ID ideia</label>
            <v-autocomplete
              id="ideas_id"
              name="ideas_id"
              :items="ideas"
              no-data-text="Selecione uma ideia existente"
              v-model="project.ideas_id"
              item-text="title"
              item-value="id"
              append-icon="mdi-chevron-down"
              :disabled="isNotEditable"
            >
              <template #selection="{ item }">
                <v-chip>{{ item.id }}</v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col sm="2" md="2" lg="1" cols="12">
            <label for="id">ID iniciativa</label>
            <div class="edit-disabled" v-if="project.id">
              <v-chip label>{{ project.id }}</v-chip>
            </div>
            <div v-else>
              <div class="v-input v-text-field v-text-field--is-booted">
                <!-- style="background-color: #5d5d5d66" -->
                <div class="v-input__control">
                  <div class="v-input__slot">
                    <div class="v-text-field__slot">
                      <input disabled id="id" name="id" type="text" />
                      <v-icon style="cursor: default">mdi-lock</v-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col sm="8" md="4" lg="6" cols="12">
            <label for="name">Nome do Macro Tema</label>
            <div v-if="isEdition" class="">
              <!-- Adiciona a classe: 'edit-disabled', para poder ativar o modo leitura -->
              <v-text-field
                id="name"
                name="name"
                v-model="project.name"
                :error-messages="nameErrors"
                :counter="255"
                required
                :disabled="isNotEditable"
                @change="detectEditedFields('Nome do Macro Tema')"
                @input="$v.project.name.$touch()"
                @blur="$v.project.name.$touch()"
              ></v-text-field>
              <!-- <v-chip label>{{ project.name }}</v-chip> -->
            </div>
            <div v-else>
              <v-text-field
                id="name"
                name="name"
                v-model="project.name"
                :error-messages="nameErrors"
                :counter="255"
                required
                :disabled="isNotEditable"
                @change="detectEditedFields('Nome do Macro Tema')"
                @input="$v.project.name.$touch()"
                @blur="$v.project.name.$touch()"
              ></v-text-field>
            </div>
          </v-col>
          <v-col sm="12" md="4" lg="4" cols="12">
            <label for="id_jira_summary">Iniciativa Jira</label>
            <v-text-field
              id="id_jira_summary"
              name="id_jira_summary"
              v-model="project.id_jira_summary"
              :disabled="true"
            >
              <template v-slot:append-outer>
                <v-tooltip bottom :disabled="!disabledLink()">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        outlined
                        color="primary"
                        :disabled="disabledLink()"
                        @click="idJiraSummaryLinkOpen()"
                      >
                        {{ idJiraSummaryBtnLabel }}
                      </v-btn>
                    </div>
                  </template>
                  <span>Para vincular ao jira é necessário salvar a iniciativa.</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="4" cols="12">
            <label for="project_type">Tipo de Macro Tema</label>
            <v-autocomplete
              id="project_type"
              name="project_type"
              v-model="project.project_type"
              :items="project_types"
              item-text="name"
              item-value="id"
              chips
              :error-messages="projectTypeErrors"
              append-icon="mdi-chevron-down"
              required
              :disabled="isNotEditable"
              @change="
                $v.project.project_type.$touch();
                detectEditedFields('Tipo de Macro Tema');
              "
              @blur="$v.project.project_type.$touch()"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  class="chip-green"
                >
                  {{ item.name }}

                  <!-- Adicione esse atributo caso não queira que na edição seja possível remover o item 'v-if="!isEdition"' -->
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col sm="6" md="4" cols="12">
            <label for="areas_id">Área Responsável</label>
            <v-autocomplete
              id="areas_id"
              name="areas_id"
              v-model="project.areas_id"
              :items="responsible_areas"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              :error-messages="projectAreaErrors"
              required
              :disabled="isNotEditable"
              @change="
                resetAdFields();
                integrationLoad(project.areas_id);
                $v.project.areas_id.$touch();
                detectEditedFields('Área Responsável');
              "
              @blur="$v.project.areas_id.$touch()"
            >
            </v-autocomplete>
          </v-col>
          <v-col md="4" cols="12">
            <label for="stakeholders_id"
              >Responsável pelo Negócio/Stakeholder Negócio</label
            >
            <v-autocomplete
              id="stakeholders_id"
              name="stakeholders_id"
              v-model="project.stakeholders_id"
              no-data-text="Selecione uma opção de Área Responsável"
              :items="stakeholders_id"
              item-disabled="disabled"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              :error-messages="stakeholderErrors"
              required
              :disabled="isNotEditable"
              @change="
                integrationFocalPointLoad(project.areas_id, project.stakeholders_id);
                $v.project.stakeholders_id.$touch();
                detectEditedFields('Responsável pelo Negócio/Stakeholder Negócio');
              "
              @blur="$v.project.stakeholders_id.$touch()"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="6" cols="12">
            <label for="problem_solved"
              >Problema a ser Resolvido/Oportunidade identificada</label
            >
            <v-textarea
              id="problem_solved"
              name="problem_solved"
              v-model="project.problem_solved"
              class="scroll-style"
              filled
              :error-messages="problemSolvedErrors"
              required
              :disabled="isNotEditable"
              @change="
                detectEditedFields('Problema a ser Resolvido/Oportunidade identificada')
              "
              @input="$v.project.problem_solved.$touch()"
              @blur="$v.project.problem_solved.$touch()"
            ></v-textarea>
          </v-col>
          <v-col sm="6" md="6" cols="12">
            <label for="objective">Objetivo do Macro Tema</label>
            <v-textarea
              id="objective"
              name="objective"
              v-model="project.objective"
              class="scroll-style"
              filled
              :error-messages="objectiveErrors"
              required
              :disabled="isNotEditable"
              @change="detectEditedFields('Objetivo do Macro Tema')"
              @input="$v.project.objective.$touch()"
              @blur="$v.project.objective.$touch()"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="6" cols="12">
            <label for="scope">Escopo</label>
            <v-textarea
              id="scope"
              name="scope"
              v-model="project.scope"
              class="scroll-style"
              filled
              :error-messages="scopeErrors"
              required
              :disabled="isNotEditable"
              @change="detectEditedFields('Escopo')"
              @input="$v.project.scope.$touch()"
              @blur="$v.project.scope.$touch()"
            ></v-textarea>
          </v-col>
          <v-col sm="6" md="6" cols="12">
            <label for="premises">Premissas</label>
            <v-textarea
              id="premises"
              name="premises"
              v-model="project.premises"
              class="scroll-style"
              filled
              :error-messages="premisesErrors"
              required
              :disabled="isNotEditable"
              @change="detectEditedFields('Premissas')"
              @input="$v.project.premises.$touch()"
              @blur="$v.project.premises.$touch()"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="4" cols="12">
            <label for="process_change">Envolve Mudança de Processo</label>
            <v-switch
              id="process_change"
              name="process_change"
              color="secondary"
              class="manual-line switch"
              v-model="project.process_change"
              :label="project.process_change ? 'Sim' : 'Não'"
              :disabled="isNotEditable"
              @change="detectEditedFields('Envolve Mudança de Processo')"
              inset
            ></v-switch>
          </v-col>
          <v-col sm="6" md="4" cols="12">
            <label for="it_area">Envolve TI</label>
            <v-autocomplete
              id="it_area"
              name="it_area"
              v-model="project.it_area"
              :items="it_areas"
              item-text="name"
              item-value="id"
              chips
              multiple
              append-icon="mdi-chevron-down"
              :error-messages="itAreaErrors"
              required
              :disabled="isNotEditable"
              @change="
                itValidate();
                $v.project.it_area.$touch();
                detectEditedFields('Envolve TI');
              "
              @blur="$v.project.it_area.$touch()"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  class="chip-green"
                >
                  {{ item.name }}
                  <v-icon @click="removeItAreas(item)" size="16" class="remove-chip"
                    >mdi-close
                  </v-icon>
                  <!-- Adicione esse atributo caso não queira que na edição seja possível remover o item 'v-if="!isEdition"' -->
                </v-chip>
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action v-if="item.active">
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content v-if="item.active">
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.name }}</span>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col md="4" cols="12">
            <label for="impacted_areas">Outras Áreas Envolvidas/Impactadas</label>
            <v-autocomplete
              id="impacted_areas"
              name="impacted_areas"
              v-model="project.impacted_areas"
              :items="impacted_areas"
              item-text="name"
              item-value="id"
              chips
              multiple
              append-icon="mdi-chevron-down"
              :error-messages="impactedAreasErrors"
              required
              :disabled="isNotEditable"
              @change="
                impactedAreasValidate();
                $v.project.impacted_areas.$touch();
                detectEditedFields('Outras Áreas Envolvidas/Impactadas');
              "
              @blur="$v.project.impacted_areas.$touch()"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  class="chip-green"
                >
                  {{ item.name }}
                  <v-icon @click="removeImpactedAreas(item)" size="16" class="remove-chip"
                    >mdi-close
                  </v-icon>
                  <!-- Adicione esse atributo caso não queira que na edição seja possível remover o item 'v-if="!isEdition"' -->
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="4" cols="12">
            <label for="focal_points">Pontos Focais da Área Responsável</label>
            <v-autocomplete
              id="focal_points"
              name="focal_points"
              v-model="project.focal_points"
              no-data-text="Selecione uma opção de Ponto Focal"
              :items="focal_points"
              item-text="name"
              item-value="id"
              item-disabled="disabled"
              chips
              multiple
              append-icon="mdi-chevron-down"
              :error-messages="focalPointsErrors"
              required
              :disabled="isNotEditable"
              @change="
                $v.project.focal_points.$touch();
                detectEditedFields('Pontos Focais da Área Responsável');
              "
              @blur="$v.project.focal_points.$touch()"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  class="chip-green"
                >
                  {{ item.name }}
                  <v-icon @click="removeFocalPoints(item)" size="16" class="remove-chip"
                    >mdi-close
                  </v-icon>
                  <!-- Adicione esse atributo caso não queira que na edição seja possível remover o item 'v-if="!isEdition"' -->
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col sm="6" md="4" cols="12">
            <label for="transformation_managers_id">Gerente Escritório Transformação</label>
            <v-autocomplete
              id="transformation_managers_id"
              name="transformation_managers_id"
              v-model="project.transformation_managers_id"
              no-data-text="Selecione uma opção de Área Responsável"
              :items="transformation_managers"
              item-text="name"
              item-value="id"
              item-disabled="active"
              append-icon="mdi-chevron-down"
              :error-messages="changeOfficeManagerErrors"
              required
              :disabled="isNotEditable"
              @change="
                $v.project.transformation_managers_id.$touch();
                detectEditedFields('Gerente Escritório de Transformação');
              "
              @blur="$v.project.transformation_managers_id.$touch()"
            >
            </v-autocomplete>
          </v-col>
          <v-col sm="6" md="4" cols="12">
            <label for="strategic_indicators_id"
              >Principal Direcionador Estratégico</label
            >
            <v-autocomplete
              id="strategic_indicators_id"
              name="strategic_indicators_id"
              v-model="project.strategic_indicators_id"
              no-data-text="Nenhum indicador cadastrado!"
              :items="strategic_indicators"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              :error-messages="strategicIndicatorsErrors"
              required
              :disabled="isNotEditable"
              @change="
                strategicIndicatorsValidate();
                $v.project.strategic_indicators_id.$touch();
                detectEditedFields('Principal Direcionador Estratégico');
              "
              @blur="$v.project.strategic_indicators_id.$touch()"
            >
            </v-autocomplete>
          </v-col>
          <v-col sm="6" md="2" cols="12">
            <label for="criticality_id">Criticidade</label>
            <v-autocomplete
              id="criticality_id"
              name="criticality_id"
              v-model="project.criticality_id"
              :items="criticalities"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              :error-messages="criticalityErrors"
              required
              :disabled="isNotEditable"
              @change="
                $v.project.criticality_id.$touch();
                detectEditedFields('Criticidade');
              "
              @blur="$v.project.criticality_id.$touch()"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  :style="
                    'background-color: ' +
                    criticalityColor(item.id, true, 'bg') +
                    '; color: ' +
                    criticalityColor(item.id, true, 'fc') +
                    '; width: 90%'
                  "
                >
                  {{ item.name }}
                </v-chip>
              </template>
              <template #item="{ item }">
                <div
                  tabindex="0"
                  role="option"
                  class="v-list-item v-list-item--link"
                  :style="
                    'background-color: ' +
                    criticalityColor(item.id, true, 'bg') +
                    '; color: ' +
                    criticalityColor(item.id, true, 'fc')
                  "
                >
                  <div class="v-list-item__content" v-if="item.active">
                    <div class="v-list-item__title">{{ item.name }}</div>
                  </div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col sm="6" md="2" cols="12">
            <label for="priority">Prioridade</label>
            <div class="edit-disabled" v-if="project.priority">
              <v-chip label>{{ project.priority }}</v-chip>
            </div>
            <div v-else>
              <div class="v-input v-text-field v-text-field--is-booted">
                <div class="v-input__control">
                  <div class="v-input__slot">
                    <div class="v-text-field__slot">
                      <input disabled id="priority" name="priority" type="text" />
                      <v-icon style="cursor: default">mdi-lock</v-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" cols="12" class="d-none d-sm-block">
            <label for="delivery_expectations">Expectativa de Entrega</label>
            <vv-table-editor
              v-model="project.delivery_expectations"
              :headers="header_delivery_expectations"
              :columns="columns_delivery_expectations"
              :data="project.delivery_expectations"
              :number-columns-required="3"
              :vv-errors="deliveryExpectationsErrors"
              :name="'delivery_expectations'"
              :label="'Expectativa de Entrega'"
              :error-messages="deliveryExpectationsErrorMessage"
              @valid="validVvTableEditor"
              @change="detectEditedFields('Expectativa de Entrega')"
              :disabled="isNotEditable"
            ></vv-table-editor>
          </v-col>

          <v-col md="12" cols="12" class="d-block d-sm-none">
            <label for="delivery_expectations">Expectativa de Entrega</label>
            <vv-table-editor-mobile
              v-model="project.delivery_expectations"
              :headers="header_delivery_expectations"
              :columns="columns_delivery_expectations"
              :data="project.delivery_expectations"
              :number-columns-required="3"
              :vv-errors="deliveryExpectationsErrors"
              :name="'delivery_expectations'"
              :label="'Expectativa de Entrega'"
              :error-messages="deliveryExpectationsErrorMessage"
              @valid="validVvTableEditor"
              @change="detectEditedFields('Expectativa de Entrega')"
              :disabled="isNotEditable"
            ></vv-table-editor-mobile>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="3" cols="12">
            <label for="capex">Necessita de CAPEX?</label>
            <v-switch
              id="capex"
              name="capex"
              v-model="project.capex"
              class="manual-line switch"
              color="secondary"
              :label="project.capex ? 'Sim' : 'Não'"
              inset
              @change="
                validBcFields();
                detectEditedFields('Necessita de CAPEX?');
              "
              :disabled="isNotEditable"
            ></v-switch>
          </v-col>
          <v-col md="3" cols="12">
            <label for="opex">Necessita de OPEX?</label>
            <v-switch
              id="opex"
              name="opex"
              v-model="project.opex"
              class="manual-line switch"
              color="secondary"
              :label="project.opex ? 'Sim' : 'Não'"
              inset
              @change="
                validBcFields();
                detectEditedFields('Necessita de OPEX?');
              "
              :disabled="isNotEditable"
            ></v-switch>
          </v-col>
          <v-col md="3" cols="12">
            <label for="estimated_cost">Custo Estimado Total</label>
            <div v-if="isEdition" class="">
              <!-- Adiciona a classe: 'edit-disabled', para poder ativar o modo leitura -->
              <vuetify-money
                v-model="project.estimated_cost"
                v-bind:valueWhenIsEmpty="''"
                v-bind:options="moneyOptions"
                :error-messages="estimatedCostErrors"
                required
                :disabled="isNotEditable"
                @input="detectEditedFields('Custo Estimado Total')"
                @blur="detectEditedFields('Custo Estimado Total')"
              />
              <!-- <v-chip label>{{ floatToMoney(project.estimated_cost) }}</v-chip> -->
            </div>
            <div v-else>
              <vuetify-money
                v-model="project.estimated_cost"
                v-bind:disabled="isEdition"
                v-bind:valueWhenIsEmpty="''"
                v-bind:options="moneyOptions"
                :error-messages="estimatedCostErrors"
                required
                @input="detectEditedFields('Custo Estimado Total')"
                @blur="detectEditedFields('Custo Estimado Total')"
              />
            </div>
          </v-col>

          <v-col md="3" cols="12">
            <label for="investment_order">Ordem de Investimento</label>
            <div>
              <v-text-field
                id="investment_order"
                name="investment_order"
                v-model="project.investment_order"
                :error-messages="investment_order_error"
                @keypress="isNumber($event, project.investment_order, 9)"
                @input="notpaste('investment_order')"
                @blur="clearError()"
                @change="detectEditedFields('Ordem de Investimento')"
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col md="12" lg="4" sm="12" cols="12">
            <label for="bc_flag">Necessita BC?</label>
            <v-switch
              id="bc_flag"
              name="bc_flag"
              v-model="project.bc_flag"
              class="manual-line switch"
              color="secondary"
              :label="project.bc_flag ? 'Sim' : 'Não'"
              inset
              @change="
                detectEditedFields('Necessita BC?');
                verifyErrors();
              "
              v-bind:disabled="
                this.project.opex === true || this.project.capex === true || isNotEditable
                  ? true
                  : false
              "
            ></v-switch>
          </v-col>

          <v-col md="6" sm="12" lg="4" cols="12">
            <label for="special_project">Macro Tema Especial</label>
            <v-autocomplete
              id="special_project"
              name="special_project"
              v-model="project.special_project"
              :items="special_projects"
              item-text="name"
              item-value="id"
              chips
              multiple
              :error-messages="specialProjectErrors"
              append-icon="mdi-chevron-down"
              required
              :disabled="isNotEditable"
              @change="
                specialProjectValidate();
                $v.project.special_project.$touch();
                detectEditedFields('Macro Tema Especial');
              "
              @blur="$v.project.special_project.$touch()"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  class="chip-green"
                >
                  {{ item.name }}
                  <v-icon
                    @click="removeSpecialProject(item)"
                    size="16"
                    class="remove-chip"
                    >mdi-close
                  </v-icon>
                  <!-- Adicione esse atributo caso não queira que na edição seja possível remover o item 'v-if="!isEdition"' -->
                </v-chip>
              </template>
              <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action v-if="item.active">
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content v-if="item.active">
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.name }}</span>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col v-if="existFile" md="6" sm="12" lg="4" xs="12" cols="12">
            <label for="bc_file">Arquivo BC </label>
            <v-btn
              @click="downloadBcfile()"
              color="secondary"
              class="btn-save-project mx-2"
              v-model="project.bc_file"
              :title="'Baixar ' + project.bc_file_name"
            >
              <v-icon>mdi-download-outline</v-icon>
            </v-btn>
            <v-btn
              @click="habilitaUpload()"
              color="warning"
              class="btn-save-project mx-2"
              title="Substituir arquivo"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn
              @click="deleteBcfile()"
              color="error"
              class="btn-save-project mx-2"
              title="Excluir arquivo"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="!existFile" md="6" sm="12" cols="12" lg="4">
            <label for="bc_file">Upload BC (.xls ou .xlsx)</label>
            <v-file-input
              show-size
              counter
              ref="bc_file"
              :disabled="isNotEditable"
              @change="detectEditedFields('Upload BC');clearErrorMessage('bc_file')"
              v-model="project.bc_file"
              :error-messages="bc_fileErrors"
              accept=".xls, .xlsx, .xlsb, .xlsm"
            >
              ></v-file-input
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" cols="12" class="d-none d-sm-block">
            <label for="indicators">Tabela de Indicadores</label>
            <vv-table-editor
              v-model="project.project_indicators"
              :headers="header_indicators"
              :data="project.project_indicators"
              :number-columns-required="7"
              :vv-errors="indicatorsErrors"
              :name="'project_indicators'"
              :label="'tabela de indicadores'"
              :error-messages="indicatorsErrorMessage"
              @valid="validTableIndicators()"
              @change="detectEditedFields('Tabela de Indicadores')"
              :disabled="isNotEditable"
            ></vv-table-editor>
          </v-col>

          <v-col md="12" cols="12" class="d-block d-sm-none">
            <label for="indicators">Tabela de Indicadores</label>
            <vv-table-editor-mobile
              v-model="project.project_indicators"
              :headers="header_indicators"
              :data="project.project_indicators"
              :number-columns-required="7"
              :vv-errors="indicatorsErrors"
              :name="'project_indicators'"
              :label="'tabela de indicadores'"
              :error-messages="indicatorsErrorMessage"
              @valid="validTableIndicators()"
              @change="detectEditedFields('Tabela de Indicadores')"
              :disabled="isNotEditable"
            ></vv-table-editor-mobile>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col v-if="editedFields.length > 0 && isEdition" cols="12" align="end">
        <v-btn
          v-if="!isNotEditable"
          @click="
            confirmEditing(
              !project.workflow_id ||
                project.workflow_id == CONST_WORKFLOW.TAP_PREENCHIMENTO ||
                project.workflow_id == CONST_WORKFLOW.REJEITADO
            )
          "
          :loading="isLoading"
          color="secondary"
          class="btn-save-project"
        >
          Salvar
        </v-btn>
        <v-btn
          v-if="
            !project.workflow_id ||
            project.workflow_id == CONST_WORKFLOW.TAP_PREENCHIMENTO ||
            project.workflow_id == CONST_WORKFLOW.REJEITADO
          "
          @click="confirmEditing(false)"
          :loading="isLoading"
          color="primary"
          class="btn-save-project mx-5"
        >
          Submeter à Aprovação
        </v-btn>
      </v-col>
      <v-col v-else cols="12" class="align-end" align="end">
        <vv-priority
          v-if="showPriority()"
          @changePriority="changePriority"
          :area_name="name_responsible_areas"
          :area_id="project.areas_id"
        ></vv-priority>
        <v-btn
          v-if="!isNotEditable && !user_is_transformation_manager"
          :loading="isLoading"
          @click="
            save(
              !project.workflow_id ||
                project.workflow_id == CONST_WORKFLOW.TAP_PREENCHIMENTO
            )
          "
          color="secondary"
          class="btn-save-project"
        >
          Salvar
        </v-btn>
        <v-btn
          v-if="
            (!project.workflow_id ||
              project.workflow_id == CONST_WORKFLOW.TAP_PREENCHIMENTO) &&
            !project.submited
          "
          @click="save(false)"
          :loading="isLoading"
          color="primary"
          class="btn-save-project"
        >
          Submeter à Aprovação
        </v-btn>
      </v-col>
    </v-row>

    <hr v-if="exibeLayoutMensagens()" />

    <v-row v-if="exibeLayoutMensagens()">
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header> Histórico Workflow </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="project.workflow_history">
                <v-row>
                  <v-col cols="12">
                    <v-card class="mx-auto" outlined>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <p>
                            Status:
                            <strong>{{
                              project.workflow_history[
                                project.workflow_history.length - 1
                              ].workflow_name
                            }}</strong>
                            atualizado por
                            <strong>{{
                              project.workflow_history[
                                project.workflow_history.length - 1
                              ].user_name
                            }}</strong>
                            <v-tooltip
                              top
                              v-if="
                                project.workflow_history[
                                  project.workflow_history.length - 1
                                ].user_original_name
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  class="ml-1"
                                >
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span>
                                Aprovação delegada por:
                                {{
                                  project.workflow_history[
                                    project.workflow_history.length - 1
                                  ].user_original_name
                                }}
                              </span>
                            </v-tooltip>
                            em
                            <strong>{{
                              moment(
                                project.workflow_history[
                                  project.workflow_history.length - 1
                                ].date
                              ).format("DD/MM/YYYY HH:mm")
                            }}</strong>
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div v-for="(message, index) in project.messages" :key="index">
                <v-row>
                  <v-col cols="12">
                    <v-card class="mx-auto" outlined>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <strong
                            >{{ message.user_name }} perguntou em
                            {{ message.updated_at }}:</strong
                          ><br />
                          {{ message.message }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>

                <div v-if="message.answer.length">
                  <div v-for="(answer, indexanswer) in message.answer" :key="indexanswer">
                    <v-row>
                      <v-col cols="10" offset="2">
                        <v-card class="mx-auto" outlined align>
                          <v-list-item three-line>
                            <v-list-item-content>
                              <strong
                                >{{ answer.user_name }} respondeu em
                                {{ answer.updated_at }}:</strong
                              ><br />
                              {{ answer.message }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <div v-else-if="project.workflow_id == 6" class="mt-10">
                  <label>Resposta</label>
                  <v-textarea
                    v-model="message.answer_text"
                    :error-messages="message.answer_error"
                    class="scroll-style"
                    filled
                  >
                  </v-textarea>
                  <v-btn @click="sendAnswer(index)" color="success"
                    >Responder Pergunta</v-btn
                  >
                </div>
                <hr class="mt-5 mb-5" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogMessage" hide-overlay persistent width="400">
      <v-alert dense type="success" style="margin: 0">
        {{ textMessage }}
      </v-alert>
    </v-dialog>

    <v-dialog v-model="dialogMessageError" hide-overlay width="500">
      <v-alert prominent type="error" style="margin: 0">
        <div v-for="errorMessage in textMessageError" :key="errorMessage">
          {{ errorMessage }}
        </div>
      </v-alert>
    </v-dialog>

    <v-dialog v-model="dialogSimpleMessageError" hide-overlay width="500">
      <v-alert prominent type="error" style="margin: 0">
        {{ dialogSimpleMessageErrorText }}
      </v-alert>
    </v-dialog>

    <v-dialog v-model="editedFieldsMessage" width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2"> Você tem certeza? </v-card-title>

        <v-card-text class="pa-6">
          <p><b>Você confirma a edição dos campos abaixo?</b></p>
          <ul>
            <li v-for="field in editedFields" :key="field">{{ field }}</li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="this.isDraft" color="primary" text @click="save(true)">
            Confirmo
          </v-btn>
          <v-btn v-else color="primary" text @click="save(false)"> Confirmo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="snackbar" hide-overlay persistent width="400">
      <v-alert dense type="error" style="margin: 0">
        <div style="display: flex; justify-content: space-between">
          <span>Confira todos os campos do formulário</span>
          <v-button @click="snackbar = false">
            <v-icon> mdi-window-close </v-icon>
          </v-button>
        </div>
      </v-alert>
    </v-dialog>

    <v-dialog v-model="dialogIdJiraSummary.open" persistent width="500">
      <v-card>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5">
              {{ dialogIdJiraSummary.title }}
            </v-list-item-title>
            <v-list-item-subtitle class="pt-2">{{
              dialogIdJiraSummary.message
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-text v-if="project.id_jira_summary == ''">
          <v-row>
            <v-col sm="12" md="12" cols="12">
              <label for="issues">Iniciativas do Jira</label>
              <v-autocomplete
                id="issues"
                name="issues"
                v-model="issueLinked"
                :items="issues"
                item-text="id_jira_summary"
                item-value="id"
                append-icon="mdi-chevron-down"
                no-data-text="Problema na conexão com o Jira"
                placeholder="Selecione a iniciativa"
                :error-messages="erroridJiraSummarySave"
                @change="erroridJiraSummarySave = ''"
                required
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-btn color="blue darken-1" text @click="dialogIdJiraSummary.open = false">
                {{ dialogIdJiraSummary.btnCancel }}
              </v-btn>
              <v-btn color="primary" @click="idJiraSummarySave()">
                {{ dialogIdJiraSummary.btnSave }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </form>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import VvPriority from "@/components/VvPriority";
import VvTableEditor from "@/components/VvTableEditor";
import VvTableEditorMobile from "@/components/VvTableEditorMobile";
import { validationMixin } from "vuelidate";
import {
  integer,
  required,
  maxLength,
  requiredIf,
  minValue,
} from "vuelidate/lib/validators";
import { getUrlParam } from "@/services/utils";
import ideaServiceFactory from "@/services/ideaService";

const ideaService = ideaServiceFactory();
const requiredIfCapexOpexSpecial = requiredIf(function () {
  return this.isRequired;
});
const requiredIfCapexOrOpex = requiredIf(function () {
  return this.isCostRequired;
});
const requiredMinVal = requiredIf(function () {
  if (this.isCostRequired) {
    return minValue(0.001);
  }
  return false;
});
export default {
  name: "Tap",
  components: {
    VvTableEditor,
    VvTableEditorMobile,
    VvLoading,
    VvPriority,
  },
  mixins: [validationMixin],
  props: {
    SavedDemandingAreas: {
      type: Array,
    },
  },
  data: () => ({
    errors: {
      bc_file: null,
    },
    moment: require("moment"),
    isDraft: false,
    editedFields: [],
    isNotEditable: false,
    existFile: false,
    isEdition: false,
    isLoading: true,
    dialogMessage: false,
    dialogMessageError: false,
    dialogIdJiraSummary: {
      open: false,
      title: "Vincular Jira",
      message: "Selecione a iniciativa do Jira",
      btnCancel: "Cancelar",
      btnSave: "Salvar",
    },
    editedFieldsMessage: false,
    textMessage: false,
    textMessageError: "",
    dialogSimpleMessageError: false,
    dialogSimpleMessageErrorText: "",
    project: {
      process_change: false,
      delivery_expectations: [],
      capex: false,
      opex: false,
      investment_order: "",
      project_indicators: [],
      submited: false,
      id_jira_summary: "",
      ideas_id: null,
    },
    issueLinked: "",
    issues: [],
    erroridJiraSummarySave: "",
    project_types: [],
    it_areas: [],
    criticalities: [],
    priorities: [1, 2, 3, 4],
    focal_points: [],
    responsible_areas: [],
    stakeholders_id: [],
    transformation_managers: [],
    strategic_indicators: [],
    header_delivery_expectations: [
      {
        edit: true,
        type: "number",
        text: "Prioridade",
        value: "priority",
        sortable: false,
      },
      {
        edit: true,
        type: "text",
        text: "Entrega de valor",
        value: "activity",
        sortable: false,
      },
      {
        edit: true,
        type: "month",
        text: "Data",
        value: "date",
        sortable: false,
      },
      {
        type: "actions",
        text: "Ações",
        value: "actions",
        sortable: false,
        width: "5%",
      },
    ],
    columns_delivery_expectations: ["priority", "activity", "date"],
    header_indicators: [
      {
        edit: true,
        type: "text",
        text: "KPI",
        value: "kpi",
        sortable: false,
      },
      {
        edit: true,
        type: "text",
        text: "Unidade de medida",
        value: "unit_measure",
        sortable: false,
      },
      {
        edit: true,
        type: "select",
        text: "Comportamento Esperado",
        value: "expected_behavior",
        sortable: false,
        items: [
          {
            id: 1,
            name: "Aumentar",
          },
          {
            id: 2,
            name: "Diminuir",
          },
        ],
      },
      {
        edit: true,
        type: "text",
        text: "Baseline (Atual)",
        value: "baseline",
        sortable: false,
        tooltip:
          "Considerar os valores dos últimos 3 a 12 meses de acordo com a disponibilidade dos dados",
      },
      {
        edit: true,
        type: "text",
        text: "Expectativa (Final do macro tema)",
        value: "expectation",
        sortable: false,
      },
      {
        edit: true,
        type: "text",
        text: "Fonte da Informação (Sistema)",
        value: "source_information",
        sortable: false,
      },
      {
        edit: true,
        type: "text",
        text: "Responsável pela atualização",
        value: "responsible_updating",
        sortable: false,
      },
      {
        edit: false,
        type: "text",
        text: "Expectativa revisada",
        value: "revised_expectation",
        sortable: false,
      },
      {
        edit: false,
        type: "text",
        text: "Motivo da Revisão",
        value: "reason_review",
        sortable: false,
      },
      {
        type: "actions_plus",
        text: "Ações",
        value: "actions",
        sortable: false,
        width: "5%",
      },
    ],
    columns_indicators: [
      "kpi",
      "unit_measure",
      "expected_behavior",
      "baseline",
      "expectation",
      "source_information",
      "responsible_updating",
    ],
    impacted_areas: [],
    moneyReadonly: false,
    moneyDisabled: false,
    moneyValueWhenIsEmpty: "",
    moneyOptions: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 19,
      precision: 2,
    },
    messageFields: {
      required: "Campo obrigatório",
      max: "Limite de caracteres excedido",
      integer: "O campo precisa ser do tipo inteiro",
      minValue: "Informe valor",
    },
    snackbar: false,
    deliveryExpectationsErrors: false,
    deliveryExpectationsErrorMessage: "",
    indicatorsErrors: false,
    indicatorsErrorMessage: "",
    bc_flag: false,
    bc_file: "",
    name_responsible_areas: "",
    user_is_transformation_manager: false,
    special_projects: [],
    investment_order_error: "",
    legacy_focal_points: [],
    ideas: [],
  }),

  validations: {
    project: {
      name: { required, maxLength: maxLength(255) },
      project_type: { required },
      areas_id: { required },
      stakeholders_id: { required },
      problem_solved: { required },
      objective: { required },
      scope: { required },
      premises: { required },
      it_area: { required },
      impacted_areas: { required },
      focal_points: { required },
      transformation_managers_id: { required },
      strategic_indicators_id: { required },
      criticality_id: { required },
      bc_file: {
        required: requiredIfCapexOpexSpecial,
      },
      estimated_cost: {
        required: requiredIfCapexOrOpex,
        minValue: requiredMinVal,
      },
      special_project: { required },
      investment_order: { integer },
    },
  },

  computed: {
    isRequired() {
      return this.project.capex || this.project.opex || this.project.bc_flag;
    },
    isCostRequired() {
      return this.project.capex || this.project.opex;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.project.name.$dirty) return errors;
      !this.$v.project.name.maxLength && errors.push(this.messageFields.max);
      !this.$v.project.name.required && errors.push(this.messageFields.required);
      return errors;
    },
    projectTypeErrors() {
      const errors = [];
      if (!this.$v.project.project_type.$dirty) return errors;
      !this.$v.project.project_type.required && errors.push(this.messageFields.required);
      return errors;
    },
    projectAreaErrors() {
      const errors = [];
      if (!this.$v.project.areas_id.$dirty) return errors;
      !this.$v.project.areas_id.required && errors.push(this.messageFields.required);
      return errors;
    },
    stakeholderErrors() {
      const errors = [];
      if (!this.$v.project.stakeholders_id.$dirty) return errors;
      !this.$v.project.stakeholders_id.required &&
        errors.push(this.messageFields.required);
      return errors;
    },
    problemSolvedErrors() {
      const errors = [];
      if (!this.$v.project.problem_solved.$dirty) return errors;
      !this.$v.project.problem_solved.required &&
        errors.push(this.messageFields.required);
      return errors;
    },
    objectiveErrors() {
      const errors = [];
      if (!this.$v.project.objective.$dirty) return errors;
      !this.$v.project.objective.required && errors.push(this.messageFields.required);
      return errors;
    },
    scopeErrors() {
      const errors = [];
      if (!this.$v.project.scope.$dirty) return errors;
      !this.$v.project.scope.required && errors.push(this.messageFields.required);
      return errors;
    },
    premisesErrors() {
      const errors = [];
      if (!this.$v.project.premises.$dirty) return errors;
      !this.$v.project.premises.required && errors.push(this.messageFields.required);
      return errors;
    },
    itAreaErrors() {
      const errors = [];
      if (!this.$v.project.it_area.$dirty) return errors;
      !this.$v.project.it_area.required && errors.push(this.messageFields.required);
      return errors;
    },
    impactedAreasErrors() {
      const errors = [];
      if (!this.$v.project.impacted_areas.$dirty) return errors;
      !this.$v.project.impacted_areas.required &&
        errors.push(this.messageFields.required);
      return errors;
    },
    focalPointsErrors() {
      const errors = [];
      if (!this.$v.project.focal_points.$dirty) return errors;
      !this.$v.project.focal_points.required && errors.push(this.messageFields.required);
      return errors;
    },
    changeOfficeManagerErrors() {
      const errors = [];
      if (!this.$v.project.transformation_managers_id.$dirty) return errors;
      !this.$v.project.transformation_managers_id.required &&
        errors.push(this.messageFields.required);
      return errors;
    },
    criticalityErrors() {
      const errors = [];
      if (!this.$v.project.criticality_id.$dirty) return errors;
      !this.$v.project.criticality_id.required &&
        errors.push(this.messageFields.required);
      return errors;
    },
    priorityErrors() {
      const errors = [];
      if (!this.$v.project.priority.$dirty) return errors;
      !this.$v.project.priority.required && errors.push(this.messageFields.required);
      return errors;
    },
    idJiraSummaryBtnLabel() {
      return typeof this.project.id_jira_summary !== "undefined" &&
        this.project.id_jira_summary !== ""
        ? "DESVINCULAR INICIATIVA"
        : "VINCULAR INICIATIVA";
    },
    estimatedCostErrors() {
      const errors = [];
      if (this.project.opex || this.project.capex) {
        if (!this.project.estimated_cost) {
          errors.push(this.messageFields.required);
        }
        if (this.project.estimated_cost < 0.001) {
          errors.push(this.messageFields.minValue);
        }
      }
      return errors;
    },
    bc_fileErrors() {
      if (this.errors.bc_file != null) {
        return this.errors.bc_file;
      }
      const errors = [];
      const validFormats = [
        "application/vnd.ms-excel",
        "application/msexcel",
        "application/x-msexcel",
        "application/x-ms-excel",
        "application/x-excel",
        "application/x-dos_ms_excel",
        "application/xls",
        "application/x-xls",
        "application/vnd.openxmlformats-",
        "officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
        "application/wps-office.xlsx",
      ];
      if (!this.$v.project.bc_file.$dirty) return errors;

      if (!this.$v.project.bc_file.required) {
        errors.push(this.messageFields.required);
      }
      if (this.project.bc_file !== null && typeof this.project.bc_file !== "undefined") {
        if (validFormats.indexOf(this.project.bc_file.type) == -1) {
          errors.push("Selecione apenas arquivos .xlsx, .xls, .xlsb, ou .xlsm");
        }
      }
      return errors;
    },
    specialProjectErrors() {
      const errors = [];
      if (!this.$v.project.special_project.$dirty) return errors;
      !this.$v.project.special_project.required &&
        errors.push(this.messageFields.required);
      return errors;
    },
    strategicIndicatorsErrors() {
      const errors = [];
      if (!this.$v.project.strategic_indicators_id.$dirty) return errors;
      !this.$v.project.strategic_indicators_id.required &&
        errors.push(this.messageFields.required);
      return errors;
    },
    investmentOrderError() {
      const errors = [];
      if (!this.$v.project.investment_order.$dirty) return errors;
      !this.$v.project.investment_order.integer &&
        errors.push(this.messageFields.integer);
      return errors;
    },
  },

  dialogMessage(val) {
    if (!val) return;

    setTimeout(function () {
      this.dialogMessage = false;
      this.textMessage = "";
    }, 2000);
  },

  watch: {
    "$route.path": "inputsLoad",
  },

  created() {
    this.inputsLoad();

    const ideaId = Number(getUrlParam("ideia"));
    this.project.ideas_id = ideaId;
    this.ideas = this.getListIdeas(ideaId);
  },

  methods: {
    inputsLoad() {
      this.isEdition = !!this.$route.params.id;
      const route = this.$route.params.id
        ? `options/tap/${this.$route.params.id}`
        : `options/tap/`;
      this.axios
        .get(route)
        .then((response) => {
          this.project_types = response.data.options.project_types;
          this.removeUnusedProjectTypes();
          this.it_areas = response.data.options.it_areas;
          this.criticalities = response.data.options.criticalities;
          this.activities = response.data.options.activities;
          this.responsible_areas = response.data.options.responsible_areas.filter(
            (responsible_area) => {
              return responsible_area.id > 1;
            }
          );
          this.impacted_areas = response.data.options.responsible_areas;
          this.strategic_indicators = response.data.options.strategic_indicators;
          this.special_projects = response.data.options.special_projects;
          this.startNoApply(this.it_areas, 4);
          this.startNoApply(this.impacted_areas, 1);
          this.startNoApply(this.strategic_indicators, 6);
          this.startNoApply(this.special_projects, 6);
        })
        .finally(() => {
          this.formLoad();
        });
    },

    integrationLoad: function (item) {
      let stakeholder = this.responsible_areas.filter((value) => {
        return value.id === item;
      });

      this.stakeholders_id = stakeholder[0].stakeholders_id;
      this.stakeholders_id.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.transformation_managers = stakeholder[0].transformation_managers;
    },

    integrationFocalPointLoad: function (area_id, stakeholders_id, autoload = false) {
      let responsible_area = this.responsible_areas.find((value) => {
        return value.id === area_id;
      });
      if (!autoload) {
        this.project.focal_points = [];
      }
      let stakeholder = responsible_area.stakeholders_id.find((value) => {
        return value.id === stakeholders_id;
      });

      this.focal_points = stakeholder?.focalPoints || [];
      this.addLegacyFocalPointToOptions();

      let loginUserId = this.$store.getters.id;
      if (stakeholder.id == loginUserId) {
        this.isNotEditable = false;
      }
      this.focal_points.map((focal_point) => {
        if (focal_point.id == loginUserId) {
          this.isNotEditable = false;
        }
      });
    },

    processLegacyFocalPoints: function () {
      this.legacy_focal_points = this.project.focal_points.filter((FocalPoint) => {
        return !this.focal_points.includes(FocalPoint.id);
      });

      this.project.focal_points = this.project.focal_points.map((o) => o.id);

      this.addLegacyFocalPointToOptions();
    },

    addLegacyFocalPointToOptions: function () {
      this.legacy_focal_points.forEach((FocalPoint) => {
        this.focal_points.push({
          active: false,
          disabled: true,
          id: FocalPoint.id,
          name: FocalPoint.name,
        });
      });
    },

    formLoad: function () {
      if (this.isEdition) {
        this.axios
          .get("project/" + this.$route.params.id)
          .then((response) => {
            this.project = response.data.project;
            const ideaId = this.project.ideas_id !== null ? this.project.ideas_id : 0;
            this.ideas = this.getListIdeas(ideaId)
            let workflow_id = this.project.workflow_id;
            let users_id = this.project.users_id;
            let loginUserId = this.$store.getters.id;
            let loginUserTypeId = this.$store.getters.type;

            this.isNotEditable = true;

            if (
              workflow_id !== this.CONST_WORKFLOW.TAP_PREENCHIMENTO &&
              workflow_id !== this.CONST_WORKFLOW.AGUARDANDO_REVISAO_SOLICITANTE
            ) {
              if (workflow_id === this.CONST_WORKFLOW.AGUARDANDO_APROVACAO) {
                if (this.project.focal_points.indexOf(loginUserId) >= 0) {
                  this.isNotEditable = false;
                }
              }

              if (workflow_id === this.CONST_WORKFLOW.APROVADO_GESTOR) {
                if (this.project.stakeholders_id === loginUserId) {
                  this.isNotEditable = false;
                }
              }
            } else {
              if (users_id === loginUserId) {
                this.isNotEditable = false;
              }
            }
            if (
              Array.isArray(loginUserTypeId) &&
              (loginUserTypeId.indexOf(
                this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO
              ) > -1 ||
                loginUserTypeId.indexOf(this.CONST_USER_TYPE.USUARIO_MASTER) > -1)
            ) {
              this.isNotEditable = false;
            }

            if (this.project.areas_id !== null) {
              this.integrationLoad(this.project.areas_id);
              if (this.project.stakeholders_id !== null) {
                this.integrationFocalPointLoad(
                  this.project.areas_id,
                  this.project.stakeholders_id,
                  true
                );
              }
            }

            if (this.project.bc_file !== null) {
              this.existFile = true;
            }

            this.getNameResponsibleAreas();

            this.user_is_transformation_manager = this.userIsTransformationManager();
          })
          .catch((error) => {
            if (error.response) {
              console.error(error.response);
            } else {
              console.error(error);
            }
            this.snackbar = true;
          })
          .finally(() => {
            this.dialogMessage = false;
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },

    disabledLink() {
      if (this.isNotEditable === true) {
        return true;
      }
      return typeof this.project.id === "undefined";
    },

    idJiraSummaryLinkOpen() {
      this.isLoading = true;
      this.issueLinked = "";

      if (this.project.id_jira_summary === "") {
        this.dialogIdJiraSummary.title = "Vincular Jira";
        this.dialogIdJiraSummary.message = "Selecione a iniciativa do Jira";
        this.dialogIdJiraSummary.btnCancel = "Cancelar";
        this.dialogIdJiraSummary.btnSave = "Salvar";

        this.axios
          .get("project/" + this.project.id + "/link/")
          .then((response) => {
            this.issues = response.data.issues;
          })
          .finally(() => {
            this.isLoading = false;
            this.dialogIdJiraSummary.open = true;
          });

        return;
      }

      this.dialogIdJiraSummary.title = "Desvincular do Jira";
      this.dialogIdJiraSummary.message = "Você tem certeza que deseja desvincular?";
      this.dialogIdJiraSummary.btnCancel = "Não";
      this.dialogIdJiraSummary.btnSave = "Sim";
      this.dialogIdJiraSummary.open = true;
      this.isLoading = false;
    },

    idJiraSummarySave() {
      if (this.project.id_jira_summary === "") {
        if (this.issueLinked === "") {
          this.erroridJiraSummarySave =
            "Seleciona uma iniciativa do Jira para fazer o vínculo.";
          return;
        }

        this.dialogIdJiraSummary.open = false;
        this.isLoading = true;

        this.axios
          .post("project/" + this.project.id + "/link/", this.issueLinked)
          .then((response) => {
            if (response.status === 200) {
              this.redirectProject(this.project, false, response.data.message);
            }
            this.dialogIdJiraSummary.open = false;
          })
          .catch((error) => {
            this.textMessageError = error.response.data;
            this.dialogMessageError = true;

            setTimeout(() => {
              this.dialogMessageError = false;
              this.dialogIdJiraSummary.open = true;
            }, 4000);
          })
          .finally(() => {
            this.isLoading = false;
          });

        return;
      }

      this.dialogIdJiraSummary.open = false;
      this.isLoading = true;

      this.axios
        .post("project/" + this.project.id + "/link/?_method=DELETE", this.issueLinked)
        .then((response) => {
          if (response.status === 200) {
            this.redirectProject(this.project, false, response.data.message);
          }
          this.dialogIdJiraSummary.open = false;
        })
        .catch((error) => {
          this.textMessageError = error.response.data;
          this.dialogMessageError = true;

          setTimeout(() => {
            this.dialogMessageError = false;
            this.dialogIdJiraSummary.open = true;
          }, 2000);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showPriority() {
      return (
        this.project.areas_id &&
        this.project.priority &&
        [
          this.CONST_WORKFLOW.APROVADO_DIRETOR,
          this.CONST_WORKFLOW.BACKLOG,
          this.CONST_WORKFLOW.EM_PLANEJAMENTO,
          this.CONST_WORKFLOW.EM_DESENVOLVIMENTO,
        ].includes(this.project.workflow_id) &&
        (this.user_is_transformation_manager ||
          this.project.stakeholders_id === this.$store.getters.id)
      );
    },

    removeProjectTypes(item) {
      if (!this.isNotEditable) {
        this.project.project_type.splice(this.project.project_type.indexOf(item.id), 1);
        this.project.project_type = [...this.project.project_type];
      }
    },

    removeUnusedProjectTypes() {
      this.project_types = this.project_types.filter((project_type) => {
        return typeof project_type === "undefined" || project_type.active !== false;
      });
    },

    removeItAreas(item) {
      if (!this.isNotEditable) {
        this.project.it_area.splice(this.project.it_area.indexOf(item.id), 1);
        this.project.it_area = [...this.project.it_area];
      }
    },

    removeImpactedAreas(item) {
      if (!this.isNotEditable) {
        this.project.impacted_areas.splice(
          this.project.impacted_areas.indexOf(item.id),
          1
        );
        this.project.impacted_areas = [...this.project.impacted_areas];
      }
    },

    removeFocalPoints(item) {
      if (!this.isNotEditable) {
        this.project.focal_points.splice(this.project.focal_points.indexOf(item.id), 1);
        this.project.focal_points = [...this.project.focal_points];
      }
    },

    removeSpecialProject(item) {
      if (!this.isNotEditable) {
        this.project.special_project.splice(
          this.project.special_project.indexOf(item.id),
          1
        );
        this.project.special_project = [...this.project.special_project];
      }
    },

    resetAdFields() {
      this.project.focal_points = [];
      this.focal_points = [];
      this.project.transformation_managers_id = "";
      this.transformation_managers = [];
    },

    validBcFields() {
      this.project.bc_flag = this.project.capex || this.project.opex;
      this.$v.$touch();
      this.removeEmptyLinesVvTableEditor();
      this.validVvTableEditor("");
      if (this.$v.$invalid || this.deliveryExpectationsErrors || this.indicatorsErrors) {
        this.isLoading = false;
        return;
      }
    },
    exibeLayoutMensagens() {
      let valid = true;

      if (this.project.workflow_id == this.CONST_WORKFLOW.TAP_PREENCHIMENTO) {
        valid = false;
      }

      if (!this.isEdition) {
        valid = false;
      }

      return valid;
    },

    save(draft = false) {
      this.editedFieldsMessage = false;
      this.isLoading = true;

      if (this.existFile) {
        delete this.project.bc_file;
      }

      if (draft) {
        this.project.draft = true;
        this.$v.project.$reset();
        this.$v.project.name.$touch();
        this.deliveryExpectationsErrors = false;
        this.indicatorsErrors = false;
      } else {
        this.project.draft = false;
        this.project.submited = true;
        this.$v.$touch();
        this.removeEmptyLinesVvTableEditor();
        this.validVvTableEditor("");

        if (
          this.deliveryExpectationsErrors ||
          this.indicatorsErrors
        ) {
          this.snackbar = true;
          this.isLoading = false;
          this.project.submited = false;
          return;
        }
      }

      let formData = this.createFormData();

      if (this.isEdition) {
        this.isLoading = true;
        this.axios
          .post("project/" + this.project.id + "?_method=PUT", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.editedFields = [];
            if (response.status === 200) {
              this.redirectProject(this.project, draft, response.data.message);
            }
          })
          .catch((error) => {
            console.error(error.response);

            if (error.response.status == 409) {
              this.textMessageError = error.response.data.message;
              this.dialogMessageError = true;
            } else if (error.response.status == 403) {
              this.dialogSimpleMessageErrorText = error.response.data.message;
              this.dialogSimpleMessageError = true;
            } else if (error.response.status == 422) {
              this.errors = error.response.data;
            } else {
              this.snackbar = true;
            }

            if (error.response.data.investment_order) {
              this.investment_order_error = error.response.data.investment_order;
            }
          }).finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = true;
        this.axios
          .post("project", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.project.id) {
              this.redirectProject(response.data.project, draft, response.data.message);
            }
          })
          .catch((error) => {
            this.errors = error.response.data;

            if (error.response.status == 409) {
              this.textMessageError = error.response.data.message;
              this.dialogMessageError = true;
            }

            this.snackbar = false;
          })
          .finally(() => {
            this.isLoading = false;
            setTimeout(() => {
              this.dialogMessageError = false;
              this.textMessage = "";
            }, 3000);
          });
      }
    },

    sendAnswer(index) {
      let sendObject = {
        message: this.project.messages[index].answer_text,
        parent_id: this.project.messages[index].id,
      };
      this.axios
        .post(`project/${this.project.id}/message`, sendObject)
        .then((response) => {
          if (response.status === 200) {
            this.dialogMessage = true;
            this.textMessage = response.data.message;
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          console.error(error.response);
          this.project.messages[index].answer_error = error.response.data.message;

          this.snackbar = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    criticalityColor(id, css, type) {
      let color = "";

      if (type == "fc") {
        if (id === 1) {
          color = css ? "rgba(0,146,0,0.8)" : "secondary";
        } else if (id === 2) {
          color = css ? "rgba(255,183,0,0.8)" : "warningLight";
        } else if (id === 3) {
          color = css ? "rgba(207,30,30,0.8) " : "error";
        }
      } else {
        if (id === 1) {
          color = css ? "rgba(0,146,0,0.3)" : "secondary";
        } else if (id === 2) {
          color = css ? "rgba(255,183,0,0.3)" : "warningLight";
        } else if (id === 3) {
          color = css ? "rgba(207,30,30,0.3) " : "error";
        }
      }

      return color;
    },

    itValidate() {
      const NOT_INVOLVE_IT = 4;
      this.project.it_area = this.checksIfOnlyOneOption(
        NOT_INVOLVE_IT,
        this.project.it_area
      );
    },

    impactedAreasValidate() {
      const NOT_APPLICABLE = 1;
      this.project.impacted_areas = this.checksIfOnlyOneOption(
        NOT_APPLICABLE,
        this.project.impacted_areas
      );
    },

    specialProjectValidate() {
      const NOT_APPLICABLE = 6;
      this.project.special_project = this.checksIfOnlyOneOption(
        NOT_APPLICABLE,
        this.project.special_project
      );
    },

    strategicIndicatorsValidate() {
      const NOT_APPLICABLE = 6;
      this.project.strategic_indicators = this.checksIfOnlyOneOption(
        NOT_APPLICABLE,
        this.strategic_indicators
      );
    },

    validVvTableEditor(name) {
      if (name === "delivery_expectations") {
        this.validDeliveryExpectations();
      } else if (name === "project_indicators") {
        this.validTableIndicators();
      } else {
        this.validDeliveryExpectations();
        this.validTableIndicators();
      }
    },

    validDeliveryExpectations() {
      let that = this;

      that.deliveryExpectationsErrors = !that.project.delivery_expectations.length;

      let newObj = that.removeEmptyObjects(that.project.delivery_expectations);

      if (!newObj.length) {
        that.deliveryExpectationsErrors = true;
      }

      newObj.map(function (item) {
        // eslint-disable-next-line no-undef
        let keys = Object.keys(_.omit(item, "id"));

        keys = that.removeFieldsEditVvTableEditor(keys);

        if (keys.length) {
          let difference = that.columns_delivery_expectations.filter(
            (x) => !keys.includes(x)
          );

          if (difference.length) {
            that.deliveryExpectationsErrors = true;
          }
        }
      });
      let priorityValid = true;
      for (let idx1 in newObj) {
        for (let idx2 in newObj) {
          if (idx1 != idx2) {
            if (newObj[idx1].priority == newObj[idx2].priority) {
              priorityValid = false;
              that.deliveryExpectationsErrors = true;
              this.deliveryExpectationsErrorMessage =
                "Não pode haver duas prioridades iguais";
            }
          }
        }
      }
      if (priorityValid) {
        that.deliveryExpectationsErrorMessage = "";
      }
    },

    validTableIndicators() {
      let vm = this;

      vm.indicatorsErrors = !vm.project.project_indicators.length;
      let newObj = vm.removeEmptyObjects(vm.project.project_indicators);

      if (!newObj.length) {
        vm.indicatorsErrors = true;
      }

      newObj.map(function (item) {
        // eslint-disable-next-line no-undef
        let keys = Object.keys(_.omit(item, "id"));

        keys = vm.removeFieldsEditVvTableEditor(keys);

        if (keys.length) {
          let difference = vm.columns_indicators.filter((x) => !keys.includes(x));

          if (difference.length) {
            vm.indicatorsErrors = true;
          }
        }
      });
    },

    removeEmptyLinesVvTableEditor() {
      let that = this;

      that.project.delivery_expectations.map(function (item, index) {
        if (Object.keys(item).length <= 1) {
          delete that.project.delivery_expectations[index];
        }
      });

      that.project.project_indicators.map(function (item, index) {
        if (Object.keys(item).length <= 1) {
          delete that.project.project_indicators[index];
        }
      });

      that.project.delivery_expectations = that.removeEmptyObjects(
        that.project.delivery_expectations
      );
      that.project.project_indicators = that.removeEmptyObjects(
        that.project.project_indicators
      );
    },

    createFormData() {
      let formData = new FormData();

      if (
        this.project.workflow_id == this.CONST_WORKFLOW.REJEITADO &&
        this.project.draft == false
      ) {
        this.project.workflow_id = this.CONST_WORKFLOW.AGUARDANDO_APROVACAO;
      }

      Object.keys(this.project).forEach((key) => {
        if (key != "refused_date" && key != "refused_user" && key != "refused_cause") {
          if (key === "delivery_expectations") {
            let i = 0;
            for (let father in this.project[key]) {
              for (let child in this.project[key][father]) {
                if (child === "date") {
                  this.project[key][father][child] = this.convertMonth(
                    this.project[key][father][child]
                  );
                }

                let customKey = key + "[" + i + "]" + "[" + child + "]";
                formData.append(customKey, this.project[key][father][child]);
              }
              i++;
            }
          } else if (key === "project_indicators") {
            let i = 0;
            for (let father in this.project[key]) {
              for (let child in this.project[key][father]) {
                let customKey = key + "[" + i + "]" + "[" + child + "]";
                formData.append(customKey, this.project[key][father][child]);
              }
              i++;
            }
          } else if (
            key === "impacted_areas" ||
            key === "it_area" ||
            key === "focal_points" ||
            key === "special_project" ||
            key === "strategic_indicators"
          ) {
            let j = 0;
            for (let matrix in this.project[key]) {
              if (
                typeof this.project[key][matrix].id !== "undefined" &&
                key == "focal_points"
              ) {
                formData.append(key + "[" + j + "]", this.project[key][matrix].id);
              } else {
                formData.append(key + "[" + j + "]", this.project[key][matrix]);
              }
              j++;
            }
          } else {
            let values = this.project[key] === true ? 1 : this.project[key];
            values = values === false ? 0 : values;

            if (values !== null) {
              if (key == "estimated_cost") {
                if (values) {
                  formData.append(key, values);
                }
              } else {
                formData.append(key, values);
              }
            }
          }
        }
      });

      return formData;
    },

    convertMonth(date) {
      const [year, month] = date.split("-");

      return `${year}-${month}-01`;
    },

    detectEditedFields(fieldName) {
      if (!this.editedFields.includes(fieldName)) {
        this.editedFields.push(fieldName);
      }
    },
    verifyErrors() {
      this.detectEditedFields("Necessita BC?");
      this.$v.$touch();
      this.removeEmptyLinesVvTableEditor();
      this.validVvTableEditor("");
      if (this.$v.$invalid || this.deliveryExpectationsErrors || this.indicatorsErrors) {
        this.isLoading = false;
        return;
      }
    },

    confirmEditing(draft = false) {
      this.isDraft = draft;
      if (this.isEdition && this.editedFields.length > 0) {
        this.editedFieldsMessage = true;
      }
    },

    changePriority() {
      this.formLoad();
    },

    notpaste(idfield) {
      document.getElementById(idfield).onpaste = function () {
        return false;
      };
    },

    redirectProject(project, draft, message) {
      this.dialogMessage = true;
      this.textMessage = message;

      let url = `/projeto/${project.id}`;

      setTimeout(() => {
        if (this.$route.path !== url) {
          this.$router.push(url);
        }

        this.formLoad();

        this.dialogMessage = false;
        this.textMessage = "";
      }, 2000);
    },

    getNameResponsibleAreas() {
      let area = this.responsible_areas.filter((value) => {
        return value.id === this.project.areas_id;
      });

      if (area[0]) {
        this.name_responsible_areas = area[0].name;
      }
    },

    clearError() {
      if (this.$v.project.investment_order.integer) {
        this.investment_order_error = "";
      }
    },
    downloadBcfile() {
      if (this.isEdition) {
        this.axios
          .get("project/" + this.project.id + "/file")
          .then((response) => {
            if (response.status === 200) {
              this.createAndDownloadBlobFile(
                this.base64ToArrayBuffer(response.data.file),
                response.data.filename
              );
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.dialogMessageError = true;
            this.errorMessage = "message";
            this.textMessageError = error.response.data;
          });
      }
    },
    deleteBcfile() {
      if (this.isEdition) {
        this.axios
          .get("project/" + this.project.id + "/deletefile")
          .then((response) => {
            if (response.status === 200) {
              this.project.bc_file = null;
              this.detectEditedFields("Upload BC");
              this.existFile = false;
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.dialogMessageError = true;
            this.errorMessage = "message";
            this.textMessageError = error.response.data;
          });
      }
    },
    habilitaUpload() {
      if (this.isEdition) {
        this.project.bc_file = "";
        this.existFile = false;
        this.detectEditedFields("Upload BC");
      }
    },
    clearErrorMessage(field) {
      this.errors[field] = null;
    },
    async getListIdeas(id) {
      const result = await ideaService.loadListIdeas(id);
      this.ideas = result;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__content {
  padding: 12px 12px 12px 49px !important;
  z-index: 10 !important;
  margin-left: -26px !important;
}

.v-list-item__content {
  strong {
    display: contents;
  }
}

label {
  font-size: 10px;
}

@media screen and (max-width: 600px) {
  button.btn-save-project.v-btn {
    width: 100%;
    margin: 20px 0 0;
  }
}
</style>

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        journey: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Object,
            default: () => ({})
        },
        errorList: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        getError(param) {
            return this.errorList[param];
        },
        clearError(param) {
            this.$emit('clearError', param);
        },
        save() {
            this.$emit('save');
        }
    }
}
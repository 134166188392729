<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="dateRangeText"
          append-icon="mdi-calendar"
          readonly
          clearable
          @click:clear="clearData"
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        no-title
        scrollable
        :max="max"
        locale="pt-br"
        range
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="primary"
          @click="menu = false"
      >
        Cancel
      </v-btn>
      <v-btn
          text
          color="primary"
          @click="$refs.menu.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "VvRangeDate",
  props: {
    dateProp: {
      type: Array,
      default: () => [],
    },
    max: {
      type: String,
      default: '',
    },
  },
  computed: {
    date: {
      get() {
        return this.dateProp;
      },
      set(value) {
        this.$emit("update:date-prop", value);
      },
    },
    dateRangeText: {
      get() {
        if (this.date.length === 0) {
          return '';
        }
        if (this.date.length > 1) {
          let d1 = new Date(this.date[0]+' 03:00:00');
          let d2 = new Date(this.date[1]+' 03:00:00');
          if (d1.getTime() > d2.getTime()) {
            return d2.toLocaleDateString() + ' - ' + d1.toLocaleDateString()
          } else {
            return d1.toLocaleDateString() + ' - ' + d2.toLocaleDateString()
          }
        } else {
          return new Date(this.date[0]+' 03:00:00').toLocaleDateString();
        }
      },
      set(value) {
        return value;
      }
    },
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    clearData() {
      this.date = [];
    },
  },
}
</script>

<style scoped>

</style>
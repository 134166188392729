<template>
  <div class="filter">

    <v-data-table
        :headers="headers"
        :items="content"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="text"
        mobile-breakpoint="0"
        hide-default-footer
        show-expand
        class="filter-table"
    >
      <template v-slot:item.title="{ item }">
        <h2>{{ item.title }}</h2>
      </template>

      <template v-slot:item.chips="{ item }">
        <div class="chip-filter-area" v-if="expanded.length < 1">
          <div class="chip-filter-quad" :class="'pos-'+pos" v-for="(chip, pos) in item.chips" :key="pos">
            <v-chip color="#B2DEB2" class="d-block chip-filter" label>
              <v-avatar left>
                <v-icon small @click="removeChip(item.chips, chip)">mdi-close</v-icon>
              </v-avatar>
              <v-tooltip top max-width="450">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ chip.label }}: {{ chip.text }}</span>
                </template>
                <span>{{ chip.text }}</span>
              </v-tooltip>
            </v-chip>
          </div>
          <div class="chip-filter-quad">
            <v-chip color="#B2DEB2" class="d-block chip-filter" label align="center">
              . . .
            </v-chip>
          </div>
        </div>
      </template>

      <template v-slot:item.clear="{ item }">
        <v-btn class="text-uppercase" small text color="#1A83B7" @click="clearFilter">{{ item.clear }}</v-btn>
      </template>

      <template v-slot:item.filter="{ item }">
        <v-btn
            color="primary"
            class="py-4"
            small
            :disabled="!canFilter"
            :class="(canFilter) ? 'pl-0' : ''"
            v-if="expanded.length < 1"
            @click="filter(false)"
        >
          <v-tooltip max-width="250" top v-if="canFilter">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  color="#A79CFF"
                  class="px-2"
              >mdi-information-outline</v-icon>
            </template>
            <span>Há alterações nos filtros que
                  ainda não foram aplicadas</span>
          </v-tooltip>
          {{ item.filter }}
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length" class="px-8 pt-2 pb-6">
          <v-form ref="form" lazy-validation>
            <div class="grid-12 gap-md row-gap-none">
              <div :class="`g-col-${field.col} sm:g-col-${field.sm} md:g-col-${field.md}`" v-for="(field, index) in fieldFilter" :key="index">
                <label v-if="field.type !== 'sliders'" :for="field.name" class="filter-label">
                  {{ field.label }}
                  <v-tooltip right max-width="450" v-if="typeof field.tooltip !== 'undefined'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-3" color="primary" v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                    {{ field.tooltip }}
                  </span>
                  </v-tooltip>
                </label>

                <div v-if="field.type === 'autocomplete'">
                  <v-autocomplete
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :items="field.items"
                      :disabled="field.disabled"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-chevron-down"
                      no-data-text="Nenhum item encontrado"
                      @change="changeCanFilterStatus"
                  ></v-autocomplete>
                </div>

                <div v-else-if="field.type === 'multiautocomplete'">
                  <v-autocomplete
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :items="field.items"
                      :disabled="field.disabled"
                      item-text="name"
                      item-value="id"
                      multiple
                      append-icon="mdi-chevron-down"
                      no-data-text="Nenhum item encontrado"
                      @change="changeCanFilterStatus"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          @click="select"
                          label
                          class="chip-green"
                      >
                        <v-icon
                            @click="removeItem(field,item)"
                            size="16"
                            class="remove-chip"
                        >mdi-close
                        </v-icon>
                        {{ item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </div>

                <div v-else-if="field.type === 'date'">
                  <vv-date-pick
                      v-model="field.value"
                      :disabled="field.disabled"
                      :clear-field="true"
                      @change="changeCanFilterStatus"
                  >
                  </vv-date-pick>
                </div>

                <div v-else-if="field.type === 'number'">
                  <v-text-field
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :disabled="field.disabled"
                      type="number"
                      @keypress="isNumber($event, field.value, 9)"
                      @change="changeCanFilterStatus"
                  ></v-text-field>
                </div>

                <div v-else-if="field.type === 'switch'">
                  <v-switch
                      v-model="field.value"
                      class="manual-line switch"
                      color="secondary"
                      :label="field.value ? 'Sim' : 'Não'"
                      inset
                      :disabled="field.disabled"
                      @change="changeCanFilterStatus"
                  />
                </div>

                <div v-else-if="field.type === 'sliders'">
                  <vv-multiple-sliders
                      v-model="field.value"
                      :id="field.id"
                      :items="field.items"
                      :disabled="field.disabled"
                      :label="field.label"
                      step="5"
                      @change="changeCanFilterStatus"
                  />
                </div>

                <div v-else>
                  <v-text-field
                      :id="field.id"
                      :name="field.name"
                      v-model="field.value"
                      :type="field.type"
                      :disabled="field.disabled"
                      @change="changeCanFilterStatus"
                  ></v-text-field>
                </div>
              </div>

              <div class="g-col-12 md:g-col-6 rtl">
                <v-btn color="primary" @click="filter(false)" class="btn-global-filter">
                  Filtrar
                </v-btn>
                <v-btn
                    color="#5D4EE8"
                    outlined
                    class="btn-global-filter"
                    @click="filter(true)"
                    v-if="acceptExport"
                >
                  Exportar listagem
                  <v-tooltip left max-width="250">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-2" color="primary" v-bind="attrs" small v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                    Para extrair toda a base do sistema, não aplicar nenhum filtro na tela
                  </span>
                  </v-tooltip>
                </v-btn>
              </div>

            </div>
          </v-form>
        </td>
      </template>
    </v-data-table>
    <modal-confirm-export :visible="dialogExport" />
  </div>
</template>

<script>
import VvDatePick from "@/components/VvDatePick";
import VvMultipleSliders from "./VvMultipleSliders";
import { isObject, flatten } from "@/services/utils";
import ModalConfirmExport from "@/components/reports/ModalConfirmExport";
import filterServiceFactory from "@/services/filterService";

const FilterService = filterServiceFactory();

export default {
  name: "VvNewFilter",
  components: {
    VvDatePick,
    VvMultipleSliders,
    ModalConfirmExport,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
      required: true,
    },
    acceptExport: {
      type: Boolean,
      default: true,
    },
    exportApi: {
      type: String,
      default: 'project',
    },
    filterName: {
      type: String,
      required: true,
    },
    notificationExport: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    canFilter: false,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'title',
        width: '136px',
      },
      { text: '', sortable: false, value: 'chips', width: '77%' },
      { text: '', sortable: false, value: 'clear', width: '5%' },
      { text: '', sortable: false, value: 'filter', width: '5%' },
      { text: '', sortable: false, value: 'data-table-expand', width: '3%' },
    ],
    content: [
      {
        title: "Filtros",
        chips: [],
        clear: "Limpar tudo",
        filter: "Filtrar",
      },
    ],
    expanded: [true],
    dialogExport: false,
  }),
  computed: {
    fieldFilter() {
      return this.fields;
    }
  },
  methods: {
    filter(isExport) {
      let payload = {};
      this.fieldFilter.map(field => {
        if (typeof field.value !== "undefined") {
          payload[field.payloadBackend] = (field.value) ? field.value : null;
        }
      });
      this.pushGlobalFilters(this.filterName, this.fieldFilter);
      this.canFilter = false;
      if (!isExport) {
        if (Object.keys(payload).length) {
          payload.page = 1;
          const globalFilters = this.getGlobalFilters(this.filterName);
          if (typeof globalFilters !== 'undefined') {
            this.loadChips(globalFilters);
          }
          this.expanded = [];
          this.$emit('filter', payload);
        }
      }
      if (isExport) {
        if (this.notificationExport) {
          this.exportNotification(payload);
        } else {
          this.export(payload);
        }
      }
    },
    loadChips(fieldsStore) {
      let upToChips = fieldsStore.map((field) => {
        if (field.value !== "" && field.value !== null) {
          return field;
        }
      });
      upToChips = upToChips.filter((filter) => {
        return typeof filter !== 'undefined';
      });

      let aux = [];
      let chips = upToChips.map((chip) => {
        if (Array.isArray(chip.value)) {
          chip.value.map((type) => {
            const text = chip.items.filter((textItem) => {
              return textItem.id === type;
            });
            aux.push({
              id: chip.id,
              label: chip.label,
              value: type,
              text: text[0].name,
              type: chip.type,
            });
          })
        } else {
          let textChip = chip.value;
          if (chip.type === 'date') {
            textChip = this.formattedDateForBr(textChip);
          }
          return {
            id: chip.id,
            label: chip.label,
            value: chip.value,
            text: textChip,
            type: chip.type,
          }
        }
      });

      chips = chips.filter((filter) => {
        return typeof filter !== 'undefined';
      });
      chips = chips.concat(aux);

      this.content[0].chips = chips;
    },
    async exportNotification(payload) {
      payload.isExport = 1;
      try {
        await FilterService.exportNotification(payload, this.exportApi);
      } catch (error) {
        this.$showError(error);
      } finally {
        this.dialogExport = true;
      }
    },
    async export(payload) {
      payload.isExport = 1;
      const newPayload = Object.entries(payload)
          .reduce((acc, [ key ,value ]) => {
            if(isObject(value)) {
              Object.entries(flatten(value))
                  .forEach(([elKey, elValue]) => acc.push([`${key}[${elKey}]`, elValue]));
            }
            else {
              acc.push([key, value]);
            }
            return acc;
          },[]);
      payload = Object.fromEntries(newPayload);
      this.$showLoading();
      try {
        const resultExport = await FilterService.exportExcel(payload, this.exportApi);
        this.createAndDownloadBlobFile(this.base64ToArrayBuffer(resultExport.file), resultExport.filename);
        this.$hideLoading();
      } catch (error) {
        console.error(error);
      } finally {
        this.$hideLoading();
        this.dialogExport = true;
      }
    },
    clearFilter() {
      this.clearGlobalFilters(this.filterName, this.fieldFilter);
      this.fieldFilter.forEach((field) => {
        if (field.type == 'date') {
          field.value = null;
        }
      });
      this.canFilter = false;
      this.content[0].chips = [];
      this.$emit('reset');
    },
    removeItem(campo, item) {
      campo.value.splice(campo.value.indexOf(item.id), 1);
    },
    removeChip(campo, item) {
      campo.splice(campo.indexOf(item), 1);

      this.fieldFilter.map((field) => {
        if (field.type === 'multiautocomplete' && Array.isArray(field.value)) {
          field.value.map((idValue, index) => {
            if (idValue === item.value) {
              field.value.splice(index, 1);
            }
          });
        }
        if (field.value == item.value) {
          field.value = null;
        }
      });
      this.canFilter = true;
    },
    changeCanFilterStatus() {
      this.canFilter = true;
    }
  },
  mounted() {
    let fieldsStore = this.getGlobalFilters(this.filterName);
    if (typeof fieldsStore !== 'undefined') {
      this.loadChips(fieldsStore)
    }
  },
}
</script>

<style lang="scss">
.filter-table {
  border: 1px solid #DBDCDC;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  .v-data-table__wrapper {
    table {
      .v-data-table-header {
        tr {
          th {
            border-bottom: none !important;
          }
        }
      }
      tbody {
        tr {
          td {
            &:has(h2) {
              padding: 0px 0px 0px 24px;
            }
            h2 {
              font-weight: 600;
              font-size: 15px;
              color: #5d5d5d;
            }
          }
        }
        .v-data-table__expanded {
          &.v-data-table__expanded__row {
            td {
              border-bottom: none !important;
              height: 66px !important;
            }
          }
        }
      }
    }
  }
  .chip-filter-area {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0px 6px;
    .chip-filter-quad:nth-child(n+16){
      display: none;
    }
    &:has(.pos-15) {
      .chip-filter-quad:last-child {
        display: block;
      }
    }
    &:not(:has(.pos-15)) {
      .chip-filter-quad:last-child {
        display: none;
      }
    }
    @media(max-width: 2424px) {
      .chip-filter-quad:nth-child(n+14){
        display: none;
      }
      &:has(.pos-13) {
        .chip-filter-quad:last-child {
          display: block;
        }
      }
      &:not(:has(.pos-13)) {
        .chip-filter-quad:last-child {
          display: none;
        }
      }
    }
    @media(max-width: 2144px) {
      .chip-filter-quad:nth-child(n+12){
        display: none;
      }
      &:has(.pos-11) {
        .chip-filter-quad:last-child {
          display: block;
        }
      }
      &:not(:has(.pos-11)) {
        .chip-filter-quad:last-child {
          display: none;
        }
      }
    }
    @media(max-width: 1904px) {
      .chip-filter-quad:nth-child(n+10){
        display: none;
      }
      &:has(.pos-9) {
        .chip-filter-quad:last-child {
          display: block;
        }
      }
      &:not(:has(.pos-9)) {
        .chip-filter-quad:last-child {
          display: none;
        }
      }
    }
    @media(max-width: 1688px) {
      .chip-filter-quad:nth-child(n+8){
        display: none;
      }
      &:has(.pos-7) {
        .chip-filter-quad:last-child {
          display: block;
        }
      }
      &:not(:has(.pos-7)) {
        .chip-filter-quad:last-child {
          display: none;
        }
      }
    }
    @media(max-width: 1472px) {
      .chip-filter-quad:nth-child(n+6){
        display: none;
      }
      &:has(.pos-5) {
        .chip-filter-quad:last-child {
          display: block;
        }
      }
      &:not(:has(.pos-5)) {
        .chip-filter-quad:last-child {
          display: none;
        }
      }
    }
    @media(max-width: 1256px) {
      .chip-filter-quad:nth-child(n+4){
        display: none;
      }
      &:has(.pos-3) {
        .chip-filter-quad:last-child {
          display: block;
        }
      }
      &:not(:has(.pos-3)) {
        .chip-filter-quad:last-child {
          display: none;
        }
      }
    }
    @media(max-width: 1040px) {
      .chip-filter-quad:nth-child(n+2){
        display: none;
      }
      &:has(.pos-1) {
        .chip-filter-quad:last-child {
          display: block;
        }
      }
      &:not(:has(.pos-1)) {
        .chip-filter-quad:last-child {
          display: none;
        }
      }
    }
    .chip-filter-quad {
      width: 186px;
      margin-right: 30px;
      margin-bottom: 18px;
      .chip-filter {
        color: #3D6A06;
        margin: 0px;
        border-radius: 6px !important;
        height: 26px;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
        }
      }
    }
  }

  tr {
    box-shadow: none !important;
    &:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: transparent !important;
    }
  }
  th {
    height: 0 !important;
  }
  td {
    height: 96px !important;
  }
}
</style>
<template>
  <v-container class="px-7">

    <vv-loading :is-loading="isLoading"></vv-loading>

    <v-alert type="error" v-if="form.epicOptions['validTree'] != 'undefined' && form.epicOptions['validTree'] == false">
      Há inconsistências na arvore de tecnologia do macro tema, selecione novamente o VSM responsável pelo entregável
    </v-alert>

    <v-row>
      <v-col cols="12" lg="3" md="4">
        <label>Estágio</label>
        <v-autocomplete
                :items="form.option.stages"
                :disabled="check_disabled_focal_point_or_stakeholder"
                item-text="name"
                item-value="id"
                v-model="form.values.stage_id"
                :error-messages="form.errors.stage_id"
                append-icon="mdi-chevron-down"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    @click="select"
                    label
                    class="chip-green"
            >
              {{ item.name }}
            </v-chip>
          </template>
          <template v-slot:item="{ active, item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action v-if="item.active">
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content v-if="item.active">
                <v-list-item-title>
                  <v-row no-gutters align="center">
                    <span>{{ item.name }}</span>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="4">
        <label>Id Épico JIRA</label>
        <v-text-field
            :disabled="check_disabled"
            v-model="form.values.id_jira"
            :error-messages="form.errors.id_jira"
            :rules="form.rules.id_jira"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <label>Nome Épico</label>
        <div v-if="isEdition" class="edit-disabled">
          <v-chip label>{{ form.values.name }}</v-chip>
        </div>
        <div v-else>
          <v-text-field
                  :disabled="check_disabled"
                  v-model="form.values.name"
                  :error-messages="form.errors.name"
                  :rules="form.rules.name"
          >
          </v-text-field>
        </div>
      </v-col>
      <v-col cols="12" lg="2" md="6">
        <label>Prioridade</label>
        <div v-if="form.values.priority" class="edit-disabled">
          <v-chip label>{{ form.values.priority }}</v-chip>
        </div>
        <div v-else>
          <v-text-field
            v-model="form.values.priority"
            disabled
          >
          </v-text-field>
        </div>
      </v-col>
      <v-col cols="12" lg="2" md="6">
        <label>Status Atual
          <v-tooltip right v-if="!this.form.values.id">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                        color="warningLight"
                        size="18"
                        v-bind="attrs"
                        v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>O épico deve ser criado para que o status 'Atenção', 'Atrasado', 'Replanej.(Atenção)' e Replanej.(Atrasado)' possam ser selecionados</span>
            </v-tooltip>
        </label>
        <v-autocomplete
                :disabled="check_disabled_focal_point_or_stakeholder"
                :items="form.option.status"
                item-text="name"
                item-disabled="disable"
                item-value="id"
                v-model="form.values.status_id"
                :error-messages="form.errors.status_id"
                :rules="form.rules.status_id"
                append-icon="mdi-chevron-down"
                class="ellipsis-on-chip"
                @change="verifyStatus()"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    @click="select"
                    label
                    :style="
                    'background-color: ' + statusAtualColor(item.id, true, 'bg',item.disable) +
                    '; color: ' + statusAtualColor(item.id, true, 'fc', item.disable) +
                    '; width: 90%'"
            >
              {{ item.name }}
            </v-chip>
          </template>
              <template #item="{ item }">
                    <option
                            tabindex="0"
                            role="option"
                            class="v-list-item v-list-item--link"
                            :disabled="!verifyCountCRApprovals(item)"
                            :style="
                            'background-color: ' + statusAtualColor(item.id, true, 'bg', !verifyCountCRApprovals(item)) +
                            '; color: ' + statusAtualColor(item.id, true, 'fc', !verifyCountCRApprovals(item))"
                    >
                      <div class="v-list-item__content">
                        <div class="v-list-item__title">{{ item.name }}</div>
                      </div>
                    </option>
              </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <label for="epic_description">Descrição Épico</label>
        <v-textarea
                :disabled="check_disabled"
                id="epic_description"
                name="epic_description"
                v-model="form.values.epic_description"
                class="scroll-style"
                filled
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="4">
        <div class="edit-disabled">
          <label>
            Status Semana Anterior
            <v-tooltip right v-if="form.last_status_date">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                        color="warningLight"
                        size="18"
                        v-bind="attrs"
                        v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ form.last_status_date }}</span>
            </v-tooltip>
          </label>
          <br>
          <v-chip label>{{ form.last_status_name }}</v-chip>
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <label>VSM TI</label>
        <v-autocomplete
                :disabled="check_disabled"
                v-model="form.values.vsm_id"
                :items="form.option.vsm"
                :error-messages="form.errors.vsm_id"
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                @change="integrationLoad(form.values.vsm_id, 'vsm', ['journeys','tribes','gpm','squads'], false);"
                :rules="form.rules.development"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <label>Jornada TI</label>
        <div v-if="isEdition" class="edit-disabled">
          <v-chip label>{{ form.values.journeys_id }}</v-chip>
        </div>
        <div v-else>
          <v-autocomplete
                  :disabled="check_disabled"
                  v-model="form.values.journeys_id"
                  :items="form.option.journeys"
                  item-text="name"
                  item-value="id"
                  item-disabled="disabled"
                  :error-messages="form.errors.journeys_id"
                  append-icon="mdi-chevron-down"
                  :rules="form.rules.development"
                  no-data-text="Selecione um VSM"
                  @change="integrationLoad(form.values.journeys_id, 'journeys', ['tribes','gpm','squads'], false);"
          >
          </v-autocomplete>
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <label>Link do PPM no docusign</label>
        <v-text-field
                :disabled="check_disabled_focal_point_or_stakeholder"
                v-model="form.values.ppm"
                :error-messages="form.errors.ppm"
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="3" md="4">
        <label>Tribo TI</label>
        <div v-if="isEdition" class="edit-disabled">
          <v-chip label>{{ form.values.tribes_id }}</v-chip>
        </div>
        <div v-else>
          <v-autocomplete
                  :disabled="check_disabled"
                  v-model="form.values.tribes_id"
                  :items="form.option.tribes"
                  item-text="name"
                  item-value="id"
                  item-disabled="disabled"
                  :error-messages="form.errors.tribes_id"
                  append-icon="mdi-chevron-down"
                  :rules="form.rules.development"
                  @change="integrationLoad(form.values.tribes_id, 'tribes', ['gpm', 'squads'], false);"
                  no-data-text="Selecione uma Jornada"
          >
          </v-autocomplete>
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <label>Squad</label>
        <v-autocomplete
                :items="form.option.squads"
                :disabled="check_disabled_focal_point_or_stakeholder"
                item-text="name"
                item-value="squad_key"
                v-model="form.values.squad_key"
                :error-messages="form.errors.squad_key"
                append-icon="mdi-chevron-down"
                @change="changePmPo(form.values.squad_key);"
                :no-data-text="squadNoDataText()"
                chips
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    @click="select"
                    label
                    class="chip-green"
            >
              {{ item.name }}
              <v-icon
                  @click="removeSquad()"
                  size="16"
                  class="remove-chip"
              >mdi-close
              </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" lg="4" md="4">
        <label>GPM TI</label>
        <div v-if="isEdition" class="edit-disabled">
          <v-chip label>{{ form.values.gpm_id }}</v-chip>
        </div>
        <div v-else>
          <v-autocomplete
                  :disabled="check_disabled"
                  v-model="form.values.gpm_id"
                  :items="form.option.gpm"
                  item-text="name"
                  item-value="id"
                  :error-messages="form.errors.gpm_id"
                  append-icon="mdi-chevron-down"
                  :rules="form.rules.development"
                  no-data-text="Selecione uma Tribo"
          >
          </v-autocomplete>
        </div>
      </v-col>
      <v-col cols="12" lg="6" md="6">
        <label>PM/PO</label>
        <v-autocomplete
            v-model="form.values.pmpo"
            :items="form.values.pmpo"
            item-text="name"
            item-value="id"
            multiple
            chips
            readonly
            append-icon="mdi-chevron-down"
            :rules="form.rules.development"
            no-data-text="Nenhum PM/PO"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                v-bind="attrs"
                :input-value="selected"
                @click="select"
                label
                class="chip-green"
            >{{ item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" lg="6"> -->
        <!-- <label>Consultor Escritório Transformação</label>
        <v-select
            v-model="form.values.change_office_manager"
            :items=""
            :error-messages="form.errors.change_office_manager"
            append-icon="mdi-chevron-down"
            :rules="form.rules.development"
        ></v-select> -->
        <!-- Comentado, pois será utilizado no futuro CVV-229 -->
      <!-- </v-col> -->
      <v-col cols="12" lg="3">
        <label>Black Friday</label>
        <v-switch
          id="black_friday"
          name="black_friday"
          color="secondary"
          class="manual-line switch"
          v-model="form.values.black_friday"
          :label="form.values.black_friday ? 'Sim' : 'Não'"
          :disabled="check_disabled_focal_point_or_stakeholder"
          inset
        ></v-switch>
      </v-col>
      <v-col cols="12" lg="3" md="4">
        <label>Tags</label>
        <div v-if="isEdition" class="edit-disabled">
          <v-chip label>{{ form.values.tags }}</v-chip>
        </div>
        <div v-else>
          <v-autocomplete
              :disabled="check_disabled"
              :items="form.option.tags"
              item-text="name"
              item-value="id"
              v-model="form.values.tags"
              :error-messages="form.errors.tags"
              append-icon="mdi-chevron-down"
              chips
              multiple
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  class="chip-green"
              >
                {{ item.name }}
                <v-icon
                    @click="removeTags(item)"
                    size="16"
                    class="remove-chip"
                >mdi-close
                </v-icon
                >
              </v-chip>
            </template>
          </v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="4">
        <label>Área de Negócio Envolvida</label>
        <v-autocomplete
          :items="demandingArea"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          :rules="form.rules.demanding_area"
          v-model="form.values.demanding_area"
          :disabled="check_disabled"
          name="demanding_area"
          id="demanding_area"
          :error-messages="form.errors.demanding_area"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="6" md="4">
        <label>Outras Jornadas e Tribos Envolvidas</label>
        <div v-if="isEdition" class="edit-disabled">
          <v-chip label>{{ form.values.journey_tribe_dependency }}</v-chip>
        </div>
        <div v-else>
          <v-autocomplete
                  :disabled="check_disabled"
                  :items="form.option.journey_tribe_dependency"
                  item-text="name"
                  item-value="id"
                  v-model="form.values.journey_tribe_dependency"
                  :error-messages="form.errors.journey_tribe_dependency"
                  append-icon="mdi-chevron-down"
                  :rules="form.rules.development"
                  @change="journeysTribesValidate()"
                  chips
                  multiple
          >
            <!-- Adicione esse atributo caso queira que na edição seja somente leitura ':disabled="isEdition"' -->
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      @click="select"
                      label
                      class="chip-green"
              >
                {{ item.name }}
                <v-icon
                        @click="removeJourneyTribes(item)"
                        size="16"
                        class="remove-chip"
                >mdi-close
                </v-icon
                >
                <!-- Adicione esse atributo caso não queira que na edição seja possível remover o item 'v-if="!isEdition"' -->
              </v-chip>
            </template>
          </v-autocomplete>
        </div>
      </v-col>
      <v-col cols="12" lg="2" md="4">
        <label>Entregável (baseline)</label>
        <div v-if="isEditable" class="edit-disabled">
          <v-chip label>{{ formattedDateForBr(form.values.delivery_baseline) }}</v-chip>
        </div>
        <div v-else>
          <vv-date-pick
                  :disabled="check_disabled"
                  v-model="form.values.delivery_baseline"
                  :error-messages="form.errors.delivery_baseline"
                  :rules="form.rules.development"
          ></vv-date-pick>
        </div>
      </v-col>
      <v-col cols="12" lg="2" md="4">
        <label>Entregável (planejado)</label>
        <vv-date-pick
                :disabled="check_disabled_focal_point_or_stakeholder"
                v-model="form.aux_delivery_planned"
                :error-messages="form.errors.delivery_planned"
                :clear-field=true
        ></vv-date-pick>
      </v-col>
      <v-col cols="12" lg="2" md="4">
        <label>Entregável (realizado)</label>
        <vv-date-pick
                :disabled="check_disabled_focal_point_or_stakeholder"
                v-model="form.aux_delivery_done"
                :error-messages="form.errors.delivery_done"
                :clear-field=true
        ></vv-date-pick>
      </v-col>

      <v-col cols="12" lg="2" md="4">
        <label>Final da Operação Assistida <br/>(baseline)</label>
        <vv-date-pick
                :disabled="check_disabled"
                v-model="form.values.assisted_operation_baseline"
                :error-messages="form.errors.assisted_operation_baseline"
                :rules="form.rules.asssisted_operation"
        ></vv-date-pick>
      </v-col>
      <v-col cols="12" lg="2" md="4">
        <label>Final da Operação Assistida <br/>(planejado)</label>
        <vv-date-pick
                :disabled="check_disabled_focal_point_or_stakeholder"
                v-model="form.values.assisted_operation_planned"
                :error-messages="form.errors.assisted_operation_planned"
                :clear-field=true
        ></vv-date-pick>
      </v-col>
      <v-col cols="12" lg="2" md="4">
        <label>Final da Operação Rollout <br/>(baseline)</label>
        <vv-date-pick
                :disabled="check_disabled"
                v-model="form.values.rollout_baseline"
                :error-messages="form.errors.rollout_baseline"
                :rules="form.rules.rollout"
        ></vv-date-pick>
      </v-col>
      <v-col cols="12" lg="2" md="4">
        <label>Final da Operação Rollout <br/>(planejado)</label>
        <vv-date-pick
                :disabled="check_disabled_focal_point_or_stakeholder"
                v-model="form.values.rollout_planned"
                :error-messages="form.errors.rollout_planned"
                :clear-field=true
        ></vv-date-pick>
      </v-col>
      <v-col cols="12" lg="2" md="4">
        <label>% evolução rollout <br/> <span class="text-white">. &nbsp;</span> </label>
        <v-autocomplete
                :disabled="check_disabled_focal_point_or_stakeholder"
                :items="form.option.rollout_percentage"
                item-text="value"
                item-value="id"
                v-model="form.values.rollout_percentage"
                :error-messages="form.errors.rollout_percentage"
                append-icon="mdi-chevron-down"
                :rules="form.rules.rollout"

        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="6" md="4">
        <label>Áreas Interessadas <br/> <span class="text-white">. &nbsp;</span> </label>
        <div v-if="isEdition" class="edit-disabled">
          <v-chip label>{{ form.values.interested_areas }}</v-chip>
        </div>
        <div v-else>
          <v-autocomplete
              :disabled="check_disabled"
              :items="form.option.interested_areas"
              item-text="name"
              item-value="id"
              v-model="form.values.interested_areas"
              :error-messages="form.errors.interested_areas"
              append-icon="mdi-chevron-down"
              chips
              multiple
          >
            <!-- Adicione esse atributo caso queira que na edição seja somente leitura ':disabled="isEdition"' -->
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  class="chip-green"
              >
                {{ item.name }}
                <v-icon
                    @click="removeInterestedAreas(item)"
                    size="16"
                    class="remove-chip"
                >mdi-close
                </v-icon
                >
                <!-- Adicione esse atributo caso não queira que na edição seja possível remover o item 'v-if="!isEdition"' -->
              </v-chip>
            </template>
          </v-autocomplete>
        </div>
      </v-col>
    </v-row>

    <v-row class="issues">
      <v-col cols="12">
        <div
            v-for="(issue, index) in form.values.issues"
            :key="index"
            class="issue-card margin20"
        >
          <v-row class="margin20">
            <v-col cols="12" lg="6">
              <label>Problema/Ponto de Atenção</label>
              <v-textarea
                      :disabled="check_disabled"
                      filled
                      class="scroll-style"
                      v-model="issue.problem"
                      :error-messages="form.errors['issues.'+index+'.problem'] ? form.errors['issues.'+index+'.problem'] : null"
                      @input="cleanErrorMessage('problem', index)"
              ></v-textarea>
            </v-col>
            <v-col cols="12" lg="6">
              <label>Plano de Ação</label>
              <v-textarea
                      :disabled="check_disabled"
                      filled
                      class="scroll-style"
                      v-model="issue.action_plan"
                      :error-messages="form.errors['issues.'+index+'.action_plan'] ? form.errors['issues.'+index+'.action_plan'] : null"
                      @input="cleanErrorMessage('action_plan', index)"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="4">
              <label>Responsavel p/ Plano de Ação</label>
              <v-text-field
                      :disabled="check_disabled"
                      v-model="issue.owner"
                      :error-messages="form.errors['issues.'+index+'.owner'] ? form.errors['issues.'+index+'.owner'] : null"
                      @input="cleanErrorMessage('owner', index)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <label>Data para o Plano de Ação</label>
<!--              <vv-date-pick-->
<!--                  v-model="issue.date"-->
<!--                  :error-messages="form.errors.date"-->
<!--              ></vv-date-pick>-->
              <!-- TODO - VERIFICAR PORQUE CHAMANDO O COMPONENTE NÃO DEU CERTO -->
              <v-menu
                  ref="menu"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                          :disabled="check_disabled"
                          :value="formatDate(issue.date)"
                          :error-messages="form.errors['issues.'+index+'.date'] ? form.errors['issues.'+index+'.date'] : null"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @blur="cleanErrorMessage('date', index)"
                  ></v-text-field>
                </template>
                <v-date-picker
                        :disabled="check_disabled"
                        v-model="issue.date"
                        no-title
                        scrollable
                        locale="pt-br"
                >
                </v-date-picker>
              </v-menu>
              <!-- TODO - VERIFICAR PORQUE CHAMANDO O COMPONENTE NÃO DEU CERTO -->
            </v-col>

            <v-col cols="12" lg="6">
              <v-btn v-if="user_is_transformation_manager" color="error" @click="deleteIssue(index, issue)" class="mx-5">
                <v-icon dark left>mdi-close-circle-outline</v-icon>
                Excluir
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-btn v-if="user_is_transformation_manager && issueUnlock" :disabled="check_disabled" dark class="ma-2" @click="addNewIssue()">
          <v-icon dark left>mdi-plus-circle-outline</v-icon>
          Adicionar problema / Ponto de Atenção
        </v-btn>

        <v-btn dark class="d-block ma-2" @click="handleSaveEpic()" :disabled="check_disabled_focal_point_or_stakeholder" :loading="isLoading">
          <v-icon dark left>mdi-folder-outline</v-icon>
          Salvar Épico
        </v-btn>
        
        <v-btn v-if="enableCR" dark class="ma-2" @click="createCr(form.values.id)" :disabled="checkIfEpicCreated()">
          <v-icon dark left>mdi-plus-circle-outline</v-icon>
          Criar Change Request
        </v-btn>

        <v-btn dark class="ma-2" @click="showCr(form.values.id)" :disabled="checkIfEpicCreated()">
          <v-icon dark left>mdi-eye</v-icon>
          Ver Change Requests
        </v-btn>
        <v-tooltip right max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-3" color="primary" v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span>
            Os botões de Change Request só estarão habilitados após a criação do epico
          </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="last">
      <v-col cols="12">
        <v-btn :disabled="!checkDoneEpic()" dark class="ma-2" @click="doneEpic()">
          <v-icon dark left>mdi-check-outline</v-icon>
          Concluir Entrega
        </v-btn>
        <v-tooltip right max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-3" color="primary" v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span>
            O épico só pode ser concluído quando seu Estágio for Homologação, Em Implantação ou Rollout
          </span>
        </v-tooltip>
      </v-col>
      <v-col v-if="JSON.stringify(this.form.values.delivery_formalization) !== '[]'">
        <v-expansion-panels accordion v-model="delivery_formalization">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Formalização de Entrega</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(value, index) in this.form.values.delivery_formalization" :key="index" class="evidence-card">
                <v-row>
                  <v-col cols="4">
                    <label>Data do envio da Formalização de Entrega</label>
                    <h4>{{ moment(value.created_at).format("DD/MM/YYYY") }}</h4>
                  </v-col>
                  <v-col cols="4" v-if="value.status_id === CONST_CHANGE_REQUEST_STATUS.APROVADO">
                    <label>Data de encerramento da Formalização de Entrega</label>
                    <h4>{{ moment(value.updated_at).format("DD/MM/YYYY") }}</h4>
                  </v-col>
                  <v-col cols="4" v-if="value.status_id === CONST_CHANGE_REQUEST_STATUS.REPROVADO">
                    <label>Data de encerramento da Formalização de Entrega</label>
                    <h4>{{ moment(value.refused_date).format("DD/MM/YYYY") }}</h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <label>Comentários Sobre a Entrega</label>
                    <v-textarea
                        :disabled="true"
                        filled
                        class="scroll-style"
                        v-model="value.comment"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" md="6">
                    <label>Operação Assistida</label>
                    <v-switch
                        :disabled="true"
                        color="secondary"
                        class="manual-line switch"
                        v-model="value.assisted_operation"
                        :label="value.assisted_operation ? 'Sim' : 'Não'"
                        inset
                    ></v-switch>
                  </v-col>
                  <v-col lg="6" md="6">
                    <label>Final Operação Assistida (baseline)</label>
                    <v-menu
                        :disabled="true"
                        ref="menu"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="formatDate(value.baseline)"
                            append-icon="mdi-calendar"
                            readonly
                            :disabled="true"
                            v-bind="attrs"
                            v-on="on"
                            :rules="value.baseline"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="value.baseline"
                          no-title
                          scrollable
                          locale="pt-br"
                          :disabled="true"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Evidências</h3>
                    <div v-if="typeof value.evidences === 'undefined'">Nenhum evidência cadastrada.</div>
                    <div v-for="(file, index) in value.evidences" :key="index" class="file-evidence margin20">
                      <a @click="downloadEvidence(value.evidences[index])">{{ file.evidence_name }}</a>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Workflow</h3>
                  </v-col>
                  <div class="content">
                    <div class="approvers">
                      <h4>Aprovadores:</h4>
                      <ul>
                        <li v-for="(approval, index) in value.approvals" :key="index" :class="'approved-'+approval.approved">
                          <span v-if="approval.delegate.id === null">
                            {{ approval.user_name }}
                            <template v-if="approval.approved === null">
                              (Pendente)
                            </template>
                            <template v-else-if="approval.approved">
                              (Aprovado)
                            </template>
                            <template v-else>
                              (Reprovado)
                            </template>
                          </span>
                          <span v-else>
                            {{ approval.delegate.delegate_user_name }}
                            <template v-if="approval.approved === null">
                              (Pendente)
                            </template>
                            <template v-else-if="approval.approved">
                              (Aprovado)
                            </template>
                            <template v-else>
                              (Reprovado)
                            </template>
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon :color="(approval.approved) ? 'success' : (approval.approved === null) ? '' : 'error'" v-bind="attrs" v-on="on">
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span>
                                {{ approval.delegate.original_user_name }}
                              </span>
                            </v-tooltip>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <v-row v-if="value.status_id == CONST_CHANGE_REQUEST_STATUS.REPROVADO">
                      <v-col cols="12">
                        <v-alert dense icon="mdi-close-circle-outline" type="error">
                          <p><strong>Atenção!</strong> Essa formalização de entrega foi rejeitada em {{ moment(value.refused_date).format("DD/MM/YYYY") }}.</p>
                          <p><strong>Motivo:</strong> {{ value.refused_cause }}</p>
                        </v-alert>
                      </v-col>
                    </v-row>
                    <div class="posts" v-if="form.messages">
                      <h4>Mensagens:</h4>
                      <div v-for="(message, index) in form.messages[value.id]" :key="index">
                        <div class="received"> 
                          <span class="sender">{{ message.user_name }} - ({{ message.created_at }})</span>
                          <p>{{ message.message }}</p>
                        </div>
                        <div class="sent" v-for="(answer, i) in message.answer" :key="i">
                          <span class="sender">{{ answer.user_name }} - ({{ answer.created_at }})</span>
                          <p>{{ answer.message }}</p>
                        </div>
                      </div>
                    </div>
                    <template v-if="value.status_id == CONST_CHANGE_REQUEST_STATUS.PENDENTE_ALTERACAO && value.user == $store.getters.id">
                      <label>Resposta</label>
                      <v-textarea
                        v-model="replyMessage"
                        class="scroll-style"
                        filled
                      ></v-textarea>
                      <v-btn
                        @click="sendAnswer(value.id)"
                        color="primary">Responder Pergunta</v-btn>
                    </template>
                  </div>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDelete" max-width="350px !important">
      <v-card>
        <v-card-title class="headline">Tem certeza de que deseja excluir este item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" class="ma-2" @click="closeDelete">Cancelar</v-btn>
          <v-btn color="success" class="ma-2" @click="deleteIssueConfirm">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="snack" hide-overlay width="400">
      <v-alert v-model="snack" dense dismissible :type="snackColor" style="margin: 0">
        <div class="d-flex justify-content-between">
          <span>{{snackbarMessage}}</span>
        </div>
      </v-alert>
    </v-dialog>

    <v-dialog v-model="dialogDoneEpic" persistent max-width="960">
      <v-card>
        <div class="d-flex justify-content-between">
          <v-card-title class="text-h5">Formalização de Entrega</v-card-title>
          <v-btn fab text @click="dialogDoneEpic = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <label>Descrição do Épico</label>
              <v-textarea
                :disabled="true"
                filled
                class="scroll-style"
                v-model="form.values.epic_description"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <label>Entregável realizado</label>
              <v-text-field
                    :value="form.aux_delivery_done ?
                        formatDate(form.aux_delivery_done) :
                        moment().format('DD/MM/YYYY')"
                    readonly
                    disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label>Comentários Sobre a Entrega</label>
              <v-textarea 
                :disabled=epic.done.disabled 
                filled
                class="scroll-style"
                v-model="epic.done.comment"
                :error-messages="form.errors.comment"
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <label>Operação Assistida</label>
              <v-switch
                id="assisted_operation"
                name="assisted_operation"
                color="secondary"
                class="manual-line switch"
                v-model="epic.done.assisted_operation"
                :error-messages="form.errors.assisted_operation"
                :label="epic.done.assisted_operation ? 'Sim' : 'Não'"
                :disabled=epic.done.disabled
                inset
              ></v-switch>
            </v-col>
            <v-col cols="6">
              <label>Final Operação Assistida (baseline)</label>
              <v-menu
                ref="menu"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(epic.done.baseline)"
                    append-icon="mdi-calendar"
                    readonly
                    :disabled=epic.done.disabled
                    v-bind="attrs"
                    v-on="on"
                    :rules="epic.rules.baseline"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="epic.done.baseline"
                  :error-messages="form.errors.baseline"
                  no-title
                  scrollable
                  locale="pt-br"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="12">
              <label>Anexar Evidência</label>
              <v-file-input
                multiple
                name="evidence[]"
                truncate-length="15"
                v-model="epic.done.evidence"
                :error-messages="form.errors.evidence"
                :disabled=epic.done.disabled
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="6" v-if="!verifySameArea()">
              <label>Ponto Focal da Área Negócio (Entrega)</label>
              <v-select
                :disabled="verifySameArea()"
                v-model="epic.done.focal_point_demanding_area"
                :items="form.option.focal_points_demanding_area"
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                no-data-text="Sem ponto focal para aprovação"
                :error-messages="form.errors.focal_point_demanding_area"
                @change="focalPointClearError"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Ponto Focal da Área Negócio (Iniciativa)</label>
              <v-select
                :disabled="epic.done.disabled"
                v-model="epic.done.focal_point_responsible_area"
                :items="form.option.focal_points_responsible_area"
                item-text="name"
                item-value="id"
                append-icon="mdi-chevron-down"
                no-data-text="Sem ponto focal para aprovação"
                :error-messages="form.errors.focal_point_responsible_area"
                @change="focalPointClearError"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" align="end">
              <v-btn
                color="primary"
                elevation="2"
                @click="saveDoneEpic()"
                :disabled=epic.done.disabled
                :loading="isLoading"
              >Submeter Entrega</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="dialogSaveEpic" persistent width="600">
      <v-card>
        <div class="pa-6">
          <h5 class="text-h5 text-center font-weight-bold">
            ATENÇÃO
          </h5>
          <p class="pt-2 mb-1">
            Você está salvando um entregável sem ID do JIRA! 
          </p>
          <p>
            Um novo épico será criado no Jira e associado a esse entregável. Tem certeza que deseja continuar?
          </p>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="dialogSaveEpic = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="saveEpic()">
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VvDatePick from "@/components/VvDatePick";
import VvLoading from "@/components/VvLoading";

export default {
  props: {
    demandingArea: {
      type: Array
    },
    data: {
      type: Object
    },
    index: {
      type: Number,
    },
    focalPointOrStakeholder: {
      type: Boolean,
      default: false
    }
  },
  created: function () {
    if ( this.$route.query.df ) {
      this.delivery_formalization = 0;
    }
  },
  mounted: function () {
    this.formLoad();
    this.messageLoad();
  },
  components: {
    VvDatePick,
    VvLoading
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.data = this.form.values;
      },
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    watch_stage_id: function() { this.form.errors.stage_id = []; },
    watch_id_jira: function() { this.form.errors.id_jira = []; },
    watch_name: function() { this.form.errors.name = []; },
    watch_priority: function() { this.form.errors.priority = []; },
    watch_status_id: function() { this.form.errors.status_id = []; },
    watch_last_status_date: function() { this.form.errors.last_status_date = []; },
    watch_vsm_id: function() { this.form.errors.vsm_id = []; },
    watch_gpm_id: function() { this.form.errors.gpm_id = []; },
    watch_ppm: function() { this.form.errors.ppm = []; },
    watch_journeys_id: function() { this.form.errors.journeys_id = []; },
    watch_tribes_id: function() { this.form.errors.tribes_id = []; },
    watch_change_office_manager: function() { this.form.errors.change_office_manager = []; },
    watch_journey_tribe_dependency: function() { this.form.errors.journey_tribe_dependency = []; },
    watch_interested_areas: function() { this.form.errors.interested_areas = []; },
    watch_delivery_baseline: function() { this.form.errors.delivery_baseline = []; },
    watch_aux_delivery_planned: function() { this.form.errors.aux_delivery_planned = []; },
    watch_aux_delivery_done: function() { this.form.errors.aux_delivery_done = []; },
    watch_assisted_operation_baseline: function() { this.form.errors.assisted_operation_baseline = []; },
    watch_assisted_operation_planned: function() { this.form.errors.assisted_operation_planned = []; },
    watch_rollout_baseline: function() { this.form.errors.rollout_baseline = []; },
    watch_rollout_planned: function() { this.form.errors.rollout_planned = []; },
    watch_rollout_percentage: function() { this.form.errors.rollout_percentage = []; },
    watch_focal_point_demanding_area: function() { this.form.errors.focal_point_demanding_area = []; },
    watch_focal_point_responsible_area: function() { this.form.errors.focal_point_responsible_area = []; },
    watch_valid_tree: function() { this.form.epicOptions['validTree'] = false; },
  },
  data() {
    return {
      moment: require("moment"),
      isNotEditable: false,
      inicialStage: null,
      inicialStatus: null,
      isEdition: false,
      isLoading: true,
      isEditable: false,
      snackbar: false,
      snackbarMessage: 'Confira todos os campos do formulário',
      snackbarColor: 'error',
      snack: false,
      snackColor: '',
      objectIndex: -1,
      objectItem: {},
      dialogDelete: false,
      dialogOnlyOneIssue: false,
      dialogDoneEpic: false,
      dialogSaveEpic: false,
      delivery_formalization: -1,
      create_permission: false,
      edit_permission: false,
      form: {
        rules: {
          id_jira: [
            value => (value || '').length <= 15 || 'Máximo de 15 caracteres',
          ],
          name: [
            value => !!value || 'Campo Obrigatório.',
            value => (value || '').length <= 300 || 'Máximo de 300 caracteres',
          ],
          priority: [
            value => !!value || 'Campo Obrigatório.',
          ],
          status_id: [
            value => !!value || 'Campo Obrigatório.',
          ],
          rollout: [
            value => this.validRequireStage(value, 6) || 'Campo Obrigatório',
          ],
          development: [
            value => this.validRequireStage(value, 2) || 'Campo Obrigatório',
          ],
          asssisted_operation: [
            value => this.validRequireStage(value, 5) || 'Campo Obrigatório',
          ],
          focal_point_demanding_area: [
            value => !!value || 'Campo Obrigatório.',
          ],
          focal_point_responsible_area: [
            value => !!value || 'Campo Obrigatório.',
          ],
        },
        option: {
          stages: [],
          priority: [1, 2, 3, 4, 5],
          status: [],
          rollout_percentage: [
            {id: 0, value: "0%"},
            {id: 10, value: "10%"},
            {id: 20, value: "20%"},
            {id: 30, value: "30%"},
            {id: 40, value: "40%"},
            {id: 50, value: "50%"},
            {id: 60, value: "60%"},
            {id: 70, value: "70%"},
            {id: 80, value: "80%"},
            {id: 90, value: "90%"},
            {id: 100, value: "100%"},
          ],
          vsm: [],
          journeys: [],
          tribes: [],
          journey_tribe_dependency: [],
          tags: [],
          gpm: [],
          focal_points: [],
          interested_areas: [],
          focal_points_demanding_area: [],
          focal_points_responsible_area: [],
          squads: [],
        },
        values: {
          delivery_planned: {},
          delivery_done: {},
          delivery_formalization: []
        },
        epicOptions:[],
        issueSkelecton: {
          problem: "",
          action_plan: "",
          owner: "",
          date: "",
        },
        errors: {},
        last_status_date: '',
        last_status_name: 'Sem informação',
        aux_delivery_planned: '',
        aux_delivery_done: '',
        black_friday: false,
        demanding_area: '',
        messages: []
      },
      epic: {
        done: {
          epic_description: "",
          comment: "",
          assisted_operation: false,
          baseline: "",
          evidence: [],
          focal_point_demanding_area: "",
          focal_point_responsible_area: "",
        },
        rules: {
          baseline: [
            value => (value.length != 0) || 'Campo Obrigatório',
          ]
        },
      },
      replyMessage: '',
      user_is_transformation_manager: '',
      user_is_master: '',
      user_is_itArea:'',
      enableCR:false,
      issueUnlock: false,
      check_disabled: true,
      check_disabled_focal_point_or_stakeholder: true,
      squads: {}
    };
  },
  computed: {
    watch_stage_id() { return this.form.values.stage_id },
    watch_id_jira() { return this.form.values.id_jira },
    watch_name() { return this.form.values.name },
    watch_priority() { return this.form.values.priority },
    watch_status_id() { return this.form.values.status_id },
    watch_last_status_date() { return this.form.values.last_status_date },
    watch_vsm_id() { return this.form.values.vsm_id },
    watch_gpm_id() { return this.form.values.gpm_id },
    watch_ppm() { return this.form.values.ppm },
    watch_journeys_id() { return this.form.values.journeys_id },
    watch_tribes_id() { return this.form.values.tribes_id },
    watch_change_office_manager() { return this.form.values.change_office_manager },
    watch_journey_tribe_dependency() { return this.form.values.journey_tribe_dependency },
    watch_interested_areas() { return this.form.values.interested_areas },
    watch_delivery_baseline() { return this.form.values.delivery_baseline },
    watch_aux_delivery_planned() { return this.form.values.aux_delivery_planned },
    watch_aux_delivery_done() { return this.form.values.aux_delivery_done },
    watch_assisted_operation_baseline() { return this.form.values.assisted_operation_baseline },
    watch_assisted_operation_planned() { return this.form.values.assisted_operation_planned },
    watch_rollout_baseline() { return this.form.values.rollout_baseline },
    watch_rollout_planned() { return this.form.values.rollout_planned },
    watch_rollout_percentage() { return this.form.values.rollout_percentage },
    watch_demanding_area() { return this.form.values.demanding_area },
    watch_focal_point_demanding_area() { return this.form.values.focal_point_demanding_area },
    watch_focal_point_responsible_area() { return this.form.values.focal_point_responsible_area },
    watch_valid_tree() { return this.form.epicOptions['validTree'] }
  },
  methods: {
    changePmPo: function (squad_key){
      this.isLoading = true;
      this.form.errors['squad_key'] = [];
      this.axios
          .get("pmpo/squad/"+squad_key)
          .then((response) => {
            this.form.values.pmpo = response.data.data
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    squadNoDataText: function(){
      let text = "Selecione uma Tribo";
      if (typeof this.form.option.tribes !== 'undefined' &&  typeof this.form.option.squads !== 'undefined'){
        if (this.form.option.tribes.length > 0 && this.form.option.squads.length == 0){
          text = "Sem squad cadastrada"
        }
      }
      return text;
    },

    cleanErrorMessage: function(inputName, index) {
      if (this.form.errors[index]) {
        this.form.errors[index][inputName] = [];
      }
    },
    validRequireStage: function(value, stageId) {
      if (this.form.values.stage_id == stageId) {
        return !!value;
      }
      return true;
    },
    integrationLoad: function (item, sourceField, destineField, autoLoad) {
      let option = [];
            
      if(sourceField == 'vsm' || (typeof this.form.option[sourceField] !== 'undefined' && this.form.option[sourceField].length > 0)){
        option = this.form.option[sourceField].filter((value) => {
            return value.id === item;
          });
        if (!autoLoad) {
          destineField.forEach(element => {
            let sufixo;
            if (element == 'squads') {
              sufixo = element + '_key';
            } else {
              sufixo = element + '_id';
            }
            this.form.values[sufixo] = 0;
          });
        }
      
        if (option.length) {
          destineField.forEach(child => {
            this.form.option[child] = this.atribuiNameSquads(option[0][child]);
          });
        }
      }
      if(!this.form.option[sourceField] || this.form.option[sourceField].length === 0 || option.length === 0 ){
        this.form.option[sourceField] = this.form.epicOptions[sourceField];
        this.form.option.squads=[];
        destineField.forEach(g =>{
          if(g == 'gpm'){
            this.form.option[g] = this.form.epicOptions[g];
               option = this.form.epicOptions[g].filter((value) => {
                let param = g+"_id";
                return value.id === this.form.values[param];
             });
          }
          
        });
        destineField.forEach(child => {
          
          if(child === "squads"){
          
            option = this.form.epicOptions.squads.filter((value) => {
              return value.squad_key === this.form.values.squad_key;
            });
            this.form.option['squads'] = this.atribuiNameSquads(option);
            
          }
          });
      }
      if (sourceField === 'tribes') {
        this.form.option['squads'].sort((a, b) => (a.name > b.name) ? 1 : -1);
      }
    },
    fillMultyCombobox: function(formLoaded, autoLoad){
      let option = [];
      let oldOption =[];
      let oldTribeOption =[];
      let oldSquadOption =[];
      if(autoLoad === true){
        if(formLoaded.form.values.vsm_id){
          let vsm = formLoaded.form.option.vsm.filter((value) => {
            return value.id === formLoaded.form.values.vsm_id
          });
          if(typeof vsm !== "undefined"){
            oldOption = vsm[0].journeys;

            option['journeys'] = formLoaded.form.epicOptions.journeys.filter((value) => {
                  return value.id === this.form.values.journeys_id;
            });

            let journey = vsm[0].journeys.filter((value) => {
                  return value.id === this.form.values.journeys_id;
            });

            let tribe = journey[0].tribes.filter((value) => {
                  return value.id === this.form.values.tribes_id;
            });
            
            oldTribeOption = journey[0].tribes;
            oldSquadOption = tribe[0].squads;

            formLoaded.form.option.journeys = option['journeys'];
            oldOption.forEach((item) => {
              formLoaded.form.option.journeys.push(item);
            });

            option['tribes'] = formLoaded.form.epicOptions.tribes.filter((value) => {
              return value.id === formLoaded.form.values.tribes_id;
            }); 
            
            formLoaded.form.option.tribes = option['tribes'];
            oldTribeOption.forEach((item) => {
              formLoaded.form.option.tribes.push(item);
            });

            formLoaded.orderNameOptions(formLoaded.form.option.tribes);

            option['gpm'] = formLoaded.form.epicOptions.gpm.filter((value) => {
              return value.id === formLoaded.form.values.gpm_id;
            });
            formLoaded.form.option.gpm = option['gpm'];

            if(formLoaded.form.values.squad_key != null || formLoaded.form.values.tribes_id != null){
              let oldSquads =[];
              let squad;
              squad = oldSquadOption.filter((value) => {
                  return value.squad_key === formLoaded.form.values.squad_key;
              });
              if(squad.length == 0){
                  let squadJiraData =  formLoaded.form.option.squads.filter((value) => {
                    return value.id === formLoaded.form.values.squad_key;
                  });
                  if(squadJiraData.length > 0){
                    squadJiraData[0].squad_key = squadJiraData[0].id;
                    squadJiraData[0].active = true;
                    squadJiraData[0].disabled = true;
                    oldSquadOption.push(squadJiraData[0]);
                  }
              }
         
              oldSquadOption.forEach((item) => {
                squad = formLoaded.form.option.squads.filter((value) => {
                  return value.id === item.squad_key;
                });
                if(squad.length > 0){
                  squad[0].squad_key = squad[0].id;
                  oldSquads.push(squad[0]);
                }
              });
              
              formLoaded.form.option.squads = oldSquads;
            }
          }
        }


      }

    },
    atribuiNameSquads: function(itensArray){
      if(itensArray){
        itensArray.forEach(item => {
          if(item['squad_key']) {
            let squad = this.squads.filter((value) => {
              return value.id === item['squad_key'];
            });
            item['name'] = squad[0]['name'];
          }
        });
      }
      return itensArray;
    },
    epicOptions(){
      this.isLoading = true;
      if (typeof this.form.values.id !== 'undefined'){
          this.axios
            .get("tree/tecnologic/treebyepic/" + this.form.values.id)
              .then((response) => {
                this.form.epicOptions['vsm'] = response.data[0].vsm;
                this.form.epicOptions['validTree'] = response.data[0].vsm.validTree;
                this.form.epicOptions['journeys'] = response.data[0].vsm.journeys;
                this.form.epicOptions['tribes'] = response.data[0].vsm.journeys[0].tribes;
                this.form.epicOptions['gpm'] = response.data[0].vsm.journeys[0].tribes[0].gpm;
                this.form.epicOptions['squads'] = response.data[0].vsm.journeys[0].tribes[0].gpm[0].squads;
                
                this.checkEnableCR()
          })
          .catch((error) => {
            console.error(error);
          });
      }
          

      this.axios
          .get("v2/options/epic")
          .then((response) => {
          this.form.option.stages = response.data.options.stages;
          this.form.option.status = response.data.options.status;
          this.form.option.tags   = response.data.options.tags;
          
          this.form.option.vsm = response.data.options.vsm.filter((vsm) => {
            return vsm.id > 1;
          });
          this.form.option.journey_tribe_dependency = response.data.options.journey_tribe_dependency;
          this.startNoApply(this.form.option.journey_tribe_dependency, 1);
          if (typeof this.form.values.id !== 'undefined') {
            this.form.option.squads = response.data.options.squads;
          }
          this.form.option.interested_areas = response.data.options.interested_areas;
          this.startNoApply(this.form.option.interested_areas, 1);
          this.squads = response.data.options.squads;
          this.convertLastStatus();

          this.form.aux_delivery_planned = this.form.values.delivery_planned ? this.form.values.delivery_planned.value : '';
          this.form.aux_delivery_done = this.form.values.delivery_done ? this.form.values.delivery_done.value : '';

          this.fillMultyCombobox(this, true);
      
          let vm = this;
          this.form.option.status.map(function(item){
            if(vm.loked(item.id)){
                item.disable = true;
            }else{
              item.disable = false;
            }
          });

          this.form.option['squads'].sort((a, b) => (a.name > b.name) ? 1 : -1);
      
        })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    verifyStatus: function(){
        this.checkIssueUnlock();

        this.removeEmptyIssue();

        if (this.issueUnlock) {
          if (this.form.values.issues.length == 0) {
            this.addNewIssue();
          }
        }
    },
    removeJourneyTribes(item) {
      this.form.values.journey_tribe_dependency.splice(this.form.values.journey_tribe_dependency.indexOf(item.id), 1)
      this.form.values.journey_tribe_dependency = [...this.form.values.journey_tribe_dependency]
    },
    removeTags(item) {
      this.form.values.tags.splice(this.form.values.tags.indexOf(item.id), 1)
      this.form.values.tags = [...this.form.values.tags]
    },
    removeInterestedAreas(item) {
      this.form.values.interested_areas.splice(this.form.values.interested_areas.indexOf(item.id), 1)
      this.form.values.interested_areas = [...this.form.values.interested_areas]
    },
    removeSquad() {
      this.form.values.squad_key = '';
    },
    formLoad: function () {
      this.isLoading = true;
      this.form.values = this.data;
      let context = this;
      if (typeof this.form.values.delivery_formalization !== 'undefined') {
        this.form.values.delivery_formalization.forEach(function(element) {
          element.approvals = context.removeApprovalsDuplicated(element);
        });
      }
      
      this.changePmPo(this.form.values.squad_key);

      this.inicialStage = this.form.values.stage_id;
      this.inicialStatus = this.form.values.status_id;

      this.user_is_transformation_manager = this.userIsTransformationManager();
      this.user_is_master = this.userIsMaster();

      let usersTI = [];

      if (typeof this.form.values.id !== 'undefined') {
        usersTI.push(this.form.values.vsm_id);
        usersTI.push(this.form.values.gpm_id);
        this.form.values.pmpo.forEach(function (itemPmPo) {
          usersTI.push(itemPmPo.users_id);
        });
      }

      this.user_is_itArea = this.userIsItArea(usersTI);
      this.enableCR = (this.user_is_transformation_manager || this.user_is_master || this.user_is_itArea) ? true :false;
      this.isEditable = this.form.values.delivery_baseline != null && (!this.user_is_master);

      this.checkEnableCR();

      this.checkIssueUnlock();

      this.convertsData();

      this.checkDisabled();
      this.checkDisabledFocalPointOrStakeholder();
      this.getPermissionCreateEdit(this);
      this.epicOptions();
      this.checkEpicConcluded();
      
    },

    checkEpicConcluded() {
      if (this.form.values.status_id == this.CONST_STATUS.CONCLUIDO 
          || this.form.values.stage_id == this.CONST_STAGE.CONCLUIDO) {
        this.check_disabled_focal_point_or_stakeholder = true;
        this.check_disabled = true;
      }
    },

    journeysTribesValidate() {
      let notIsIt = this.form.values.journey_tribe_dependency.find(function (params) {
        return params == 1;   // 1 => opção 'Não Aplicavel'
      });
      if (notIsIt == 1) {
        this.form.values.journey_tribe_dependency = [1];
      }
    },
    addNewIssue: function () {
      this.isLoading = true;
      const clone = Object.assign({}, this.form.issueSkelecton);
      if (this.form.values.issues === undefined) {
        this.form.values.issues = [];
      }
      this.form.values.issues.push(clone);

      this.isLoading = false;
    },
    deleteIssue(index, issue) {
      if (
        this.form.values.issues.length > 1 &&
        [
          this.CONST_STATUS.ATRASADO,
          this.CONST_STATUS.ATENCAO,
          this.CONST_STATUS.REPLANEJADO_ATENCAO,
          this.CONST_STATUS.REPLANEJADO_ATRASADO
        ].includes(this.form.values.status_id)
      ) {
        this.objectIndex = index;
        this.objectItem = issue;
        this.dialogDelete = true;

        if (!issue.id) {
          this.form.values.issues.splice(index, 1);
          this.closeDelete();
        }
      }else if([
        this.CONST_STATUS.ATRASADO,
        this.CONST_STATUS.ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATRASADO
      ].includes(this.form.values.status_id) === false){
        this.objectIndex = index;
        this.objectItem = issue;
        this.dialogDelete = true;

        if (!issue.id) {
          this.form.values.issues.splice(index, 1);
          this.closeDelete();
        }
      }else {
        this.snack = true
        this.snackColor = "warning"
        this.snackbarMessage = "É obrigatório cadastrar no minimo um (problema / ponto de atenção) para o status atual"
      }
    },
    deleteIssueConfirm: function () {
      let index = this.objectIndex
      let issue = this.objectItem
      this.isLoading = true

      let project_id = this.$route.params.id;
      let epic_id = this.form.values.id

      let endpoint = '/project/' + project_id + '/epic/' + epic_id + '/issue';

      this.axios.delete(endpoint + '/' + issue.id, issue)
          .then(response => {
            this.closeDelete();
            this.form.values.issues.splice(index, 1);
            this.snackbarMessages(response.data.message, 'snackbarColorSuccess');
          })
          .catch(error => {
            if (error.response.data.status_id == "O épico não pode estar em 'Atenção' ou 'Atraso' para deletar pontos de atenção") {
              this.snackbarMessage = "É necessário salvar o épico antes de excluir ponto de atenção!";
              this.snack = true;
              this.snackColor = 'snackbarColor';
            } else {
              this.form.errors = error.response.data;
              this.snackbarMessages(error.response.data, 'snackbarColor');
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    handleSaveEpic() {
      if(!this.form.values.id_jira) {
        this.dialogSaveEpic = true;
      } else {
        this.saveEpic();
      }
    },
    saveEpic() {
      this.isLoading = true

      let project_id = this.$route.params.id;

      let submitData = this.submitData();

      if(!submitData.id) {
        this.axios.post('/project/' + project_id + '/epic', submitData)
          .then(response => {
            this.snackbarMessages(response.data.message, 'snackbarColorSuccess');
            this.form.values.id = response.data.epic.id;
            this.form.values.priority = response.data.epic.priority;
            this.setClickAddEpic(true);
            this.isEditable = this.form.values.delivery_baseline != null  && (!this.user_is_master);

            this.$emit('changePriority');
          })
          .catch(error => {
            this.form.errors = error.response.data;
            this.snackbarMessages(error.response.data, 'snackbarColor');
          })
          .finally(() => {
            this.checkIssueUnlock();
            this.isLoading = false;
          });
      } else {
        this.axios.put('/project/' + project_id + '/epic/' + submitData.id, submitData)
          .then(response => {
            this.snackbarMessages(response.data.message, 'snackbarColorSuccess');
            this.form.values.status_id = response.data.epic.status_id;
            this.form.values.last_status = response.data.epic.last_status;
            this.form.values.priority = response.data.epic.priority;
            this.convertLastStatus();
            this.isEditable = this.form.values.delivery_baseline != null  && (!this.user_is_master);

            this.$router.replace({ query: { panel: this.index } })

            this.$emit('changePriority');
          })
          .catch(error => {
            this.form.errors = error.response.data;
            this.snackbarMessages(error.response.data, 'snackbarColor');
          })
          .finally(() => {
            this.checkIssueUnlock();
            this.isLoading = false;
          });
      }
    },
    checkDoneEpic() {
      if (!this.userAllowedDelivery(this.$store.getters.id) || !this.epicAllowedDelivery(this.inicialStage)) {
        return false
      }

      if (this.form.epicOptions['validTree'] === false) {
        return false
      }

      if (Array.isArray(this.form.values.delivery_formalization) &&
          this.form.values.delivery_formalization.length == 0
      ) {
        return true;
      }

      if (typeof this.form.values.delivery_formalization[0] !== 'undefined' &&
          this.workflowAllowedDelivery(this.form.values.delivery_formalization[0].status_id) &&
          (this.form.values.delivery_formalization[0].status_id == this.CONST_CHANGE_REQUEST_STATUS.REPROVADO ||
              this.$store.getters.id === this.form.values.delivery_formalization[0].user
          )
      ) {
        return true;
      }

      if (typeof this.form.values.delivery_formalization[0] !== 'undefined' &&
          this.form.values.delivery_formalization[0].status_id == this.CONST_CHANGE_REQUEST_STATUS.APROVADO &&
          this.inicialStatus != this.CONST_STATUS.CONCLUIDO
      ) {
        return true;
      }

      return false
    },
    doneEpic() {
      if (this.epicAllowedDelivery(this.inicialStage)) {
        
        if (this.form.values.delivery_formalization.length > 0) {
          this.form.values.delivery_formalization.forEach(df => {
            if (df.status_id != this.CONST_CHANGE_REQUEST_STATUS.REPROVADO &&
                (df.status_id != this.CONST_CHANGE_REQUEST_STATUS.APROVADO &&
                    this.inicialStatus != this.CONST_STATUS.CONCLUIDO)) {
              this.epic.done.id = df.id;
              this.epic.done.assisted_operation = (df.assisted_operation == 0)? false : true;
              this.epic.done.comment = df.comment;
              this.epic.done.baseline = (df.baseline != null) ? df.baseline : false;
              this.epic.done.evidence = (df.evidence_file != undefined) ? df.evidence_file : [];
              this.epic.done.epic_description = this.form.values.epic_description;

              if (df.status_id != this.CONST_CHANGE_REQUEST_STATUS.PENDENTE_ALTERACAO) {
                this.epic.done.disabled = true;
              }
            }
          });
        }
        
        this.dialogDoneEpic = true
        if (JSON.stringify(this.form.option.focal_points_demanding_area) === '[]') {
          this.axios.get(`options/tap/focalpoints/${this.$route.params.id}`)
            .then(response => {
              this.form.option.focal_points_responsible_area = response.data
            }).catch(error => {
              console.error('error', error)
            });

          this.axios.get(`options/focalpoints/${this.data.id}`)
            .then(response => {
              this.form.option.focal_points_demanding_area = response.data;
            })
            .catch(error => {
              console.error('Erro', error)
            });
        }
      } else {
        this.snack = true
        this.snackColor = "warning"
        this.snackbarMessage = "O épico só pode ser concluído quando seu Estágio for: (Homologação, Em Implantação ou Rollout)"
      }
    },
    workflowAllowedDelivery(stage_id) {
      return [this.CONST_CHANGE_REQUEST_STATUS.REPROVADO, this.CONST_CHANGE_REQUEST_STATUS.PENDENTE_ALTERACAO].includes(stage_id);
    },
    epicAllowedDelivery(stage_id){
      return [this.CONST_STAGE.HOMOLOGACAO, this.CONST_STAGE.EM_IMPLANTACAO, this.CONST_STAGE.ROLLOUT].includes(stage_id)
    },
    userAllowedDelivery(loginUserId) {
      return this.form.values.vsm_id === loginUserId ||
      this.form.values.gpm_id === loginUserId ||
      this.$store.getters.type.indexOf(this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO) !== -1 ||
      this.$store.getters.type.indexOf(this.CONST_USER_TYPE.USUARIO_MASTER) !== -1 ||
      this.userIsPmPoEpic(loginUserId)
    },
    userIsPmPoEpic(loginUserId) {
      if (typeof this.form.values.pmpo !== 'undefined') {
        return this.form.values.pmpo.some((pmpo)=>{
          if (pmpo.users_id === loginUserId) {
            return true;
          }
        })
      }
      return false;
    },
    saveDoneEpic() {
      this.isLoading = true;

      let project_id = this.$route.params.id;
      let epic_id = this.form.values.id
      let submitData = this.epic.done;
      let formData = new FormData();
      let assistedOperation = this.epic.done.assisted_operation ? 1 : 0;
      
      formData.append('assisted_operation', assistedOperation);
      formData.append('baseline', this.epic.done.baseline);
      formData.append('comment', this.epic.done.comment);
      formData.append('epic_description', this.epic.done.epic_description);
      formData.append('focal_point_demanding_area', this.epic.done.focal_point_demanding_area);
      formData.append('focal_point_responsible_area', this.epic.done.focal_point_responsible_area);

      this.epic.done.evidence.forEach(evidence => {
        formData.append('evidence[]', evidence);
      })
      
      if(!submitData.id) {
        this.isLoading = true;
        this.axios.post('/project/' + project_id + '/epic/' + epic_id + '/formalization', formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
            .then(response => {
              this.snackbarMessages(response.data.message, 'snackbarColorSuccess');
              this.epic.done.disabled = true;
              this.check_disabled_focal_point_or_stakeholder = true
              this.check_disabled = true
              this.dialogDoneEpic = false;
              this.isLoading = false;

              this.$emit('changePriority');
            })
            .catch(error => {
              this.form.errors = error.response.data;
              this.snackbarMessages(error.response.data, 'snackbarColor');
            })
            .finally(() => {
              this.checkIssueUnlock();
              this.isLoading = false;
            });
      } else {
        formData.append('id', this.epic.done.id);
        this.axios.post('/project/' + project_id + '/epic/' + epic_id + '/formalization?_method=PUT', formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
            .then(response => {
              this.snackbarMessages(response.data.message, 'snackbarColorSuccess');
              this.epic.done.disabled = true;
              this.dialogDoneEpic = false;
              this.isLoading = false;

              this.$emit('changePriority');
            })
            .catch(error => {
              this.form.errors = error.response.data;
              this.snackbarMessages(error.response.data, 'snackbarColor');
            })
            .finally(() => {
              this.checkIssueUnlock();
              this.isLoading = false;
            });
      }    
    },
    checkRequiredBaseline() {
      return this.epic.done.assisted_operation == true
    },
    convertsData() {
      if (this.form.values.rollout_percentage) {
        this.form.values.rollout_percentage = parseInt(this.form.values.rollout_percentage)
      }
    },
    submitData() {
      let submitData = this.form.values;

      submitData.delivery_planned = this.form.aux_delivery_planned
      submitData.delivery_done = this.form.aux_delivery_done
      submitData.project_id = this.$route.params.id
      submitData.black_friday = this.form.values.black_friday
      submitData.demanding_area = this.form.values.demanding_area

      if (submitData.journeys_id === 0) {
        delete submitData.journeys_id;
      }

      if (submitData.tribes_id === 0) {
        delete submitData.tribes_id;
      }

      return submitData;
    },
    statusAtualColor(id, css, type, disable) {
      let color = '';

      if (type == 'fc') {
        color = css ? "rgba(93, 93, 93, 0.8) " : "gray";

        if (id == 5 || id == 2 || id == 9) {
          color = css ? "rgba(20, 146, 0, 0.8)" : "secondary";
        } else if (id == 1 || id == 3 || id == 11) {
          color = css ? "rgba(157, 157, 157, 0.8) " : "grayLight";
        }else if (id == 8) {
          color = css ? "rgba(8, 123, 188, 0.8) " : "info";
        }else if (id == 7) {
          color = css ? "rgba(93, 93, 93, 0.8) " : "gray";
        }else if (id == 4 || id == 12) {
          color = css ? "rgba(207, 30, 30, 0.8) " : "error";
        }else if (id == 10 || id == 6) {
          color = css ? "rgba(255, 183, 0, 0.8) " : "warningLight";
        }
        if(disable){
          color = css ? "rgba(0, 0, 0, 0.2) " : "grayLight";
        }
      } else {
        color = css ? "rgba(255, 255, 255, 0.8) " : "white";

        if (id == 5 || id == 2 || id == 9) {
          color = css ? "rgba(20, 146, 0, 0.3)" : "secondary";
        } else if (id == 1 || id == 3 || id == 11) {
          color = css ? "rgba(157, 157, 157, 0.3) " : "grayLight";
        }else if (id == 8) {
          color = css ? "rgba(8, 123, 188, 0.3) " : "info";
        }else if (id == 7) {
          color = css ? "rgba(93, 93, 93, 0.3) " : "gray";
        }else if (id == 4 || id == 12) {
          color = css ? "rgba(207, 30, 30, 0.3) " : "error";
        }else if (id == 10 || id == 6) {
          color = css ? "rgba(255, 183, 0, 0.3) " : "warningLight";
        }
        if(disable){
          color = css ? "rgba(255, 255, 255, 0.8) " : "white";
        }
      }



      return color;
    },
    saveIssue(index, issue) {
      this.isLoading = true

      let project_id = this.$route.params.id;
      let epic_id = this.form.values.id

      let endpoint = '/project/' + project_id + '/epic/' + epic_id + '/issue';

      if(!issue.id) {
        this.axios.post(endpoint, issue)
            .then(response => {
              this.snackbarMessages(response.data.message, 'snackbarColorSuccess');

              this.form.values.issues[index] = response.data.issue;
            })
            .catch(error => {
              this.form.errors[index] = error.response.data;
              this.snackbarMessages(error.response.data, 'snackbarColor');
            })
            .finally(() => {
              this.isLoading = false;
            });
      } else {
        this.axios.put(endpoint + '/' + issue.id, issue)
            .then(response => {
              this.snackbarMessages(response.data.message, 'snackbarColorSuccess');
            })
            .catch(error => {
              this.form[index].errors = error.response.data;
              this.snackbarMessages(error.response.data, 'snackbarColor');
            })
            .finally(() => {
              this.isLoading = false;
            });
      }
    },
    formatDate(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');

      return `${day}/${month}/${year}`;
    },
    formatDateTime(dateTime) {
      if (!dateTime) {
        return null;
      }

      const [date, time] = dateTime.split(' ');

      // eslint-disable-next-line no-unused-vars
      const [hour, minute, second] = time.split(':');

      const [year, month, day] = date.split('-');

      return `${day}/${month}/${year} - ${hour}h`;
    },
    snackbarMessages(message, color) {
      this.snack = true;
      this.snackColor = color;
      if (typeof message === 'object') {
        Object.keys(message).forEach((key) => {
          if (typeof message[key] === 'object') {
            Object.keys(message[key]).forEach((key2) => {
              if (key !== 'message') {
                this.snackbarMessage = 'Confira todos os campos do formulário';
              } else {
                this.snackbarMessage = message[key][key2];
              }
            })
          } else {
            this.snackbarMessage = message[key];
          }
        });
      } else {
        this.snackbarMessage = message;
      }
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.objectItem = {};
        this.objectIndex = -1;
      })
    },
    setClickAddEpic(value) {
      this.$emit("callSetClickAddEpic", value);
    },
    checkIssueUnlock() {
      this.issueUnlock = false;

      if (!this.form.values.id) {
        return;
      }

      if ([
        this.CONST_STATUS.ATRASADO,
        this.CONST_STATUS.ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATRASADO
      ].includes(this.form.values.status_id)) {
        this.issueUnlock = true;
      }
    },
    loked(item_id){
      if (!this.form.values.id && [
        this.CONST_STATUS.ATRASADO,
        this.CONST_STATUS.ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATRASADO
      ].includes(item_id)) {
        return true;
      }
      return false;
    },
    removeEmptyIssue() {
      if (![
        this.CONST_STATUS.ATRASADO,
        this.CONST_STATUS.ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATRASADO
      ].includes(this.form.values.status_id)) {
        this.form.values.issues.forEach(issue => {
          if(issue.action_plan == ""
            && issue.date == ""
            && issue.owner == ""
            && issue.problem == ""
          ) {
            let removeIssue = this.form.values.issues.indexOf(issue);
            this.form.values.issues.splice(removeIssue, 1);
          }
        })
      }
    },
    convertLastStatus() {
      if (this.form.values.last_status && this.form.values.last_status.value) {
        let that = this;

        this.form.last_status_name = this.form.option.status.filter(x => x.id == that.form.values.last_status.value)[0].name

        if (this.form.values.last_status.date) {
          this.form.last_status_date = this.formatDateTime(this.form.values.last_status.date);
        }
      }
    },
    checkEnableCR() {
      this.enableCR = (this.user_is_transformation_manager || this.user_is_master || this.user_is_itArea) ? true :false;

      if (this.inicialStage == this.CONST_STAGE.CANCELADO ||
        this.inicialStage == this.CONST_STAGE.CONCLUIDO ||
        this.inicialStatus == this.CONST_STATUS.CANCELADO ||
        this.inicialStatus == this.CONST_STATUS.CONCLUIDO ||
        this.form.epicOptions['validTree'] == false
        ) {
        this.enableCR = false;
      }
    },
    checkIfEpicCreated() {
      return (typeof this.form.values.id === 'undefined') ? true : false;
    },
    checkDisabled() {
      if (this.form.values.id === undefined || this.user_is_transformation_manager || this.user_is_master) {
        this.check_disabled = false;
      }

      if (typeof this.form.values.delivery_formalization !== 'undefined') {
        if (this.form.values.delivery_formalization.length > 0) {
          if (typeof this.form.values.delivery_formalization !== 'undefined' &&
              this.form.values.delivery_formalization[0].status_id === this.CONST_CHANGE_REQUEST_STATUS.PENDENTE_ALTERACAO &&
              this.$store.getters.id === this.form.values.delivery_formalization[0].user
          ) {
            this.check_disabled = false;
          }

          let isDisableAproved = false;
          this.form.values.delivery_formalization.forEach(df => {
            if (df.status_id != this.CONST_CHANGE_REQUEST_STATUS.REPROVADO
                && df.status_id != this.CONST_CHANGE_REQUEST_STATUS.PENDENTE_ALTERACAO) {
              isDisableAproved = true;
            }
          });

          if(isDisableAproved) {
            this.check_disabled = true;
          }


          if (this.form.values.delivery_formalization[0].status_id === this.CONST_CHANGE_REQUEST_STATUS.APROVADO &&
              this.user_is_master) {
            this.check_disabled = false;
          }
        }
      }
    },
    checkDisabledStatus(status_id){
      if ([
        this.CONST_STATUS.ATRASADO,
        this.CONST_STATUS.ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATENCAO,
        this.CONST_STATUS.REPLANEJADO_ATRASADO
      ].includes(status_id)) {
        return true;
      }
      return false;
    },
    checkDisabledFocalPointOrStakeholder() {
      this.check_disabled_focal_point_or_stakeholder = this.check_disabled;
      if (this.focalPointOrStakeholder) {
        this.check_disabled_focal_point_or_stakeholder = false;
      }
      if (this.form.values.id === undefined) {
        this.check_disabled_focal_point_or_stakeholder = false;
      }
    },
    createCr(value) {
      this.$emit("createChangeRequest", value);
    },
    showCr(value) {
      this.$emit("showChangeRequests", value)
    },
    messageLoad() {
      let delivery_formalization = this.form.values.delivery_formalization;

      if (typeof delivery_formalization !== 'undefined') {
        if (JSON.stringify(delivery_formalization) !== '[]') {
          Object.keys(delivery_formalization).forEach((key) => {
            this.axios
                .get(`message/generic?model=DeliveryFormalization&model_id=${delivery_formalization[key].id}`)
                .then((response) => {
                  this.form.messages[delivery_formalization[key].id] = response.data.message;
                })
                .catch((error) => {
                  console.error(error);
                });
          })
        }
      }
    },
    downloadEvidence(item) {
      this.isLoading = true;
      this.axios
        .get(`/delivery/evidence/${item.id}`)
        .then((response) => {
          this.createAndDownloadBlobFile(this.base64ToArrayBuffer(response.data.file), response.data.filename, response.data.extension);
          this.isLoading = false;
        })
        .catch((error) => {
          this.modalEvidence = false;
          this.dialogMessage = true;
          console.error(error);
          this.textMessage = 'Ocorreu um erro inesperado';
          this.typeMessage = "error";
          this.isLoading = false;
        });
    },
    sendAnswer(model_id) {
      let sendObject = {
        model: "DeliveryFormalization",
        model_id: model_id,
        message: this.replyMessage,
        parent_id: this.form.messages[model_id][this.form.messages[model_id].length - 1].id
      };
      
      this.axios
        .post(`message/generic`, sendObject).then((response) => {
          if (response.status === 200) {
            this.snack = true
            this.snackColor = "success"
            this.snackbarMessage = "Mensagem enviada com sucesso!"
            this.form.messages[model_id].push({
              user_name: "Você",
              created_at: "Agora",
              message: this.replyMessage
            })
            this.replyMessage = ''

            if (this.form.values.delivery_formalization.length > 0) {
              this.form.values.delivery_formalization.forEach(df => {
                if (df.status_id != this.CONST_CHANGE_REQUEST_STATUS.REPROVADO) {
                  this.epic.done.id = df.id;
                  this.epic.done.assisted_operation = (df.assisted_operation == 0)? false : true;
                  this.epic.done.comment = df.comment;
                  this.epic.done.baseline = (df.baseline != null) ? df.baseline : false;
                  this.epic.done.evidence = (df.evidence_file != undefined) ? df.evidence_file : [];
                  this.epic.done.epic_description = this.form.values.epic_description;
                }
              });
            }
            
            this.saveDoneEpic();
          }
        })
        .catch(error => {
          console.error(error.response);          
          this.snack = true
          this.snackColor = "error"
          this.snackbarMessage = "Falha ao enviar a mensagem!"
        });
    },
    focalPointClearError() {
      this.form.errors.focal_point = [];
    },
    verifySameArea() {
      if ((this.form.values.project_areas_id === this.form.values.demanding_area) || this.epic.done.disabled) return true
      return false
    },

    verifyCountCRApprovals(item) {
      if ([this.CONST_STATUS.REPLANEJADO_PRAZO,
        this.CONST_STATUS.REPLANEJADO_ATENCAO,
        this.CONST_STATUS.REPLANEJADO_FORMALIZADO_NEGOCIO,
        this.CONST_STATUS.REPLANEJADO_ATRASADO].indexOf(item.id) !== -1) {
        if (this.form.values.count_approvals_cr > 0) {
          return true;
        }
        return false;
      }
      return item.active;
    },
    removeApprovalsDuplicated(approversList){
      let newApproverList = [];
      let arrNames = [];
      approversList.approvals.forEach (function(item) {
        if (!arrNames.includes(item.user_name)) {
          arrNames.push(item.user_name);
          newApproverList.push(item);
        }
      });
      return newApproverList;
    }
  },
};
</script>

<style lang="scss" scoped>
.last {
  border-bottom: 1px solid #5d5d5d;
  margin-bottom: 10px;
}

.issue-card {
  border-top: 1px dashed #5d5d5d;
  // &:first-child { border: 0px; }
}

.evidence-card {
  margin-bottom: 20px;
  border-bottom: 1px dashed #5d5d5d;
  &:last-child { border: 0px; }
}

.file-evidence {
  border-bottom: 1px dot-dot-dash #5d5d5d;
}

.v-card__text label {
  font-size: 10px;
}

.margin20{
  margin-top: 20px;
}

.ellipsis-on-chip {
  span.v-chip__content {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 5px;
  }
}

.content {
  display: grid;
  padding: 12px;
  width: 100%;

  .approvers {
    margin-bottom: 20px;
    
    ul {
      list-style: none;
      padding:0px;

      li {
        float:left;
        padding: 5px 10px;
        background: #e0e0e0;
        color: rgba(0, 0, 0, 0.87);
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 5px;
      }

      li.approved-true {
        background-color: rgba(0,146,0,0.3);
        color: rgba(0,146,0,0.8);
      }

      li.approved-false {
        background-color: rgba(255,0,0,0.3);
        color: rgba(255,0,0,0.8);
      }
    }
  }

  .posts {
    .received, .sent {
      padding: 10px 20px;
      width: 100%;
      border-radius: 5px;
      margin-bottom: 10px;
      border: 1px solid rgba(0,0,0,0.12);

      .sender {
        font-weight: bold;
        line-height: 27px;
      }

      p {
        margin-bottom: 5px;
      }
    }
  }
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--background-dark-disabled) !important;
}
</style>

<template>
  <div class="vv-breadcrumbs">
    <v-breadcrumbs
        :items="itemsBreadcrumbs"
    ></v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "VvBreadcrumbs",
  props: {
    itemsBreadcrumbs: {
      type: Array,
      default: () => [],
    }
  },
}
</script>

<style lang="scss">
.vv-breadcrumbs {
  .v-breadcrumbs__item {
    font-family: Poppins, Arial, sans-serif;
    font-size: 14px;
  }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":true}},[_c('vv-loading',{attrs:{"is-loading":_vm.isLoading}}),_c('vv-breadcrumbs',{attrs:{"items-breadcrumbs":_vm.breadcrumb}}),_c('vv-filter',{attrs:{"filter-name":_vm.filterName,"fields":_vm.fields,"export-api":"/v2/report/delivery-formalization","notification-export":true},on:{"filter":_vm.filter,"reset":_vm.reset}}),_c('div',{staticClass:"delivery-formalization-vision-card"},[_c('h2',{staticClass:"title-form"},[_vm._v("Listagem de Formalização de Entregas")]),_c('v-data-table',{staticClass:"cursor icon-down",attrs:{"sort-by":"project_id","headers":_vm.headers,"items":_vm.deliveries,"search":_vm.search,"mobile-breakpoint":0,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"options":_vm.options,"server-items-length":_vm.serverItemsLength},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.epicName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"readmore",attrs:{"title":item.epicName}},[_vm._v(_vm._s(item.epicName))])]}},{key:"item.deliveryFormalizationComment",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"readmore",attrs:{"title":item.deliveryFormalizationComment}},[_vm._v(_vm._s(item.deliveryFormalizationComment))])]}},{key:"item.vsmName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"readmore",attrs:{"title":item.vsmName}},[_vm._v(_vm._s(item.vsmName))])]}},{key:"item.vsmWorkflow",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"readmore",attrs:{"title":item.vsmWorkflow}},[_vm._v(_vm._s(item.vsmWorkflow))])]}},{key:"item.focalPointResponsibleAreaName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"readmore",attrs:{"title":item.focalPointResponsibleAreaName}},[_vm._v(_vm._s(item.focalPointResponsibleAreaName))])]}},{key:"item.focalPointResponsibleAreaWorkflow",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"readmore",attrs:{"title":item.focalPointResponsibleAreaWorkflow}},[_vm._v(_vm._s(item.focalPointResponsibleAreaWorkflow))])]}},{key:"item.focalPointDemandingAreaName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"readmore",attrs:{"title":item.focalPointDemandingAreaName}},[_vm._v(_vm._s(item.focalPointDemandingAreaName))])]}},{key:"item.focalPointDemandingAreaWorkflow",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"readmore",attrs:{"title":item.focalPointDemandingAreaWorkflow}},[_vm._v(_vm._s(item.focalPointDemandingAreaWorkflow))])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"item.evidenceFile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.evidenceFile ? "Sim" : "Não")+" ")]}},{key:"no-data",fn:function(){return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"primary","loading":_vm.isLoading},on:{"click":_vm.initialize}},[_vm._v(" Recarregar ")])]},proxy:true}])})],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"500"},model:{value:(_vm.dialogMessage),callback:function ($$v) {_vm.dialogMessage=$$v},expression:"dialogMessage"}},[_c('v-alert',{staticStyle:{"margin":"0"},attrs:{"dense":"","type":_vm.typeMessage}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.textMessage)}})])],1),_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.modalEvidence),callback:function ($$v) {_vm.modalEvidence=$$v},expression:"modalEvidence"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Opções da Formalização ")]),_c('v-card-text',{staticClass:"mt-3"},[_c('v-container',[_c('h2',[_vm._v("Download das Evidências")]),_vm._l((_vm.evidenceList),function(item,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(item.evidence_name))]),_c('br'),_c('v-btn',{staticClass:"mt-1",attrs:{"color":"success","target":"_blank"},on:{"click":function($event){return _vm.downloadEvidence(item)}}},[_vm._v("Baixar Evidência")])],1)],1)})],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.goToProject}},[_vm._v(" Acessar Registro ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.closeModal}},[_vm._v(" Fechar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
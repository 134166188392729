<template>
  <div>
    <label>{{ label }}</label>
    <v-switch
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
      @change="$emit('input', !!$event)"
      :label="labelInfo"
      color="secondary"
      class="vv-switch switch mb-2"
      :class="customClass"
      inset
    />
  </div>
</template>

<script>
export default {
  name: "VvSwitch",
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    manualLine: {
      type: Boolean,
      default: true
    },
    reactiveLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inicitalClass: '',
    }
  },
  created() {
    if (this.value) {
      this.inicitalClass = "v-input--is-label-active v-input--is-dirty secondary--text v-input-open-active-without-model";  
    }
  },
  watch: {
    value() {
      this.inicitalClass = '';
    } 
  },
  computed: {
    labelInfo() {
      if (!this.reactiveLabel) return;
      return this.value ? 'Sim' : 'Não';
    },
    customClass() {
      if (this.manualLine) {
        return 'manual-line '+this.inicitalClass;
      } else {
        return this.inicitalClass;
      }
    }
  }
};
</script>

<style lang="scss">
.vv-switch .v-input__slot {
  padding-left: 8px;
}

.v-input-open-active-without-model {
  .v-input__control {
    .v-input__slot {
      .v-input--selection-controls__input {
        .v-input--switch__track {
          &.theme--light {
            color: var(--text-warning);
          }
        }
      }
    }
  }
}

.medium {
  &.active {
    .v-input__control {
      .v-input__slot {
        .v-input--selection-controls__input {
          .v-input--switch__track {
            color: #00d3c3 !important;
            caret-color: #00d3c3 !important;
          }
        }
      }
    }
  }

  .v-input__control {
    .v-input__slot {
      .v-input--selection-controls__input {
        width: 40px;

        .v-input--selection-controls__ripple {
          height: 30px;
          width: 30px;
          margin: 6px;
        }

        .v-input--switch__track {
          width: 40px;
          height: 20px;
          left: -2px;
        }

        .v-input--switch__thumb {
          top: calc(50% - 12px);
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

.small {
  &.active {
    .v-input__control {
      .v-input__slot {
        .v-input--selection-controls__input {
          .v-input--switch__track {
            color: #00d3c3 !important;
            caret-color: #00d3c3 !important;
          }
        }
      }
    }
  }
  
  .v-input__control {
    .v-input__slot {
      .v-input--selection-controls__input {
        width: 36px;

        .v-input--selection-controls__ripple {
          height: 28.4px;
          width: 28.4px;
          margin: 4px;
        }

        .v-input--switch__track {
          width: 36px;
          height: 18px;
          left: 0px;
        }

        .v-input--switch__thumb {
          top: calc(50% - 12.55px);
          height: 14.4px;
          width: 14.4px;
          left: 3px;

          &.secondary--text {
            left: -1px;
          }
        }
      }
    }
  }
}
</style>
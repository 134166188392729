<template>
  <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
              :disabled="disabled"
              v-model="dataFormated"
              :label="label"
              :error-messages="errorMessages"
              :append-icon="appendIcon"
              readonly
              :rules="rules"
              v-bind="attrs"
              v-on="on"
              :clearable="clearField"
              @click:clear="date = null"
              @input="$emit('input', dataFormated)"
              @blur="$emit('change', $event)"
      ></v-text-field>
    </template>
    <v-date-picker
            v-model="date"
            no-title
            scrollable
            locale="pt-br"
            :min="minDate"
            :max="maxDate"
            @input="(usingButton) ? false : $refs.menu.save(date)"
    >
      <v-spacer></v-spacer>
      <v-btn
              v-if="usingButton"
              text
              color="primary"
              @click="menu = false"
      >
        Voltar
      </v-btn>
      <v-btn
              v-if="usingButton"
              text
              color="primary"
              @click="$refs.menu.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>

</template>

<script>
    export default {
        props: {
            label: {
                type: String
            },
            placeholder: {
                type: String
            },
            errorMessages: {
                type: Array
            },
            disabled: {
                type: Boolean,
                default: false
            },
            value: {
                type: String
            },
            rules: {
              type: Array
            },
            clearField: {
              type: Boolean,
              default: false,
            },
            minDate: {
              type: String,
              default: ''
            },
            maxDate: {
              type: String,
              default: ''
            },
            appendIcon: {
              type: String,
              default: 'mdi-calendar'
            },
            usingButton: {
              type: Boolean,
              default: true
            }
        },
        data: () => ({
            activePicker : 'YEAR',
            date: "",
            dataFormated: "",
            menu: false,
        }),
        watch: {
            date: function () {
                this.dataFormated = this.formatDate(this.date);
                this.$emit("input", this.date);
            },
            value: function () {
                this.date = this.value;
            }
        },
        mounted() {
            this.inputLoad();
        },
        updated: function () {
            this.inputLoad();
        },
        methods: {
            inputLoad: function () {
                this.date = this.value;
            },
            formatDate(date) {
                if (!date) {
                    return null;
                }
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`;
            }
        }
    }
</script>

<style lang="scss" scoped>
  .v-picker {
    .v-date-picker-header__value button {
      font-weight: normal;
      text-transform: uppercase;
    }

    .theme--light.v-date-picker-table th {
      font-weight: bold;
      color: black;
    }

    .v-btn--rounded {
      border-radius: 0;
    }

    div.accent--text {
      color: #6A5AFF !important;
    }

    .v-date-picker-table__current.accent--text {
      border-color: #6A5AFF;
      .v-btn__content {
        color: #6A5AFF;
        caret-color: #6A5AFF;
      }
    }

    .v-btn--active.accent {
      background-color: #6A5AFF !important;
      color: #eeeeee;
      font-weight: bold;
    }
  }
</style>

<template>
  <v-alert type="error" outlined class="pa-2 mt-4 mb-9" icon="mdi-close-circle-outline">
    <span>ATENÇÃO</span>
    {{ text }} em {{ formattedDateForBr(refusedDate) }} por: {{ refusedUser }}.<br>
    <span>MOTIVO</span>
    {{ refusedCause }}.
  </v-alert>
</template>

<script>
export default {
  props: {
    refusedDate: String,
    refusedCause: String,
    refusedUser: {
      type: String,
      default: ''
    },
    text: String
  }
}
</script>

<style lang="scss">
  .v-alert.error--text {
    line-height: 24px;
    
    span {
      font-weight: 600;
      font-size: 14px;
      color: #B71816;
      margin-right: 10px;
    }
  }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":true}},[_c('vv-loading',{attrs:{"is-loading":_vm.isLoading}}),_c('vv-breadcrumbs',{attrs:{"items-breadcrumbs":_vm.breadcrumb}}),_c('delegate-approvals',{attrs:{"prop-users":_vm.propUsers}}),_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"7","md":"6"}},[_c('v-card-title',[_vm._v("Delegação de aprovação")])],1),(_vm.isApproval)?_c('v-col',{staticClass:"text-md-end",attrs:{"cols":"12","sm":"5","md":"6"}},[(_vm.isApproval)?_c('v-btn',{staticClass:"mt-sm-5 mr-3 ml-4",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.loadHistoric()}}},[_vm._v("Histórico "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-history")])],1):_vm._e(),_c('v-btn',{staticClass:"mt-sm-5 mr-3 ml-4",attrs:{"color":"primary","outlined":"","loading":_vm.loadingButton},on:{"click":function($event){return _vm.exportDelegate()}}},[_vm._v("Exportar listagem "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-tray-arrow-down")])],1),_c('v-tooltip',{attrs:{"bottom":"","max-width":"240"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-sm-5 mr-md-5",attrs:{"color":"error","size":"22 "}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,2926184338)},[_c('span',[_vm._v("Para extrair toda a base do sistema, não aplicar nenhum filtro na tela")])])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"icon-down",attrs:{"sort-by":"id","headers":_vm.headers,"items":_vm.delegation.active,"search":_vm.search,"mobile-breakpoint":0,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"options":_vm.options,"server-items-length":_vm.serverItemsLength,"page":_vm.currentPage},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.delegation_status_id",fn:function(ref){
var item = ref.item;
return [(item.delegation_status_id === _vm.CONST_DELEGATIONS_STATUS.ATIVADO)?_c('v-chip',{attrs:{"color":"#aeeac6","label":"","small":""}},[_vm._v(" Ativo ")]):(item.delegation_status_id === _vm.CONST_DELEGATIONS_STATUS.AGUARDANDO)?_c('v-chip',{attrs:{"color":"yellow lighten-1","label":"","small":""}},[_vm._v(" Pendente ")]):_c('v-chip',{attrs:{"color":"#dbdcdc","label":"","small":""}},[_vm._v(" Inativo ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(((item.delegation_status_id === _vm.CONST_DELEGATIONS_STATUS.AGUARDANDO) ||
                  (item.delegation_status_id === _vm.CONST_DELEGATIONS_STATUS.ATIVADO)) &&
                  ((_vm.$store.getters.id === item.original_user_id) || _vm.userIsMaster()))?_c('v-btn',{attrs:{"outlined":"","color":"error","small":""},on:{"click":function($event){return _vm.openDialogDelegate(item.id, item.delegate_user_name)}}},[_vm._v(" Inativar ")]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"item.initial_date",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDelegationDates(item.initial_date)))])]}},{key:"item.final_date",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDelegationDates(item.final_date)))])]}},{key:"no-data",fn:function(){return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"primary","loading":_vm.isLoading}},[_vm._v(" Recarregar ")])]},proxy:true}])})],1)],1),(_vm.showHistory)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v("Histórico de delegação de aprovação")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"icon-down",attrs:{"sort-by":"id","headers":_vm.headers,"items":_vm.delegation.inactive,"search":_vm.search,"mobile-breakpoint":0,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"options":_vm.options_history,"server-items-length":_vm.serverItemsLength_history,"page":_vm.currentPage_history},on:{"update:options":function($event){_vm.options_history=$event},"update:page":function($event){_vm.currentPage_history=$event}},scopedSlots:_vm._u([{key:"item.delegation_status_id",fn:function(ref){
                  var item = ref.item;
return [(item.delegation_status_id === _vm.CONST_DELEGATIONS_STATUS.ATIVADO)?_c('v-chip',{attrs:{"color":"#aeeac6","label":"","small":""}},[_vm._v(" Ativo ")]):(item.delegation_status_id === _vm.CONST_DELEGATIONS_STATUS.AGUARDANDO)?_c('v-chip',{attrs:{"color":"yellow lighten-1","label":"","small":""}},[_vm._v(" Pendente ")]):_c('v-chip',{attrs:{"color":"#dbdcdc","label":"","small":""}},[_vm._v(" Inativo ")])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("Nenhum histórico de delegação")])]},proxy:true}],null,false,3714646693)})],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.dialog.suspendDelegate.open),callback:function ($$v) {_vm.$set(_vm.dialog.suspendDelegate, "open", $$v)},expression:"dialog.suspendDelegate.open"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.dialog.suspendDelegate.title))]),_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.dialog.suspendDelegate.text))]),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Nome:")]),_c('p',[_vm._v(_vm._s(_vm.dialog.suspendDelegate.nameApproval))])])],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#7d7d7d","text":""},on:{"click":function($event){return _vm.closeDialogDelegate()}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.suspendDelegate()}}},[_vm._v(" Inativar ")])],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"400"},model:{value:(_vm.dialog.alertMessage.open),callback:function ($$v) {_vm.$set(_vm.dialog.alertMessage, "open", $$v)},expression:"dialog.alertMessage.open"}},[_c('v-alert',{staticClass:"ma-0",attrs:{"dense":"","type":_vm.dialog.alertMessage.type}},[_vm._v(" "+_vm._s(_vm.dialog.alertMessage.textMessage)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
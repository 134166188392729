<template>
  <v-card class="pa-6">
    <div class="grid-8 xs:grid-5 md:grid-5 gap-md">
      <div class="g-col-2 title-quarters">
        Q{{ value.quarterNumber }}
      </div>
      <div class="g-col-3 xs:g-col-2 md:g-col-2">
        <label>De:</label>
        <date-pick
            :disabled="disabled"
            :clear-field="false"
            :using-button="false"
            :append-icon="null"
            v-model="value.dateStart"
            :error-messages="getError(`${indexQuarter}.dateStart`)"
            @change="cleanError(`${indexQuarter}.dateStart`)"
        ></date-pick>
      </div>
      <div class="g-col-3 xs:g-col-2 md:g-col-2">
        <label>Até:</label>
        <date-pick
            :disabled="disabled"
            :clear-field="false"
            :using-button="false"
            :append-icon="null"
            :min-date="value.dateStart"
            v-model="value.dateEnd"
            :error-messages="getError(`${indexQuarter}.dateEnd`)"
            @change="cleanError(`${indexQuarter}.dateEnd`)"
        ></date-pick>
      </div>
      <!--
      <div class="g-col-3 xs:g-col-5 md:g-col-5">
        <label>Data final do planejamento:</label>
        <date-pick
            :disabled="disabled"
            :clear-field="false"
            :using-button="false"
            :append-icon="null"
            :max-date="value.dateEnd"
            v-model="value.datePlanning"
            :error-messages="getError(`${indexQuarter}.datePlanning`)"
            @change="cleanError(`${indexQuarter}.datePlanning`)"
        ></date-pick>
      </div>
      -->
    </div>
    <div class="grid-12 xs:grid-2 md:grid-2 gap-md">
      <div class="g-col-12 xs:g-col-2 md:g-col-2">
        <label>Label Jira</label>
        <v-autocomplete
            :items="labelJiraList"
            :disabled="disabled"
            item-text="name"
            item-value="id"
            v-model="value.jiraLabelId"
            append-icon="mdi-chevron-down"
            no-data-text="Nenhum label jira encontrado..."
            :error-messages="getError(`${indexQuarter}.jiraLabelId`)"
            @change="cleanError(`${indexQuarter}.jiraLabelId`)"
        ></v-autocomplete>
      </div>
      <!--
      <div class="g-col-2">
        <label>Assinar PT</label>
        <v-switch
            :disabled="disabled"
            color="secondary"
            class="switch"
            v-model="value.assingPt"
            inset
        ></v-switch>
      </div>
      -->
    </div>
  </v-card>
</template>

<script>
import DatePick from "@/components/VvDatePick";
import ErrorMessageHandler from "@/domains/quarters/mixins/ErrorMessageHandler";

export default {
  name: "QuarterCard",
  components: {
    DatePick
  },
  mixins: [
      ErrorMessageHandler,
  ],
  props: {
    quarter: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    indexQuarter: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    labelJiraList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return this.quarter;
      },
      set(value) {
        this.$emit('update:quarter', value);
      }
    },
    errorMessages: {
      get() {
        return this.errors;
      },
      set(value) {
        this.$emit('update:errors', value);
      }
    },
  },
}
</script>

<style scoped>
.title-quarters {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  color: #202121;
}
</style>
<template>
  <div class="outerBorder" :style="{borderColor: colors.border}">
      <div class="leftBorderDetail" :style="{borderColor: colors.border}">
          <v-icon class="iconInformation" :style="{color: colors.colorIcon}">{{ icon }}</v-icon>
      </div>
      <div class="contents" :style="{color: colors.colorFont}">
          <h3 class="titleAlert">
            {{title}}
          </h3>
          <ul>
<!--            Realizar a chamada do componente utilizando <li> </li> normal de preferência em uma iteração de array (v-for)-->
            <slot></slot>
          </ul>
      </div>
  </div>
</template>

<script>
export default {
    name: 'VvBoxAlert',
    props: {
        icon: {
            type: String,
            default: 'info'
        },
        title: {
            type: String,
            default: 'info'
        },
        colors: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>

<style>
.outerBorder {
    border: solid 1px #929497;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 10px #DBDCDC;
    border-radius: 8px;
}

.leftBorderDetail {
    border-left: solid 4px #D9D9D9;
    padding: 8px;
    margin: 8px;
}

.contents {
    padding: 8px 0px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: #6d6e70;
}

.iconInformation {
    height: 100%;
    margin-left: 13px;
    margin-right: 23px;
}

.titleAlert {
    font-size: 15px;
    color: #6d6e70;
    &::before {
        content: "" !important;
        margin-left: -5px;
        font-size: 18px;
        position: relative;
        top: 1px;
    }
}

</style>
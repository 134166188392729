<template>
  <v-container>
    <vv-loading :is-loading="isLoading"></vv-loading>

    <header-view :url-create="urlCreate" :disable-button="!isPermissionToCreate"></header-view>

    <vv-filter
      :filter-name="filterName"
      :fields="fields"
      export-api="problem"
      @filter="filter"
      @reset="reset"
    ></vv-filter>

    <div class="dores-vision-card">
      <h2 class="title-form">Listagem dores</h2>

      <v-data-table
        sort-by="id"
        :headers="headers"
        :items="problems"
        :search="search"
        :mobile-breakpoint="0"
        :header-props="headerProps"
        :footer-props="footerProps"
        class="cursor icon-down"
        @click:row="rowClicked"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        :page.sync="currentPage"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>

        <template v-slot:no-data>
          <v-btn
            class="ma-2"
            outlined
            color="primary"
            :loading="isLoading"
            @click="list(payload)"
          >
            Recarregar
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <vv-alert
      v-model="alert.show"
      :message="alert.message"
      :type="alert.type"
      :dismissible="alert.dismissible"
    />

    <v-dialog v-model="modalInterativo" width="800" :loading="isLoading">
      <v-card>
        <v-card-title class="font-weight-bold"> Opções da Dor </v-card-title>
        <div v-if="fullLoad">
            <div class="mx-6 mt-2 mb-6">
            <div>Selecione as ideias a serem vinculadas a Dor</div>
              <v-row>
                <v-col sm="12" md="12" cols="12">
                    <vv-multiple-select
                      label="Lista de ideias"
                      v-model="listIdeasLinkedProblem"
                      :items="listIdeas"
                      item-text="title"
                      item-value="id"
                      :disabled="isEditingLink"
                    >
                      <template #chip="scope">
                        {{ scope.id }} - {{ scope.title }}
                      </template>
                      <template #cell="scope">
                        {{ scope.id }} - {{ scope.title }}
                      </template>
                    </vv-multiple-select>
                </v-col>
              </v-row>
            </div>
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="goToProblem" :loading="isLoading"> Acessar Registro </v-btn>
          <v-btn color="primary" @click="goToIdea" :loading="isLoading"> Criar ideia para a Dor </v-btn>
          <v-btn color="primary" v-if="isEditingLink" @click="releaseButton" :loading="isLoading"> Vinculação ideia </v-btn>
          <v-btn color="primary" v-else @click="linkIdeasToTheProblem" :loading="isLoading">Salvar vínculo</v-btn>
          <v-btn color="primary" @click="closeModal" :loading="isLoading"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<style lang="scss" scoped>
  .dores-vision-card {
    background-color: white;
    padding: 24px 30px;
    border-radius: 6px;
    margin-top: 20px;

    h2 {
      color: #5d5d5d;
      font-size: 18px;
      font-weight: bold;
    }

    .title_colunm {
      font-weight: bold !important;
      text-align: center !important;
    }
  }

  .wrap-text-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: initial;
  }
</style>

<script>
import problemServiceFactory from "@/services/problemService";
import HeaderView from "@/components/HeaderView";
import VvLoading from "@/components/VvLoading";
import VvFilter from "@/components/VvFilter";
import VvAlert from "@/components/common/VvAlert";
import VvMultipleSelect from "@/components/common/VvMultipleSelect";

const problemService = problemServiceFactory();

export default {
  components: {
    HeaderView,
    VvAlert,
    VvLoading,
    VvFilter,
    VvMultipleSelect,
  },
  data: () => ({
    listOptions: {
      impactedAreas: [],
      statusProblem: [],
      frequencyProblem: [],
      problemImpact: [],
      urgentResolution: [],
      mainImpacted: [],
    },
    fieldsBoolean: [
      { id: true, name: 'Sim'},
      { id: false, name: 'Não'}
    ],
    alert: { 
      show: false,
      message: '',
      type: 'info',
      dismissible: false
    },
    dialog: {
      show: false
    },
    isEditingLink: true,
    ideasToLink: [],
    listIdeas: [],
    listIdeasLinkedProblem: [],
    modalInterativo: false,
    item: undefined,
    payload: {},
    fullLoad: false,
    isLoading: false,
    urlCreate: "/dor/criar",
    search: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    footerProps: {
      "items-per-page-text": "Linhas por página",
      "items-per-page-options": [5, 10, 15, 30, 50, 100],
    },
    options: {
      itemsPerPage: 15,
    },
    serverItemsLength: 0,
    headers: [
      { text: "ID Dor", value: "id", align: "center", class: "cabecalho" },
      {
        text: "TÍtulo da Dor",
        value: "problem_title",
        width: 250,
        class: "cabecalho",
      },
      { text: "ID Ideia", value: "ideas_id", align: "center", class: "cabecalho" },
      {
        text: "Descrição Dor",
        value: "problem_description",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Área de negócio responsável",
        value: "responsible_area",
        class: "cabecalho",
        align: "center"
      },
      { text: "Status Dor", value: "problem_status", align: "center", class: "cabecalho" },
      {
        text: "Impacto da Dor",
        value: "problem_impact",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Descrição do impacto",
        value: "description_impact",
        width: 120,
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Frequência da Dor",
        value: "problem_frequency",
        width: 120,
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Urgência para resolução",
        value: "urgency_resolution",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Principais impactados",
        value: "main_impacted",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Causa raiz conhecida",
        value: "root_cause",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Detalhe causa raiz",
        value: "description_root_cause",
        align: "center",
        class: "cabecalho",
      },
      {
        text: "Sugestões de correções",
        value: "suggestion",
        align: "center",
        class: "cabecalho",
      },
    ],
    problems: [],
    objectIndex: -1,
    objectItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
    fields: [],
    currentPage: 1,
    filterName: "problem",
    isPermissionToCreate: true,
  }),

  watch: {
    options: {
      handler() {
        this.fetchDatatable();
      },
      deep: true,
    },
  },

  created() {
    this.loadLists();
    this.verifyPermissionToCreate();
    this.loadListIdeas();
  },

  methods: {
    async loadLists() {
      try {
        const result = await problemService.loadLists();
        this.listOptions = {
          ...result,
          impactedAreas: result.impactedAreas.filter(({ id }) => id > 1),
        };
        this.createFilterFields();
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      }
    },

    async loadListIdeas() {
      this.isLoading = true;
      try {
        const result = await problemService.loadListIdeas();
        this.listIdeas = result.ideas_list
        this.isLoading = false;
      } catch ({message}) {
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      }
    },

    async listIdeasLinkedFromProblem() {
      this.isLoading = true;
      try {
        const result = await problemService.ListIdeasLinkedToProblem(this.item);
        this.listIdeasLinkedProblem = result.ideas_linked_to_the_problem.map(({ id }) => id);
        this.isLoading = false;
      } catch ({message}) {
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.fullLoad = true
      }
    },

    async list(payload) {
      this.isLoading = true;

      try {
        const result = await problemService.list(payload)
        this.problems = result.data
        this.serverItemsLength = result.total
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.isLoading = false;
      }
    },

    async linkIdeasToTheProblem() {
      this.isLoading = true;

      try {
        const result = await problemService.linkIdeasToTheProblem(this.item, this.listIdeasLinkedProblem)
        this.listIdeasLinkedFromProblem();
        this.list();
        this.isEditingLink = true;
        this.alert = {
          show: true,
          message: result.message,
          type: "success",
          dismissible: true,
        };
        
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.isLoading = false;
      }
    },

    async verifyPermissionToCreate() {
      this.isLoading = true;
      try {
        const result = await problemService.verifyPermissionToCreate(this.item, this.ideasToLink);
        this.isPermissionToCreate = result;
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.isLoading = false;
      }
    },

    onConfirmation() {
      if(!this.isEditing) {
        this.dialog.show = true;
        return;
      }
      this.unlinkIdeasFromProblem();
    },

    goToProblem() {
      this.$router.push(`/dor/${this.item}`);
    },

    goToIdea() {
      this.$router.push(`/ideia/criar/${this.item}`);
    },

    closeModal() {
      this.modalInterativo = false;
      this.listIdeasLinkedProblem = [];
      this.fullLoad = false;
      this.isEditingLink = true;
    },

    releaseButton() {
      this.isEditingLink = false;
    },

    rowClicked({id}) {
      this.modalInterativo = true
      this.item = id
      this.listIdeasLinkedFromProblem();
    },

    createFilterFields() {
      this.fields = [
        {
          type: "number",
          label: "ID Dor",
          sm: "2",
          id: "project_id",
          name: "project_id",
          payloadBackend: "id[eq]",
          items: [],
        },
        {
          type: "text",
          label: "Título da Dor",
          sm: "4",
          id: "problem_title",
          name: "problem_title",
          payloadBackend: "problem_title[like]",
          items: [],
        },
        {
          type: "multiautocomplete",
          label: "Atrelado à ideia",
          disabled: true,
          sm: "4",
          id: "id",
          name: "name",
          payloadBackend: "atrelado_a_ideia[or]",
          items: this.fieldsBoolean
        },
        {
          type: "number",
          label: "ID Ideia",
          disabled: true,
          sm: "2",
          id: "idea_id",
          name: "idea_id",
          payloadBackend: "idea_id[eq]",
          items: [],
        },
        {
          type: "multiautocomplete",
          label: "Área negócio Responsável",
          sm: "4",
          id: "area_id",
          name: "area_id",
          payloadBackend: "area_id[or]",
          items: this.listOptions.impactedAreas,
        },
        {
          type: "multiautocomplete",
          label: "Status Dor",
          sm: "4",
          id: "problem_status_id",
          name: "problem_status_id",
          payloadBackend: "problem_status_id[or]",
          items: this.listOptions.statusProblem,
        },
        {
          type: "multiautocomplete",
          label: "Impacto da Dor",
          sm: "4",
          id: "problem_impact_id",
          name: "problem_impact_id",
          payloadBackend: "problem_impact_id[or]",
          items: this.listOptions.problemImpact,
        },
        {
          type: "multiautocomplete",
          label: "Frequência da Dor",
          sm: "4",
          id: "problem_frequency_id",
          name: "problem_frequency_id",
          payloadBackend: "problem_frequency_id[or]",
          items: this.listOptions.frequencyProblem,
        },
        {
          type: "multiautocomplete",
          label: "Urgência para resolução",
          sm: "4",
          id: "urgency_resolution_id",
          name: "urgency_resolution_id",
          payloadBackend: "urgency_resolution_id[or]",
          items: this.listOptions.urgentResolution,
        },
        {
          type: "multiautocomplete",
          label: "Principais impactados",
          sm: "4",
          id: "main_impacted_id",
          name: "main_impacted_id",
          payloadBackend: "main_impacted_id[or]",
          items: this.listOptions.mainImpacted,
        },
        {
          type: "multiautocomplete",
          label: "Causa raiz conhecida",
          disabled: false,
          sm: "4",
          id: "id",
          name: "name",
          payloadBackend: "root_cause_boolean[or]",
          items: this.fieldsBoolean,
        },
      ];

      let fieldsStore = this.getGlobalFilters(this.filterName);
      if (fieldsStore !== undefined) {
        this.fields = fieldsStore;

        let payload = {};

        this.fields.map((field) => {
          if (field.value) {
            payload[field.payloadBackend] = field.value;
          }
        });
        if (Object.keys(payload).length) {
          payload.page = 1;
          this.filter(payload);
        }
      }
    },

    filter(payload) {
      this.payload = payload;
      this.setPagination(payload.page);
      this.list(payload);
    },

    reset() {
      this.payload = {};
      this.setPagination();
      this.list();
    },

    fetchDatatable() {
      this.setPagination(this.payload.page);
      this.list(this.payload);
    },

    setPagination() {
      this.payload["page"] = this.options.page;
      this.payload["page_size"] = this.options.itemsPerPage;
      this.payload["sortBy"] = this.options.sortBy[0];
      this.payload["sortDesc"] = 0;
      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.payload["sortDesc"] = this.options.sortDesc[0] ? 1 : 0;
      }
    },
  },
};
</script>

<template>
  <div class="history-delivery-formalization">
    <v-expansion-panels v-model="isOpenHistory" accordion>
      <v-expansion-panel>
        <hr/>
        <v-expansion-panel-header>ver histórico</v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-if="items.length > 0">
            <details-delivery-formalizations
              :items="items" 
              :disable-response="true"
            >
            </details-delivery-formalizations>
          </template>
          <template v-else>
            <div class="text-center">carregando...</div>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import deliveryFormalizationServiceFactory from "@/domains/deliveryFormalization/services/deliveryFormalizationService";
import DetailsDeliveryFormalizations from './DetailsDeliveryFormalizations.vue';

const deliveryFormalizationService = deliveryFormalizationServiceFactory();

export default {
  components: {
    DetailsDeliveryFormalizations
  },
  props: {
    formalizationId: Number,
    openHistory: Number
  },
  data: () => ({
    items: [],
    isOpenHistory: -1,
  }),
  watch: {
    openHistory: function (newVal, oldVal) {
      this.isOpenHistory = oldVal

      setTimeout(() => {
        this.isOpenHistory = newVal
      }, 1000)
    },
    formalizationId: function () {
      this.items = [];
      this.isOpenHistory = -1;
    },
    isOpenHistory: function (newVal) {
      if(newVal > -1 && this.items.length === 0){
        this.load(this.formalizationId);
      }
    }
  },
  methods: {
    async load(id) {
      const formalizationHistory = await deliveryFormalizationService.loadDeliveryFormalizationHistory(id);
      if (formalizationHistory.status === 200) {
        this.items = formalizationHistory.data.history;
      } else {
        this.errorAlert = true;
      }
    }
  }
};
</script>

<style lang="scss">
.history-delivery-formalization {
  margin-top: 20px;

  .v-expansion-panel {
    hr {
      border: none;
      background: #929497;
      height: 1px;
      width: calc(100% - 222px);
      margin: 0px auto -26px;
    }

    &.v-expansion-panel--active {
      &.v-item--active {
        hr {
          margin-bottom: -34px;
        }
      }
    }

    .v-expansion-panel-header {
      width: fit-content;
      background-color: #ffffff;
      margin: auto;
      padding: 0px 16px;
      color: #929497;
      font-size: 14px;
    }

    .v-expansion-panel-content {
      padding-top: 23px;

      .v-expansion-panel-content__wrap {
        padding: 0px;
      }
    }
  }
}
</style>
import axios from "@/plugins/axios";

export default function closuresServiceFactory() {
  return {
    listClosures() {
      return axios
        .get(`v2/finalization/workflow`)
        .then(res => res);
    },
    sendMessage(project_id, closure_id, data) {
      return axios
        .post(`v2/project/${project_id}/finalization/${closure_id}/message`, data)
        .then(res => res);
    },
    approve(project_id, closure_id) {
      return axios
        .post(`v2/project/${project_id}/finalization/workflow/${closure_id}/pass`)
        .then(res => res);
    },
    refuse(project_id, closure_id, data) {
      return axios
      .post(`v2/project/${project_id}/finalization/workflow/${closure_id}/fail`, data)
      .then(res => res);
    }
  }
}
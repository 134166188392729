import NotificationDialog from "@/domains/notifications/components/NotificationDialog/NotificationDialog.vue";
import HeaderServiceFactory from "@/template/services/headerService.js";

const headerService = HeaderServiceFactory();

export default {
    props: {
        hasNotifications: {
            type: Boolean,
            default: false
        }
    },
    components: {
        NotificationDialog
    },
    data() {
        return {
            notificationsMenu: false,
            notifications: [],
            totalNotifications: 0,
            notificationDialog: {
                show: false,
                data: {}
            }
        }
    },
    methods: {
        async loadNotifications() {
            this.$showLoading();
            try {
                const notifications = await headerService.loadNotifications(true);
                this.notifications = notifications.data;
                this.notificationsMenu = true;
                this.totalNotifications = notifications.total;
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        openNotificationModal(notification) {
            this.notificationDialog = {
                show: true,
                data: notification
            }
            this.changeNotificationStatus(true, notification)
        },
        async changeNotificationStatus(status, data) {
            let payload = {
                status: status,
                notifications: [ data.id ]
            };

            try {
                this.$showLoading();
                await headerService.changeNotificationStatus(payload);
                this.loadNotifications();
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        async deleteNotifications(data) {
            let payload = {
                notifications: [ data.id ]
            };

            try {
                this.$showLoading();
                await headerService.deleteNotifications(payload);
                this.loadNotifications();
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        }
    }
}
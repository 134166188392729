<template>
  <label>
    {{ label }}
    <v-textarea
      :counter="counter"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
      :error-messages="errorMessages"
      class="scroll-style"
      filled
      @input="$emit('input', $event)"
    />
  </label>
</template>

<script>
export default {
  name: "VvTextareaInput",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    counter: {
      type: Boolean,
      default: true
    }
  },
};
</script>

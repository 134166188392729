<template>
  <v-container :fluid="true">

    <vv-loading :is-loading="isLoading"></vv-loading>

    <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />

    <v-card class="card-options-management">
      <h2 class="title-form mb-0 pa-4 pb-0">Gestão de opções</h2>
      <v-card-text class="py-8 px-6">
        <v-row>
          <v-col md="6" cols="12">
            <label for="stage">Etapa</label>
            <v-autocomplete
                id="stage"
                name="stage"
                v-model="stage"
                :items="stages"
                append-icon="mdi-chevron-down"
                @input="loadStageFields"
            >
            </v-autocomplete>
          </v-col>
          <v-col md="6" cols="12">
            <label for="stage">Campo</label>
            <v-autocomplete
                id="field"
                name="field"
                v-model="field"
                :items="stageFields"
                item-text="field"
                item-value="model"
                append-icon="mdi-chevron-down"
                no-data-text="Selecione uma etapa"
                @input="loadValues"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="card-options-management" v-if="values.length && !isCampaign">
      <v-card-text class="py-8 px-6">
        <v-row>
          <v-col>
            <h2 align="start" class="text-gray">Valores</h2>
          </v-col>
          <v-col class="align-end" v-if="((user_is_transformation_manager || user_is_master) && permission_create_edit) || (permission_master && user_is_master)">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" fab x-small @click="addValue">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Adicionar</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <v-list>
          <v-list-item v-for="(item, index) in values" :key="index">
            <v-list-item-icon v-if="!item.id" class="options-management-remove-value">
              <v-icon color="red" @click="removeValue(item)">
                mdi-close
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-text-field
                  v-model="item.name"
                  :value="item.name"
                  :disabled="disabledField(item)"
                  @change="addAuthUser(index)"
              ></v-text-field>
            </v-list-item-content>
            <v-list-item-icon>
              <v-switch
                  color="secondary"
                  class="manual-line switch manual-line-list-item-icon"
                  v-model="item.active"
                  :label="item.active ? 'Ativo' : 'Inativo'"
                  inset
                  :disabled="disabledSwitch(item)"
                  @change="checkOneActive(), addAuthUser(index)"
              ></v-switch>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <v-card class="card-options-management" v-if="isCampaign"> 
      <v-card-text class="py-8 px-6">
        <v-row>
          <v-col>
            <h2 align="start" class="text-gray">Campanhas</h2>
          </v-col>
          <v-col class="align-end" v-if="((user_is_transformation_manager || user_is_master))">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" fab x-small @click="addValueCampaign">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Adicionar</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <template>
          <v-expansion-panels
            v-model="createOpen"
          >
            <v-expansion-panel
              v-for="(campaign, index) in campaigns"
              :key="index"
            >
              <v-expansion-panel-header>  
                <div class="d-flex justify-space-between align-center">
                  <div class="d-flex align-center">
                    <v-list-item-icon v-if="!campaign.value.id" class="my-0 ml-0 mr-4" >
                      <v-icon color="red" @click.stop="removeCampaign(campaign.value)">
                        mdi-close
                      </v-icon>
                    </v-list-item-icon>
                    <span>{{ campaign.value.name }}</span>
                  </div>
                  <v-switch
                    @click.stop="changeStatus(campaign.value.id)"
                    color="secondary"
                    class="switch my-0 mr-6"
                    style="display: inline-block;"
                    hide-details
                    v-model="campaign.value.active"
                    :label="campaign.value.active ? 'Ativo' : 'Inativo'"
                    inset
                    :disabled="disabledSwitch(campaign.value)"
                  ></v-switch>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <campaign v-model="campaign.value" @updateCampaigns="loadValues" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-card-text>
    </v-card>

    <v-row v-if="values.length && (permission_active || permission_create_edit) && !isCampaign">
      <v-col cols="12" class="align-end">
        <v-btn color="secondary" class="btn-save-project" @click="validFields()" :loading="isLoading">
          Salvar
        </v-btn>
      </v-col>
    </v-row>

    <vv-alert 
        v-model="alert.show" 
        :message="alert.message"  
        :type="alert.type" 
        :dismissible="alert.dismissible" />

    <v-dialog v-model="dialogMessage" hide-overlay persistent width="400">
      <v-alert dense :type="typeMessage" style="margin: 0">
        <div class="d-flex justify-content-between">
          <span>{{ textMessage }}</span>
          <v-button @click="dialogMessage = false">
            <v-icon> mdi-window-close </v-icon>
          </v-button>
        </div>
      </v-alert>
    </v-dialog>
  </v-container>
</template>
<script>
import VvLoading from "@/components/VvLoading";
import Campaign from "@/components/forms/Campaign";
import campaignServiceFactory from "@/services/campaignService";
import VvAlert from "@/components/common/VvAlert";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

const campaignService = campaignServiceFactory();

export default {
  components: {
    VvBreadcrumbs,
    VvLoading,
    Campaign,
    VvAlert
  },

  data: () => ({
    isLoading: true,
    urlCreate: '',
    optionsManagement: [],
    stages: [],
    fields: [],
    stageFields: [],
    stage: null,
    field: null,
    values: [],
    oneActive: false,
    initialObj: {
      name: '',
      active: true
    },
    campaignList: [],
    user_is_transformation_manager: false,
    user_is_master: false,
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    permission_active: false,
    permission_create_edit: false,
    permission_master: false,
    dialogMessage: false,
    typeMessage: "success",
    textMessage: "",
    url: "",
    isCampaign: false,
    isNoEndDate: false,
    alert: {
        show: false,
        message: "",
        type: "info",
        dismissible: false,
    },
    createOpen: null,
    breadcrumb: [
      {
        text: 'Gestão',
        disabled: true,
      },
      {
        text: 'Opções',
        disabled: false,
      },
    ],
  }),

  dialogMessage(val) {
    if (!val) return;

    setTimeout(function () {
      this.dialogMessage = false;
      this.typeMessage = "success";
      this.textMessage = "";
    }, 2000);
  },

  computed: {
    campaigns(){ 
      return this.campaignList.map((campaign) => ({
        value: campaign
      })) 
    }
  },

  created() {
    this.user_is_transformation_manager = this.userIsTransformationManager();
    this.user_is_master = this.userIsMaster();
    this.initialize();
  },

  methods: {
    initialize() {
      this.axios.get('options/management')
          .then(response => {
            this.optionsManagement = response.data.options_management;
            this.loadOptions();
          })
          .catch(error => {
            console.log(error);
          });
    },
    loadOptions() {
      this.optionsManagement.map(item => {
        this.loadOptionsStage(item);
        this.loadOptionsFields(item);
      });

      this.isLoading = false;
    },
    loadOptionsStage(item) {
      if (!this.stages.includes(item.stage)) {
        this.stages.push(item.stage);
      }
    },
    loadOptionsFields(item) {
      let obj = item;

      if (!this.fields[item.stage]) {
        this.fields[item.stage] = [obj];
      } else {
        this.fields[item.stage].push(obj);
      }
    },
    loadStageFields() {
      this.values = [];
      this.field = null;
      this.stageFields = this.fields[this.stage];
    },
    checkPermissionCreateEdit() {
      this.permission_create_edit = this.stageFields.filter(item => item.model === this.field)[0].permission_create_edit;
    },
    checkPermissionActive() {
      this.permission_active = this.stageFields.filter(item => item.model === this.field)[0].permission_active;
    },
    checkPermissionMaster() {
      this.permission_master = this.stageFields.filter(item => item.model === this.field)[0].only_master_permission;
    },
    loadValues() {
      this.isLoading = true;
      const campaign = this.field === "Campaign";
      campaign ? this.isCampaign = true : this.isCampaign = false;
      this.axios.get(`options/management/${this.field}`)
          .then(response => {
            campaign ? this.campaignList = response.data : this.values = response.data;

            this.checkPermissionCreateEdit();
            this.checkPermissionActive();
            this.checkPermissionMaster();
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
            this.createOpen = null;
          });
    },
    checkOneActive() {
      this.oneActive = this.values.filter(item => item.active).length === 1;
    },
    addAuthUser(index) {
      this.values[index].user = this.$store.getters.id
    },
    addValue() {
      const clone = Object.assign({}, this.initialObj);
      this.values.push(clone);

      this.checkOneActive();
    },
    addValueCampaign() {
      const clone = Object.assign({}, this.initialObj);
      this.campaignList.push(clone);
      this.createOpen = this.campaigns.length - 1;
      
      this.checkOneActive();
    },
    removeValue(item) {
      this.checkOneActive();

      if (this.oneActive) {
        if (!item.active) {
          this.removeItem(item);
        }
      } else {
        this.removeItem(item);
      }

      this.checkOneActive();
    },
    removeItem(item) {
      this.values.splice(this.values.indexOf(item), 1);
    },
    removeCampaign(item) {
      this.campaignList.splice(this.campaignList.indexOf(item), 1);
    },
    validFields() {
      let errox = false;
      Object.keys(this.values).forEach((key) => {
        if (this.values[key].name == "") {
          errox = true;
          this.typeMessage = "warning";
          this.dialogMessage = true;
          this.textMessage = "Por favor, preencha todos os campos";
          return;
        }
      });

      if (!errox) {
        this.save();
      }
    },
    save() {
      this.isLoading = true;
      this.axios
          .post(`options/management`, {
            model: this.field,
            user_is_master: this.user_is_master,
            values: this.values
          })
          .then(response => {
            this.snackbar = true;
            this.snackbarMessage = response.data.message;
            this.snackbarColor = 'success';

            this.loadValues();
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
          });
    },
    disabledField(item) {
      this.permission_create_edit = (this.permission_master && this.user_is_master) ? true : this.permission_create_edit;
      return item.readonly || !(this.user_is_master || this.user_is_transformation_manager) || !this.permission_create_edit;
    },
    disabledSwitch(item) {
      this.permission_active = (this.permission_master && this.user_is_master) ? true : this.permission_active;
      return (item.active && this.oneActive) || item.readonly || !(this.user_is_master || this.user_is_transformation_manager) || !this.permission_active;
    },
    async changeStatus(id) {
      if(this.user_is_transformation_manager || this.user_is_master){
        this.isLoading = true;
        try {
          const response = await campaignService.changeStatus(id);
          const message = response.active === false ? "Campanha inativa com sucesso" : "Campanha ativa com sucesso";
          this.alert = {
              show: true,
              message,
              type: 'success',
              dismissible: true
          };
          this.isLoading = false;
        } catch (error) {
          const message = error.message || error;
          this.alert = {
              show: true,
              message,
              type: 'error',
              dismissible: true
          };
          this.isLoading = false;
        }
      }
    }
  }
}
</script>

<style scoped>
.card-options-management {
  margin-top: 15px
}

.options-management-remove-value {
  margin: 20px 0 0 !important;
}
</style>

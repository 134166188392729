var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":true}},[_c('vv-breadcrumbs',{attrs:{"items-breadcrumbs":_vm.breadcrumb}}),_c('div',{staticClass:"notifications-index"},[_c('h1',[_vm._v("Notificações")]),_c('div',{staticClass:"actions-info"},[_c('div',{staticClass:"actions"},[_c('button',{staticClass:"read-status",class:_vm.listOnlyRead ? 'selected' : '',on:{"click":function($event){_vm.listOnlyRead = true}}},[_vm._v(" Todas ")]),_c('button',{staticClass:"read-status",class:!_vm.listOnlyRead ? 'selected' : '',on:{"click":function($event){_vm.listOnlyRead = false}}},[_vm._v(" Não Lidas ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"action",attrs:{"disabled":_vm.selected.length == 0 ? true : false},on:{"click":function($event){return _vm.changeNotificationStatus(true)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-open-outline")])],1)]}}])},[_c('span',[_vm._v("Marcar como lida")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"action",attrs:{"disabled":_vm.selected.length == 0 ? true : false},on:{"click":function($event){return _vm.changeNotificationStatus(false)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-outline")])],1)]}}])},[_c('span',[_vm._v("Marcar como não lida")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"action",attrs:{"disabled":_vm.selected.length == 0 ? true : false},on:{"click":function($event){return _vm.deleteNotifications()}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}])},[_c('span',[_vm._v("Excluir notificação")])])],1),_c('div',{staticClass:"info"},[_vm._v(" Quantidade de notificações: "+_vm._s(_vm.serverItemsLength)+" ")])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.notifications,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"options":_vm.options,"server-items-length":_vm.serverItemsLength,"page":_vm.currentPage,"mobile-breakpoint":0,"show-select":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.currentPage=$event},"click:row":_vm.openNotification},scopedSlots:_vm._u([{key:"item.moduleName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",class:!item.isRead ? 'not-read' : '',staticStyle:{"align-items":"center"}},[(!item.isRead)?_c('div',{staticClass:"not-read-bullet"}):_vm._e(),_vm._v(" "+_vm._s(item.moduleName)+" ")])]}},{key:"item.notificationTypeName",fn:function(ref){
var item = ref.item;
return [_c('div',{class:!item.isRead ? 'not-read' : ''},[_vm._v(_vm._s(item.notificationTypeName))])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"message-column",class:!item.isRead ? 'not-read' : '',domProps:{"innerHTML":_vm._s(item.message)}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{class:!item.isRead ? 'not-read' : ''},[_vm._v(_vm._s(_vm.convertDateToNotificationDefault(item.createdAt, true)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 hiddenIfDisable",attrs:{"icon":"","disabled":item.route === '' || item.hasDownload},on:{"click":function($event){return _vm.$router.push(item.route)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v("Abrir link")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 hiddenIfDisable",attrs:{"icon":"","disabled":!item.hasDownload},on:{"click":function($event){return _vm.pathBlank(item.route)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-tray-arrow-down ")])],1)]}}],null,true)},[_c('span',[_vm._v("Fazer download")])]),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('div',{staticClass:"notification-action-menu"},[_c('ul',[_c('li',[_c('button',{staticClass:"trash",on:{"click":function($event){return _vm.deleteNotifications(item.id)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")]),_vm._v(" Excluir ")],1)]),_c('li',[_c('button',{on:{"click":function($event){return _vm.changeNotificationStatus(true, item.id)}}},[_c('v-icon',[_vm._v("mdi-email-open-outline")]),_vm._v(" Lida ")],1)]),_c('li',[_c('button',{on:{"click":function($event){return _vm.changeNotificationStatus(false, item.id)}}},[_c('v-icon',[_vm._v("mdi-email-outline")]),_vm._v(" Não Lida ")],1)])])])])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.loadNotifications()}}},[_vm._v(" Recarregar ")])]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('notification-dialog',{attrs:{"data":_vm.notificationDialog.data,"origin":'page'},on:{"input":function($event){_vm.notificationDialog.show = $event},"changeNotificationStatus":_vm.changeNotificationStatus,"deleteNotifications":_vm.deleteNotifications},model:{value:(_vm.notificationDialog.show),callback:function ($$v) {_vm.$set(_vm.notificationDialog, "show", $$v)},expression:"notificationDialog.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
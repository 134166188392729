var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workflow-details"},[_c('v-btn',{staticClass:"mt-sm-5",attrs:{"color":"primary","outlined":""},on:{"click":_vm.load}},[_vm._v(" Histórico Workflow "),_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-history ")])],1),_c('modal',{attrs:{"title":"workflow","width":700},model:{value:(_vm.dialog.workflow),callback:function ($$v) {_vm.$set(_vm.dialog, "workflow", $$v)},expression:"dialog.workflow"}},[(_vm.hasWorkflow)?_c('div',[_c('div',[_vm._v("Status")]),_c('h3',{staticClass:"mb-4 blue-grey--text text--darken-3"},[_vm._v(_vm._s(_vm.workflow.name))]),_c('div',[_vm._v("Atualizado por:")]),_c('h3',{staticClass:"mb-4 blue-grey--text text--darken-3"},[_c('span',{style:({
            'font-style': _vm.hasOriginalUser ? 'italic' : 'normal',
          })},[_vm._v(" "+_vm._s(_vm.workflow.user)+" "),(_vm.hasOriginalUser)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,958939161)},[_c('span',[_vm._v(" Aprovação delegada por: "+_vm._s(_vm.workflow.user_original_name)+" ")])]):_vm._e()],1),_c('span',{staticClass:"font-weight-light"},[_vm._v(" - "+_vm._s(_vm.workflow.date.replaceAll("-", "/"))+" ")])]),(_vm.hasMessages)?_c('v-divider'):_vm._e(),(_vm.hasMessages)?_c('div',{staticClass:"mt-4"},_vm._l((_vm.workflow.messages),function(message,id){return _c('div',{key:message.id,staticClass:"mb-4 blue-grey--text text--darken-3",style:({
            'border-bottom':
              id !== _vm.workflow.messages.length - 1
                ? '1px solid rgba(0, 0, 0, 0.2)'
                : 'none',
          })},[_c('div',{staticClass:"grey lighten-5 mx-auto px-6 py-3 mb-3",staticStyle:{"font-size":"var(--font-size-p)","border-left":"2px solid #ccd7dd !important"}},[_c('span',{staticClass:"font-weight-bold blue-grey--text text--darken-4"},[_vm._v(" "+_vm._s(message.userName)+" ")]),_vm._v(" perguntou em "),_c('span',[_vm._v(_vm._s(message.updatedAt))]),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(message.message))])]),(message.answer.length > 0)?_vm._l((message.answer),function(answer){return _c('div',{key:answer.id,staticClass:"grey lighten-5 mx-auto ml-10 px-6 py-3 mb-3",staticStyle:{"font-size":"var(--font-size-p)","border-left":"2px solid #ccd7dd !important"}},[_c('span',{staticClass:"font-weight-bold blue-grey--text text--darken-4"},[_vm._v(" "+_vm._s(answer.userName)+" ")]),_vm._v(" perguntou em "),_c('span',[_vm._v(_vm._s(answer.updatedAt))]),_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(answer.message))])])}):_vm._e()],2)}),0):_vm._e()],1):_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"set-project-step"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex justify-content"},[(_vm.statusWorkflowEncerramento != '')?_c('div',[_c('label',[_vm._v(" Workflow Encerramento"),_c('br'),_c('v-btn',{staticClass:"mt-2 mr-6 btn-workflow-pendente",class:_vm.statusWorkflowClass(_vm.statusWorkflowEncerramento),attrs:{"depressed":"","dark":""}},[_vm._v(" "+_vm._s(_vm.statusWorkflowEncerramento)+" ")])],1)]):_vm._e(),_c('div',[_c('label',[_vm._v(" Etapa da Iniciativa "),_c('v-tooltip',{attrs:{"right":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"grey","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("O Encerramento só será habilitado quando todos os épicos estiverem concluídos e/ou cancelados")])]),_c('br'),_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",staticStyle:{"color":"#5D4EE8"},attrs:{"color":"#E2DEFF","depressed":"","dark":"","loading":_vm.loading}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selected)+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-down ")])],1)],1)])])]}}])},[_c('v-list',_vm._l((_vm.orderedItems),function(item,index){return _c('v-list-item',{key:index,staticClass:"menu-item",attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.validateStep(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-3"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" "),(item.active)?_c('v-icon',{staticStyle:{"color":"#5D4EE8"},attrs:{"right":""}},[_vm._v(" mdi-check ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.description)+" ")])])],1)}),1)],1),_c('vv-confimation-dialog',{attrs:{"title":_vm.confirmationModal.title,"message":_vm.confirmationModal.message,"boud-message":_vm.confirmationModal.boudMessage},on:{"confirmed":function($event){return _vm.setStep(_vm.confirmationModal.step)},"denied":function($event){_vm.confirmationModal.enable=false}},model:{value:(_vm.confirmationModal.enable),callback:function ($$v) {_vm.$set(_vm.confirmationModal, "enable", $$v)},expression:"confirmationModal.enable"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-list-item-content>
    <v-list-item-title class="mb-2">
      {{ value.id }} - {{ value.problem_title }}
    </v-list-item-title>

    <v-tooltip bottom max-width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-subtitle v-bind="attrs" v-on="on" class="wrap-text-3">
          <span class="font-weight-bold">Dor:</span>
          {{ value.problem_description }}
        </v-list-item-subtitle>
      </template>
      <div>
        <h4>Descrição da Dor</h4>
        <div>
          {{ value.problem_description }}
        </div>
      </div>
    </v-tooltip>
    
    <v-tooltip bottom max-width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-subtitle v-bind="attrs" v-on="on">
          <span class="font-weight-bold">Impacto:</span>
          {{ value.description_impact }}
        </v-list-item-subtitle>
      </template>
      <div>
        <h4>Descrição do Impacto</h4>
        <div>
          {{ value.description_impact }}
        </div>
      </div>
    </v-tooltip>
    <v-list-item-subtitle class="pt-1 text-caption">
      <span class="font-weight-bold">Área:</span> {{ value.responsible_area }}
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
  export default {
    name: "VvProblemCell",
    props: {
      value: {
        type: Object,
        required: true
      }
    }
  };
</script>

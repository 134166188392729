<template>
  <div class="tecnologicTree" v-scroll="fixFooter">
    <v-form ref="form">
      <v-row class="justify-content-between">
        <v-col cols="auto">
          <h2>{{ title }}</h2>
        </v-col>
        <v-col cols="auto" sm-align="end" v-if="!disable">
          <v-menu offset-y v-if="is_edit">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mr-4"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Opções
                <v-icon right dark>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showChangeVsmModal()">
                <v-list-item-title>Trocar VSM</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showChangeTribeModal()">
                <v-list-item-title>Migrar Tribo</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showChangeSquadModal()">
                <v-list-item-title>Migrar Squad</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            depressed
            color="primary"
            @click="addTree(is_edit)"
            v-if="is_edit"
          >
            {{ functionalButton }}
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="addTree(is_edit)"
            v-else
          >
            {{ functionalButton }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <label>VSM:</label>
          <template v-if="is_edit">
            <v-autocomplete
              clearable
              v-model="form.id"
              :items="tree_tecnologic"
              :rules="rules"
              error-message = ""
              no-data-text = "Nenhum VSM cadastrado"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              @change="integrationVsm()"
            ></v-autocomplete>
          </template>
          <template v-else>
            <v-autocomplete
              clearable
              v-model="form.id"
              :items="noVsm"
              :rules="rules"
              no-data-text = "Nenhum VSM cadastrado"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              @change="addJourney(true)"
            ></v-autocomplete>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="form.id != null">
        <v-col cols="12">
          <v-expansion-panels>
            <v-expansion-panel v-for="(journey, indexJourney) in form.journeys" :key="indexJourney" class="journey-panel" :class="journey.active ? 'active' : 'inactive'">
              <v-expansion-panel-header v-slot="{ open }">
                <div class="journey-header">
                  <v-row>
                    <v-col cols="6" sm="10">
                      <h2>Jornada</h2>
                      <p v-if="journey.error" class="text-error"><b class="text-error">Verifique os dados dessa jornada</b></p>
                      <p>Nome da Jornada</p>
                      <template v-if="!open">
                        <p class="journey-header-name">{{ journey.name }}</p>
                      </template>
                    </v-col>
                    <v-col cols="6" sm="2" class="journey-header-active" :class="journey.active ? 'active' : 'inactive'" v-if="!open">
                      {{ journey.active ? 'ATIVO' : 'INATIVO' }}
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="journey-header with-input">
                  <v-row>
                    <v-col cols="6" sm="10">
                      <v-text-field
                        v-model="journey.name"
                        :rules="rules"
                        :disabled="disable"
                        class="journey-header-name"></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="2">
                      <template v-if="is_edit && !journey.new">
                        <v-switch
                          color="secondary"
                          class="switch-br"
                          v-model="journey.active"
                          :label="journey.active ? 'ATIVO' : 'INATIVO'"
                          inset
                          :disabled="disableJourney || journey.disable"
                          @click="enableDisableItem(CONST_NIVEL_TREE_TECH_DISABLE.JORNEY, journey, indexJourney, '', '')"></v-switch>
                      </template>
                      <template v-else>
                        <v-btn icon color="#FF502D" @click="removeJourney(indexJourney)">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                    </v-col>
                  </v-row>
                </div>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(tribe, indexTribe) in journey.tribes" :key="indexTribe" class="tribe-panel" :class="tribe.active ? 'active' : 'inactive'">
                    <v-expansion-panel-header v-slot="{ open }">
                      <div class="tribe-header">
                        <h2><v-icon color="#DBDCDC" dense>mdi-dots-vertical</v-icon> Tribo</h2>
                        <p v-if="tribe.error" class="text-error"><b class="text-error">Verifique os dados dessa tribo</b></p>
                        <p>Nome da Tribo</p>
                        <template v-if="!open">
                          <p class="tribe-header-name">{{ tribe.name }}</p>
                        </template>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="tribe-header with-input">
                        <v-row>
                          <v-col cols="6" sm="10">
                            <v-text-field
                              v-model="tribe.name"
                              :disabled="disable"
                              :rules="rules"
                              class="tribe-header-name"></v-text-field>
                          </v-col>
                          <v-col cols="6" sm="2">
                            <template v-if="is_edit && !tribe.new">
                              <v-switch
                                color="secondary"
                                class="switch-br"
                                v-model="tribe.active"
                                :label="tribe.active ? 'Ativo' : 'Inativo'"
                                inset
                                :disabled="disable || disableFieldTribe(indexJourney) || tribe.disable"
                                @click="enableDisableItem(CONST_NIVEL_TREE_TECH_DISABLE.TRIBE, tribe, indexJourney, indexTribe, '')"
                              ></v-switch>
                            </template>
                            <template v-else>
                              <v-btn icon color="#FF502D" @click="removeTribe(indexJourney, indexTribe)">
                                <v-icon small>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                          </v-col>
                          <v-col cols="6" sm="10">
                            <label>GPM</label>
                            <v-autocomplete
                              clearable
                              v-model="tribe.gpm_id"
                              :items="gpmList"
                              :rules="rules"
                              no-data-text = "Nenhum possível GPM encontrado"
                              item-text="name"
                              item-value="id"
                              append-icon="mdi-chevron-down"
                              class="tribe-header-name"
                              :disabled="disable"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-expansion-panels class="squads-panel">
                              <v-expansion-panel>
                                <v-expansion-panel-header class="squads-header">
                                  Squads
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div class="squads-cards">
                                    <v-row>
                                      <template v-for="(squad, indexSquad) in tribe.squads">
                                        <v-col cols="12" sm="6" md="4" :key="indexSquad" v-if="typeof squad.edit == 'undefined' || squad.edit == false">
                                          <div class="squad-card">
                                            <div class="squad-card-header">
                                              <div>
                                                <p>Nome da Squad</p>
                                                <h2>{{ squad.name }}</h2>
                                              </div>
                                              <div>
                                                <template v-if="is_edit && !squad.new">
                                                  <v-switch
                                                    color="secondary"
                                                    class="switch-br"
                                                    v-model="squad.active"
                                                    :disabled="disable || disableFieldSquad(indexJourney, indexTribe) || squad.disable"
                                                    :label="squad.active ? 'Ativo' : 'Inativo'"
                                                    inset
                                                    @click="enableDisableItem(CONST_NIVEL_TREE_TECH_DISABLE.SQUAD, squad, indexJourney, indexTribe, indexSquad)"></v-switch>
                                                </template>
                                                <template v-else>
                                                  <v-btn icon color="#FF502D" @click="removeSquad(indexJourney, indexTribe, indexSquad)">
                                                    <v-icon small>mdi-delete</v-icon>
                                                  </v-btn>
                                                </template>
                                              </div>
                                            </div>
                                            <div class="squad-card-body">
                                              <p>PM/PO</p>
                                              <h2>
                                                <template v-for="listPmpo in noVsmNoGPM">
                                                  <template v-for="pmpo in squad.pmpo">
                                                    <template v-if="listPmpo.id == pmpo">{{listPmpo.name}}; </template>
                                                  </template>
                                                </template>
                                              </h2>
                                            </div>

                                            <v-menu offset-y v-if="is_edit">
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="btn-edit-squad" icon v-bind="attrs" v-on="on">
                                                  <v-icon color="#DBDCDC" dense>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </template>
                                              <v-list>
                                                <v-list-item @click="editSquad(indexJourney, indexTribe, indexSquad)">
                                                  <v-list-item-title>Editar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="showChangeSquadModal()">
                                                  <v-list-item-title>Migrar Squad</v-list-item-title>
                                                </v-list-item>
                                              </v-list>
                                            </v-menu>
                                          </div>
                                        </v-col>
                                        <v-col cols="12" :key="indexSquad" v-else>
                                          <div class="squad-card edition">
                                            <v-row>
                                              <v-col cols="12">
                                                <label>Nome da Squad</label>
                                                <v-autocomplete
                                                  clearable
                                                  required
                                                  v-model="squad.squad_key"
                                                  :disabled="disable"
                                                  :items="squads"
                                                  :rules="rules"
                                                  no-data-text="Nenhuma Squad possível"
                                                  item-text="name"
                                                  item-value="id"
                                                  append-icon="mdi-chevron-down"
                                                  class="input"
                                                ></v-autocomplete>
                                              </v-col>
                                              <v-col cols="12">
                                                <label>PM/PO</label>
                                                <v-autocomplete
                                                  multiple
                                                  clearable
                                                  :items="noVsmNoGPM"
                                                  :disabled="disable"
                                                  v-model="squad.pmpo"
                                                  error-message = ""
                                                  no-data-text = "Não há PM/PO para os parâmetros requeridos"
                                                  item-text="name"
                                                  item-value="id"
                                                  append-icon="mdi-chevron-down"
                                                  class="input"
                                                ></v-autocomplete>
                                              </v-col>
                                            </v-row>
                                          </div>
                                        </v-col>
                                      </template>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="4">
                                        <v-btn
                                          class="newSquad"
                                          @click="addSquad(indexJourney, indexTribe)">
                                          NOVA SQUAD
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-btn
                  elevation="0"
                  class="newTribe"
                  @click="addTribe(indexJourney)"
                  v-if="!disable">
                  NOVA TRIBO
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="newJourney">
            <v-btn
              color="primary"
              outlined
              @click="addJourney()"
              v-if="form.journeys.length > 0 && !disable">
              NOVA JORNADA
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="form.id != null">
        <v-col cols="12" class="text-end">
          <template v-if="!disable">
            <v-btn
                color="secondary"
                elevation="0"
                outlined
                style="margin-right: 16px"
                @click="closeOrClear('clear')">
              Limpar
            </v-btn>
            <v-btn
                color="primary"
                elevation="0"
                @click="saveTree()">
              Salvar
            </v-btn>
          </template>
        </v-col>
      </v-row>
      <div class="footerFixed" v-if="form.id != null" :class="footerFixed ? 'show' : ''">
        <v-container>
          <v-row>
            <v-col cols="12" class="text-end">
              <template v-if="!disable">
                <v-btn
                    color="secondary"
                    elevation="0"
                    outlined
                    style="margin-right: 16px"
                    @click="closeOrClear('clear')">
                  Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    elevation="0"
                    class="save-btn-footer-fixed"
                    @click="saveTree()">
                  Salvar
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-dialog v-model="dialogMessage" hide-overlay width="400">
        <v-alert :type=typeMessage style="margin: 0">
          {{ textMessage }}
        </v-alert>
      </v-dialog>

      <template>
        <v-row justify="center">
          <v-dialog
            v-model="modalCloseOrClear.status"
            persistent
            width="500"
          >
            <v-card>
              <v-card-title class="text-h5">
                {{ modalCloseOrClear.title }}
              </v-card-title>
              <v-card-text>{{ modalCloseOrClear.text }}</v-card-text>
              <v-card-actions style="border-top: 0.5px solid #A1A1A1">
                <v-spacer></v-spacer>
                <v-btn
                  color="#A1A1A1"
                  text
                  @click="modalCloseOrClear.status = false"
                >
                  Não
                </v-btn>
                <v-btn
                  color="primary"
                  @click="modalCloseOrClear.status = false; resetFields()"
                >
                  Sim
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-form>

    <v-dialog
        v-model="changeVsmDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Trocar VSM de uma Árvore</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  :items="tree_tecnologic"
                  item-text="name"
                  item-value="id"
                  :label="dialogChangeVsm.label.currentVsmId"
                  v-model="dialogChangeVsm.form.currentVsmId"
                  no-data-text = "Sem dados"
                  append-icon="mdi-chevron-down"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  :items="noVsm"
                  item-text="name"
                  item-value="id"
                  :label="dialogChangeVsm.label.newVsmId"
                  v-model="dialogChangeVsm.form.newVsmId"
                  no-data-text = "Sem dados"
                  append-icon="mdi-chevron-down"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="changeVsmDialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveChangeVsm()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="changeTribeDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Trocar Tribo de uma Árvore</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                    :items="dialogChangeTribe.options.tribes"
                    item-text="tribe_name"
                    item-value="tribe_id"
                    append-icon="mdi-chevron-down"
                    :label="dialogChangeTribe.label.tribeId"
                    v-model="dialogChangeTribe.form.tribeId"
                    @change="changeCurrentJourney()"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    :label="dialogChangeTribe.label.currentJourneyId"
                    v-model="dialogChangeTribe.label.selectedTribeJourney"
                    :disabled="true"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                    :items="dialogChangeTribe.options.journeys"
                    item-text="journey_name"
                    item-value="journey_id"
                    append-icon="mdi-chevron-down"
                    :label="dialogChangeTribe.label.newJourneyId"
                    v-model="dialogChangeTribe.form.newJourneyId"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip left max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="ml-3 d-none d-sm-flex"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>
              Ao migrar uma tribo, será migrada também toda a estrutura abaixo desta (GPM, Squads e PMs/POs)
            </span>
          </v-tooltip>

          <v-btn
              color="blue darken-1"
              text
              @click="changeTribeDialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveChangeTribe()"
          >
            Migrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="changeSquadDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Trocar Squad de uma Árvore</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                    :items="dialogChangeSquad.options.squads"
                    item-text="name"
                    item-value="id"
                    append-icon="mdi-chevron-down"
                    :label="dialogChangeSquad.label.squadId"
                    v-model="dialogChangeSquad.form.squadId"
                    @change="changeCurrentTribe()"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    :label="dialogChangeSquad.label.currentTribeId"
                    v-model="dialogChangeSquad.label.selectedSquadTribe"
                    :disabled="true"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                    :items="dialogChangeSquad.options.tribes"
                    item-text="tribe_name"
                    item-value="tribe_id"
                    append-icon="mdi-chevron-down"
                    :label="dialogChangeSquad.label.newTribeId"
                    v-model="dialogChangeSquad.form.newTribeId"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-tooltip left max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  class="ml-3 d-none d-sm-flex"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>
              Ao migrar uma squad, será migrada também toda a estrutura abaixo desta (PMs/POs)
            </span>
          </v-tooltip>

          <v-btn
              color="blue darken-1"
              text
              @click="changeSquadDialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveChangeSquad()"
          >
            Migrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <tree-tech-disabled-modal :data="desableDataModel" :show="disableShowModal"></tree-tech-disabled-modal>

  </div>
</template>

<script>
import TreeTechDisabledModal from "@/components/modals/TreeTechDisabledModal.vue";

export default {
  components: {
    TreeTechDisabledModal
  },
  data() {
    return {
      footerFixed: false,
      desableDataModel: {},
      disableShowModal: false,
      disableIndex: {
        journey: null,
        tribe: null,
        squad: null,
      },
      disableNivel: null,
      is_edit: true,
      disable: true,
      disableJourney: true,
      dialogMessage: false,
      changeVsmDialog: false,
      changeTribeDialog: false,
      changeSquadDialog: false,
      modalCloseOrClear: {
        status: false,
        title: '',
        text: ''
      },
      typeMessage: "success",
      textMessage: "",
      functionalButton: "Novo VSM",
      title: "Listagem/Edição de Árvore de Técnologia",
      tree_tecnologic: [],
      users: null,
      gpmList: null,
      noVsm: null,
      noVsmNoGPM: null,
      squadsHasEpics: null,
      dialogChangeVsm: {
        form: {
          currentVsmId: 0,
          newVsmId: 0
        },
        label: {
          currentVsmId: "VSM Atual",
          newVsmId: "Novo VSM"
        }
      },
      dialogChangeTribe: {
        form: {
          tribeId: 0,
          newJourneyId: 0
        },
        label: {
          tribeId: "Tribo a ser Migrada",
          currentJourneyId: "Jornada Atual",
          newJourneyId: "Nova Jornada",
          selectedTribeJourney: "Selecione uma Tribo",
        },
        options: {
          tribes: [],
          journeys: [],
        }
      },
      dialogChangeSquad: {
        form: {
          squadId: 0,
          newTribeId: 0
        },
        label: {
          squadId: "Squad a ser migrado",
          currentTribeId: "Tribo Atual",
          newTribeId: "Nova Tribo",
          selectedSquadTribe: "Selecione um Squad",
        },
        options: {
          squads: [],
          tribes: [],
        }
      },
      form: {
        id: null,
        journeys: [],
        journeysOriginal: []
      },
      rules: [
        v => !!v || 'Este campo é obrigatório',
      ]
    }
  },
  created() {
    this.initialize();
  },
  directives: {
    scroll: {
      inserted: function(el, binding) {
        let f = function(evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f);
          }
        };
        window.addEventListener('scroll', f);
      }
    }
  },
  methods: {
    fixFooter: function() {
      let endOfPage = document.body.scrollHeight > window.innerHeight ? document.body.scrollHeight - window.innerHeight : window.innerHeight
      this.footerFixed = window.scrollY < (endOfPage - 133) ? true : false;
    },
    getTree(getSguads = null) {
      this.axios.get("tree/tecnologic").then((response) => {
        this.tree_tecnologic = response.data;
      })
      .finally(() => {
        if(getSguads) {
          this.getSquads();
        }
      })
    },
    getUsers() {
      this.axios.get("user").then((response) => {
        this.users = response.data
      })
    },
    getNoVSM() {
      this.axios.get("user/noVsm").then((response) => {
        this.noVsm = response.data
      })
    },
    getNoVSMNoGPM() {
      this.axios.get("user/noVsmNoGpm").then((response) => {
        this.noVsmNoGPM = response.data
      })
    },
    getSquads() {
      this.axios.get("tree/tecnologic/squads").then((response) => {
        this.squads = response.data.squads
        Object.keys(this.tree_tecnologic).forEach((keyTree) => {
          let journeys = this.tree_tecnologic[keyTree].journeys
          Object.keys(journeys).forEach((keyJourney) => {
            let tribes = journeys[keyJourney].tribes
            Object.keys(tribes).forEach((keyTribe) => {
              let squads = tribes[keyTribe].squads
              Object.keys(squads).forEach((keySquad) => {
                Object.keys(this.squads).forEach((keySquadList) => {
                  if (this.squads[keySquadList].id == squads[keySquad].squad_key) {
                    this.$set(squads[keySquad], 'name', this.squads[keySquadList].name)
                  }
                })
              })
              squads.sort(function(a, b) {
                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
              });
            })
          })
        })
      })
    },
    getTribes() {
      this.axios.get("tree/tecnologic/tribes").then((response) => {
        this.dialogChangeTribe.options.tribes = response.data;
        this.dialogChangeSquad.options.tribes = response.data;
      })
    },
    getJourneys() {
      this.axios.get("tree/tecnologic/journeys").then((response) => {
        this.dialogChangeTribe.options.journeys = response.data;
      })
    },
    getSquadsInTree() {
      this.axios.get("tree/tecnologic/squads-in-trees").then((response) => {
        this.dialogChangeSquad.options.squads = response.data;
        this.dialogChangeSquad.options.squads.sort((a, b) => (a.name > b.name) ? 1 : -1)
      })
      .finally(() => {
        this.$emit("loading", false);
      })
    },
    integrationVsm() {
      this.form.journeys = [];
      Object.keys(this.tree_tecnologic).forEach((key) => {
        if(this.tree_tecnologic[key].id == this.form.id) {
          this.form.journeys = this.tree_tecnologic[key].journeys
          this.form.journeysOriginal = this.deepCopy(this.tree_tecnologic[key].journeys);
        }
      })
      this.disableJourneys()
    },
    async initialize(){
      this.$emit("loading", true);
      this.disableFields();
      this.getUsers();
      this.getTree(true);
      this.getNoVSM();
      this.getNoVSMNoGPM();
      this.disableJourneys();
      this.getTribes();
      this.getJourneys();
      this.getSquadsInTree();
      this.getNoVsmNoPmpo();
      this.getSquadHasEpic();
    },
    getSquadHasEpic() {
      this.axios.get("tree/tecnologic/squadshasepics").then((response) => {
        this.squadsHasEpics = response.data;
      });
    },
    getNoVsmNoPmpo() {
      this.$emit("loading", true);
      this.axios.get("user/noVsmNoPmpo/").then(response => {
        this.gpmList = response.data;
      })
      .catch(error => {
        this.disable = false;
        this.typeMessage = "warning"
        this.dialogMessage = true
        if (typeof error.response.data.message != 'undefined') {
          this.textMessage = error.response.data.message;
        } else {
          this.textMessage = 'Verifique todos os campos!';
        }
      }).finally(() => {
        this.$emit("loading", false);
      })
    },
    addTree(status) {
      if (!status) {
        this.is_edit = true
        this.title = "Listagem/Edição de Árvore de Técnologia"
        this.functionalButton = "Novo VSM"
        this.closeOrClear('close')
      } else {
        this.is_edit = false
        this.title = "Novo VSM"
        this.functionalButton = "Cancelar"
        this.resetFields()
      }
    },
    closeOrClear(status = null) {
      this.modalCloseOrClear.status = true

      if (status == 'close') {
        this.modalCloseOrClear.title = "Sair da criação"
        this.modalCloseOrClear.text = "Você realmente deseja sair da criação da árvore?"
      } else if (status == 'clear') {
        this.modalCloseOrClear.title = "Limpar dados preenchidos"
        this.modalCloseOrClear.text = "Você realmente deseja limpar os dados preenchidos?"
      } else {
        this.modalCloseOrClear.status = false
      }
    },
    addJourney(changeVsm = false) {
      if(changeVsm){
        if(this.form.journeys.length === 0){
          this.addJourneyForm();
        }
      }else{
        this.addJourneyForm();
      }
      this.disableJourneys()
    },
    addJourneyForm(){
      this.form.journeys.push({
        active: true,
        id: 0,
        name: '',
        new: true,
        disable: false,
        tribes: [
          {
            id: 0,
            name: '',
            active: true,
            gpm_id: 0,
            new: true,
            disable: false,
            squads: []
          }
        ]
      })
    },
    removeJourney(index) {
      if (!this.is_edit) {
        if (this.form.journeys.length > 1) {
          this.form.journeys.splice(index, 1)
        } else {
          this.dialogMessage = true
          this.typeMessage = "warning"
          this.textMessage = "Um VSM deve conter ao menos uma jornada"
        }
      } else {
        let journeysActive = []
        Object.keys(this.form.journeys).forEach((key) => {
          if (this.form.journeys[key].active == true) {
            journeysActive.push(key)
          }
        })
        if (journeysActive.length > 1) {
          this.form.journeys.splice(index, 1)
        } else {
          this.dialogMessage = true
          this.typeMessage = "warning"
          this.textMessage = "Um VSM deve conter ao menos uma jornada"
        }
      }

      this.disableJourneys()
    },
    addTribe(index) {
      this.form.journeys[index].tribes.push({
        id: 0,
        name: "",
        active: true,
        gpm_id: 0,
        new: true,
        disable: false,
        squads: []
      })
    },
    removeTribe(indexJourney, indexTribe) {
      if (!this.is_edit) {
        if (this.form.journeys[indexJourney].tribes.length > 1) {
          this.form.journeys[indexJourney].tribes.splice(indexTribe, 1)
        } else {
          this.dialogMessage = true
          this.typeMessage = "warning"
          this.textMessage = "Uma jornada deve conter ao menos uma tribo"
        }
      } else {
        let tribesActive = []
        Object.keys(this.form.journeys[indexJourney].tribes).forEach((key) => {
          if (this.form.journeys[indexJourney].tribes[key].active == true) {
            tribesActive.push(key)
          }
        })
        if (tribesActive.length > 1) {
          this.form.journeys[indexJourney].tribes.splice(indexTribe, 1)
        } else {
          this.dialogMessage = true
          this.typeMessage = "warning"
          this.textMessage = "Uma jornada deve conter ao menos uma tribo"
        }
      }
    },
    addSquad(indexJourney, indexTribe) {
      this.form.journeys[indexJourney].tribes[indexTribe].squads.push({
        name: "",
        squad_key: 0,
        active: true,
        new: true,
        edit: true
      })
      this.disableFieldSquad(indexJourney, indexTribe) 
    },
    removeSquad(indexJourney, indexTribe, indexSquad) {
      if (!this.is_edit) {
        if (this.form.journeys[indexJourney].tribes[indexTribe].squads.length > 1) {
          this.form.journeys[indexJourney].tribes[indexTribe].squads.splice(indexSquad, 1)
        } else {
          this.dialogMessage = true
          this.typeMessage = "warning"
          this.textMessage = "Uma tribo deve conter ao menos uma squad"
        }
      } else {
        let squadsActive = []
        Object.keys(this.form.journeys[indexJourney].tribes[indexTribe].squads).forEach((key) => {
          if (this.form.journeys[indexJourney].tribes[indexTribe].squads[key].active == true) {
            squadsActive.push(key)
          }
        })
        if (squadsActive.length > 1) {
          this.form.journeys[indexJourney].tribes[indexTribe].squads.splice(indexSquad, 1)
        } else {
          this.dialogMessage = true
          this.typeMessage = "warning"
          this.textMessage = "Uma tribo deve conter ao menos uma squad"
        }
      }
    },
    resetFields() {
      this.form = {
        id: null,
        journeys: []
      }

      this.disableIndex = {
        journey: null,
            tribe: null,
            squad: null,
      };
      this.disableNivel = null;

      this.disableFields()
      this.getUsers()
      this.getTree(true)
      this.getNoVSM()
      this.getNoVSMNoGPM()
    },

    saveTree() {
      this.disable = true;
      this.showErrorsTree();
      if (this.$refs.form.validate()) {
        this.addNameSquads();
        this.$emit("loading", true);
        if (this.is_edit) {
          this.axios.put("tree/tecnologic", this.form).then(response => {
            this.textMessage = response.data.message
            this.typeMessage = "success"
            this.dialogMessage = true
            this.resetFields()
          })
          .catch(error => {
            this.disable = false;
            this.typeMessage = "warning"
            this.dialogMessage = true
            this.textMessage = typeof error.response.data.message != 'undefined' ? error.response.data.message : 'Preencha todos os campos'
          }).finally(() => {
            this.$emit("loading", false);
          })
        } else {
          this.axios.post("tree/tecnologic", this.form).then(response => {
            this.textMessage = response.data.message
            this.typeMessage = "success"
            this.dialogMessage = true
            this.resetFields()
          })
          .catch(error => {
            this.disable = false;
            this.typeMessage = "warning"
            this.dialogMessage = true
            this.textMessage = typeof error.response.data.message != 'undefined' ? error.response.data.message : 'Preencha todos os campos'
          }).finally(() => {
            this.$emit("loading", false);
          })
        }
      } else {
        this.disable = false;
      }
    },
    disableFields() {
      this.disable = true
      this.disableJourney = true
      if (this.$store.getters.type.indexOf(this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO) != -1 ||
          this.$store.getters.type.indexOf(this.CONST_USER_TYPE.USUARIO_MASTER) != -1) {
        this.disable = false
        this.disableJourney = false
      }
    },
    showErrorsTree() {
      this.form.journeys.forEach((journey) => {
        journey.tribes.forEach((tribe) => {
          if (tribe.name == '') {
            journey.error = true;
          }
          tribe.squads.forEach((squad) => {
            if (squad.name == '') {
              tribe.error = true;
              journey.error = true;
            }
          });
        });
      });
    },
    setIndexDisabled(nivel, journeyIndex = null, tribeIndex = null, squadIndex = null) {
      this.disableNivel = nivel;
      this.disableIndex.journey = journeyIndex;
      this.disableIndex.tribe = tribeIndex;
      this.disableIndex.squad = squadIndex;
    },
    enableFields() {
      if (this.disableNivel == this.CONST_NIVEL_TREE_TECH_DISABLE.JORNEY) {
        this.form.journeys[this.disableIndex.journey].active = true;
        this.disableJourneys();
      } else if (this.disableNivel == this.CONST_NIVEL_TREE_TECH_DISABLE.TRIBE) {
        this.form.journeys[this.disableIndex.journey].tribes[this.disableIndex.tribe].active = true;
        this.disableFieldTribe(this.disableIndex.journey);
      } else if (this.disableNivel == this.CONST_NIVEL_TREE_TECH_DISABLE.SQUAD) {
        this.form.journeys[this.disableIndex.journey].tribes[this.disableIndex.tribe].squads[this.disableIndex.squad].active = true;
        this.disableFieldSquad(this.disableIndex.journey, this.disableIndex.tribe);
      }
    },
    disableJourneys(index = null) {
      let journeysActive = []
      Object.keys(this.form.journeys).forEach((key) => {
        if (this.form.journeys[key].active == true) {
          journeysActive.push(key)
        }
      });
      if (journeysActive.length == 1) {
        this.form.journeys[journeysActive[0]].disable = true;
      } else {
        Object.keys(this.form.journeys).forEach((key) => {
          this.form.journeys[key].disable = false
        })
      }
      if (index != null) {
        let atualJourney = this.form.journeys[index]
        if (atualJourney.tribes.length == 1 && atualJourney.tribes[0].active == false) {
          this.form.journeys[index].tribes[0].active = true
        }
        if (this.form.journeys[index].active == true) {
          Object.keys(this.form.journeys[index].tribes).forEach((key) => {
            this.form.journeys[index].tribes[key].disable = false
            Object.keys(this.form.journeys[index].tribes[key].squads).forEach((key2) => {
              this.form.journeys[index].tribes[key].squads[key2].disable = false
              if (this.form.journeys[index].tribes[key].squads.length === 1) {
                this.form.journeys[index].tribes[key].squads[key2].active = true
              }
            })
          })
        }
      }
    },
    disableFieldTribe(index) {
      let tribesActive = []
      Object.keys(this.form.journeys[index].tribes).forEach((key) => {
        if (this.form.journeys[index].tribes[key].active == true) {
          tribesActive.push(key)
        }
      })
      if (tribesActive.length == 1) {
        this.form.journeys[index].tribes[tribesActive[0]].disable = true;
        
      } else {
        Object.keys(this.form.journeys[index].tribes).forEach((key) => {
          if (this.form.journeys[index].active == true) {
            this.form.journeys[index].tribes[key].disable = false
          }
        })
      }
      if (this.form.journeys[index].tribes.length <= 1) {
        return true
      }
      return false
    },
    disableFieldSquad(indexJourney, indexTribe) {
      let squadsActive = [];

      Object.keys(this.form.journeys[indexJourney].tribes[indexTribe].squads).forEach((key) => {
        if (this.form.journeys[indexJourney].tribes[indexTribe].squads[key].active == true) {
          squadsActive.push(key)
        }
      });
      if (squadsActive.length == 1) {
        this.form.journeys[indexJourney].tribes[indexTribe].squads[squadsActive[0]].disable = true;

      } else {
        Object.keys(this.form.journeys[indexJourney].tribes[indexTribe].squads).forEach((key) => {
          if (this.form.journeys[indexJourney].tribes[indexTribe].squads[key].active == true) {
            this.form.journeys[indexJourney].tribes[indexTribe].squads[key].disable = false
          }
        })
      }
      if (this.form.journeys[indexJourney].tribes[indexTribe].squads.length <= 1) {
        return true
      }
      return false
    },
    showChangeVsmModal() {
      this.initialize().finally(() => {
        setTimeout(() => {
          this.changeVsmDialog = !this.changeVsmDialog;
        }, 1000)
      });
    },
    showChangeTribeModal() {
      this.initialize().finally(() => {
        setTimeout(() => {
          this.changeTribeDialog = !this.changeTribeDialog;
        }, 1000)
      });
    },
    showChangeSquadModal() {
      this.initialize().finally(() => {
        setTimeout(() => {
          this.changeSquadDialog = !this.changeSquadDialog;
        }, 1000)
      });
    },
    saveChangeVsm() {
      this.$emit("loading", true);
      this.axios.put("tree/tecnologic/changeVsm", this.dialogChangeVsm.form).then(response => {
        this.resetFields()
        this.textMessage = response.data.message
        this.typeMessage = "success"
        this.dialogMessage = true
        this.dialogChangeVsm.form = {
          currentVsmId: 0,
          newVsmId: 0
        }
        this.changeVsmDialog = false
      }).catch(error => {
        this.typeMessage = "warning"
        this.dialogMessage = true
        this.textMessage = error.response.data.message
      }).finally(() => {
        this.$emit("loading", false);
      })
    },
    saveChangeTribe() {
      this.axios.put("tree/tecnologic/tribes", this.dialogChangeTribe.form).then(response => {
        this.textMessage = response.data.message
        this.typeMessage = "success"
        this.dialogMessage = true
        this.resetFields();
        this.getTribes();
        this.getJourneys();
        this.changeTribeDialog = false;
        this.dialogChangeTribe.form.tribeId = 0;
        this.dialogChangeTribe.form.newJourneyId = 0;
      })
          .catch(error => {
            this.typeMessage = "warning"
            this.dialogMessage = true
            this.textMessage = error.response.data.message
          })
    },
    saveChangeSquad() {
      this.axios.put("tree/tecnologic/squads-in-trees", this.dialogChangeSquad.form).then(response => {
        this.textMessage = response.data.message
        this.typeMessage = "success"
        this.dialogMessage = true
        this.resetFields();
        this.getSquadsInTree();
        this.changeSquadDialog = false;
        this.dialogChangeSquad.form.squadId = 0;
        this.dialogChangeSquad.form.newTribeId = 0;
      })
        .catch(error => {
          this.typeMessage = "warning"
          this.dialogMessage = true
          this.textMessage = error.response.data.message
        })
    },
    addNameSquads() {
      this.form.journeys.forEach(journey => {
        journey.tribes.forEach(tribe => {
          tribe.squads.forEach(squad => {
            squad.name = this.squads.filter((value) => {
              return value.id === squad.squad_key;
            });
            squad.name = (typeof squad.name[0] !== 'undefined') ? squad.name[0].name : '';
          });
        });
      });
    },
    changeCurrentJourney() {
      let idSelected = this.dialogChangeTribe.form.tribeId;
      let journeySelected = "";
      this.dialogChangeTribe.options.tribes.forEach(function(item){
        if (item.tribe_id == idSelected) {
          journeySelected = item.journey_name;
          return;
        }
      });
      this.dialogChangeTribe.label.selectedTribeJourney = journeySelected;
    },
    changeCurrentTribe() {
      let idSelected = this.dialogChangeSquad.form.squadId;
      let tribeSelected = "";
      this.dialogChangeSquad.options.squads.forEach(function(item){
        if (item.id == idSelected) {
          tribeSelected = item.tribe_name;
          return;
        }
      });
      this.dialogChangeSquad.label.selectedSquadTribe = tribeSelected;
    },
    editSquad(journeyIndex, tribeIndex, squadIndex, save = false) {
      let squad = this.form.journeys[journeyIndex].tribes[tribeIndex].squads[squadIndex]
      if (save) {
        this.$set(squad, 'edit', false)
      } else {
        this.$set(squad, 'edit', true)
      }
    },
    enableDisableItem (nivelDisable, entity, indexJourney, indexTribe, indexSquad) {
      if (nivelDisable === this.CONST_NIVEL_TREE_TECH_DISABLE.JORNEY) {
        let journeysActivesCount = this.countJourneysAtives();
        if(journeysActivesCount.length < 1 && this.form.journeys[indexJourney].disable) {
          this.disableJourneys(indexJourney);
          this.form.journeys[0].active = true;
          this.form.journeys[0].disable = true;
          
          this.typeMessage = "warning";
          this.dialogMessage = true;
          this.textMessage = 'Esta é a última jornada existente na árvore. Você não pode inativa-la!';
          return true;
        } else {
          this.disableJourneys(indexJourney);
          this.showInativateModal(this.CONST_NIVEL_TREE_TECH_DISABLE.JORNEY, entity); 
          this.setIndexDisabled(this.CONST_NIVEL_TREE_TECH_DISABLE.JORNEY, indexJourney);
          return false;
        }
        
      }
      if (nivelDisable === this.CONST_NIVEL_TREE_TECH_DISABLE.TRIBE) {
        let tribesActivesCount = this.countTribesActives(indexJourney);
        if (tribesActivesCount.length < 1 && this.form.journeys[indexJourney].tribes[indexTribe].disable) {
          this.disableFieldTribe(indexJourney);
          this.typeMessage = "warning";
          this.dialogMessage = true;
          this.textMessage = 'Esta é a última tribo existente na árvore. Você não pode inativa-la!';
          return true;
        } else {
          this.disableFieldTribe(indexJourney);
          this.showInativateModal(nivelDisable, entity);
          this.setIndexDisabled(this.CONST_NIVEL_TREE_TECH_DISABLE.TRIBE, indexJourney, indexTribe, indexSquad);
          return false;
        }

      }

      if (nivelDisable === this.CONST_NIVEL_TREE_TECH_DISABLE.SQUAD) {
        let squadsActivesCount = this.countSquadsActives(indexJourney, indexTribe);
        if(squadsActivesCount.length < 1 && this.form.journeys[indexJourney].tribes[indexTribe].squads[indexSquad].disable) {
          this.disableFieldSquad(indexJourney, indexTribe);
          this.typeMessage = "warning";
          this.dialogMessage = true;
          this.textMessage = 'Esta é a última squad existente na árvore. Você não pode inativa-la!';
          return true;
        } else {
          let existEpic = this.squadsHasEpics.filter(function(item) {
            if (item.squad_key == entity.squad_key) {
              return item.squad_key
            }
          })
          if (existEpic.length > 0) {
            this.disableFieldSquad(indexJourney, indexTribe);
            this.showInativateModal(nivelDisable, entity);
            this.setIndexDisabled(this.CONST_NIVEL_TREE_TECH_DISABLE.SQUAD, indexJourney, indexTribe, indexSquad);
          } else {
            this.disableFieldSquad(indexJourney, indexTribe);
            this.setIndexDisabled(this.CONST_NIVEL_TREE_TECH_DISABLE.SQUAD, indexJourney, indexTribe, indexSquad);
          }
          
          return false;
        }
      }
      
    },
    showInativateModal(nivelDisable, entity)
    {
      if (!entity.active && this.getOriginalActiveEntity(nivelDisable, entity.id)) {
        let modalData = {
          'nivel': nivelDisable,
          'entity': entity,
          'form': this.form,
          'options': this.dialogChangeSquad.options
        };
        this.desableDataModel = modalData;
        this.disableShowModal = true;
      } else {
        this.disableShowModal = false;
      }
    },
    getOriginalActiveEntity(nivelDisable, entityId) {
      let originalValueActiveEntity = true;
      for (let indexJourney = 0;indexJourney < this.form.journeysOriginal.length; indexJourney++) {
        if (nivelDisable === this.CONST_NIVEL_TREE_TECH_DISABLE.JORNEY) {
          if (this.form.journeysOriginal[indexJourney].id === entityId &&
              !this.form.journeysOriginal[indexJourney].active
          ) {
            return false;
          }
        } else {
          for (let indexTribe = 0; indexTribe < this.form.journeysOriginal[indexJourney].tribes.length; indexTribe++) {
            if (nivelDisable === this.CONST_NIVEL_TREE_TECH_DISABLE.TRIBE) {
              if (this.form.journeysOriginal[indexJourney].tribes[indexTribe].id === entityId &&
                  !this.form.journeysOriginal[indexJourney].tribes[indexTribe].active
              ) {
                return false;
              }
            } else {
              for (let indexSquad = 0; indexSquad < this.form.journeysOriginal[indexJourney].tribes[indexTribe].squads.length; indexSquad++) {
                if (nivelDisable === this.CONST_NIVEL_TREE_TECH_DISABLE.SQUAD) {
                  if (this.form.journeysOriginal[indexJourney].tribes[indexTribe].squads[indexSquad].id === entityId &&
                      !this.form.journeysOriginal[indexJourney].tribes[indexTribe].squads[indexSquad].active
                  ) {
                    return false;
                  }
                } else {
                  console.error('Nenhum nível especificado');
                  return false;
                }
              }
            }
          }
        }
      }


      return originalValueActiveEntity;
    },
    closeInativateModal() {
      this.disableShowModal = false;
    },
    countJourneysAtives() {
      let journeysActive = [];
      Object.keys(this.form.journeys).forEach((key) => {
        if (this.form.journeys[key].active == true && this.form.journeys[key].disable == false) {
          journeysActive.push(key)
        }
      });
      return journeysActive;
    },
    countTribesActives(index) {
      let tribesActive = []
      Object.keys(this.form.journeys[index].tribes).forEach((key) => {
        if (this.form.journeys[index].tribes[key].active == true && this.form.journeys[index].tribes[key].disable == false) {
          tribesActive.push(key)
        }
      })
      return tribesActive;
    },
    countSquadsActives(indexJourney, indexTribe) {
      let squadsActive = [];

      Object.keys(this.form.journeys[indexJourney].tribes[indexTribe].squads).forEach((key) => {
        if (this.form.journeys[indexJourney].tribes[indexTribe].squads[key].active == true && 
            this.form.journeys[indexJourney].tribes[indexTribe].squads[key].disable == false) {
          squadsActive.push(key)
        }
      });
      return squadsActive;
    }

    
  }
}
</script>

<style lang="scss" scoped>
  .v-list-item {
    .v-list-item__title {
      padding: 10.4px 10px;
      text-align: center;
    }
  }

  .tecnologicTree {
    padding: 8px 14px;

    .journey-panel {
      background-color: #FCFCFC !important;
      padding: 0px;
      margin-top: 24px;

      &::after {
        border-color: rgba(0,0,0,0) !important;
      }

      &:first-child {
        margin-top: 0px;
      }

      &.inactive {
        background-color: #DBDCDC !important;
      }

      .v-expansion-panel-header {
        padding: 24px 16px;
      }

      .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
          padding: 0px 16px;
        }
      }

      .newTribe {
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        color: #5D4EE8;
        border: 1px solid #E2DEFF;
        border-radius: 6px;
        padding: 14px 0;
        background-color: #ffffff;
        margin-top: 24px;
      }

      .journey-header {
        h2 {
          font-size: 16px;
          font-weight: 500;
          color: #202121;
          margin-bottom: 24px;
        }

        p {
          font-size: 10px;
          font-weight: 400;
          color: #5D5D5D;
          margin-bottom: 16px;
        }

        .journey-header-name {
          font-size: 14px;
          font-weight: 400;
          color: #202121;
        }

        .journey-header-active {
          font-size: 14px;
          font-weight: 600;
          padding-top: 53.1px;

          &.active {
            color: #00D3C3;
          }

          &.inactive {
            color: #FF502D;
          }
        }

        &.with-input {
          margin-top: -50px;
          margin-left: -8px;

          @media (max-width: 768px) {
            margin-left: 16px;
          }
        }
      }

      .tribe-panel {
        padding: 0px;
        border: 1px solid #E2DEFF;
        margin-top: 16px;

        &:first-child {
          margin-top: 0px;
        }

        &::after {
          border-color: rgba(0,0,0,0) !important;
        }

        .v-expansion-panel-header {
          padding: 24px 16px;
        }

        .v-expansion-panel-content {
          .v-expansion-panel-content__wrap {
            padding: 0px 16px;
          }
        }

        .tribe-header {
          h2 {
            font-size: 16px;
            font-weight: 500;
            color: #202121;
            margin-bottom: 24px;

            i {
              margin-right: 9px;
            }
          }

          .col {
            padding: 0px 16px;
          }

          p {
            font-size: 10px;
            font-weight: 400;
            color: #5D5D5D;
            margin-bottom: 16px;
          }

          .tribe-header-name {
            font-size: 14px;
            font-weight: 400;
            color: #202121;
          }

          .tribe-header-active {
            font-size: 14px;
            font-weight: 600;
            padding-top: 53.1px;

            &.active {
              color: #00D3C3;
            }

            &.inactive {
              color: #FF502D;
            }
          }

          &.with-input {
            margin-top: -38px;
            margin-left: -12px;

            @media (max-width: 768px) {
              margin-left: 12px;
            }
          }

          .squads-panel {
            padding-bottom: 34px;

            .squads-header {
              font-size: 14px;
              font-weight: 500;
              color: #6D6E70;
              width: 191px;
              padding: 24px 16px 24px 0px;
            }

            .squads-cards {
              margin: 13px -24px 0;

              @media (max-width: 768px) {
                margin: 13px 0 0;
              }

              .newSquad {
                width: 100%;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                border-radius: 6px;
                font-weight: 500;
                font-size: 14px;
                color: #5D4EE8;
                border-top: 4px solid #C6BEFF;
                padding: 19px 0;
                margin-top: 16px;
              }

              .squad-card {
                border-radius: 6px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 0px #5D4EE8;
                background-color: #ffffff;
                padding: 24px;
                position: relative;
                margin-bottom: 14px;

                .btn-edit-squad {
                  position: absolute;
                  bottom: 20px;
                  right: 17px;
                }

                p {
                  margin: 0px;
                }

                h2 {
                  font-weight: 400;
                  font-size: 14px;
                  color: #202121;
                  margin: 0px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }

                .squad-card-header {
                  margin-bottom: 24px;
                  display: flex;
                  justify-content: space-between;

                  div {
                    &:first-child {
                      width: calc(100% - 54px);
                    }

                    &:last-child {
                      position: relative;


                      .switch-br {
                        position: absolute;
                        top: 19px;
                        right: 16px;
                      }
                    }
                  }
                }

                .squad-card-body {
                  margin-top: -6px;
                  height: 80px;

                  h2 {
                    white-space: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                  }
                }

                &.edition {
                  padding-top: 35px;

                  .input {
                    font-weight: 400;
                    font-size: 14px;
                  }

                  .squad-save-edition {
                    position: relative;
                    text-align: center;

                    .v-btn {
                      position: absolute;
                      bottom: 13px;
                    }
                  }

                  .squad-close-edition {
                    position: relative;
                    text-align: center;

                    .v-btn {
                      position: absolute;
                      top: -2px;
                      right: -37px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .newJourney {
      background-color: #FCFCFC;
      width: 100%;
      padding: 16px;
      text-align: center;
      margin-top: 24px;
      border-radius: 6px;
    }
  }

  .footerFixed {
    position: fixed;  
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 200;
    background-color: #ffffff;
    display: none;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);

    .save-btn-footer-fixed {
      margin-right: 24px;
    }

    &.show {
      display: block;
    }
  }
</style>
<template>
  <v-container :fluid="true">
    <vv-loading :is-loading="isLoading"></vv-loading>

    <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />

    <delegate-approvals :prop-users="propUsers"></delegate-approvals>
    <v-card flat>
      <v-row>
        <v-col cols="12" sm="7" md="6">
          <v-card-title>Delegação de aprovação</v-card-title>
        </v-col>
        <v-col cols="12" sm="5" md="6" class="text-md-end" v-if="isApproval">
          <v-btn
              color="primary"
              class="mt-sm-5 mr-3 ml-4"
              outlined
              @click="loadHistoric()"
              v-if="isApproval"
          >Histórico <v-icon class="ml-1">mdi-history</v-icon>
          </v-btn>
          <v-btn
              color="primary"
              class="mt-sm-5 mr-3 ml-4"
              outlined
              @click="exportDelegate()"
              :loading="loadingButton"
          >Exportar listagem <v-icon class="ml-1">mdi-tray-arrow-down</v-icon></v-btn>
          <v-tooltip bottom max-width="240">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  color="error"
                  size="22 "
                  class="mt-sm-5 mr-md-5"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Para extrair toda a base do
              sistema, não aplicar nenhum
              filtro na tela</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
              sort-by="id"
              :headers="headers"
              :items="delegation.active"
              :search="search"
              :mobile-breakpoint="0"
              :header-props="headerProps"
              :footer-props="footerProps"
              class="icon-down"
              :options.sync="options"
              :server-items-length="serverItemsLength"
              :page.sync="currentPage"
          >
            <template v-slot:item.delegation_status_id="{ item }">
              <v-chip
                  color="#aeeac6"
                  label
                  small
                  v-if="item.delegation_status_id === CONST_DELEGATIONS_STATUS.ATIVADO">
                Ativo
              </v-chip>
              <v-chip
                  color="yellow lighten-1"
                  label
                  small
                  v-else-if="item.delegation_status_id === CONST_DELEGATIONS_STATUS.AGUARDANDO">
                Pendente
              </v-chip>
              <v-chip color="#dbdcdc" label small v-else>
                Inativo
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                  v-if="((item.delegation_status_id === CONST_DELEGATIONS_STATUS.AGUARDANDO) ||
                    (item.delegation_status_id === CONST_DELEGATIONS_STATUS.ATIVADO)) &&
                    (($store.getters.id === item.original_user_id) || userIsMaster())"
                  outlined
                  color="error"
                  small
                  @click="openDialogDelegate(item.id, item.delegate_user_name)"
              >
                Inativar
              </v-btn>
            </template>

            <template #footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
            </template>

            <template v-slot:item.initial_date="{ item }">
              <span>{{ formatDelegationDates(item.initial_date) }}</span>
            </template>
            <template v-slot:item.final_date="{ item }">
              <span>{{ formatDelegationDates(item.final_date) }}</span>
            </template>

            <template v-slot:no-data>
              <v-btn
                  class="ma-2"
                  outlined
                  color="primary"
                  :loading="isLoading"
              >
                Recarregar
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="showHistory">
        <v-col cols="12">
          <v-card-title>Histórico de delegação de aprovação</v-card-title>
        </v-col>
        <v-col cols="12">
          <v-data-table
              sort-by="id"
              :headers="headers"
              :items="delegation.inactive"
              :search="search"
              :mobile-breakpoint="0"
              :header-props="headerProps"
              :footer-props="footerProps"
              class="icon-down"
              :options.sync="options_history"
              :server-items-length="serverItemsLength_history"
              :page.sync="currentPage_history"
          >
            <template v-slot:item.delegation_status_id="{ item }">
              <v-chip
                  color="#aeeac6"
                  label
                  small
                  v-if="item.delegation_status_id === CONST_DELEGATIONS_STATUS.ATIVADO">
                Ativo
              </v-chip>
              <v-chip
                  color="yellow lighten-1"
                  label
                  small
                  v-else-if="item.delegation_status_id === CONST_DELEGATIONS_STATUS.AGUARDANDO">
                Pendente
              </v-chip>
              <v-chip color="#dbdcdc" label small v-else>
                Inativo
              </v-chip>
            </template>

            <template #footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
            </template>

            <template v-slot:no-data>
              <span>Nenhum histórico de delegação</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialog.suspendDelegate.open" persistent max-width="500">
      <v-card>
        <v-card-title>{{ dialog.suspendDelegate.title }}</v-card-title>
        <v-card-text>
          <p>{{ dialog.suspendDelegate.text }}</p>
          <div>
            <v-row>
              <v-col cols="12">
                <label>Nome:</label>
                <p>{{ dialog.suspendDelegate.nameApproval }}</p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="#7d7d7d"
              text
              @click="closeDialogDelegate()"
          >
            Fechar
          </v-btn>
          <v-btn
              color="error"
              @click="suspendDelegate()"
          >
            Inativar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.alertMessage.open" hide-overlay persistent width="400">
      <v-alert dense :type="dialog.alertMessage.type" class="ma-0">
        {{ dialog.alertMessage.textMessage }}
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import DelegateApprovals from "@/components/forms/DelegateApprovals";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

export default {
  name: "ListDelegateApprovals",
  components: {
    VvBreadcrumbs,
    VvLoading,
    DelegateApprovals,
  },
  watch: {
    options: {
      handler() {
        this.fetchDatatable()
      },
      deep: true,
    },
    options_history: {
      handler() {
        this.fetchDatatableHistory()
      },
      deep: true,
    },
  },
  data: () => ({
    payload: {},
    payload_history: {},
    isLoading: false,
    loadingButton: false,
    isApproval: false,
    search: '',
    headerProps: {
      sortByText: "Ordenar por"
    },
    footerProps: {
      'items-per-page-text': 'Linhas por página',
      'items-per-page-options': [5, 10, 15, 30, 50, 100]
    },
    options: {
      itemsPerPage: 15,
    },
    options_history: {
      itemsPerPage: 15,
    },
    serverItemsLength: -1,
    serverItemsLength_history: -1,
    headers: [
      { text: 'Id delegação', value: 'id', align: 'center' },
      { text: 'Nome delegador', value: 'original_user_name', align: 'center' },
      { text: 'Nome aprovador temporário', value: 'delegate_user_name', align: 'center' },
      { text: 'De', value: 'initial_date', align: 'center' },
      { text: 'Até', value: 'final_date', align: 'center' },
      { text: 'Status', value: 'delegation_status_id', align: 'center' },
      { text: 'Ações', value: 'actions', align: 'center', width: 120, sortable: false  },
    ],
    currentPage: 1,
    currentPage_history: 1,
    delegation: {
      active: [],
      inactive: [],
      all: [],
    },
    propUsers: [],
    showHistory: false,
    dialog: {
      suspendDelegate: {
        open: false,
        title: 'Inativar delegação',
        text: 'Você realmente deseja inativar a delegação do usuário:',
        id: null,
        nameApproval: '',
      },
      alertMessage: {
        open: false,
        type: 'success',
        textMessage: 'Delegação removida com sucesso',
      },
    },
    breadcrumb: [
      {
        text: 'Gestão',
        disabled: true,
      },
      {
        text: 'Delegação de Aprovação',
        disabled: false,
      },
    ],
  }),
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.isLoading = true;
      this.isApproval = this.validApproval();
      this.axios
          .get("user")
          .then((response) => {
            this.propUsers = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    loadDelegations() {
      this.isLoading = true;
      this.delegation.all = [];
      this.delegation.active = [];

      this.axios
          .get(`delegations/my-delegations?actives=true`, { params: this.payload })
          .then((response) => {
            this.delegation.active = response.data.data;
            this.currentPage = response.data.current_page;
            this.serverItemsLength = response.data.total;
          })
          .catch((error) => {
            console.error(error);
            this.dialog.alertMessage.type = 'error';
            this.dialog.alertMessage.textMessage = error.response.data.message;
            this.dialog.alertMessage.open = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    loadHistoric() {
      this.delegation.inactive = [];
      this.showHistory = true;
      if (this.showHistory && this.delegation.inactive.length <= 0) {
        this.isLoading = true;
        this.axios
            .get(`delegations/my-delegations`, { params: this.payload_history })
            .then((response) => {
              this.delegation.inactive = response.data.data;
              this.currentPage_history = response.data.current_page;
              this.serverItemsLength_history = response.data.total;
            })
            .catch((error) => {
              console.error(error);
              this.dialog.alertMessage.type = 'error';
              this.dialog.alertMessage.textMessage = error.response.data.message;
              this.dialog.alertMessage.open = true;
            })
            .finally(() => {
              this.isLoading = false;
            });
      }
    },
    openDialogDelegate(delegateId, delegateUserName) {
      this.dialog.suspendDelegate.id = delegateId;
      this.dialog.suspendDelegate.nameApproval = delegateUserName;
      this.dialog.suspendDelegate.open = true;
    },
    closeDialogDelegate() {
      this.dialog.suspendDelegate.id = null;
      this.dialog.suspendDelegate.nameApproval = '';
      this.dialog.suspendDelegate.open = false;
    },
    suspendDelegate() {
      let delegateIdSuspend = {
        delegation_id: this.dialog.suspendDelegate.id,
      }
      this.isLoading = true;
      this.dialog.suspendDelegate.open = false;
      this.axios
          .post(`delegations/suspend`, delegateIdSuspend)
          .then(() => {
            this.dialog.alertMessage.type = 'success';
            this.dialog.alertMessage.textMessage = 'Delegação removida com sucesso';
            this.dialog.alertMessage.open = true;
          })
          .catch((error) => {
            console.error(error);
            this.dialog.alertMessage.type = 'error';
            this.dialog.alertMessage.textMessage = 'Erro! Tente novamente';
            this.dialog.alertMessage.open = true;
          })
          .finally(() => {
            this.dialog.suspendDelegate.id = null;
            this.dialog.suspendDelegate.nameApproval = '';
            this.loadDelegations();
            if (this.delegation.inactive.length > 0) {
              this.loadHistoric();
            }
            setTimeout(() => {
              this.dialog.alertMessage.open = false;
            }, 4000)
          });
    },
    exportDelegate() {
      this.loadingButton = true;
      this.isLoading = true;
      this.axios
          .get("delegations/my-delegations?isExport=true")
          .then((response) => {
            this.createAndDownloadBlobFile(this.base64ToArrayBuffer(response.data.file), response.data.filename);
          })
          .catch((error) => {
            console.error(error);
            this.dialog.alertMessage.type = 'error';
            this.dialog.alertMessage.textMessage = 'Erro! Tente novamente';
            this.dialog.alertMessage.open = true;
            setTimeout(() => {
              this.dialog.alertMessage.open = false;
            }, 4000)
          })
          .finally(() => {
            this.loadingButton = false;
            this.isLoading = false;
          });
    },
    fetchDatatable: async function() {
      this.isLoading = true;
      this.setPagination();
      this.loadDelegations();
    },
    setPagination(page) {
      this.payload['page'] = (page) ? page : this.options.page;
      this.payload['page_size'] = this.options.itemsPerPage;
      this.payload['sortBy'] = this.options.sortBy[0];
      this.payload['sortDesc'] = 0;
      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.payload['sortDesc'] = this.options.sortDesc[0] ? 1 : 0;
      }
    },
    fetchDatatableHistory: async function() {
      this.isLoading = true;
      this.setPaginationHistory();
      this.loadHistoric();
    },
    setPaginationHistory(page) {
      this.payload_history['page'] = (page) ? page : this.options_history.page;
      this.payload_history['page_size'] = this.options_history.itemsPerPage;
      this.payload_history['sortBy'] = this.options_history.sortBy[0];
      this.payload_history['sortDesc'] = 0;
      if (this.options_history.sortBy.length && this.options_history.sortDesc.length) {
        this.payload_history['sortDesc'] = this.options_history.sortDesc[0] ? 1 : 0;
      }
    },
    validApproval() {
      let typeUser = this.$store.getters.type;
      let retorno = false;
      let approvalsType = [
        this.CONST_USER_TYPE.VSM,
        this.CONST_USER_TYPE.STAKEHOLDER,
        this.CONST_USER_TYPE.PONTOS_FOCAIS,
        this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO,
        this.CONST_USER_TYPE.USUARIO_MASTER,
        this.CONST_USER_TYPE.CURADORIA,
      ]
      for (let indexTypeUser = 0; indexTypeUser < typeUser.length; indexTypeUser++) {
        if (approvalsType.indexOf(typeUser[indexTypeUser]) > -1) {
          retorno = true;
        }
      }
      return retorno;
    },
    formatDelegationDates(rawDate) {
      if(typeof rawDate === "undefined" || rawDate === null){
        return '';
      }
      let date = new Date(rawDate);
      return date.toLocaleDateString();
    },
  },
}
</script>

<style scoped>

</style>
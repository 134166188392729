import axios from "@/plugins/axios";

export default function faqServiceFactory() {
    return {
        loadTreeMenu() {
            return axios
                .get(`v2/articles/menu`)
                .then(res => res.data);
        },
        loadArticle(id, keywords) {
            const url = (keywords !== null) ? `v2/articles/${id}?search=${keywords}` : `v2/articles/${id}`;
            return axios
                .get(url)
                .then(res => res.data);
        },
        searchTerm(term) {
            return axios
                .get(`v2/articles/search?search=${term}`)
                .then(res => res.data);
        },
    }
}
<template>
  <v-container>
    <v-row v-if="approvals.length > 0">
      <v-col cols="12">
        <h2>Workflow</h2>
        <div class="content">
          <div class="approvers">
            <h4>Aprovadores:</h4>
            <ul>
              <li v-for="(approval, index) in approvals" :key="index" :class="'approved-'+approval.approved">
                <span v-if="approval.delegate.id === null">
                  {{ approval.user_name }}
                  <template v-if="approval.approved === null">
                    (Pendente)
                  </template>
                  <template v-else-if="approval.approved">
                    (Aprovado)
                  </template>
                  <template v-else>
                    (Reprovado)
                  </template>
                </span>
                <span v-else>
                  {{ approval.delegate.delegate_user_name }}
                  <template v-if="approval.approved === null">
                    (Pendente)
                  </template>
                  <template v-else-if="approval.approved">
                    (Aprovado)
                  </template>
                  <template v-else>
                    (Reprovado)
                  </template>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon :color="(approval.approved) ? 'success' : (approval.approved === null) ? '' : 'error'" v-bind="attrs" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      {{ approval.delegate.original_user_name }}
                    </span>
                  </v-tooltip>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-alert v-if="form.values.status_id == CONST_CHANGE_REQUEST_STATUS.REPROVADO" type="error" width="100%">
        <span v-if="form.values.refused_original_user_name !== null">
          Change Request recusada por <strong>{{ form.values.refused_user }}</strong>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-1" color="primary" v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span>
              {{ form.values.refused_original_user_name }}
            </span>
          </v-tooltip>
          pelo motivo: <strong>{{ form.values.refused_cause }}</strong>
        </span>
        <span v-else>
          Change Request recusada por <strong>{{ form.values.refused_user }}</strong> pelo motivo: <strong>{{ form.values.refused_cause }}</strong>
        </span>
      </v-alert>
      <v-col cols="12">
        <label for="description">Motivo do Change Request</label>
        <v-textarea
          id="description"
          name="description"
          v-model="form.values.description"
          class="scroll-style"
          :error-messages="form.errors.description"
          :disabled="checkStatus(form.values.status_id)"
          required
          filled
          :rules="form.rules.description"
        ></v-textarea>
      </v-col>
      <v-col cols="12" md="3">
        <label for="origin_id">Origem da Solicitação</label>
        <v-autocomplete
          id="origin_id"
          name="origin_id"
          v-model="form.values.origin_id"          
          :items="form.option.origin"
          :error-messages="form.errors.origin_id"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          :rules="form.rules.origin_id"
          @change="limitedClassification()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <label for="classification_id">Classificação</label>
        <v-autocomplete
          id="classification_id"
          name="classification_id"
          v-model="form.values.classification_id"
          :items="customRating"
          :error-messages="form.errors.classification_id"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          :rules="form.rules.classification_id"
        >
          <template v-slot:item="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content v-if="item.active">
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.name }}</span>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span v-html="item.description"></span>
            </v-tooltip>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="4">
        <label for="impacts_id">Impactos (multiseleção)</label>
        <v-autocomplete
          id="impacts_id"
          name="impacts_id"
          v-model="form.values.impacts_id"
          :items="form.option.impact"
          :error-messages="form.errors.impacts_id"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          :rules="form.rules.impacts_id"
          multiple
          chips
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              @click="select"
              label
              class="chip-green"              
              >{{ item.name }}
              <v-icon size="16" class="remove-chip" @click="removeImpact(item)"
              :disabled="checkStatus(form.values.status_id)"
                >mdi-close</v-icon
              >
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-on="on" v-bind="attrs">
                  <template v-slot:default="{ active }">
                  
                    <v-list-item-content>
                      <v-list-item-title :input-value="active">{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
              <span v-html="item.description"></span>
            </v-tooltip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <label for="focal_point">Aprovador - Ponto Focal</label>
        <v-autocomplete
          id="focal_point"
          name="focal_point"
          v-model="form.values.focal_point_approver"
          :items="form.approver_option.focal_point"
          :error-messages="form.errors.focal_point_approver"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          :rules="form.rules.focal_point_approver"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <label>Multiplos Épicos Impactados</label>
        <v-switch
          color="secondary"
          class="manual-line switch manual-line-list-item-icon"
          v-model="form.values.multiple_epics"
          :label="form.values.multiple_epics ? 'Sim' : 'Não'"
          inset
          :disabled="checkEdit()"></v-switch>
      </v-col>
      <v-col cols="12" md="6" v-if="form.values.multiple_epics">
        <label for="impacts_id">Épicos Impactados</label>
        <v-autocomplete
          id="impacts_id"
          name="impacts_id"
          v-model="form.values.multiple_epics_impacted"
          :items="multiple_epics_impacted"
          :error-messages="form.errors.multiple_epics_impacted"
          :disabled="checkEdit()"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          :rules="form.errors.multiple_epics_impacted"
          multiple
          chips
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              @click="select"
              label
              class="chip-green"
              >
              <v-icon size="16" class="remove-chip" @click="removeEpicImpacted(item)"
              :disabled="checkEdit()"
                >mdi-close</v-icon
              >{{ item.id_jira }} |  {{ item.name }}              
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-on="on" v-bind="attrs">
                  <template v-slot:default="{ active }">
                  
                    <v-list-item-content>
                      <v-list-item-title :input-value="active">{{ item.id_jira }} |  {{ item.name }} </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-tooltip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row
      v-if="
        form.values.classification_id ==
        CONST_CHANGE_REQUEST_CLASSIFICATION.REPRIOZICAO_AREA_RESPONSAVEL
      "
    >
      <v-col cols="12" md="4">
        <label>Iniciativa Prioritária</label>
        <v-autocomplete
          v-model="form.values.classification_project_id"
          :items="form.option.projects"
          :error-messages="form.errors.classification_project_id"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          @change="integrationLoad(form.values.classification_project_id);"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <label for="classification_epic_id">Épico Prioritário</label>
        <v-autocomplete
          id="classification_epic_id"
          name="classification_epic_id"
          v-model="form.values.classification_epic_id"
          :items="form.option.epics"
          :error-messages="form.errors.classification_epic_id"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row
      v-if="
        form.values.classification_id ==
        CONST_CHANGE_REQUEST_CLASSIFICATION.REPRIOZICAO_IMPACTO_AREA
      "
    >
      <v-col cols="12" md="4">
        <label for="classification_area_id">Área Envolvida</label>
        <v-autocomplete
          id="classification_area_id"
          name="classification_area_id"
          v-model="form.values.classification_area_id"
          :items="form.option.areas"
          :error-messages="form.errors.classification_area_id"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        v-if="
          form.values.impacts_id.includes(CONST_CHANGE_REQUEST_IMPACT.ESCOPO)
        "
      >
        <label for="impact_description">Mudança Detalhada</label>
        <v-textarea
          id="impact_description"
          name="impact_description"
          v-model="form.values.impact_description"
          class="scroll-style"
          filled
          :error-messages="form.errors.impact_description"
          :disabled="checkStatus(form.values.status_id)"
          :rules="form.rules.impact_description"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
        v-if="
          form.values.impacts_id.includes(
            CONST_CHANGE_REQUEST_IMPACT.NOVAS_DEPENDENCIAS
          )
        "
      >
        <label for="area_id">Área Envolvida</label>
        <v-autocomplete
          id="area_id"
          name="area_id"
          v-model="form.values.area_id"
          :items="form.option.areas"
          :error-messages="form.errors.area_id"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
          @change="integrationFocalPointLoadFromArea(form.values.area_id)"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
        v-if="
          form.values.impacts_id.includes(
            CONST_CHANGE_REQUEST_IMPACT.NOVAS_DEPENDENCIAS
          )
        "
      >
        <label for="focal_point_id">Ponto Focal</label>
        <v-autocomplete
          id="focal_point_id"
          name="focal_point_id"
          v-model="form.values.focal_point_id"
          :items="form.option.focal_points"
          :error-messages="form.errors.focal_point_id"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
        v-if="
          form.values.impacts_id.includes(
            CONST_CHANGE_REQUEST_IMPACT.NOVAS_DEPENDENCIAS
          )
        "
      >
        <label for="journey_id">Jornada</label>
        <v-autocomplete
          id="journey_id"
          name="journey_id"
          v-model="form.values.journey_id"
          :items="form.option.journeys"
          :error-messages="form.errors.journey_id"
          :disabled="checkStatus(form.values.status_id)"
          item-text="name"
          item-value="id"
          append-icon="mdi-chevron-down"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="4"
        v-if="
          form.values.impacts_id.includes(
            CONST_CHANGE_REQUEST_IMPACT.NOVAS_DEPENDENCIAS
          )
        "
      >
        <label>Existe Entrega de Novo Épico?</label>
        <v-tooltip right max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-3" color="primary" v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span>
            Se sim, é importante que o épico já tenha sido cadastrado para fazer o vínculo na Change Request
          </span>
        </v-tooltip>
        <v-switch
          color="secondary"
          class="manual-line switch manual-line-list-item-icon"
          v-model="form.values.delivery_new_epic"
          :label="form.values.delivery_new_epic ? 'Sim' : 'Não'"
          inset
          :disabled="checkStatus(form.values.status_id)"></v-switch>
      </v-col>
      <v-col
        cols="8"
        v-if="form.values.impacts_id.includes(CONST_CHANGE_REQUEST_IMPACT.NOVAS_DEPENDENCIAS) && form.values.delivery_new_epic"
      >
        <label>Novo Épico a ser Entregue</label>
        <v-autocomplete
          v-model="form.values.new_epic_id"
          :items="epicsList"
          :disabled="checkStatus(form.values.status_id)"
          :item-text="montaLabel"
          item-value="id"
          append-icon="mdi-chevron-down"
          required
          :error-messages="form.errors.new_epic_id"
          :rules="form.rules.new_epic_id"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        v-if="
          form.values.impacts_id.includes(
            CONST_CHANGE_REQUEST_IMPACT.NOVAS_DEPENDENCIAS
          )
        "
      >
        <label for="dependency_description">Detalhe Dependência</label>
        <v-textarea
          id="dependency_description"
          name="dependency_description"
          v-model="form.values.dependency_description"
          class="scroll-style"
          filled
          :error-messages="form.errors.dependency_description"
          :disabled="checkStatus(form.values.status_id)"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row
      v-if="form.values.impacts_id.includes(CONST_CHANGE_REQUEST_IMPACT.PRAZO)"
    >
       <v-col cols="12" md="4">
          <label>{{ deadlineLabel }}</label>
          <v-text-field
           :value="formattedDateForBr(deadlineValue)"
            id="delivery_baseline"
            name="delivery_baseline"
            disabled
          ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <label for="impact_deadline">Nova Data</label>
        <v-menu
            ref="menu"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :disabled="checkStatus(form.values.status_id)"
                :value="formatDate(form.values.impact_deadline)"
                :error-messages="form.errors.impact_deadline"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @blur="limpaMensagemErro('date', index)"
            ></v-text-field>
          </template>
          <v-date-picker
              :disabled="checkStatus(form.values.status_id)"
              v-model="form.values.impact_deadline"
              no-title
              scrollable
              locale="pt-br"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row
      v-if="form.values.impacts_id.includes(CONST_CHANGE_REQUEST_IMPACT.CUSTO)"
    >
      <v-col cols="12" md="4">
        <label for="capex">CAPEX</label>
        <v-switch
          v-model="form.values.capex"
          id="capex"
          name="capex"
          color="secondary"
          class="manual-line switch"
          inset
          :error-messages="form.errors.capex"
          :disabled="checkStatus(form.values.status_id)"
        ></v-switch>
      </v-col>
      <v-col cols="12" md="4">
        <label for="capex_value">Valor do Impacto</label>
        <v-text-field
          id="capex_value"
          name="capex_value"
          v-model="form.values.capex_value"
          :disabled="checkStatus(form.values.status_id) || !form.values.capex"
          :error-messages="form.errors.capex_value"
          type="number"
          @keypress="isNumber($event, field.value, 9)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <label for="capex_description">Descrição dos Gastos</label>
        <v-text-field
          id="capex_description"
          name="capex_description"
          v-model="form.values.capex_description"
          :disabled="checkStatus(form.values.status_id) || !form.values.capex"
          :error-messages="form.errors.capex_description"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <label for="opex">OPEX</label>
        <v-switch
          v-model="form.values.opex"
          id="opex"
          name="opex"
          color="secondary"
          class="manual-line switch"
          inset
          :error-messages="form.errors.opex"
          :disabled="checkStatus(form.values.status_id)"
        ></v-switch>
      </v-col>
      <v-col cols="12" md="4">
        <label for="opex_value">Valor do Impacto</label>
        <v-text-field
          id="opex_value"
          name="opex_value"
          v-model="form.values.opex_value"
          :disabled="checkStatus(form.values.status_id) || !form.values.opex"
          :error-messages="form.errors.opex_value"
          type="number"
          @keypress="isNumber($event, field.value, 9)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <label for="opex_description">Descrição dos Gastos</label>
        <v-text-field
          id="opex_description"
          name="opex_description"
          v-model="form.values.opex_description"
          :disabled="checkStatus(form.values.status_id) || !form.values.opex"
          :error-messages="form.errors.opex_description"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="form.message.length > 0">
      <v-card width="100%">
        <v-card-title>
          Histórico de mensagens
        </v-card-title>
        <v-card-text>
          <div v-for="(message, index) in form.message" :key="index">
            <v-alert text>
              <p>{{ message.user_name }} questionou:</p>
              {{ message.message }}
            </v-alert>            
            <v-alert text border="left" v-if="message.answer.length > 0">
              <p> {{ message.answer[0].user_name }} respondeu:</p>
              {{ message.answer[0].message }}
            </v-alert>
            <div v-else>
              <label>Resposta</label>
              <v-textarea filled v-model="form.values.message"></v-textarea>
            </div>
          </div>
        </v-card-text>    
      </v-card>
    </v-row>
    <template v-if="epicValidTree">
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            dark
            class="d-block ma-2"
            @click="createChangeRequest"
            :disabled="checkStatus(form.values.status_id)"
            :loading="isLoading"
          >
            <v-icon dark left>mdi-folder-outline</v-icon>
            {{label.button.submitApproval}}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-alert type="error">
        Há inconsistências na arvore de tecnologia do macro tema, selecione novamente o VSM responsável pelo entregável
      </v-alert>
    </template>
  </v-container>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
    },
    epicId: {
      type: Number,
      default: -1,
    },
    epicsList: {
      type: Array,
    }
  },
  mounted: function () { 
    this.formLoad();
    this.getFocalPointsApprover();
    this.treeByEpic();
  },
  data: () => ({
    labelClassificationRepriority: "Lista",
    label: {
      button: {
        submitApproval: "Submeter para aprovação"
      }
    },
    impactIsScope: false,
    capexActive: false,
    opexActive: false,
    approvals: [],
    epicValidTree: false,
    customRating: [],
    isLoading: false,
    form: {
      values: {
        description: "",
        impacts_id: [],
        delivery_planned: '',
        delivery_baseline: '',
        message: '',
        delivery_new_epic: false,
        focal_point_approver: '',
        multiple_epics: false,
        multiple_epics_impacted: []
      },
      option: {
        areas: [],
        focal_points: [],
        epics: [],
      },
      approver_option: {
        focal_point: [],
      },
      message: [],
      errors: {},      
      rules: {},
    },    
  }),
  watch: {
    watch_description: function() { this.form.errors.description = []; },
    watch_origin_id: function() { this.form.errors.origin_id = []; },
    watch_classification_id: function() { this.form.errors.classification_id = []; },
    watch_impacts_id: function() { this.form.errors.impacts_id = []; },
    watch_area_id: function() { this.form.errors.area_id = []; },
    watch_impact_description: function() { this.form.errors.impact_description = []; },
    watch_focal_point_id: function() { this.form.errors.focal_point_id = []; },
    watch_journey_id: function() { this.form.errors.journey_id = []; },
    watch_dependency_description: function() { this.form.errors.dependency_description = []; },
    watch_impact_deadline: function() { this.form.errors.impact_deadline = []; },
    watch_capex: function() { this.form.errors.capex = []; },
    watch_capex_value: function() { this.form.errors.capex_value = []; },
    watch_capex_description: function() { this.form.errors.capex_description = []; },
    watch_opex: function() { this.form.errors.opex = []; },
    watch_opex_value: function() { this.form.errors.opex_value = []; },
    watch_opex_description: function() { this.form.errors.opex_description = []; },
    watch_new_epic_id: function() { this.form.errors.new_epic_id = []; },
    watch_multiple_epics_impacted: function() { this.form.errors.multiple_epics_impacted = []; },
    epicId: function() { this.treeByEpic(); },
    customRating: function(newValue) { return newValue },
  },
  computed: {
    watch_description() { return this.form.values.description },
    watch_origin_id() { return this.form.values.origin_id },
    watch_classification_id() { return this.form.values.classification_id },
    watch_impacts_id() { return this.form.values.impacts_id },
    watch_area_id() { return this.form.values.area_id },
    watch_impact_description() { return this.form.values.impact_description },
    watch_focal_point_id() { return this.form.values.focal_point_id },
    watch_journey_id() { return this.form.values.journey_id },
    watch_dependency_description() { return this.form.values.dependency_description },
    watch_impact_deadline() { return this.form.values.impact_deadline },
    watch_capex() { return this.form.values.capex },
    watch_capex_value() { return this.form.values.capex_value },
    watch_capex_description() { return this.form.values.capex_description },
    watch_opex() { return this.form.values.opex },
    watch_opex_value() { return this.form.values.opex_value },
    watch_opex_description() { return this.form.values.opex_description },
    watch_new_epic_id() { return this.form.values.new_epic_id },
    watch_multiple_epics_impacted() { return this.form.values.multiple_epics_impacted },
    multiple_epics_impacted() {
      let epicId = this.epicId == -1 ? this.data.epic_id : this.epicId
      let newEpicList = []
      Object.keys(this.epicsList).forEach((key) => {
        if(this.epicsList[key].id != epicId) {
          newEpicList.push(this.epicsList[key])
        }
      })
      return newEpicList
    },
    deadlineLabel(){
      let epicId = this.epicId == -1 ? this.data.epic_id : this.epicId;
      let label = '';
      Object.keys(this.$store.getters.epics).forEach((key) => {
        if(this.$store.getters.epics[key].id == epicId){
          label = this.$store.getters.epics[key].delivery_planned.value ?
            "Data Original" :
            "Entrega Baseline"
        }
      });
      return label;
    },
    deadlineValue(){
      let epicId = this.epicId == -1 ? this.data.epic_id : this.epicId;
      let label = '';
      Object.keys(this.$store.getters.epics).forEach((key) => {
        if(this.$store.getters.epics[key].id == epicId){
          label = this.$store.getters.epics[key].delivery_planned.value ?
            this.$store.getters.epics[key].delivery_planned.value :
            this.$store.getters.epics[key].delivery_baseline
        }
      });
      return label;
    },
  },
    
  methods: {
    limitedClassification() {
      this.customRating = []
      for (let i = 0; i < this.form.option.classification.length; i++) {
        if (this.form.values.origin_id == 1 && this.form.option.classification[i].name == "Atraso") {
          this.customRating.push(this.form.option.classification[i]) 
        } else if (this.form.values.origin_id == 2) {
          this.customRating.push(this.form.option.classification[i]) 
        }
      }
    },
    removeImpact(item) {
      this.form.values.impacts_id.splice(
        this.form.values.impacts_id.indexOf(item.id),
        1
      );
      this.form.values.impact = [...this.form.values.impact];
    },
    removeEpicImpacted(item) {
      this.form.values.multiple_epics_impacted.splice(
        this.form.values.multiple_epics_impacted.indexOf(item.id),
        1
      );
    },
    formLoad() {
      this.axios
        .get("options/cr")
        .then((response) => {
          this.form.option = response.data.options;

          if (this.data) {
            this.form.values = this.data;
            this.approvals = this.data.approvals;
            this.loadPriorityEpic();
            this.integrationFocalPointLoadFromArea(this.form.values.area_id);
            this.messageLoad();
          }

          if (this.userIsMaster() && this.form.values.status_id == this.CONST_CHANGE_REQUEST_STATUS.APROVADO) {
            this.label.button.submitApproval = 'Salvar';
          }

          this.startNoApply(this.form.option.areas, 1);
          this.startNoApply(this.form.option.journeys, 1);
          // this.loadEpicsNotCanceled(this.$store.getters.epics);
          // this.form.values.projects = this.form.option.projectsW
        })
        .catch((error) => {
          console.error(error);
        });  
    },

    montaLabel(item){
      return item.id_jira + ' | ' + item.name;
    },

    treeByEpic() {
      let epicId = this.epicId == -1 ? this.data.epic_id : this.epicId;
      this.axios
        .get("tree/tecnologic/treebyepic/" + epicId)
          .then((response) => {
            this.epicValidTree = response.data[0].vsm.validTree;
      })
    },

    getFocalPointsApprover() {
        this.axios
          .get(`options/tap/focalpoints/${this.$route.params.id}`)
          .then((response) => {
            this.form.approver_option.focal_point = response.data
          })
          .catch((error) => {
            console.error(error)
          });
    },

    checkDeliveryDate(option, values) {
      if (option.classification_epic_id[0].delivery_planned.value) {
        values.delivery_planned = this.formatDate(option.classification_epic_id[0].delivery_planned.value);
      } else {
        values.delivery_baseline = this.formatDate(option.classification_epic_id[0].delivery_baseline);
      }
    },

    integrationFocalPointLoadFromArea(area_id) {

      let focalPoint = this.form.option.areas.filter((value) => {
        return value.id === area_id;
      });

      if (focalPoint.length > 0) 
        this.form.option.focal_points = focalPoint[0].focal_points;
    },

    loadEpicsNotCanceled(data) {
      let epics = data.filter((epic) => {
        return epic.status_id != this.CONST_STATUS.CANCELADO;
      });

      this.form.option.classification_epic_id = epics;
    },
    
    createChangeRequest() {
      let project_id = this.$route.params.id;
      this.isLoading = true;
      if (!this.form.values.capex) {
        this.form.values.capex_value = null;
        this.form.values.capex_description = null;
      }

      if (!this.form.values.opex) {
        this.form.values.opex_value = null;
        this.form.values.opex_description = null;
      }
        
      if (this.data == undefined) {
        this.isLoading = true;
        this.axios
          .post(`/project/${project_id}/epic/${this.epicId}/cr`, this.form.values)
          .then((response) => {
            this.clearForm();            
            let objEmit = {
              epicId: this.epicId,
              message: response.data.message,
            };
            this.$emit("createSuccess", objEmit);
            this.isLoading = false;
          })
          .catch((error) => {            
            if(error.response.status == 422) {
              this.form.errors = error.response.data
              console.log(this.form.errors);
            }
            else {
              this.$emit("failed", error.response.data.message);
            }
            this.isLoading = false;            
          });
      } else {
        this.isLoading = true;
        this.axios
          .put(`/project/${project_id}/epic/${this.data.epic_id}/cr/${this.data.id}`, this.form.values)
          .then((response) => {
            let returnData = {
              message: response.data.message,
              projectId: project_id,
              epicId: this.data.epic_id,
              crId: this.data.id,
            }

            if(this.form.values.message != undefined) {
              this.saveAnswer(this.form.values.message);
            }

            this.$emit("updateSuccess", returnData);
            this.isLoading = false;
          })
          .catch((error) => {
            if(error.response.status == 422) {
              this.form.errors = error.response.data
              console.log(this.form.errors);
            }
            else {
              this.$emit("failed", error.response.data.message);
            }
            this.isLoading = false;
          });
      }
    },

    checkStatus(status) {
      if (!status || 
          status == this.CONST_CHANGE_REQUEST_STATUS.PENDENTE_ALTERACAO ||
          status == this.CONST_CHANGE_REQUEST_STATUS.REPROVADO) {
        return false;
      }
      if (this.userIsMaster() && status == this.CONST_CHANGE_REQUEST_STATUS.APROVADO) {
        return false
      }
      return true;
    },

    checkEdit() {
      if (!this.form.values.status_id) {
        return false
      }
      return true
    },

    formatDate(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');

      return `${day}/${month}/${year}`;
    },

    messageLoad() {
      this.axios
        .get(`message/generic?model=ChangeRequest&model_id=${this.form.values.id}`)
        .then((response) => {
          this.form.message = response.data.message;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    saveAnswer(message) {
      let messageBody = {
        model: "ChangeRequest",
        model_id: this.form.values.id,
        message: message,
        parent_id: this.form.message[this.form.message.length - 1].id
      }
    
      this.axios
        .post(`message/generic?model=ChangeRequest&model_id=${this.form.values.id}`, messageBody)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    integrationLoad: function (item) {
      let epicos = this.form.option.projects.filter((value) => {
        return value.id === item;
      });
      
      this.form.option.epics = epicos[0].epics;
    },

    loadPriorityEpic: function () {
      let epicos = this.form.option.projects.filter((value) => {
        return value.id === this.form.values.classification_project_id;
      });

      if(epicos.length > 0){
        this.form.option.epics = epicos[0].epics;
      }

    },

    clearForm() {
      this.form.values.description = null;
      this.form.values.origin_id = null;
      this.form.values.classification_id = null;
      this.form.values.impacts_id = null;
      this.form.values.classification_project_id = null;
      this.form.values.classification_epic_id = null;
      this.form.values.classification_area_id = null;
      this.form.values.impact_description = null;
      this.form.values.area_id = null;
      this.form.values.focal_point_id = null;
      this.form.values.journey_id = null;
      this.form.values.dependency_description = null;
      this.form.values.impact_deadline = null;
      this.form.values.capex = false;
      this.form.values.capex_value = null;
      this.form.values.capex_description = null;
      this.form.values.opex = false;
      this.form.values.opex_value = null;
      this.form.values.opex_description = null;
      this.form.values.focal_point_approver = null;
    },    
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content--fixed {
  max-width: 300px;
}

.content {
  display: grid;
  padding: 12px;
  width: 100%;

  .approvers {
    margin-bottom: 20px;

    ul {
      list-style: none;
      padding: 0px;

      li {
        float: left;
        padding: 5px 10px;
        background: #e0e0e0;
        color: rgba(0, 0, 0, 0.87);
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 5px;
      }

      li.approved-true {
        background-color: rgba(0, 146, 0, 0.3);
        color: rgba(0, 146, 0, 0.8);
      }

      li.approved-false {
        background-color: rgba(255, 0, 0, 0.3);
        color: rgba(255, 0, 0, 0.8);
      }
    }
  }
}
</style>

<template>
  <v-container :fluid="true">

    <vv-loading :is-loading="isLoading"></vv-loading>

    <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />

    <vv-filter :filter-name="filterName" :fields="fields" @filter="filter" @reset="reset" export-api="v2/report/change-request" :notification-export="true"></vv-filter>

    <div class="cr-vision-card">

      <h2 class="title-form">Listagem Change Requests</h2>

      <v-data-table
          sort-by="project_id"
          :headers="headers"
          :items="changes"
          :search="search"
          :mobile-breakpoint="0"
          :header-props="headerProps"
          :footer-props="footerProps"
          class="cursor icon-down"
          @click:row="editItem"
          :options.sync="options"
          :server-items-length="serverItemsLength"
          :page.sync="currentPage"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>

        <template v-slot:no-data>
          <v-btn
              class="ma-2"
              outlined
              color="primary"
              :loading="isLoading"
              @click="initialize"
          >
            Recarregar
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog
        v-model="dialogMessage"
        hide-overlay
        persistent
        width="500"
    >
      <v-alert
          dense
          :type="typeMessage"
          style="margin: 0;"
      >
        <span v-html="textMessage"></span>
      </v-alert>
    </v-dialog>

  </v-container>
</template>

<script>
import VvLoading from '@/components/VvLoading';
import VvFilter from '@/components/VvFilter';
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

export default {
  name: "ChangeRequestList",

  components: {
    VvBreadcrumbs,
    VvLoading,
    VvFilter
  },

  data: () => ({
    payload: {},
    isLoading: true,
    dialogMessage: false,
    typeMessage: 'success',
    textMessage: '',
    search: '',
    headerProps: {
      sortByText: "Ordenar por"
    },
    footerProps: {
      'items-per-page-text': 'Linhas por página',
      'items-per-page-options': [5, 10, 15, 30, 50, 100]
    },
    options: {
      itemsPerPage: 15,
    },
    serverItemsLength: 0,
    headers: [
      {text: 'ID iniciativa', value: 'projectId', align: 'center'},
      {text: 'ID épico', value: 'epicId', align: 'center'},
      {text: 'ID épico Jira', value: 'idJira', align: 'center'},
      {text: 'ID change', value: 'changeRequestId', align: 'center'},
      {text: 'Status change', value: 'status', class:'cabecalho'},
      {text: 'Motivo change', value: 'description', align: 'center', class:'cabecalho'},
      {text: 'Origem solicitação', value: 'origin', align: 'center', class:'cabecalho'},
      {text: 'Classificação', value: 'classification', align: 'center', class:'cabecalho'},
      {text: 'Impactos', value: 'impacts', align: 'center', class:'cabecalho'},
      {text: 'Aprovadores', value: 'approvalUserName', align: 'center', class:'cabecalho'},
      {text: 'Múltiplos épicos impactados', value: 'multipleEpics', align: 'center', class:'cabecalho'},
    ],
    changes: [],
    objectIndex: -1,
    objectItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
    fields:[],
    currentPage: 1,
    status_cr: [],
    origin: [],
    classification: [],
    impacts: [],
    areas: [],
    approvalUsers: [],
    breadcrumb: [
      {
        text: 'Listagens',
        disabled: true,
      },
      {
        text: 'Change Requests',
        disabled: false,
      },
    ],
    filterName: 'changeRequest'
  }),

  watch: {
    dialogMessage(val) {
      if (!val) return

      setTimeout(() => (
          this.dialogMessage = false, this.textMessage = '', this.typeMessage = 'success'
      ), 2500)
    },

    options: {
      handler() {
        this.fetchDatatable()
      },
      deep: true,
    },
  },

  created() {
    this.loadApprovalsCRFields();
    this.mountValuesFilterFields();
  },

  methods: {
    initialize() {
      this.isLoading = true;
      if(this.$store.getters.userToken) {
        this.axios.get('v2/change-request', {
          params: this.payload
        })
            .then((response) => {
              this.changes = response.data.data;
              this.currentPage = response.data.current_page;
              this.serverItemsLength = response.data.total;
            })
            .catch((error) => {
              let messagesError = '<ul>';
              Object.keys(error.response.data).forEach((key) => {
                messagesError += `<li>${error.response.data[key]} </li>`;
              });
              messagesError += '</ul>';

              this.dialogMessage = true
              this.textMessage = messagesError
              this.typeMessage = 'error'
            })
            .finally(() => {
              this.isLoading = false
            })
      } else {
        setTimeout(() => {
          this.initialize();
        }, 100)
      }
    },

    mountValuesFilterFields() {
      this.axios
          .get("v2/options/change-request-list")
          .then((response) => {
            this.status_cr = response.data.options.statusChangeRequest;
            this.origin = response.data.options.origin;
            this.classification = response.data.options.classification;
            this.impacts = response.data.options.impacts;
            this.areas = response.data.options.areas;
            this.startNoApply(this.areas, 1);
            this.createFilterFields();
          })
          .catch((error) => {
            console.log(error);
            this.dialogMessage = true
            this.textMessage = error
            this.typeMessage = 'error'
          });
    },

    loadApprovalsCRFields() {
      this.axios
          .get('list-cr/approvals')
          .then((response) => {
            this.approvalUsers = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
    },

    editItem(item) {
      let url = `/projeto/${item.projectId}/epicos/${item.epicId}/change-request/${item.changeRequestId}`;

      this.$router.push(url);
    },

    createFilterFields(){
      this.fields = [
        {
          "type": "number",
          "label": "ID Iniciativa",
          "sm": "3",
          "id": "projectId",
          "name": "projectId",
          "payloadBackend": "projectId[eq]",
          "items": []
        },
        {
          "type": "number",
          "label": "ID épico",
          "sm": "3",
          "id": "epicId",
          "name": "epicId",
          "payloadBackend": "epicId[eq]",
          "items": []
        },
        {
          "type": "text",
          "label": "ID épico Jira",
          "sm": "3",
          "id": "idJira",
          "name": "idJira",
          "payloadBackend": "idJira[like]",
          "items": []
        },
        {
          "type": "number",
          "label": "ID change",
          "sm": "3",
          "id": "changeRequestId",
          "name": "changeRequestId",
          "payloadBackend": "changeRequestId[eq]",
          "items": []
        },
        {
          "type": "multiautocomplete",
          "label": "Status change",
          "sm": "3",
          "id": "statusId",
          "name": "statusId",
          "payloadBackend": "status[id][or]",
          "items": this.status_cr
        },
        {
          "type": "multiautocomplete",
          "label": "Origem solicitação",
          "sm": "3",
          "md": "3",
          "id": "originId",
          "name": "originId",
          "payloadBackend": "origin[id][or]",
          "items": this.origin
        },
        {
          "type": "multiautocomplete",
          "label": "Classificação",
          "sm": "3",
          "md": "3",
          "id": "classificationId",
          "name": "classificationId",
          "payloadBackend": "classification[id][or]",
          "items": this.classification
        },
        {
          "type": "multiautocomplete",
          "label": "Impactos",
          "sm": "3",
          "md": "3",
          "id": "impactsId",
          "name": "impactsId",
          "payloadBackend": "impacts[changeRequestImpactId][or]",
          "items": this.impacts
        },
        {
          "type": "multiautocomplete",
          "label": "Área responsável (iniciativa)",
          "sm": "3",
          "md": "3",
          "id": "projectAreaId",
          "name": "projectAreaId",
          "payloadBackend": "projectAreaId[or]",
          "items": this.areas
        },
        {
          "type": "multiautocomplete",
          "label": "Área responsável (entrega)",
          "sm": "3",
          "md": "3",
          "id": "epicAreaId",
          "name": "epicAreaId",
          "payloadBackend": "epicAreaId[or]",
          "items": this.areas
        },
        {
          "type": "autocomplete",
          "label": "Entrega de novo épico (sim/não)",
          "sm": "3",
          "md": "3",
          "id": "deliveryNewEpic",
          "name": "deliveryNewEpic",
          "payloadBackend": "deliveryNewEpic[eq]",
          "items": [
            {
              id: 'false',
              name: 'Não'
            },
            {
              id: 'true',
              name: 'Sim'
            }
          ]
        },
        {
          "type": "autocomplete",
          "label": "Múltiplos épicos impactados",
          "sm": "3",
          "md": "3",
          "id": "multipleEpics",
          "name": "multipleEpics",
          "payloadBackend": "multipleEpics[eq]",
          "items": [
            {
              id: 'false',
              name: 'Não'
            },
            {
              id: 'true',
              name: 'Sim'
            }
          ]
        },
        {
          "type": "multiautocomplete",
          "label": "Aprovador",
          "sm": "3",
          "md": "3",
          "id": "id",
          "name": "name",
          "payloadBackend": "approvals[user_id][or]",
          "items": this.approvalUsers
        },
        {
          "type": "multiautocomplete",
          "label": "Status Aprovador",
          "tooltip": `Ao aplicar este filtro (com um “Aprovador” selecionado), apenas será exibido na tabela a listagem
                        com esta combinação. Se “Aprovador” não estiver selecionado, trará toda a
                        listagem de acordo com o status escolhido.`,
          "sm": "3",
          "md": "3",
          "id": "id",
          "name": "name",
          "payloadBackend": "approvalsStatus",
          "items": [
            {
              id: 'true',
              name: 'Aprovado'
            },
            {
              id: 'false',
              name: 'Reprovado'
            },
            {
              id: 'null',
              name: 'Pendente'
            }
          ]
        }
      ];

      let fieldsStore = this.getGlobalFilters(this.filterName);
      if (fieldsStore !== undefined) {
        this.fields = fieldsStore;

        let payload = {};

        this.fields.map(field => {
          if (field.value) {
            payload[field.payloadBackend] = field.value
          }
        });
        if (Object.keys(payload).length) {
          payload.page = 1;
          this.filter(payload);
        }
      }
    },

    filter(payload) {
      this.payload = payload;
      this.setPagination(payload.page);
      this.initialize();
    },

    reset() {
      this.payload = {};
      this.setPagination();
      this.initialize();
    },

    fetchDatatable: async function() {
      this.isLoading = true;
      this.setPagination();
      this.initialize();
    },

    setPagination(page) {
      this.payload['page'] = (page) ? page : this.options.page;
      this.payload['pageSize'] = this.options.itemsPerPage;
      this.payload['sortBy'] = this.options.sortBy[0];
      this.payload['sortDesc'] = 0;

      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.payload['sortDesc'] = this.options.sortDesc[0] ? 1 : 0;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.cr-vision-card {
  background-color: white;
  padding: 24px 30px;
  border-radius: 6px;
  margin-top: 20px;
  h2 {
    color: #5D5D5D;
    font-size: 18px;
    font-weight: bold;
  }
  .title_colunm{
    font-weight: bold !important;
    text-align: center !important;
  }
}
</style>
<template>
  <label>
    {{ label }}
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      :item-text="itemText"
      :item-value="itemValue"
      :items="list"
      v-bind="$attrs" 
      v-on="$listeners"
      :chips="chip"
      :disabled="disabled"
      append-icon="mdi-chevron-down"
      :clearable="clearable"
    >
      <template #selection="{ attrs, item, selected }" v-if="chip">
        <v-chip v-bind="attrs" :input-value="selected" :disabled="disabled">
          {{ labelValue ? item[itemValue] : item[itemText] }}
        </v-chip>
      </template>
    </v-autocomplete>
  </label>
</template>

<script>
export default {
  name: "VvSingleSelect",
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number, Object],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    toDisableAll: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    labelValue: {
      type: Boolean,
    },
    chip: {
      type: Boolean,
      default: true,
    },
    showInactive: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredItems() {
      return this.items
        .filter((item) => item.active !== false || item[this.itemValue] === this.value);
    },
    list() {
      const items = this.showInactive ? [...this.items] : [...this.filteredItems];
      const toDisable = items.find((el) => el[this.itemValue] === this.toDisableAll);
      if (!toDisable) return items;
      return [toDisable, ...items.filter((el) => el[this.itemValue] !== this.toDisableAll)];
    },
  },
};
</script>

import axios from "@/plugins/axios";

export default function technologyTreeServiceFactory() {
    return {
        createTechTree(params) {
            return axios
                .post(`v2/trees/tech/`, params)
                .then(res => res.data)
        },
        editTechTree(params) {
            return axios
                .put(`v2/trees/tech/`, params)
                .then(res => res.data)
        },
        getFilterOptions() {
            return axios
                .get(`v2/options/tech-tree`)
                .then(res => res.data);
        },
        getFormOptions() {
            return axios
                .get(`v2/options/tech-tree-form`)
                .then(res => res.data);
        },
        getTechnologyTrees(params) {
            return axios
                .get(`v2/trees/tech`, { params: params })
                .then(res => res.data);
        },
        getJourney(id) {
            return axios
                .get(`v2/trees/tech/${id}`)
                .then(res => res.data);
        },
        getJourneyInativactionOptions(id) {
            return axios
                .get(`v2/trees/tech/journey-tree/${id}?fullMigration`)
                .then(res => res.data);
        },
        migrateJourney(payload) {
            return axios
                .post(`v2/trees/tech/migrate-journey`, payload)
                .then(res => res.data);
        },
        migrateTribe(payload) {
            return axios
                .post(`v2/trees/tech/migrate-tribe`, payload)
                .then(res => res.data);
        },
        migrateSquad(payload) {
            return axios
                .post(`v2/trees/tech/migrate-squad`, payload)
                .then(res => res.data);
        },
        checkTreeLevel(modelId, modelLevel) {
            return axios
                .get(`v2/trees/tech/check-tree-level/?modelId=${modelId}&modelLevel=${modelLevel}`)
                .then(res => res.data);
        }
    }
}
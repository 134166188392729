import axios from "@/plugins/axios";

export default function migrateProjectsServiceFactory() {
  return {
    getOptions(project_id) {
      return axios
        .get(`v2/project/${project_id}/subproject-migrate`)
        .then((res) => res.data);
    },
    migrateProjects(project_id, projects) {
      return axios
        .post(`v2/project/${project_id}/subproject-migrate`, projects)
        .then((res) => res.data);
    }
  }
}
<template>
    <div class="download-messages">
        <div class="message" v-show="!loading">
            <h1 :class="removed ? 'removed' : ''">
                {{ !removed ? 'Seu download começará em instantes...' : 'Download indisponível' }}
            </h1>
            <div class="removed" v-if="removed">
                <p>Este arquivo não está mais disponível para download</p>
                <p><span>Exporte novamente</span> a listagem para realizar o download.</p>
            </div>
            <p>Tipo de arquivo:<span>Listagem de {{type}}</span></p>
            <p>Data da solicitação:<span>{{date}}</span></p>
            <p>Data de expiração:<span>{{expire_date}}</span></p>
            <div class="image">
                <img src="../../assets/img/download_avaiable.png" v-if="!removed" />
                <img src="../../assets/img/download_unavaiable.png" v-else />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['removed', 'date', 'expire_date', 'type', 'loading'],
}
</script>

<style lang="scss">
    .download-messages {
        background-color: #ffffff;
        border-radius: 6px;
        padding: 30px;

        .message {
            padding: 40px 30px;
            border: 1px solid #DBDCDC;
            border-radius: 6px;

            h1 {
                color: #17117A;
                font-weight: 500;
                font-size: 24px;
                margin-bottom: 24px;

                &.removed {
                    color: #FF502D;
                }
            }
            
            div {
                &.removed {
                    p {
                        font-size: 14px;
                        color: #929497;
                        font-weight: 400;
                        margin-bottom: 0px;

                        &:last-child {
                            color: #6D6E70;
                            margin-bottom: 33px;
                            
                            span {
                                font-weight: 500;
                                margin-left: 0px !important;
                            }
                        }
                    }
                }
            }

            p {
                color: #929497;
                font-size: 14px;
                font-weight: 400;

                span {
                    color: #202121;
                    font-size: 16px;
                    margin-left: 8px;
                }
            }

            .image {
                width: 100%;
                text-align: center;
                margin-top: 40px;

                img {
                    width: 295px;
                    height: 282px;
                }
            }
        }
    }
</style>
import VvLoading from "@/components/VvLoading";
import SingleSelect from "@/components/common/VvSingleSelect";
import TextInput from "@/components/common/VvTextInput";
import VvRangeDate from "@/components/common/VvRangeDate";
import auditServiceFactory from "../../services/auditService";

const auditService = auditServiceFactory();

export default {
    name: "Epics",
    components: {
        VvLoading,
        SingleSelect,
        TextInput,
        VvRangeDate
    },
    props: {
        modulesId: {
            type: Number,
            default: null,
        },
    },
    data: () => ({
        isLoading: false,
        options: {
            epics: [],
            fields: [],
            users: [],
        },
        value: {
            epic: null,
            jira: null,
            field: null,
            date: [],
            user: null,
        },
        dialog: {
            showModal: false,
            showConfirmDialog: false,
        },
        errorList: [],
        today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
    }),
    methods: {
        async loadOptions() {
            try {
                this.isLoading = true;
                let result = await auditService.loadOptions();
                this.options = result;
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false;
            }
        },
        async openModalDownload() {
            this.isLoading = true;
            let payload = {
                module: this.modulesId,
            };
            if (this.value.epic !== null) {
                payload.epic = this.value.epic;
            }
            if (this.value.user !== null) {
                payload.user = this.value.user;
            }
            if (this.value.jira !== null) {
                payload.jira = this.value.jira;
            }
            if (this.value.field !== null) {
                payload.field = this.value.field;
            }
            if (this.value.date.length > 0) {
                payload.date = this.value.date;
            }
            try {
                await auditService.generateExcel(payload);
                this.dialog.showModal = true;
            } catch (error) {
                const {
                    response: { data: errors },
                } = error;
                let message = errors;
                if (error.response.status === 422) {
                    message = "Verifique os campos";
                }
                this.$showError(message);
                this.setErrorList(error);
            } finally {
                this.isLoading = false;
            }
        },
        clearFilter() {
            this.value.epic  = null;
            this.value.jira  = null;
            this.value.field = null;
            this.value.date  = [];
            this.value.user  = null;
            this.errorList = [];
        },
        getError(param) {
            return this.errorList[param];
        },
        setErrorList(error) {
            const {
                response: { data: errors },
            } = error;
            this.errorList = errors;
        },
        clearError(param) {
            delete this.errorList[param];
        },
    },
    mounted() {
        this.loadOptions();
    },
}
<template>
  <v-container :fluid="true" id="epic">

    <vv-loading :is-loading="isLoading"></vv-loading>

    <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />

    <v-card>
      <v-toolbar class="panel-header px-7 py-10 mobile-height-header">
        <div class="grid-8 gap-md">
          <div class="g-col-1 md:g-col-8">
            <label>ID Iniciativa</label>
            <h2>{{ getLabel(project.id) }}</h2>
          </div>
          <div class="g-col-5 md:g-col-8">
            <label>Nome da Iniciativa</label>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <h2 
                  class="project-name"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ getLabel(project.name) }}
                </h2>
              </template>
              <span>{{ getLabel(project.name) }}</span>
            </v-tooltip>
          </div>
          <set-project-step 
            v-if="project.id" 
            :statusWorkflowEncerramento="statusWorkflowEncerramento"
            :id="project.id"
            @setErrosChangeStep="setErrosChangeStep" />
        </div>
      </v-toolbar>
      <v-alert type="error" outlined class="pa-2 mt-6 mx-7" icon="mdi-alert-outline" id="currentStepAlert" v-if="currentStep == CONST_PROJECT_STEPS.CONGELADA">
        Iniciativas congeladas não permitem a edição de nenhum campo ou épico e os workflows pendentes de aprovação ficam congelados.
      </v-alert>
      <v-tabs class="options-tabs px-7" v-model="loadingTab">
        <v-tab @click="changeUri(project.id)">Iniciativa</v-tab>
        <v-tab :disabled="!ableEpicTab" @click="changeUri(project.id, 'visualizar')">
          Macro Temas 
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="ml-2" dense color="#DBDCDC">mdi-eye-outline</v-icon>
            </template>
            <span>Apenas visualização</span>
          </v-tooltip>
        </v-tab>
        <v-tab :disabled="!ableEpicTab" @click="changeUri(project.id, 'epicos')">Entregáveis</v-tab>
        <v-tab :disabled="!ableEpicTab" @click="changeUri(project.id, 'change-request')">
          Change Requests
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="ml-2" dense color="#DBDCDC">mdi-eye-outline</v-icon>
            </template>
            <span>Apenas visualização</span>
          </v-tooltip>
        </v-tab>
        <v-tab :disabled="!ableEpicTab" @click="changeUri(project.id, 'formalizacao')">
          Formalização de entrega
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="ml-2" dense color="#DBDCDC">mdi-eye-outline</v-icon>
            </template>
            <span>Apenas visualização</span>
          </v-tooltip>
        </v-tab>
        <v-tabs-items v-model="loadingTab" touchless>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <project 
                  :id="project.id" 
                  :name="project.name"
                  ref="projectComponent" 
                  @currentStep="currentStep = $event"
                  @statusWorkflowEncerramento="statusWorkflowEncerramento = $event"
                  @updateBreadcrumb="updateInsideBreadcrumb"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <list-sub-projects></list-sub-projects>
          </v-tab-item>
          <v-tab-item>
            <list-epic
                :create-epic-permission="listEpicOptions"
                :project="project"
            ></list-epic>
          </v-tab-item>
          <v-tab-item>
            <list-change-request
              :project="project"
            ></list-change-request>
          </v-tab-item>
          <v-tab-item>
            <list-delivery-formalization></list-delivery-formalization>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import Project from "./Project";
import ListEpic from "@/domains/epic/components/ListEpic";
import epicServiceFactory from "@/domains/epic/services/epicService";
import ListDeliveryFormalization from '@/domains/deliveryFormalization/components/ListDeliveryFormalization.vue';
import ListChangeRequest from '../../changeRequest/componets/ListChangeRequest.vue';
import ListSubProjects from '../../subProject/components/ListSubProjects/ListSubProjects.vue';
import SetProjectStep from "../components/SetProjectStep";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

const epicService = epicServiceFactory();

export default {
  name: "OverviewProject",
  components: {
    VvBreadcrumbs,
    VvLoading,
    Project,
    ListEpic,
    ListDeliveryFormalization,
    SetProjectStep,
    ListChangeRequest,
    ListSubProjects,
  },
  data: () => ({
    isLoading: false,
    loadingTab: null,
    project: {
      id: null,
      name: '',
      focalPoints: [],
      projectArea: [],
      impactedAreas: [],
      workflowId: null,
      aprovvedSubprojects: [],
      ideasApprovedList: []
    },
    listEpicOptions: null,
    savedDemandingAreas: [],
    ableEpicTab: false,
    statusWorkflowEncerramento: '',
    breadcrumb: [
      {
        text: 'Iniciativa',
        disabled: true,
      },
      {
        text: 'Cadastro',
        disabled: false,
      },
    ],
    currentStep: null
  }),
  mounted() {
    const {
      meta: {
        isCreating
      },
      params: {
        id
      }
    } = this.$route;
    if(isCreating) return;

    if (id) {
      this.loadingTab = 1;
    }
    this.startPage();
    this.openReferedTab();
  },
  watch: {
    loadingTab(tabId) {
      this.updateBreadcrumb(tabId);
    },
  },
  methods: {
    async startPage() {
      this.isLoading = true;
      const result = await epicService.loadSummary(this.$route.params.id);
      this.project.canShowEpicsFinish = result.canShowEpicsFinish;
      this.project.id = result.project.projectId;
      this.project.name = result.project.name;
      this.project.focalPoints = result.project.focalPoints;
      this.project.projectArea = result.project.projectArea;
      this.project.impactedAreas = result.project.impactedAreas;
      this.project.workflowId = result.project.workflowId;
      this.project.ideasApprovedList = result.project.ideas;
      this.listEpicOptions = result.createEpicPermission;
      this.ableEpicTab = result.epicTabPermission;
      this.project.aprovvedSubprojects = result.project.aprovvedSubprojects;
      this.isLoading = false;
    },
    openReferedTab() {
      this.loadingTab = this.$route.meta.tab;
    },
    changeUri(id, type = '') {
      let url = `/projeto/${id}/${type}`;
      this.$router.push(url);
    },
    getLabel(value) {
      const hasValue = ![null, undefined, ''].includes(value);
      switch (true) {
        case this.isLoading && !hasValue: return 'Carregando...'
        case !this.isLoading && !hasValue: return '-'
        default: return value
      }
    },
    /**
     * Recebe o callback do erro de mudança de step e gerencia para exibilo de acordo com a sua etapa destino
     * @param erros
     * @param targetStepId
     */
    setErrosChangeStep(erros, targetStepId) {
      if ([
            this.CONST_PROJECT_STEPS.DISCOVERY,
            this.CONST_PROJECT_STEPS.INCEPTION,
            this.CONST_PROJECT_STEPS.BACKLOG,
            this.CONST_PROJECT_STEPS.EM_PRENCHIMENTO
          ].includes(targetStepId)) {
        this.$refs.projectComponent.setStepErrors(erros, targetStepId);
      }
    },
    updateBreadcrumb(tabId) {
      switch (tabId) {
        case this.CONST_TAB_INITIATIVES.INITIATIVE:
          this.breadcrumb = [
            {
              text: 'Iniciativa',
              disabled: true,
            },
            {
              text: 'Cadastro',
              disabled: false,
            }
          ];
          break;
        case this.CONST_TAB_INITIATIVES.PROJECT:
          this.breadcrumb = [
            {
              text: 'Iniciativa',
              disabled: true,
            },
            {
              text: 'Macro Temas',
              disabled: false,
            }
          ];
          break;
        case this.CONST_TAB_INITIATIVES.EPICS:
          this.breadcrumb = [
            {
              text: 'Iniciativa',
              disabled: true,
            },
            {
              text: 'Macro Temas',
              disabled: true,
            },
            {
              text: 'Entregáveis',
              disabled: false,
            }
          ];
          break;
        case this.CONST_TAB_INITIATIVES.CHANGE_REQUEST:
          this.breadcrumb = [
            {
              text: 'Iniciativa',
              disabled: true,
            },
            {
              text: 'Macro Temas',
              disabled: true,
            },
            {
              text: 'Entregáveis',
              disabled: true,
            },
            {
              text: 'Change Requests',
              disabled: false,
            }
          ];
          break;
        case this.CONST_TAB_INITIATIVES.DELIVERY_FORMALIZATION:
          this.breadcrumb = [
            {
              text: 'Iniciativa',
              disabled: true,
            },
            {
              text: 'Macro Temas',
              disabled: true,
            },
            {
              text: 'Entregáveis',
              disabled: true,
            },
            {
              text: 'Formalização de Entrega',
              disabled: false,
            }
          ];
          break;
      }
    },
    updateInsideBreadcrumb(tabId) {
      switch (tabId) {
        case this.CONST_TAB_STEP_INITIATIVES.CADASTRO:
          this.breadcrumb = [
            {
              text: 'Iniciativa',
              disabled: true,
            },
            {
              text: 'Cadastro',
              disabled: false,
            }
          ];
          break;
        case this.CONST_TAB_STEP_INITIATIVES.DISCOVERY:
          this.breadcrumb = [
            {
              text: 'Iniciativa',
              disabled: true,
            },
            {
              text: 'Discovery',
              disabled: false,
            }
          ];
          break;
        case this.CONST_TAB_STEP_INITIATIVES.INCEPTION:
          this.breadcrumb = [
            {
              text: 'Iniciativa',
              disabled: true,
            },
            {
              text: 'Inception',
              disabled: false,
            }
          ];
          break;
        case this.CONST_TAB_STEP_INITIATIVES.ENCERRAMENTO:
          this.breadcrumb = [
            {
              text: 'Iniciativa',
              disabled: true,
            },
            {
              text: 'Encerramento',
              disabled: false,
            }
          ];
          break;
      }
    }
  },
}
</script>

<style lang="scss">
#epic {
  .v-card {
    box-shadow: none;
    margin-bottom: 40px;
  }
  header.panel-header {
    box-shadow: none;
    border: none;

    h2 {
      color: #6D6E70;

      &.project-name {
        width: calc(100% - 240px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 25px;
        max-height: 57px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media(max-width: 1264px) {
          width: 100%;
        }
      }
    }
    .v-toolbar__content {
      display: grid;
    }
  }
  .options-tabs {
    .v-tabs-bar {
      border-radius: 0;
    }
    .v-tab {
      text-transform: capitalize;
      color: #929497;
      font-size: 14px;
      &.v-tab--active {
        color: #124F63;
        border: 1px solid #00d3c3;
        border-radius: 4px 4px 0 0;
        border-bottom: none;
        position: relative;
        &::after {
          content: "";
          height: 2px;
          position: absolute;
          background: #ffffff;
          bottom: -2px;
          width: 100%;
        }
      }
    }
    .v-slide-group__content {
      border-bottom: 1px solid #00d3c3;
    }
  }
  .v-tabs-slider-wrapper {
    display: none;
  }
  .mobile-height-header {
    height: auto !important;
    @media (max-width: 600px) {
      .v-toolbar__content {
        height: auto !important;
      }
    }
  }
  .v-toolbar__content {
    @media(max-width: 1264px) {
      height: 220px !important;
    }
  }
}

</style>
import Vue from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './vuex'

axios.defaults.baseURL = process.env.VUE_APP_API;

axios.defaults.params = {};
axios.interceptors.request.use((config) => {

    let urlAction = config.url.split('/');
    if (!urlAction.includes('v2')) {
        config.baseURL += '/v1';
    }

    let token = '';
    let expiresIn = '';

    if (store.getters.isEmulated) {
        config.params['userEmuId'] = store.getters.userEmuled.id;
    }

    for (let localStorageKey in window.localStorage) {
        if (
            localStorageKey.indexOf(process.env.VUE_APP_OAUTH_CLIENT_ID) != -1 &&
            localStorageKey.indexOf('scopes') != -1
        ) {
            token = JSON.parse(window.localStorage.getItem(localStorageKey)).accessToken;
            expiresIn = JSON.parse(window.localStorage.getItem(localStorageKey)).expiresIn;
        }
    }

    let timestamp = Date.now().toString().substr(0,10);

    if (timestamp > expiresIn) {
        return new Promise((resolve) => {
            let vue = new Vue();
            vue.$msal.signIn();

            resolve(vue.$msal.acquireToken());
        }).then((data) => {
            config.headers.Authorization = 'Bearer ' + data.accessToken;

            return config;
        })
    } else {
        config.headers.Authorization =  'Bearer ' + token;

        return config;
    }
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        store.commit("logoff");
        let vue = new Vue();
        vue.$msal.signOut();
    }
    return Promise.reject(error);
});

Vue.use(VueAxios, axios);

export default axios;

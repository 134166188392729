<template>
  <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="960px"
  >
    <template v-slot:activator="{ on, attrs }" v-if="showButton">
      <v-btn
          class="btn-save-project"
          :class="classButton"
          :color="colorButton"
          :text="onlyTextButton"
          dark
          v-bind="attrs"
          @click="openDialog"
      >
        {{ labelButton }}
      </v-btn>
    </template>
    <v-card class="v-card-align">
      <v-card-title class="v-card-title-align">
        <v-row>
          <v-col :align="alignTitle" class="title-modal" :class="(labelBefore) ? 'label-before' : ''">
            {{ project_name }}
          </v-col>
          <v-btn
              class="btn-close-modal"
              fab
              x-small
              outlined
              color="grey"
              @click="dialog = false"
              :disabled="dialogMessage || isLoadingButton"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text class="v-card-text-align scroll-style">
        <v-list>
          <draggable v-model="epics" group="people" @start="drag=true" @end="drag=false" handle=".handle">
            <div v-for="(item, index) in epics" :key="index">
              <v-list-item class="priority-item">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="priority-number">{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <span class="priority-text">{{ item.name }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-icon class="priority-icon" @click="moveDown(index)" :disabled="dialogMessage">
                  mdi-chevron-down
                </v-icon>
                <v-icon class="priority-icon" @click="moveUp(index)" :disabled="dialogMessage">
                  mdi-chevron-up
                </v-icon>
                <v-icon class="handle" :disabled="dialogMessage">
                  mdi-cursor-move
                </v-icon>
              </v-list-item>
              <v-divider v-if="index < epics.length - 1" class="priority-divider"></v-divider>
            </div>
          </draggable>
        </v-list>
      </v-card-text>

      <v-row>
        <v-col cols="12" class="align-end" align="end">
          <v-btn
              class="btn-save-project"
              color="grey"
              @click="dialog = false"
              outlined
              :disabled="dialogMessage"
              :loading="isLoadingButton"
          >
            Cancelar
          </v-btn>
          <v-btn
              class="btn-save-project priority-btn"
              color="primary"
              @click="save"
              :disabled="dialogMessage"
              :loading="isLoadingButton"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>

    </v-card>

    <v-dialog v-model="dialogMessage" hide-overlay persistent width="400">
      <v-alert dense type="success" style="margin: 0">
        {{ textMessage }}
      </v-alert>
    </v-dialog>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "VvPriorityEpic",
  props: {
    project_name: String,
    project_id: Number,
    showButton: {
      type: Boolean,
      default: true
    },
    labelButton: {
      type: String,
      default: "Repriorizar Épicos",
    },
    colorButton: {
      type: String,
      default: "info",
    },
    onlyTextButton: {
      type: Boolean,
      default: false,
    },
    alignTitle: {
      type: String,
      default: "center",
    },
    labelBefore: {
      type: Boolean,
      default: false,
    },
    classButton: {
      type: String,
      default: "",
    },
  },
  components: {
    draggable
  },
  data() {
    return {
      isLoadingButton: false,
      dialogMessage: false,
      textMessage: '',
      dialog: false,
      old: [],
      epics: [],
    };
  },

  mounted: function () {
    this.getEpics();

    if (!this.showButton) {
      this.dialog = true;
    }
  },

  methods: {
    async openDialog() {
      await this.getEpics();
      this.dialog = true;
    },
    getEpics() {
      this.$emit("loading", false);
      this.axios
          .get('v2/project/' + this.project_id + '/epic/priority')
          .then((response) => {
            this.old = response.data;
            this.epics = response.data;
          })
          .catch(error => {
            console.error(error.response.data);
          })
          .finally(() => {
            this.$emit("loading", false);
          });
    },

    moveUp(index) {
      if (index > 0) {
        let el = this.epics[index];
        this.epics[index] = this.epics[index - 1];
        this.epics[index - 1] = el;

        this.$set(this.epics, index - 1, el);
      }
    },

    moveDown(index) {
      if (index !== -1 && index < this.epics.length - 1) {
        let el = this.epics[index];
        this.epics[index] = this.epics[index + 1];
        this.epics[index + 1] = el;

        this.$set(this.epics, index + 1, el);
      }
    },

    async save() {
      this.repriorise();
      this.isLoadingButton = true;
      const priorityData = { 'priorities' : this.epics }
      await this.axios.put('v2/project/' + this.project_id + '/epic/priority', priorityData)
        .then((response) => {
          this.dialogMessage = true;
          this.textMessage = response.data.message;

          this.$emit('changePriority', this.epics);

          setTimeout(() => {
            this.dialogMessage = false;
            this.textMessage = '';
            this.dialog = false;
          }, 2000);
        })
        .catch(error => {
          console.error(error.response.data);
        }).finally(() => {
          this.isLoadingButton = false;
        });
      this.dialog = false;
    },

    repriorise() {
      this.epics.map((epic, index) => {
        epic.priority = index + 1;
        return epic;
      });
    },
  },
}
</script>

<style scoped>
.btn-save {
  margin-right: 0 !important;
}

.v-card-align {
  padding: 40px;
}

.v-card-text-align {
  height: 300px;
}

.v-card-title-align {
  padding-right: 12px !important;
}

.priority-item:hover {
  background-color: rgba(93, 93, 93, 0.18);
}

.title-modal {
  color: var(--text-gray);
  font-size: var(--font-size-h2);
  font-weight: bold;
}

.priority-number {
  color: var(--text-gray);
  font-size: var(--font-size-h2);
  font-weight: bold;
  padding-right: 30px;
}

.priority-text {
  color: var(--text-gray);
  font-size: var(--font-size-p);
}

.priority-icon {
  margin-right: 15px;
}

.priority-divider {
  margin-left: 10px;
}

.priority-btn {
  margin-right: 0 !important;
}

.label-before.title-modal::before {
  content: 'Nome do Macro Tema';
  font-size: 10px;
  color: #929497;
  display: block;
  height: 20px;
}
</style>

<template>
  <div>
    <template v-if="canShowFormalization">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            outlined
            v-bind="attrs"
            v-on="on"
            class="ml-4"
          >
            <v-tooltip top max-width="277">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-1"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>
                <p>"Formalização de entrega" deve ser usado para concluir o épico.</p>
                <p>Necessário alterar e salvar o "Estágio" do épico para "Pronto para formalizar", além de que iniciativas com "Capex" exigem preenchimento do campo "Ordem de Investimento" no épico para habilitar.</p>
              </span>
            </v-tooltip>
            Formalização de Entrega <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item link class="px-6" @click="openModal" :disabled="!createDeliveryFormalization">
            <v-list-item-title>
              Concluir entrega
            </v-list-item-title>
          </v-list-item>
          <v-list-item link class="px-6" @click="showFormalization" :disabled="!existDeliveryFormalization">
            <v-list-item-title>
              Consultar formalização
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-else>
      <v-tooltip top max-width="277">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            outlined
            v-bind="attrs"
            v-on="on"
            class="ml-4 personal-disabled"
          >
            <v-icon
              dark
              v-bind="attrs"
              v-on="on"
              small
              class="mr-1 personal-disabled"
            >
              mdi-information-outline
            </v-icon>
            Formalização de Entrega <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>
          <p>"Formalização de entrega" deve ser usado para concluir o épico.</p>
          <p>Necessário alterar e salvar o "Estágio" do épico para "Pronto para formalizar", além de que iniciativas com "Capex" exigem preenchimento do campo "Ordem de Investimento" no épico para habilitar.</p>
        </span>
      </v-tooltip>
    </template>    

    <v-dialog
        v-model="modalOpen"
        width="960"
        persistent
        scrollable
    >
      <v-card id="modalDeliveryFormalization">
        <v-toolbar flat>
          <v-toolbar-title>Formalização de Entrega</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                @click="closeModal"
            ><v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-4 px-4">
          <form-delivery-formalization
              :options="options"
              :item="item"
              :focal-points-tap="focalPointsTap"
              :project-area="projectArea"
              @deliveryFormalizationsSaved="closeModal"
              ref="formDF"
          ></form-delivery-formalization>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="$refs.formDF.confirmationDialog.value=true"
          >
            Submeter entrega
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormDeliveryFormalization from "./FormDeliveryFormalization";

export default {
  name: "ModuleDeliveryFormalization",
  components: {
    FormDeliveryFormalization,
  },
  props: {
    item: {
      type: Object,
    },
    options: {
      type: Object,
    },
    focalPointsTap: {
      type: Array,
    },
    projectArea: {
      type: Number,
    },
    projectId: {
      type: Number,
    }
  },
  data: () => ({
    moment: require("moment"),
    modalOpen: false,
  }),
  computed: {
    createDeliveryFormalization() {
      return this.item.permissions?.createDeliveryFormalization;
    },
    canShowFormalization() {
      return this.item.permissions?.canShowDeliveryFormalization;
    },
    existDeliveryFormalization() {
      return this.item.permissions?.existDeliveryFormalization;
    }
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    showFormalization() {
      const formalizationId = this.item.latestDeliveryFormalizationId;
      const projectId = this.projectId;
      if(formalizationId && projectId) {
        this.$router.push(`/projeto/${projectId}/formalizacao/${formalizationId}`)
      }  
    },
  },
}
</script>

<style lang="scss">
#modalDeliveryFormalization {
  h3 {
    font-size: 15px;
    color: #6d6e70;
    &::before {
      content: "\2022";
      margin-right: 5px;
      font-size: 18px;
      position: relative;
      top: 1px;
    }
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

  p {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    text-align: left;
    color: #929497;
  }

  textarea {
    font-size: 14px;
  }
}

.personal-disabled {
  color: #DBDCDC !important;
  cursor: not-allowed;
}
</style>
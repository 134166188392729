import businessTreeServiceFactory from "../../services/businessTreeService";
const businessTreeService = businessTreeServiceFactory();

export default {
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        stakeholder: {
            type: Boolean,
            default: false
        },
        focalPoint: {
            type: Boolean,
            default: false
        },
        focalPoints: {
            type: Array,
            default: () => ([])
        },
        inativeFocalPointOptions: {
            type: Object,
            default: () => ({})
        },
        inativeStakeholderOptions: {
            type: Object,
            default: () => ({})
        },
        stakeholderDetails: {
            type: Object,
            default: () => ({})
        },
        area: {
            type: Object,
            default: () => ({})
        },
        focalPointIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            stakeholderSubstitute: {
                id: null,
                name: '',
                focalPoints: []
            },
            substituteFocalPointOptions: [],
            fromTo: {
                currentStakeholderId: null,
                newStakeholderId: null,
                focalPoints: [
                    {
                        currentFocalPointId: null,
                        newFocalPointId: null
                    }
                ]
            }
        }
    },
    methods: {
        cancel() {
            this.$emit("close");
            if (!this.stakeholder) {
                this.$emit("switchStakeholder");
            } else {
                this.$emit("switchFocalPoint");
            }
        },
        handleOptions() {
            this.substituteFocalPointOptions = [];
            
            this.inativeStakeholderOptions.stakeholders.map(stakeholder => {
                if (stakeholder.id === this.fromTo.newStakeholderId) {
                    stakeholder.focalPoints.map(focalPoint => {
                        this.substituteFocalPointOptions.push(focalPoint);
                    })
                }
            });
        },
        clearData() {
            this.fromTo = {
                currentStakeholderId: null,
                newStakeholderId: null,
                focalPoints: [
                    {
                        currentFocalPointId: null,
                        newFocalPointId: null
                    }
                ]
            }
        },
        async save() {
            this.fromTo.currentStakeholderId = this.stakeholderDetails.data.id;

            let obj = {
                id: this.area.id,
                name: this.area.name,
                active: this.area.area_status,
                stakeholders: this.area.stakeholders_id,
                fromTo: this.fromTo
            }

            try {
                this.$emit('loading', true);
                const result = await businessTreeService.editStakeholder(obj);
                this.$showSuccess(result.message || 'Árvore editada com sucesso!');
                this.$emit('resultInative', result.area);
                this.$emit('closeModal');
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$emit('loading', false);
            }
        }
    },
    watch: {
        stakeholderDetails: {
            handler(value) {
                this.fromTo.focalPoints = [];
                value.data.focalPoints.map((focalPoint) => {
                    this.fromTo.focalPoints.push({
                        currentFocalPointId: focalPoint.id,
                        newFocalPointId: null
                    })
                })
            },
            deep: true
        }
    }
}
<template>
  <v-tabs
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    color="#2A5F50"
    background-color="white"
    class="vv-tabs"
    @change="$emit('input', $event)"
  >
    <slot></slot>
  </v-tabs>
</template>

<script>
export default {
  name: "VvTabs",
  inheritAttrs: false,
  props: {
    value: [Number, String],
  },
};
</script>

<style lang="scss">
.vv-tabs {
  .v-slide-group__content {
    border-bottom: 1px solid #00d3c3 !important;
  }
  .v-tab {
    text-transform: capitalize;
    &.v-tab--active {
      color: #124f63;
      border: 1px solid #00d3c3;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #fff;
      position: relative;
    }
  }
  .v-tabs-slider-wrapper {
    display: none;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Closure"}},[_c('h2',[_vm._v("Histórico de encerramentos")]),_c('v-data-table',{staticClass:"data-table-closures",attrs:{"headers":_vm.headers,"items":_vm.closures,"single-expand":true,"expanded":_vm.expandedClosure,"mobile-breakpoint":0,"no-data-text":'Não há histórico de encerramento cadastrados',"itemsPerPage":_vm.closures.length,"hide-default-footer":"","item-key":"id","dense":""},on:{"update:expanded":function($event){_vm.expandedClosure=$event},"click:row":_vm.openDetails},scopedSlots:_vm._u([{key:"item.requesterUser",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-area"},[_vm._v(_vm._s(item.requesterUser))])]}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-area",staticStyle:{"width":"157px"}},[_vm._v(_vm._s(item.area))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDateForBr(item.date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"status-closure",class:_vm.treatClass(item.status)},[_vm._v(_vm._s(item.status))])]}},{key:"item.focalPointStatus",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"status-closure",class:_vm.treatClass(item.focalPointStatus)},[_vm._v(_vm._s(item.focalPointStatus))])]}},{key:"item.stakeholderStatus",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"status-closure",class:_vm.treatClass(item.stakeholderStatus)},[_vm._v(_vm._s(item.stakeholderStatus))])]}},{key:"item.expandButton",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"#6D6E70"}},[(item.id === _vm.toggleById)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('closure-details',{attrs:{"item":_vm.closure}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="details-delivery-formalizations" v-for="(item, i) in items" :key="i">
      <div class="grid-1 mb-10 created" v-if="item.createdUserName != null" >
        <label>Criado Por</label>
        <h2>{{item.createdUserName}}</h2>
      </div>
      <div class="grid-10 first">
        <div class="g-col-3">
          <div class="dates">
            <div>
              <div class="date">
                <span>Data do envio da Formalização de entrega</span>
                <p>{{ formattedDateForBr(item.createDate) }}</p>
              </div>
              <div class="date">
                <span>{{ item.endDate != null ? "Data do encerramento (última aprovação)" : "Data de Reprovação (última reprovação)" }}</span>
                <p>{{ dateDeliveryOrReprove(item.endDate, item.refusedDate) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="g-col-7">
          <div class="comment">
            <label>Comentários sobre a entrega</label>
            <v-textarea counter v-model="item.comment" filled disabled></v-textarea>
          </div>
        </div>
      </div>
      <h2>Evidências</h2>
      <div class="evidences">
        <template v-if="item.evidences.length > 0">
          <button 
            v-for="(evidence, i) in item.evidences"
            :key="i"
            @click="downloadEvidence(evidence.id)"
          >
            <v-icon>mdi-file-outline</v-icon>
              {{ evidence.fileName }}
            <v-icon>mdi-tray-arrow-down</v-icon>
          </button>
        </template>
        <template v-else>
          Nenhuma evidência anexada
        </template>
      </div>
      <template v-if="Object.keys(item.aprovals).length > 0">
        <h2>Workflow</h2>
        <list-approvers :approvers="item.aprovals"></list-approvers>
      </template>
      <refused-alert
        v-if="item.refusedCause != null"
        :text="'Essa Formalização de Entrega foi rejeitada'"
        :refusedCause="item.refusedCause"
        :refusedDate="item.refusedDate" />
      <template v-if="item.message.length > 0">
        <h2>Dúvidas</h2>
        <messages-delivery-formalization 
          :index="i"
          :messages="item.message" 
          :disable-response="disableResponse"
          @messageData="messageData = $event"
        ></messages-delivery-formalization>
      </template>
    </div>
    <vv-alert 
      v-model="alert.show" 
      :message="alert.message"  
      :type="alert.type" 
      :dismissible="alert.dismissible" />
  </div>
</template>

<script>
import VvAlert from "@/components/common/VvAlert";
import ListApprovers from '../../shared/ListApprovers.vue'
import MessagesDeliveryFormalization from '../../shared/Message.vue'
import RefusedAlert from '../../shared/RefusedAlert.vue'
import deliveryFormalizationServiceFactory from "@/domains/deliveryFormalization/services/deliveryFormalizationService";

const deliveryFormalizationService = deliveryFormalizationServiceFactory();

export default {
  components: {
    VvAlert,
    ListApprovers,
    MessagesDeliveryFormalization,
    RefusedAlert
  },
  props: {
    items: Array,
    formalization: {
      type: Object,
      default: () => ({}),
    },
    disableResponse: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    loading(event) {
      this.$emit('loading', event);
    },
    errorAlert(event) {
      this.$emit('error', event);
    },
    messageData(value) {
      this.sendMessage(value);
    }
  },
  data() {
    return {
      loading: false,
      errorAlert: false,
      messageData: {},
      sendStatus: 0,
      sendResponseMessage: '',
      alert: {
        show: false,
        message: '',
        type: 'info',
        dismissible: false,
      },
    };
  },
  methods: {
    async downloadEvidence(id) {
      this.loading = true;
      const evidence = await deliveryFormalizationService.downloadEvidence(id);
      if (evidence.status === 200) {
        this.createAndDownloadBlobFile(this.base64ToArrayBuffer(evidence.data.file), evidence.data.filename, evidence.data.extension);
      } else {
        this.errorAlert = true;
      }
      this.loading = false;
    },
    async sendMessage(messageData) {
      this.loading = true;

      const send = await deliveryFormalizationService.sendMessage({
        message: messageData.message,
        parentId: messageData.parentId,
        formalizationId: this.formalization.id
      });

      if (send.status === 200) {
        this.alert = {
          show: true,
          message: send.data.message,
          type: 'success',
        };

        setTimeout(() => {
          this.alert.show = false;
        }, 3000);

        this.$emit('update-formalization', send.data.formalization);
      } else {
        this.errorAlert = false;
      }

      this.loading = false;
    },
    dateDeliveryOrReprove(endDate, refusedDate) {
      if (endDate != null || refusedDate != null) {
        return endDate != null ? this.formattedDateForBr(endDate) : this.formattedDateForBr(refusedDate)
      } else {
        return 'dd/mm/yyyy'
      }
    }
  }
}
</script>

<style lang="scss">
.details-delivery-formalizations {

  .created {
    h2 {
      font-weight: 500;
      font-size: 16px;
      color: #202121;
      margin: 0px;
    }
  }

  h2 {
    margin: 40px 0px 24px;
    font-weight: 500;
    font-size: 16px;
    color: #6D6E70;
  }

  .first {
    margin-bottom: -29px;

    .dates {
      border: 1px solid #DBDCDC;
      border-radius: 6px;
      padding: 24px 16px 0px;

      .date {
        margin-bottom: 30px;

        &:nth-child(3n) {
          margin-bottom: 15px;
        }

        span {
          font-size: 10px;
          font-weight: 400;
          color: #6D6E70;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          color: #202121;
          margin-bottom: 0px;
        }

        .v-input--selection-controls {
          margin-top: 0px;
        }
      }
    }

    .comment {
      padding: 0px 30px;

      .v-textarea {
        margin-top: 12px !important;

        .v-input__slot {
          height: 136px;
        }
      }
    }
  }

  .evidences {
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    color: #929497;

    button {
      border: 1px solid #DBDCDC;
      border-radius: 6px;
      padding: 8px 16px;
      margin-right: 12px;
      margin-bottom: 12px;

      i {
        margin-right: 10px;
        color: #DBDCDC;
        font-size: 20px;

        &:last-child {
          margin-right: 0px;
          margin-left: 10px;
          color: #111827;
        }
      }
    }
  }
}
</style>
<template>
<v-card
    class="fill-width pa-4"
    outlined
    >
    <label v-if="label">{{label}}</label>
    <div 
        v-for="(item, id) in content"
        :key="id"
        class="d-flex flex-wrap"
    >
      <v-checkbox
          v-model="item.selected"
          :label="item.name"
          class="pt-0 mt-1 mr-3"
          style="flex-basis: min(300px, 100%)"
          hide-details
          @change="onCheckbox(id)"
      />
      <v-slider
          dense
          hide-details
          :step="Number(step)"
          thumb-label
          ticks
          @change="emitValue"
          :disabled="!item.selected"
          v-model="item.value"
          style="flex-basis: min(300px, 100%)"
      />
    </div>
</v-card>
</template>

<script>
export default {
    name: "VvMultipleSliders",
    props: {
    label: {
      type: String
    },
    value: {
      type: Object
    },
    items: {
      type: Array,
      required: true
    },
    step: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
       localContent: []
    }
  },
  watch: {
    value(newValue) {
      this.content = this.mapIn(newValue)
    },
  },
  computed: {
    content: {
      get() {
        return this.mapOut()
      },
      set(value) {
        this.localContent = value
      }
    }
  },
  methods: {
    onCheckbox(id) {
      const item = this.content[id]
      if(item.selected && item.value <= 0) item.value = 100
      this.emitValue()
    },
    emitValue() {
      const data = this.content
        .reduce((accumulator, {id , value, selected}) => {
          if(selected === true) {
            accumulator[id] = value / 100
          }
          return accumulator
        }, {})
      this.$emit("input", data)
    },
    mapOut() {
      const data = this.items
        .map(({id, name}, index) => {
          const {selected, value} = this.localContent[index] || {}
          return {
            id,
            name,
            selected: selected || false,
            value: value || 0
          }
        })
      this.localContent = data
      return data
    },
    mapIn(value) {
      value = value || {}
      const data = Array(this.items.length)
        .fill({
          selected: false,
          value: 0,
        })
        .map((_,id) => {
          const findByKey = id => ([key,]) => Number(key) === id
          const findItem = id => Object.entries(value)
            .find(findByKey(id))
          const findIndex = id => Object.entries(value)
            .findIndex(findByKey(id))
          const item = findItem(this.getItemsIndex(id)) || []
          const index = findIndex(this.getItemsIndex(id))
          return {
            id: this.getItemsIndex(id),
            name: this.items[id].name,
            selected: index > -1,
            value: Number(item[1]) * 100 || 0
          }
        })
      this.localContent = data
      return data
    },
    getItemsIndex(value) {
      return this.items[value].id
    }
  }
}
</script>
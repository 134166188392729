import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from './plugins/axios';
import router from '@/router';
import mixin from "./plugins/mixin";
import "./plugins/vuetify-money.js";
import msal, {msalMixin} from 'vue-msal';
import {globalConst} from './plugins/global-constants';
import store from './plugins/vuex';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(msal, {
    auth: {
        clientId: process.env.VUE_APP_OAUTH_CLIENT_ID,
        tenantId: process.env.VUE_APP_OAUTH_TENANT_ID,
        redirectUri: process.env.VUE_APP_OAUTH_REDIRECT_URI
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    },
    graph: {
        callAfterInit: true,
        endpoints: {
            photo: {url: '/me/photo/$value', responseType: 'blob', force: true}
        }
    }
});

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: "mdi"
})

Vue.config.productionTip = false;

const publicPages = ['/'];

router.beforeEach((to, from, next) => {
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = JSON.parse(window.localStorage.getItem('vuex')).user.authenticated;

    if (authRequired && !loggedIn) {
        next('/');
    } else {
        next();
    }
    
})

let environment = '';
console.log('environment raw: [' + process.env.VUE_APP_AMB + ']');
if (['DEV', 'SIT', 'HLG', 'STG'].indexOf(process.env.VUE_APP_AMB) > -1) {
    console.log('Ambiente não Produtivo');
    environment = `[ ${process.env.VUE_APP_AMB} ] `;
}

let defaultTitle = 'Central Vai Via ' + environment + '- ';

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = defaultTitle + to.name || defaultTitle;
    });
});

new Vue({
    mixins: [msalMixin],
    router,
    store: store,
    vuetify,
    async created() {
        if (!await this.$msal.isAuthenticated()) {
            this.$msal.signIn();
        }

        let token = await this.$msal.acquireToken();
        window.localStorage.token = token.accessToken;

        this.$store.commit('login', this.$msal.data);
        this.validandoToken = setInterval(() => this.verificaToken(), 2000);
    },
    mixin,
    axios,
    globalConst,
    render: h => h(App)
}).$mount('#app')

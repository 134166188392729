import Vue from 'vue';

const GLOBAL_CONSTANTS = {
    CONST_DEFAULT_AREA: 1,
    CONST_MODULE: {
        'WORKFLOW': 1,
        'INICIATIVA': 2,
        'ENTREGAVEIS': 3,
        'WORKFLOW_CR': 4,
        'WORKFLOW_FE': 5, //formalização de entrega
        'CR_AREA_DEPENDENCIAS': 6,
        'DELEGACAO': 7,
        'IDEAS': 8,
        'CHANGE_REQUEST': 9,
        'DELIVERY_FORMALIZATION': 10,
        'PROJETOS': 11,
        'ENCERRAMENTO': 12,
        'TREE_BUSINESS': 13,
        'TREE_TECH': 14,
        'AUDITORIA': 16,
    },

    CONST_NOTIFICATION_TYPE: {
        'AVISO': 1,
        'APROVACAO': 2,
        'REPROVACAO': 3,
        'QUESTIONAMENTO': 4,
        'SOLICITACAO': 5,
        'RESPOSTA': 6,
    },

    CONST_STAGE: {
        'BACKLOG': 1,
        'DESENVOLVIMENTO': 2,
        'HOMOLOGACAO': 3,
        'EM_IMPLANTACAO': 4,
        'OPERACAO_ASSISTIDA': 5,
        'ROLLOUT': 6,
        'EM_PLANEJAMENTO': 7,
        'CONCLUIDO': 8,
        'SEM_INFORMACAO': 9,
        'CANCELADO': 10,
    },

    CONST_STATUS: {
        'SEM_INFORMACAO': 1,
        'BACKLOG': 2,
        'NAO_INICIADO': 3,
        'ATRASADO': 4,
        'NO_PRAZO': 5,
        'ATENCAO': 6,
        'CANCELADO': 7,
        'CONCLUIDO': 8,
        'REPLANEJADO_PRAZO': 9,
        'REPLANEJADO_ATENCAO': 10,
        'REPLANEJADO_FORMALIZADO_NEGOCIO': 11,
        'REPLANEJADO_ATRASADO': 12,
        'NAO_APLICAVEL': 13
    },

    CONST_USER_TYPE: {
        'VSM': 1,
        'GPM': 2,
        'STAKEHOLDER': 3,
        'PONTOS_FOCAIS': 4,
        'GERENTES_ESCRITORIO_TRANSFORMACAO': 5,
        'USUARIOS_GERAIS': 6,
        'USUARIO_MASTER': 7,
        'PM_PO': 8,
        'CURADORIA': 10,
        'RESPONSAVEL_PELO_EPICO': 11,
    },

    CONST_WORKFLOW: {
        'TAP_PREENCHIMENTO': 1,
        'AGUARDANDO_APROVACAO': 2,
        'APROVADO_GESTOR': 3,
        'APROVADO_DIRETOR': 4,
        'REJEITADO': 5,
        'AGUARDANDO_REVISAO_SOLICITANTE': 6,
        'BACKLOG': 7,
        'EM_PLANEJAMENTO': 8,
        'EM_DESENVOLVIMENTO': 9,
        'CONCLUIDO': 10,
        'CANCELADO': 11,
    },

    CONST_TREES: {
        'BUSINESS': 0,
        'TECNOLOGIC': 1,
    },

    CONST_CHANGE_REQUEST_CLASSIFICATION: {
        'ATRASO': 1,
        'ADICAO_ESCOPO': 2,
        'REPRIOZICAO_DEMANDA_LEGAL': 3,
        'REPRIOZICAO_AREA_RESPONSAVEL': 4,
        'REPRIOZICAO_IMPACTO_AREA': 5,
        'DESPRIORIZACAO': 6,
        'CANCELAMENTO': 7
    },

    CONST_CHANGE_REQUEST_IMPACT: {
        'ESCOPO': 1,
        'NOVAS_DEPENDENCIAS': 2,
        'PRAZO': 3,
        'CUSTO': 4,
        'CANCELAMENTO': 5
    },

    CONST_CHANGE_REQUEST_STATUS: {
        'PENDENTE_APROVACAO': 1,
        'APROVADO': 2,
        'REPROVADO': 3,
        'PENDENTE_ALTERACAO': 4,
    },

    CONST_NIVEL_TREE_TECH_DISABLE: {
        'JORNEY': 1,
        'TRIBE': 2,
        'SQUAD': 3,
    },

    CONST_DELEGATIONS_STATUS: {
        'AGUARDANDO': 1,
        'EXPIRADO': 2,
        'ATIVADO': 3,
        'SUSPENSO': 4,
    },

    CONST_TIPO_LISTAGEM_EPICOS: {
        'CONCLUIDOS': 0,
        'ATIVOS': 1,
    },

    CONST_WORKFLOW_IDEA: {
        'EM_PREENCHIMENTO': 1,
        'AGUARDANDO_APROVACAO': 2,
        'APROVADO_CURADOR': 3,
        'APROVADO_COMITE': 4,
        'REJEITADO_CURADOR': 5,
        'REJEITADO_COMITE': 6,
        'AGUARDANDO_ALTERACAO': 7,
    },

    CONST_PROJECT_ITEM_DISABLE_OTHERS: {
        'IT_AREAS': 4,
        'IMPACT_AREAS': 1,
        'STRATEGIC_INDICATORS': 6,
        'SPECIAL_PROJECT': 6,
    },

    CONST_SUBPROJECT_STATUS: {
        'AGUARDANDO': 1,
        'APROVADO': 2,
        'REPROVADO': 3,
    },

    CONST_PROJECT_INDICATORS_FREQUENCY: {
        'MENSAL': 1,
        'TRIMESTRAL': 2,
        'ANUAL': 3
    },

    CONST_PROJECT_STEPS: {
        'CADASTRO': 1,
        'DISCOVERY': 2,
        'INCEPTION': 3,
        'BACKLOG': 4,
        'EM_PRENCHIMENTO': 5,
        'CONCLUIDO': 6,
        'CANCELADO': 7,
        'ENCERRAMENTO': 8,
        'CONGELADA': 9
    },

    CONST_TYPE_EPICS: {
        'TECNOLOGIA': 1,
        'NEGOCIO': 2,
    },

    CONST_TAB_INITIATIVES: {
        'INITIATIVE': 0,
        'PROJECT': 1,
        'EPICS': 2,
        'CHANGE_REQUEST': 3,
        'DELIVERY_FORMALIZATION': 4,
    },

    CONST_TAB_STEP_INITIATIVES: {
        'CADASTRO': 1,
        'DISCOVERY': 2,
        'INCEPTION': 3,
        'ENCERRAMENTO': 4,
    },

    CONST_REF_TREE: {
        'BUSINESS': -1,
        'TECH': -2,
    },

    CONST_DEFAULT_OPTIONS_ITENS: {
        'AREA': 1,
    }
};

Object.entries(GLOBAL_CONSTANTS).forEach(([key, value]) => {
    Vue.prototype[key] = value;
});

export default GLOBAL_CONSTANTS;
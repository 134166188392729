<template>
  <div id="responsableForm">
    <div class="grid-3 gap-md mt-7">
      <div class="grid-3  gap-md g-col-3" v-if="item.epicTypeId === CONST_TYPE_EPICS.TECNOLOGIA">
        <div class="md:g-col-4">
          <label>Jornada TI</label>
          <v-autocomplete
            :items="removeDisabledToOptions(typeof options != 'undefined' ? options.journeys : [], item.journeysId)"
            item-disabled="disable"
            :disabled="!canEditFieldsPermission"
            item-text="name"
            item-value="id"
            v-model="item.journeysId"
            append-icon="mdi-chevron-down"
            :error-messages="getError('journeysId')"
            @change="changeJourney(true)"
            @input="cleanError('journeysId')"
          >
          </v-autocomplete>
        </div>
        <div class="md:g-col-4">
          <label>VSM TI</label>
          <v-autocomplete
            :items="removeDisabledToOptions(innerOptions.vsm, item.vsmId)"
            :disabled="!canEditFieldsPermission"
            item-text="name"
            item-value="id"
            item-disabled="disable"
            v-model="item.vsmId"
            append-icon="mdi-chevron-down"
            :error-messages="getError('vsmId')"
            @change="changeJourney(true)"
            @input="cleanError('vsmId')"
            no-data-text="Selecione uma Jornada"
          >
          </v-autocomplete>
        </div>
        <div class="md:g-col-4">
          <label>sr GPM</label>
          <v-autocomplete
            :items="srGpmOptions"
            v-model="item.seniorGpmId"
            item-text="name"
            item-value="id"
            item-disabled="disable"
            append-icon="mdi-chevron-down"
            disabled
          >
          </v-autocomplete>
        </div>
        <div class="md:g-col-4">
          <label>Tribo TI</label>
          <v-autocomplete
            :items="removeDisabledToOptions(innerOptions.tribes, item.tribesId)"
            :disabled="!canEditFieldsPermission"
            item-text="name"
            item-value="id"
            item-disabled="disable"
            v-model="item.tribesId"
            append-icon="mdi-chevron-down"
            no-data-text="Selecione uma Jornada"
            @change="changeTribe(true)"
            :error-messages="getError('tribesId')"
            @input="cleanError('tribesId')"
          >
          </v-autocomplete>
        </div>
        <div class="md:g-col-4">
          <label>GPM TI</label>
          <v-autocomplete
            :items="innerOptions.gpms"
            :disabled="!canEditFieldsPermission"
            item-text="name"
            item-value="id"
            item-disabled="disable"
            v-model="item.gpmId"
            append-icon="mdi-chevron-down"
            no-data-text="Selecione uma Tribo"
            :error-messages="getError('gpmId')"
            @input="cleanError('gpmId')"
          >
          </v-autocomplete>
        </div>
        <div class="md:g-col-4">
          <label>Squad</label>
          <v-autocomplete
            :items="removeDisabledToOptions(innerOptions.squads, item.squadKey, 'squad_key')"
            :disabled="!canEditStakeholderFieldsPermission"
            item-text="name"
            item-value="squadKey"
            item-disabled="disable"
            v-model="item.squadKey"
            append-icon="mdi-chevron-down"
            chips
            @change="changeSquad()"
            :error-messages="getError('squadKey')"
            @input="cleanError('squadKey')"
            no-data-text="Selecione uma Tribo"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                @click="select"
                label
                class="chip-green"
              >
                {{ item.name }}
                <v-icon
                  v-if="false"
                  @click="removeSquad()"
                  size="16"
                  class="remove-chip"
                >mdi-close
                </v-icon>
              </v-chip>
              </template>
          </v-autocomplete>
        </div>
        <div class="md:g-col-4">
            <label>PM/PO</label>
            <v-autocomplete
                    :items="innerOptions.pmpos"
                    :disabled="false"
                    item-text="name"
                    item-value="usersId"
                    item-disabled="disable"
                    v-model="visualPmpo"
                    multiple
                    chips
                    readonly
                    append-icon="mdi-chevron-down"
                    no-data-text="Nenhum PM/PO"
                    :error-messages="getError('pmpo')"
                    @input="cleanError('pmpo')"
            >
                <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            @click="select"
                            label
                            class="chip-green"
                    >{{ item.name }}
                    </v-chip>
                </template>
            </v-autocomplete>
        </div>
      </div>
      <div class="grid-3 gap-md g-col-3">
        <div class="md:g-col-4">
          <label>Área de negócio envolvida</label>
          <v-autocomplete
            :items="removeDisabledToOptions(typeof options != 'undefined' ? options.areaResponsible : [], item.demandingArea)"
            :disabled="!canEditFieldsPermission"
            item-text="name"
            item-value="id"
            item-disabled="disable"
            v-model="item.demandingArea"
            name="demandingArea"
            id="demandingArea"
            append-icon="mdi-chevron-down"
            :error-messages="getError('demandingArea')"
            @input="cleanError('demandingArea')"
            @change="changeAreaResponsible(true)"
          ></v-autocomplete>
        </div>
        <div class="grid-2 gap-md md:g-col-3 g-col-2" v-if="item.epicTypeId === CONST_TYPE_EPICS.NEGOCIO">
          <div class="md:g-col-4">
            <label>Stakeholder</label>
            <v-autocomplete
              :items="removeDisabledToOptions(innerOptions.stakeholders, item.stakeholderId)"
              :disabled="!canEditFieldsPermission"
              item-text="name"
              item-value="id"
              item-disabled="disable"
              v-model="item.stakeholderId"
              append-icon="mdi-chevron-down"
              no-data-text="Selecione um Stakeholder"
              :error-messages="getError('stakeholderId')"
              @input="cleanError('stakeholderId')"
              @change="changeStakeholder(true)"
            >
            </v-autocomplete>
          </div>
          <div class="md:g-col-4">
            <label>Ponto focal</label>
            <v-autocomplete
              :items="removeDisabledToOptions(innerOptions.focalPoints, item.focalPoints)"
              :disabled="!canEditFieldsPermission"
              item-value="id"
              item-text="name"
              item-disabled="disable"
              v-model="item.focalPoints"
              append-icon="mdi-chevron-down"
              no-data-text="Selecione Pontos Focais"
              chips
              multiple
              :error-messages="getError('focalPoints')"
              @input="cleanError('focalPoints')"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  class="chip-green"
                >{{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </div>
        </div>
        <div class="md:g-col-4" v-if="item.epicTypeId === CONST_TYPE_EPICS.NEGOCIO">
          <label>Responsável pelo épico</label>
          <v-autocomplete
            :items="typeof options != 'undefined' ? options.epicResponsible : []"
            :disabled="!canEditFieldsPermission"
            item-text="name"
            item-value="id"
            item-disabled="disable"
            v-model="item.epicResponsibleId"
            append-icon="mdi-chevron-down"
            :error-messages="getError('epicResponsibleId')"
            @input="cleanError('epicResponsibleId')"
          >
          </v-autocomplete>
        </div>
        <div class="md:g-col-4" v-if="item.epicTypeId === CONST_TYPE_EPICS.TECNOLOGIA">
          <label>Outras jornadas e Tribos envolvidas</label>
          <v-autocomplete
            :items="typeof options != 'undefined' ? options.journey_tribe_dependency : []"
            :disabled="!canEditFieldsPermission"
            item-text="name"
            item-value="id"
            item-disabled="disable"
            v-model="item.journeyTribeDependency"
            append-icon="mdi-chevron-down"
            chips
            multiple
            @change="journeyTribeDependencyValidate()"
            :error-messages="getError('journeyTribeDependency')"
            @input="cleanError('journeyTribeDependency')"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                @click="select"
                label
                class="chip-green"
              >
                {{ item.name }}
                <v-icon
                  v-if="false"
                  @click="removeJourneyTribes(item)"
                  size="16"
                  class="remove-chip"
                >mdi-close
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </div>
        <div class="md:g-col-4">
          <label>Áreas interessadas</label>
          <v-autocomplete
            :items="typeof options != 'undefined' ? options.interested_areas : []"
            :disabled="!canEditFieldsPermission"
            item-text="name"
            item-value="id"
            item-disabled="disable"
            v-model="item.interestedAreas"
            append-icon="mdi-chevron-down"
            chips
            multiple
            @change="interestedAreasValidate()"
            :error-messages="getError('interestedAreas')"
            @input="cleanError('interestedAreas')"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                @click="select"
                label
                class="chip-green"
              >
                {{ item.name }}
                <v-icon
                  v-if="false"
                  @click="removeInterestedAreas(item)"
                  size="16"
                  class="remove-chip"
                >mdi-close
                </v-icon>
            </v-chip>
            </template>
          </v-autocomplete>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import epicServiceFactory from "@/domains/epic/services/epicService";
import ErrorMessageHandler from "../mixins/ErrorMessageHandler";
const epicService = epicServiceFactory();

export default {
  name: "ResponsableForm",
  mixins: [
    ErrorMessageHandler
  ],
  props: {
    item: {
      type: Object
    },
    errorList: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object
    },
    completedEpic: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  watch: {
    watch_vsmId(newValue, oldValue) {
      this.loadTechTree(oldValue, newValue);
    },
  },
  computed: {
    canEditFieldsPermission() {
      return this.item.permissions?.canEditFieldsPermission;
    },
    canEditStakeholderFieldsPermission() {
      return this.item.permissions?.canEditStakeholderFieldsPermission;
    },
    watch_vsmId() { return this.item.vsmId },
    srGpmOptions(){
      return [...this.options.seniorGpm, ...this.innerOptions.srGpm];
    }
  },
  data: () => ({
    epicOptions: [],
    visualPmpo: [],
    squads: [],
    countLoad: 0,
    treeValid: true,
    innerOptions: {
      journeys: [],
      tribes: [],
      gpms: [],
      squads: [],
      pmpos: [],
      stakeholders: [],
      focalPoints: [],
      srGpm: [],
      vsm: []
    },
  }),
  mounted() {
    this.squads = this.item.squads;
    this.componentLoad();
  },
  updated() {
    if (this.countLoad < 1) {
      this.componentLoad();
      this.countLoad++;
    }
  },
  methods: {
    componentLoad() {
      if (this.item.vsmId) {
        this.changeVsm();
      }
      if (this.item.journeysId) {
        this.changeJourney();
      }
      if (this.item.tribesId) {
        this.changeTribe();
      }
      if (this.item.squadKey) {
        this.changeSquad();
      }
      if (this.item.demandingArea) {
        this.changeAreaResponsible();
      }
      if (this.item.stakeholderId) {
        this.changeStakeholder();
      }
    },
    removeSquad() {
      this.item.squad_key = '';
    },
    removeDisabledToOptions(itens, selected, chave = 'id') {
      return itens.filter(
          (item) => item.disabled !== true || selected === item[chave]
      );
    },
    removeJourneyTribes(journeyOrTribe) {
      this.item.journeyTribeDependency.splice(this.item.journeyTribeDependency.indexOf(journeyOrTribe.id), 1)
      this.item.journeyTribeDependency = [...this.item.journeyTribeDependency]
    },
    removeInterestedAreas(interestedAreas) {
      this.item.interestedAreas.splice(this.item.interestedAreas.indexOf(interestedAreas.id), 1)
      this.item.interestedAreas = [...this.item.interestedAreas]
    },
    changeVsm(onInput = false) {
      if (typeof this.options !== 'undefined') {
        let vsmSelected = this.searchSelectedInOptions(this.item.vsmId, this.options.vsm);
        this.innerOptions.journeys = vsmSelected[0].journeys;
        if (onInput) {
          this.$emit('valid-tree', true);
          this.item.tribesId = null
          this.item.gpmId = null
          this.item.squadKey = ''
          this.item.pmpos = []
        }
      }
    },
    changeAreaResponsible(onInput = false) {
      if (typeof this.options !== 'undefined') {
        let areaResponsibleSelected = this.searchSelectedInOptions(this.item.demandingArea, this.options.areaResponsible);
        if (areaResponsibleSelected.length > 0) {
          this.innerOptions.stakeholders = areaResponsibleSelected[0].stakeholders_id;
        }
        if (onInput) {
          this.$emit('valid-tree', true);
          this.item.stakeholderId = null
          this.item.focalPoints = null
        }
      }
    },
    changeStakeholder(onInput = false) {
      let stakeholdersSelected = this.searchSelectedInOptions(this.item.stakeholderId, this.innerOptions.stakeholders);
      if (typeof stakeholdersSelected[0] !== 'undefined') {
        this.innerOptions.focalPoints = stakeholdersSelected[0].focalPoints; 
        if (onInput) {
          this.$emit('valid-tree', true);
          this.item.focalPoints = null
        }
      }
    },
    changeJourney(onInput = false) {
      let journeySelected = this.searchSelectedInOptions(this.item.journeysId, this.options.journeys);
      
      this.innerOptions.vsm = [{
        id: journeySelected[0].vsmId,
        name:  journeySelected[0].vsmName
      }];

      this.innerOptions.srGpm = [{
        id: journeySelected[0].srGpmId,
        name:  journeySelected[0].srGpmName
      }];


      this.item.seniorGpmId = onInput ? this.innerOptions.srGpm[0].id : this.item.seniorGpmId;  
      this.item.vsmId = onInput ? this.innerOptions.vsm[0].id : this.item.vsmId;

      if (typeof journeySelected[0] !== 'undefined') {
        this.innerOptions.tribes = journeySelected[0].tribes;
        if (onInput) {
          this.$emit('valid-tree', true);
          this.item.tribesId = null
          this.item.gpmId = null
          this.item.squadKey = ''
          this.item.pmpos = []
        }
      }
    },
    changeTribe(onInput = false) {
      let tribeSelected = this.searchSelectedInOptions(this.item.tribesId, this.innerOptions.tribes);
      if (typeof tribeSelected[0] !== 'undefined') {
        
        this.innerOptions.gpms = tribeSelected[0].gpm;
        
        let squadsInTribe = tribeSelected[0].squads;

        if (this.item.stageId === this.CONST_STAGE.CONCLUIDO) {
          if (this.item.gpm[0].id !== tribeSelected[0].gpm[0].id) {
            this.innerOptions.gpms.push(this.item.gpm[0]);
          }
        }

        for (let indexSquads = 0; indexSquads < squadsInTribe.length; indexSquads++) {
          try {
            squadsInTribe[indexSquads].name = this.searchSelectedInOptions(squadsInTribe[indexSquads].squadKey, this.options.squads)[0].name;
          } catch (e) {
            continue;
          }
        }
        this.innerOptions.squads = squadsInTribe;
        if (onInput) {
          this.$emit('valid-tree', true);
          this.item.gpmId = null
          this.item.squadKey = ''
          this.item.pmpo = []
        }
      }
    },
    async changeSquad() {
      const dataPmpo = await epicService.loadPmPoBySquad(this.item.squadKey);
      this.innerOptions.pmpos = dataPmpo.data;
      let seletedPmpo = [];
      for (let indexPmpo = 0; indexPmpo < this.innerOptions.pmpos.length; indexPmpo++) {
        seletedPmpo.push(this.innerOptions.pmpos[indexPmpo].usersId);
      }

      this.visualPmpo = seletedPmpo;
    },
    searchSelectedInOptions(id, collection) {
      return collection.filter(obj => {
        return obj.id === id
      });
    },
    journeyTribeDependencyValidate() {
      const NOT_INVOLVE_IT = 1;
      this.item.journeyTribeDependency = this.checksIfOnlyOneOption(
          NOT_INVOLVE_IT,
          this.item.journeyTribeDependency
      );
    },
    interestedAreasValidate() {
      const NOT_INVOLVE_IT = 1;
      this.item.interestedAreas = this.checksIfOnlyOneOption(
          NOT_INVOLVE_IT,
          this.item.interestedAreas
      );
    },
    loadTechTree(oldValue, newValue) {
      if (oldValue === undefined && newValue !== undefined) {
        this.componentLoad();
      }
    }
  },
}
</script>

<style lang="scss">
#responsableForm {
  h3 {
    font-size: 15px;
    color: #6D6E70;
    &::before {
      content: "•";
      margin-right: 5px;
      font-size: 18px;
      position: relative;
      top: 1px;
    }
  }

  .v-input {
    font-size: 14px;
  }
}
</style>
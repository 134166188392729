import MultipleSelect from "@/components/common/VvMultipleSelect.vue";

export default {
    components: {
        MultipleSelect
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'edit'
        },
        tribeEditModalData: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Object,
            default: () => ({})
        },
        errorList: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            panel: 0
        }
    },
    methods: {
        addSquad(index) {
            this.panel = 0;
            this.$emit('addSquad', index);
        },
        removeSquad(tribeIndex, squadIndex) {
            this.$emit('removeSquad', tribeIndex, squadIndex)
            this.panel = null;
        },
        getSquadName(squadKey, index) {
            this.options.squads.map(squad => {
                if (squad.id === squadKey) {
                    this.tribeEditModalData.journeyDetail.tribes[this.tribeEditModalData.index].squads[index].name = squad.name;
                }
            })
        },
        save() {
            this.$emit('save');
        },
        getError(param) {
            return this.errorList[param];
        },
        clearError(param) {
            this.$emit('clearError', param);
        },
    }
}
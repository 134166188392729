import Vue from 'vue';

Vue.mixin({
    methods: {
        formattedDateForBr(date) {
            if(typeof date === "undefined"){
                return "";
            }
            if(date !== null) {
                let arrDate = date.split('-');
                if (arrDate[0].length == 4) {
                    let day = arrDate[2];
                    if (day.indexOf('T') > -1) {
                        let splitDay = day.split('T');
                        day = splitDay[0]
                    }
                    return day + '/' + arrDate[1] + '/' + arrDate[0];
                } else {
                    return arrDate[0] + '/' + arrDate[1] + '/' + arrDate[2];
                }
            }
        },
        
        convertMonthToDisplay(date_month) {
            if (date_month) {
                date_month +='T00:00-03:00';
                let date = new Date(date_month);
                let year = date.getFullYear();
                let options = {month: 'long', timeZone: 'UTC'};
                let month_name = date.toLocaleDateString("pt-BR", options);

                return month_name + '/' + year;
            }
        },

        convertMounthName(date_month, day) {
            let text_month = date_month.split('/');

            let objMount = {
                '01': "janeiro",
                '02': "fevereiro",
                '03': "março",
                '04': "abril",
                '05': "maio",
                '06': "junho",
                '07': "julho",
                '08': "agosto",
                '09': "setembro",
                '10': "outubro",
                '11': "novembro",
                '12': "dezembro",
            };

            let getKeyByValue = function (object, value) {
                return Object.keys(object).find(key => object[key] === value);
            };

            let month = getKeyByValue(objMount, text_month[0]);

            let date_return = text_month[1] + '-' + month;

            if (day) {
                return date_return + '-01';
            }

            return date_return;
        },

        isNumber: function (evt, value, max, acceptZero = false, onlyInt = false) {
            if (typeof value !== 'undefined' && value != null) {
                if (value.length >= max) {
                    evt.preventDefault();
                }
            }

            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;

            if (charCode === 48) {
                if (acceptZero) return true;
                if (value === '' || value === undefined || value < 1) {
                    evt.preventDefault();
                }
            }

            if ((charCode > 31 && (charCode < 47 || charCode > 57))) {
                evt.preventDefault();
            } else {
                return true;
            }

            if (onlyInt) {
                if ([46, 110, 190].includes(charCode)) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        },

        isLetter(evt) {
            evt = (evt) ? evt : window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;

            let removeAsciiCode = [];
            for (let i = 33; i < 48; i++) {
                removeAsciiCode.push(i);
            }
            for (let i = 58; i < 65; i++) {
                removeAsciiCode.push(i);
            }
            for (let i = 91; i < 97; i++) {
                removeAsciiCode.push(i);
            }
            for (let i = 123; i < 126; i++) {
                removeAsciiCode.push(i);
            }

            if (removeAsciiCode.includes(charCode)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        floatToMoney(value) {
            if (value) {
                let val = (value/1).toFixed(2).replace('.', ',')
                return 'R$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        },

        removeEmpty(items) {
            items.map(function (obj) {
                let propNames = Object.getOwnPropertyNames(obj);
                for (let i = 0; i < propNames.length; i++) {
                    let propName = propNames[i];
                    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                        delete obj[propName];
                    }
                }

                return obj
            })

            return items
        },

        removeEmptyObjects(items) {
            return items.filter(value => Object.keys(value).length !== 0);
        },

        removeFieldsEditVvTableEditor(keys) {
            return keys.filter(function (e) {
                return e !== 'id' && e !== 'project_id' && e !== 'created_at' && e !== 'updated_at' && e !== 'user'
            })
        },

        userIsTransformationManager() {
            return this.$store.getters.type.indexOf(this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO) > -1;
        },

        userIsMaster() {
            return this.$store.getters.type.indexOf(this.CONST_USER_TYPE.USUARIO_MASTER) > -1;
        },

        userIsItArea(userTI){
            if (this.$store.getters.type.indexOf(this.CONST_USER_TYPE.VSM) > -1 ||
                this.$store.getters.type.indexOf(this.CONST_USER_TYPE.GPM) > -1 ||
                this.$store.getters.type.indexOf(this.CONST_USER_TYPE.PM_PO) > -1) {
                if (userTI.indexOf(this.$store.getters.id) > -1) {
                    return true;
                }
            }
            return false;
        },
        hasAuthorizedToRegisterTap(){
            return this.userIsTransformationManager() || this.userIsMaster() || this.$store.getters.type.indexOf(this.CONST_USER_TYPE.PONTOS_FOCAIS) > -1
              || this.$store.getters.type.indexOf(this.CONST_USER_TYPE.STAKEHOLDER) > -1
              || (this.$store.getters.type.indexOf(this.CONST_USER_TYPE.VSM) > -1
              || this.$store.getters.type.indexOf(this.CONST_USER_TYPE.GPM) > -1
              || this.$store.getters.type.indexOf(this.CONST_USER_TYPE.PM_PO) > -1);
        },

        checksIfOnlyOneOption(id, options) {
            let notIsIt = options.find(function (params) {
                return params == id;
            });

            if (notIsIt == id) {
                options = [id];
            }

            return options;
        },

        base64ToArrayBuffer(base64) {
            const binaryString = window.atob(base64);
            const bytes = new Uint8Array(binaryString.length);
            return bytes.map((byte, i) => binaryString.charCodeAt(i));
        },

        createAndDownloadBlobFile(body, filename, extension = 'xlsx') {
            const blob = new Blob([body]);
            const fileName = `${filename}.${extension}`;
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, fileName);
            } else {
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', fileName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },
        pushGlobalFilters(filterKey, filterData) {
            let filterStore = {
                'filterName': filterKey,
                'fields': filterData
            };
            this.$store.commit('setFilterFields', filterStore);
        },
        getGlobalFilters(filterKey) {
            let findFilter = undefined;
            this.$store.getters.getFilterFields.forEach(function(filterLine) {
                if (filterLine.key === filterKey) {
                    let dateNow = Date.now();
                    let diffInMilliseconds = Math.abs( filterLine.date - dateNow);
                    let diffInMinutes = Math.floor((diffInMilliseconds/1000/60));
                    if (diffInMinutes <= 120) {
                        if(filterKey === 'epics'){
                            Object.keys(filterLine.fields).some(function(item){
                                if(Array.isArray(filterLine.fields[item])){
                                    if(filterLine.fields[item].length > 0){
                                        findFilter = filterLine.fields;
                                        return true;
                                    }
                                } else if (filterLine.fields[item] !== null) {
                                    findFilter = filterLine.fields;
                                    return true;
                                }
                            });
                        } else {
                            Object.keys(filterLine.fields).some(function(item){
                                if(filterLine.fields[item]){
                                    if(filterLine.fields[item]['value'] !== "" && typeof filterLine.fields[item]['value'] !== 'undefined' && filterLine.fields[item]['value'] !== null){
                                        findFilter = filterLine.fields;
                                        return true;
                                    }
                                }
                            });
                        }
                    }
                }
            });
            return findFilter;
        },
        verificaToken() {
            if (this.$store.getters.userToken) {
              if (this.$msal.isAuthenticated()) {
                clearInterval(this.validandoToken);
                this.deliveryData();
              }
              this.$msal.signIn();
            } else if (localStorage.token !== undefined) {
              this.deliveryData();
            } else {
              this.$msal.signIn();
            }
          },
          deliveryData: function () {
              let user = {};

            if (!this.$store.getters.isEmulated) {
                user = this.$store.getters.userAuth;
            } else {
                user = this.$store.getters.userOriginal;
            }

            let userDelivery = {
              oid: user.user.accountIdentifier,
              name: user.user.name,
              email: user.user.userName,
            };
      
            this.axios
                .post("v2/user/", userDelivery)
                .then((response) => {
                  this.$store.commit('id', response.data.user.id);
                  this.$store.commit('type', response.data.user.user_types_id);
                  this.$store.commit('types', response.data.user.types);
                  this.$store.commit('tree_types', response.data.user.tree_types);
                  this.$store.commit('delegations_types', response.data.user.delegations_types);
                })
                .catch(error => {
                  console.error(error);
                });
          },
          clearGlobalFilters(filterKey, fields) {


            fields.forEach(function(filterLine) {
                filterLine.value = null;
            });

            if (find) {
                let filterStore = {
                    'filterName': filterKey,
                    'fields': fields
                };
                this.$store.commit('setFilterFields', filterStore);
            }
        },
        getPermissionCreateEdit(component){
            let id = {"id" : component.form.values.id};
            this.axios.post('/user/epic-permissions', id)
                .then(response => {
                component.create_permission = response.data.create_permission;
                component.edit_permission = response.data.edit_permission;
                if(typeof component.form.values.id !== 'undefined' && component.edit_permission){
                    component.check_disabled_focal_point_or_stakeholder = false;
                    component.check_disabled = false;
                }
                
            })
            .catch(error => {
                this.form.errors = error.response.data;
                this.snackbarMessages(error.response.data, 'snackbarColor');
            });
        },
        getEnvEnvironment() {
            if (typeof process.env.VUE_APP_AMB !== 'undefined') {
                return process.env.VUE_APP_AMB;
            }
            return 'PRD'
        },
        startNoApply(item, idNoApply) {
            Object.keys(item).forEach((key) => {
                if (item[key].id === idNoApply) {
                    let auxItem = item[key];
                    let index = item.indexOf(item[key]);
                    item.splice(index, 1);
                    item.unshift(auxItem);
                }
            });
        },
        getNameOptionByStatus(value, options) {
            let retorno = "Valor Invalido";

            if (!Array.isArray(options)) {
                return retorno;
            }

            options.forEach(function(option) {
                if (option.id == value) {
                    retorno = option.name;
                    return;
                }
            });

            return retorno;
        },
        parseMonth(month){
            month = month.toString();
            month = month.padStart(2, '0');
            switch (month) {
                case "01":
                    return "janeiro";
                case "02":
                    return "fevereiro";
                case "03":
                    return "março";
                case "04":
                    return "abril";
                case "05":
                    return "maio";
                case "06":
                    return "junho";
                case "07":
                    return "julho";
                case "08":
                    return "agosto";
                case "09":
                    return "setembro";
                case "10":
                    return "outubro";
                case "11":
                    return "novembro";
                case "12":
                    return "dezembro";
                default:
                    return "sem dado";
            }
        },
        parseMonthAbbreviated(month){
            switch (month) {
                case "01":
                    return "Jan";
                case "02":
                    return "Fev";
                case "03":
                    return "Mar";
                case "04":
                    return "Abr";
                case "05":
                    return "Mai";
                case "06":
                    return "Jun";
                case "07":
                    return "Jul";
                case "08":
                    return "Ago";
                case "09":
                    return "Set";
                case "10":
                    return "Out";
                case "11":
                    return "Nov";
                case "12":
                    return "Dez";
                default:
                    return "sem dado";
            }
        },
        orderNameOptions(options) {
            if (typeof options !== 'undefined' && Array.isArray(options)) {
                return options.sort((a, b) => (a.name > b.name) ? 1 : -1);
            }
            return [];
        },
        deepCopy(anything) {
            return JSON.parse(JSON.stringify(anything));
        },
        getToday() {
            let date = new Date();
            let year = date.getFullYear();
            let mouth = (date.getMonth() + 1);
                if (mouth < 10) {
                    mouth = `0${mouth}`;
                }
            let day = date.getDate();
                if (day < 10) {
                    day = `0${day}`;
                }
            let today = `${year}-${mouth}-${day}`;
            return today;
        },
        isEmpty(obj) {
            if (typeof obj === 'object') {
                return Object.keys(obj).length === 0;
            }
        },
        textToLowerCase(text) {
            return text.toLowerCase();
        },
        convertDateToNotificationDefault(complete, hasYear = false) {
            complete = complete.split(" ");
            let hour = complete[1];
            let date = complete[0].split("-");

            if (hasYear) {
                return hour+" - "+date[2]+" "+this.parseMonthAbbreviated(date[1])+" "+date[0];
            }
            return hour+" - "+date[2]+" "+this.parseMonthAbbreviated(date[1]);
        },
        pathBlank(route) {
            let routeData = this.$router.resolve({path: route});
            window.open(routeData.href, '_blank')
        },
        pushCacheSearchDoc(keywords) {
            let searchStore = {
                'searchName': 'Search Documentation',
                'keywords': keywords
            };
            this.$store.commit('setSearchDocs', searchStore);
        },
        getCacheSearchDoc(SearchDocName) {
            let findKeyword = "";
            this.$store.getters.getSearchDocs.forEach((searchLine) => {
                if (searchLine.key === SearchDocName) {
                    const dateNow = Date.now();
                    const diffInMilliseconds = Math.abs( searchLine.date - dateNow);
                    const diffInMinutes = Math.floor((diffInMilliseconds/1000/60));
                    if (diffInMinutes <= 120) {
                        findKeyword = searchLine.keywords;
                    }
                }
            });
            return findKeyword;
        },
        clearCacheSearchDoc() {
            this.$store.commit('clearCacheSearch');
        },
    }
});

import VvSwitch from '@/components/common/VvSwitch.vue';

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: () => ({})
        },
        area: {
            type: Object,
            default: () => ({})
        },
        errorList: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        VvSwitch
    },
    data() {
        return {
            clonedArea: {}
        }
    },
    methods: {
        getError(param) {
            return this.errorList[param];
        },
        clearError(param) {
            this.$emit('clearError', `${param}`)
        },
        save() {
            let obj = {
                id: this.clonedArea.areaId,
                active: this.clonedArea.areaActive,
                name: this.clonedArea.areaName,
                transformation_manager_id: this.clonedArea.transformationManagersId
            };
            this.$emit('save', obj)
        }
    },
    watch: {
        area: {
            handler() {
                this.clonedArea = JSON.parse(JSON.stringify(this.area))
            },
            deep: true
        }
    }
}
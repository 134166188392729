<template>
  <div class="d-flex" style="gap: 8px">
    <div
      v-for="(item, index) in sorted"
      :key="index"
      class="d-flex pl-5 pr-4 py-3 step"
      :class="{
        selected: index + 1 === value,
        outfocus: index + 1 !== value,
        open: item.active && index + 1 !== value,
        'cursor-select': item.active,
        'cursor-blocked': !item.active,
      }"
      @click="select(item, index + 1)"
    >
      <div class="mr-2" style="flex: 1">
        <div class="font-weight-black" style="font-size: 1.1em; color: black">
          {{ item.name }}
        </div>
        <div>{{ item.description }}</div>
      </div>
      <v-chip
        :color="item.active ? 'primary' : 'grey'"
        class="chip"
        :class="{
          'cursor-select': item.active,
          'cursor-blocked': !item.active,
        }"
        :disabled="!item.active"
      >
        <span class="font-weight-bold">{{ index + 1 }}</span>
      </v-chip>
    </div>
  </div>
</template>

<script>
const sortBy = (by, cresc = true) => (a, b) => {
  const before = a[by];
  const next = b[by];
  if (before < next) return cresc ? -1 : 1;
  if (before > next) return cresc ? 1 : -1;
  return 0;
};

export default {
  name: "VvStepper",
  props: {
    value: {
      type: Number,
    },
    items: {
      type: Array,
      default: () => [],
    },
    hasFinalization: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sorted() {
      const list = [...this.items];
      let newList = [];
      list.map((step) => {
        if (
          step.id === this.CONST_PROJECT_STEPS.CADASTRO ||
          step.id === this.CONST_PROJECT_STEPS.DISCOVERY ||
          step.id === this.CONST_PROJECT_STEPS.INCEPTION
        ) {
          newList.push(step);
        } else if (step.id === this.CONST_PROJECT_STEPS.ENCERRAMENTO && this.hasFinalization) {
          step.active = true;
          newList.push(step);
        }
      });
      return newList.sort(sortBy("order"));
    },
  },
  methods: {
    select(item, index) {
      if (!item.active) return;
      this.$emit("input", index);
    },
  },
};
</script>

<style scoped>
.chip {
  --size: 36px;
  min-width: var(--size);
  width: var(--size);
  height: var(--size);
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 18px;
}
.step {
  flex: 1; 
  align-items: center;
  border-radius: 8px;
}
.selected {
  box-shadow: inset 0 0 0 2px #6a5aff !important;
}
.outfocus {
  opacity: 0.5;
}
.open {
  background-color: #e2deff;
}
.cursor-select {
  cursor: pointer;
}
.cursor-blocked {
  cursor: not-allowed;
}
</style>

<template>
  <v-dialog
      v-model="dialog"
      scrollable
      persistent
      width="960px"
  >
    <template v-slot:activator="{ on, attrs }" v-if="showButton">
      <v-btn
          class="btn-save-project"
          color="info"
          dark
          v-bind="attrs"
          v-on="on"
      >
        Repriorizar Macro Tema
      </v-btn>
    </template>
    <v-card class="v-card-align">
      <v-card-title class="v-card-title-align">
        <v-row>
          <v-col align="center" class="title-modal">
            {{ area_name }}
          </v-col>
          <v-btn
              class="btn-close-modal"
              fab
              x-small
              outlined
              color="grey"
              @click="dialog = false"
              :disabled="dialogMessage"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text class="v-card-text-align scroll-style">
        <v-list>
          <draggable v-model="priorities" group="people" @start="drag=true" @end="drag=false" handle=".handle">
            <div v-for="(item, index) in priorities" :key="index">
              <v-list-item class="priority-item">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="priority-number">{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <span class="priority-text">{{ item.name }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-icon class="priority-icon" @click="moveDown(index)" :disabled="dialogMessage">
                  mdi-chevron-down
                </v-icon>
                <v-icon class="priority-icon" @click="moveUp(index)" :disabled="dialogMessage">
                  mdi-chevron-up
                </v-icon>
                <v-icon class="handle" :disabled="dialogMessage">
                  mdi-cursor-move
                </v-icon>
              </v-list-item>
              <v-divider v-if="index < priorities.length - 1" class="priority-divider"></v-divider>
            </div>
          </draggable>
        </v-list>
      </v-card-text>

      <v-row>
        <v-col cols="12" align="end">
          <v-btn
              class="btn-save-project"
              color="success"
              @click="dialog = false"
              outlined
              :disabled="dialogMessage"
          >
            Cancelar
          </v-btn>
          <v-btn
              class="btn-save-project priority-btn"
              color="success"
              @click="save"
              :disabled="dialogMessage"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialogMessage" hide-overlay persistent width="400">
      <v-alert dense type="success" style="margin: 0">
        {{ textMessage }}
      </v-alert>
    </v-dialog>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "VvPriority",
  props: {
    area_name: String,
    area_id: Number,
    showButton: {
      type: Boolean,
      default: true
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      dialogMessage: false,
      textMessage: '',
      dialog: false,
      old: [],
      priorities: [],
    };
  },

  mounted: function () {
    this.getPriorities();
    
    if(!this.showButton) {
      this.dialog = true;
    }
  },

  methods: {
    getPriorities() {
      this.axios
          .get('/project/priority', {
            params: {
              area_id: this.area_id
            }
          })
          .then((response) => {
            this.old = response.data;
            this.priorities = response.data;
          })
          .catch(error => {
            console.error(error.response.data);
          });
    },

    moveUp(index) {
      if (index > 0) {
        let el = this.priorities[index];
        this.priorities[index] = this.priorities[index - 1];
        this.priorities[index - 1] = el;

        this.$set(this.priorities, index - 1, el);
      }
    },

    moveDown(index) {
      if (index !== -1 && index < this.priorities.length - 1) {
        let el = this.priorities[index];
        this.priorities[index] = this.priorities[index + 1];
        this.priorities[index + 1] = el;

        this.$set(this.priorities, index + 1, el);
      }
    },

    save() {
      this.repriorise();
      this.axios.put('/project/priority', this.priorities)
          .then((response) => {
            this.dialogMessage = true;
            this.textMessage = response.data.message;

            this.$emit('changePriority');

            setTimeout(() => {
              this.dialogMessage = false;
              this.textMessage = '';
              this.dialog = false;
            }, 2000);
          })
          .catch(error => {
            console.error(error.response.data);
          });
    },

    repriorise() {
      this.priorities.map((project, index) => {
        project.priority = index + 1;
        return project;
      });
    },
  },
}
</script>

<style scoped lang="scss">
.btn-save {
  margin-right: 0 !important;
}

.v-card-align {
  padding: 40px;
}

.v-card-text-align {
  height: 300px;
}

.v-card-title-align {
  padding-right: 12px !important;
}

.priority-item:hover {
  background-color: rgba(93, 93, 93, 0.18);
}

.title-modal {
  color: var(--text-gray);
  font-size: var(--font-size-h2);
  font-weight: bold;
}

.priority-number {
  color: var(--text-gray);
  font-size: var(--font-size-h2);
  font-weight: bold;
  padding-right: 30px;
}

.priority-text {
  color: var(--text-gray);
  font-size: var(--font-size-p);
}

.priority-icon {
  padding-right: 15px;
}

.priority-divider {
  margin-left: 10px;
}

.priority-btn {
  margin-right: 0 !important;
}

@media screen and (max-width: 600px) {
  .priority-number {
    font-size: var(--font-size-h6);
    padding-right: 10px;
  }
  .v-card-align {
    padding: 10px;
    .col button {
      margin: 20px 5% !important;
      width: 40%;
    }
  }
  .priority-icon {
    padding-right: 0;
  }
  .v-dialog > .v-card > .v-card__text {
    padding: 0 5px 5px;
  }
}
</style>

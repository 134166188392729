<template>
  <v-data-table
    :headers="headers"
    :items="deliveryFormalizations"
    :single-expand="true"
    :mobile-breakpoint="0"
    :no-data-text="noDataText"
    :no-results-text="noResultsText"
    :search="search"
    :custom-filter="customSearch"
    :expanded.sync="expandedFormalization"
    :itemsPerPage="deliveryFormalizations.length"
    hide-default-footer
    item-key="id"
    dense
    class="data-table-delivery-formalizations"
    @click:row="openDetails"
  >
    <template v-slot:item.epicName="{ item }">
      <p class="epic-name" :id="'formalization-'+item.id">{{ item.epicName }}</p>
    </template>

    <template v-slot:item.status="{ item }">
      <div class="workflow-status" :class="parseStatusFormalization(item.statusId)">{{ item.status }}</div>
    </template>

    <template v-slot:item.vsm="{ item }">
        <p 
          v-for="(vsm, i) in item.vsm"
          :key="i"
          class="approver vsm" 
          :class="vsm.status.toLowerCase()" 
        >
          {{vsm.name}}
        </p>
    </template>

    <template v-slot:item.focalPoints="{ item }">
        <p 
          v-for="(focalPoint, i) in item.focalPoints"
          :key="i"
          class="approver focal-point" 
          :class="focalPoint.status.toLowerCase()" 
        >
          {{focalPoint.name}}
        </p>
    </template>

    <template v-slot:item.expandButton="{ item }">
      <v-btn icon color="#6D6E70">
        <v-icon v-if="item.id === toggleById">mdi-chevron-up</v-icon>
        <v-icon v-else>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <details-delivery-formalizations 
          :formalization="item"
          @update-formalization="updateFormalization"
          :items="details"
          @error="errorAlert = $event"
          @loading="loading = $event"
          :disable-response="!canResponse(item)"
        >
        </details-delivery-formalizations>
        <history-delivery-formalization 
          v-if="details.length > 0 && details[0].hasHistory" 
          :id="deliveryFormalizationId"
          :formalizationId="details.length > 0 && details[0].id"
          :openHistory="openHistory"
          @loading="loading = $event"
        >
        </history-delivery-formalization>
      </td>
    </template>
  </v-data-table>
</template>,

<script>
import DetailsDeliveryFormalizations from './DetailsDeliveryFormalizations.vue';
import deliveryFormalizationServiceFactory from "@/domains/deliveryFormalization/services/deliveryFormalizationService";
import HistoryDeliveryFormalization from './HistoryDeliveryFormalization.vue'
import dataTableDeliveryFormalizationRules from '../rules/dataTableDeliveryFormalizationRules'

const deliveryFormalizationService = deliveryFormalizationServiceFactory();
const deliveryFormalizationRules = dataTableDeliveryFormalizationRules();

export default {
  components: {
    DetailsDeliveryFormalizations,
    HistoryDeliveryFormalization
  },
  props: {
    deliveryFormalizations: {
      type: Array,
      required: true
    },
    search: String,
    loadingDeliveryFormalizations: Boolean
  },
  data() {
    return {
      approvers: [],
      headers: [
        {
          text: "ID épico",
          align: "start",
          value: "epicId",
          width: "99px"
        },
        {
          text: "Entregável",
          align: "start",
          value: "epicName",
        },
        {
          text: "Área",
          align: "start",
          value: "area",
          width: "79px"
        },
        {
          text: "Status Workflow",
          align: "start",
          value: "status",
          width: "175px"
        },
        {
          text: "VSM",
          align: "start",
          value: "vsm",
          sort: (a, b) => a.map(({name}) => name) < b.map(({name}) => name) ? -1 : 1
        },
        {
          text: "Pontos Focais",
          align: "start",
          value: "focalPoints",
          sort: (a, b) => a.map(({name}) => name) < b.map(({name}) => name) ? -1 : 1
        },
        {
          text: "",
          value: "expandButton"
        }
      ],
      details: [],
      history: [],
      loading: false,
      expandedFormalization: [],
      deliveryFormalizationId: null,
      formalizationId: null,
      noDataText: 'carregando...',
      noResultsText: 'nenhuma formalização encontrada',
      toggleById: null,
      errorAlert: false,
      openHistory: -1
    }
  },
  watch: {
    loading(event) {
      this.$emit('loading', event);
    },
    errorAlert(event) {
      this.$emit('error', event);
    },
    loadingDeliveryFormalizations(value) {
      this.statusListDeliveryFormalization(value);
    },
    async deliveryFormalizations() {
      let isHistory = false;
      const isOnDeliveryFormalizations = this.deliveryFormalizations
          .map(({id}) => id)
          .includes(this.formalizationId);

      const isOnExpandedDeliveryFormalizations = this.expandedFormalization
          .map(({id}) => id)
          .includes(this.formalizationId);

      if (isOnDeliveryFormalizations === false && !isNaN(this.formalizationId)) {
        let currentFormalization = await deliveryFormalizationService.getCurrentFormalization(this.formalizationId);
        if (currentFormalization.status === 200) {
          this.formalizationId = currentFormalization.data.current;
          isHistory = true;
        }
      }

      const formalization = this.deliveryFormalizations
          .find(({id}) => id === this.formalizationId);

      if (isNaN(this.formalizationId) || isOnExpandedDeliveryFormalizations === true) return;
      this.expandedFormalization.push(formalization);
      await this.load(this.formalizationId, true, isHistory);
    }
  },
  created() {
    this.formalizationId = parseInt(this.$route.params.formalizationId);
  },
  methods: {
    customSearch(value, search, item) {
      if (Array.isArray(value)) {
        return value.some(item=>Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search)))
      }
      return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search))
    },
    openDetails(row, slot) {
      if (!slot.isExpanded) {
        this.load(row.id);
      }
      this.toggleButton(row.id)
      slot.expand(!slot.isExpanded);
    },
    async load(id, byRoute = false, isHistory = false) {
      this.$emit('loading', true);
      this.openHistory = -1
      this.deliveryFormalizationId = id;
      const formalization = await deliveryFormalizationService.loadDeliveryFormalization(id);
      if (formalization.status === 200) {
        this.details = [formalization.data.detail];
        if (byRoute === true) {
          await this.toggleButton(id);
          this.$vuetify.goTo('#formalization-' + id, {offset: 55});
          if (isHistory === true) {
            this.openHistory = 0
          }
        }
      } else {
        this.errorAlert = true;
      }
      this.$emit('loading', false);
    },
    statusListDeliveryFormalization() {
      if (this.deliveryFormalizations.length === 0 && this.loadingDeliveryFormalizations === false) {
        this.noDataText = 'nenhuma formalização encontrada';
      }
    },
    parseStatusFormalization(id) {
      let status = ""
      switch (id) {
        case this.CONST_CHANGE_REQUEST_STATUS.PENDENTE_APROVACAO:
          status = 'pendente';
          break;
        case this.CONST_CHANGE_REQUEST_STATUS.APROVADO:
          status = 'aprovado';
          break;
        case this.CONST_CHANGE_REQUEST_STATUS.REPROVADO:
          status = 'recusado';
          break;
        case  this.CONST_CHANGE_REQUEST_STATUS.PENDENTE_ALTERACAO:
          status = 'pendente';
          break;
      }

      return status
    },
    toggleButton(id) {
      if (this.toggleById === id) {
        this.toggleById = null;
      } else {
        this.toggleById = id;
      }
    },
    canResponse(item) {
      const userId = this.$store.getters.id;
      return deliveryFormalizationRules.canResponse(item, userId);
    },
    updateFormalization(formalization) {
      const data = this.deliveryFormalizations.map(item => {
        if(item.id === formalization.id) return formalization;
        return item;
      })
      this.$emit('update:deliveryFormalizations', data);
    }
  }
}
</script>

<style lang="scss">
.data-table-delivery-formalizations {
  cursor: pointer;

  &.v-data-table {
    .v-data-table__wrapper {
      table {
        thead {
          background-color: #f4f5f5;
          height: 34px;

          tr {
            th {
              span {
                font-weight: 400;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              height: 83px;
              color: #6D6E70;
              font-weight: 500;
            }

            &.v-data-table__expanded__content {
              box-shadow: none;
              cursor: auto;

              td {
                padding: 51px 0px;
              }
            }
          }
        }
      }
    }
  }

  .approver {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 0px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &.pendente {
      color: #929497;
    }

    &.aprovado {
      color: #6DA151;
    }

    &.reprovado {
      color: #FF502D;
    }

    @media (max-width: 1903px) {
      &.transformation-manager {
        max-width: 103.06px;
      }

      &.vsm {
        max-width: 79.19px;
      }

      &.focal-point {
        max-width: 125.16px;
      }
    }
  }

  .workflow-status {
    padding: 8px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    border-radius: 34px;
    width: fit-content;

    &.pendente {
      color: #6D6E70;
      background-color: #DBDCDC;
    }

    &.aprovado {
      color: #6DA151;
      background-color: #B2DEB2;
    }

    &.recusado {
      color: #FF502D;
      background: rgba(255, 80, 45, 0.25);
    }
  }

  .epic-name {
    margin-bottom: 0px !important;

    @media (max-width: 1903px) {
      width: 222.94px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
import axios from "@/plugins/axios";

export default function dashboardService() {
    return {
        initializeDataForm() {
            return axios
                .get(`v2/dashboard/options`)
                .then(res => res.data)
        },

        initializeDataFormRoadMap() {
            return axios
                .get(`v2/dashboard/options/roadmap`)
                .then(res => res.data)
        },

        getProjectsRoadMap(params) {
            return axios
                .get("v2/dashboard/options/roadmap/areas", { params: params } )
                .then(res => res.data)
        },

        getGraphRoadMap(params) {
            return axios
                .get("v2/dashboard/roadmap", { params: params })
                .then(res => res.data)
        },

        getGraphic(params) {
            return axios
                .get("v2/dashboard", { params: params })
                .then(res => res.data)
        },
    }
}
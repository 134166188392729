

export default {
    name: "RoadMapGraphic",
    props: {
        item: {
            type: Object,
            default: () => ({}),
        }
    },
    methods: {
        fillItem(start, end, actual) {
            if (start == actual || end == actual || (actual > start && actual < end)) {
                if (actual == start && actual == end) {
                    return "full-rounded-item filled-item "
                }
                if (actual == start) {
                    return "start-rounded-item filled-item"
                }
                if (actual == end) {
                    return "end-rounded-item filled-item"
                }
                return "filled-item"
            }
        },
        buttonOpaque(value) {
            if (value) {
                return 'opaque-button'
            }
        },
        nextSlide(projectLenght, projectIndex) {
            if (projectLenght !== projectIndex+1) {
                this.$refs.carousel.next();
            }
        },
        prevSlide(projectIndex) {
            if(projectIndex !== 0) {
                this.$refs.carousel.prev();
            }
        },
    }
}
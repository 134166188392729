<template>
  <v-container :fluid="true" class="px-3" id="listChangeRequest">
    <vv-loading :is-loading="isLoading"></vv-loading>
    <div class="grid-3 gap-xl">
      <div>
        <label for="search-epic">Selecionar Épico</label>
        <v-select
          id="search-epic"
          :items="epics"
          item-text="name"
          item-value="id"
          v-model="epicId"
          class="search-epic"
          no-data-text="Nenhum épico encontrado"
        ></v-select>
      </div>
      <div v-if="epicId !== null">
        <label for="search-change-request">Busca</label>
        <v-text-field
          id="search-change-request"
          placeholder="Épico, id, nome"
          append-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </div>
    </div>
		<data-table-change-request 
      v-if="showDataTable"
      :changeRequests="changeRequests"
      :search="search"
      :loadingChangeRequests="loadingChangeRequests"
      :project="project"
      :deliveryPlanned="epic.deliveryPlanned"
      @updateChangeRequest="updateChangeRequest"
      @loading="isLoading = $event"
      @error="errorAlert = $event"
    ></data-table-change-request>
		<v-dialog v-model="errorAlert" hide-overlay width="500">
      <v-alert type="error">Erro! Tente novamente</v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import DataTableChangeRequest from "./DataTableChangeRequest.vue";
import changeRequestServiceFactory from "@/domains/changeRequest/services/changeRequestServices";

const ChangeRequestService = changeRequestServiceFactory();

export default {
  props: {
    project: Object
  },
	components: {
		VvLoading,
		DataTableChangeRequest
	},
	data() {
		return {
			isLoading: false,
			errorAlert: false,
			search: '',
      epics: [],
      epic: {},
      epicId: null,
      showDataTable: false,
			changeRequests: [],
			loadingChangeRequests: false,
		}
	},
  created() {
    this.loadEpicsList();
    this.loadScreen();
  },
  watch: {
    epicId(newValue, oldValue) {
      if (newValue != oldValue) {
        this.epicId = newValue;
        this.loadChangeRequestList(newValue);
      }
    }
  },
  methods: {
    loadScreen() {
      if (
        typeof this.$route.params.changeId !== 'undefined' &&
        typeof this.$route.params.epicId !== 'undefined' && 
        typeof this.$route.params.id != 'undefined'
      ) {
        this.epicId = this.$route.params.epicId;
        this.loadChangeRequestList(this.epicId);
      }
    },
    async loadEpicsList() {
      this.isLoading = true;
      const result = await ChangeRequestService.loadEpicsList(this.$route.params.id);

      if (result.status === 200) {
        this.epics = result.data.epics;

        if (typeof this.$route.params.epicId !== 'undefined') {
          this.epicId = this.$route.params.epicId;
        }
      }

      this.isLoading = false;
    },
    async loadChangeRequestList(epicId) {
      this.isLoading = true;
      const result = await ChangeRequestService.loadList(this.$route.params.id, epicId);
      
      if (result.status === 200) {
        this.search = '';
        this.showDataTable = true;
        this.changeRequests = result.data.changeRequests;
        for (let i = 0; i < this.changeRequests.length; i++) {
          this.changeRequests[i].created_at = this.formattedDateForBr(this.changeRequests[i].created_at);
          this.changeRequests[i].deliveryNewEpic = this.changeRequests[i].deliveryNewEpic ? "Sim" : "Não"
          this.changeRequests[i].newEpicId = this.changeRequests[i].newEpicId === null ? "-" : this.changeRequests[i].newEpicId
        }
        this.epic = result.data.epic;
      }
      this.isLoading = false;
    },
    updateChangeRequest(changeRequest) {
      this.loadChangeRequestList(changeRequest.epicId)
    }
  }
}
</script>

<style lang="scss">
.v-dialog {
  box-shadow: none;
}
.search-epic {
  max-width: 539px;

  @media (max-width: 1903px) {
    max-width: 339px;
  }

  @media (max-width: 1263px) {
    max-width: 244px;
  }
}
</style>
import dashboardService from "../../service/dashboardService.js";
import RoadMapGraphic from "../../../../components/RoadMapGraphic/RoadMapGraphic.vue"
import ModalConfirmExport from "@/components/reports/ModalConfirmExport";
import filterServiceFactory from "@/services/filterService";
import html2canvas from 'html2canvas';

const FilterService = filterServiceFactory();
const dashboardServiceFactory = new dashboardService();

export default {
    name: "Dashboard",
    components: {
        ModalConfirmExport,
        RoadMapGraphic
    },
    data: () => ({
        dialogExport: false,
        filterPanel: 0,
        totalDelivery: '',
        errorList: {},
        form: {
            yearId: null,
            areas: [],
            projects: []
        },
        quarterId: [],
        options: {
            years: [],
            areas: [],
            projects: []
        },
        roadMapData: {},
        breadcrumb: [
            {
                text: 'Dashboard',
                disabled: false,
            },
        ],
    }),
    methods: {
        clearFilter() {
            this.options.years.map(year => {
                let currentYear = new Date().getFullYear();
                if (year.name == currentYear) {
                    this.form.yearId = year.id
                }
            })
            this.form.areas = [];
            this.form.projects = [];
        },
        async initializeDataFormRequest() {
            let response = {};

            try {
                this.$showLoading();
                response = await dashboardServiceFactory.initializeDataFormRoadMap();
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }

            this.options.areas = response.options.areas
            this.options.years = response.options.years

            this.options.years.map(year => {
                let currentYear = new Date().getFullYear();
                if (year.name == currentYear) {
                    this.form.yearId = year.id
                }
            })
        },
        async requestProjects(id) {
            try {
                this.$showLoading();
                const payload = { "areasId[or][]": id }
                const response = await dashboardServiceFactory.getProjectsRoadMap(payload)
                this.options.projects = response.options.projects
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        setErrorList(error) {
            const {
                response: { data: errors },
            } = error;
            this.errorList = errors;
        },
        openModal() {
            this.modalOpen = true;
        },
        async exportNotification() {
            try {
                const payload = {
                    "yearId[eq]": this.form.yearId,
                    "areaId[eq]": this.form.areas,
                    "projectId[or]": this.form.projects
                }
                await FilterService.exportNotification(payload, 'v2/dashboard/report/roadmap');
                this.dialogExport = true;
            } catch (error) {
                this.$showError(error);
            }
        },
        async exportToImage() {
            try {
                this.$showLoading();
                const area = this.$refs.areaToExport
                const canvas = await html2canvas(area)
                const image = canvas.toDataURL('image/png')
                const downloadLink = document.createElement('a')

                downloadLink.href = image;
                downloadLink.download = 'visao-de-roadmap.png'
                downloadLink.click();
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        getError(param) {
            return this.errorList[param];
        },
        clearError(param) {
            delete this.errorList[param];
        },
        async getData(params) {
            try {
                this.$showLoading();

                const payload = {
                    "yearId[eq]": params.yearId,
                    "areaId[eq]": params.areas,
                    "projectId[or]": params.projects
                }

                if (params.areas && params.projects) {
                    const response = await dashboardServiceFactory.getGraphRoadMap(payload)
                    if (response.projects.length > 0) {
                        this.roadMapData = response
                    } else {
                        this.roadMapData = {}
                        this.$showError('Não existem macro temas na iniciativa para gerar a visão de roadmap.');
                    }
                }
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
    },
    created() {
        this.initializeDataFormRequest()
    }
}
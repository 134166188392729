var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"top":"","width":"640"},on:{"click:outside":function($event){return _vm.$emit('input', false);}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',{attrs:{"id":"NotificationDialog"}},[_c('v-card-title',[_c('h2',[_vm._v("Notificação")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.changeNotificationStatus(true, _vm.data)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-open-outline")])],1)]}}])},[_c('span',[_vm._v("Marcar como lida")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.changeNotificationStatus(false, _vm.data)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-outline")])],1)]}}])},[_c('span',[_vm._v("Marcar como não lida")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteNotifications(_vm.data)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}])},[_c('span',[_vm._v("Excluir notificação")])]),_c('button',{on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-card-text',[_c('ul',[_c('li',[_vm._v("Hora e Data: "),_c('span',[_vm._v(_vm._s(!_vm.isEmpty(_vm.data) ? _vm.convertDateToNotificationDefault(_vm.data.createdAt, true) : ''))])]),_c('li',[_vm._v("Modulo: "),_c('span',[_vm._v(_vm._s(_vm.data.moduleName))])]),_c('li',[_vm._v("Tipo: "),_c('span',[_vm._v(_vm._s(_vm.data.notificationTypeName))])])])]),_c('v-divider',{staticClass:"mx-6"}),_c('v-card-text',{staticClass:"mt-2"},[_c('h2',[_vm._v("Detalhes")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.data.message)}})]),_c('v-card-actions',[(_vm.data.hasDownload)?_c('button',{on:{"click":function($event){_vm.pathBlank(_vm.data.route); _vm.$emit('input', false);}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tray-arrow-down")]),_vm._v(" Download ")],1):_vm._e(),(!_vm.data.hasDownload && _vm.data.route != null)?_c('button',{on:{"click":function($event){_vm.$router.push(_vm.data.route); _vm.$router.go(); _vm.$emit('input', false);}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-open-in-new")]),_vm._v(" Acessar página ")],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <vv-loading :is-loading="isLoading"></vv-loading>
    <v-dialog
        v-model="dialog"
        width="500"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            outlined
            v-bind="attrs"
            v-on="on"
        >
          Novo ano
        </v-btn>
      </template>

      <v-card>
        <v-card-text class="pt-6">
          <h2>Selecione o ano</h2>
          <div class="mt-2">Escolha qual(is) ano(s) deseja parametrizar.</div>
          <div class="mt-8">
            <label>Ano</label>
            <v-autocomplete
                :items="yearList"
                :disabled="false"
                item-text="year"
                item-value="id"
                v-model="years"
                append-icon="mdi-chevron-down"
                no-data-text="Nenhum ano encontrado..."
                multiple
            >
              <template #item="scope">
                <div class="d-flex" style="width: 100%;">
                  <div class="ml-4 d-flex itens-center">
                    <v-icon v-if="years.includes(scope.item['year'])">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else-if="scope.item['disabled']" color="grey">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else>
                      mdi-checkbox-blank-outline
                    </v-icon>
                  </div>
                  <v-list-item-content style="max-width: 480px">
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-title :input-value="active" :class="{'grey-disabled': scope.item['disabled']}">{{ scope.item['year'] }}</v-list-item-title>
                      </template>
                    </v-list-item>
                  </v-list-item-content>
                </div>
              </template>
            </v-autocomplete>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              :disabled="emptyYears"
              @click="saveNewPeriod"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import {clone} from "@/services/utils";
import quartersServiceFactory from "@/domains/quarters/services/quartersService";

const quartersService = quartersServiceFactory();

export default {
  name: "NewPeriod",
  components: {
    VvLoading,
  },
  props: {
    yearsOptions: {
      type: Array,
      default: () => [],
    }
  },
  watch: {
    optionsYears() {
      this.createOptions();
    },
  },
  computed: {
    yearList() {
      return this.options?.map((years) => {
        const newYears = {...years};
        if(years.quarters.length > 0) newYears.disabled = true;
        return newYears;
      })
    },
    optionsYears() {
      return this.yearsOptions;
    },
    emptyYears() {
      return (this.years.length < 1);
    },
  },
  mounted() {
    this.createOptions();
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    moment: require('moment'),
    years: [],
    options: [],
    currentYear: null,
  }),
  methods: {
    createOptions() {
      this.currentYear = parseInt(this.moment().format("YYYY"));
      this.options = clone(this.optionsYears);
      for (let countYear = 0; countYear < 6; countYear++) {
        const addYear = this.currentYear + countYear;
        if (typeof (this.options.find((option) => option.year == addYear)) === 'undefined') {
          this.options.push({
            disabled: false,
            quarters: [],
            year: addYear,
          })
        }
      }
    },
    async saveNewPeriod() {
      this.isLoading = true;
      this.dialog = false;
      const sendYears = {
        years: [...this.years]
      };
      const resultSavePeriod = await quartersService.createNewQuarters(sendYears);
      this.years = [];
      this.options = [];
      this.$emit('status', resultSavePeriod);
      this.isLoading = false;
    },
  },
}
</script>

<style scoped>
.grey-disabled {
  color: #929497;
}
</style>
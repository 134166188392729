<template>
  <label>
    {{ label }}
    <v-text-field
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
      @input="$emit('input', $event)"
    />
  </label>
</template>

<script>
export default {
  name: "VvTextInput",
  inheritAttrs: false,
  props: {
    value: {
      default: "",
    },
    label: {
      type: String,
    },
  },
};
</script>

<template>
  <div class="vv-rich-text">
    <tiptap-vuetify
      v-model="content"
      :extensions="extensions"
      :disabled="disabled"
      :error-messages="errorMessages"
      :rules="rules"
      :required="required"
      :class="errors.length > 0 ? 'error-message-rich-text' : ''"
      @input="clearRichError"
    />
    <div
      v-if="errors.length > 0"
      class="color-error-rich-text"
    >
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  History,
} from "tiptap-vuetify";

export default {
  name: "VvRichText",
  components: { TiptapVuetify },
  props: {
    value: {
      type: String,
      default: "<p></p>",
    },
    disabled: {
      type: Boolean,
    },
    errorMessages: {
        type: Array,
        default: () => []
    },
    rules: {
      type: Array
    },
    required: {
        type: Boolean,
        default: false
    },
  },
  data: () => ({
    extensions: [
      ListItem,
      History,
      Bold,
      Italic,
      Underline,
      Strike,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Paragraph,
      BulletList,
      OrderedList,
    ],
  }),
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    errors() {
      return this.errorMessages;
    },
  },
  methods: {
    clearRichError() {
      if(this.errors.length < 1){
        return;
      }
      this.$emit("errorChange", []);
    }
  }
};
</script>

<style lang="scss">
  $gap: 16px;
  $items: 4, 12, 20, 24;
  .vv-rich-text {
    .tiptap-vuetify-editor__content {
      [contenteditable]{
        padding: 16px;
        &.focus-visible{
          outline: #00d3c3 solid 2px;
        }
      }
    }
    .v-toolbar__content > div {
      @each $item in $items {
        & > button:nth-child(#{$item}) {
          margin-right: $gap;
        }
      }
    }
  }
  .error-message-rich-text {
    [contenteditable]{
      padding: 16px;
      outline: #cf1e1e solid 2px;
    }
  }
  .color-error-rich-text {
    color: #cf1e1e !important;
    caret-color: #cf1e1e !important;
    font-size: 12px;
    margin-top: 4px;
  }
  
</style>


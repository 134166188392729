import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#6A5AFF',
        secondary: '#00D3C3',
        accent: '#00A5A8',
        info: '#087BBC',
        error: '#CF1E1E',
        warning: '#FFB700',
        warningLight: '#FFB700',
        success: '#34751A',
        gray: '#5D5D5D',
        orange: '#FFE8D5',
        footer: '#6A5AFF',
        itext: '#FFFFFF',
        toolbarColor: '#00D3C3',
        toolbarTextColor: '#2A5F50',
        snackbarColor: '#e57373',
        snackbarColorSuccess: '#629c5a'
      },
      dark: {
        primary: '#6A5AFF',
        secondary: '#6A5AFF',
        accent: '#00A5A8',
        info: '#087BBC',
        error: '#CF1E1E',
        warning: '#00D3C3',
        warningLight: '#FFB700',
        success: '#6A5AFF',
        gray: '#5D5D5D',
        orange: '#FF502D',
        footer: '#6A5AFF'
      },
    },
  },
});

<template>
  <div>
    <vv-loading :is-loading="isLoading"></vv-loading>
    <h3>Macro Temas</h3>
    <div class="function-space">
      <div class="search-space">
        <text-input
            v-model="search"
            label="Busca"
            append-icon="mdi-magnify"
            class="text-input-project-list"
        />
      </div>
      <div class="buttons-space">
        <migrate-projects 
          :iniciativeName="iniciativeName" 
          :currentStep="currentStep"
          :permissionMigrate="permissionMigrate" />
        <modal-strategic-impacts
          :options-status="statusOption"
          :auto-open-modal-impacts="autoOpenModalImpacts"
          :errors-impacts="errorChangeStep"
          @reload-projects="loadSubprojects"
          v-if="createPermission"
        />
        <v-btn
          color="primary"
          @click="createNewProject"
          :disabled="!createPermission"
        >
          Novo macro tema
        </v-btn>
      </div>
    </div>

    <div v-if="errorChangeStep.subprojects ? errorChangeStep.subprojects : false">
      <v-alert
          dense
          outlined
          type="error"
      >{{ errorChangeStep.subprojects[0] }}</v-alert>
    </div>

    <v-data-table
        :headers="header"
        :items="subprojects"
        :mobile-breakpoint="0"
        :search="search"
        :expanded.sync="expandedProject"
        no-data-text="Nenhum macro tema cadastrado"
        item-key="id"
        hide-default-footer
        disable-pagination
        show-expand
        @item-expanded="loadSubprojectDetails"
        class="data-table-list-project elevation-1"
    >
      <template v-slot:item.id="{ item: {id} }">
        <div
            class="slot-id"
            :class="{
            'error-border': errorIds.includes(id),
          }">
          {{ id }}
        </div>
      </template>
      <template v-slot:item.value.statusId="{ item }">
        <v-autocomplete
            v-model="item.value.statusId"
            :items="statusOption"
            :disabled="!item.value.editPermission"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            class="select-table"
            no-data-text="Nenhum status encontrado..."
            @change="changeStatus(item.id)"
            solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <span
                v-bind="attrs"
                :input-value="selected"
                @click="select"
                label
                :class="(item.id === CONST_SUBPROJECT_STATUS.APROVADO) ? 'green-status' :
                        ((item.id === CONST_SUBPROJECT_STATUS.REPROVADO) ? 'red-status' : '')"
            >
              {{ item.name }}
            </span>
          </template>
        </v-autocomplete>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon v-if="item.new" size="19" color="#FF502D" @click="modalRemoveNewProject(item.value)">mdi-trash-can-outline</v-icon>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <form-sub-project
              v-model="item.value"
              :options-status="statusOption"
              :options-tags="tagsOption"
              :options-products="productsOption"
              :options-quarters="quartersOption"
              :origin-subproject="getOriginSubproject(item.id)"
              :error-list="errorList[indexOf(item)]"
              @update:errorList="value => updateErrorList(value, item)"
              @hasChange="(change) => ($emit('hasChange', change))"
          />
          <guide-objectives
              v-model="item.value"
              :items="optionsDrivers"
              :groups="options.driversCategory"
              :disabled="!item.value.editPermission"
          />
        </td>
      </template>
    </v-data-table>

    <confirmation-dialog
        :value="confirmationDialog.value"
        :title="confirmationDialog.title"
        :message="confirmationDialog.message"
        @confirmed="removeNewProject"
        @denied="confirmationDialog.value=false"
    />
  </div>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import TextInput from "@/components/common/VvTextInput";
import FormSubProject from "@/domains/subProject/components/FormSubProject/FormSubProject.vue";
import ConfirmationDialog from "@/components/common/VvConfirmationDialog";
import GuideObjectives from "@/domains/project/components/GuideObjectives";
import ModalStrategicImpacts from "@/domains/project/components/ModalStrategicImpacts";
import subprojectServiceFactory from "@/domains/subProject/services/subProjectService";
import MigrateProjects from "@/domains/project/components/MigrateProjects/MigrateProjects.vue";
import {clone} from "@/services/utils";

const subprojectService = subprojectServiceFactory();

export default {
  name: "ProjectList",
  components: {
    VvLoading,
    TextInput,
    FormSubProject,
    ConfirmationDialog,
    GuideObjectives,
    ModalStrategicImpacts,
    MigrateProjects
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    initiativeId: {
      type: Number,
      default: 0,
    },
    iniciativeName: {
      type: String,
      default: String
    },
    originSubproject: {
      type: Array,
      default: () => ([]),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    drivers: {
      type: Array,
      default: () => [],
    },
    errorList: {
      type: Array,
      default: () => [],
    },
    autoOpenModalImpacts: {
      type: Boolean,
    },
    errorChangeStep: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: Number,
      default: 0
    },
    permissionMigrate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    subprojects: {
      get() {
        return this.value.map((subproject) => ({ value: subproject, id: subproject.id, new: (subproject.new) }));
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    origin() {
      return this.originSubproject;
    },
    optionsDrivers() {
      const arrayOptions = this.options.drivers.map((driver) => {
        if (this.drivers.includes(driver.id)) {
          return driver;
        }
      });
      return arrayOptions.filter(n => n);
    },
    errorIds() {
      return this.errorList.map((value) => {
        const {id, error = {}} = value || {};
        return Object.values(error).length > 0 && id;
      });
    }
  },
  watch: {
    initiativeId(value) {
      const id = Number(value);
      if (isNaN(id)) return;
      this.loadSubprojects();
    },
    options() {
      this.statusOption = this.options.statusSubproject;
      this.tagsOption = this.options.tags;
    },
  },
  data: () => ({
    isLoading: false,
    createPermission: false,
    search: '',
    header: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Nome",
        value: "value.name",
      },
      {
        text: "Pontuação",
        value: "value.score",
      },
      {
        text: "Direcionadores envolvidos",
        value: "value.directions",
      },
      {
        text: "Status",
        value: "value.statusId",
        width: "17%",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
      {
        text: "",
        value: "data-table-expand",
      },
    ],
    expandedProject: [],
    statusOption: [],
    tagsOption: [],
    productsOption: [],
    quartersOption: [],
    newSubprojectId: 1,

    confirmationDialog: {
      value: false,
      title: 'Salvar alterações',
      message: 'Você realmente deseja salvar todas as alterações?',
      id: null,
    },
  }),
  methods: {
    createNewProject() {
      this.$emit('createNewProject');
    },
    openNewProject(project) {
      this.expandedProject = [];
      this.expandedProject.push(project);
      this.$nextTick(() => {
        document.getElementById('aboutProject').scrollIntoView();
        const yScroll = window.scrollY - 150;
        window.scrollTo(0, yScroll);
      });
    },
    modalRemoveNewProject(project) {
      const newProject = {
        id: project.id,
        new: true,
        name: null,
        score: null,
        directions: null,
        statusId: null,
        delivery: null,
        problems: null,
        macroScope: null,
        acceptanceCriteria: null,
        outScope: null,
        premises: null,
        restrictions: null,
      };
      this.confirmationDialog.id = project.id;
      if (JSON.stringify(project) !== JSON.stringify(newProject)) {
        this.confirmationDialog.title = 'Excluir macro tema';
        this.confirmationDialog.message = 'Você realmente deseja excluir esse macro tema?';
        this.confirmationDialog.value = true;
      } else {
        this.removeNewProject();
      }
    },
    removeNewProject() {
      let removeId;
      for (let indexProjects = 0; indexProjects < this.subprojects.length; indexProjects++) {
        if (this.subprojects[indexProjects].id === this.confirmationDialog.id) {
          removeId = indexProjects;
        }
      }
      this.confirmationDialog.value = false;
      this.confirmationDialog.id = null;
      this.$emit('removeNewProject', removeId);
    },
    async loadSubprojects() {
      this.isLoading = true;
      try {
        const resultProject = await subprojectService.loadProjects(this.initiativeId);
        const listSubprojects = resultProject.subprojects;
        if (listSubprojects.length > 0) {
          const subprojectsSort = [...listSubprojects];
          subprojectsSort.sort((a,b) => {return b.id-a.id});
          this.newSubprojectId = subprojectsSort[0].id + 1;
        }
        this.subprojects = listSubprojects;
        this.createPermission = resultProject.createPermission;
        this.$emit('addId', this.newSubprojectId);
        if (resultProject.dateCanvas !== null) {
          this.$emit('lastUpdateCanvas', resultProject.dateCanvas);
        }
        this.$emit('updateOriginSubproject');
        this.$emit('canEditCanvas', resultProject.createPermission);
        this.$nextTick(() => {
          if (typeof this.$route.query.subprojectId !== 'undefined') {
            this.expandSubprojectById(parseInt(this.$route.query.subprojectId));
          }
        })
      } catch (error) {
        const { response: { data: { message } = {} } = {} } = error;
        this.$showError(message || "Erro ao carregar os macro temas!");
      } finally {
        this.isLoading = false;
      }
    },
    async loadSubprojectDetails({item}) {
      if (typeof item.value.loaded === 'undefined' && typeof item.new === 'undefined') {
        this.isLoading = true;
        try {
          const resultProjectDetails = await subprojectService.loadProjectDetails(this.initiativeId, item.value.id);

          const detailProjetcOpen = resultProjectDetails.subproject;
          const fullSubproject = (subprojectItem) => {
            if (subprojectItem.id === detailProjetcOpen.id) {
              detailProjetcOpen.loaded = true;
              detailProjetcOpen.statusId = subprojectItem.value.statusId;
              if (detailProjetcOpen.acceptanceCriteria === null) detailProjetcOpen.acceptanceCriteria = "";
              return detailProjetcOpen;
            }
            return subprojectItem.value;
          };
          this.subprojects = this.subprojects.map(fullSubproject);
          this.$emit('updateOriginSubproject', item.id);
        } catch (error) {
          const { response: { data: { message } = {} } = {} } = error;
          this.$showError(message || "Erro ao carregar os dados do macro tema!");
        } finally {
          this.isLoading = false;
        }
      }
    },
    getOriginSubproject(value) {
      return this.origin.find(({id}) => id === value);
    },
    changeStatus(idProject) {
      const change = {
        id: idProject,
        hasChange: JSON.stringify(this.value) !== JSON.stringify(this.originSubproject)
      }
      this.$emit('hasChange', change)
    },
    indexOf(item) {
      return this.subprojects.findIndex(({id}) => id === item.id);
    },
    updateErrorList(value, item) {
      const index = this.indexOf(item);
      const error = value.error || {};
      const newErrorList = clone(this.errorList);
      newErrorList[index] = {
        id: item.id,
        error,
      };
      this.$emit("update:errorList", newErrorList);
    },
    expandSubprojectById(subprojectId) {
      if (!this.subprojects.map(({id}) => id).includes(subprojectId)) return;
      if (this.expandedProject.map(({id}) => id).includes(subprojectId)) return;
      const subproject = this.subprojects.find(({id}) => id === subprojectId);
      this.expandedProject.push(subproject);
      this.loadSubprojectDetails({item:subproject});
      this.$nextTick(() => {
        document.getElementById('aboutProject').scrollIntoView();
        const yScroll = window.scrollY - 150;
        window.scrollTo(0, yScroll);
      });
    },
  },
  mounted() {
    if (this.initiativeId > 0) {
      this.loadSubprojects();
      this.statusOption = this.options.statusSubproject;
      this.productsOption = this.options.products;
      this.tagsOption = this.options.tags;
      this.quartersOption = this.options.quarters;
    }
  },
}
</script>

<style lang="scss">
.text-input-project-list input {
  font-size: 12px;
}
.function-space {
  display: flex;
  justify-content: space-between;

  .search-space {
    width: calc(100% - 771px);
  }

  .buttons-space {
    display: flex;
    justify-content: space-between;
    width: fit-content; 
    padding-top: 29px;

    button {
      margin-left: 24px;
    }

    @media (max-width: 698px) {
      flex-wrap: wrap;
      
      button {
        margin-left: 0px !important;
        margin-bottom: 10px;
      }
    }
  }

  @media(max-width: 1264px) {
    display: block;

    .search-space {
      width: 100%;
    }

    .buttons-space {
      padding-top: 0px;
      padding-bottom: 27px;
      width: 100%;
    }
  }
}
.data-table-list-project {
  .v-data-table__expanded__content {
    box-shadow: none !important;
  }

  .v-data-table-header {
    background-color: #F4F5F5;
  }

  .select-table {
    .v-input__slot {
      box-shadow: none !important;
      background-color: transparent !important;
      margin: auto;
      padding-left: 0 !important;
      font-size: 14px;
      i {
        color: #DBDCDC
      }
    }
    .v-text-field__details {
      display: none;
    }
  }

  .error-border {
    border-left: 4px solid #FF502D !important;
  }

  .green-status {
    color: #699F10;
  }
  .red-status {
    color: #FF502D;
  }
}
</style>
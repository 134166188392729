import axios from "@/plugins/axios";

export default function jiraServiceFactory(){
  return {
    loadJiraLabels() {
      return axios
        .get(`v2/jira/list-jira-labels`)
        .then(res => res.data);
    },
  }
}
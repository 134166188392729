<template>
  <div id="FinancialPlanning">
    <div class="grid-12 gap-xl mb-5">
      <div class="g-col-3 switch-area">
        <switch-input
          v-model="capex"
          label="Necessita de Capex?"
          :disabled="canEditInception"
          :reactive-label="false"
          :manual-line="false"
          @change="capex || opex ? bcFlag = true : bcFlag = false" />
        <switch-input
          v-model="bcFlag"
          label="Necessita de BC?"
          :disabled="disableBcFlag"
          :reactive-label="false"
          :manual-line="false" />
        <switch-input
          v-model="opex"
          label="Necessita de Opex?"
          :disabled="canEditInception"
          :reactive-label="false"
          :manual-line="false"
          @change="opex || capex ? bcFlag = true : bcFlag = false" />
      </div>
      <div class="g-col-9 field-text-area">
        <div class="grid-10 gap-xl row-gap-none">
          <div class="g-col-6">
            <label> Custo estimado total</label>
            <vuetify-money
              v-model="value.estimatedCost"
              :options="moneyOptions"
              :disabled="canEditInception"
              :error-messages="getErrorInception('estimatedCost')"
              @input="clearErrorInception('estimatedCost')" />
          </div>

          <div class="g-col-4">
            <single-select
              v-model="value.bcStatusId"
              label="Status BC"
              :disabled="canEditInception"
              :items="options.bcStatus"
              item-value="id"
              item-text="name"
              no-data-text="Não tem opções disponíveis no momento"
              :error-messages="getErrorInception('bcStatusId')"
              @input="clearErrorInception('bcStatusId')" />
          </div>
          <div class="g-col-10">
            <div class="bc-upload">
              <label>
                Upload BC (.xls ou .xlsx)
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                      small 
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>O tamanho máximo por arquivo é de 8MB</span>
                </v-tooltip>
              </label>
              <div class="files" >
                <div class="file" v-if="issetFile && value.bcFileName != 'null'">
                  <div @click="downloadEvidence()">
                    <v-icon>mdi-file-outline</v-icon>
                    {{ value.bcFileName }}
                  </div>
                  <div v-if="!hasPreviusBcFile" @click="value.bcFile = null">
                    <v-icon>mdi-close</v-icon>
                  </div>
                </div>
                <template v-if="!canEditInception">
                  <v-file-input
                    v-model="bcFile"
                    class="d-none"
                    id="file"
                    @change="changeFileName()" />
                  <a
                    v-if="typeof getErrorInception('bcFile') === 'undefined'"
                    @click="openUploadWindow()">
                    Novo arquivo
                  </a>
                  <a
                    v-else
                    class="erro"
                    @click="openUploadWindow()">
                    Erro. Tente novamente
                  </a>
                </template>
                <template v-else>
                  <v-btn
                    outlined
                    :disabled="true"
                    color="success"
                  >Novo arquivo</v-btn>
                </template>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="g-col-12">
        <investment-order
            v-model="value.investmentOrder"
            :disabled="!this.permissions.canEditInceptionInvestmentOrder"
            :canDeleteInvestmentOrder="this.permissions.canDeleteInvestmentOrder"
            :error-messages="errorListInception"
            ref="investmentOrder"
            @onDisabled="onDisabled"
        ></investment-order>
      </div>
    </div>
    <expand v-model="showKpisEstrategicos" title="KPIs estratégicos" class="mb-7">
      <multiple-switchs
          :disabled="canEditInception"
        v-model="value.inceptionDrivers"
        :items="items"
        :groups="groups" />
    </expand>

    <div v-if="errorListInception.projectIndicators">
      <v-alert
          dense
          outlined
          type="error"
      >{{ errorListInception.projectIndicators[0] }}</v-alert>
    </div>

    <indicators-table 
      v-model="value.projectIndicators"
      :options="options"
      :canEditIndicators="canEditIndicators"
      :canEditInception="canEditInception"
      :inceptionDrivers="value.inceptionDrivers"
      :errorListInception.sync="errorListInception" />
  </div>
</template>

<script>
import SwitchInput from '@/components/common/VvSwitch.vue';
import SingleSelect from '@/components/common/VvSingleSelect.vue';
import MultipleSwitchs from "@/components/common/VvMultipleSwitchs";
import Expand from "@/components/common/VvExpand.vue";
import IndicatorsTable from '@/domains/project/components/IndicatorsTable.vue';
import inceptionServiceFactory from "@/domains/project/services/inceptionService";
import ErrorMessageHandler from '../mixins/ErrorMessageHandler';
import InvestmentOrder from '@/domains/project/components/InvestmentOrder/InvestmentOrder.vue';

const inceptionService = inceptionServiceFactory();

export default {
  components: {
    SwitchInput,
    SingleSelect,
    MultipleSwitchs,
    Expand,
    IndicatorsTable,
    InvestmentOrder
  },
  mixins: [
    ErrorMessageHandler
  ],
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    permissions: {
      type: Object,
      default: () => ({})
    },
    driversCategoryInception: {
      type: Array,
      default: () => []
    },
    errorListInception: {
      type: Object,
      default: () => ({})
    },
    hasPreviusBcFile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moneyOptions: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      },
      showKpisEstrategicos: true
    }
  },
  watch: {
    hasPreviusBcFile(value) {
      return value;
    }
  },
  computed: {
    disableBcFlag() {
      if (this.value.capex || this.value.opex || !this.permissions.canEditInception) {
        return true;
      }
      return false;
    },
    canEditInception() {
      return !this.permissions.canEditInception;
    },
    canEditIndicators() {
      return !this.permissions.canEditIndicators;
    },
    groups() {
      let tabs = [];
      if (typeof this.options.inceptionDrivers === 'object') {
        Object.keys(this.options.inceptionDrivers).forEach((index) => {
          if (this.driversCategoryInception.indexOf(this.options.inceptionDrivers[index].id) > -1) {
            tabs.push({
              id: this.options.inceptionDrivers[index].id,
              name: this.options.inceptionDrivers[index].name
            });
          }
        });
      }
      return tabs;
    },
    items() {
      let items = [];
      if (typeof this.options.inceptionDrivers === 'object') {
        Object.keys(this.options.inceptionDrivers).forEach((index) => {
          if (this.driversCategoryInception.indexOf(this.options.inceptionDrivers[index].id) > -1) {
            Object.keys(this.options.inceptionDrivers[index].inceptionDrivers).forEach((indexChildren) => {
              let value = false;
              if (Array.isArray(this.value.inceptionDrivers)) {
                if (this.value.inceptionDrivers.indexOf(this.options.inceptionDrivers[index].inceptionDrivers[indexChildren].id) > -1) {
                  value = true;
                }
              }
              items.push({
                name: this.options.inceptionDrivers[index].inceptionDrivers[indexChildren].name,
                id: this.options.inceptionDrivers[index].inceptionDrivers[indexChildren].id,
                driversCategoryId: this.options.inceptionDrivers[index].id,
                value: value
              });
            });
          }
        });
      }
      return items;
    },
    issetFile() {
      return this.value.bcFile === null || "" ? false : true;
    },
    capex: {
      get() {
        if (typeof this.value.capex === 'number') {
          return this.value.capex === 1 ? true : false;
        }
        return this.value.capex;
      },
      set(newValue) {
        this.value.capex = newValue;
      }
    },
    bcFlag: {
      get() {
        if (typeof this.value.bcFlag === 'number') {
          return this.value.bcFlag === 1 ? true : false;
        }
        return this.value.bcFlag;
      },
      set(newValue) {
        this.value.bcFlag = newValue;
      }
    },
    opex: {
      get() {
        if (typeof this.value.opex === 'number') {
          return this.value.opex === 1 ? true : false;
        }
        return this.value.opex;
      },
      set(newValue) {
        this.value.opex = newValue;
      }
    },
    bcFile: {
      get() {
        if (typeof this.value.bcFile === 'string') {
          return null;
        }
        return this.value.bcFile;
      },
      set(newValue) {
        this.value.bcFile = newValue;
      }
    }
  },
  methods: {
    openUploadWindow() {
      this.clearErrorInception('bcFile');
      let fileField = document.getElementById('file');
      fileField.click();
    },
    changeFileName() {
      this.value.bcFileName = this.value.bcFile.name;
    },
    async downloadEvidence() {
      this.$showLoading();
      const evidence = await inceptionService.downloadEvidence(this.$route.params.id);
      if (evidence.status === 200) {
        this.createAndDownloadBlobFile(this.base64ToArrayBuffer(evidence.data.file), evidence.data.filename, evidence.data.extension);
      }
      this.$hideLoading();
    },
    onSave() {
      this.$refs.investmentOrder.clearErrorMessage();
    },
    onDisabled(investmentOrderItem, action) {
      this.$emit('onDisabledInvestmentOrder', investmentOrderItem, action)
    },
  }
}
</script>

<style lang="scss">
  #FinancialPlanning {
    .switch-area {
      border: 1px solid #DBDCDC;
      border-radius: 8px;
      padding: 30px;
    }

    .field-text-area {
      padding: 30px 0;
    }

    .bc-upload {
      .files {
        margin-top: 16px;
        display: flex;

        .file {
          border: 1px solid #DBDCDC;
          border-radius: 6px;
          padding: 10px 15px;
          margin-right: 16px;
          margin-bottom: 16px;
          width: fit-content;
          cursor: pointer;
          display: flex;

          div {
            i {
              margin-right: 14px;
              color: #DBDCDC;
              font-size: 20px;
            }

            &:nth-child(2) {
              i {
                margin-right: 0px;
                margin-left: 26px;
                color: #929497;
              }
            }
          }
        }

        a {
          background: rgba(226, 222, 255, 0.2);
          border: 1px dashed #5D4EE8;
          border-radius: 8px;
          font-weight: 500;
          font-size: 14px;
          color: #5D4EE8 !important;
          padding: 9px 24px;
          height: 44px;
          margin-left: 16px;

          &.erro {
            border: 1px dashed #FF502D;
            color: #FF502D !important;
            background: rgba(255, 232, 213, 0.2);
          }
        }
      }
    }
  }
</style>
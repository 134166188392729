<template>
  <div class="grid-10 approvers">
    <div class="g-col-3" v-for="(approver, i) in approvers" :key="i">
      <div class="approvers-internal">
        <div class="approver" :class="treatClass(approver.status)">
          <p>
            {{ approver.userType !== '' ? approver.userType : userType(approver.userTypeId) }} 
            {{ typeof approver.moduleId !== 'undefined' && approver.moduleId !== null ? `(${moduleType(approver.moduleId)})` : '' }}
          </p>
          <div class="approver-name" :class="approver.delegate.delegateUserId != null ? 'delegate' : ''">
            {{ approver.delegate.delegateUserId != null ? approver.delegate.delegateUserName : approver.userName }}
            <v-tooltip bottom v-if="approver.delegate.delegateUserId != null">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#DBDCDC" dark v-bind="attrs" v-on="on" size="18px">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ approver.userName }}</span>
            </v-tooltip>
          </div>
          <div class="status-area">
            <div>
              <p class="last">Status:</p>
              <div class="status">
                {{ approver.status }}
              </div>
            </div>
            <div v-if="(typeof approver.date !== 'undefined')">
              <p class="last">Data:</p>
              <div class="status-data">
                {{ formattedDateForBr(approver.date) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    approvers: Array,
  },
  methods: {
    userType(userTypeId) {
      let type;
      switch (userTypeId) {
        case this.CONST_USER_TYPE.VSM:
          type = 'VSM';
          break;
        case this.CONST_USER_TYPE.GPM:
          type = 'GPM';
          break;
        case this.CONST_USER_TYPE.STAKEHOLDER:
          type = 'Stakeholder';
          break;
        case  this.CONST_USER_TYPE.PONTOS_FOCAIS:
          type = 'Ponto Focal';
          break;
        case  this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO:
          type = 'Gerente ET';
          break;
        case  this.CONST_USER_TYPE.PM_PO:
          type = 'PM/PO';
          break;
        case  this.CONST_USER_TYPE.RESPONSAVEL_PELO_EPICO:
          type = 'Responsável Pelo Épico';
          break;
      }
      return type;
    },
    moduleType(moduleId) {
      let type;
      switch (moduleId) {
        case this.CONST_MODULE.WORKFLOW:
          type = 'workflow';
          break;
        case this.CONST_MODULE.INICIATIVA:
          type = 'iniciativa';
          break;
        case this.CONST_MODULE.ENTREGAVEIS:
          type = 'épico';
          break;
        case this.CONST_MODULE.WORKFLOW_CR:
          type = 'change request';
          break;
        case this.CONST_MODULE.WORKFLOW_FE:
          type = 'formalização de entrega';
          break;
        case this.CONST_MODULE.CR_AREA_DEPENDENCIAS:
          type = 'novas dependências';
          break;
        case this.CONST_MODULE.DELEGACAO:
          type = 'delegado';
          break;
      }
      return type;
    },
    treatClass(className) {
      className = className.toLowerCase();
      return className.replace(' ', '-');
    }
  }
};
</script>

<style lang="scss">
.approvers {
  .approvers-internal {
    padding-right: 30px;

    .approver {
      border-radius: 6px;
      margin-bottom: 24px !important;
      padding: 16px;

      p {
        font-weight: normal;
        font-size: 10px;
        color: #5d5d5d;
        margin-bottom: 8px;

        &.last {
          margin-top: 16px;
        }
      }

      .approver-name {
        font-weight: normal;
        font-size: 14px;
        color: #202121;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &.delegate {
          font-style: italic;
        }
      }

      .status-area {
        display: flex;
        justify-content: space-between;

        .status {
          padding: 8px;
          border-radius: 17px;
          font-weight: normal;
          font-size: 12px;
          width: fit-content;
        }

        .status-data {
          color: #929497;
          font-size: 14px;
        }
      }

      &.aprovado {
        border: 1px solid #6da151;

        .status {
          background-color: #b2deb2;
          color: #6da151;
        }
      }

      &.pendente {
        border: 1px solid #dbdcdc;

        .status {
          background-color: #dbdcdc;
          color: #929497;
        }
      }

      &.reprovado {
        border: 1px solid #ff502d;

        .status {
          background-color: rgba(255, 80, 45, 0.25);
          color: #ff502d;
        }
      }

      &.encerramento-reprovado {
        border: 1px solid #FFA981;

        .status {
          background-color: #FFE8D5;
          color: #FF502D;
        }
      }

      &.não-necessário {
        border: 1px solid #b8daff;

        .status {
          background-color: #cce5ff;
          color: #004085;
        }
      }
    }
  }
}
</style>
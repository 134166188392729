import { render, staticRenderFns } from "./GuideObjectivesForm.vue?vue&type=template&id=133a375e&scoped=true&"
import script from "./GuideObjectivesForm.vue?vue&type=script&lang=js&"
export * from "./GuideObjectivesForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "133a375e",
  null
  
)

export default component.exports
export default {
  methods: {
    getError(param) {
      return this.errorMessages[param];
    },
    cleanError(param) {
      delete this.errorMessages[param];
    },

  },
};

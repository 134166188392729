<template>
  <div class="messages-shared">
    <div v-for="(message, i) in messages" :key="i" class="message-space">
      <div class="grid-10">
        <div class="g-col-10 message">
          <div class="message-data">
            <ul>
              <li>{{ message.userName }}</li>
              <li>-</li>
              <li>{{ message.createdAt }}</li>
            </ul>
          </div>
          <p>{{ message.message }}</p>
        </div>
        <div class="g-col-10" v-if="!disableResponse && i === 0">
          <v-btn
            plain
            elevation="0"
            color="#1A83B7"
            class="btn-response"
            @click="showResponseField = true"
          >
            <v-icon>mdi-reply</v-icon>
            Responder
          </v-btn>
        </div>
      </div>
      <div class="grid-10 rtl">
        <div
          class="g-col-9 response-field"
          v-if="showResponseField && !disableResponse && i === 0"
        >
          <div class="ltr grid-10">
            <div class="g-col-9 field">
              <label>Resposta:</label>
              <v-textarea
                filled
                v-model="response"
                hide-details
                class="mb-4"
              ></v-textarea>
              <v-btn outlined color="#5D4EE8" @click="sendMessage(i)"> Enviar </v-btn>
            </div>
          </div>
        </div>
        <div
          class="g-col-9 message response"
          v-for="(response, j) in message.answer"
          :key="j"
        >
          <div class="ltr">
            <div class="message-data">
              <ul>
                <li>{{ response.userName }}</li>
                <li>-</li>
                <li>{{ response.createdAt }}</li>
              </ul>
            </div>
            <p>{{ response.message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    messages: Array,
    status: Number,
    message: String,
    disableResponse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showResponseField: false,
      response: '',
    };
  },
  watch: {
    status(value) {
      return value;
    },
    message(value) {
      return value;
    }
  },
  methods: {
    sendMessage(index) {
      let emit = {
        index: index,
        message: this.response,
        parentId: this.messages[index].id
      };

      this.messages[index].answer.unshift({
        userName: 'Eu',
        createdAt: 'Agora',
        message: this.response
      });

      this.showResponseField = false
      this.response = ''

      this.$emit('messageData', emit);
    },
  }
};
</script>

<style lang="scss">
.messages-shared {
  .message-space {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0px;
    }

    .message {
      box-shadow: 2px 0px 0px 0px #dbdcdc inset;
      background-color: #fcfdfc;
      padding: 16px;
      text-align: left;

      &.response {
        margin-top: 16px;

        label {
          margin-bottom: 13px;
        }
      }

      .message-data {
        margin-bottom: 13px;
        display: inline-block;

        ul {
          list-style: none;
          padding-left: 0px;

          li {
            float: left;
            margin-right: 16px;
            font-size: 14px;
            font-weight: normal;
            color: #929497;

            &:first-child {
              color: #202121;
              font-weight: 600;
            }

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }

      p {
        margin-bottom: 0px;
        font-weight: normal;
        font-size: 14px;
        color: #6d6e70;
      }
    }

    .btn-response {
      font-size: 14px;
      margin-left: 32px;

      .v-icon {
        margin-right: 10px;
        font-size: 15px;
      }
    }

    .response-field {
      text-align: left;
      margin-top: 20px;

      .field {
        .v-textarea {
          margin-top: 13px !important;

          textarea {
            height: 74px;
            width: calc(100% - 16px);
          }
        }
      }

      .button {
        padding-top: 82px;
      }
    }
  }
}
</style>
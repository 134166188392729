<template>
  <v-container :fluid="true">
    <div v-if="env === 'PRD'">
      <v-alert
          style="margin-top: 10%"
          border="right"
          colored-border
          type="error"
          elevation="2"
      >
        Você não tem permissão para acessar essa funcionalidade.
      </v-alert>
    </div>
    <div v-else>
      <vv-loading :is-loading="isLoading"></vv-loading>
      <header-view class="mb-4 pl-4"></header-view>
      <div v-if="!$store.getters.isEmulated">
        <div class="data-table-custom">

            <v-data-table
                style="padding-top: 15px;"
                sort-by="name"
                :headers="headers"
                :items="users"
                :hide-default-footer="true"
                class="cursor icon-down"
                :items-per-page="-1"
                :search="search"
            >
              <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Procurar"
                    class="mx-4"
                ></v-text-field>
              </template>

              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-end">
                  <v-btn
                      title="Iniciar Emulação"
                      icon
                      @click="startEmulation(item)"
                  >
                    <v-icon>
                      mdi-play
                    </v-icon>
                  </v-btn>
                  <v-btn
                      title="Favoritar Usuário"
                      icon
                      @click="addUserToReservedList(item)"
                  >
                    <v-icon>
                      mdi-star-plus
                    </v-icon>
                  </v-btn>
                </div>
              </template>

            <template v-slot:no-data>
              <v-btn
                  class="ma-2"
                  outlined
                  color="primary"
                  :loading="isLoading"
                  @click="initialize"
              >
                Recarregar
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>

      <div v-if="$store.getters.isEmulated" style="margin-top: 20px;">
        <p>Você está agora, em uma sessão <strong>EMULADA</strong>. Todas as ações feitas por você, serão computadas, como se fossem feitas pelo usuário <strong>{{ $store.getters.userEmuled.name }}</strong></p>
        <p>Para voltar a utilizar seu usuário normal, clique no botão "Parar Emulação" posicionado no topo da Central Vai Via!</p>
      </div>
    </div>
  </v-container>

</template>
<script>
import HeaderView from "@/components/HeaderView";
import VvLoading from '@/components/VvLoading'

export default {
  components: {
    HeaderView, VvLoading
  },
  data: () => ({
    isLoading: false,
    headers: [
      {text: 'Nome do Usuário', value: 'name'},
      {text: 'E-mail do Usuário', value: 'email'},
      {text: 'Tipos', value: 'types_names'},
      {
        text: "Ações",
        value: "actions",
        sortable: false,
      },
    ],
    users: [],
    search: '',
    dialogMessage: false,
    typeMessage: 'success',
    textMessage: ''
  }),
  created() {
    this.initialize();
  },
  watch: {
    options: {
      handler() {
        this.fetchDatatable()
      },
      deep: true,
    }
  },
  computed: {
    env() {
      return process.env.VUE_APP_AMB;
    }
  },
  methods: {
    startEmulation(item) {
      this.$store.commit('startUserEmulation', item);
      document.location.reload(true);
    },
    addUserToReservedList(item) {
      this.$store.commit('addUserToReservedList', item);
      this.$showSuccess(`Usuário ${item.name} adicionado a lista de favoritos`);
    },
    initialize() {
      if (this.$store.getters.userToken) {
        this.isLoading = true;
        this.axios.get('emulation/list-users')
            .then((response) => {
              this.users = response.data;
            })
            .catch((error) => {
              this.dialogMessage = true
              this.textMessage = error.data.message
              this.typeMessage = 'error'
            })
            .finally(() => {
              this.isLoading = false;
              setTimeout(() => {
                this.dialogMessage = false;
                this.textMessage = '';
                this.dialog = false;
              }, 2000);
            })
      }
    },
    fetchDatatable: async function() {
      this.isLoading = true;
      this.setPagination();
      this.initialize();
    },

    reset() {
      this.payload = {};
      this.initialize();
    },
  }
}
</script>
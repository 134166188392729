import NotFound from "@/views/NotFound";
import OptionsManagement from "@/views/optionsManagement/Index";
import Project from "@/views/project/Index";
import ProjectCreateEdit from "@/views/project/CreateEdit";
import EpicList from "@/domains/epicList/views/Index/Index.vue";
import EpicCreateEdit from "@/views/epic/CreateEdit";
import Workflow from "@/views/workflow/Index";
import ChangeRequest from "@/views/workflow/ChangeRequest";
import DeliveryFormalization from "@/views/deliveryFormalization/Index";
import DeliveryFormalizationWorkflow from "@/views/workflow/DeliveryFormalization";
import ClosureWorkflow from "@/views/workflow/components/Closure/Closure.vue";
import Notification from "@/domains/notifications/views/Index/Index.vue";
import Indicators from "@/views/indicators/Index";
import Home from "@/views/home/Index";
import TreeManagement from "@/views/treeManagement/Index";
import UserEmulation from "@/views/emulation/Index.vue";
import Dashboard from "@/domains/dashboard/views/Index/Index.vue";
import ChangeRequestList from "@/views/changeRequest/Index";
import ListDelegateApprovals from "@/views/delegateApprovals/Index";
import MonthlyReports from "@/views/monthlyReports/Index"
import ProblemCreateEdit from "@/views/problem/CreateEdit";
import IdeaCreateEdit from "@/views/idea/CreateEdit";
import IdeaApproval from "@/views/workflow/IdeaApproval";
import ProblemList from "@/views/problem/Index";
import ReportDownload from "@/views/reports/Download";
import IdeaList from "@/views/idea/Index";
import OverviewProject from "@/domains/project/views/OverviewProject";
import ListQuarters from "@/domains/quarters/views/ListQuarters";
import CanvasModel from "@/domains/project/components/CanvasModel";
import Listing from "@/domains/subProject/views/Listing/Listing.vue";
import Users from "@/domains/users/views/Users/Users.vue";
import FAQ from "@/domains/faq/views/FAQ/FAQ.vue";
import BusinessTree from "@/domains/businessTree/views/Index/Index.vue";
import NewBusinessTree from "@/domains/businessTree/views/newBusinessTree/NewBusinessTree.vue";
import TechnologyTree from "@/domains/technologyTree/views/Index/Index.vue";
import NewTechnologyTree from "@/domains/technologyTree/views/NewTechnologyTree/NewTechnologyTree.vue";
import Auditoria from "@/domains/audit/views/Modules/Modules.vue";

const titlePage = 'Listagem de iniciativas'

const routes = [
    {
        name: titlePage,
        path: '/',
        component: Home
    },
    {
        name: titlePage,
        path: '/projetos',
        component: Project
    },
    {
        name: "Criação de Macro Tema",
        path: '/old/projeto/criar',
        component: ProjectCreateEdit
    },
    {
        name: "projectCreate",
        path: '/projeto/criar',
        component: OverviewProject,
        meta: {
            isCreating: true,
        },
    },
    {
        name: "Épicos",
        path: '/old/projeto/:id/epicos',
        component: EpicCreateEdit
    },
    {
        name: "Épicos",
        path: '/projeto/:id',
        component: OverviewProject,
        children: [
            {
                name: "Iniciativa",
                path: '',
                meta: {
                    tab: 0
                }
            },
            {
                name: "Macro Temas",
                path: 'visualizar',
                meta: {
                    tab: 1
                }
            },
            {
                name: "Épicos",
                path: 'epicos/:epicId?',
                meta: {
                    tab: 2
                },
                children: [
                    {
                        name: "Change Requests",
                        path: 'change-request/:changeId?',
                        meta: {
                            tab: 3
                        }
                    }
                ]
            },
            {
                name: "Change Requests",
                path: 'change-request',
                meta: {
                    tab: 3
                }
            },
            {
                name: "Formalização de Entrega",
                path: 'formalizacao/:formalizationId?',
                meta: {
                    tab: 4
                }
            }
        ]
    },
    {
        name: "Listagem Entregáveis",
        path: '/listagem/entregaveis',
        component: EpicList
    },
    {
        name: 'Gestão de opções',
        path: '/gestao/opcoes',
        component: OptionsManagement
    },
    {
        name: 'Gestão de Árvores',
        path: '/gestao/arvores',
        component: TreeManagement
    },
    {
        name: 'Workflow',
        path: '/workflow/iniciativas',
        component: Workflow
    },
    {
        name: 'Change Requests',
        path: '/workflow/change-requests',
        component: ChangeRequest
    },
    {
        name: 'Formalização de Entregas',
        path: '/workflow/formalizacao-entregas',
        component: DeliveryFormalizationWorkflow,
    },
    {
        name: 'Encerramento',
        path: '/workflow/encerramento',
        component: ClosureWorkflow
    },
    {
        name: 'Notificações',
        path: '/notificacoes',
        component: Notification
    },
    {
        name: 'Listagem Indicadores',
        path: '/listagem/indicadores',
        component: Indicators
    },
    {
        name: 'Gestão de Usuários',
        path: '/gestao/usuarios/master',
        redirect: '/gestao/usuarios',
    },
    {
        name: 'Listagem Formalização de Entregas',
        path: '/listagem/formalizacao-entregas',
        component: DeliveryFormalization
    },
    {
        name: 'Listagem Relatórios mensais',
        path: '/listagem/relatorios-mensais',
        component: MonthlyReports
    },
    {
        name: 'Emulação de Usuários',
        path: '/user-emu',
        component: UserEmulation
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard
    },
    {
        name: 'Listagem Change Request',
        path: '/listagem/change-request',
        component: ChangeRequestList
    },
    {
        name: 'Delegar Aprovações',
        path: '/gestao/delegar-aprovacoes',
        component: ListDelegateApprovals
    },
    {
        name: 'Cadastro de Dor',
        path: '/dor/criar',
        component: ProblemCreateEdit,
        meta: {
            isEditing: false
        }
    },
    {
        name: 'Edição de Dor',
        path: '/dor/:id',
        component: ProblemCreateEdit,
        meta: {
            isEditing: true
        }
    },
    {
        name: 'Cadastro de Ideia',
        path: '/ideia/criar',
        component: IdeaCreateEdit,
        meta: {
            isEditing: false
        }
    },
    {
        name: 'Cadastro de Ideia',
        path: '/ideia/criar/:id',
        component: IdeaCreateEdit,
        meta: {
            isEditing: false,
            createWithProblem: true
        }
    },
    {
        name: 'Edição de Ideia',
        path: '/ideia/:id',
        component: IdeaCreateEdit,
        meta: {
            isEditing: true
        }
    },
    {
        name: 'Edição de Ideia',
        path: '/ideia/criar/campanha/:id',
        component: IdeaCreateEdit,
        meta: {
            isEditing: false,
            createWithCampaign: true
        }
    },
    {
        name: 'Listagem de Ideia',
        path: '/listagem/ideias',
        component: IdeaList,
    },
    {
        name: 'Workflow de Ideias',
        path: '/workflow/ideias',
        component: IdeaApproval
    },
    {
        name: 'Listagem de Dores',
        path: '/listagem/dores',
        component: ProblemList
    },
    {
        name: 'Downloads',
        path: '/relatorios/download/:id',
        component: ReportDownload
    },
    {
        name: 'Quarters',
        path: '/gestao/quarters',
        component: ListQuarters
    },
    {
        name: 'Canvas Model',
        path: '/canvas/model/:initiativeId',
        component: CanvasModel
    },
    {
        name: 'Listagem Macro Temas',
        path: '/listagem/projetos',
        component: Listing
    },
    {
        name: 'Gestão de Usuários',
        path: '/gestao/usuarios',
        component: Users
    },
    {
        name: 'Ajuda',
        path: '/faq',
        component: FAQ
    },
    {
        name: 'Gestão de Árvores de Negócio',
        path: '/gestao/arvores-negocio',
        component: BusinessTree
    },
    {
        name: 'Nova Área',
        path: '/gestao/arvores-negocio/criar',
        component: NewBusinessTree
    },
    {
        name: 'Gestão de Árvores de Tecnologia',
        path: '/gestao/arvores-tecnologia',
        component: TechnologyTree
    },
    {
        name: 'Nova Jornada',
        path: '/gestao/arvores-tecnologia/criar',
        component: NewTechnologyTree
    },
    {
        name: 'Auditoria',
        path: '/auditoria',
        component: Auditoria,
    },
    {
        path: '*',
        component: NotFound
    }
];

export default routes;

import axios from "@/plugins/axios";

export default function epicListServiceFactory() {
    return {
        getEpics(params) {
            return axios
                .get(`v2/epic/`, { params: params })
                .then(res => res.data)
        },
        getFilterOptions() {
            return axios
                .get(`v2/epics/options/list/`)
                .then(res => res.data)
        }
    }
}
<template>
  <div class="div-header d-flex justify-space-between align-center mt-4">
    <h1 align="start" class="text-gray headerViewTitle">{{ $route.name }}</h1>
    <v-tooltip bottom v-if="tooltip && urlCreate">
      <template v-slot:activator="{ on, attrs }">
        <div
            v-bind="attrs"
            v-on="on"
            :class="{'not-allowed': disableButton}"
        >
          <v-btn
            outlined
            class="btn-cadastrar"
            color="primary"
            @click="createItem(urlCreate)"
            :disabled="disableButton"
          >
            <v-icon small>mdi-plus</v-icon>
            Cadastrar
          </v-btn>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <v-btn
      v-if="!tooltip && urlCreate"
      outlined
      class="btn-cadastrar"
      color="primary"
      @click="createItem(urlCreate)"
      :disabled="disableButton"
    >
      <v-icon small>mdi-plus</v-icon>
      Cadastrar
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'HeaderView',
  props: {
    urlCreate: String,
    disableButton: Boolean,
    tooltip: String
  },

  methods: {
    createItem(urlCreate) {
      this.$router.push(urlCreate);
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-cadastrar {
  min-width: 172px !important;
  max-width: 172px !important;
  margin-left: 8px;
  display: none;
}
.not-allowed {
  cursor: not-allowed;
}
</style>

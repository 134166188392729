import FormSubProject from "@/domains/subProject/components/FormSubProject/FormSubProject.vue";
import GuideObjectives from "@/domains/project/components/GuideObjectives";
import LinkedEpics from '@/domains/project/components/LinkedEpics.vue';

export default {
  props: {
    search: {
      type: String
    },
    subProjects: {
      type: Array
    },
    subProject: {
      type: Object
    },
    options: {
      type: Object
    },
    autoOpenProject: {
      type: Number,
    },
  },
  components: {
    FormSubProject,
    GuideObjectives,
    LinkedEpics
  },
  watch: {
    subProjects() {
      if (this.autoOpenProject > 0) {
        this.expandSubprojectById(this.autoOpenProject);
      }
    },
    subProject: {
      handler(){
        this.optionsDrivers()
      },
      deep: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id"
        },
        {
          text: "Nome",
          align: "start",
          value: "name"
        },
        {
          text: "Prioridade",
          align: "start",
          value: "score"
        },
        {
          text: "Direcionadores envolvidos",
          align: "start",
          value: "directions"
        },
        {
          text: "Épicos vinculados",
          align: "start",
          value: "epicCount"
        },
        {
          text: "",
          value: "expandButton"
        }
      ],
      expandedProjects: [],
      drivers: [],
      toggleById: null
    }
  },
  methods: {
    openDetails(row, slot) {
      if (!slot.isExpanded) {
        this.$emit('openProject', row.id);
      }
      this.toggleButton(row.id)
      slot.expand(!slot.isExpanded);
    },
    toggleButton(id) {
      if (this.toggleById === id) {
        this.toggleById = null;
      } else {
        this.toggleById = id;
      }
    },
    optionsDrivers() {
      if (typeof this.subProject !== 'undefined') {
        if (this.subProject.lenght > 0) {
          const arrayOptions = this.options.drivers.map((driver) => {
            if (this.subProject.drivers.includes(driver.id)) {
              return driver;
            }
          });
          this.drivers = arrayOptions.filter(n => n);
        }
      }
    },
    expandSubprojectById(subprojectId) {
      if (!this.subProjects.map(({id}) => id).includes(subprojectId)) return;
      if (this.expandedProjects.map(({id}) => id).includes(subprojectId)) return;
      const subproject = this.subProjects.find(({id}) => id === subprojectId);
      this.expandedProjects.push(subproject);
      this.$showLoading();
      this.$emit('openProject', this.autoOpenProject);
      this.$nextTick(() => {
        document.getElementById('aboutProject').scrollIntoView();
        const yScroll = window.scrollY - 250;
        window.scrollTo(0, yScroll);
      });
    },
  }
}
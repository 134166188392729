import { render, staticRenderFns } from "./ProjectList.vue?vue&type=template&id=3cc9add8&"
import script from "./ProjectList.vue?vue&type=script&lang=js&"
export * from "./ProjectList.vue?vue&type=script&lang=js&"
import style0 from "./ProjectList.vue?vue&type=style&index=0&id=3cc9add8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAlert,VAutocomplete,VBtn,VDataTable,VIcon})

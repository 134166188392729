<template>
  <v-container :fluid="true" class="px-3" id="listEpic">
    <vv-loading :is-loading="isLoading"></vv-loading>
    <div class="d-flex grid-8 mt-10 mb-8">
      <v-row>
        <v-col class="d-flex justify-start g-col-6">
          <h3 class="leftTitle">Entregáveis</h3>
        </v-col>
        <v-col class="d-flex justify-end g-col-6">
          <!--
          <vv-priority-epic 
            v-if="createEpicPermission"
            @changePriority="changePriority"
            :project_name="newProject.name"
            :project_id="newProject.id"
            :only-text-button="true"
            :loading="loadingChange"
            :label-before="true"
            color-button="#929497"
            align-title="left"
            label-button="Repriorizar"
            class-button="g-col-1 xs:g-col-4 md:g-col-2"
          />-->
          <v-btn
            color="primary"
            outlined
            class="ml-4 g-col-2 xs:g-col-4 md:g-col-2 mr-3 "
            @click="showAllEpics"
            v-if="project.canShowEpicsFinish === true"
          >
            {{ showFinishedEpics ? 'Ocultar entregas concluídas' : 'Ver entregas concluídas' }}
          </v-btn>
          <template v-else>
              <v-tooltip top max-width="219">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="ml-4 g-col-2 xs:g-col-4 md:g-col-2 mr-3 personal-disabled"
                  >
                    Ver entregas concluídas
                  </v-btn>
                </template>
                  <span>Não existem entregas concluídas nesta iniciativa.</span>
              </v-tooltip>
          </template>
          <v-btn
            color="g-col-1 xs:g-col-4 md:g-col-2 primary"
            :disabled="!createEpicPermission"
            @click="insertNewEpic()"
          >
            Novo épico
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div>
      <data-table-epics
        :items="dataTable.listEpics"
        :options="dataTable.options"
        :no-data-text="dataTable.noDataText"
        :completed-epic="false"
        :opened-epic="openEpicOnCreate"
        :focal-points-tap="project.focalPoints"
        :project="project"
        :origin-items="dataTable.originEpics"
        :error-list.sync="dataTable.errors.epics"
        :edited-list="epicsChanges"
        :permission-type-epic="permissionTypeEpic"
        @getDataEpic="setDataEpic"
        @hasChange="hasChange"
        @changeStatusId="changeStatusId"
        ref="dataTableEpics"
      ></data-table-epics>
    </div>
    <div v-if="showFinishedEpics" class="dataTableFinishedEpics">
      <h3 class="mt-12 mb-6">Entregas Concluídas</h3>
      <data-table-epics
        :items="dataTable.listFinishedEpics"
        :options="dataTable.options"
        :no-data-text="dataTable.noDataTextFinished"
        :completed-epic="true"
        :opened-epic="openEpicFinishedOnCreate"
        :focal-points-tap="project.focalPoints"
        :project="project"
        :origin-items="dataTable.originalFinishedEpics"
        :error-list.sync="dataTable.errors.finishedEpics"
        :edited-list="epicsChanges"
        @getDataEpic="setDataEpic"
        @hasChange="hasChange"
        ref="dataTableFinishedEpics"
      ></data-table-epics>
    </div>
    <v-footer class="d-flex grid-6 xs:grid-1 gap-md" padless id="footerFixed">
      <div class="row px-16 align-center">
        <div :class="(epicsChanges.length > 0) ? 'count-change-epic ml-11 d-flex justify-end col' : 'no-changes-epic ml-11 d-flex justify-end col'">
          <v-tooltip top v-if="epicsChanges.length > 0">
              <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="pt-2 d-flex justify-content-center"
              >
                <v-icon color="primary">mdi-eye</v-icon>
                {{ epicsChanges.length }} {{ (epicsChanges.length > 1) ? 'Épicos foram alterados' : 'Épico foi alterado' }}
              </span>
              </template>
            <span v-for="epicId in epicsChanges" :key="epicId">ID: {{ epicId }}<br></span>
          </v-tooltip>
          <span class="pt-2 d-flex justify-content-center" v-else>
            Nenhum Épico foi alterado
          </span>
        </div>
        <div class="save-all-epics d-flex justify-end col">
          <v-btn class="ml-12" color="primary" @click="validateIdJira" :disabled="!cantSaveEpic()">Salvar tudo</v-btn>
        </div>
      </div>
    </v-footer>
    <vv-confirmation-dialog
      :value="confirmationDialog.value"
      :title="confirmationDialog.title"
      :message="confirmationDialog.message"
      :has-empty-id-jira="confirmationDialog.idJira"
      :epic-type="confirmationDialog.epicType"
      @confirmed="saveAllEpics"
      @denied="confirmationDialog.value=false"
    ></vv-confirmation-dialog>
    <dialog-result
      :show="resultDialog.show"
      :success="resultDialog.success"
      :error="resultDialog.error"
      :total="resultDialog.total"
      @confirmed="closeResultDialogModal"
    ></dialog-result>
    <vv-alert
      v-model="alert.show"
      :message="alert.message"
      :type="alert.type"
      :dismissible="alert.dismissible"
    ></vv-alert>
  </v-container>
</template>

<script>
  import VvLoading from "@/components/VvLoading";
  import DataTableEpics from "./DataTableEpics";
  import DialogResult from "./DialogResult";
  //import VvPriorityEpic from "@/components/VvPriorityEpic";
  import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";
  import VvAlert from "@/components/common/VvAlert";
  import epicServiceFactory from "@/domains/epic/services/epicService";
  import ideaServiceFactory from "@/services/ideaService";
  import { clone } from "@/services/utils";

  const epicService = epicServiceFactory();
  const ideaService = ideaServiceFactory();

  export default {
    name: "ListEpic",
    props: {
      project: {
        type: Object,
      },
      createEpicPermission: {
        type: Boolean,
      },
    },
    components: {
      DataTableEpics,
      DialogResult,
      VvLoading,
      VvConfirmationDialog,
      VvAlert,
    },
    data: () => ({
      permissionTypeEpic: {
        canEditTypeEpic: false,
        onlyBusines: false
      },
      isLoading: false,
      openEpicOnCreate: null,
      openEpicFinishedOnCreate: null,
      startEpicBarPosition: null,
      heightFooterPage: null,
      isCreationNewEpic: false,
      nextNewEpicId: 0,
      expanded: [],
      showFinishedEpics: false,
      btnShowEpicsText: 'VER ÉPICOS FINALIZADOS',
      epicsChanges: [],
      dataTable: {
        noDataText: 'carregando...',
        noDataTextFinished: 'carregando...',
        listEpics: [],
        originEpics: [],
        LastChangeDetected: [],
        listFinishedEpics: [],
        originalFinishedEpics: [],
        canShowEpicsFinish: false,
        singleExpand: true,
        select: null,
        options: {
          stages: [],
          status: [],
          ideaList: [],
          impactedAreas: [],
        },
        errors: {
          epics: [],
          finishedEpics: [],
        }
      },
      newEpic: {
        assistedOperationBaseline: null,
        assistedOperationPlanned: null,
        blackFriday: false,
        dateJiraUpdated: null,
        deliveryBaseline: null,
        deliveryDone: {
          value: null,
          history: []
        },
        deliveryPlanned: {
          value: null,
          history: []
        },
        demandingArea: null,
        epicDescription: null,
        gpmId: null,
        id: 0,
        idJira: null,
        ideaId: null,
        interestedAreas: [],
        issues: [],
        journeyTribeDependency: [],
        journeysId: null,
        lastStatus: {
          value: null,
          date: null
        },
        name: null,
        pmpo: [],
        ppm: null,
        priority: null,
        progress: null,
        rolloutBaseline: null,
        rolloutPercentage: null,
        rolloutPlanned: null,
        squadKey: null,
        stageId: null,
        statusId: null,
        tags: [],
        tribesId: null,
        vsmId: null,
        investmentOrder: null,
      },
      confirmationDialog: {
        value: false,
        title: 'Salvar alterações',
        message: 'Você realmente deseja salvar todas as alterações?',
        idJira: false,
        epicType: null,
      },
      resultDialog: {
        show: false,
        success: 0,
        error: 0,
        total: 0,
      },
      alert: {
        show: false,
        message: '',
        type: 'info',
        dismissible: false,
      },
    }),
    computed: {
      newProject() {
        return this.project;
      },
      newEpicsCount() {
        return this.dataTable.listEpics.reduce((accumulator, current) => {
          if(typeof current.new !== 'undefined') accumulator++;
          return accumulator;
        }, 0)
      },
    },
    watch: {
    },
    mounted() {
      document.getElementById('footerFixed').classList.add('close-footer');
      this.heightFooterPage = document.getElementById('footerPage').clientHeight;
      this.startEpicBarPosition = document.getElementById('app').scrollHeight;
      this.footerBarScroll();
      window.addEventListener('scroll', this.footerBarScroll);
      if (this.$route.query.id) {
        let epicId = parseInt(this.$route.query.id);
        let epicFinished = false;
        if (this.$route.query.legacy) {
          epicFinished = true
        }
        this.checkStatusEpicsParam(epicId, epicFinished);
      }
      this.loadEpicsSummary(this.CONST_TIPO_LISTAGEM_EPICOS.ATIVOS);
    },
    destroyed() {
      window.removeEventListener('scroll', this.footerBarScroll);
    },
    methods: {
      changeStatusId(status, epicId) {
        let epicIndex = null
        this.dataTable.listEpics.map((item, index) => {
          if (item.id === epicId) {
            epicIndex = index
          }
        })
        this.dataTable.listEpics[epicIndex].statusId = status
      },
      footerBarScroll() {
        this.startEpicBarPosition = document.getElementById('app').scrollHeight;
        let footerBarOptions = document.getElementById('footerFixed');
        if ((pageYOffset + window.innerHeight) > (this.startEpicBarPosition - (this.heightFooterPage / 3))) {
          footerBarOptions.classList.add('close-footer');
        } else {
          footerBarOptions.classList.remove('close-footer');
        }
      },
      checkStatusEpicsParam(epicId, epicFinished) {
        if (!epicFinished) {
          this.openEpicOnCreate = epicId
        } else {
          this.openEpicFinishedOnCreate = epicId;
          this.showAllEpics();
        }
      },
      showAllEpics() {
        this.showFinishedEpics = !this.showFinishedEpics;
        this.btnShowEpicsText = (this.showFinishedEpics) ? 'OCULTAR ÉPICOS FINALIZADOS' : 'VER ÉPICOS FINALIZADOS';
        if (this.showFinishedEpics) {
          this.loadEpicsSummary(this.CONST_TIPO_LISTAGEM_EPICOS.CONCLUIDOS);
        }
        this.$nextTick(this.footerBarScroll());
      },
      async loadEpicsOptions() {
        const result = await epicService.loadEpicsOptions(this.$route.params.id);
        const {
          impactedAreas,
          projectArea: [projectArea] = [],
        } = this.project;
        this.dataTable.options = result.options;
        const filteredImpactedAreas = impactedAreas.filter(({id}) => id !== 1);
        if(typeof projectArea !== "undefined") filteredImpactedAreas.push(projectArea);
        this.dataTable.options.impactedAreas = filteredImpactedAreas;
        this.startNoApply(this.dataTable.options.journey_tribe_dependency, 1);
        this.startNoApply(this.dataTable.options.tags, 1);
      },
      async loadEpicsSummary(activeEpics = this.CONST_TIPO_LISTAGEM_EPICOS.ATIVOS) {
        try {
          this.isLoading = true;
          await this.loadEpicsOptions();
          const result = await epicService.loadEpicsSummary(this.$route.params.id, activeEpics);
          if (activeEpics === this.CONST_TIPO_LISTAGEM_EPICOS.ATIVOS) {
            this.dataTable.listEpics = result.epics;
            this.dataTable.originEpics = clone(result.epics);
            this.dataTable.noDataText = 'Nenhum épico cadastrado';
          } else {
            this.dataTable.listFinishedEpics = result.epics;
            this.dataTable.originalFinishedEpics = clone(result.epics);
            this.dataTable.noDataTextFinished = 'Nenhum épico concluído';
          }
          this.$nextTick(this.footerBarScroll());
          this.nextNewEpicId = this.dataTable.listEpics.length;
          const ideaId = Number(this.$router.currentRoute.query.ideia);
          if(!isNaN(ideaId)){
            this.insertNewEpic(ideaId);
          }

          const fromInception = Number(this.$router.currentRoute.query.frominception);
          if(!isNaN(fromInception)){
            this.insertNewEpic(null, fromInception);
          }
        } catch (error) {
          console.error(error)
        } finally {
          if (activeEpics === this.CONST_TIPO_LISTAGEM_EPICOS.ATIVOS) {
            window.scrollTo(0, window.scrollY + 1)
          } else {
            this.$vuetify.goTo(".dataTableFinishedEpics", { offset: 130 });
          }
          this.isLoading = false;
        }
      },
      async getListIdeas() {
        const resultIdea = await ideaService.loadListIdeas();
        this.dataTable.options.ideaList = resultIdea;
      },
      async insertNewEpic(ideaId = null, subProjectId = null) {
        this.isCreationNewEpic = true;
        const nextNewEpicId = this.nextNewEpicId + 1;
        const newEpicData = {
          id: nextNewEpicId,
          new: true,
          idJira: '',
          name: '',
          ideaId,
          subprojectId: subProjectId,
          priority: null,
          stageId: null,
          statusId: null,
          issues:[],
          ppm: '',
          progress: 0,
          deliveryPlanned: {
            value: null,
          },
          deliveryDone: {
            value: null,
          },
          permissions: {
            canEditFieldsPermission: true,
            canEditStakeholderFieldsPermission: true,
            createChangeRequest: false,
            createDeliveryFormalization: false,
            canEditSpecialsFields: true,
            editDeliveryBaseline: false,
            editDeliveryFormalization: false,
            validateTreeByEpic: true,
          }
        };
        if(ideaId !== null){
          this.openEpicOnCreate = nextNewEpicId;
        }
        this.isLoading = true;
        this.permissionTypeEpic = await epicService.permissionTypeEpic(this.$route.params.id, null);
        this.dataTable.listEpics.push(clone(newEpicData));
        this.dataTable.originEpics.push(clone(newEpicData));
        this.nextNewEpicId++;
        setTimeout(() => {
          window.scrollTo(0, window.scrollY - 100)
        }, 100);
        this.$refs.dataTableEpics.openNewEpic(newEpicData);
        this.isLoading = false;
      },
      async setDataEpic({ epic, doneEpic }) {
        const {
          listFinishedEpics,
          originalFinishedEpics,
          listEpics,
          originEpics,
        } = this.dataTable;

        if (!epic.new) {
          this.isLoading = true;
          setTimeout(() => {
            this.$vuetify.goTo("#aboutForm-" + epic.id, { offset: 305 });
          }, 100);

          const fullEpic = await epicService.loadDataEpic(this.$route.params.id, epic.id);
          this.permissionTypeEpic = await epicService.permissionTypeEpic(this.$route.params.id, epic.id);

          const setNewEpic = (epic) => {
            if (epic.id === fullEpic.epic.id) {
              fullEpic.epic.loaded = true;
              if (epic.stageId !== fullEpic.epic.stageId) {
                fullEpic.epic.stageId = epic.stageId;
              }
              if (epic.statusId !== fullEpic.epic.statusId) {
                fullEpic.epic.statusId = epic.statusId;
              }
              if (fullEpic.epic.epicDescription === null) fullEpic.epic.epicDescription = "";
              return clone(fullEpic.epic);
            }
            const data = clone(epic);
            if (data.epicDescription === null) data.epicDescription = "";
            return data;
          };
          if (doneEpic) {
            this.dataTable.listFinishedEpics = listFinishedEpics.map(setNewEpic);
            this.dataTable.originalFinishedEpics = originalFinishedEpics.map(setNewEpic);
          } else {
            this.dataTable.listEpics = listEpics.map(setNewEpic);
            this.dataTable.originEpics = originEpics.map(setNewEpic);
          }
          this.isLoading = false;
        } else {
          this.dataTable.listEpics = listEpics.map((epic) => (epic.id === 0) ? this.newEpic : epic);
        }
      },
      async saveAllEpics() {
        const {
          listEpics,
          listFinishedEpics,
          originEpics,
          originalFinishedEpics,
          errors: {
            epics: epicErrors,
          },
        } = this.dataTable;

        this.isLoading = true;
        this.confirmationDialog.value = false;

        const epicFilter = (epic) => typeof epic.new !== 'undefined' || this.epicsChanges.includes(epic.id);
        const createOrUpdateEpics = listEpics.filter(epicFilter);
        const createOrUpdateFinishedEpics = listFinishedEpics.filter(epicFilter);
        const createOrUpdateMergedEpics = [...createOrUpdateEpics, ...createOrUpdateFinishedEpics];

        const resultSaveEpics = await epicService.saveEpics(this.$route.params.id, createOrUpdateMergedEpics);
        this.updatePermissions(resultSaveEpics);
        this.updateSucessPayloads(resultSaveEpics);

        let newEpicIds = [];

        for (let count = 0; count < resultSaveEpics.length; count++) {
          if (typeof resultSaveEpics[count].payload.new !== 'undefined' && resultSaveEpics[count].payload.new === true) {
            if (typeof resultSaveEpics[count].response !== 'undefined') {
              newEpicIds.push(resultSaveEpics[count].response.data.epic.id)
            }
          }
        }

        const responseCount = resultSaveEpics.reduce((accumulator, {error, response, id}) => {
          if (typeof error !== 'undefined') {
            accumulator.errorCount++;
            accumulator.errorList.push({id, error: error.response?.data});
            accumulator.errorStatuses.push(error.response.status);
          }
          if (typeof response !== 'undefined') {
            accumulator.successCount++;
            accumulator.successList.push({id, response: response.data.epic});
          }
          return accumulator
        }, {successCount: 0, errorCount: 0, successList: [], errorList: [], errorStatuses: []});

        const {
          successCount,
          errorCount,
          errorList,
          successList,
        } = responseCount;
        const errorIds = errorList.map(({id}) => id);
        const successIds = successList.map(({id}) => id);

        if (errorList.permission !== undefined) {
          this.alert = {
              show: true,
              message: errorList.permission.shift(),
              type: 'error',
              dismissible: false,
          }
            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
        }

        if (errorCount > 0) {
          this.resultDialog.success = successCount;
          this.resultDialog.error = errorCount;
          this.resultDialog.total = successCount + errorCount;

          const findErrorBy = (id) => errorList.find((el) => el.id === id);
          const getMappedErrors = (arr = []) => Array(arr.length).fill()
              .map((_, index) => {
                const id = arr[index].id;
                return errorIds.includes(id) ? findErrorBy(id) : {}
              });
          this.dataTable.errors = {
            epics: getMappedErrors(listEpics),
            finishedEpics: getMappedErrors(listFinishedEpics),
          };

          this.resultDialog.show = true;
        }
        if (successCount > 0 && errorCount === 0) {
          const id = this.$route.query.id;
          this.checkStatusEpicsParam(parseInt(id), true);
          if (this.$route.query.frominception) {
            const query = Object.assign({}, this.$route.query);
            delete query.frominception;
            this.$router.replace({ query });
          }
          const url = id ? `?id=${id}` : this.$route.path;
          await this.updatePriorityEpic(newEpicIds);
          this.$router.go(url);
          this.alert = {
            show: true,
            message: 'Salvamento concluído',
            type: 'success',
            dismissible: false,
          }
          setTimeout(() => {
            this.alert.show = false;
          }, 3000);
        }

        function swapEpics(toCompare, origin, destiny, condition) {
          const originToDestiny = toCompare
              .filter((epic) => condition(epic));
          const originToDestinyIds = originToDestiny
              .map(({id}) => id);
          return {
            from: origin
                .filter((epic) => !originToDestinyIds.includes(epic.id)),
            to: [...destiny, ...clone(originToDestiny)],
          }
        }

        const findNewEpicBy = (id) => successList.find((epic) => epic.id === id);
        this.epicsChanges = this.epicsChanges.filter((id) => !successIds.includes(id));
        if (successIds.length > 0) {
          this.dataTable.listEpics = listEpics
              .map((epic) => {
                if (!epic.new || !successIds.includes(epic.id)) {
                  return epic;
                }
                const data = clone(findNewEpicBy(epic.id).response);
                if (data.epicDescription === null) data.epicDescription = "";
                return data;
              });
          this.dataTable.originEpics = originEpics
              .map((epic, index) => {
                if (!successIds.includes(epic.id)) return epic;
                const listEpic = listEpics[index];
                const data = clone(findNewEpicBy(epic.id).response || listEpic);
                data.loaded = listEpic.loaded;
                if (data.epicDescription === null) data.epicDescription = "";
                return data;
              });
          this.dataTable.errors.epics = epicErrors
              .map((epic) => (successIds.includes(epic?.id)) ? {} : epic);

          const {
            CONCLUIDO,
            CANCELADO,
          } = this.CONST_STATUS;
          const statusList = [CONCLUIDO, CANCELADO];

          if (createOrUpdateFinishedEpics.length > 0) {
            const statusNotIncluded = (epic) => !statusList.includes(epic.statusId);
            const {
              from: newFinishedEpics,
              to: newListEpics
            } = swapEpics(createOrUpdateFinishedEpics, listFinishedEpics, listEpics, statusNotIncluded);
            this.dataTable.listFinishedEpics = newFinishedEpics;
            this.dataTable.listEpics = newListEpics;
            const {
              from: newOriginFinishedEpics,
              to: newOriginListEpics
            } = swapEpics(createOrUpdateFinishedEpics, originalFinishedEpics, originEpics, statusNotIncluded);
            this.dataTable.originalFinishedEpics = newOriginFinishedEpics;
            this.dataTable.originEpics = newOriginListEpics;
            this.updatePermissions(resultSaveEpics);
          }
          if (createOrUpdateEpics.length > 0) {
            const statusNotIncluded = (epic) => statusList.includes(epic.statusId);
            const {
              from: newListEpics,
              to: newFinishedEpics
            } = swapEpics(createOrUpdateEpics, listEpics, listFinishedEpics, statusNotIncluded);
            this.dataTable.listFinishedEpics = newFinishedEpics;
            this.dataTable.listEpics = newListEpics;
            const {
              from: newOriginListEpics,
              to: newOriginFinishedEpics
            } = swapEpics(createOrUpdateEpics, originEpics, originalFinishedEpics, statusNotIncluded);
            this.dataTable.originalFinishedEpics = newOriginFinishedEpics;
            this.dataTable.originEpics = newOriginListEpics;
            this.updatePermissions(resultSaveEpics);
          }
          await this.updatePriorityEpic(newEpicIds);
        }

        this.isLoading = false;
      },
      async updatePriorityEpic(newEpicIds) {
        try {
          await epicService.updatePriority(this.$route.params.id, newEpicIds);
        } catch (error) {
          console.error(error)
        }
      },
      closeResultDialogModal() {
        this.resultDialog.success = 0;
        this.resultDialog.error = 0;
        this.resultDialog.total = 0;
        this.resultDialog.show = false;
      },
      /*
      changePriority(epics) {
        const findEpicById = (id) => epics.find((epic) => epic.id === id);
        this.dataTable.listEpics = this.dataTable.listEpics.map((epic) => {
          const priorityEpic = findEpicById(epic.id);
          const newEpic = clone(epic);
          newEpic.priority = priorityEpic.priority;
          return newEpic;
        });
        this.dataTable.originEpics = clone(this.dataTable.listEpics);
      },
      */
      hasChange(change) {
        const {id, hasChange} = change;
        const index = this.epicsChanges.findIndex((index) => id === index);
        if (hasChange) {
          this.epicsChanges = Array.from(new Set([...this.epicsChanges, id]));
        } else {
          if (index >= 0) {
            this.epicsChanges.splice(index, 1);
          }
        }
      },
      updatePermissions(resultSave) {
        this.dataTable.listEpics.forEach((successList, indexSuccessList) => {
          resultSave.forEach((resultList) => {
            if (successList.id === resultList.id) {
              if (typeof resultList.error === 'undefined') {
                this.dataTable.listEpics[indexSuccessList].permissions = resultList.response.data.epic.permissions;
                this.dataTable.listEpics[indexSuccessList].permissions.validateTreeByEpic = true;
              }
            }
          });
        });
      },
      updateSucessPayloads(resultSave) {
        this.dataTable.listEpics.forEach((successList, indexSuccessList) => {
          resultSave.forEach((resultList) => {
            if (successList.id === resultList.id) {
              if (typeof resultList.error === 'undefined') {
                if (resultList.response.data.epic.id !== this.dataTable.listEpics[indexSuccessList].id) {
                  this.dataTable.listEpics[indexSuccessList].id = resultList.response.data.epic.id;
                  this.dataTable.listEpics[indexSuccessList].new = false;
                }
                this.dataTable.originEpics[indexSuccessList] = {...this.dataTable.listEpics[indexSuccessList]};
              }
            }
          });
        });
      },
      cantSaveEpic() {
        return this.newEpicsCount > 0 || this.epicsChanges.length > 0;
      },
      validateIdJira() {
        this.confirmationDialog.idJira = false;
        const epicFilter = (epic) => typeof epic.new !== 'undefined' || this.epicsChanges.includes(epic.id);
        const createOrUpdateEpics = this.dataTable.listEpics.filter(epicFilter);
        const createOrUpdateFinishedEpics = this.dataTable.listFinishedEpics.filter(epicFilter);
        const createOrUpdateMergedEpics = [...createOrUpdateEpics, ...createOrUpdateFinishedEpics];
        createOrUpdateMergedEpics.forEach((epic) => {
          this.confirmationDialog.epicType = epic.epicTypeId;
          if (epic.idJira === "") {
            this.confirmationDialog.idJira = true;
          }
        });
        this.confirmationDialog.value = true;
      },
      loadingChange(status){
        this.isLoading = status;
      }
    },
  }
</script>
<style lang="scss">
  #listEpic {
    padding-bottom: 60px;

    #footerFixed {
      margin: 0 auto;
      padding: 12px 0;
      z-index: 4;
      width: 100%;
      left: 0;
      right: 0;
      position: fixed;
      bottom: 0px;
      transition: bottom 300ms;

      background-color: #FFFFFF;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 0px 0px;

      .see-finished-epic {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
      .count-change-epic {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: var(--text-primary);
      }
      .no-changes-epic {
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        text-align: center;
      }
      .save-all-epics {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }

      &.close-footer {
        bottom: 84px;
      }
    }
    .select-table {
      .v-input__slot {
        box-shadow: none;
        background-color: transparent;
        margin: auto;
        padding-left: 0;
        i {
          color: #DBDCDC
        }
      }
      .v-text-field__details {
        display: none;
      }
    }

    .btn-save-project {
      margin: 0;
      font-size: 14px;
    }

    .leftTitle {
      font-weight: 600 !important;
      line-height: 19.2px;
    }

    .personal-disabled {
      color: #DBDCDC !important;
      cursor: not-allowed;
    }
  }
</style>
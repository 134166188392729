<template>
  <div id="historyQuarters" v-if="showHistory">
    <v-expansion-panels v-model="isOpenHistory" accordion>
      <v-expansion-panel>
        <hr/>
        <v-expansion-panel-header>{{ labelHistory }}</v-expansion-panel-header>
        <v-expansion-panel-content class="remove-padding">
          <v-expansion-panels v-model="historyOpen">
            <v-expansion-panel v-for="(timeCourse) in quarters" :key="timeCourse.id" class="return-full-width">
              <v-expansion-panel-header v-slot="{ open }">
                <div class="grid-10">
                  <div class="g-col-2 xs:g-col-10 year-list mt-3">
                    {{ timeCourse.year }}
                  </div>
                  <div class="g-col-8 xs:g-col-10 text--secondary">
                    <v-fade-transition leave-absolute>
                  <span v-if="!open">
                    <v-chip v-for="(quarter) in timeCourse.quarters" :key="quarter.id" class="bg-green">
                      Q{{ quarter.quarterNumber }}
                    </v-chip>
                  </span>
                    </v-fade-transition>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="grid-2 xs:grid-1 gap-md">
                  <v-card class="pa-6 bg-green" v-for="(quarter) in timeCourse.quarters" :key="quarter.id">
                    <div class="grid-8 xs:grid-5 md:grid-5 gap-md">
                      <div class="g-col-2 title-quarters">
                        Q{{ quarter.quarterNumber }}
                      </div>
                      <div class="g-col-3 xs:g-col-2 md:g-col-2">
                        <label>De:</label>
                        <vv-date-pick
                            :disabled="true"
                            :clear-field="false"
                            :using-button="false"
                            :append-icon="null"
                            v-model="quarter.dateStart"
                        ></vv-date-pick>
                      </div>
                      <div class="g-col-3 xs:g-col-2 md:g-col-2">
                        <label>Até:</label>
                        <vv-date-pick
                            :disabled="true"
                            :clear-field="false"
                            :using-button="false"
                            :append-icon="null"
                            :min-date="quarter.dateStart"
                            v-model="quarter.dateEnd"
                        ></vv-date-pick>
                      </div>
                      <!--
                      <div class="g-col-3 xs:g-col-5 md:g-col-5">
                        <label>Data final do planejamento:</label>
                        <vv-date-pick
                            :disabled="true"
                            :clear-field="false"
                            :using-button="false"
                            :append-icon="null"
                            :min-date="quarter.dateStart"
                            :max-date="quarter.dateEnd"
                            v-model="quarter.datePlanning"
                        ></vv-date-pick>
                      </div>
                      -->
                    </div>
                    <div class="grid-12 gap-md xs:grid-2 md:grid-2">
                      <div class="g-col-12 xs:g-col-2 md:g-col-2">
                        <label>Label Jira</label>
                        <v-autocomplete
                            :items="listJiraLabel"
                            :disabled="true"
                            item-text="name"
                            item-value="id"
                            v-model="quarter.jiraLabelId"
                            append-icon="mdi-chevron-down"
                            no-data-text="Nenhum label jira encontrado..."
                        ></v-autocomplete>
                      </div>
                      <!--
                      <div class="g-col-2">
                        <label>Assinar PT</label>
                        <v-switch
                            :disabled="true"
                            color="secondary"
                            class="switch"
                            v-model="quarter.assingPt"
                            inset
                        ></v-switch>
                      </div>
                      -->
                    </div>
                  </v-card>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import VvDatePick from "@/components/VvDatePick";

export default {
  name: "HistoryQuarters",
  components: {
    VvDatePick,
  },
  props: {
    quarters: {
      type: Array,
      default: () => [],
    },
    listJiraLabel: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showHistory() {
      return this.quarters.length > 0
    },
    labelHistory() {
      if (typeof this.isOpenHistory === 'undefined') {
        return 'ver histórico';
      }
      return 'fechar histórico';
    },
  },
  data: () => ({
    isOpenHistory: undefined,
    historyOpen: null,
  }),
}
</script>

<style lang="scss">
#historyQuarters {
  margin-top: 20px;

  .v-expansion-panel {
    hr {
      border: none;
      background: #929497;
      height: 1px;
      width: calc(100% - 222px);
      margin: 0px auto -26px;
    }

    &.v-expansion-panel--active {
      &.v-item--active {
        hr {
          margin-bottom: -34px;
        }
      }
    }

    .v-expansion-panel-header {
      width: fit-content;
      background-color: #ffffff;
      margin: auto;
      padding: 0px 16px;
      color: #929497;
      font-size: 14px;
    }

    .return-full-width {
      background-color: #F5F5F5;
      padding: 20px 16px;
      .v-expansion-panel-header {
        background-color: #F5F5F5;
        width: 100%;
      }
    }

    .v-expansion-panel-content {
      padding-top: 23px;

      .v-expansion-panel-content__wrap {
        padding: 0px;
      }
    }

    .remove-padding {
      .v-expansion-panel-content__wrap {
        padding: 0px;
      }
    }

    .year-list {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .title-quarters {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    color: #202121;
  }

  span.bg-green {
    background-color: rgba(105, 159, 16, 0.5);
    color: #FCFDFC;
  }

  .v-card {
    &.bg-green {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 0px rgba(105, 159, 16, 0.5);
    }
  }
}
</style>
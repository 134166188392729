<template>
  <v-container :fluid="true">
    <vv-loading :is-loading="isLoading"></vv-loading>

    <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />

    <v-card>
      <h2 class="title-form mb-0 pa-4 pb-0">Change Requests</h2>
      <v-card-title>
        <v-text-field
          v-model="search"          
          append-icon="mdi-magnify"
          label="Filtrar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-row>
        <v-col cols="12" sm="12" class="align-end" v-if="selected.length" align="end">
          <v-btn
              class="col-11 ml-4 col-xl-2 col-md-3 col-sm-4 btn-spaced"
              color="primary"
              dark
              @click="approvedLot()"
              :loading="isLoading"
          >
            <v-icon small>mdi-check</v-icon>
            Aprovar Selecionados
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-model = "selected"
        :headers="list.headers"
        :items="list.changeRequests"
        :search="search"
        show-select
        hide-default-footer
        disable-pagination
        mobile-breakpoint="0"
        no-data-text="Nenhuma Aprovação Pendente"
        class="elevation-1 mt-3"
      >
        <template v-slot:item.actions="{ item }">
          <div class="d-flex">
            <v-btn
              class="ma-1 pa-0"
              text
              :disabled="checkStatus('go',item.epic_id)"
              color="warning"
              min-width="40"
              @click="goToChangeRequest(item)"
            >
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
            <v-btn
              class="ma-1 pa-0"
              text
              :disabled="checkStatus('approve',item.epic_id)"
              color="secondary"
              min-width="40"
              @click="modalApproved(item)"
            >
              <v-icon> mdi-check-circle-outline </v-icon>
            </v-btn>
            <v-btn
              class="ma-1 pa-0"
              text
              :disabled="checkStatus('disapprove',item.epic_id)"
              color="error"
              min-width="40"
              @click="modalDisapproved(item)"
            >
              <v-icon> mdi-close-circle-outline </v-icon>
            </v-btn>
            <v-btn
              class="ma-1 pa-0"
              text
              :disabled="checkStatus('doubt',item.epic_id, item.user_requester)"
              color="info"
              min-width="40"
              @click="modalDoubt(item)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal de Aprovação -->
    <v-dialog v-model="dialog.approved" width="500" persistent>
      <v-card>
        <v-card-title> Modal de Aprovação </v-card-title>

        <v-card-text class="mt-5 mb-3">
          Você realmente deseja aprovar essa Change Request?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click="closeModal"> Fechar </v-btn>
          <v-btn color="primary" @click="approved"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de Reprovação -->
    <v-dialog v-model="dialog.disapproved" width="500" persistent>
      <v-card>
        <v-card-title> Modal de Reprovação </v-card-title>

        <v-card-text class="mt-3">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  name="input-7-1"
                  label="Justificativa da reprovação da Change Request"
                  v-model="justify_disapproved"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click="closeModal"> Fechar </v-btn>
          <v-btn color="error" @click="disapproved"> Reprovar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de Dúvidas -->
    <v-dialog v-model="dialog.doubt" width="500" persistent>
      <v-card>
        <v-card-title> Modal de Dúvidas </v-card-title>

        <v-card-text class="mt-3">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  name="input-7-1"
                  counter
                  label="Escreva a sua dúvida sobre a Change Request"
                  v-model="question"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click="closeModal"> Fechar </v-btn>
          <v-btn color="info" @click="doubt"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de erro -->
    <v-dialog v-model="dialog.error" width="500" persistent>
      <v-card>
        <v-card-title> {{ error.title }} </v-card-title>

        <v-card-text class="mt-5 mb-3">
          {{ error.message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeModal"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";
export default {
  components: {
    VvBreadcrumbs,
    VvLoading,
  },
  data: () => ({
    isLoading: true,
    search: "",
    dialog: {
      approved: false,
      disapproved: false,
      doubt: false,
      error: false,
    },
    selected : [],
    error: {
      message: "",
      title: "Problema"
    },
    modal_item: 0,
    justify_disapproved: "",
    question: "",
    breadcrumb: [
      {
        text: 'Workflow',
        disabled: true,
      },
      {
        text: 'Change Requests',
        disabled: false,
      },
    ],
    list: {
      headers: [
        {
          text: "ID Iniciativa",
          value: "project_id",
          sortable: false,
          align: "center",
        },
        {
          text: "Área Responsável",
          value: "area_name",
          sortable: false,
          align: "center",
        },
        {
          text: "ID Entregável",
          value: "epic_id",
          sortable: false,
          align: "center",
        },
        {
          text: "ID Épico Jira",
          value: "id_jira",
          sortable: false,
          align: "center",
        },
        {
          text: "Entregável",
          value: "epic_name",
          sortable: false,
          align: "center",
        },
        {
          text: "Motivo CR",
          value: "description",
          sortable: false,
          align: "center",
        },
        {
          text: "Origem Soliticação",
          value: "origin",
          sortable: false,
          align: "center",
        },
        {
          text: "Classificação",
          value: "classification",
          sortable: false,
          align: "center",
        },
        {
          text: "Impactos",
          value: "textImpact",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "20%",
          align: "center",
        },
      ],
      changeRequests: [
      ]
    }
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize: function () {
      
      this.axios
        .get("cr-workflow", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.list.changeRequests = Array.isArray(response.data) !== true ? [] : response.data;
          this.parseImpactOptions();
        })
        .catch((error) => {
          this.dialog.error = true;
          this.error.message = error.response.data.message;
        })
        .finally(() => {
          this.isLoading = false;
        });

    },

    modalApproved(item) {
      this.dialog.approved = true;
      this.modal_item = [item.id];      
    },

    approvedLot(){
      if(this.selected.length > 0){
        this.dialog.approved = true;
        let approveds = this.selected.map(function(it){
          return it.id;
        });
        this.modal_item = approveds;
        return;
      }
    },

    modalDisapproved(item) {
      this.dialog.disapproved = true;
      this.modal_item = item.id;
    },

    modalDoubt(item) {
      this.dialog.doubt = true;
      this.modal_item = item.id;
    },

    closeModal() {
      this.dialog.approved = false;
      this.dialog.disapproved = false;
      this.dialog.doubt = false;
      this.dialog.error = false;
      this.justify_disapproved = "";
      this.question = "";
      this.error.message = "";
      this.modal_item = 0;
      this.modal_item_area_name = "";
    },

    checkStatus(action, id, user = -1) {
      if(id) {
        if(action == 'approve')
          return false;
        if(this.selected.length > 0){
          return true;
        }
        if(action == 'doubt' && (this.$store.getters.id == user)) return true;
        return false;
      }
      return true;
    },

    approved() {
      if ( this.modal_item.length > 0 ) {
        this.isLoading = true;
        let submit = { changeRequestId: this.modal_item };
        this.axios
          .post("v2/change-request/pass", submit)
          .then((response) => {            
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            console.log("Aprovado", response);
          })
          .catch((error) => {
            this.error.message = error.response.data.message;
            this.dialog.error = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      this.closeModal();
    },

    disapproved() {
      if (this.justify_disapproved.trim() !== "") {
        if (this.modal_item > 0) {
          this.isLoading = true;
          let submit = {
            change_request_id: this.modal_item,
            refused_cause: this.justify_disapproved,
          };
          this.axios
            .post("cr-workflow/fail", submit)
            .then((response) => {
              setTimeout(() => {
                window.location.reload();
              }, 500);
              console.log("Reprovado", response);
            })
            .catch((error) => {
              this.error.message = error.response.data.message;
              this.dialog.error = true;
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
        this.closeModal();
      }
    },

    doubt() {
      if (this.question.trim() !== "") {
        if (this.modal_item > 0) {
          this.isLoading = true;


          let submit = {         
            message: this.question,
          };
          let endpoint = `v2/message/change-request/${this.modal_item}`;
          this.axios
            .post(endpoint, submit)
            .then((response) => {
              setTimeout(() => {
                window.location.reload();
              }, 500);
              console.log("Duvida enviada", response);
            })
            .catch((error) => {
              this.error.message = error.response.data.message;
              this.dialog.error = true;
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
      this.closeModal();
    },

    goToChangeRequest(changeRequest) {
      this.$router.push(`/projeto/${changeRequest.project_id}/epicos/${changeRequest.epic_id}/change-request/${changeRequest.id}`);
    },

    parseImpactOptions() {
      let str_impacts = '';

      Object.keys(this.list.changeRequests).forEach((key) => {
        Object.keys(this.list.changeRequests[key].impacts).forEach((impact) => {
          str_impacts += this.list.changeRequests[key].impacts[impact] + ' | ';
        });
        str_impacts = str_impacts.slice(0, -3);
        this.list.changeRequests[key].textImpact = str_impacts;          
        str_impacts = '';
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table tr:nth-child(even) {
  background: #efefef;
}

.v-dialog > .v-card > .v-card__title {
  font-size: 1.2rem;
}

.btn-spaced {
  margin-top: 10px;
  margin-right: 20px;
}

.v-data-table {
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            &:not(.v-data-table__mobile-row) {
              &:not(:first-child) {
                border-left: thin solid rgba(0, 0, 0, 0.12);
              }
            }
          }
        }
      }
    }
  }
}
</style>

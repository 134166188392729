<template>
  <v-overlay style="z-index: 10000;" :value="isLoading">
    <v-progress-circular
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'VvLoading',
  props: {
    isLoading: Boolean
  },
}
</script>

<template>
    <div id="IssuesForm">
      <div class="grid-10 md:grid-12 gap-md pb-6 pt-10" :class="(index > 0) ? 'issues-border' : ''" v-for="(issue, index) in item.issues" :key="index">
        <div class="g-col-5 md:g-col-6">
          <label>Problema/Ponto de Atenção</label>
          <v-textarea
              :disabled="!canEditFieldsPermission"
              v-model="issue.problem"
              class="scroll-style"
              :error-messages="getError(`issues.${index}.problem`)"
              @input="cleanError(`issues.${index}.problem`)"
          ></v-textarea>
        </div>
        <div class="g-col-5 md:g-col-6">
          <label>Plano de ação</label>
          <v-textarea
              :disabled="!canEditFieldsPermission"
              v-model="issue.actionPlan"
              class="scroll-style"
              :error-messages="getError(`issues.${index}.actionPlan`)"
              @input="cleanError(`issues.${index}.actionPlan`)"
          ></v-textarea>
        </div>
        <div class="g-col-3 md:g-col-3">
          <label>Responsável pelo plano de ação</label>
          <v-text-field
              :disabled="!canEditFieldsPermission"
              v-model="issue.owner"
              :error-messages="getError(`issues.${index}.owner`)"
              @input="cleanError(`issues.${index}.owner`)"
          ></v-text-field>
        </div>
        <div class="g-col-2 md:g-col-3">
          <label>Entregável (planejado)</label>
          <vv-date-pick
              :disabled="!canEditFieldsPermission"
              :clear-field="true"
              :using-button="false"
              v-model="issue.date"
              class="ajust-font"
              :error-messages="getError(`issues.${index}.date`)"
              @input="cleanError(`issues.${index}.date`)"
          ></vv-date-pick>
        </div>
        <div class="g-col-1 md:g-col-1">
          <v-btn
              color="error"
              class="mt-4"
              text
              @click="modalRemoveIssue(issue, index)"
              :disabled="canDisableDeleteProblem"
          ><v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
        <div class="g-col-4 md:g-col-3">
          <v-btn
              color="primary"
              outlined
              class="float-right mt-4"
              v-if="checkIssueUnlock(item.statusId, item.new) && lastIssue(item.issues, index)"
              @click="addIssue"
              :disabled="!canEditFieldsPermission"
          >Novo ponto de atenção</v-btn>
        </div>
      </div>
      <vv-confirmation-dialog
          :value="confirmationDialog.value"
          :title="confirmationDialog.title"
          :message="confirmationDialog.message"
          @confirmed="removeIssue()"
          @denied="confirmationDialog.value=false"
      ></vv-confirmation-dialog>
    </div>
</template>

<script>
import ErrorMessageHandler from "../mixins/ErrorMessageHandler";
import epicRulesFactory from "@/domains/epic/rules/epicRules";
import vvDatePick from "../../../components/VvDatePick.vue";
import vvConfirmationDialog from "../../../components/common/VvConfirmationDialog.vue";

const epicRules = epicRulesFactory();

export default {
    name: "IssuesForm",
    mixins: [
        ErrorMessageHandler
    ],
    props: {
        item: {
            type: Object
        },
        errorList: {
            type: Object,
            default: () => ({}),
        },
        options: {
            type: Object
        },
        completedEpic: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: () => [],
        }
    },
    data: () => ({
      issues: [],
      confirmationDialog: {
        value: false,
        title: 'Salvar alterações',
        message: 'Você realmente deseja salvar todas as alterações?',
        id: null,
      },
    }),
    components: {
      vvDatePick,
      vvConfirmationDialog
    },
    computed: {
        canDisableDeleteProblem() {
            return epicRules.checkDisableDeleteProblem(this.item.statusId, this.item.issues);
        },
        canEditFieldsPermission() {
            return this.item.permissions?.canEditFieldsPermission;
        },
    },
    watch:{
        'item.deliveryBaseline'(newData, oldData){
            if ((oldData === undefined && newData === null) || this.item.new === true) {
                this.disabledDeliveryBaseline = false
            } else if ( this.disabledDeliveryBaseline == null) {
                this.disabledDeliveryBaseline = true
            }
        }
    },
    methods: {
        addIssue() {
            let objNewIssue = {
                problem: null,
                actionPlan: null,
                owner: null,
                date: null,
            }
            this.item.issues.push(objNewIssue);
        },
        removeIssue() {
            this.item.issues.splice(this.confirmationDialog.id, 1);
            this.confirmationDialog.value = false;
            this.confirmationDialog.id = null;
            this.confirmationDialog.title = '';
            this.confirmationDialog.message = '';
        },
        modalRemoveIssue(issue, idIssue) {
            this.confirmationDialog.id = idIssue;
            if (this.validCallDialog(issue)) {
                this.confirmationDialog.title = 'Excluir ponto de atenção';
                this.confirmationDialog.message = 'Você realmente deseja excluir esse ponto de atenção?';
                this.confirmationDialog.value = true;
            } else {
                this.removeIssue()
            }
        },
        validCallDialog(issue) {
          const issueEmpty = {
            problemDescription: null,
            actionPlan: null,
            userToActionPlan: null,
            plannedDate: null
          };
          return JSON.stringify(issue) !== JSON.stringify(issueEmpty);
        },
        checkIssueUnlock(statusId, newEpic) {
            return epicRules.checkIssueUnlock(statusId, newEpic);
        },
        lastIssue(issues, index) {
            return epicRules.lastIssue(issues, index);
        },
    }
}
</script>

<style lang="scss">
#IssuesForm {
  h3 {
    font-size: 15px;
    color: #6d6e70;
    &::before {
      content: "•";
      margin-right: 5px;
      font-size: 18px;
      position: relative;
      top: 1px;
    }
  }

  fieldset {
    border: 1px solid #dbdcdc;
    border-radius: 8px;

    .v-chip.v-size--default {
      font-size: 12px;
    }

    label {
      white-space: nowrap;
    }
  }

  .v-input {
    font-size: 14px;
  }
}
</style>
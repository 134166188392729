export default function FilterConfigs() {
    return {
        loadFields() {
            return [
                {
                    type: "text",
                    label: "ID Épico Jira",
                    sm: "4",
                    col: "4",
                    id: "idJira",
                    name: "idJira",
                    value: "",
                    payloadBackend: "idJira[like]",
                    items: [],
                },
                {
                    type: "number",
                    label: "ID Épico",
                    sm: "4",
                    col: "4",
                    id: "epicId",
                    name: "epicId",
                    value: "",
                    payloadBackend: "epicId[eq]",
                    items: [],
                },
                {
                    type: "text",
                    label: "Nome do Entregável",
                    sm: "4",
                    col: "4",
                    id: "epicName",
                    name: "epicName",
                    value: "",
                    payloadBackend: "epicName[like]",
                    items: [],
                },
                {
                    type: "autocomplete",
                    label: "% Conclusão Épico Jira",
                    sm: "4",
                    col: "4",
                    id: "progress",
                    name: "progress",
                    value: "",
                    payloadBackend: "progress[lte]",
                    items: [
                        { id: 25, name: "Até 25%" },
                        { id: 50, name: "Até 50%" },
                        { id: 75, name: "Até 75%" },
                        { id: 100, name: "Até 100%" },
                    ],
                },
                {
                    type: "multiautocomplete",
                    label: "Estágio",
                    sm: "4",
                    col: "4",
                    id: "stageId",
                    name: "stages",
                    value: "",
                    payloadBackend: "stageId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Status Atual",
                    sm: "4",
                    col: "4",
                    id: "statusId",
                    name: "status",
                    value: "",
                    payloadBackend: "statusId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Quarter",
                    sm: "4",
                    col: "4",
                    id: "quarterId",
                    name: "quarter",
                    value: "",
                    payloadBackend: "quarterId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Jornada",
                    sm: "4",
                    col: "4",
                    id: "journeyId",
                    name: "journeys",
                    value: "",
                    payloadBackend: "journeyId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Tribo",
                    sm: "4",
                    col: "4",
                    id: "tribesId",
                    name: "tribes",
                    value: "",
                    payloadBackend: "tribesId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Squad",
                    sm: "4",
                    col: "4",
                    id: "squadKey",
                    name: "squads",
                    value: "",
                    payloadBackend: "squadKey[or]",
                    items: [],
                },
            ]
        }
    }
}
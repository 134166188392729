<template>
  <v-container>
    <vv-loading :is-loading="isLoading"></vv-loading>
    <header-view class="mb-4"></header-view>

    <div class="header-card px-7 pb-2 pt-7">
      <vv-tabs v-model="tabSelected">
        <v-tab v-for="tab in tabs" :key="tab.key"> {{ tab.title }} </v-tab>
      </vv-tabs>
    </div>
    <div class="body-card px-7 pb-7 pt-2">
      <v-tabs-items v-model="tabSelected">
        <v-tab-item v-for="(tab, id) in tabs" :key="tab.key">
          <v-data-table
            sort-by="id"
            :headers="table.headers"
            :items="tab.items"
            :search="table.search"
            :mobile-breakpoint="0"
            :header-props="table.headerProps"
            :footer-props="table.footerProps"
            :options.sync="options[id]"
            :page.sync="options[id].page"
            :server-items-length="options[id].serverItemsLength"
            class="cursor icon-down"
          >
            <template #footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} de
              {{ props.itemsLength }}
            </template>

            <template v-slot:no-data>
              <v-btn
                class="ma-2"
                outlined
                color="primary"
                :loading="isLoading"
                @click="tabSelected === 0 ? loadCuratorList() : loadCommitteeList()"
              >
                Recarregar
              </v-btn>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <v-btn
                  class="ma-1 pa-0"
                  text
                  color="warning"
                  min-width="40"
                  @click="$router.push(`/ideia/${item.id}`)"
                >
                  <v-icon> mdi-magnify </v-icon>
                </v-btn>
                <v-btn
                  class="ma-1 pa-0"
                  text
                  color="secondary"
                  min-width="40"
                  :disabled="!hasPermission"
                  @click="openApproval(item)"
                >
                  <v-icon> mdi-check-circle-outline </v-icon>
                </v-btn>
                <v-btn
                  class="ma-1 pa-0"
                  text
                  color="error"
                  min-width="40"
                  :disabled="!hasPermission"
                  @click="openDisapproval(item)"
                >
                  <v-icon> mdi-close-circle-outline </v-icon>
                </v-btn>
                <v-btn
                  class="ma-1 pa-0"
                  text
                  :disabled="!hasPermission"
                  color="info"
                  min-width="40"
                  @click="openQuestion(item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <vv-confirmation-dialog
      v-model="dialog.showApproval"
      :title="dialog.title"
      message="Você realmente deseja aprovar essa Ideia?"
      @confirmed="submitApproval(dialog.draft)"
      @denied="dialog.draft = null"
    />
    <vv-confirmation-dialog
      v-model="dialog.showCommitteeRedirect"
      title="Aprovação de Ideia pelo Comitê"
      message="Você deseja tornar esta Ideia uma Iniciativa ou um Entregável?"
      @denied="(dialog.draft = null), (dialog.isEpic = false)"
    >
      <template v-if="dialog.isEpic" #form>
        <vv-multiple-select
          label="ID Dor"
          v-model="dialog.epicSelected"
          :items="optionsList.epics"
          item-text="title"
          item-value="id"
        >
          <template #chip="scope">
            {{ scope.id }} - {{ scope.title }}
          </template>
          <template #cell="scope">
            <vv-problem-cell :value="scope" />
          </template>
        </vv-multiple-select>
      </template>
      <template #actions>
        <v-btn 
          color="primary" 
          :disabled="true" 
          depressed 
          @click="dialog.isEpic = true"
        >
          Entregável
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="$router.push(`/projeto/criar/?ideia=${dialog.draft.id}`)"
        >
          Iniciativa
        </v-btn>
      </template>
    </vv-confirmation-dialog>
    <vv-confirmation-dialog
      v-model="dialog.showDisapproval"
      :title="dialog.title"
      message="Você realmente deseja reprovar essa Ideia?"
      @confirmed="submitDisapproval(dialog.draft)"
      @denied="(dialog.draft = null), (dialog.text = '')"
      :disable-ok="!dialog.text"
    >
      <template #form>
        <label>
          Justificativa de Rejeição (Campo obrigatório!)
          <v-textarea filled dense hide-details v-model="dialog.text" />
        </label>
      </template>
    </vv-confirmation-dialog>
    <vv-confirmation-dialog
      v-model="dialog.showQuestion"
      title="Mudanças/Dúvidas"
      @confirmed="submitQuestion(dialog.draft)"
      @denied="(dialog.draft = null), (dialog.text = '')"
      :disable-ok="!dialog.text"
    >
      <template #form>
        <label>
          Mudanças/Dúvidas (Campo obrigatório!)
          <v-textarea filled dense hide-details v-model="dialog.text" />
        </label>
      </template>
    </vv-confirmation-dialog>

    <vv-alert
      v-model="alert.show"
      :message="alert.message"
      :type="alert.type"
      :dismissible="alert.dismissible"
    />
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import HeaderView from "@/components/HeaderView";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog.vue";
import VvMultipleSelect from "@/components/common/VvMultipleSelect.vue";
import VvTabs from "@/components/common/VvTabs.vue";
import VvAlert from "@/components/common/VvAlert";

import ideaServiceFactory from "@/services/ideaService";
import messageServiceFactory from "@/services/messageService";
const ideaService = ideaServiceFactory();
const messageService = messageServiceFactory();

export default {
  name: "IdeaApproval",
  components: {
    VvLoading,
    HeaderView,
    VvConfirmationDialog,
    VvMultipleSelect,
    VvTabs,
    VvAlert,
  },
  data: () => ({
    // Page related
    isLoading: false,
    tabSelected: 0,
    tabs: [
      { title: "Ideias Curadoria", key: 0, items: [] },
      { title: "Ideias Comitê", key: 1, items: [] },
    ],
    optionsList: {
      epics: [],
    },
    // Alert related
    alert: {
      show: false,
      message: "",
      type: "info",
      dismissible: false,
    },
    // Dialog related
    dialog: {
      showApproval: false,
      showDisapproval: false,
      showCommitteeRedirect: false,
      showQuestion: false,
      isEpic: false,
      epicSelected: null,
      draft: null,
      title: "",
      text: "",
    },
    // table config
    table: {
      search: "",
      items: [],
      headerProps: {
        sortByText: "Ordenar por",
      },
      footerProps: {
        "items-per-page-text": "Linhas por página",
        "items-per-page-options": [5, 10, 15, 30, 50, 100],
      },
      headers: [
        { text: "ID Ideia", value: "id", align: "center", class: "cabecalho" },
        {
          text: "Título da Ideia",
          value: "title",
          align: "center",
          class: "cabecalho",
        },
        {
          text: "Campanha",
          value: "campaign",
          align: "center",
          class: "cabecalho",
        },
        {
          text: "Público Alvo",
          value: "targetAudiences",
          align: "center",
          class: "cabecalho",
        },
        {
          text: "Duração Estimada",
          value: "estimatedDurations",
          align: "center",
          class: "cabecalho",
        },
        {
          text: "Área responsável",
          value: "responsibleArea",
          class: "cabecalho",
          align: "center",
        },
        {
          text: "Pontuação Ideia",
          value: "points",
          align: "center",
          class: "cabecalho",
        },
        {
          text: "Nível de Aprovação",
          value: "approvalLevel",
          align: "center",
          class: "cabecalho",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "20%",
          align: "center",
        },
      ],
    },
    options: [
      {
        key: "curator",
        itemsPerPage: 15,
        sortBy: [],
        page: 1,
        serverItemsLength: 0,
      },
      {
        key: "committee",
        itemsPerPage: 15,
        sortBy: [],
        page: 1,
        serverItemsLength: 0,
      }
    ],
  }),
  computed: {
    hasPermission() {
      const loginUserTypeId = this.$store.getters.type;
      const containMaster = loginUserTypeId
        .includes(this.CONST_USER_TYPE.USUARIO_MASTER);
      const containCurator = loginUserTypeId
        .includes(this.CONST_USER_TYPE.CURADORIA);
      return containMaster || containCurator;
    },
  },
  watch: {
    options: {
      handler() {
        this.loadingListByTabs();
      },
      deep: true,
    },
    tabSelected() {
      this.loadingListByTabs();
    }
  },
  methods: {
    // Approval related
    openApproval(item) {
      this.dialog.title =
        this.tabSelected === 0
          ? "Aprovação incicial da Ideia"
          : "Aprovação de Ideia pelo Comitê";
      this.dialog.showApproval = true;
      this.dialog.draft = item;
    },
    async submitApproval(item) {
      this.isLoading = true;
      try {
        const result = await ideaService.workflowApproval(item.id);
        this.alert = {
          show: true,
          message: result.message,
          type: "success",
          dismissible: true,
        };
      } catch (error) {
        this.showError(error);
      } finally {
        if (this.tabSelected === 0) {
          this.loadCuratorList();
          this.dialog.draft = null;
        }
        if (this.tabSelected === 1) {
          this.loadCommitteeList();
          this.dialog.showCommitteeRedirect = true;
        }
        this.isLoading = false;
      }
    },
    // Disapproval related
    openDisapproval(item) {
      this.dialog.title =
        this.tabSelected === 0
          ? "Reprovação inicial da Ideia"
          : "Reprovação de Ideia pelo Comitê";
      this.dialog.showDisapproval = true;
      this.dialog.draft = item;
    },
    async submitDisapproval(item) {
      this.isLoading = true;
      try {
        const result = await ideaService.workflowDisapproval({
          idea_id: item.id,
          refused_cause: this.dialog.text,
        });
        this.alert = {
          show: true,
          message: result.message,
          type: "success",
          dismissible: true,
        };
      } catch (error) {
        this.showError(error);
      } finally {
        this.dialog.draft = null;
        this.dialog.text = "";
        this.isLoading = false;
        if (this.tabSelected === 0) {
          this.loadCuratorList();
        }
        if (this.tabSelected === 1) {
          this.loadCommitteeList();
        }
      }
    },
    // Question related
    openQuestion(item) {
      this.dialog.showQuestion = true;
      this.dialog.draft = item;
    },
    async submitQuestion(item) {
      this.isLoading = true;
      try {
        const result = await messageService.sendMessage({
          modelId: item.id,
          model: "Idea",
          message: this.dialog.text,
        });
        this.alert = {
          show: true,
          message: result.message,
          type: "success",
          dismissible: true,
        };
      } catch (error) {
        this.showError(error);
      } finally {
        this.dialog.draft = null;
        this.dialog.text = "";
        this.isLoading = false;
        if (this.tabSelected === 0) {
          this.loadCuratorList();
        }
        if (this.tabSelected === 1) {
          this.loadCommitteeList();
        }
      }
    },
    // Curator related
    async loadCuratorList(reset) {
      try {
        if (reset === true) this.options[this.tabSelected].page = 1;
        this.isLoading = true;
        this.tabs[0].items = [];
        const result = await ideaService.listWorkflowFirstApproval(
          this.getPagination(0)
        );
        this.tabs[0].items = result.data;
        this.setPagination(result, 0);
      } catch (error) {
        this.showError(error);
      } finally {
        this.isLoading = false;
      }
    },
    // Committee related
    async loadCommitteeList(reset) {
      try {
        if (reset === true) this.options[this.tabSelected].page = 1;
        this.isLoading = true;
        this.tabs[1].items = [];
        const result = await ideaService.listWorkflowSecondApproval(
          this.getPagination(1)
        );
        this.tabs[1].items = result.data;
        this.setPagination(result, 1);
      } catch (error) {
        this.showError(error);
      } finally {
        this.isLoading = false;
      }
    },
    openComitteeRedirect(item) {
      this.dialog.showCommitteeRedirect = true;
      this.dialog.draft = item;
    },
    // table related
    setPagination(result, index) {
      const { currentPage, total } = result;
      this.options[index].serverItemsLength = total;
      this.options[index].page = currentPage;
    },
    getPagination(index) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options[index];
      const pagination = {
        page,
        page_size: itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: 0,
      };
      if (sortBy.length && sortDesc.length) {
        pagination.sortDesc = sortDesc[0] ? 1 : 0;
      }
      return pagination;
    },
    loadingListByTabs() {
      if (this.tabSelected === 0) {
        this.loadCuratorList();
      }
      if (this.tabSelected === 1) {
        this.loadCommitteeList();
      }
    },
    showError(error) {
      const message = error.response.data.message || error.message;
      this.alert = {
        show: true,
        message,
        type: "error",
        dismissible: true,
      };
    }
  },
};
</script>

<style lang="scss">
.header-card {
  position: sticky;
  top: 100px;
  z-index: 2;
  background: #fff;
  border-radius: 4px 4px 0 0;
}
.body-card {
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ActionsChangeRequest"}},[(_vm.createChangeRequest || _vm.existChangeRequest)?[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"primary","outlined":""}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","max-width":"219"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"grey","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("\"Change Request\" deve ser usada para modificar ou cancelar um épico. Necessário preencher e salvar os campos de “Responsáveis e outros envolvidos” no épico para habilitar.")])]),_vm._v(" Change Request "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,645025845)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"px-6",attrs:{"link":"","disabled":!_vm.createChangeRequest},on:{"click":_vm.openModal}},[_c('v-list-item-title',[_vm._v(" Solicitar Change ")])],1),_c('v-list-item',{staticClass:"px-6",attrs:{"link":"","disabled":!_vm.existChangeRequest}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.goToChangeRequest()}}},[_vm._v(" Consultar Change ")])],1)],1)],1)]:[_c('v-tooltip',{attrs:{"top":"","max-width":"219"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 personal-disabled",attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1 personal-disabled",attrs:{"dark":"","small":""}},[_vm._v(" mdi-information-outline ")]),_vm._v(" Change Request "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('span',[_vm._v("\"Change Request\" deve ser usada para modificar ou cancelar um épico. Necessário preencher e salvar os campos de “Responsáveis e outros envolvidos” no épico para habilitar.")])])],_c('v-dialog',{attrs:{"width":"960","scrollable":""},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Nova Change Request")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.modalOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"sub-header-modal"},[_vm._v(" ID do épico: "),_c('span',[_vm._v(_vm._s(_vm.item.id))]),_c('br'),_vm._v(" Épico: "),_c('span',[_vm._v(_vm._s(_vm.item.name))])]),_c('v-card-text',[_c('form-change-request',{ref:"formCR",attrs:{"epic-id":_vm.item.id,"item":_vm.item,"orignal-data":_vm.orignalData,"focal-point-approver":_vm.focalPointsTap},on:{"changeRequestSaved":_vm.closeModal}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs.formCR.confirmationDialog.value=true}}},[_vm._v(" Submeter change request ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>

    <idea></idea>

  </v-container>
</template>

<script>
import Idea from "@/components/forms/Idea";

export default {
  components: {Idea},
  data: () => ({}),
}
</script>

<style>
</style>

export default {
    props: {
        journeyDetail: {
            type: Object,
            default: () => ({})
        },
        canCreate: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        openTribeEditModal(index) {
            this.$emit('openTribeEditModal', this.journeyDetail, index)
        },
        openTribeCreateModal() {
            let tribeIndex = this.journeyDetail.tribes.length;
            this.$emit('openTribeCreateModal', tribeIndex)
        }
    }
}
export default {
    data() {
        return {
            items: [
                {
                    icon: 'mdi-monitor-dashboard',
                    title: 'Dashboard',
                    link: '/dashboard',
                    hasSubItem: false
                },
                {
                    icon: 'mdi-format-list-bulleted',
                    items: [
                        {
                            title: "Iniciativas",
                            link: "/projetos",
                        },
                        {
                            title: "Macro Temas",
                            link: "/listagem/projetos"
                        },
                        {
                            title: "Entregáveis",
                            link: "/listagem/entregaveis",
                        },
                        {
                            title: "Indicadores",
                            link: "/listagem/indicadores",
                        },
                        {
                            title: "Formalização Entregas",
                            link: "/listagem/formalizacao-entregas",
                        },
                        {
                            title: "Change Requests",
                            link: "/listagem/change-request"
                        },
                        /*
                        {
                            title: "Dores",
                            link: "/listagem/dores",
                        },
                        {
                            title: "Ideias",
                            link: "/listagem/ideias",
                        },
                        */
                        {
                            title: "Relatórios Mensais",
                            link: "/listagem/relatorios-mensais",
                        },
                    ],
                    hasSubItem: true,
                    title: 'Listagens',
                    link: ''
                },
                {
                    icon: 'mdi-sort-bool-descending-variant',
                    items: [
                        {
                            title: "Change Requests",
                            link: "/workflow/change-requests",
                        },
                        {
                            title: "Formalização Entrega",
                            link: "/workflow/formalizacao-entregas"
                        },
                        {
                            title: "Encerramento de Iniciativa",
                            link: "/workflow/encerramento"
                        },
                    ],
                    hasSubItem: true,
                    title: 'Workflow',
                    link: ''
                },
                {
                    icon: 'mdi-tune-variant',
                    items: [
                        {
                            title: "Árvore de Negócio",
                            link: "/gestao/arvores-negocio",
                        },
                        {
                            title: "Árvore de Tecnologia",
                            link: "/gestao/arvores-tecnologia",
                        },
                        {
                            title: "Opções",
                            link: "/gestao/opcoes",
                        },                        
                        {
                            title: "Usuários",
                            link: "/gestao/usuarios",
                        },
                        {
                            title: "Delegar aprovações",
                            link: "/gestao/delegar-aprovacoes",
                        },
                        {
                            title: "Quarters",
                            link: "/gestao/quarters",
                        },
                    ],
                    hasSubItem: true,
                    title: 'Gestão',
                    link: ''
                },
                // {
                //     icon: 'mdi-calendar-range',
                //     items: [
                //         {
                //             title: "Planejamento Squads",
                //             link: "/planejamento/pmpo",
                //         },
                //         {
                //             title: "Aprovação de Tecnologia",
                //             link: "/planejamento/aprovacao-tecnologia",
                //         },
                //         {
                //             title: "Aprovação de Negócio",
                //             link: "/planejamento/aprovacao-negocio",
                //         }
                //     ],
                //     hasSubItem: true,
                //     title: 'Planej. Trimestral',
                //     link: ''
                // },
                {
                    icon: 'mdi-text-box-search-outline',
                    hasSubItem: false,
                    title: 'Auditoria',
                    link: '/auditoria',
                },
            ],
        }
    }
}
<template>
  <div id="ActionsChangeRequest">
    <template v-if="createChangeRequest || existChangeRequest">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            outlined
            v-bind="attrs"
            v-on="on"
            class="ml-4"
          >
            <v-tooltip top max-width="219">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mr-1"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>"Change Request" deve ser usada para modificar ou cancelar um épico. Necessário preencher e salvar os campos de “Responsáveis e outros envolvidos” no épico para habilitar.</span>
            </v-tooltip>
            Change Request <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item link class="px-6" @click="openModal" :disabled="!createChangeRequest">
            <v-list-item-title>
              Solicitar Change
            </v-list-item-title>
          </v-list-item>
          <v-list-item link class="px-6" :disabled="!existChangeRequest">
            <v-list-item-title @click="goToChangeRequest()">
              Consultar Change
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-else>
      <v-tooltip top max-width="219">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            v-bind="attrs"
            v-on="on"
            class="ml-4 personal-disabled"
          >
            <v-icon
              dark
              small
              class="mr-1 personal-disabled"
            >
              mdi-information-outline
            </v-icon>
            Change Request <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <span>"Change Request" deve ser usada para modificar ou cancelar um épico. Necessário preencher e salvar os campos de “Responsáveis e outros envolvidos” no épico para habilitar.</span>
        </v-tooltip>
    </template>
    

    <v-dialog
        v-model="modalOpen"
        width="960"
        scrollable
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Nova Change Request</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                @click="modalOpen = false"
            ><v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="sub-header-modal">
          ID do épico: <span>{{ item.id }}</span><br>
          Épico: <span>{{ item.name }}</span>
        </v-card-text>
        <v-card-text>
          <form-change-request
              :epic-id="item.id"
              :item="item"
              :orignal-data="orignalData"
              :focal-point-approver="focalPointsTap"
              @changeRequestSaved="closeModal"
              ref="formCR"
          ></form-change-request>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$refs.formCR.confirmationDialog.value=true">
            Submeter change request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormChangeRequest from "./FormChangeRequest";

export default {
  name: "ActionsChangeRequest",
  components: {
    FormChangeRequest,
  },
  props: {
    item: {
      type: Object,
    },
    options: {
      type: Object,
    },
    focalPointsTap: {
      type: Array,
    },
  },
  computed: {
    createChangeRequest() {
      return this.item.permissions?.createChangeRequest;
    },

    existChangeRequest() {
        return this.item.permissions.existChangeRequest;
    },

    orignalData() {
      return (typeof this.itemClone.deliveryPlanned !== 'undefined' && this.itemClone.deliveryPlanned.value !== null)
          ?this.itemClone.deliveryPlanned.value
          :this.itemClone.deliveryBaseline;
    }
  },
  data: () => ({
    modalOpen: false,
    itemClone:{},
    searchEpic: '',
    projectData: {
      epics: {}
    },
  }),
  mounted() {},
  watch: {
    item(_new) {
      if (this.isEmpty(this.itemClone)) {
        this.itemClone = JSON.parse(JSON.stringify(_new))
      }
    }
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    createChangeRequests(data) {
      this.dialogCr = false;
      this.textMessage = data.message;
      this.dialogMessage = true;
      this.showCr(data.epicId);
      this.getChangeRequests(data.epicId);
      setTimeout(() => {
        this.dialogMessage = false;
        window.location.reload();
      }, 2000);
    },
    showFailMessage(data) {
      if (data.user_type) {
        this.textMessageError = data.user_type;
      } else {
        this.textMessageError = data;
      }
      this.dialogMessageError = true;
      setTimeout(() => {
        this.dialogMessageError = false;
      }, 4000);
    },
    goToChangeRequest() {
      this.$router.push(`/projeto/${this.$route.params.id}/epicos/${this.item.id}/change-request/`);
    },
  },
}
</script>

<style lang="scss">
  #ActionsChangeRequest {
    .sub-header-modal {
      height: 100px;
      overflow: hidden;
      color: #929497;
      span {
        color: black;
      }
    }

    .personal-disabled {
      color: #DBDCDC;
      cursor: not-allowed;
    }
  }
</style>
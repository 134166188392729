export default {
  methods: {
    getError(param) {
      const errorList = this.errorList?.error;
      return errorList && errorList[param];
    },
    cleanError(param) {
      const errorList = { ...this.errorList };
      if (typeof errorList.error !== "undefined") delete errorList.error[param];
      this.$emit("update:errorList", errorList);
    },
  },
};

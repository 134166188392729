import VvLoading from "@/components/common/VvLoading";
import VvBreadcrumbs from '@/components/common/VvBreadcrumbs';
import technologyTreeServiceFactory from "../../services/technologyTreeService";
import TribeEditModal from '../../components/TribeEditModal/TribeEditModal.vue'
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";

const technologyTreeService = technologyTreeServiceFactory();

export default {
    components: {
        VvLoading,
        VvBreadcrumbs,
        TribeEditModal,
        VvConfirmationDialog
    },
    data() {
        return {
            loading: false,
            breadcrumb: [
                {
                    text: 'Gestão',
                    disabled: true,
                },
                {
                    text: 'Árvore de Técnologia',
                    disabled: true,
                },
                {
                    text: 'Nova Jornada',
                    disabled: false,
                }
            ],
            options: {},
            journey: {
                id: null,
                name: "",
                isActive: true,
                vsmId: null,
                vsmName: "",
                srGpmId: null,
                srGpmName: "",
                tribes: []
            },
            showTribeEditModal: false,
            tribeEditModalData: {},
            errorList: [],
            confirmationDialog: {
                value: false,
                title: '',
                message: '',
                type: ''
            },
            id: null
        }
    },
    methods: {
        async createTechTree() {
            try {
                this.loading = true;
                const response = await technologyTreeService.createTechTree(this.journey);
                this.$showSuccess(response.message);
                setTimeout(() => {
                    this.$router.push('/gestao/arvores-tecnologia');
                }, 2000);
            } catch (error) {
                if (error.response.status !== 422) {
                    return this.$showError(error);
                }
                this.$showError('Um ou mais tribos não estão cadastrados corretamente, verifique e tente novamente.');
                this.setErrorList(error);
            } finally {
                this.loading = false;
            }
        },
        async getOptions() {
            try {
                this.loading = true;
                const resultOptionsForm = await technologyTreeService.getFormOptions();
                const resultOptions = await technologyTreeService.getFilterOptions();
                this.options = resultOptions.options;
                this.options = {...this.options, ...resultOptionsForm.options}
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        handleModal() {
            this.confirmationDialog.value = false;

            if (this.confirmationDialog.type === 'cancel') {
                this.$router.push('/gestao/arvores-tecnologia');
            } else if (this.confirmationDialog.type === 'save') {
                this.createTechTree();
            } else {
                this.journey.tribes.splice(this.id, 1);

                if (typeof this.errorList[`tribes.${this.id}.gpmId`] !== 'undefined') {
                    this.clearError(`tribes.${this.id}.gpmId`);
                }

                if (typeof this.errorList[`tribes.${this.id}.name`] !== 'undefined') {
                    this.clearError(`tribes.${this.id}.name`);
                }

                if (typeof this.errorList[`tribes.${this.id}.squads.0.squadKey`] !== 'undefined') {
                    this.clearError(`tribes.${this.id}.squads.0.squadKey`);
                }
            }
        },
        openCreateModalTribe() {
            this.journey.tribes.unshift({
                id: null,
                name: "",
                isActive: true,
                gpmId: null,
                gpmName: "",
                squads: [
                    {
                        id: null,
                        name: "",
                        active: false,
                        pmpo: [],
                    }
                ],
                squadsPmpoNames: [
                    {
                        pmpos: []
                    }
                ]
            });

            this.tribeEditModalData = {
                journeyDetail: this.journey,
                index: 0
            };

            this.showTribeEditModal = true;
        },
        openEditModalTribe(index) {
            this.tribeEditModalData = {
                journeyDetail: this.journey,
                index: index
            };

            this.showTribeEditModal = true;
        },
        deleteTribe(index) {
            this.confirmationDialog = {
                value: true,
                title: 'Excluir tribo',
                message: 'Você realmente deseja excluir essa tribo?',
                type: ''
            };
            this.id = index;
        },
        getVsmName(vsmId) {
            this.options.vsms.map(vsm => {
                if (vsm.id === vsmId) {
                    this.journey.vsmName = vsm.name;
                }
            })
        },
        getSrGpmName(srGpmId) {
            this.options.seniorGpms.map(srGpm => {
                if (srGpm.id === srGpmId) {
                    this.journey.srGpmName = srGpm.name;
                }
            })
        },
        save() {
            this.journey.tribes.map((tribe, tribeIndex) => {
                this.options.gpms.map(gpmOptions => {
                    if (tribe.gpmId === gpmOptions.id) {
                        tribe.gpmName = gpmOptions.name
                    }
                });

                tribe.squads.map((squad, squadIndex) => {
                    this.journey.tribes[tribeIndex].squadsPmpoNames[squadIndex].pmpos = [];
                    squad.pmpo.map((pmpo, pmpoIndex) => {
                        this.options.pmpos.map(pmpoOptions => {
                            if (pmpo === pmpoOptions.id) {
                                this.journey.tribes[tribeIndex].squadsPmpoNames[squadIndex].pmpos[pmpoIndex] = pmpoOptions.name
                            }
                        });
                    });
                });
            });
            this.showTribeEditModal = false;
        },
        cancel() {
            this.confirmationDialog = {
                value: true,
                title: 'Cancelar cadastro',
                message: 'Você realmente deseja cancelar o cadastro dessa árvore?',
                type: 'cancel'
            }
        },
        saveConfirm() {
            this.confirmationDialog = {
                value: true,
                title: 'Salvar cadastro',
                message: 'Você realmente deseja salvar o cadastro dessa árvore?',
                type: 'save'
            }
        },
        addSquad(index) {
            this.journey.tribes[index].squads.unshift({
                active: true,
                id: null,
                name: "",
                pmpo: [],
                squadKey: "",
                new: true
            });

            this.journey.tribes[index].squadsPmpoNames.unshift({
                pmpos: []
            })
        },
        removeSquad(tribeIndex, squadIndex) {
            this.journey.tribes[tribeIndex].squads.splice(squadIndex, 1);
            this.journey.tribes[tribeIndex].squadsPmpoNames.splice(squadIndex, 1);
        },
        cancelEditTribe() {
            this.showTribeEditModal = false;
        },
        setErrorList(error) {
            const {
                response: { data: errors },
            } = error;
            this.errorList = errors;
        },
        getError(param) {
            return this.errorList[param];
        },
        clearError(param) {
            delete this.errorList[param];
        },
        errorBorder(index) {
            if (
                typeof this.errorList[`tribes.${index}.gpmId`] !== 'undefined' ||
                typeof this.errorList[`tribes.${index}.name`] !== 'undefined' ||
                typeof this.errorList[`tribes.${index}.squads.0.squadKey`] !== 'undefined'
            ) {
                return 'tribe-error'
            }

            return '';
        },
    },
    created() {
        this.getOptions();
    },
    computed: {
        isDisable() {
            if (
                this.journey.name !== '' &&
                this.journey.vsmId !== null
            ) {
                return false;
            }
            return true
        }
    },
}
import axios from "@/plugins/axios";

export default function filterService(){
    return {
        exportExcel(payload, url) {
            return axios.get(url, {
                params: payload
            }).then(res => res.data);
        },
        exportNotification(payload, url) {
            return axios.get(url, {
                params: payload
            }).then(res => res.data);
        },
        filterUsers(payload, url) {
            return axios.get(url, {
                params: payload
            }).then(res => res.data);
        },
    }
}



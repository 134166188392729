var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":true}},[_c('vv-loading',{attrs:{"is-loading":_vm.loading || _vm.loadingOptions}}),_c('vv-breadcrumbs',{attrs:{"items-breadcrumbs":_vm.breadcrumb}}),_c('div',{staticClass:"epicList"},[_c('h1',[_vm._v("Listagem Entregáveis")]),_c('vv-new-filter',{staticClass:"mb-8",attrs:{"filter-name":_vm.filterName,"fields":_vm.fields,"acceptExport":true,"export-api":"v2/report/epic"},on:{"filter":_vm.filter,"reset":_vm.resetFilter}}),_c('v-data-table',{staticClass:"list-epics cursor icon-down",attrs:{"sort-by":"project_id","headers":_vm.headersTable,"headers-props":_vm.headerProps,"items":_vm.epics,"footer-props":_vm.footerProps,"mobile-breakpoint":0,"options":_vm.options,"server-items-length":_vm.serverItemsLength},on:{"click:row":_vm.rowClicked,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.epicName",fn:function(ref){
var item = ref.item;
return [_c('div',{style:('min-width: 200px')},[_vm._v(" "+_vm._s(item.epicName)+" ")])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{staticClass:"header-epic-fields-value d-block text-truncate p-relative",style:('width: 160px'),attrs:{"value":item.progress,"color":"accent","height":"25","disabled":""}},[_c('span',[_vm._v(_vm._s(item.progress)+"% ")]),(item.dateJiraUpdated)?_c('span',{staticClass:"position-tooltip"},[_c('v-tooltip',{attrs:{"right":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(" Em: "+_vm._s(_vm.moment(item.dateJiraUpdated).format("DD/MM/YYYY HH:mm"))+" ")])])],1):_vm._e()])]}},{key:"item.stageId",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{style:('background-color: ' +
              _vm.statusAtualColorStage(item.stageId, true, 'bg') +
              '; color: ' +
              _vm.statusAtualColorStage(item.stageId, true, 'fc') +
              '; flex-direction: column' +
              '; width: 160px'),attrs:{"dark":"","label":""}},[_vm._v(" "+_vm._s(_vm.getName(item.stageId, 'stages'))+" ")])]}},{key:"item.statusId",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{style:('background-color: ' +
            _vm.statusAtualColor(item.statusId, true, 'bg') +
            '; color: ' +
            _vm.statusAtualColor(item.statusId, true, 'fc') +
            '; flex-direction: column' +
            '; width: 240px'),attrs:{"dark":"","label":""}},[_vm._v(" "+_vm._s(_vm.getName(item.statusId, 'status'))+" ")])]}},{key:"item.blackFriday",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.blackFriday ? "sim" : "não")+" ")]}},{key:"item.changeRequest",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.changeRequest ? "sim" : "não")+" ")]}},{key:"item.deliveryDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertMount(item.deliveryDate))+" ")]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"primary","loading":_vm.loading || _vm.loadingOptions},on:{"click":_vm.getEpics}},[_vm._v(" Recarregar ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
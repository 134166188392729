import RouterLinks from "@/template/components/RouterLinks/RouterLinks.vue";
import UserMenu from "@/template//components/UserMenu/UserMenu.vue";
import HeaderServiceFactory from "@/template/services/headerService";
import Notifications from "@/template/components/Notifications/Notifications.vue";

const headerService = HeaderServiceFactory();

export default {
    name: 'Header',
    components: {
        RouterLinks,
        UserMenu,
        Notifications
    },
    data() {
        return {
            drawer: false,
            menu: false,
            hasNotifications: false
        }
    },
    methods: {
        async latestNotifications() {
            try {
                const result = await headerService.latestNotifications();
                this.hasNotifications = result.notifications;
            } catch (error) {
                this.$showError(error);
            }
        },
        changeEmuledUser(user) {
            this.$store.commit('startUserEmulation', user);
            document.location.reload(true);
        },
        logoff () {
            this.$store.commit("stopUserEmulation");
            this.$store.commit("logoff");
            this.$msal.signOut();
        },
    },
    mounted() {
        this.latestNotifications();
    }
}
<template>
  <div id="datasForm">
    <div class="grid-13 gap-md mt-7">
      <vv-box-alert title="Importante :" icon="mdi-information-outline">
          <li>Ao definir e salvar <b>”Data da entrega planejada”</b> ela não poderá ser mais alterada.</li>
          <li>Use <b>"Data da entrega replanejada"</b> para fazer alterações dentro do mesmo trimestre.</li>
          <li>Para mudanças em trimestres diferentes, solicite um <b>Change Request</b>.</li>
      </vv-box-alert>
      <div class="md:g-col-4">
        <div class="grid-3 md:grid-1 gap-md pa-6">
          <div class="md:g-col-1">
            <label>Data da entrega planejada</label>
              <vv-date-pick
                :disabled="!editDeliveryBaseline && disabledDeliveryBaseline"
                v-model="item.deliveryBaseline"
                :clear-field="true"
                :using-button="false"
                :error-messages="getError('deliveryBaseline')"
                @input="clearError(item.deliveryBaseline,'deliveryBaseline')"
              ></vv-date-pick>
          </div>
          <div class="md:g-col-1">
            <label>Data da entrega replanejada (se aplicável)</label>
            <div v-if="canEditStakeholderFieldsPermission && disabledDeliveryBaseline && item.deliveryBaseline !== null" >
              <vv-date-pick
                v-model="deliveryPlanned"
                :clear-field="true"
                :using-button="false"
                :error-messages="getError('deliveryPlanned')"
                @input="clearError(deliveryPlanned,'deliveryPlanned')"
              ></vv-date-pick>
            </div>
            <div v-else>
              <v-tooltip top max-width="300">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <vv-date-pick
                      disabled
                      v-model="deliveryPlanned"
                      :clear-field="true"
                      :using-button="false"
                      :error-messages="getError('deliveryPlanned')"
                      @input="clearError(deliveryPlanned,'deliveryPlanned')"
                  ></vv-date-pick>
                  </div>
                </template>
                <span >Habilitada apenas para entregáveis com "Data de entrega planejada" definida.</span>
              </v-tooltip>
            </div>
          </div>
          <div class="g-col-1 md:g-col-1">
            <label>Data da entrega concluída</label>
            <vv-date-pick
                v-if="deliveryDone"
                disabled
                v-model="deliveryDone"
                :clear-field="true"
                :using-button="false"
                :error-messages="getError('deliveryDone')"
                @input="clearError(deliveryDone,'deliveryDone')"
            ></vv-date-pick>
            <v-tooltip top max-width="300" v-if="!deliveryDone">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <vv-date-pick
                    disabled
                    v-model="deliveryDone"
                    :clear-field="true"
                    :using-button="false"
                    :error-messages="getError('deliveryDone')"
                    @input="clearError(deliveryDone,'deliveryDone')"
                  ></vv-date-pick>
                </div>
              </template>
              <span >Preenchida automaticamente após a aprovação total da formalização da entrega.</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VvDatePick from "@/components/VvDatePick";
import VvBoxAlert from "../../../components/common/VvBoxAlert";
import ErrorMessageHandler from "../mixins/ErrorMessageHandler";

export default {
  name: "DatasForm",
  mixins: [
    ErrorMessageHandler
  ],
  props: {
    item: {
      type: Object
    },
    errorList: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object
    },
    completedEpic: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return {
      disabledDeliveryBaseline: null,
    }
  },
  components: {
    VvDatePick,
    VvBoxAlert
  },
  computed: {
    deliveryPlanned: {
      get() {
        return this.item.deliveryPlanned?.value;
      },
      set(value) {
        if (typeof this.item.deliveryPlanned === 'undefined') return;
        this.item.deliveryPlanned.value = value;
      },
    },
    deliveryDone: {
      get() {
        return this.item.deliveryDone?.value;
      },
      set(value) {
        if (typeof this.item.deliveryDone === 'undefined') return;
        this.item.deliveryDone.value = value;
      },
    },
    editDeliveryBaseline() {
      if (this.item.new === true) {
        return this.item.permissions?.editDeliveryBaseline;
      }
      return false
    },
    canEditFieldsPermission() {
      return this.item.permissions?.canEditFieldsPermission;
    },
    canEditStakeholderFieldsPermission() {
      return this.item.permissions?.canEditStakeholderFieldsPermission;
    },
  },
  methods: {
    clearError(element, param) {
      if (element) {
        this.cleanError(param)
      }
    }
  },
  watch:{
    'item.deliveryBaseline'() {
      if (this.item.new === true) {
        this.disabledDeliveryBaseline = false
      }
    }
  },
  mounted() {
    if (this.item.deliveryBaseline === null || this.item.new === true) {
      this.disabledDeliveryBaseline = false
    } else {
      this.disabledDeliveryBaseline = true
    }
  },
}
</script>

<style lang="scss">
#datasForm {
  h3 {
    font-size: 15px;
    color: #6d6e70;
    &::before {
      content: "•";
      margin-right: 5px;
      font-size: 18px;
      position: relative;
      top: 1px;
    }
  }

  fieldset {
    border: 1px solid #dbdcdc;
    border-radius: 8px;

    .v-chip.v-size--default {
      font-size: 12px;
    }

    label {
      white-space: nowrap;
    }
  }

  .v-input {
    font-size: 14px;
  }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('expand',{attrs:{"title":"Sobre o macro tema","inside-class":"grid-10 md:grid-2 sm:grid-1 gap-md items-end","id":"aboutProject"},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},[_c('text-input',{class:_vm.viewMode ? 'g-col-10' : 'g-col-8 md:g-col-4',attrs:{"label":"Nome do macro tema","maxlength":"255","disabled":!_vm.subproject.editPermission || _vm.viewMode,"error-messages":_vm.getErrorSubproject('name')},on:{"input":function($event){return _vm.cleanErrorSubproject('name')}},model:{value:(_vm.subproject.name),callback:function ($$v) {_vm.$set(_vm.subproject, "name", $$v)},expression:"subproject.name"}}),(!_vm.viewMode)?_c('label',{staticClass:"g-col-2 md:g-col-2"},[_vm._v(" Status "),_c('v-autocomplete',{attrs:{"items":_vm.optionsStatus,"item-value":"id","item-text":"name","no-data-text":"Não tem opções disponíveis no momento","disabled":!_vm.subproject.editPermission,"append-icon":"mdi-chevron-down","error-messages":_vm.getErrorSubproject('statusId')},on:{"input":function($event){return _vm.cleanErrorSubproject('statusId')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({class:_vm.chipColor(item.id),attrs:{"input-value":selected,"label":""},on:{"click":select}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,false,1850548108),model:{value:(_vm.subproject.statusId),callback:function ($$v) {_vm.$set(_vm.subproject, "statusId", $$v)},expression:"subproject.statusId"}})],1):_vm._e(),_c('div',{class:_vm.viewMode ? 'g-col-3' : 'g-col-3'},[_c('label',[_vm._v("Quarter Inicial")]),_c('v-autocomplete',{attrs:{"disabled":!_vm.subproject.editPermission || _vm.viewMode,"items":_vm.optionsQuarters,"item-text":"name","item-value":"id","append-icon":"mdi-chevron-down","chips":"","no-data-text":"carregando...","error-messages":_vm.getErrorSubproject('startQuarterId')},on:{"input":function($event){return _vm.removeIfNotApplicable()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({class:_vm.chipColor(item.id),attrs:{"input-value":selected,"label":""},on:{"click":select}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.subproject.startQuarterId),callback:function ($$v) {_vm.$set(_vm.subproject, "startQuarterId", $$v)},expression:"subproject.startQuarterId"}})],1),_c('div',{class:_vm.viewMode ? 'g-col-3' : 'g-col-3'},[_c('label',[_vm._v("Quarter Final")]),_c('v-autocomplete',{attrs:{"disabled":!_vm.subproject.editPermission || _vm.viewMode,"items":_vm.optionsQuarters,"item-text":"name","item-value":"id","append-icon":"mdi-chevron-down","chips":"","no-data-text":"carregando...","error-messages":_vm.getErrorSubproject('endQuarterId')},on:{"input":function($event){return _vm.removeIfNotApplicable()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({class:_vm.chipColor(item.id),attrs:{"input-value":selected,"label":""},on:{"click":select}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.subproject.endQuarterId),callback:function ($$v) {_vm.$set(_vm.subproject, "endQuarterId", $$v)},expression:"subproject.endQuarterId"}})],1),_c('div',{class:_vm.viewMode ? 'g-col-4' : 'g-col-4'},[_c('label',[_vm._v("Tags (Opcional)")]),_c('v-autocomplete',{attrs:{"disabled":!_vm.subproject.editPermission || _vm.viewMode,"items":_vm.optionsTags,"item-text":"name","item-value":"id","item-disabled":"disabled","append-icon":"mdi-chevron-down","chips":"","multiple":"","no-data-text":"carregando..."},on:{"input":function($event){return _vm.removeIfNotApplicable()}},model:{value:(_vm.subproject.tags),callback:function ($$v) {_vm.$set(_vm.subproject, "tags", $$v)},expression:"subproject.tags"}})],1),_c('textarea-input',{staticClass:"g-col-10",attrs:{"label":"Macro escopo e entregáveis","rows":"2","disabled":!_vm.subproject.editPermission || _vm.viewMode},model:{value:(_vm.subproject.macroScope),callback:function ($$v) {_vm.$set(_vm.subproject, "macroScope", $$v)},expression:"subproject.macroScope"}}),_c('div',{staticClass:"m-0 g-col-2"},[_c('v-tooltip',{attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"d-inline-flex"},'label',attrs,false),on),[_c('v-icon',{staticClass:"pr-1",attrs:{"size":"13","small":""}},[_vm._v(" mdi-information-outline ")]),_vm._v(" Modernização da plataforma ")],1)]}}])},[_c('span',[_vm._v(" Para macro temas e épicos com iniciativas de modernização de legados, mainframe (alta plataforma) e componentes (baixa plataforma). ")])]),_c('switch-input',{attrs:{"manualLine":false,"disabled":!_vm.subproject.editPermission || _vm.viewMode,"error-messages":_vm.getErrorSubproject('plataformModernization')},on:{"input":function($event){return _vm.cleanErrorSubproject('plataformModernization')},"change":function($event){return _vm.clearProducts()}},model:{value:(_vm.subproject.plataformModernization),callback:function ($$v) {_vm.$set(_vm.subproject, "plataformModernization", $$v)},expression:"subproject.plataformModernization"}})],1),_c('div',{staticClass:"g-col-4"},[(_vm.subproject.plataformModernization)?_c('div',[_c('label',[_vm._v("Produtos")]),_c('v-autocomplete',{attrs:{"disabled":_vm.ableEditPlatformModernization,"items":_vm.optionsProducts,"item-text":"name","item-value":"id","item-disabled":"disabled","append-icon":"mdi-chevron-down","tooltip":"Selecione ao menos um produto para ver sistemas","chips":"","multiple":"","no-data-text":"carregando...","error-messages":_vm.getErrorSubproject('products')},on:{"input":function($event){_vm.removeIfNotApplicable();
          _vm.cleanErrorSubproject('products')},"change":function($event){return _vm.checkSystemsValues()}},model:{value:(_vm.subproject.products),callback:function ($$v) {_vm.$set(_vm.subproject, "products", $$v)},expression:"subproject.products"}})],1):_vm._e()]),_c('div',{staticClass:"g-col-4"},[(_vm.subproject.plataformModernization)?_c('div',[_c('label',[_vm._v("Sistemas")]),(_vm.subproject.products.length <= 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-autocomplete',{attrs:{"disabled":_vm.ableEditPlatformModernization || _vm.subproject.products.length <= 0,"items":_vm.optionsSystems,"item-text":"name","item-value":"id","item-disabled":"disabled","append-icon":"mdi-chevron-down","chips":"","multiple":"","no-data-text":"carregando...","error-messages":_vm.getErrorSubproject('systems')},on:{"input":function($event){_vm.removeIfNotApplicable();
                _vm.cleanErrorSubproject('products');
                _vm.cleanErrorSubproject('systems')}},model:{value:(_vm.subproject.systems),callback:function ($$v) {_vm.$set(_vm.subproject, "systems", $$v)},expression:"subproject.systems"}})],1)]}}],null,false,450710750)},[_c('span',[_vm._v("Selecione ao menos um produto para ver sistemas")])]):_vm._e(),(_vm.subproject.products.length > 0)?_c('v-autocomplete',{attrs:{"disabled":_vm.ableEditPlatformModernization || _vm.subproject.products.length <= 0,"items":_vm.optionsSystems,"item-text":"name","item-value":"id","item-disabled":"disabled","append-icon":"mdi-chevron-down","chips":"","multiple":"","no-data-text":"carregando...","error-messages":_vm.getErrorSubproject('systems')},on:{"input":function($event){_vm.removeIfNotApplicable();
        _vm.cleanErrorSubproject('products');
        _vm.cleanErrorSubproject('systems')}},model:{value:(_vm.subproject.systems),callback:function ($$v) {_vm.$set(_vm.subproject, "systems", $$v)},expression:"subproject.systems"}}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Expand from "@/components/common/VvExpand";
import TextInput from "@/components/common/VvTextInput";
import TextareaInput from "@/components/common/VvTextareaInput";
import SwitchInput from "@/components/common/VvSwitch.vue";
import ErrorMessageHandler from "@/domains/project/mixins/ErrorMessageHandler";

export default {
  name: "FormProject",
  components: {
    Expand,
    TextInput,
    TextareaInput,
    SwitchInput,
  },
  mixins: [
    ErrorMessageHandler
  ],
  props: {
    value: {
      type: Object,
      required: true,
    },
    optionsStatus: {
      type: Array,
      default: () => [],
    },
    optionsTags: {
      type: Array,
      default: () => [],
    },
    optionsQuarters: {
      type: Array,
      default: () => [],
    },
    optionsProducts: {
      type: Array,
      default: () => [],
    },
    originSubproject: {
      type: Object
    },
    errorList: {
      type: Object,
      default: () => ({}),
    },
    viewMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    expanded: true,
  }),
  methods: {
    chipColor(id) {
      switch(id) {
        case this.CONST_SUBPROJECT_STATUS.APROVADO:
          return 'chip-green'
        case this.CONST_SUBPROJECT_STATUS.REPROVADO:
          return 'chip-red'
        default:
          return ''
      }
    },
    removeIfNotApplicable() {
      this.subproject.tags.map(tag => {
        if (tag === 1) {
          this.subproject.tags = [1]
        }
      })
    },
    checkSystemsValues() {
      let systems = []

      if (typeof this.subproject.systems !== 'undefined' && this.subproject.systems.length > 0) {
        this.subproject.systems.map(system => {
          this.optionsProducts.map(option => {
            option.systems.map(optionSystem => {
              if (optionSystem.id === system) {
                this.subproject.products.map(product => {
                  if (product === option.id) {
                    systems.push(optionSystem.id)
                  }
                })
              }
            })
          })
        })
        this.subproject.systems = systems.length > 0 ? systems : []
      }
    },
    clearProducts() {
      if (!this.subproject.plataformModernization) {
        this.subproject.products = []
        this.subproject.systems = []
      }
    }
  },
  computed: {
    subproject: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    optionsSystems() {
      let systems = []
      this.optionsProducts.map(option => {
        if (typeof this.subproject.products !== "undefined" && this.subproject.products.length > 0) {
          this.subproject.products.map(product => {
            if (option.id === product) {
              option.systems.map(system => {
                systems.push(system)
              })
            }
          })
        }
      })
      return systems
    },
    hasChange() {
      return JSON.stringify(this.subproject) !== JSON.stringify(this.originSubproject);
    },
    ableEditPlatformModernization() {
      if (this.viewMode) {
        return true
      }
      if (this.subproject.editPermission) {
        return false
      }
      if (this.subproject.plataformModernization) {
        return false
      }
    }
  },
  watch: {
    hasChange(value) {
      this.$emit('hasChange', {id: this.subproject.id, hasChange: value});
    },
  },
}
<template>
  <v-container :fluid="true" id="quarters">
    <vv-loading :is-loading="isLoading"></vv-loading>

    <vv-breadcrumbs class="ml-5" :items-breadcrumbs="breadcrumb" />

    <div class="box-quarters">
      <div class="grid-10 mb-10">
        <div class="g-col-8 xs:g-col-10 md:g-col-5">
          <h1>Quarters</h1>
        </div>
        <div id="btnNewYear" class="g-col-2 xs:g-col-8 md:g-col-5 rtl" v-if="canEditQuartersPermission">
          <new-period
              :years-options="infoQuarters.years"
              @status="yearsCreated"
          ></new-period>
        </div>
      </div>

      <div class="grid-1">
        <v-expansion-panels v-model="quartersOpen">
          <v-expansion-panel v-for="(timeCourse) in currentAndFutureYears" :key="timeCourse.id">
            <v-expansion-panel-header v-slot="{ open }">
              <div class="grid-10">
                <div class="g-col-2 xs:g-col-10 year-list mt-3">
                  {{ timeCourse.year }}
                </div>
                <div class="g-col-8 xs:g-col-10 text--secondary">
                  <v-fade-transition leave-absolute>
                    <span v-if="!open">
                      <v-chip v-for="(quarter) in timeCourse.quarters" :key="quarter.id" :class="{'quarters-ok': checkStatusQuarter(quarter)}">
                        Q{{ quarter.quarterNumber }}
                      </v-chip>
                    </span>
                  </v-fade-transition>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="grid-2 xs:grid-1 gap-md">
                <template
                    v-for="(quarter, indexQuarter) in timeCourse.quarters"
                >
                  <quarter-card
                      :quarter.sync="quarter"
                      :errors.sync="errors"
                      :disabled="!canEditQuartersPermission"
                      :index-quarter="indexQuarter"
                      :label-jira-list="options.labelJira"
                      :class="{'quarters-ok': checkStatusQuarter(quarter)}"
                      :key="quarter.id"
                  />
                </template>
              </div>
              <div class="grid-10 xs:grid-2 md:grid-4 rtl xs:rtl md:rtl gap-md mt-10 mb-8">
                <v-btn color="g-col-1 primary" v-if="canEditQuartersPermission" @click="openModalSave(timeCourse.id, timeCourse.quarters)">Salvar</v-btn>
                <v-btn color="g-col-1 text" v-if="canEditQuartersPermission" @click="openModalCancel()">Cancelar</v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>

    <history-quarters
        :quarters="pastYears"
        :list-jira-label="options.labelJira"
    ></history-quarters>

    <vv-confirmation-dialog
        :value="confirmationDialog.value"
        :title="confirmationDialog.title"
        :message="confirmationDialog.message"
        @confirmed="confirmModal"
        @denied="resetConfirmationDialog"
    ></vv-confirmation-dialog>
    <vv-alert
        v-model="alert.show"
        :message="alert.message"
        :type="alert.type"
        :dismissible="alert.dismissible"
    ></vv-alert>
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import HistoryQuarters from "@/domains/quarters/components/HistoryQuarters";
import NewPeriod from "@/domains/quarters/components/NewPeriod";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";
import VvAlert from "@/components/common/VvAlert";
import QuarterCard from "@/domains/quarters/components/QuarterCard";
import quartersServiceFactory from "@/domains/quarters/services/quartersService";
import jiraServiceFactory from "@/domains/jira/services/jiraService";
import {clone} from "@/services/utils";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

const quartersService = quartersServiceFactory();
const jiraService = jiraServiceFactory();

export default {
  name: "ListQuarters",
  components: {
    VvBreadcrumbs,
    VvLoading,
    QuarterCard,
    HistoryQuarters,
    NewPeriod,
    VvConfirmationDialog,
    VvAlert,
  },
  computed: {
    currentAndFutureYears() {
      return this.infoQuarters.years?.filter((quarter) => (!quarter.isHistory));
    },
    pastYears() {
      return this.infoQuarters.years?.filter((quarter) => (quarter.isHistory));
    },
  },
  data: () => ({
    isLoading: false,
    moment: require("moment"),
    canEditQuartersPermission: false,
    infoQuarters: {},
    cloneInfoQuarters: {},
    quartersOpen: null,
    options: {
      labelJira: [],
    },
    confirmationDialog: {
      value: false,
      title: '',
      message: '',
      yearId: null,
      quartersObj: [],
    },
    alert: {
      show: false,
      message: '',
      type: 'info',
      dismissible: false,
    },
    errors: {},
    breadcrumb: [
      {
        text: 'Gestão',
        disabled: true,
      },
      {
        text: 'Quarters',
        disabled: false,
      },
    ],
  }),
  mounted() {
    this.loadQuarters();
  },
  methods: {
    async loadQuarters() {
      this.isLoading = true;
      this.loadJiraLabelsOptions();
      const resultAllQuarters = await quartersService.loadAllQuarters();
      this.canEditQuartersPermission = resultAllQuarters.isEditable;
      this.infoQuarters = resultAllQuarters;
      this.cloneInfoQuarters = clone(this.infoQuarters);
      this.isLoading = false;
      this.status = true;
    },
    async loadJiraLabelsOptions() {
      const resultJiraLabels = await jiraService.loadJiraLabels();
      this.options.labelJira = resultJiraLabels;
    },
    openModalSave(yearId, quartersObj) {
      this.confirmationDialog.yearId = yearId;
      this.confirmationDialog.quartersObj = quartersObj;
      this.confirmationDialog.title = "Confirmar salvamento";
      this.confirmationDialog.message = "Você realmente deseja salvar as alterações?";
      this.confirmationDialog.value = true;
    },
    openModalCancel() {
      this.confirmationDialog.yearId = null;
      this.confirmationDialog.quartersObj = [];
      this.confirmationDialog.title = "Cancelar edição";
      this.confirmationDialog.message = "Você realmente deseja cancelar as alterações?";
      this.confirmationDialog.value = true;
    },
    confirmModal() {
      if (this.confirmationDialog.yearId !== null) {
        this.saveQuarter();
      } else {
        this.cancelQuarter();
      }
    },
    async saveQuarter() {
      this.isLoading = true;
      this.confirmationDialog.value = false;
      const resultSaveQuarters = await quartersService.saveQuarters(this.confirmationDialog.quartersObj);
      if (resultSaveQuarters.status === 200) {
        this.alert.message = resultSaveQuarters.data.message;
        this.alert.type = "success";
        this.alert.show = true;
        this.closeAlert(3000);
      } else {
        this.errors = resultSaveQuarters.data;
        this.alert.message = resultSaveQuarters.data.message || 'Confira os campos com erro';
        this.alert.type = "error";
        this.alert.show = true;
        this.closeAlert(5000);
      }
      this.cloneInfoQuarters = clone(this.infoQuarters);
      this.resetConfirmationDialog();
      this.isLoading = false;
    },
    cancelQuarter() {
      this.quartersOpen = null;
      this.resetConfirmationDialog();
      this.infoQuarters = clone(this.cloneInfoQuarters);
    },
    checkStatusQuarter({dateEnd, datePlanning, dateStart, jiraLabelId}) {
      const quarters = [
        dateEnd,
        datePlanning,
        dateStart,
        jiraLabelId
      ];
      return !quarters.includes(null);
    },
    resetConfirmationDialog() {
      this.confirmationDialog.yearId = null;
      this.confirmationDialog.quartersObj = [];
      this.confirmationDialog.title = '';
      this.confirmationDialog.message = '';
      this.confirmationDialog.value = false;
    },
    closeAlert(timeToClose = 3000) {
      setTimeout(() => {
        this.alert.show = false;
        this.alert.message = '';
        this.alert.type = "info";
      }, timeToClose)
    },
    yearsCreated(responseEmit) {
      if (responseEmit.status >= 200 && responseEmit.status < 300) {
        this.alert.message = responseEmit.data.message;
        this.alert.type = "success";
        this.alert.show = true;
        this.closeAlert(3000);
        this.loadQuarters();
      } else {
        this.alert.message = responseEmit.data.message;
        this.alert.type = "error";
        this.alert.show = true;
        this.closeAlert(5000);
      }
    },
  },
}
</script>

<style lang="scss">
#quarters {
  .box-quarters {
    background-color: #FFFFFF;
    padding: 24px;
    border-radius: 5px;
  }

  .v-expansion-panel {
    background-color: #FCFCFC;
  }

  h1 {
    font-size: 24px;
  }

  .year-list {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
  }

  span.quarters-ok {
    background-color: #699F10;
    border-color: #DBDCDC;
    color: #FCFDFC;
  }

  .v-chip {
    background-color: #FCFDFC;
    border: 1px solid #DBDCDC;
  }

  .v-card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 0px #9E9E9E;
    &.quarters-ok {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 0px #699F10;
    }
  }

  .vv-breadcrumbs {
    ul {
      padding-left: 0;
    }
  }
}
</style>
<template>
  <v-expansion-panels v-model="show" class="vv-expand">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h3 class="title-form">• {{ title }}</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div :class="insideClass">
          <slot />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "VvExpand",
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    insideClass: {
      type: String,
    },
  },
  computed: {
    show: {
      get() {
      return this.value ? 0 : undefined;
      },
      set(value) {
        this.$emit("input", value?.length > 0);
      },
    }
  },
};
</script>

<style lang="scss">
  .vv-expand .v-expansion-panel-header,
  .vv-expand .v-expansion-panel-content__wrap {
    padding-inline: 0;
  }
  .vv-expand .title-form {
    color: #6D6E70;
    font-weight: 600;
    font-size: 15px;
  }
</style>

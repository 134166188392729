<template>
  <div>
    <vv-loading :is-loading="isLoading"></vv-loading>
    <form>
      <v-card flat>
        <v-card-text class="py-8 px-6">
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <label>Nome Delegador</label>
              <v-autocomplete
                  id="project_type"
                  name="project_type"
                  v-model="form.values.user_logged"
                  :items="form.options.approvals"
                  item-text="name"
                  item-value="id"
                  :error-messages="null"
                  append-icon="mdi-chevron-down"
                  required
                  :disabled="isMasterUser"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <label>Aprovador temporário</label>
              <v-autocomplete
                  id="project_type"
                  name="project_type"
                  v-model="form.values.approval"
                  :items="propUsers"
                  item-text="name"
                  item-value="id"
                  :error-messages="null"
                  append-icon="mdi-chevron-down"
                  required
                  :disabled="!isApproval"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="2">
              <label>De</label>
              <vv-date-pick
                  :disabled="!isApproval"
                  v-model="form.values.startDate"
                  :error-messages="null"
                  :clear-field="true"
                  :min-date="form.rulesDate.today"
              ></vv-date-pick>
            </v-col>
            <v-col cols="6" sm="4" md="2">
              <label>Até</label>
              <vv-date-pick
                  :disabled="!isApproval"
                  v-model="form.values.endDate"
                  :error-messages="null"
                  :clear-field="true"
                  :min-date="form.rulesDate.rangeDate"
              ></vv-date-pick>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-btn
                  color="primary"
                  class="mt-5 mr-3"
                  :disabled="!abledBtnCadastrar"
                  @click="openDialogDelegate"
              >Cadastrar</v-btn>
              <v-tooltip bottom max-width="360">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="error"
                      size="22 "
                      class="mt-5"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Ao cadastrar uma delegação de aprovação
                    todas as suas atribuições nos fluxos de
                    aprovação do sistema serão atribuídas pelo
                    período determinado ao usuário selecionado.
                    Caso não tenha um período definido,
                    não preencher a data final</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </form>

    <v-dialog v-model="dialog.newDelegate.open" :persistent="abledBtnCadastrar" max-width="500">
      <v-card>
        <v-card-title>{{ dialog.newDelegate.title }}</v-card-title>
        <v-card-text>
          <p>{{ dialog.newDelegate.text }}</p>
          <div>
            <v-row>
              <v-col cols="12">
                <label>Nome:</label>
                <p>{{ dialog.newDelegate.nameApproval }}</p>
              </v-col>
              <v-col cols="4" v-if="form.values.startDate !== null">
                <label>De:</label>
                <p v-if="abledBtnCadastrar">{{ dialog.newDelegate.startDate }}</p>
              </v-col>
              <v-col cols="8" v-if="form.values.endDate !== null">
                <label>Até:</label>
                <p>{{ dialog.newDelegate.endDate }}</p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="abledBtnCadastrar">
          <v-spacer></v-spacer>
          <v-btn
              color="#7d7d7d"
              text
              @click="closeDialogDelegate()"
          >
            Fechar
          </v-btn>
          <v-btn
              color="primary"
              :disabled="!abledBtnCadastrar"
              @click="saveDelegate()"
          >
            Sim
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else class="d-block">
          <v-spacer></v-spacer>
          <v-alert type="error">
            <p>Por favor, preencha a data inicial da delegação</p>
          </v-alert>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.alertMessage.open" hide-overlay persistent width="400">
      <v-alert dense :type="dialog.alertMessage.type" class="ma-0">
        {{ dialog.alertMessage.textMessage }}
        <ul v-if="dialog.alertMessage.listErrors.length > 0">
          <li v-for="error in dialog.alertMessage.listErrors" :key="error">
            {{ error }}
          </li>
        </ul>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import VvDatePick from "@/components/VvDatePick";
import VvLoading from "@/components/VvLoading";

export default {
  name: "DelegateApprovals",
  components: {
    VvDatePick,
    VvLoading,
  },
  props: {
    propUsers: {},
  },
  computed: {
    approval() {
      return this.form.values.approval;
    },
    startDate() {
      return this.form.values.startDate;
    }
  },
  watch: {
    approval() {
      if (this.form.values.startDate !== null && this.form.values.startDate !== '') {
        this.abledBtnCadastrar = true;
      } else {
        this.abledBtnCadastrar = false;
      }
    },
    startDate() {
      this.form.rulesDate.rangeDate = this.getToday();
      if (this.form.values.startDate !== null && this.form.values.startDate !== '') {
        if (this.form.values.approval !== null) {
          this.abledBtnCadastrar = true;
        } else {
          this.abledBtnCadastrar = false;
        }
        this.form.rulesDate.rangeDate = this.form.values.startDate;
      } else {
        this.abledBtnCadastrar = false;
      }
      this.form.values.endDate = null;
    }
  },
  data: () => ({
    isLoading: false,
    isApproval: false,
    abledBtnCadastrar: false,
    isMasterUser: false,
    form: {
      options: {
        users: [],
        approvals: [],
      },
      values: {
        user_logged: null,
        approval: null,
        startDate: null,
        endDate: null,
      },
      rulesDate: {
        rangeDate: '',
        today: '',
      },
    },
    dialog: {
      newDelegate: {
        open: false,
        title: 'Nova delegação',
        text: 'Você realmente deseja delegar à esse usuário:',
        nameApproval: '',
        startDate: null,
        endDate: null,
      },
      alertMessage: {
        open: false,
        type: 'success',
        textMessage: '',
        listErrors: [],
      },
    },
  }),
  mounted() {
    this.initialize();
    this.form.rulesDate.today = this.form.rulesDate.rangeDate = this.getToday();
    this.form.values.user_logged = this.$store.getters.id;
    this.verifyUserIsMaster();
  },
  methods: {
    initialize() {
      this.isLoading = true;
      this.isApproval = this.$parent.validApproval();
      this.form.options.users = this.propUsers;
      this.loadOnlyApprovalsOptions();
      this.isLoading = false;
    },
    loadOnlyApprovalsOptions() {
      this.axios
          .get("user/all-approvers")
          .then((response) => {
            this.form.options.approvals = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    openDialogDelegate() {
      this.dialog.newDelegate.nameApproval = this.returnNameUser(this.form.values.approval);
      this.dialog.newDelegate.startDate = (this.form.values.startDate !== '') ? this.formattedDateForBr(this.form.values.startDate) : '';
      this.dialog.newDelegate.endDate = (this.form.values.endDate !== null && this.form.values.endDate !== '') ? this.formattedDateForBr(this.form.values.endDate) : '';
      this.dialog.newDelegate.open = true;
    },
    closeDialogDelegate() {
      this.dialog.newDelegate.nameApproval = '';
      this.dialog.newDelegate.startDate = null;
      this.dialog.newDelegate.endDate = null;
      this.dialog.newDelegate.open = false;
    },
    returnNameUser(userId) {
      let userName = '';
      this.form.options.users = this.propUsers;
      for (let indexUser = 0; indexUser < this.form.options.users.length; indexUser++) {
        if (this.form.options.users[indexUser].id === userId) {
          userName = this.form.options.users[indexUser];
          break;
        }
      }
      return userName.name;
    },
    saveDelegate() {
      this.isLoading = true;
      this.dialog.newDelegate.open = false;
      this.dialog.alertMessage.listErrors = [];
      let objSaveDelegate = {
        original_user_id: this.form.values.user_logged,
        delegate_to: this.form.values.approval,
        initial_date: this.form.values.startDate,
        final_date: this.form.values.endDate
      };

      this.axios
          .post("delegations", objSaveDelegate)
          .then(() => {
            this.dialog.alertMessage.type = 'success';
            this.dialog.alertMessage.textMessage = 'Delegação adicionada com sucesso!';
            this.dialog.alertMessage.open = true;
            this.form.values.approval = null;
            this.form.values.startDate = null;
            this.form.values.endDate = null;
            this.$parent.loadDelegations();
          })
          .catch((error) => {
            console.error(error);
            this.dialog.alertMessage.type = 'error';
            this.dialog.alertMessage.textMessage = 'Erro! Veja abaixo os erros:';
            this.dialog.alertMessage.listErrors = error.response.data.errors;
            this.dialog.alertMessage.open = true;
          })
          .finally(() => {
            this.closeDialogDelegate();
            setTimeout(() => {
              this.dialog.alertMessage.open = false;
            }, 6000)
            this.isLoading = false;
          })
    },
    getToday() {
      let date = new Date();
      let year = date.getFullYear();
      let mouth = (date.getMonth() + 1);
      if (mouth < 10) {
        mouth = `0${mouth}`;
      }
      let day = date.getDate();
      if (day < 10) {
        day = `0${day}`;
      }
      let today = `${year}-${mouth}-${day}`;
      return today;
    },
    verifyUserIsMaster() {
      this.isMasterUser = !this.userIsMaster();
    },
  },
}
</script>

<style scoped></style>
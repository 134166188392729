import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";
import StakeholderEditModal from '../../components/StakeholderEditModal/StakeholderEditModal.vue';
import businessTreeServiceFactory from '../../services/businessTreeService.js';
const businessTreeService = businessTreeServiceFactory();

export default {
    components: {
        VvBreadcrumbs,
        VvConfirmationDialog,
        StakeholderEditModal
    },
    data() {
        return {
            showModal: false,
            stakeholderDialog: false,
            options: {},
            newItems: [],
            id: Number,
            breadcrumb: [
                {
                    text: 'Gestão',
                    disabled: true,
                },
                {
                    text: 'Árvores de Negócios',
                    disabled: true,
                },
                {
                    text: 'Nova Área',
                    disabled: false,
                }
            ],
            confirmationDialog: {
                value: false,
                title: 'Excluir Steakholder/Ponto Focal',
                message: 'Você realmente deseja excluir esse steakholder/ponto focal?',
                type: ''
            },
            area: {
                name: "",
                stakeholders: []
            },
            stakeholder: {
                id: null,
                focalPoints: [],
                update: false
            },
            errorList: {},
            stakeholderIndex: null,
            canCreate: false
        }
    },
    methods: {
        errorBorder(index){
            if (typeof this.errorList[`stakeholders.${index}.id`] !== 'undefined' || typeof this.errorList[`stakeholders.${index}.focalPoints.0.id`] !== 'undefined' ) {
                return 'stakeholder-error'
            }

            return '';
        },
        async getOptions() {
            try {
                this.loading = true;
                const resultFilterOptions = await businessTreeService.getOptions();
                const resultFormOptions = await businessTreeService.getFormOptions();
                this.options = resultFilterOptions.options;
                this.options = {...this.options, ...resultFormOptions}
                if (!resultFilterOptions.canCreate) {
                    this.$showError('Você não tem permissão para criar uma nova Área');
                    this.$router.push('/gestao/arvores-negocio');
                }
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        openCreateModal() {
            this.$refs.newStakeholderModal.handleNewFocalPoint();
            this.stakeholderDialog = true;
        },
        openEditModal(stakeholder, index) {
            this.stakeholder = stakeholder;
            this.stakeholderIndex = index;
            this.$set(this.stakeholder, 'update', true);
            this.stakeholderDialog = true;
        },
        getIdRemove(index) {
            this.confirmationDialog = {
                value: true,
                title: 'Excluir Steakholder/Ponto Focal',
                message: 'Você realmente deseja excluir esse steakholder/ponto focal?',
                type: ''
            },
            this.id = index;
        },
        handleNew() {
            this.newItems.push({});
        },
        handleModal() {
            this.confirmationDialog.value = false;

            if (this.confirmationDialog.type === 'cancel') {
                this.$router.push('/gestao/arvores-negocio');
            } else if (this.confirmationDialog.type === 'save') {
                this.createArea();
            } else {
                this.area.stakeholders.splice(this.id, 1);
            }
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        cancel() {
            this.confirmationDialog = {
                value: true,
                title: 'Cancelar cadastro',
                message: 'Você realmente deseja cancelar o cadastro dessa árvore?',
                type: 'cancel'
            }
        },
        save() {
            this.confirmationDialog = {
                value: true,
                title: 'Salvar cadastro',
                message: 'Você realmente deseja salvar o cadastro dessa árvore?',
                type: 'save'
            }
        },
        saveStakeholder() {
            this.stakeholderDialog = false;
            if (this.stakeholder.update) {
                this.area.stakeholders.map(stakeholder => {
                    if (stakeholder.id === this.stakeholder.id) {
                        stakeholder = this.stakeholder;
                    }
                })
            } else {
                this.area.stakeholders.push({
                    id: this.stakeholder.id,
                    focalPoints: this.stakeholder.focalPoints
                });
            }
            this.stakeholder = {
                id: null,
                focalPoints: [],
                update: false
            };
        },
        getStakeholderName(id) {
            let name = "";
            this.options.stakeholders.map(stakeholder => {
                if (stakeholder.id === id) {
                    name = stakeholder.name;
                }
            })
            return name;
        },
        closeStakeholderModal() {
            this.stakeholderDialog = false;
            this.stakeholder = {
                id: null,
                focalPoints: [],
                update: false
            };
        },
        setErrorList(error) {
            const {
                response: { data: errors },
            } = error;
            this.errorList = errors;
        },
        getError(param) {
            return this.errorList[param];
        },
        clearError(param) {
            delete this.errorList[param];
        },
        async createArea() {
            try {
                this.loading = true;
                await businessTreeService.createArea(this.area);
                this.$showSuccess("Área cadastrada com sucesso!");
                setTimeout(() => {
                    this.$router.push('/gestao/arvores-negocio');
                }, 2000);
            } catch (error) {
                if (error.response.status !== 422) {
                    this.$showError(error);
                }
                this.$showError('Um ou mais stakeholders não estão cadastrados corretamente, verifique e tente novamente.');
                this.setErrorList(error);
            } finally {
                this.loading = false;
            }
        }
    },
    computed: {
        isDisable() {
            if (this.area.name !== '' && this.area.transformation_manager_id !== null) {
                return false;
            }
            return true
        }
    },
    created() {
        this.getOptions();
    }
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"data-table-change-requests",attrs:{"headers":_vm.headers,"items":_vm.changeRequests,"single-expand":true,"expanded":_vm.expandedChange,"mobile-breakpoint":0,"no-data-text":_vm.noDataText,"no-results-text":_vm.noResultsText,"search":_vm.search,"custom-filter":_vm.customSearch,"itemsPerPage":_vm.changeRequests.length,"hide-default-footer":"","item-key":"id","dense":""},on:{"update:expanded":function($event){_vm.expandedChange=$event},"click:row":_vm.openDetails},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"id":'cr-' + item.id}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"reason mb-0",attrs:{"id":'cr-' + item.id}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"workflow-status",class:_vm.parseStatusChangeRequest(item.statusId)},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.transformationManagers",fn:function(ref){
var item = ref.item;
return _vm._l((item.transformationManagers),function(transformationManager,i){return _c('p',{key:i,staticClass:"approver transformation-manager",class:transformationManager.status.toLowerCase()},[_vm._v(" "+_vm._s(transformationManager.name)+" ")])})}},{key:"item.vsm",fn:function(ref){
var item = ref.item;
return _vm._l((item.vsm),function(vsm,i){return _c('p',{key:i,staticClass:"approver vsm",class:vsm.status.toLowerCase()},[_vm._v(" "+_vm._s(vsm.name)+" ")])})}},{key:"item.focalPoints",fn:function(ref){
var item = ref.item;
return _vm._l((item.focalPoints),function(focalPoint,i){return _c('p',{key:i,staticClass:"approver focal-point",class:focalPoint.status.toLowerCase()},[_vm._v(" "+_vm._s(focalPoint.name)+" ")])})}},{key:"item.stakeholder",fn:function(ref){
var item = ref.item;
return _vm._l((item.stakeholder),function(stakeholder,i){return _c('p',{key:i,staticClass:"approver stakeholder",class:stakeholder.status.toLowerCase()},[_vm._v(" "+_vm._s(stakeholder.name)+" ")])})}},{key:"item.expandButton",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"#6D6E70"}},[(item.id === _vm.toggleById)?_c('v-icon',[_vm._v("mdi-chevron-up")]):_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('details-change-request',{attrs:{"item":_vm.details,"permissions":_vm.permissions,"project":_vm.project,"deliveryPlanned":_vm.deliveryPlanned},on:{"updateChangeRequest":_vm.updateChangeRequest,"error":function($event){_vm.errorAlert = $event},"loading":function($event){_vm.loading = $event}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        createOrUpdate: {
            type: String,
            default: 'create',
        },
        areaDetail: {
            type: Object,
            default: () => ({}),
        },
        options: {
            type: Object,
            default: () => ({})
        },
        area: {
            type: Object,
            default: () => ({})
        },
        errorList: {
            type: Object,
            default: () => ({})
        },
        stakeholderIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            itemDialog: {
                value: false,
                switchStakeholder: false,
                switchFocalPoint: false,
                data: [{}],
                type: ''
            },
            name: '',
            focalPointPanel: null,
        }
    },
    computed: {
        areaName() {
            if (!this.isEmpty(this.area)) {
                return this.area.name;
            }
        },
        transformationManagerName() {
            if (!this.isEmpty(this.area)) {
                if (this.createOrUpdate === 'update' || this.createOrUpdate === 'createInUpdate') {
                    return this.area.transformation_managers.name;
                } 
                return this.area.transformation_manager_name;
            }
        }
    },
    methods: {
        getError(param) {
            return this.errorList[param];
        },
        clearError(param) {
            this.$emit('clearError', param);
        },
        handleNewFocalPoint() {
            this.areaDetail.focalPoints.unshift({
                active: true,
                disabled: false,
                id: null,
                name: "",
                new: true
            });
            this.focalPointPanel = 0;
        },
        handleRemoveFocalPoint(index) {
            this.areaDetail.focalPoints.splice(index, 1);
        },
        cancel() {
            this.$emit("close");
        },
        save() {
            if (this.createOrUpdate === 'update' || this.createOrUpdate === 'createInUpdate') {
                let obj = {
                    id: this.area.id,
                    active: this.areaDetail.active ? true : this.area.area_status,
                    name: this.area.name,
                    stakeholders: [
                        this.areaDetail
                    ]
                };
                this.$emit("save", obj);
            } else {
                this.$emit("save");
            }
        },
        handleChange(data, index) {
            this.options.focalPoints.map(focalPoint => {
                if (focalPoint.id === data.id) {
                    this.areaDetail.focalPoints[index].name = focalPoint.name;
                }
            });
            this.$emit('clearError', `stakeholders.${this.stakeholderIndex}.focalPoints.${index}.id`)
        },
    }
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.canShowFormalization)?[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":"","max-width":"277"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"grey","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_c('p',[_vm._v("\"Formalização de entrega\" deve ser usado para concluir o épico.")]),_c('p',[_vm._v("Necessário alterar e salvar o \"Estágio\" do épico para \"Pronto para formalizar\", além de que iniciativas com \"Capex\" exigem preenchimento do campo \"Ordem de Investimento\" no épico para habilitar.")])])]),_vm._v(" Formalização de Entrega "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,3792741660)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"px-6",attrs:{"link":"","disabled":!_vm.createDeliveryFormalization},on:{"click":_vm.openModal}},[_c('v-list-item-title',[_vm._v(" Concluir entrega ")])],1),_c('v-list-item',{staticClass:"px-6",attrs:{"link":"","disabled":!_vm.existDeliveryFormalization},on:{"click":_vm.showFormalization}},[_c('v-list-item-title',[_vm._v(" Consultar formalização ")])],1)],1)],1)]:[_c('v-tooltip',{attrs:{"top":"","max-width":"277"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 personal-disabled",attrs:{"dark":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1 personal-disabled",attrs:{"dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")]),_vm._v(" Formalização de Entrega "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('span',[_c('p',[_vm._v("\"Formalização de entrega\" deve ser usado para concluir o épico.")]),_c('p',[_vm._v("Necessário alterar e salvar o \"Estágio\" do épico para \"Pronto para formalizar\", além de que iniciativas com \"Capex\" exigem preenchimento do campo \"Ordem de Investimento\" no épico para habilitar.")])])])],_c('v-dialog',{attrs:{"width":"960","persistent":"","scrollable":""},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}},[_c('v-card',{attrs:{"id":"modalDeliveryFormalization"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Formalização de Entrega")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-4 px-4"},[_c('form-delivery-formalization',{ref:"formDF",attrs:{"options":_vm.options,"item":_vm.item,"focal-points-tap":_vm.focalPointsTap,"project-area":_vm.projectArea},on:{"deliveryFormalizationsSaved":_vm.closeModal}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs.formDF.confirmationDialog.value=true}}},[_vm._v(" Submeter entrega ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
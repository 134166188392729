import VvLoading from "@/components/common/VvLoading";
import VvBreadcrumbs from '@/components/common/VvBreadcrumbs';
import VvNewFilter from "@/components/common/VvNewFilter";
import VvPagination from '@/components/common/VvPagination';
import ListJourneys from "../../components/ListJourneys/ListJourneys.vue";
import technologyTreeServiceFactory from "../../services/technologyTreeService";
import MigrateJourneyModal from '../../components/MigrateJourneyModal/MigrateJourneyModal.vue';
import MigrateTribeModal from '../../components/MigrateTribeModal/MigrateTribeModal.vue';
import MigrateSquadModal from '../../components/MigrateSquadModal/MigrateSquadModal.vue';
import TribeEditModal from "../../components/TribeEditModal/TribeEditModal.vue";
import JourneyEditModal from "../../components/JourneyEditModal/JourneyEditModal.vue";
import InactivationModal from "../../components/InactivationModal/InactivationModal.vue";

const technologyTreeService = technologyTreeServiceFactory();

export default {
    components: {
        VvLoading,
        VvBreadcrumbs,
        VvNewFilter,
        VvPagination,
        ListJourneys,
        MigrateJourneyModal,
        MigrateTribeModal,
        MigrateSquadModal,
        TribeEditModal,
        JourneyEditModal,
        InactivationModal
    },
    data() {
        return {
            loading: false,
            loadingOptions: false,
            breadcrumb: [
                {
                    text: 'Gestão',
                    disabled: true,
                },
                {
                    text: 'Árvore de Técnologia',
                    disabled: false,
                }
            ],
            filterName: 'technologyTree',
            fields: [
                {
                    type: "multiautocomplete",
                    label: "Jornada",
                    sm: "4",
                    col: "4",
                    id: "journeys",
                    name: "journeys",
                    value: "",
                    payloadBackend: "journeyId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "VSM",
                    sm: "4",
                    col: "4",
                    id: "vsms",
                    name: "vsms",
                    value: "",
                    payloadBackend: "vsmId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "sr GPM",
                    sm: "4",
                    col: "4",
                    id: "srGpms",
                    name: "srGpms",
                    value: "",
                    payloadBackend: "seniorGpmId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Tribo",
                    sm: "4",
                    col: "4",
                    id: "tribes",
                    name: "tribes",
                    value: "",
                    payloadBackend: "tribeId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "GPM",
                    sm: "4",
                    col: "4",
                    id: "gpms",
                    name: "gpms",
                    value: "",
                    payloadBackend: "gpmId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Squad",
                    sm: "4",
                    col: "4",
                    id: "squads",
                    name: "squads",
                    value: "",
                    payloadBackend: "squadKey[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "PM/PO",
                    sm: "4",
                    col: "4",
                    id: "pmpo",
                    name: "pmpo",
                    value: "",
                    payloadBackend: "pmpoId[or]",
                    items: [],
                },
            ],
            payload: {},
            pageSize: 5,
            atualPage: 0,
            totalItems: 0,
            journeys: [],
            journeysClone: [],
            journeyDetail: {},
            journey: {},
            showJourneyModal: false,
            showTribeModal: false,
            showSquadModal: false,
            journeyDetailClone: {},
            showTribeEditModal: false,
            tribeEditModalData: {},
            showJourneyEditModal: false,
            showInactivationModal: false,
            inactivationModalData: {},
            inactivationModalType: '',
            inactivationModalOptions: {},
            showTribeCreateModal: false,
            tribeCreateModalData: {},
            options: {},
            formOptions: {},
            canCreate: false,
            errorList: {},
        }
    },
    methods: {
        async getFilterOptions() {
            try {
                this.loadingOptions = true;
                const result = await technologyTreeService.getFilterOptions();
                const resultOptionsForm = await technologyTreeService.getFormOptions();
                this.formOptions = resultOptionsForm.options;
                this.options = result.options;
                this.canCreate = result.permissions.canCreateMigrate;
                this.fields.map((field) => {
                    field.items = result.options[field.name];
                });
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loadingOptions = false;
            }
        },
        async getTechnologyTrees(viaUrl = false) {
            try {
                this.loading = true;
                const result = await technologyTreeService.getTechnologyTrees(this.payload);
                this.atualPage = result.tech.current_page - 1;
                this.pageSize = parseInt(result.tech.per_page);
                this.totalItems = result.tech.total;
                this.journeys = result.tech.data;
                if (viaUrl) {
                    this.$refs.listJourneys.openJourneyByUrl(this.$route.query.journeyId);
                }
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        async openJourney(id, byInactivation = false) {
            try {
                this.loading = true;
                const result = await technologyTreeService.getJourney(id);
                this.journeyDetail = result.journey;

                if (byInactivation) {
                    this.inactivationModalData = result.journey;
                    this.$refs.inactivationModal.createData('journey', result.journey);
                }
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        async getJourneyInativactionOptions(id) {
            try {
                this.loading = true;
                const result = await technologyTreeService.getJourneyInativactionOptions(id);
                this.inactivationModalOptions = result.tree;
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        filter(payloads, viaUrl = false) {
            for (let payload in payloads) {
                if (payloads[payload] !== null) {
                    this.payload[payload] = payloads[payload]
                }
            }
            this.getTechnologyTrees(viaUrl);
            this.$refs.listJourneys.closeJourneys();
        },
        resetFilter() {
            this.payload = {}
            this.getTechnologyTrees();
        },
        journeyMigrateModal() {
            this.showJourneyModal = true;
            this.$refs.migrateJourneyModal.clear();
        },
        tribeMigrateModal() {
            this.showTribeModal = true;
            this.$refs.migrateTribeModal.clear();
        },
        squadMigrateModal() {
            this.showSquadModal = true;
            this.$refs.migrateSquadModal.clear();
        },
        openTribeEditModal(journeyDetail, index) {
            this.tribeEditModalData = {
                journeyDetail: journeyDetail,
                index: index
            }
            this.journeyDetailClone = JSON.parse(JSON.stringify(this.journeyDetail));
            this.showTribeEditModal = true;
        },
        openTribeCreateModal(tribeIndex) {
            this.journeyDetailClone = JSON.parse(JSON.stringify(this.journeyDetail));
            this.journeyDetail.tribes.push({
                id: null,
                name: "",
                isActive: true,
                gpmId: null,
                gpmName: "",
                squads: []
            });
            this.$refs.tribeEditModal.addSquad(tribeIndex);
            let index = (this.journeyDetail.tribes.length) - 1;
            this.tribeCreateModalData = {
                journeyDetail: this.journeyDetail,
                index: index
            }
            this.showTribeCreateModal = true;
        },
        openJourneyEditModal(journey) {
            this.showJourneyEditModal = true;
            this.journeysClone = JSON.parse(JSON.stringify(this.journeys));
            this.journey = journey;
        },
        cancelEditTribe() {
            this.showTribeEditModal = false;
            this.showTribeCreateModal = false;
            this.journeyDetail = this.journeyDetailClone;
        },
        cancelEditJourney() {
            this.showJourneyEditModal = false;
            this.journeys = this.journeysClone;
        },
        cancelInactivation(id, type) {
            this.showInactivationModal = false;
            if (type === 'journey') {
                this.journeys.map(journey => {
                    if (journey.id === id) {
                        journey.isActive = true
                    }
                })
            } else if (type === 'tribe') {
                this.journeyDetail.tribes.map(tribe => {
                    if (tribe.id === id) {
                        tribe.isActive = true
                    }
                })
            } else if (type === 'squad') {
                this.journeyDetail.tribes.map(tribe => {
                    tribe.squads.map(squad => {
                        if (squad.id === id) {
                            squad.isActive = true
                        }
                    })
                })
            } 
        },
        addSquad(index) {
            this.journeyDetail.tribes[index].squads.unshift({
                isActive: true,
                id: null,
                name: "",
                pmpo: [],
                squadKey: "",
                new: true
            });
        },
        removeSquad(tribeIndex, squadIndex) {
            this.journeyDetail.tribes[tribeIndex].squads.splice(squadIndex, 1);
        },
        async openInactivationModal(type, data, option = {}) {
            if (await this.checkTreeLevel(data.id, type)) {
                if (type === 'journey') {
                    this.getJourneyInativactionOptions(data.id);
                    this.openJourney(data.id, true);
                } else {
                    this.inactivationModalData = data;
                    this.inactivationModalOptions = option;
                    this.$refs.inactivationModal.createData(type, data);
                }
                this.showInactivationModal = true;
                this.inactivationModalType = type;
            }
        },
        async migrateJourney(data) {
            data = {
                oldVsmId: data.oldVsmId,
                newVsmId: data.newVsmId
            }

            try {
                this.loading = true;
                const result = await technologyTreeService.migrateJourney(data);
                this.$showSuccess(result.message);
                this.showJourneyModal = false;
                this.getFilterOptions();
                this.resetFilter();
                setTimeout(() => {
                    this.$router.push('/gestao/arvores-tecnologia');
                    this.$router.go();
                }, 2000);
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        async migrateTribe(data) {
            data = {
                tribeId: data.tribeId,
                journeyId: data.newJourneyId
            }

            try {
                this.loading = true;
                const result = await technologyTreeService.migrateTribe(data);
                this.$showSuccess(result.message);
                this.showTribeModal = false;
                this.getFilterOptions();
                setTimeout(() => {
                    this.$router.push('/gestao/arvores-tecnologia');
                    this.$router.go();
                }, 2000);
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        async migrateSquad(data) {
            data = {
                squadKey: data.squadKey,
                tribeId: data.newTribeId
            }

            try {
                this.loading = true;
                const result = await technologyTreeService.migrateSquad(data);
                this.$showSuccess(result.message);
                this.showSquadModal = false;
                this.getFilterOptions();
                setTimeout(() => {
                    this.$router.push('/gestao/arvores-tecnologia');
                    this.$router.go();
                }, 2000);
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        async editTribe(fromTo = null) {
            if (fromTo !== null) {
                this.payloadToEdit.fromTo = fromTo
            }
            try {
                this.loading = true;
                const result = await technologyTreeService.editTechTree(this.payloadToEdit);
                this.$showSuccess(result.message);
                this.journeyDetail = result.journey;
                this.showTribeEditModal = false;
                this.showTribeCreateModal = false;
                this.getFilterOptions();
                this.journeys.map((journey, key) => {
                    if (journey.id === result.journey.id) {
                        this.journeys[key] = result.journey;
                    }
                })
            } catch (error) {
                if (error.response.status !== 422) {
                    this.$showError(error);
                }
                this.setErrorList(error);
            } finally {
                this.loading = false;
                if (fromTo !== null) {
                    this.showInactivationModal = false;
                }
            }
        },
        async editJourney(fromTo = null) {
            if (fromTo !== null) {
                this.journey.fromTo = fromTo
            }
            try {
                this.loading = true;
                delete this.journey.tribes;
                const result = await technologyTreeService.editTechTree(this.journey);
                this.$showSuccess(result.message);
                this.journeys.map((journey, key) => {
                    if (journey.id === result.journey.id) {
                        this.journeys[key] = {
                            id: result.journey.id, 
                            name: result.journey.name, 
                            isActive: result.journey.isActive, 
                            vsmId: result.journey.vsmId, 
                            vsmName: result.journey.vsmName, 
                            srGpmId: result.journey.srGpmId, 
                            srGpmName: result.journey.srGpmName,
                            oldSrGpmId: result.journey.srGpmId
                        }
                    }
                })
                this.openJourney(result.journey.id)
                this.showJourneyEditModal = false;
                this.journey = {};
                this.getFilterOptions();
            } catch (error) {
                if (error.response.status !== 422) {
                    this.$showError(error);
                }
                this.setErrorList(error);
            } finally {
                this.loading = false;
                if (fromTo !== null) {
                    this.showInactivationModal = false;
                }
            }
        },
        init() {
            let fieldsStore = this.getGlobalFilters(this.filterName);
            let payloads = {};
            this.fields = typeof fieldsStore !== 'undefined' ? fieldsStore : this.fields;
            if (typeof this.$route.query.journeyId !== 'undefined') {
                payloads = {
                    'journeyId[or]': [this.$route.query.journeyId]
                }
                this.filter(payloads, true);
            } else if (typeof fieldsStore !== 'undefined') {
                this.fields.map(field => {
                    if (field.value) {
                        payloads[field.payloadBackend] = field.value;
                    }
                });
                if (Object.keys(payloads).length) {
                    payloads.page = 1;
                    this.filter(payloads);
                }
            } else {
                this.getTechnologyTrees();
            }
        },
        setErrorList(error) {
            const {
                response: { data: errors },
            } = error;
            this.errorList = errors;
        },
        clearError(param) {
            delete this.errorList[param];
        },
        saveInactivation(fromTo, type) {
            if (type === 'journey') {
                this.editJourney(fromTo)
            } else {
                this.editTribe(fromTo)
            }
        },
        async checkTreeLevel(id, type) {
            switch (type) {
                case 'journey':
                    type = 1;
                    break;
                case 'tribe':
                    type = 2;
                    break;
                case 'squad':
                    type = 3;
                    break;
            }
            try {
                this.loading = true;
                const result = await technologyTreeService.checkTreeLevel(id, type);
                return result.isOnActiveEpic;
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        }
    },
    created() {
        this.getFilterOptions();
        this.init();
    },
    computed: {
        journeyDetailCopy() {
            return JSON.parse(JSON.stringify(this.journeyDetail));
        },
        journeysCopy() {
            return JSON.parse(JSON.stringify(this.journey));
        },
        payloadToEdit() {
            let payloadToEdit = {
                disable: this.journeyDetail.disable,
                id: this.journeyDetail.id,
                isActive: this.journeyDetail.isActive,
                name: this.journeyDetail.name,
                oldSrGpmId: this.journeyDetail.oldSrGpmId,
                oldVsmId: this.journeyDetail.oldVsmId,
                srGpmId: this.journeyDetail.srGpmId,
                srGpmName: this.journeyDetail.srGpmName,
                tribes: [],
                vsmId: this.journeyDetail.vsmId,
                vsmName: this.journeyDetail.vsmName
            };

            this.journeyDetail.tribes.map((tribe, indexTribe) => {
                payloadToEdit.tribes.push({
                    id: tribe.id,
                    name: tribe.name,
                    isActive: tribe.isActive,
                    disable: tribe.disable,
                    gpmId: tribe.gpmId,
                    oldGpmId: tribe.oldGpmId,
                    gpmName: tribe.gpmName,
                    squads: []
                })

                tribe.squads.map((squad, indexSquad) => {
                    payloadToEdit.tribes[indexTribe].squads.push({
                        id: squad.id,
                        squadKey: squad.squadKey,
                        name: squad.name,
                        disable: squad.disable,
                        pmpo: [],
                        isActive: squad.isActive
                    })

                    squad.pmpo.map(pmpo => {
                        payloadToEdit.tribes[indexTribe].squads[indexSquad].pmpo.push(typeof pmpo.id !== 'undefined' ? pmpo.id : pmpo)
                    })
                })
            });

            return payloadToEdit;
        }
    },
    watch: {
        atualPage(value) {
            this.payload['page'] = (value + 1);
            this.getTechnologyTrees();
        },
        pageSize(value) {
            this.payload['pageSize'] = parseInt(value);
            this.payload['page'] = 1;
            this.getTechnologyTrees();
        },
        journeyDetailCopy(newVal, oldVal) {
            if (!this.isEmpty(oldVal)) {
                if (oldVal.id === newVal.id) {
                    let activeTribes = 0;
                    oldVal.tribes.map(oldVal => {
                        if (oldVal.isActive) {
                            activeTribes += 1;
                        }
                    });
                    oldVal.tribes.map(oldTribe => {
                        newVal.tribes.map(newTribe => {
                            if (oldTribe.id === newTribe.id) {
                                if (oldTribe.isActive === true && newTribe.isActive === false) {
                                    if (activeTribes > 1) {
                                        this.openInactivationModal('tribe', newTribe, newVal);
                                    }
                                }
                                let activeSquads = 0;
                                oldTribe.squads.map(squad => {
                                    if (squad.isActive) {
                                        activeSquads += 1;
                                    }
                                });
                                oldTribe.squads.map(oldSquad => {
                                    newTribe.squads.map(newSquad => {
                                        if (oldSquad.id === newSquad.id) {
                                            if (oldSquad.isActive === true && newSquad.isActive === false) {
                                                if (activeSquads > 1) {
                                                    this.openInactivationModal('squad', newSquad, newTribe);
                                                }
                                            }
                                        }
                                    });
                                });
                            }
                        });
                    });
                }
            }
        },
        journeysCopy(newVal, oldVal) {
            if (!this.isEmpty(oldVal)) {
                if (oldVal.id === newVal.id) {
                    if (
                        oldVal.isActive === true &&
                        newVal.isActive === false
                    ) {
                        this.openInactivationModal('journey', newVal);
                    }
                }
            }
        }
    }
}
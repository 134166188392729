import { render, staticRenderFns } from "./InvestmentOrder.vue?vue&type=template&id=0fb76007&scoped=true&"
import script from "./InvestmentOrder.js?vue&type=script&lang=js&"
export * from "./InvestmentOrder.js?vue&type=script&lang=js&"
import style0 from "./InvestmentOrder.scss?vue&type=style&index=0&id=0fb76007&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb76007",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAlert,VBtn,VIcon,VSwitch})

import axios from "@/plugins/axios";
import { map, getFormData } from "./utils";

const campaignMap = [
    "id",
    "name",
    "objective",
    ["userResponsible", "responsible_user"],
    ["startValidity", "validity_of"],
    ["endValidity", "validity_until"],
    ["noEndDate", "no_end_date"],
];

export default function campaignServiceFactory() {
    return {
        list() {
            return axios
                .get("campaign/list")
                .then((res) => res.data)
                .then((res) => res.map(item => map(item).from(campaignMap)))
        },
        getFileAttachment(id) {
            return axios.get(`campaign/attachment/${id}`)
            .then((res) => res.data);
        },
        create(payload) {
            return axios.post(
                "campaign",
                getFormData(payload),
                {
                    headers: {
                        "Content-Type": "multipart/form-data;",
                    },
                }
            );
        },
        changeStatus(id) {
            return axios
                .put(`campaign/change-status/${id}`)
                .then((res) => res.data)
        },
        edit(payload) {
            return axios.post(
                `campaign/${payload.id}?_method=PUT`,
                getFormData(payload),
                {
                    headers: {
                        "Content-Type": "multipart/form-data;",
                    },
                }
            );
        },
    }
}
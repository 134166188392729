<template>
    <v-container :fluid="true">

        <vv-loading :is-loading="isLoading"></vv-loading>

        <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />
        
        <v-card>
            <v-spacer class="h-20"></v-spacer>
            <v-tabs
                v-model="currentTree"
                background-color="toolbarColor" 
                centered
                color="toolbarTextColor"
            >
                <v-tab
                    v-for="tree in trees"
                    :key="tree.id"
                >
                    {{ tree.value }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentTree">
                <v-tab-item
                    v-for="tree in trees"
                    :key="tree.id"
                >
                    <v-card flat>
                        <v-card-text v-if="tree.id == CONST_TREES.BUSINESS">
                            <template v-if="businessCreate">
                                <CreateTreeBusiness
                                    v-show="businessCreate"
                                    @Edit="businessCreate = $event"></CreateTreeBusiness>
                            </template>
                            <template v-else>
                                <business
                                    v-show="!businessCreate"
                                    @Create="businessCreate = $event"></business>
                            </template>
                        </v-card-text>
                        <v-card-text v-if="tree.id == CONST_TREES.TECNOLOGIC">
                            <Tecnologic v-on:loading="loadingChange"></Tecnologic>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>
    import Business from '@/components/trees/Business';
    import CreateTreeBusiness from '@/components/trees/CreateTreeBusiness';
    import Tecnologic from "@/components/trees/Tecnologic";
    import VvLoading from "@/components/VvLoading";
    import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

    export default {
        components: {
            VvBreadcrumbs,
            VvLoading,
            Business,
            CreateTreeBusiness,
            Tecnologic
        },
        
        mounted: function () {
              this.initialize()
        },

        data: () => ({
            isLoading: false,
            currentTree: 0,
            trees: [
                { id: 0, value: "Árvore de Negócios" },
                { id: 1, value: "Árvore de Tecnologia" }
            ],
            breadcrumb: [
                {
                    text: 'Gestão',
                    disabled: true,
                },
                {
                    text: 'Árvores',
                    disabled: true,
                },
                {
                    text: 'Árvore de Negócios',
                    disabled: false,
                }
            ],
            businessCreate: false
        }),

        watch: {
            currentTree(tabId) {
                switch (tabId) {
                    case this.CONST_TREES.BUSINESS:
                        this.breadcrumb = [
                            {
                                text: 'Gestão',
                                disabled: true,
                            },
                            {
                                text: 'Árvores',
                                disabled: true,
                            },
                            {
                                text: 'Árvore de Negócios',
                                disabled: false,
                            }
                        ];
                        break;
                    case this.CONST_TREES.TECNOLOGIC:
                        this.breadcrumb = [
                            {
                                text: 'Gestão',
                                disabled: true,
                            },
                            {
                                text: 'Árvores',
                                disabled: true,
                            },
                            {
                                text: 'Árvore de Técnologia',
                                disabled: false,
                            }
                        ]
                        break;
                }
            }
        },

        methods: {
            initialize() {
                this.isLoading = true 
                setTimeout(() => {
                        this.isLoading = false
                    }, 300 
                )
            },
            loadingChange(status){
                this.isLoading = status;
            }
        },

    }
</script>

<style scoped>
    .v-tab {
        text-transform: capitalize;
        border-radius: 5px 5px 0 0;
        font-size: 14px;
        font-weight: bold;
        font-family: 'Poppins';
    }

    .v-tab--active {
        background-color: var(--text-white);
    }

    .h-20 {
        height: 20px;
        background-color: var(--text-warning);
    }

    .v-slide-group__prev.v-slide-group__prev--disabled {
        display: none;
    }
</style>
<template>
    <v-form ref="form" lazy-validation id="business" v-scroll="fixFooter">
        

                <vv-loading :is-loading="isLoading"></vv-loading>

                <v-row>
                    <v-col cols="12" sm="6">
                        <h2>Listagem/Edição de Árvore de Negócios</h2>
                    </v-col>
                    <v-col cols="12" sm="6" align="end">
                        <v-btn outlined color="primary" @click="newTree()" v-if="!isDisabledForm">
                            Nova Área
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col sm="12" md="5" cols="12">
                        <label for="area">Área Responsável</label>
                        <v-autocomplete
                            id="area"
                            name="area"
                            item-text="name"
                            item-value="id"
                            v-model="form.area.id"
                            :items="areas"
                            @change="
                            resetFields();
                            integrationAreas();"
                            append-icon="mdi-chevron-down"
                            no-data-text = "Sem Área Responsável Cadastrada para este parâmetro"
                            clearable
                            required
                        ></v-autocomplete>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col sm="12" md="5" cols="12">
                        <label for="transformation_manager"
                        >Gerente Escritório Transformação</label
                        >
                        <div>
                            <v-autocomplete
                                id="transformation_manager"
                                name="transformation_manager"
                                item-text="name"
                                item-value="id"
                                no-data-text="Nenhum registro encontrado"
                                v-model="form.transformation_manager.id"
                                :items="usersDeliveryOffice"
                                :disabled="isDisabledForm"
                                @change="setNewTransformationManagerId(0, form.transformation_manager.id)"
                                append-icon="mdi-chevron-down"
                                required
                            ></v-autocomplete>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                  <v-expansion-panels>
                    <template v-for="(stakeholder, indexStakeholder) in form.stakeholders" cols="12" sm="12">
                        <v-expansion-panel :key="indexStakeholder" class="mw-98 mb-3" cols="12" sm="12" :style="(!stakeholder.active) ? 'background-color: #DBDCDC' : ''">
                          <v-expansion-panel-header v-slot="{ open }">
                            <v-row no-gutters>
                              <v-col cols="12" class="mb-4">
                                <strong>Responsável pelo Negócio/Stakeholder Negócio</strong>
                              </v-col>
                              <v-col
                                  cols="12"
                                  class="text--secondary"
                              >
                                <v-fade-transition leave-absolute>
                                  <v-row
                                      v-if="!open"
                                      no-gutters
                                      style="width: 100%"
                                  >
                                    <v-col cols="9" sm="10">
                                      {{ stakeholder.name }}
                                    </v-col>
                                    <v-col cols="3" sm="2" class="mt-sm-n4" v-if="!stakeholder.clone">
                                      <span :class="(stakeholder.active) ? 'secondary--text' : 'error--text'">
                                        <strong>{{ (stakeholder.active) ? 'ATIVO' : 'INATIVO' }}</strong>
                                      </span>
                                    </v-col>
                                  </v-row>
                                </v-fade-transition>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <v-row
                                justify="space-around"
                                no-gutters
                            >
                              <v-col cols="12">
                                <v-col :key="indexStakeholder" cols="12" sm="12">
                                  <v-row>
                                    <v-col cols="12" class="py-0">
                                      <label class="d-block">Nome do responsável</label>
                                    </v-col>
                                    <v-col cols="10" sm="10" class="list pt-0">
                                      <v-autocomplete
                                          id="stakeholders"
                                          name="stakeholders"
                                          item-text="name"
                                          item-value="id"
                                          item-disabled="disabled"
                                          no-data-text="Selecione uma opção de Área Responsável"
                                          v-model="stakeholder.id"
                                          :items="usersStakeholders"
                                          :disabled="isDisabledForm"
                                          @change="setNewStakeholderId(indexStakeholder, stakeholder.id)"
                                          append-icon="mdi-chevron-down"
                                          required
                                      >
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col cols="2" sm="2">
                                      <template v-if="stakeholder.clone">
                                        <v-btn class="delete" @click="delete_block_stakeholder(indexStakeholder)">
                                          <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                      </template>
                                      <template v-else>
                                        <v-switch
                                            color="secondary"
                                            class="switch manual-line-list-item-icon mt-n3"
                                            v-model="stakeholder.active"
                                            :label="stakeholder.active ? 'Ativo' : 'Inativo'"
                                            inset
                                            :disabled="disable"
                                            @click="checkDialogStakeholder(stakeholder)"
                                        >
                                        </v-switch>
                                      </template>
                                    </v-col>

                                    <v-expansion-panels>
                                      <v-expansion-panel :style="(!stakeholder.active) ? 'background-color: #DBDCDC' : ''">
                                        <v-expansion-panel-header class="remove-space-icon">
                                          <v-col sm="12" md="2" cols="12">
                                            <div class="focalpoints_title">
                                              <label>Pontos Focais:</label>
                                            </div>
                                          </v-col>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                          <v-row>
                                            <template v-for="(focalpoint, indexFocalpoint) in stakeholder.focalPoints">
                                              <v-col :key="indexFocalpoint" :sm="(focalpoint.clone) ? 12 : 6" :lg="(focalpoint.clone) ? 12 : 4" cols="11" class="mb-3">
                                                <v-row :class="focalpoint.clone ? 'mr-3 mr-sm-n3 mr-md-3' : ''">
                                                  <template v-if="focalpoint.clone">
                                                    <v-col sm="11" md="12" cols="12" class="tree-row-list mr-sm-n3 mr-3 mr-md-3">
                                                      <div class="line">
                                                        <v-col cols="9" sm="10" md="11">
                                                          <label for="new_focalpoints">Nome:</label>
                                                          <template>
                                                            <v-autocomplete
                                                                id="new_focalpoints"
                                                                name="new_focalpoints"
                                                                item-text="name"
                                                                item-value="id"
                                                                item-disabled="disabled"
                                                                no-data-text="Selecione um Stakeholder"
                                                                v-model="form.stakeholders[indexStakeholder].focalPoints[indexFocalpoint].id"
                                                                :items="usersFocalPoints"
                                                                :disabled="isDisabledForm"
                                                                append-icon="mdi-chevron-down"
                                                                required
                                                            >
                                                            </v-autocomplete>
                                                          </template>
                                                        </v-col>
                                                        <v-col cols="2" md="1">
                                                          <template>
                                                            <v-btn class="delete mt-9" @click="delete_block_new_focalpoint(indexStakeholder, indexFocalpoint)">
                                                              <v-icon small>mdi-delete</v-icon>
                                                            </v-btn>
                                                          </template>
                                                        </v-col>
                                                      </div>
                                                    </v-col>
                                                  </template>
                                                  <template v-else>
                                                    <v-row>
                                                      <v-col cols="12" sm="11" md="12">
                                                        <div class="list tree-row-list">
                                                          <template>
                                                            <v-col cols="9" sm="9" class="float-left float-sm-none">
                                                              <label class="d-block">Nome:</label>
                                                              <div class="focalpoints">
                                                                <v-tooltip bottom>
                                                                  <template v-slot:activator="{ on, attrs }">
                                                                    <span
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                      {{ focalpoint.name }}
                                                                    </span>
                                                                  </template>
                                                                  <span>{{ focalpoint.name }}</span>
                                                                </v-tooltip>
                                                              </div>
                                                              <div>
                                                              </div>
                                                            </v-col>
                                                          </template>
                                                          <template>
                                                            <v-col cols="2" sm="3" class="float-left float-sm-none">
                                                              <v-switch
                                                                  color="secondary"
                                                                  class="switch manual-line-list-item-icon mt-5 "
                                                                  v-model="focalpoint.active"
                                                                  :label="focalpoint.active ? 'Ativo' : 'Inativo'"
                                                                  @click="checkDialogFocalPoint(focalpoint, stakeholder)"
                                                                  inset
                                                                  :disabled="checkDisabledSwitch(indexStakeholder)"
                                                              >
                                                              </v-switch>
                                                            </v-col>
                                                          </template>
                                                        </div>
                                                      </v-col>
                                                    </v-row>
                                                  </template>
                                                </v-row>
                                              </v-col>
                                            </template>
                                          </v-row>
                                          <v-row>
                                            <v-col sm="6" md="4" cols="11" class="py-0" v-if="!isDisabledForm">
                                              <v-btn
                                                  outlined
                                                  color="primary"
                                                  class="ml-2 my-2 add-focal-point btn-add-focal-point"
                                                  @click="clone_block_new_focalpoint(indexStakeholder)"
                                              >
                                                Novo Ponto Focal
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-expansion-panel-content>
                                      </v-expansion-panel>
                                    </v-expansion-panels>
                                  </v-row>
                                </v-col>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                    </template>
                  </v-expansion-panels>
                </v-row>

                <v-row v-if="!isDisabledForm">
                    <v-col sm="12" md="12" cols="12">
                        <v-btn outlined color="primary" class="add-focal-point" @click="clone_block_stakeholder()">
                            Novo Stakeholder
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="mb-2">
                  <v-col sm="12" md="12" cols="12" align="end" v-if="!isDisabledForm">
                    <v-btn color="secondary" @click="closeOrClear('clear')" class="btn-edit" outlined>
                      Limpar
                    </v-btn>
                    <v-btn color="primary" class="btn-edit" @click="editTree()">
                      Salvar
                    </v-btn>
                  </v-col>
                </v-row>

                <div class="footerFixed" v-if="!isDisabledForm" :class="footerFixed ? 'show' : ''">
                   
                        <v-row>
                            <v-col sm="12" md="12" cols="12" align="end" >
                                <v-btn color="secondary" @click="closeOrClear('clear')" class="btn-edit" outlined>
                                    Limpar
                                </v-btn>
                                <v-btn color="primary" class="btn-edit mr-6" @click="editTree()">
                                    Salvar
                                </v-btn>
                            </v-col>
                        </v-row>
                    
                </div>

                <v-dialog v-model="dialogMessage" hide-overlay persistent width="400">
                    <v-alert dense :type="typeMessage" style="margin: 0">
                        <div style="display: flex; justify-content: space-between;">
                            <span>{{textMessage}}</span>
                            <v-button @click="dialogMessage = false">
                                <v-icon>
                                    mdi-window-close
                                </v-icon>
                            </v-button>
                        </div>
                    </v-alert>
                </v-dialog>
            <v-dialog v-model="dialogDisabledFocalPoint" persistent max-width="960">
                <v-card>
                    <div class="d-flex justify-content-between">
                        <v-card-title class="text-h5"></v-card-title>
                        <v-btn fab text @click="closeDialogFocalPoint()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <label>Ponto Focal</label>
                                <v-text-field
                                    :disabled="true"
                                    v-model="focalPointToDisable.name"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <label>Ponto Focal Substituto</label>
                                <v-autocomplete
                                    v-model="focalPointsToChange.id"
                                    :items="focalPointsToChange"
                                    item-text="name"
                                    item-value="id"
                                    append-icon="mdi-chevron-down"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text>
                        <v-spacer></v-spacer>
                        <v-row>
                            <v-col cols="12" align="end">
                                <v-tooltip right max-width="250">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon class="mr-3" color="primary" v-bind="attrs" v-on="on">
                                            mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>
                                        O ponto focal possui iniciativas ativas atreladas à ele, deve-se selecionar um novo ponto focal do stakeholder para recebe-las
                                    </span>
                                </v-tooltip>
                                <v-btn
                                    color="primary"
                                    elevation="2"
                                    @click="disableFocalPoint()"
                                >Salvar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDisabledStakeholder" persistent max-width="960">
                <v-card>
                    <div class="d-flex justify-content-between">
                        <v-card-title class="text-h5"></v-card-title>
                        <v-btn fab text @click="closeDialogStakeholder()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <label>Stakeholder</label>
                                <v-text-field
                                    :disabled="true"
                                    v-model="stakeholderToDisableName"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <label>Stakeholder Substituto</label>
                                <v-autocomplete
                                    v-model="stakeholdersToChange.id"
                                    :items="stakeholdersToChange"
                                    item-text="name"
                                    item-value="id"
                                    append-icon="mdi-chevron-down"
                                    @change="loadFocalPoints"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <template v-for="(focalpoint, i) in focalPointsArray">
                            <v-row :key="focalpoint.id">
                                <v-col cols="6">
                                    <label>Ponto Focal</label>
                                    <v-text-field
                                        :disabled="true"
                                        v-model="focalpoint.name"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <label>Ponto Focal Substituto</label>
                                    <v-autocomplete v-if="focalPointsArray[i].newFocalPoints"
                                        v-model="focalPointsArray[i].newFocalPoints.id"
                                        :items="focalPointsArray[i].newFocalPoints"
                                        item-text="name"
                                        item-value="id"
                                        append-icon="mdi-chevron-down"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </template>
                    </v-card-text>
                    <v-card-text>
                        <v-spacer></v-spacer>
                        <v-row>
                            <v-col cols="12" align="end">
                                <v-tooltip right max-width="250">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon class="mr-3" color="primary" v-bind="attrs" v-on="on">
                                          mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <span>
                                        O stakeholder possui pontos focais que também serão inativados e ambos possuem iniciativas ativas atreladas à eles, deve-se selecionar o novo stakeholder e seus pontos focais para receberem as iniciativas
                                    </span>
                                </v-tooltip>
                                <v-btn
                                    color="primary"
                                    elevation="2"
                                    @click="disableStakeholder()"
                                >Salvar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

        <template>
            <v-row justify="center">
                <v-dialog
                    v-model="modalCloseOrClear.status"
                    persistent
                    width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                            {{ modalCloseOrClear.title }}
                        </v-card-title>
                        <v-card-text>{{ modalCloseOrClear.text }}</v-card-text>
                        <v-card-actions style="border-top: 0.5px solid #A1A1A1">
                            <v-spacer></v-spacer>
                            <v-btn
                            color="#A1A1A1"
                            text
                            @click="modalCloseOrClear.status = false"
                            >
                            Não
                            </v-btn>
                            <v-btn
                            color="primary"
                            @click="modalCloseOrClear.status = false; reset()"
                            >
                            Sim
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
    </v-form>
</template>

<style lang="scss" scoped>
#business {
    .manual-line :nth-child(2n){
        @media (max-width: 500px) {
            border:none !important;
            display: block;
        }
    }

    .delete {
        min-width: 30px !important;
        height: 30px !important;
        padding: 0px !important;
        background-color: transparent;
        color: #FF0000;
        //border: 1px solid #FF0000;
        //border-radius: 15px;
        margin-top: 2px;
        margin-left: 5px;
        box-shadow: none;

        &.last{
            @media(max-width:599px){
                margin-top: 0px;
            }
        }
    }

    .spotlight {
    font-weight: bold;
      font-size: 16px;
    }

    .tree-div {
        margin-top: 20px;
        border: 1px solid #9e9c9c;
        border-radius: 4px 4px 0 0;
        background-color: white;
        padding: 30px;
    }

    .tree-row-list {
        border-radius: 6px;
        background-color: white;
        padding: 0px;
        margin: 0px 0px 0px 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 0px #5D4EE8;
        min-height: 94px;
    }
    .btn-add-focal-point {
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25), inset 0px 4px 0px #C6BEFF;
      border-radius: 6px;
      height: 50px !important;
    }

    .btn-edit.v-btn {
        margin-left: 0px;
        margin-right: 20px;
    }

    .btn-edit.v-btn:last-child {
        margin-right: 0px;
    }

    .focalpoints_title {
        padding: 0;
        label {
            font-size: 14px;
            font-weight: 500;
        }
    }
    .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
         @media (max-width: 500px) {
           display: block;
         }
        .focalpoints {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 210px;
          @media screen and (max-width: 380px) {
            max-width: 180px;
          }
        }
    }
    .line {
        display: flex;
        flex-direction: row;
    }

    .v-expansion-panel {
      border: 1px solid #e2deff;
      border-radius: 5px;
      .v-expansion-panel {
        border: none;
        button {
          padding-left: 0;
        }
        .v-expansion-panel-content__wrap {
          padding-left: 0 !important;
        }
      }
    }

    button.add-focal-point {
      text-transform: uppercase;
      border: 1px solid #d3d3d3;
      border-radius: 6px;
      width: 100%;
      height: 46px;
    }

    .mw-98 {
      max-width: 98%;
    }

    .footerFixed {
        position: fixed;  
        width: 100%;
        bottom: 0px;
        left: 0px;
        z-index: 200;
        background-color: #ffffff;
        display: none;
        box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);

        .save-btn-footer-fixed {
            margin-right: 24px;
        }

        &.show {
            display: block;
        }
    }
}
</style>

<script>
    import VvLoading from "@/components/VvLoading";

    export default {
        name: "Business",
        components: {
            VvLoading,
        },

        data: () => ({
            footerFixed: false,
            disable: false,
            isLoading: true,
            isDisabledForm: true,
            urlCreate: "CrateTreeBusiness",
            dialogMessage: false,
            textMessage: "",
            typeMessage: "success",
            objectItem: {
                name: "",
            },
            defaultItem: {
                name: "",
            },
            modalCloseOrClear: {
                status: false,
                title: '',
                text: ''
            },
            values: [],
            fields: [],
            items: [],
            active_stakeholder: null,
            active_focalpoint: null,
            active: null,
            focalPoints: [],
            focalPoint: null,
            users: [],
            usersDeliveryOffice: [],
            usersStakeholders: [],
            usersFocalPoints: [],
            areas: [],
            responseAreas: [],
            block_new_focalpoint: [],
            new_focalpoint: [],
            new_focalpoints: [],
            new_stakeholders: [],
            form: {
                area: {
                    id: null,
                },
                transformation_manager: {
                    id: null,
                },
                stakeholders: [],
            },
            isMobile: false,
            stakeholder_ids: [],
            dialogDisabledFocalPoint: false,
            disabledAtual: null,
            focalPointsToChange: [],
            focalPointToDisable: {
                name:null,
                id:null,
                stakeholderId:null,
            },
            stakeholdersToChange: [],
            stakeholderToDisable: null,
            stakeholderToDisableName: null,
            dialogDisabledStakeholder: false,
            focalPointsArray:[],
            transformation_manager_ids: [],
            clearModal: false,
        }),
        directives: {
            scroll: {
                inserted: function(el, binding) {
                    let f = function(evt) {
                        if (binding.value(evt, el)) {
                            window.removeEventListener('scroll', f);
                        }
                    };
                    window.addEventListener('scroll', f);
                }
            }
        },

        dialogMessage(val) {
            if (!val) return;

            setTimeout(function () {
                this.dialogMessage = false;
                this.textMessage = "success";
            }, 200);
        },

        watch: {},

        created() {
            this.mountValuesTreeFields();
            this.getUsers();
            this.checkPermission();
            this.disabledSwitch();
        },

        mounted () {
        },

        methods: {
            fixFooter: function() {
                let endOfPage = document.body.scrollHeight > window.innerHeight ? document.body.scrollHeight - window.innerHeight : window.innerHeight
                this.footerFixed = window.scrollY < (endOfPage - 125) ? true : false;
            },
            closeOrClear(status = null) {
                this.modalCloseOrClear.status = true

                if (status == 'close') {
                    this.modalCloseOrClear.title = "Sair da criação"
                    this.modalCloseOrClear.text = "Você realmente deseja sair da criação da árvore?"
                } else if (status == 'clear') {
                    this.modalCloseOrClear.title = "Limpar dados preenchidos"
                    this.modalCloseOrClear.text = "Você realmente deseja limpar os dados preenchidos?"
                } else {
                    this.modalCloseOrClear.status = false
                }
            },

            checkDisabledSwitch(index) {
              if (this.disable) {
                  return this.disable;
              }

              if (!this.form.stakeholders[index].active) {
                  return true;
              }

              return false;
            },

            checkDialogFocalPoint(val, stakeholder) {
                if(!val.active && !val.disabled) {
                    this.disabledAtual = val;
                    this.focalPointsToChange = [];
                    this.focalPointToDisable.name = val.name;
                    this.focalPointToDisable.id = val.id;
                    this.focalPointToDisable.stakeholderId = stakeholder.id;

                    stakeholder.focalPoints.forEach(focalPoint => {
                        if (focalPoint.active && !focalPoint.disabled) {
                            this.focalPointsToChange.push(focalPoint);
                        }
                    });

                    if (this.focalPointsToChange.length == 0) {
                        this.textMessage = 'É necessário ter ao menos um ponto focal ativo';
                        this.typeMessage = "warning";
                        this.dialogMessage = true;
                        setTimeout(() => {
                            this.disabledAtual.active = true;
                        }, 1)

                        return true;
                    }

                    this.dialogDisabledFocalPoint = true;
                }
            },

            checkDialogStakeholder(val) {
                if(!val.active && !val.disabled) {
                    this.focalPointsArray = []
                    this.disabledAtual = val;
                    this.stakeholdersToChange = [];
                    this.stakeholderToDisable = val;
                    this.stakeholderToDisableName = val.name;

                    this.form.stakeholders.forEach(stakeholder => {
                        if (stakeholder.active && !stakeholder.disabled) {
                            this.stakeholdersToChange.push(Object.assign([], stakeholder));
                        }
                    });


                    if (this.stakeholdersToChange.length == 0) {
                        this.textMessage = 'É necessário ter ao menos um stakeholder ativo';
                        this.typeMessage = "warning";
                        this.dialogMessage = true;
                        setTimeout(() => {
                            this.disabledAtual.active = true;
                        }, 1)

                        return true;
                    }

                    this.focalPointsArray = Object.assign([], this.stakeholderToDisable.focalPoints);
                    let focalPointsTempArray = [];
                    this.focalPointsArray.forEach(focalPoints => {
                        if (focalPoints.active) {
                            focalPoints.newFocalPoints = [];
                          focalPointsTempArray.push(focalPoints);
                        }
                    });
                    this.focalPointsArray = focalPointsTempArray;

                    this.dialogDisabledStakeholder = true;
                }
            },

            closeDialogFocalPoint() {
                this.dialogDisabledFocalPoint = false;
                this.disabledAtual.active = true;
            },

            closeDialogStakeholder() {
                this.dialogDisabledStakeholder = false;
                this.disabledAtual.active = true;
                this.focalPointsArray = [];
            },

            loadFocalPoints(event) {
                this.stakeholdersToChange.forEach(stakeholder => {
                    if (stakeholder.id == event) {
                        this.focalPointsArray.forEach(focalPoints => {
                            focalPoints.newFocalPoints = Object.assign([],stakeholder.focalPoints);
                        });
                    }
                });
            },

            initialize() {
                this.isLoading = true 
                setTimeout(() => {
                        this.isLoading = false
                    }, 300 
                )
            },
            checkPermission() {

                if (this.$store.getters.type.indexOf(this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO) != -1 ||
                    this.$store.getters.type.indexOf(this.CONST_USER_TYPE.USUARIO_MASTER) != -1) {
                    this.isDisabledForm = false;
                }

            },
            newTree() {
                let state = true;
                this.$emit("Create", state);
            },

            clone_block_stakeholder() {
                this.form.stakeholders.push({
                    active: true,
                    focalPoints: [],
                    id: 0,
                    name: "",
                    clone: true,
                });
            },

            delete_block_stakeholder(index) {
                this.form.stakeholders.splice(index, 1);
            },

            clone_block_new_focalpoint(i) {
                this.form.stakeholders[i].focalPoints.push({
                    id: 0,
                    name: "",
                    active: true,
                    clone: true,
                });
            },

            delete_block_new_focalpoint(index_stakeholder, index_focalPoint) {
                this.form.stakeholders[index_stakeholder].focalPoints.splice(
                    index_focalPoint,
                    1
                );
            },

            disableFocalpoints(index = null, items) {


                Object.keys(items).forEach((key) => {
                    this.form.stakeholders[index].focalPoints[key].active = false
                    this.form.stakeholders[index].focalPoints[key].disable = true
                    this.form.stakeholders[index].focalPoints[key].isDisabledForm = true
 
                })
            },

            mountValuesTreeFields() {
                this.isLoading = true;

                this.axios
                    .get("tree/business")
                    .then((response) => {
                        this.responseAreas = response.data;
                        Object.keys(response.data).forEach((key) => {
                            this.areas.push(response.data[key].area);
                        });
                    })
                    .catch((error) => {
                        this.typeMessage = "warning";
                        this.textMessage = error;
                        this.dialogMessage = true;
                    });
            },

            integrationAreas() {
                this.stakeholder_ids = [];
                this.transformation_manager_ids = [];
                Object.keys(this.responseAreas).forEach((key) => {
                    let ArrayFull = this.responseAreas[key];

                    if (ArrayFull.area.id == this.form.area.id) {
                        this.form.transformation_manager.id =
                            ArrayFull.transformation_manager.id; // Gerente de Transformação
                        this.transformation_manager_ids.push({old: ArrayFull.transformation_manager.id})

                        Object.keys(ArrayFull.stakeholders).forEach((key2) => {
                            this.form.stakeholders.push(ArrayFull.stakeholders[key2]); // Stakeholders (com os respectivos Pontos Focais)
                            this.stakeholder_ids.push({old: ArrayFull.stakeholders[key2].id})
                        });
                    }
                });
            },

            disabledSwitch() {
                
                this.disable = true
                if (this.$store.getters.type.indexOf(this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO) != -1) {
                    this.disable = false
                }                
            },

            getUsers() {
                this.isLoading = true;

                this.axios
                    .get("user")
                    .then((response) => {
                        this.users = response.data;
                    })
                    .catch((error) => {
                        this.typeMessage = "warning";
                        this.textMessage = error;
                        this.dialogMessage = true;
                    });

              this.axios
                  .get("tree/business/users-option")
                  .then((response) => {
                    this.usersDeliveryOffice = response.data;
                  })
                  .catch((error) => {
                    this.typeMessage = "warning";
                    this.textMessage = error;
                    this.dialogMessage = true;
                  });

                this.axios
                    .get("tree/business/users-options")
                    .then((response) => {
                        this.usersStakeholders = response.data.optionsStakeholders;
                        this.usersFocalPoints = response.data.optionsFocalPoints;
                    })
                    .catch((error) => {
                        this.typeMessage = "warning";
                        this.textMessage = error;
                        this.dialogMessage = true;
                    });
            },

            editTree() {
                let submitData = this.form
                if (submitData.area.id == null) {
                    this.typeMessage = "warning";
                    this.textMessage = "Selecione uma Área"
                    this.dialogMessage = true
                    return false
                }

                submitData.stakeholder_ids = this.stakeholder_ids;
                submitData.transformation_manager_ids = this.transformation_manager_ids;

                this.axios
                    .put("tree/business/", submitData)
                    .then((response) => {
                        this.textMessage = response.data.message;
                        this.typeMessage = "success";
                        this.dialogMessage = true;
                        this.mountValuesTreeFields();
                        this.reset();
                        this.initialize();
                    })
                    .catch((error) => {
                        this.textMessage = error.response.data.message;
                        this.typeMessage = "warning";
                        this.dialogMessage = true;
                    });
            },

            disableStakeholder() {
                if(this.stakeholdersToChange.id == null) {
                    this.textMessage = 'Selecione o stakeholder e os pontos focais substitutos';
                    this.typeMessage = "warning";
                    this.dialogMessage = true;
                    return false;
                }

                let submitData = {};
                submitData.areaId = this.form.area.id;
                submitData.focalpoints = [];

                let i = 0;
                this.focalPointsArray.forEach(focalPoint => {
                    if(focalPoint.newFocalPoints.id == null) {
                        this.textMessage = 'Selecione os pontos focais substitutos';
                        this.typeMessage = "warning";
                        this.dialogMessage = true;
                    }

                    submitData.focalpoints[i] = {};
                    submitData.focalpoints[i].id = focalPoint.id;
                    submitData.focalpoints[i].newFocalPointId = focalPoint.newFocalPoints.id;
                    i++;
                });

                if (this.dialogMessage) {
                  return false
                }

                submitData.newStakeholderId = this.stakeholdersToChange.id;
                submitData.stakeholderId = this.stakeholderToDisable.id;

                this.axios
                    .put("tree/business/disabled-stakeholder/", submitData)
                    .then((response) => {
                        this.textMessage = response.data.message;
                        this.typeMessage = "success";
                        this.dialogMessage = true;
                        this.dialogDisabledStakeholder = false;
                        this.mountValuesTreeFields();
                        this.reset();
                        this.initialize();
                    })
                    .catch((error) => {
                        this.textMessage = error.response.data.message;
                        this.typeMessage = "warning";
                        this.dialogMessage = true;
                    });
            },

            disableFocalPoint() {
                if(this.focalPointsToChange.id == null) {
                    this.textMessage = 'Selecione o ponto focal substituto';
                    this.typeMessage = "warning";
                    this.dialogMessage = true;
                    return false;
                }

                let submitData = {};
                submitData.areaId = this.form.area.id;
                submitData.stakeholderId = this.focalPointToDisable.stakeholderId;
                submitData.focalPointId = this.focalPointToDisable.id;
                submitData.newFocalPointId = this.focalPointsToChange.id;

                this.axios
                    .put("tree/business/disabled-focalpoint/", submitData)
                    .then((response) => {
                        this.textMessage = response.data.message;
                        this.typeMessage = "success";
                        this.dialogMessage = true;
                        this.dialogDisabledFocalPoint = false;
                        this.mountValuesTreeFields();
                        this.reset();
                        this.initialize();
                    })
                    .catch((error) => {
                        this.textMessage = error.response.data.message;
                        this.typeMessage = "warning";
                        this.dialogMessage = true;
                    });
            },

            resetFields() {
                this.form.stakeholders = [];
                this.focalPoints = [];
                this.new_focalpoint = [];
            },

            reset() {
                this.form.area = {};
                this.form.transformation_manager = {};
                this.form.stakeholders = [];
                this.focalPoints = [];
                this.new_focalpoint = [];
                this.clearModal = false;
                this.initialize();
            },

            setNewStakeholderId(index, value){
                this.stakeholder_ids[index].new = value;
            },

            setNewTransformationManagerId(index, value){
                this.transformation_manager_ids[index].new = value;
            }
        },
    };
</script>

import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";
import VvNewFilter from "@/components/common/VvNewFilter";
import VvPagination from '@/components/common/VvPagination';
import ListAreas from "../../components/ListAreas/ListAreas.vue";
import AreaEditModal from "../../components/AreaEditModal/AreaEditModal.vue";
import VvLoading from "@/components/common/VvLoading";
import businessTreeServiceFactory from '../../services/businessTreeService.js';
import StakeholderEditModal from '../../components/StakeholderEditModal/StakeholderEditModal.vue';
import InativeFocalPointModal from '../../components/InativeFocalPointModal/InativeFocalPointModal.vue';

const businessTreeService = businessTreeServiceFactory();

export default {
    components: {
        VvBreadcrumbs,
        VvNewFilter,
        VvPagination,
        ListAreas,
        VvLoading,
        AreaEditModal,
        StakeholderEditModal,
        InativeFocalPointModal
    },
    data() {
        return {
            loading: false,
            areaDialog: false,
            stakeholderDialog: false,
            stakeholder: {
                value: false,
                title: "Editar árvore",
                update: true,
                data: {}
            },
            area: {},
            breadcrumb: [
                {
                    text: 'Gestão',
                    disabled: true,
                },
                {
                    text: 'Árvores de Negócios',
                    disabled: false,
                }
            ],
            urlCreate: '/gestao/arvores-negocio/criar',
            filterName: 'businessTree',
            canCreate: false,
            options: {},
            formOptions: {},
            payload: {},
            fields: [
                {
                    type: "multiautocomplete",
                    label: "Área",
                    sm: "4",
                    col: "4",
                    id: "area",
                    name: "area",
                    value: "",
                    payloadBackend: "areaId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Stakeholder",
                    sm: "4",
                    col: "4",
                    id: "stakeholder",
                    name: "stakeholder",
                    value: "",
                    payloadBackend: "stakeholderUserId[or]",
                    items: [],
                },
                {
                    type: "multiautocomplete",
                    label: "Ponto Focal",
                    sm: "4",
                    col: "4",
                    id: "focalPoint",
                    name: "focalPoint",
                    value: "",
                    payloadBackend: "focalPointUserId[or]",
                    items: [],
                },
            ],
            pageSize: 5,
            atualPage: 0,
            totalItems: 0,
            areas: [],
            itemDialog: {
                value: false,
                switchStakeholder: false,
                switchFocalPoint: false,
                data: [{}],
                type: ''
            },
            inativeFocalPointOptions: {
                name: "",
                options: []
            },
            stakeholderDetail: {},
            areaDetail: {},
            mirroAreaDatail: {},
            areaIndex: null,
            newStakeholderDialog: false,
            newStakeholder: {
                id: null,
                focalPoints: [],
                update: false,
                active: true
            },
            focalPointIndex: 0,
            errorList: {},
            areaDetailBackup: {}
        }
    },
    methods: {
        handleChangePage(urlCreate) {
            this.$router.push(urlCreate);
        },
        async getOptions() {
            try {
                this.loading = true;
                const resultFilterOptions = await businessTreeService.getOptions();
                const resultFormOptions = await businessTreeService.getFormOptions();
                this.canCreate = resultFilterOptions.canCreate;
                this.options = resultFilterOptions.options;
                this.formOptions = {...this.options, ...resultFormOptions}
                this.loadFields(resultFilterOptions);
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        async getAreas(viaUrl = false) {
            try {
                this.loading = true;
                const result = await businessTreeService.getAreas(this.payload);
                this.atualPage = result.business.current_page - 1;
                this.pageSize = parseInt(result.business.per_page);
                this.totalItems = result.business.total;
                this.areas = result.business.data;
                if (viaUrl) {
                    this.identifyAndOpenAreaRow(this.$route.query.areaId);
                }
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        loadFields(result) {
            this.fields.map((field) => {
                if (field.name === 'area') {
                    field.items = result.options.areas;
                } else if (field.name === 'transformationManager') {
                    field.items = result.options.transformationManagers;
                } else if (field.name === 'stakeholder') {
                    field.items = result.options.stakeholders;
                } else if (field.name === 'focalPoint') {
                    field.items = result.options.focalPoints;
                }
            });
        },
        areaEditModal(area) {
            this.area = area;
            this.areaDialog = true;
        },
        stakeholderEditModal(stakeholder, area) {
            this.area = area;
            this.stakeholder.data = stakeholder;
            this.areaDetailBackup = JSON.parse(JSON.stringify(this.areaDetail));
            this.stakeholderDialog = true;
        },
        async getStakeholders(id) {
            try {
                this.loading = true;
                const data = await businessTreeService.getStakeholders(id);

                const filterStakeholders = data.options.stakeholders.filter(stakeholder => stakeholder.id !== this.stakeholder.data.id);
                const newObject = { stakeholders: filterStakeholders }

                this.stakeholderDetail = newObject;

            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        filter(payloads, viaUrl = false) {
            for (let payload in payloads) {
                if (payloads[payload] !== null) {
                    this.payload[payload] = payloads[payload]
                }
            }
            this.getAreas(viaUrl);
            this.$refs.ListAreas.closeAreas();
        },
        resetFilter() {
            this.payload = {}
            this.areaIndex = null;
            this.getAreas();
        },
        openModalInative(data, id = null, index = null) {
            if (id !== null) {
                this.mountModalInactiveOptions(id);
            }
            this.$refs.inativeFocalPointModal.clearData();
            this.itemDialog = data;
            this.getStakeholders(this.area.id)
            if (index !== null) {
                this.focalPointIndex = index;
            }
        },
        mountModalInactiveOptions(id) {
            this.inativeFocalPointOptions = {
                id: null,
                name: "",
                options: []
            };

            this.stakeholder.data.focalPoints.map((focalPoint) => {
                if (focalPoint.id !== id && !focalPoint.new) {
                    this.inativeFocalPointOptions.options.push(focalPoint);
                } else {
                    this.inativeFocalPointOptions.name = focalPoint.name;
                    this.inativeFocalPointOptions.id = focalPoint.id;
                }
            })
        },
        switchFocalPoint() {
            this.stakeholder.data.focalPoints.map((focalPoint) => {
                if (focalPoint.id === this.inativeFocalPointOptions.id) {
                    return focalPoint.active = true;
                }
            })
        },
        async openDetails(area, viaUrl = false) {
            try {
                this.loading = true;
                const result = await businessTreeService.getAreaDetail(area.areaId);
                this.areaDetail = result;
                this.mirroAreaDatail = JSON.parse(JSON.stringify(result))
                if (viaUrl) {
                    this.$vuetify.goTo("#areaIdGoTo-"+area.areaId+this.areaIndex, { offset: 115 });
                }
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        init() {
            let fieldsStore = this.getGlobalFilters(this.filterName);
            let payloads = {};
            this.fields = typeof fieldsStore !== 'undefined' ? fieldsStore : this.fields;
            if (typeof this.$route.query.areaId !== 'undefined') {
                payloads = {
                    'areaId[or]': [this.$route.query.areaId]
                }
                this.filter(payloads, true);
            } else if (typeof fieldsStore !== 'undefined') {
                this.fields.map(field => {
                    if (field.value) {
                        payloads[field.payloadBackend] = field.value;
                    }
                });
                if (Object.keys(payloads).length) {
                    payloads.page = 1;
                    this.filter(payloads);
                }
            } else {
                this.getAreas();
            }
        },
        identifyAndOpenAreaRow(areaId) {
            this.areas.map((area, index) => {
                if (area.areaId === parseInt(areaId)) {
                    this.areaIndex = index;
                }
            });
            this.openDetails({ areaId: parseInt(areaId) }, true);
        },
        setErrorList(error) {
            const {
                response: { data: errors },
            } = error;
            this.errorList = errors;
        },
        clearError(param) {
            delete this.errorList[param];
        },
        async editStakeholder(params) {
            try {
                this.loading = true;
                const result = await businessTreeService.editStakeholder(params);
                result.area.stakeholders_id.map(stakeholder => {
                    if (typeof params.stakeholders !== 'undefined') {
                        this.areas.map((area) => {
                            if (area.areaId === params.id) {
                                area.areaActive = params.active;
                                area.areaId = params.id;
                                area.areaName = params.name;
                                area.transformationManagersId = params.transformation_manager_id;
                                area.transformationManagersName = "";
                            }
                        });
                        if (stakeholder.id === params.stakeholders[0].id) {
                            this.stakeholder.data = stakeholder;
                        }
                    } else {
                        this.areas.map((area) => {
                            if (area.areaId === params.id) {
                                area.areaActive = params.active;
                                area.areaId = params.id;
                                area.areaName = params.name;
                                area.transformationManagersId = params.transformation_manager_id;
                                area.transformationManagersName = "";
                            }
                        });
                    }
                });
                this.areaDetail = result.area;
                this.mirroAreaDatail = JSON.parse(JSON.stringify(result.area));
                this.$showSuccess(result.message || "Árvore editada com sucesso!");
                this.areaDialog = false;
                this.stakeholderDialog = false;
                this.closeStakeholderModal();
            } catch (error) {
                if (error.response.status !== 422) {
                    this.$showError(error);
                }
                this.setErrorList(error);
            } finally {
                this.loading = false;
            }
        },
        updateMirror(area) {
            this.areaDetail = area
            this.mirroAreaDatail = JSON.parse(JSON.stringify(area));
        },
        openNewStakeholderModal(area) {
            this.area = area;
            this.newStakeholderDialog = true;
            this.$refs.newStakeholderModal.handleNewFocalPoint();
        },
        closeStakeholderModal() {
            this.newStakeholderDialog = false;
            this.newStakeholder = {
                id: null,
                focalPoints: [],
                update: false,
                active: true
            };
        },
        closeStakeholderEditModal() {
            this.stakeholderDialog = false;
            this.areaDetail = this.areaDetailBackup;
        },
        closeModalInative() {
            this.stakeholderDialog = false;
            this.itemDialog.value = false;
        }
    },
    computed: {
        clonedAreaDetail() {
            return JSON.parse(JSON.stringify(this.stakeholder.data))
        }
    },
    watch: {
        atualPage(value) {
            this.payload['page'] = (value + 1);
            this.getAreas();
        },
        pageSize(value) {
            this.payload['pageSize'] = parseInt(value);
            this.payload['page'] = 1;
            this.getAreas();
        },
        clonedAreaDetail(newVal, oldVal) {
            let qtdActiveFocalPoints = 0;
            
            newVal.focalPoints.map(focalPoint => {
                if (focalPoint.active) {
                    qtdActiveFocalPoints += 1;
                }
            });

            const mirrorStakeholder = this.mirroAreaDatail.stakeholders_id.filter(stakeholder => {
                if (stakeholder.id === newVal.id) {
                    return stakeholder;
                }
            })[0];

            if (!this.isEmpty(oldVal)) {
                if (mirrorStakeholder.id === newVal.id) {
                    if (mirrorStakeholder.active === true && newVal.active === false && qtdActiveFocalPoints > 0) {
                        let dataStakeholder = {
                            data: newVal,
                            type: 'stakeholder',
                            value: true
                        };
                        this.openModalInative(dataStakeholder);
                    }
                    if (newVal.active && mirrorStakeholder.active) {
                        mirrorStakeholder.focalPoints.map(focalPoint => {
                            newVal.focalPoints.map(focalPointNew => {
                                if (focalPoint.id === focalPointNew.id) {
                                    if (focalPoint.active === true && focalPointNew.active === false && qtdActiveFocalPoints !== 0) {
                                        let data = {
                                            data: newVal,
                                            type: 'focalPoint',
                                            value: true
                                        };
                                        this.openModalInative(data, focalPoint.id);
                                    }
                                }
                            });
                        });
                    }
                }
            }
        }
    },
    created() {
        this.getOptions();
        this.init();
    }
}
<template>
  <v-container :fluid="true">
    <vv-loading :is-loading="isLoading"></vv-loading>

    <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />

    <vv-filter :filter-name="filterName" :fields="fields" @filter="filter" @reset="reset" export-api="/v2/report/delivery-formalization" :notification-export="true"></vv-filter>

    <div class="delivery-formalization-vision-card">
      <h2 class="title-form">Listagem de Formalização de Entregas</h2>

      <v-data-table
        sort-by="project_id"
        :headers="headers"
        :items="deliveries"
        :search="search"
        :mobile-breakpoint="0"
        :header-props="headerProps"
        :footer-props="footerProps"
        class="cursor icon-down"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        @click:row="rowClicked"
      >
        <template v-slot:item.epicName="{ item }">
          <span class="readmore" :title="item.epicName">{{ item.epicName }}</span>
        </template>
        <template v-slot:item.deliveryFormalizationComment="{ item }">
          <span class="readmore" :title="item.deliveryFormalizationComment">{{ item.deliveryFormalizationComment }}</span>
        </template>
        <template v-slot:item.vsmName="{ item }">
          <span class="readmore" :title="item.vsmName">{{ item.vsmName }}</span>
        </template>
        <template v-slot:item.vsmWorkflow="{ item }">
          <span class="readmore" :title="item.vsmWorkflow">{{ item.vsmWorkflow }}</span>
        </template>
        <template v-slot:item.focalPointResponsibleAreaName="{ item }">
          <span class="readmore" :title="item.focalPointResponsibleAreaName">{{ item.focalPointResponsibleAreaName }}</span>
        </template>
        <template v-slot:item.focalPointResponsibleAreaWorkflow="{ item }">
          <span class="readmore" :title="item.focalPointResponsibleAreaWorkflow">{{ item.focalPointResponsibleAreaWorkflow }}</span>
        </template>
        <template v-slot:item.focalPointDemandingAreaName="{ item }">
          <span class="readmore" :title="item.focalPointDemandingAreaName">{{ item.focalPointDemandingAreaName }}</span>
        </template>
        <template v-slot:item.focalPointDemandingAreaWorkflow="{ item }">
          <span class="readmore" :title="item.focalPointDemandingAreaWorkflow">{{ item.focalPointDemandingAreaWorkflow }}</span>
        </template>

        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop}} de {{ props.itemsLength }}
        </template>

        <template v-slot:item.evidenceFile="{ item }">
          {{ item.evidenceFile ? "Sim" : "Não"}}
        </template>

        <template v-slot:no-data>
          <v-btn
              class="ma-2"
              outlined
              color="primary"
              :loading="isLoading"
              @click="initialize"
          >
            Recarregar
          </v-btn>
        </template>

      </v-data-table>
    </div>

    <v-dialog v-model="dialogMessage" hide-overlay persistent width="500">
      <v-alert dense :type="typeMessage" style="margin: 0">
        <span v-html="textMessage"></span>
      </v-alert>
    </v-dialog>

    <v-dialog v-model="modalEvidence" width="500" persistent>
      <v-card>
        <v-card-title> Opções da Formalização </v-card-title>

        <v-card-text class="mt-3">
          <v-container>
            <h2>Download das Evidências</h2>
            <v-row  v-for="(item, index) in evidenceList" :key="index">
              <v-col cols="12">
                <span>{{ item.evidence_name }}</span><br/>
                <v-btn class="mt-1" color="success" target="_blank" @click="downloadEvidence(item)">Baixar Evidência</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="goToProject"> Acessar Registro </v-btn>
          <v-btn color="primary" @click="closeModal"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import VvFilter from "@/components/VvFilter";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

export default {
  name: "DeliveryFormalization",
  components: {
    VvBreadcrumbs,
    VvLoading,
    VvFilter,
  },
  data: () => ({
    payload: {},
    isLoading: true,
    fields: [],
    modalEvidence: false,
    evidenceList: [],
    itemSelected: {},
    headers: [
      {
        text: "ID Iniciativa",
        value: "projectId",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "ID Épico Jira",
        value: "epicIdJira",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "ID Formalização",
        value: "deliveryFormalizationId",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Entregável",
        value: "epicName",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Comentário sobre a entrega",
        value: "deliveryFormalizationComment",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Status Workflow",
        value: "statusName",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Área Responsável",
        value: "projectAreaName",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "VSM",
        value: "vsmName",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Status VSM",
        value: "vsmWorkflow",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Ponto Focal da Área de Negócio",
        value: "focalPointResponsibleAreaName",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Status Ponto Focal da Área de Negócio",
        value: "focalPointResponsibleAreaWorkflow",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Ponto Focal da Área Demandante",
        value: "focalPointDemandingAreaName",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Status Ponto Focal da Área Demandante",
        value: "focalPointDemandingAreaWorkflow",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "Evidência",
        value: "evidenceFile",
        align: "center",
        class: "cabecalho"
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        width: "10%",
        align: "center",
        class: "acao"
      },
    ],
    deliveries: [],
    search: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    footerProps: {
      "items-per-page-text": "Linhas por página",
      "items-per-page-options": [5, 10, 15, 30, 50, 100],
    },
    options: {
      itemsPerPage: 15,
    },
    serverItemsLength: 0,
    dialogMessage: false,
    typeMessage: "success",
    textMessage: "",
    evidenceFile: [
      { id: 0, name: 'Não' },
      { id: 1, name: 'Sim' }
    ],
    breadcrumb: [
      {
        text: 'Listagens',
        disabled: true,
      },
      {
        text: 'Formalização de Entregas',
        disabled: false,
      },
    ],
    filterName: 'deliveryFormalization'
  }),

  created() {
    this.mountValuesFilterFields();
  },

  watch: {
    dialogMessage(val) {
      if (!val) return;
      setTimeout(
        () => (
          (this.dialogMessage = false),
          (this.textMessage = ""),
          (this.typeMessage = "success")
        ),
        3000
      );
    },

    options: {
      handler() {
        this.fetchDatatable();
      },
      deep: true,
    },
  },

  methods: {
    initialize() {
      this.isLoading = true;
      if (this.$store.getters.userToken) {
        this.axios
          .get("v2/delivery-formalization", {
            params: this.payload,
          })
          .then((response) => {
            this.deliveries = response.data.data;
            this.serverItemsLength = response.data.total;
          })
          .catch((error) => {
            let messagesError = "<ul>";
            Object.keys(error.response.data).forEach((key) => {
              messagesError += `<li>${error.response.data[key]} </li>`;
            });
            messagesError += "</ul>";

            this.dialogMessage = true;
            this.textMessage = messagesError;
            this.typeMessage = "error";
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        setTimeout(() => {
          this.initialize();
        }, 100);
      }
    },

    filter(payload) {
      this.payload = payload;
      this.setPagination();
      this.initialize();
    },

    reset() {
      this.payload = {};
      this.setPagination();
      this.initialize();
    },

    editItem(item) {
      console.log(item);
      // let url = `/projeto/${item.project_id}`;
      // if (item.workflow_id != 1) {
      //   url += "/epicos";
      // }
      // this.$router.push(url);
    },

    showEvidence(item) {
      // console.log(item);

      this.axios
        .get(`delivery/${item.deliveryFormalizationId}/evidences`)
        .then((response) => {
          this.evidenceList = response.data;
        })
        .catch((error) => {
          this.dialogMessage = true;
          this.textMessage = error;
          this.typeMessage = "error";
        });
      setTimeout(() => {
        this.modalEvidence = true;
      }, 100);
    },

    downloadEvidence(item) {
      this.isLoading = true;
      this.axios
        .get(`/delivery/evidence/${item.id}`)
        .then((response) => {
          this.createAndDownloadBlobFile(this.base64ToArrayBuffer(response.data.file), response.data.filename, response.data.extension);
          this.isLoading = false;
        })
        .catch((error) => {
          this.modalEvidence = false;
          this.dialogMessage = true;
          console.log(error);
          this.textMessage = 'Ocorreu um erro inesperado';
          this.typeMessage = "error";
          this.isLoading = false;
        });
    },

    mountValuesFilterFields() {
      this.axios
        .get("v2/options/delivery-formalization")
        .then((response) => {
          
          let response_data = response.data.options;
          this.responsibleAreas = response_data.areas;
          this.workflowStatus = response_data.workflows;
          this.stakeholders = response_data.usersStakeholders;
          this.transformationManager = response_data.usersTransformationManager;
          this.focalPoint = response_data.usersFocalPoint;
          this.focalPointResponsibleAreaName = response_data.usersFocalPoint;
          this.focalPointResponsibleAreaWorkflow = response_data.statusWorkflow;
          this.focalPointDemandingAreaName = response_data.usersFocalPoint;
          this.focalPointDemandingAreaWorkflow = response_data.statusWorkflow;
          this.vsm = response_data.usersVsm;
          this.status = response_data.statusWorkflow;
          this.startNoApply(this.responsibleAreas, 1);
          this.startNoApply(this.status, -1);
        
          this.createFilterFields();
        })
        .catch((error) => {
          this.dialogMessage = true;
          this.textMessage = error;
          this.typeMessage = "error";
        });
    },

    createFilterFields() {
      this.fields = [
        {
          type: "number",
          label: "ID Iniciativa",
          sm: "2", id: "projectId",
          name: "projectId",
          payloadBackend: "projectId[eq]",
          items: []
        },
        {
          type: "text",
          label: "ID Épico Jira",
          sm: "4",
          id: "epicIdJira",
          name: "epicIdJira",
          payloadBackend: "epicIdJira[like]",
          items: []
        },
        {
          type: "number",
          label: "ID Formalização",
          sm: "2",
          id: "deliveryFormalizationId",
          name: "deliveryFormalizationId",
          payloadBackend: "deliveryFormalizationId[eq]",
          items: []
        },
        {
          type: "text",
          label: "Entregável",
          sm: "4",
          id: "epicName",
          name: "epicName",
          payloadBackend: "epicName[like]",
          items: []
        },
        {
          type: "text",
          label: "Comentário sobre a entrega",
          sm: "6",
          id: "deliveryFormalizationComment",
          name: "deliveryFormalizationComment",
          payloadBackend: "deliveryFormalizationComment[like]",
          items: []
        },
        {
          type: "multiautocomplete",
          label: "Status Workflow",
          sm: "3",
          id: "statusName",
          name: "statusName",
          payloadBackend: "statusId[or]",
          items: this.workflowStatus
        },
        {
          type: "multiautocomplete",
          label: "Área Responsável",
          sm: "3",
          id: "areaName",
          name: "areaName",
          payloadBackend: "projectAreaId[or]",
          items: this.responsibleAreas
        },
        {
          type: "multiautocomplete",
          label: "VSM",
          sm: "4",
          id: "vsmName",
          name: "vsmName",
          payloadBackend: "vsmId",
          items: this.vsm
        },
        {
          type: "multiautocomplete",
          label: "Status VSM",
          sm: "2",
          id: "vsmWorkflow",
          name: "vsmWorkflow",
          payloadBackend: "vsmWorkflow",
          items: this.status
        },
        {
          type: "multiautocomplete",
          label: "Ponto Focal da Área de Negócio",
          sm: "4",
          id: "focalPointResponsibleAreaName",
          name: "focalPointResponsibleAreaName",
          payloadBackend: "focalPointResponsibleArea",
          items: this.focalPointResponsibleAreaName
        },
        {
          type: "multiautocomplete",
          label: "Status Ponto Focal da Área de Negócio",
          sm: "2",
          id: "focalPointResponsibleAreaWorkflow",
          name: "focalPointResponsibleAreaWorkflow",
          payloadBackend: "focalPointResponsibleAreaWorkflow",
          items: this.focalPointResponsibleAreaWorkflow
        },
        {
          type: "multiautocomplete",
          label: "Ponto Focal da Área Demandante",
          sm: "4",
          id: "focalPointDemandingAreaName",
          name: "focalPointDemandingAreaName",
          payloadBackend: "focalPointDemandingArea",
          items: this.focalPointDemandingAreaName
        },
        {
          type: "multiautocomplete",
          label: "Status Ponto Focal da Área Demandante",
          sm: "2",
          id: "focalPointDemandingAreaWorkflow",
          name: "focalPointDemandingAreaWorkflow",
          payloadBackend: "focalPointDemandingAreaWorkflow",
          items: this.focalPointDemandingAreaWorkflow
        },
        {
          type: "autocomplete",
          label: "Evidência",
          sm: "2",
          id: "evidenceFile",
          name: "evidenceFile",
          payloadBackend: "evidenceFile[eq]",
          items: this.evidenceFile
        },
      ];

      let fieldsStore = this.getGlobalFilters(this.filterName);
      if (fieldsStore !== undefined) {
        this.fields = fieldsStore;
        let payload = {};

        this.fields.map(field => {
          if (field.value) {
            payload[field.payloadBackend] = field.value
          }
        });
        if (Object.keys(payload).length) {
          payload.page = 1;
          this.filter(payload);
        }
      }
    },

    fetchDatatable: async function () {
      this.isLoading = true;
      this.setPagination();
      this.initialize();
    },

    setPagination() {
      this.payload["page"] = this.options.page;
      this.payload["page_size"] = this.options.itemsPerPage;
      this.payload["sortBy"] = this.options.sortBy[0];
      this.payload["sortDesc"] = 0;
      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.payload["sortDesc"] = this.options.sortDesc[0] ? 1 : 0;
      }
    },

    closeModal() {
      this.modalEvidence = false;
      this.evidenceList = [];
    },

    rowClicked(project) {
      if (project.evidenceFile) {
        this.itemSelected = project;
        this.showEvidence(project);
      } else {
        this.$router.push(`/projeto/${project.projectId}/formalizacao/${project.deliveryFormalizationId}`);
      }
    },
    goToProject() {
      this.$router.push(`/projeto/${this.itemSelected.projectId}/formalizacao/${this.itemSelected.deliveryFormalizationId}`);
    }
  },

}
</script>

<style lang="scss" scoped>
.delivery-formalization-vision-card {
  background-color: white;
  padding: 24px 30px;
  border-radius: 6px;
  margin-top: 20px;

  h2 {
    color: #5d5d5d;
    font-size: 18px;
    font-weight: bold;
  }

  .title_colunm {
    font-weight: bold !important;
    text-align: center !important;
  }

  td span.readmore {
    font-size: 14px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
  }
}
</style>
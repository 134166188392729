import axios from "@/plugins/axios";

export default function HeaderServiceFactory() {
  return {
    latestNotifications() {
      return axios
        .get(`v2/notifications/unread`)
        .then(res => res.data)
    },
    loadNotifications() {
      return axios
        .get(`v2/notifications?pageSize=10&sortDesc=1`)
        .then(res => res.data)
    },
    changeNotificationStatus(payload) {
      return axios
        .put(`v2/notifications/status`, payload)
        .then(res => res.data)
    },
    deleteNotifications(payload) {
      return axios
        .put(`v2/notifications`, payload)
        .then(res => res.data)
    }
  }
}
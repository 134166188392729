import axios from "@/plugins/axios";

export default function inceptionServiceFactory() {
  return {
    loadOptions() {
      return axios
        .get(`/v2/options/inception`)
        .then((res) => res.data);
    },
    loadInception(projectId) {
      return axios
        .get(`/v2/project/${projectId}/inception`)
        .then((res) => res.data)
    },
    editInception(projectId, formData) {
      return axios
        .post(
          `/v2/project/${projectId}/inception?_method=PUT`, 
          formData, 
          { 
            headers: {
              "Content-Type": "multipart/form-data",
            }
          }
        )
        .then((res) => res)
    },
    downloadEvidence(projectId) {
      return axios
        .get(`v2/project/${projectId}/inception/bc-file`)
        .then((res) => res)
    },
    loadSubProject(projectId, activeSubprojects = true) {
      return axios
        .get(`v2/project/${projectId}/inception/subprojects?activeSubprojects=${activeSubprojects ? 1 : 0}`)
        .then((res) => res)
    },
    loadEpicsList(projectId) {
      return axios
        .get(`v2/project/${projectId}/epic/summary?activeEpics=1`)
        .then(res => res);
    },
    linkEpicToProject(projectId, subProject, epicId) {
      return axios
        .put(`v2/project/${projectId}/subproject/${subProject}/link/epic/${epicId}`)
        .then(res => res);
    },
    getInceptionPlannings(projectId, subprojectId) {
      return axios
        .get(`v2/project/${projectId}/inception/indicator/${subprojectId}/plannings`)
        .then(res => res);
    },
    editInceptionPlannings(projectId, subprojectId, payload) {
      return axios
        .put(`v2/project/${projectId}/inception/indicator/${subprojectId}/plannings`, payload)
        .then((res) => res)
    }
  }
}
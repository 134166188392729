var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',[_vm._v(_vm._s(_vm.label))]),_c('v-autocomplete',_vm._g(_vm._b({attrs:{"value":_vm.value,"items":_vm.toDisableAll ? _vm.list : _vm.ordered,"item-text":_vm.itemText,"item-value":_vm.itemValue,"disabled":_vm.disabled,"multiple":"","append-icon":"mdi-chevron-down","no-data-text":_vm.noDataText},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
var index = ref.index;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":!_vm.disabled,"disabled":_vm.disabled},on:{"click:close":function($event){return _vm.remove(index)}}},'v-chip',attrs,false),[(_vm.$scopedSlots.chip)?_vm._t("chip",null,null,item):_c('span',[_vm._v(_vm._s(item[_vm.itemText]))])],2)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",class:{ divider: _vm.$scopedSlots.cell },staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.itemSelected(item)}}},[_c('div',{staticClass:"ml-4 d-flex itens-center"},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.value.includes(item[_vm.itemValue]) ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-list-item-content',{staticStyle:{"max-width":"480px"}},[(_vm.$scopedSlots.cell)?_vm._t("cell",null,null,item):_c('span',[_vm._v(_vm._s(item[_vm.itemText]))])],2)],1)]}}],null,true)},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }
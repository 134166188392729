import dashboardService from "../../service/dashboardService.js";
import ModalConfirmExport from "@/components/reports/ModalConfirmExport";
import filterServiceFactory from "@/services/filterService";
import html2canvas from 'html2canvas';

const FilterService = filterServiceFactory();
const dashboardServiceFactory = new dashboardService();

export default {
    name: "Dashboard",
    components: {
        ModalConfirmExport
    },
    data: () => ({
        dialogExport: false,
        today: '',
        filterPanel: -1,
        totalDelivery: '',
        errorList: {},
        pizzaChartOptions: {
            labels: [],
            series: [],
            legend: {
                position: 'left'
            },
            colors: [
                '#DBDCDC',
                '#699F10',
                '#FFDC42',
                '#FF502D',
                '#35DAFF',
                '#929497',
                '#5D4EE8'
            ],
        },
        stackedBarKey:0,
        stackedBarOptions: {
            colors: [
                '#DBDCDC',
                '#699F10',
                '#FFDC42',
                '#FF502D',
                '#35DAFF',
                '#929497',
                '#5D4EE8'
            ],
            series: [],
            chart: {
                toolbar: {
                    show: false
                },
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            xaxis: {
                categories: [],
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        let pos = val > 1 ? ' entregáveis':' entregável'
                        return val + pos
                    }
                }
            },
            fill: {
                opacity: 1

            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        },
        form: {
            yearId: null,
            quarterId: [],
            epicTypeId: [],
            vsmId: [],
            journeyId: [],
            tribeId: []
        },
        resultGraphicRequest: {
            yearId: null,
            quarterId: [],
            epicTypeId: [],
            vsmId: [],
            journeyId: [],
            tribeId: []
        },
        quarterId: [],
        options: {
            years: [],
            quarters: [],
            epicTypes: [],
            vsms: [],
            journeys: [],
            tribes: []
        },
        items: [
            "Dashboard",
            "Integração Power BI"
        ],
        breadcrumb: [
            {
                text: 'Dashboard',
                disabled: false,
            },
        ],
    }),

    computed: {
        yearLabel() {
            let label = '';
            this.options.years.map(year => {
                if(year.id == this.resultGraphicRequest.yearId) {
                    label = year.name
                }
            })
            return label
        },

        quarterLabel() {
            let label = [];
            this.options.quarters.map(quarter => {
                if(this.resultGraphicRequest.quarterId.includes(quarter.id)) {
                    quarter.name.split("/")
                    label.push(quarter.name[0]+quarter.name[1])
                }
            })
            return label
        },

        epicTypesLabel() {
            let label = [];
            this.options.epicTypes.map(epicType => {
                if(this.resultGraphicRequest.epicTypeId.includes(epicType.id)) {
                    label.push(epicType.name)
                }
            })
            return label
        },

        vsmLabel() {
            let label = [];
            this.options.vsms.map(vsm => {
                if(this.resultGraphicRequest.vsmId.includes(vsm.id)) {
                    label.push(vsm.name)
                }
            })
            return label
        },

        journeyLabel() {
            let label = [];
            this.options.journeys.map(journey => {
                if(this.resultGraphicRequest.journeyId.includes(journey.id)) {
                    label.push(journey.name)
                }
            })
            return label
        },

        tribeLabel() {
            let label = [];
            this.options.tribes.map(tribe => {
                if (this.resultGraphicRequest.tribeId.includes(tribe.id)) {
                    label.push(tribe.name)
                }
            })
            return label
        },
    },

    methods: {
        clearFilter() {
            this.options.years.map(year => {
                let currentYear = new Date().getFullYear();
                if (year.name == currentYear) {
                    this.form.yearId = year.id
                }
            })
            this.form.quarterId = [];
            this.form.epicTypeId = [];
            this.form.vsmId = [];
            this.form.journeyId = [];
            this.form.tribeId = [];
            this.quarterId = [];

            this.getGraphicNewRequest()
        },

        async initializeDataFormRequest() {
            let response = {};

            try {
                response = await dashboardServiceFactory.initializeDataForm();
            } catch (error) {
                this.$showError(error);
            }

            this.options = response.options
            this.options.mockedQuarters = [
                {
                    id: 1,
                    name: 'Q1'
                },
                {
                    id: 2,
                    name: 'Q2'
                },
                {
                    id: 3,
                    name: 'Q3'
                },
                {
                    id: 4,
                    name: 'Q4'
                }
            ];

            this.options.years.map(year => {
                let currentYear = new Date().getFullYear();
                if (year.name == currentYear) {
                    this.form.yearId = year.id
                }
            })
            this.getGraphicRequest()
        },

        getGraphicNewRequest() {
            this.pizzaChartOptions.labels = [];
            this.pizzaChartOptions.series = [];
            this.stackedBarOptions.series = [];
            this.stackedBarOptions.xaxis.categories = [];
            this.getGraphicRequest()
        },

        async getGraphicRequest() {
            let response = {};

            this.resultGraphicRequest = {
                yearId: null,
                quarterId: [],
                epicTypeId: [],
                vsmId: [],
                journeyId: [],
                tribeId: []
            };

            try {
                this.$showLoading();
                response = await dashboardServiceFactory.getGraphic(this.form);
                this.totalDelivery = response.simplePie.total
                this.resultGraphicRequest = JSON.parse(JSON.stringify(this.form));
            } catch (error) {
                this.setErrorList(error);
            } finally {
                this.$hideLoading();
            }
            response.simplePie.labels.map(label => {
                this.pizzaChartOptions.labels.push(label)
            })
            response.simplePie.series.map(serie => {
                this.pizzaChartOptions.series.push(serie)
            })
            response.stackedBar.series.map(serie => {
                this.stackedBarOptions.series.push(serie)
            })
            response.stackedBar.categories.map( category => {
                this.stackedBarOptions.xaxis.categories.push(category)
            })
            this.stackedBarKey++;
        },

        setErrorList(error) {
            const {
                response: { data: errors },
            } = error;
            this.errorList = errors;
        },

        openModal() {
            this.modalOpen = true;
        },

        async exportNotification() {
            try {
                await FilterService.exportNotification(this.form, 'v2/dashboard/report');
                this.dialogExport = true;
            } catch (error) {
                this.$showError(error);
            }
        },

        async exportToImage() {
            try {
                this.$showLoading();
                const area = this.$refs.areaToExport
                const canvas = await html2canvas(area)
                const image = canvas.toDataURL('image/png')
                const downloadLink = document.createElement('a')

                downloadLink.href = image;
                downloadLink.download = 'visao-de-entregas.png'
                downloadLink.click();
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },

        getError(param) {
            return this.errorList[param];
        },

        clearError(param) {
            delete this.errorList[param];
        },

        setQuarterYearId() {
            this.form.quarterId = [];
            this.options.quarters.map(quarter => {
                this.options.mockedQuarters.map(mockedQuarter => {
                    this.quarterId.map(id => {
                        if (id === mockedQuarter.id) {
                            this.options.years.map(year => {
                                if (this.form.yearId === year.id) {
                                    let quarterYearName = mockedQuarter.name + '/' + year.name
                                    if (quarterYearName == quarter.name) {
                                        if (!this.form.quarterId.includes(quarter.id)) {
                                            this.form.quarterId.push(quarter.id)
                                        }
                                    }
                                }
                            })
                        }
                    })
                })
            })
        }
    },

    mounted: function () {
        this.initializeDataFormRequest()
        let dataAtual = new Date();
        let dia = ("0" + dataAtual.getDate()).slice(-2);
        let mes = ("0" + (dataAtual.getMonth() + 1)).slice(-2);
        let ano = dataAtual.getFullYear();
        let horas = dataAtual.getHours();
        let minutos = dataAtual.getMinutes();
        this.today = dia +'/'+mes+'/'+ano+ ' às ' + horas+':'+ minutos
    }
}
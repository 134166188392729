<template>
  <div id="IndicatorsTable">
    <h3 class="title-form">• Tabela de indicadores</h3>
    <v-data-table
      :headers="headers"
      :items="value"
      :mobile-breakpoint="0"
      :no-data-text="'Não há indicadores cadastrados'"
      :itemsPerPage="value.length"
      hide-default-footer
      item-key="id"
      dense
      class="data-table-indicators-table"
    >
      <template v-slot:item.acoes="{ item }">
        <div class="d-flex">
          <template v-if="!item.new">
            <v-btn
              icon
              color="#FF502D"
              v-if="planningsErrors[value.indexOf(item)]"
              @click="openDriverModal(value.indexOf(item))"
            >
              <v-icon size="20">mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              icon
              color="#6D6E70"
              v-else
              @click="openDriverModal(value.indexOf(item))"
            >
              <v-icon size="20">mdi-magnify</v-icon>
            </v-btn>
          </template>
          <v-btn
            icon
            :disabled="canEditInception"
            color="#FF502D"
            @click="deleteDriver(value.indexOf(item))"
            v-if="item.new"
          >
            <v-icon size="20">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.driversCategoryId="{ item }">
        <v-select
          v-model="item.driversCategoryId"
          :items="indicatorsOptions"
          item-value="id"
          item-text="name"
          no-data-text="Selecione um KPI estratégico na tabela KPIs estratégicos"
          :disabled="canEditInception"
          :error-messages="getErrorInception('projectIndicators.'+value.indexOf(item)+'.driversCategoryId')"
          @input="clearErrorInception('projectIndicators.'+value.indexOf(item)+'.driversCategoryId')"
        ></v-select>
      </template>

      <template v-slot:item.kpi="{ item }">
        <v-text-field
          v-model="item.kpi"
          :disabled="canEditInception"
          :error-messages="getErrorInception('projectIndicators.'+value.indexOf(item)+'.kpi')"
          @input="clearErrorInception('projectIndicators.'+value.indexOf(item)+'.kpi')"
        ></v-text-field>
      </template>

      <template v-slot:item.unitMeasure="{ item }">
        <v-text-field
          v-model="item.unitMeasure"
          :disabled="canEditInception"
          :error-messages="getErrorInception('projectIndicators.'+value.indexOf(item)+'.unitMeasure')"
          @input="clearErrorInception('projectIndicators.'+value.indexOf(item)+'.unitMeasure')"
        ></v-text-field>
      </template>

      <template v-slot:item.sourceInformation="{ item }">
        <v-text-field
          v-model="item.sourceInformation"
          :disabled="canEditInception"
          :error-messages="getErrorInception('projectIndicators.'+value.indexOf(item)+'.sourceInformation')"
          @input="clearErrorInception('projectIndicators.'+value.indexOf(item)+'.sourceInformation')"
        ></v-text-field>
      </template>

      <template v-slot:item.projectIndicatorsFrequencyId="{ item }">
        <v-select
          v-model="item.projectIndicatorsFrequencyId"
          :items="options.indicatorFrequency"
          item-value="id"
          item-text="name"
          :disabled="canEditInception"
          :error-messages="getErrorInception('projectIndicators.'+value.indexOf(item)+'.projectIndicatorsFrequencyId')"
          @input="clearErrorInception('projectIndicators.'+value.indexOf(item)+'.projectIndicatorsFrequencyId')"
        ></v-select>
      </template>

      <template v-slot:item.responsibleUpdating="{ item }">
        <v-text-field
          v-model="item.responsibleUpdating"
          :disabled="canEditInception"
          :error-messages="getErrorInception('projectIndicators.'+value.indexOf(item)+'.responsibleUpdating')"
          @input="clearErrorInception('projectIndicators.'+value.indexOf(item)+'.responsibleUpdating')"
        ></v-text-field>
      </template>      
    </v-data-table>

    <modal-indicators-plannings
      :dialog="dialog"
      :canEditIndicators="canEditIndicators"
      :canEditInception="canEditInception"
      :data="indicatorsDataForModal"
      :plannings="plannings"
      @closeModal="closeModal" />
    <v-btn
      :disabled="canEditInception"
      plain
      large
      color="#5A6BFF"
      class="mt-1 px-0"
      @click="addDriver()">
      + Novo KPI
    </v-btn>
  </div>
</template>

<script>
import ErrorMessageHandler from '../mixins/ErrorMessageHandler';
import ModalIndicatorsPlannings from './ModalIndicatorsPlannings.vue';
import inceptionServiceFactory from '../services/inceptionService.js';

const inceptionService = inceptionServiceFactory();

export default {
  components: { ModalIndicatorsPlannings },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    },
    inceptionDrivers: {
      type: Array,
      default: () => []
    },
    errorListInception: {
      type: Object,
      default: () => ({})
    },
    canEditIndicators: {
      type: Boolean,
      default: false
    },
    canEditInception: {
      type: Boolean,
      default: false
    },
  },
  mixins: [ErrorMessageHandler],
  data() {
    return {
      dialog: false,
      dialogContentIndex: 0,
      headers: [
        {
          text: "Ações",
          align: "start",
          value: "acoes",
          width: "108px"
        },
        {
          text: "Direcionador",
          align: "start",
          value: "driversCategoryId",
          width: "220px"
        },
        {
          text: "KPI",
          align: "start",
          value: "kpi",
          width: "120px"
        },
        {
          text: "Unidade de medida",
          align: "start",
          value: "unitMeasure",
          width: "192px"
        },
        {
          text: "Fonte de informação (sistema)",
          align: "start",
          value: "sourceInformation",
          width: "261px"
        },
        {
          text: "Frequência de atualização",
          align: "start",
          value: "projectIndicatorsFrequencyId",
          width: "235px"
        },
        {
          text: "Responsável pela atualização",
          align: "start",
          value: "responsibleUpdating",
          width: "243px"
        },
        {
          text: "",
          value: "expandButton"
        }
      ],
      planningsErrors: [],
      plannings: [],
      indicatorsDataForModal: {}
    }
  },
  watch: {
    errorListInception: {
      handler(val){
        Object.keys(val).forEach((index) => {
          let brokenIndex = index.split('.');
          if (brokenIndex[0] === "projectIndicators" && brokenIndex[2] === "plannings" && brokenIndex[4] === "planned") {
          this.planningsErrors[parseInt(brokenIndex[1])] = true
          }
        });
      },
      deep: true
    },
  },
  computed: {
    indicatorsOptions() {
      let indicators = [];
      if (typeof this.options.inceptionDrivers === 'object') {
        Object.keys(this.options.inceptionDrivers).forEach((index) => {
          let isAnEligibleIndicator = false;
          Object.keys(this.options.inceptionDrivers[index].inceptionDrivers).forEach((indexChildren) => {
            if (this.inceptionDrivers.indexOf(this.options.inceptionDrivers[index].inceptionDrivers[indexChildren].id) > -1) {
              isAnEligibleIndicator = true;
            }
          });
          if (isAnEligibleIndicator) {
            indicators.push({
              id: this.options.inceptionDrivers[index].id,
              name: this.options.inceptionDrivers[index].name
            });
          }
        });
      }
      let optionsIndicators = [];
      optionsIndicators.push({id: null, name: 'Não aplicável'});
      for (let indexIndicators = 0; indexIndicators < indicators.length; indexIndicators++) {
        optionsIndicators.push(indicators[indexIndicators]);
      }
      return optionsIndicators;
    },
  },
  methods: {
    addDriver() {
      this.value.push(
        {
          driversCategoryId: null,
          kpi: null,
          unitMeasure: null,
          sourceInformation: null,
          projectIndicatorsFrequencyId: null,
          responsibleUpdating: null,
          new: true,
          plannings: [],
          enableFrequency: true
        }
      );
    },
    async openDriverModal(index) {
      try {
        this.$showLoading();
        this.indicatorsDataForModal = {
          id: this.value[index].id,
          driversCategoryName: this.driversCategoryName(this.value[index].driversCategoryId),
          kpi: this.value[index].kpi,
          unitMeasure: this.value[index].unitMeasure,
          sourceInformation: this.value[index].sourceInformation,
          responsibleUpdating: this.value[index].responsibleUpdating,
          projectIndicatorsFrequencyName: this.projectIndicatorsFrequencyName(this.value[index].projectIndicatorsFrequencyId),
          projectIndicatorsFrequencyId: this.value[index].projectIndicatorsFrequencyId
        }
        const result = await inceptionService.getInceptionPlannings(this.$route.params.id, this.value[index].id);
        this.plannings = result.data.plannings;
        this.dialog = true;
      } catch (error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    },
    closeModal() {
      this.dialog = false;
    },
    deleteDriver(index) {
      this.value.splice(index, 1);
    },
    estrategicKpiOptions(parameterIndex) {
      let kpi = [];
      if (typeof this.options.inceptionDrivers === 'object') {
        Object.keys(this.options.inceptionDrivers).forEach((index) => {
          if (this.value[parameterIndex].driversCategoryId == this.options.inceptionDrivers[index].id) {
            Object.keys(this.options.inceptionDrivers[index].inceptionDrivers).forEach((indexChildren) => {
              if (this.inceptionDrivers.indexOf(this.options.inceptionDrivers[index].inceptionDrivers[indexChildren].id) > -1) {
                kpi.push({
                  id: this.options.inceptionDrivers[index].inceptionDrivers[indexChildren].id,
                  name: this.options.inceptionDrivers[index].inceptionDrivers[indexChildren].name
                });
              }
            });
          }
        });
      }
      return kpi;
    },
    driversCategoryName(driversCategoryId) {
      if (driversCategoryId !== null) {
        let list = this.options.inceptionDrivers.filter(function(item) {
          return (item.id == driversCategoryId);
        });
        return list[0].name;
      }
    },
    projectIndicatorsFrequencyName(projectIndicatorsFrequencyId) {
      if (projectIndicatorsFrequencyId !== null) {
        let list = this.options.indicatorFrequency.filter(function(item) {
          return (item.id == projectIndicatorsFrequencyId);
        });
        return list[0].name;
      }
    },
  }
}
</script>

<style lang="scss">
#IndicatorsTable {
  .data-table-indicators-table {
    &.v-data-table {
      .v-data-table__wrapper {
        table {
          thead {
            background-color: #f4f5f5;
            height: 34px;

            tr {
              th {
                span {
                  font-weight: 400;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                height: 83px;
                color: #6D6E70;
                font-weight: 500;
              }

              &.v-data-table__expanded__content {
                box-shadow: none;
                cursor: auto;

                td {
                  padding: 51px 0px;
                }
              }
            }
          }
        }
      }
    }
  }

  .title-form {
    font-weight: 600;
    font-size: 15px;
    color: #6D6E70;
    margin-bottom: 32px;
  }
}

.v-card {
  .modal-layout {
    padding: 24px 32px;

    .modal-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;

      h3 {
        font-weight: 500;
        font-size: 18px;
        color: #5D5D5D;
        margin-top: -6px;
      }

      button {
        margin-top: -9px;
      }
    }

    .modal-details {
      .modal-detail {
        margin-bottom: 24px;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          font-size: 14px;
          color: #6D6E70;
          margin-bottom: 0px;
          width: 90%;

          &:last-child {
            font-weight: normal;
            color: #A1A1A1;
          }
        }
      }
    }

    .modal-form {
      margin-top: 36px;

      .modal-form-header {
        font-weight: 600;
        font-size: 16px;
        color: #6D6E70;
        border-bottom: 1px solid #DBDCDC;
        
        div {
          padding-bottom: 16px;

          &:last-child {
            border-left: 1px solid #DBDCDC;
            padding-left: 16px;
          }
        }
      }

      .modal-form-frequency {
        font-weight: normal;
        font-size: 14px;
        color: #A1A1A1;
        margin-top: 8px;
      }

      .modal-form-body {
        .modal-form-body-add-button {
          margin: 16px 0 0px;
          text-align: right;
        }

        .modal-form-body-period {
          max-height: 296px;
          overflow: auto;

          h3 {
            font-weight: 500;
            font-size: 14px;
            color: #A1A1A1;
            margin-bottom: 18px;
          }
        }
      }
    }
  }

  .modal-footer {
    border: 1px solid #DBDCDC;
    margin-top: 96px;
    padding: 16px 32px;
    text-align: right;

    button {
      &:last-child {
        color: #ffffff;
      }
    }
  }
}
</style>
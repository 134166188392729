export default {
    data() {
        return {
            environment: ''
        }
    },
    methods: {
        logoff() {
            this.$emit('logoff', true);
        }
    },
    mounted() {
        this.environment = this.getEnvEnvironment();
    }
}
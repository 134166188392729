export default {
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            tribe: {
                tribeId: null,
                journeyId: null,
                newJourneyId: null,
            },
            journeyCurrent: [],
            newJourney: []
        }
    },
    methods: {
        handleOptions(id) {
            this.newJourney = [];
            this.tribe.newJourneyId = null;

            this.options.tribes.map(tribe => {
                if (tribe.id === id) {
                    tribe.journey.map(journey => {
                        this.tribe.journeyId = journey.id
                        this.journeyCurrent = [journey];

                        let filterJourneys = this.options.journeys.filter(newjourney => newjourney.id !== journey.id)
                        this.newJourney = filterJourneys;
                    })
                }
            })
        },
        closeModal() {
            this.$emit("close");
        },
        save() {
            this.$emit('save', this.tribe)
        },
        clear() {
            this.tribe = {
                tribeId: null,
                journeyId: null,
                newJourneyId: null,
            }
        }
    },
}
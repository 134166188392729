import GLOBAL_CONSTANTS from "@/plugins/global-constants";

export default function epicRulesFactory() {
  return {
    checkIssueUnlock(statusId, epicNew = false) {
      if (epicNew) {
        return;
      }

      if ([
        GLOBAL_CONSTANTS.CONST_STATUS.ATRASADO,
        GLOBAL_CONSTANTS.CONST_STATUS.ATENCAO,
        GLOBAL_CONSTANTS.CONST_STATUS.REPLANEJADO_ATENCAO,
        GLOBAL_CONSTANTS.CONST_STATUS.REPLANEJADO_ATRASADO
      ].includes(statusId)) {
        return true;
      }
      return false;
    },
    checkHasProblem(statusId) {
      const { ATRASADO, ATENCAO, REPLANEJADO_ATENCAO, REPLANEJADO_ATRASADO } = GLOBAL_CONSTANTS.CONST_STATUS;
      return [ ATRASADO, ATENCAO, REPLANEJADO_ATENCAO, REPLANEJADO_ATRASADO ].includes(statusId);
    },
    statusOnCreate(statusId) {
      const { 
        ATRASADO, 
        ATENCAO, 
        CANCELADO, 
        REPLANEJADO_ATENCAO, 
        REPLANEJADO_ATRASADO, 
        REPLANEJADO_PRAZO, 
        REPLANEJADO_FORMALIZADO_NEGOCIO 
      } = GLOBAL_CONSTANTS.CONST_STATUS;
      return [
        ATRASADO, 
        ATENCAO, 
        CANCELADO, 
        REPLANEJADO_ATENCAO, 
        REPLANEJADO_ATRASADO, 
        REPLANEJADO_PRAZO, 
        REPLANEJADO_FORMALIZADO_NEGOCIO,
      ].includes(statusId);
    },
    checkDisableDeleteProblem(statusId, issues) {
      return this.checkHasProblem(statusId) && issues.length < 2;
    },
    lastIssue(issues, indexIssue) {
      let lastIssue = issues.length - 1;
      if (lastIssue === indexIssue) {
        return true;
      }
      return false;
    },
  }
}
<template>
  <div id="DataTableEpics">
    <v-data-table
        :headers="headers"
        :items="items"
        :single-expand="singleExpand"
        :disable-pagination="true"
        hide-default-footer
        item-key="id"
        show-expand
        :no-data-text="noDataText"
        class="data-table-epics elevation-1 grid-1"
        @item-expanded="loadDetails"
        :expanded.sync="expandedEpics"
        mobile-breakpoint="0"
        dense
    >
      <template v-slot:item.id="{ item: {id} }">
        <div
            class="slot-id"
            :class="{
            'error-border': errorIds.includes(id), 
            'change-border': editedList.includes(id),
          }">
          {{ id }}
        </div>
      </template>

      <template v-slot:item.name="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
          <span
              v-bind="attrs"
              v-on="on"
              class="text-truncate line-1"
          >{{ item.name }}</span>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.quarterYear="{ item }">
        <v-chip
            v-if="item.quarterYear"
            :input-value="item.quarterYear"
            label
            style="background: white;"
        >
          {{ item.quarterYear }}
        </v-chip>
      </template>

      <template v-slot:item.stageId="{ item }">
        <v-autocomplete
            :items="options.stages"
            v-model="item.stageId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            class="select-table"
            :class="customBackgroundStage(item.stageId)"
            :disabled="!item.permissions.canEditFieldsPermission"
            solo
            no-data-text="Nenhum estágio encontrado..."
            @change="hasChangeListInputs(item.id)"
            @input="setConditionalSelects(item.stageId, item.id)"
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content v-if="item.active">
                <v-list-item-title>
                  <v-row no-gutters align="center">
                    <span>{{ item.name }}</span>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

        </v-autocomplete>
      </template>

      <template v-slot:item.statusId="{ item }">
        <v-autocomplete
            :items="typeof options !== 'undefined' ? options.status: []"
            v-model="item.statusId"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            class="select-table"
            :class="customBackground(item.statusId)"
            :disabled="!item.permissions.canEditFieldsPermission || canEditStatus || item.statusId == 7 || item.statusId == 13 || item.statusId == 14"
            solo
            no-data-text="Nenhum status encontrado..."
            @change="hasChangeListInputs(item.id)"
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" >
              <v-list-item-content v-if="item.active">
                <v-list-item-title>
                  <v-row no-gutters align="center">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  :style="
                  'color: ' + statusAtualColor(item.id, true, 'fc', item.disable) +';'"
              >
                {{ item.name }}
              </v-chip>
          </template>
        </v-autocomplete>
      </template>

      <template v-slot:item.progress="{ item }">
        <v-progress-linear background-color="#dbdcdc" color="secondary" :value="item.progress"></v-progress-linear> {{ item.progress }}%
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon v-if="item.new" size="19" color="#FF502D" @click="modalRemoveNewEpic(item)">mdi-trash-can-outline</v-icon>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="epic-form px-2">
          <v-alert type="error" outlined class="pa-2 mt-6" icon="mdi-alert-outline" v-if="!validTree(item) && item.epicTypeId == CONST_TYPE_EPICS.TECNOLOGIA">
            Há inconsistências na árvore de tecnologia do macro tema, selecione novamente o VSM responsável pelo entregável.
          </v-alert>
          <v-alert type="error" outlined class="pa-2 mt-6" icon="mdi-alert-outline" v-if="!validTree(item) && item.epicTypeId == CONST_TYPE_EPICS.NEGOCIO">
            Há inconsistências na árvore de negócio do entregável.
          </v-alert>
          <div class="d-flex justify-content-between mt-4">
            <div class="d-flex epic-data mb-5">
              <div>
                <span>ID épico</span>
                <p>{{item.id}}</p>
              </div>
              <div>
                <span>Nome do épico</span>
                <p>{{item.name}}</p>
              </div>
            </div>
            <div class="d-flex justify-end" v-if="!item.new" style="align-items:center;">
              <actions-change-request
                :options="options"
                :item="item"
                :focal-points-tap="focalPointsTap"
              ></actions-change-request>
              <module-delivery-formalization
                  :options="options"
                  :item="item"
                  :focal-points-tap="focalPointsTap"
                  :project-area="project.projectArea[0] && project.projectArea[0].id"
                  :project-id="project.id"
              ></module-delivery-formalization>
            </div>
          </div>
          <vv-expand-inner title="Sobre o épico" :sessionWithError='hasErrorInSession(existSameErrorField, item.id, "about-form")' style="border-radius: 8px 8px 0px 0px" :value="true">
            <about-form
              :item="item"
              :options="options"
              :origin-item="getOriginEpic(item.id)"
              :project="project"
              :completed-epic="completedEpic"
              :error-list="errorList[indexOf(item)]"
              :permission-type-epic="permissionTypeEpic"
              @update:errorList="value => updateErrorList(value, item)"
              @hasChange="(change) => ($emit('hasChange', change))"
              @canEditStatus="canEditStatus = $event"
              class="mt-7"
              ref="aboutForm"
              ></about-form>
          </vv-expand-inner>
          <vv-expand-inner title="Problemas/Ponto de atenção" v-if="typeof item.issues !== 'undefined' && item.issues.length > 0" :sessionWithError='hasErrorInSession(existSameErrorField, item.id, "issues-form")'>
            <issues-form
              :item="item"
              :options="options"
              :completed-epic="completedEpic"
              :error-list="errorList[indexOf(item)]"
              @update:errorList="value => updateErrorList(value, item)"
              ref="issuesForm"
            ></issues-form>
          </vv-expand-inner>
          <vv-expand-inner title="Responsáveis e outros envolvidos" :sessionWithError='hasErrorInSession(existSameErrorField, item.id, "responsable-form")'>
            <responsable-form
              :item="item"
              :options="options"
              :completed-epic="completedEpic"
              :error-list="errorList[indexOf(item)]"
              @update:errorList="value => updateErrorList(value, item)"
              ref="responsableForm"
            ></responsable-form>
          </vv-expand-inner>
          <vv-expand-inner title="Datas de entrega" :sessionWithError='hasErrorInSession(existSameErrorField, item.id, "datas-form")'>
            <template v-slot:header>
              <v-chip
                v-if="item.quarterYear"
                :input-value="item.quarterYear"
                label
                class="ml-2 mt-n1"
                style="background: white;"
              >
                {{ item.quarterYear }}
              </v-chip>
            </template>
            <datas-form
              :item="item"
              :options="options"
              :completed-epic="completedEpic"
              :error-list="errorList[indexOf(item)]"
              @update:errorList="value => updateErrorList(value, item)"
              ref="datasForm"
            ></datas-form>
          </vv-expand-inner>
          <template>
            <vv-expand-inner title="Direcionadores e objetivos (Opcional)" class="mb-4" v-if="existDrivers">
              <guide-objectives-form
                  v-if="!item.new"
                  :value.sync="item"
                  :project-id="project.id"
                  :subproject-id="item.subprojectId"
                  :items="itemsDrivers"
                  :groups="groups"
                  :disabled="!item.permissions.canEditFieldsPermission"
              />
            </vv-expand-inner>
          </template>
        </td>
      </template>
    </v-data-table>
    <vv-confirmation-dialog
      :value="confirmationDialog.value"
      :title="confirmationDialog.title"
      :message="confirmationDialog.message"
      @confirmed="removeNewEpic()"
      @denied="confirmationDialog.value=false"
    ></vv-confirmation-dialog>
  </div>
</template>

<script>
import AboutForm from "@/domains/epic/components/AboutForm/AboutForm.vue";
import ResponsableForm from "./ResponsableForm";
import DatasForm from "./DatasForm";
import ModuleDeliveryFormalization from "@/domains/deliveryFormalization/components/ModuleDeliveryFormalization";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";
import ActionsChangeRequest from "@/domains/changeRequest/componets/ActionsChangeRequest";
import GuideObjectivesForm from "@/domains/epic/components/GuideObjectivesForm";
import VvExpandInner from "../../../components/common/VvExpandInner.vue";
import IssuesForm from "./IssuesForm.vue";
import epicServiceFactory from "@/domains/epic/services/epicService";
const epicService = epicServiceFactory();
import { clone } from "@/services/utils";

export default {
  name: "DataTableEpics",
  components: {
    VvConfirmationDialog,
    AboutForm,
    ResponsableForm,
    DatasForm,
    IssuesForm,
    ModuleDeliveryFormalization,
    ActionsChangeRequest,
    GuideObjectivesForm,
    VvExpandInner
  },
  props: {
    collapse: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
    },
    options: {
      type: Object,
    },
    noDataText: {
      type: String,
      default: 'Sem dados carregados',
    },
    openedEpic: {
      type: Number,
    },
    completedEpic: {
      type: Boolean,
      default: false,
    },
    editedList: {
      type: Array,
      default: () => ([]),
    },
    focalPointsTap: {
      type: Array,
    },
    project: {
      type: Object,
    },
    originItems: {
      type: Array,
      default: () => ([]),
    },
    errorList: {
      type: Array,
      default: () => ([]),
    },
    permissionTypeEpic: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    canEditStatus: false,
    singleExpand: false,
    openEpicOnCreate: null,
    existDrivers: true,
    groups: [],
    itemsDrivers: [],
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id",
        cellClass: "pa-0",
      },
      {
        text: "ID macro tema",
        align: "start",
        value: "subprojectId",
      },
      {
        text: "Quarter",
        align: "center",
        value: "quarterYear",
      },
      {
        text: "Jira",
        align: "start",
        value: "idJira",
      },
      {
        text: "Nome",
        align: "start",
        value: "name",
      },
      {
        text: "Estágio",
        align: "start",
        value: "stageId",
      },
      {
        text: "Status atual",
        align: "start",
        value: "statusId",
      },
      {
        text: "% Conclusão do épico",
        align: "center",
        value: "progress",
        class: "text-start",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
      {
        text: '',
        value: 'data-table-expand',
        sortable: false,
      },
    ],
    modalDeliveryFormalization: {
      open: false,
    },
    confirmationDialog: {
      value: false,
      title: 'Salvar alterações',
      message: 'Você realmente deseja salvar todas as alterações?',
      id: null,
    },
    expandedEpics: [],
    itsOpened: false,
    changeEpicsStyle: [],
    sessionsError:[
      {
        'session': 'about-form',
        'fields':['epicTypeId','subprojectId','stageId','statusId','blackFriday','capex','opex','priority','idJira','name','epicDescription','expectedResult','measuredResult','ppm','tags','investmentOrder','products','systems','components']
      },
      {
        'session': 'responsable-form',
        'fields': ['journeysId','vsmId','tribesId','gpmId','squadKey','pmpo','demandingArea','stakeholderId','focalPoints','epicResponsibleId','journeyTribeDependency','interestedAreas']
      },
      {
        'session': 'datas-form',
        'fields': ['deliveryBaseline','deliveryPlanned','deliveryDone']
      },
      {
        'session': 'issues-form',
        'fields': ['issues']
      },

    ]
  }),
  watch: {
    openedEpic() {
      this.expandEpicById();
    },
    items() {
      if (this.itsOpened) return;
      this.itsOpened = true;
      this.expandEpicById();
    },
  },
  computed: {
    errorIds() {
      return this.errorList.map((value) => {
        const {id, error = {}} = value || {};
        return Object.values(error).length > 0 && id;
      });
    },
    existSameErrorField(){
      let sessionErrorList = []
      this.errorList.forEach(item => {
        if(item && item.id){
          let sessionErrors = []
          this.sessionsError.forEach(itemSession => {
            if(item.error && itemSession.fields){
              let keysErrors = Object.keys(item.error).map((item)=> item.split('.')[0]);
              const filtered = itemSession.fields.filter( itemFilter => {
                  if(keysErrors.includes(itemFilter)) {
                    return itemFilter;
                  }
                });
              if(keysErrors.length > 0 && filtered.length > 0){
                sessionErrors.push(itemSession.session);
              }
            }
          })
          if(sessionErrors){
            sessionErrorList.push({'id': item.id, 'sessionErrors': sessionErrors});
          }
        }
      })
      return sessionErrorList;
    }
  },
  methods: {
    statusAtualColor(id, css, type, disable) {
      let color = '';
      if (type == 'fc') {
        color = css ? "rgba(93, 93, 93, 0.8) " : "gray";
        if (id == 5 || id == 2 || id == 9) {
          color = css ? "rgba(20, 146, 0, 0.8)" : "secondary";
        } else if (id == 1 || id == 3 || id == 7 || id == 8 || id == 11 || id == 13 || id == 14) {
          color = css ? "rgba(157, 157, 157, 0.8) " : "grayLight";
        }else if (id == 7) {
          color = css ? "#FF502D" : "orange";
        }else if (id == 4 || id == 12) {
          color = css ? "rgba(207, 30, 30, 0.8) " : "error";
        }else if (id == 10 || id == 6) {
          color = css ? "rgba(255, 183, 0, 0.8) " : "warningLight";
        }
        if(disable){
          color = css ? "rgba(0, 0, 0, 0.2) " : "grayLight";
        }
      } else {
        color = css ? "rgba(255, 255, 255, 0.8) " : "white";
        if (id == 5 || id == 2 || id == 9) {
          color = css ? "rgba(20, 146, 0, 0.3)" : "secondary";
        } else if (id == 1 || id == 3 || id == 8 || id == 11 || id == 13 || id == 14) {
          color = css ? "rgba(157, 157, 157, 0.3) " : "grayLight";
        }else if (id == 7) {
          color = css ? "#FFE8D5" : "orange";
        }else if (id == 4 || id == 12) {
          color = css ? "rgba(207, 30, 30, 0.3) " : "error";
        }else if (id == 10 || id == 6) {
          color = css ? "rgba(255, 183, 0, 0.3) " : "warningLight";
        }
        if(disable){
          color = css ? "rgba(255, 255, 255, 0.8) " : "white";
        }
      }
      return color;
    },
    customBackground(id) {
      if (id == 1 || id == 3 || id == 7 || id == 8 || id == 11 || id == 13 || id == 14) {
        return 'custom_gray'
      } else if (id == 4 || id == 12) {
        return 'custom_red'
      } else if (id == 10 || id == 6) {
        return 'custom_yellow'
      }
    },
    customBackgroundStage(id) {
      if (id == 8 || id == 10) {
        return 'custom_gray'
      } else {
        return 'custom_green'
      }
    },
    setConditionalSelects(stageId, epicId) {
      let status = null
      this.options.stages.map(stage => {
        if (stage.status.length > 0 ) {
          if (stage.id === stageId) {
            status = stage.status[0]
          }
        }
      })
      this.$emit("changeStatusId", status, epicId)
    },
    hasErrorInSession(errorList, itemId, sessionName){
      let sessionErrors = errorList.find( item => item.id == itemId)?.sessionErrors
      if(!sessionErrors) return false

      return sessionErrors.includes(sessionName);
    },
    hasChangeListInputs(epicId) {
      let change = {id: epicId, hasChange: true};
      this.$emit('hasChange', change);
    },
    openModalDeliveryFormalization() {
      this.modalDeliveryFormalization.open = true;
    },
    closeModalDeliveryFormalization() {
      this.modalDeliveryFormalization.open = false;
    },
    async loadDetails({item}) {
      if (typeof item.loaded === 'undefined') {
        this.$emit('getDataEpic', {epic: item, doneEpic: this.completedEpic});
      }
      const driversObjectives = await epicService.loadGuideObjectives(this.$route.params.id, item.subprojectId);
      this.groups = driversObjectives.driversCategory;
      this.itemsDrivers = driversObjectives.drivers;
      this.existDrivers = driversObjectives.drivers.length > 0 ? true : false;
    },
    modalRemoveNewEpic(epic) {
      const newEpicData = {
        id: epic.id,
        new: true,
        idJira: '',
        ideaId: '',
        name: '',
        priority: null,
        stageId: null,
        statusId: null,
        progress: 0,
        deliveryPlanned: {
          value: null,
        },
        deliveryDone: {
          value: null,
        },
        permissions: {
          canEditFieldsPermission: true,
          canEditStakeholderFieldsPermission: true,
          canEditSpecialsFields: true,
          createChangeRequest: false,
          createDeliveryFormalization: false,
          editDeliveryBaseline: false,
          editDeliveryFormalization: false,
          validateTreeByEpic: true,
        }
      };
      this.confirmationDialog.id = epic.id;
      if (JSON.stringify(epic) !== JSON.stringify(newEpicData)) {
        this.confirmationDialog.title = 'Excluir épico';
        this.confirmationDialog.message = 'Você realmente deseja excluir esse épico?';
        this.confirmationDialog.value = true;
      } else {
        this.removeNewEpic();
      }
    },
    removeNewEpic() {
      let removeId;
      for (let indexEpics = 0; indexEpics < this.items.length; indexEpics++) {
        if (this.items[indexEpics].id === this.confirmationDialog.id) {
          removeId = indexEpics;
        }
      }
      this.items.splice(removeId, 1);
      this.confirmationDialog.value = false;
      this.confirmationDialog.id = null;
      this.$emit('removeNewEpic');
    },
    validTree(item) {
      if (typeof item.permissions.validateTreeByEpic === "undefined") {
        return true;
      }
      return item.permissions ? item.permissions?.validateTreeByEpic : true;
    },
    expandEpicById() {
      const epicId = this.openedEpic;
      if (!this.items.map(({id}) => id).includes(epicId)) return;
      if (this.expandedEpics.map(({id}) => id).includes(epicId)) return;
      const epic = this.items.find(({id}) => id === epicId);
      this.expandedEpics.push(epic);
      this.loadDetails({item:epic});
    },
    indexOf(item) {
      return this.items.findIndex(({id}) => id === item.id);
    },
    getOriginEpic(value) {
      return this.originItems.find(({id}) => id === value);
    },
    openNewEpic(epic) {
      this.expandedEpics = [];
      this.expandedEpics.push(epic);
      this.$nextTick(() => {
        document.getElementById('aboutForm-' + epic.id).scrollIntoView();
        const yScroll = window.scrollY - 200;
        window.scrollTo(0, yScroll);
      });
    },
    updateErrorList(value, item) {
      const index = this.indexOf(item);
      const error = value.error || {};
      const newErrorList = clone(this.errorList);
      newErrorList[index] = {
        id: item.id,
        error,
      };
      this.$emit("update:errorList", newErrorList);
    },
  },
}
</script>

<style lang="scss">
#DataTableEpics {

  .select-table {
    .v-select__slot {
      height: 32px !important;
      border-radius: 6px;
      width: 200px;
      padding-left: 8px !important;
      background-color: #C6DDBE !important;
      cursor: pointer;
    }
    &.custom_gray{
      .v-select__slot {
        background-color: rgba(157, 157, 157, 0.3) !important;
        pointer-events: none;
      }
      .v-input__append-inner {
        display: none;
      }
    }
    &.custom_blue{
      .v-select__slot {
        background-color: rgba(8, 123, 188, 0.8) !important;
        pointer-events: none;
      }
    }
    &.custom_red{
      .v-select__slot {
        background-color: rgba(207, 30, 30, 0.3) !important;
        pointer-events: none;
      }
    }
    &.custom_yellow{
      .v-select__slot {
        background-color: rgba(255, 183, 0, 0.3) !important;
        pointer-events: none;
      }
    }
    &.custom_orange{
      .v-select__slot {
        background-color: #FFE8D5 !important;
        pointer-events: none;
      }
      .v-input__append-inner {
        display: none;
      }
    }

    .theme--light.v-chip:not(.v-chip--active) {
      background: transparent !important;
    }

    .v-input__control {
      .v-input__slot {
        padding: 0px !important;
        margin: 8px !important;
        font-size: 14px;
        input {
          color: #3D6A06;
        }
        i {
          color: #3D6A06 !important;
          margin-right: 8px;
        }
      }
    }
  }
  
  div.data-table-epics.v-data-table {
    border-bottom: 1px solid lightgray;
    box-shadow: none !important;
    border-radius: 0;

    thead.v-data-table-header {
      background-color: #F4F5F5;
      th {
        color: #838383 !important;
        font-size: 12px;
        font-weight: 400;
      }
    }
    tr {
      td:first-child {
        border-left: 4px solid transparent;
      }
      &.errors td:first-child {
        border-left-color: #FF502D;
      }
      &.errors.v-data-table__expanded td:first-child {
        border-left-color: #B71816;
      }
      &.changes td:first-child {
        border-left-color: #A79CFF;
      }
      div.v-select__selection--comma {
        margin: inherit;
        font-size: 14px;
      }
      .v-autocomplete {
        font-size: 14px;
      }
      .v-progress-linear {
        width: 55%;
        height: 12px !important;
        float: left;
        margin-top: 3%;
        border-radius: 10px;
      }
    }

    .text-truncate {
      display: block;
      width: 280px;
      &.line-2 {
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal !important;
      }
      @media screen and (min-width: 1024px) and (max-width: 1366px) {
        width: 170px;
      }
    }

    &.line-2 {
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .epic-form {
      padding: 0;
      background:#FCFDFC;
    }

    .v-data-table__wrapper {
      tbody tr.v-data-table__expanded__content {
        box-shadow: none;
      }

      @media (min-width: 1024px) {
        overflow-x: hidden;
      }
    }
  }

  .slot-id {
    display: block;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    padding: 23% 0;
  }

  .change-border {
    border-left: 4px solid #A79CFF !important;
  }

  .error-border {
    border-left: 4px solid #FF502D !important;
  }

  .epic-data {
    div {
      span {
        color: #929497;
        font-size: 10px;
        font-weight: 400;
      }

      p {
        font-weight: 600;
        font-size: 18px;
        color: #6D6E70;
        margin-top: 8px;
        margin-bottom: 4px;
      }

      &:first-child {
        margin-right: 70px;
      }
    }
  }
}
</style>
export default {
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        formOptions: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            journey: {
                oldVsmId: null,
                journeyId: [],
                newVsmId: null,
            },
            journeyToMigrate: [],
            newVsm: []
        }
    },

    methods: {
        handleOptions(id) {
            this.journey.journeyId = [];
            this.newVsm = [];
            this.journey.newVsmId = null;

            this.options.vsms.map(vsm => {
                if (vsm.id === id) {
                    vsm.journeys.map(journey => {
                        this.journey.journeyId.push(journey.id)
                        this.journeyToMigrate.push(journey)
                    })
                } else {
                    this.newVsm.push(vsm)
                }
            })
        },
        save() {
            this.$emit("save", this.journey)
        },
        closeModal() {
            this.$emit("close");
        },
        clear() {
            this.journey = {
                oldVsmId: null,
                journeyId: [],
                newVsmId: null,
            };
        }
    },
}
<template>
  <v-dialog
      :value="show"
      persistent
      width="500"
  >
    <v-card>
      <v-card-text class="pt-6">
        <h2 class="mb-6"> Atenção </h2>
        <div class="mb-4"> D{{ (total > 1) ? 'os' : 'e' }} {{ total }} épico{{ (total > 1) ? 's' : '' }} alterado{{ (total > 1) ? 's' : '' }}: </div>
        <ul>
          <li v-if="success > 0" class="mb-4 font-green">{{ success }} épico{{ (success > 1) ? 's' : '' }} fo{{ (success > 1) ? 'ram' : 'i' }} salvo{{ (success > 1) ? 's' : '' }} com sucesso</li>
          <li v-if="error > 0" class="mb-5 font-red">{{ error }} épico{{ (error > 1) ? 's' : '' }} necessita{{ (error > 1) ? 'm' : '' }} de correção</li>
        </ul>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            depressed
            @click="confirm"
        >
          Corrigir épico(s)
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogResult",
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    },
    success: {
      type: Number,
      default: 0,
    },
    error: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirmed', true)
      this.$emit('input', false)
    },
  }
}
</script>

<style scoped>
.font-green {
  color: #699F10;
}
.font-red {
  color: #FF502D;
}
</style>
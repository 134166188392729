<template>
  <v-card outlined class="mb-0 d-flex align-center">
    <v-list-item three-line>
      <v-list-item-content class="text-center">
        <v-list-item-title
          class="mb-1"
          v-if="label"
          style="white-space: normal"
        >
          {{ label }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="value">
          <v-btn
            icon
            color="primary"
            @click="beforeItem"
            :disabled="selectedIndex <= 0 || disabled"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="mx-2"> {{ selected }} </span>
          <v-btn
            icon
            color="primary"
            @click="nextItem"
            :disabled="selectedIndex >= options.length - 1 || disabled"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "VvHeightScore",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
  },
  watch: {
    options() {
      this.updateEmptyValue();
    },
    value() {
      this.updateEmptyValue();
    },
  },
  computed: {
    selected() {
      return this.options[this.selectedIndex]?.name;
    },
    selectedIndex() {
      return this.options.findIndex(({ id }) => id === this.value);
    },
  },
  methods: {
    nextItem() {
      this.$emit("input", this.options[this.selectedIndex + 1].id);
    },
    beforeItem() {
      this.$emit("input", this.options[this.selectedIndex - 1].id);
    },
    updateEmptyValue() {
      if (!this.value) this.$emit("input", this.options?.[0].id);
    },
  },
  mounted() {
    this.updateEmptyValue();
  }
};
</script>

import axios from "@/plugins/axios";

export default function businessTreeServiceFactory() {
  return {
    getFormOptions() {
      return axios
          .get(`v2/trees/business/form-options`)
          .then(res => res.data)
    },
    getOptions() {
      return axios
        .get(`v2/options/business-tree`)
        .then(res => res.data)
    },
    getAreas(params) {
      return axios
        .get(`v2/trees/business`, { params: params })
        .then(res => res.data)
    },
    getAreaDetail(areaId) {
      return axios
        .get(`v2/trees/business/${areaId}`)
        .then(res => res.data)
    },
    createArea(params) {
      return axios
        .post(`v2/trees/business`, params)
        .then(res => res.data)
    },
    editStakeholder(params) {
      return axios
        .put(`v2/trees/business`, params)
        .then(res => res.data)
    },
    getStakeholders(areaId) {
      return axios
        .get(`v2/options/area-tree/${areaId}`)
        .then(res => res.data)
    }
  }
}
import axios from "@/plugins/axios";

export default function subprojectServiceFactory() {
  return {
    loadProjects(projectId) {
      return axios
        .get(`/v2/project/${projectId}/subproject/summary`)
        .then((res) => res.data);
    },
    loadProjectDetails(projectId, subprojectId) {
      return axios
          .get(`/v2/project/${projectId}/subproject/${subprojectId}`)
          .then((res) => res.data);
    },
    loadCanvas(projectId) {
      return axios
        .get(`/v2/project/${projectId}/canvas/`)
        .then((res) => res.data);
    },
    saveCanvas(projectId, canvas, canvasExist) {
      if (!canvasExist) {
        return axios
          .post(`/v2/project/${projectId}/canvas/`, canvas)
          .then((res) => res.data);
      }
      return axios
        .put(`/v2/project/${projectId}/canvas/${canvas.id}`, canvas)
        .then((res) => res.data);
    },
    async saveProjects(projectId, newSubprojects) {
      const payload = newSubprojects.map((subproject) => {
        const id = subproject.id;
        const data = {...subproject};
        if (data.new) {
          delete data.id;
        }
        return {
          id,
          payload: {
            ...data,
          }
        }
      });
      payload.forEach((item) => {
        item.promise = (item.payload.new) ? axios.post(`/v2/project/${projectId}/subproject`, item.payload) : axios.put(`/v2/project/${projectId}/subproject/${item.payload.id}`, item.payload);
        item.promise
          .then(res => item.response = res)
          .catch(err => item.error = err);
      });
      await Promise.allSettled(payload.map(({promise}) => promise));
      return payload;
    },
    loadStrategicImpacts(projectId) {
      return axios
        .get(`/v2/project/${projectId}/subproject/impacts_summary`)
        .then((res) => res.data);
    },
    saveStrategicImpacts(projectId, objPayload) {
      const payload = { payload: objPayload }
      return axios
        .put(`/v2/project/${projectId}/subproject/impacts_save`, payload)
        .then((res) => res.data);
    },
    loadList(params) {
      return axios
        .get(`/v2/subproject`, {params: params})
        .then((res) => res.data);
    },
    loadOptionsProjects() {
      return axios
        .get(`/v2/options/subproject-list`)
        .then((res) => res.data);
    },
  }
}
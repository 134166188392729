import axios from "@/plugins/axios";

export default function deliveryFormalizationServiceFactory() {
  return {
    loadList(projectId) {
      return axios
        .get(`v2/delivery-formalization/list/${projectId}`)
        .then(res => res)
        .catch(err => err.response);
    },
    loadDeliveryFormalization(deliveryFormalizationId) {
      return axios
        .get(`v2/delivery-formalization/${deliveryFormalizationId}`)
        .then(res => res)
        .catch(err => err.response);
    },
    loadDeliveryFormalizationHistory(deliveryFormalizationId) {
      return axios
        .get(`v2/delivery-formalization/${deliveryFormalizationId}/history`)
        .then(res => res)
        .catch(err => err.response);
    },
    getFocalPointsDemandingArea(epicId) {
      return axios
        .get(`options/focalpoints/${epicId}`)
        .then(res => res.data);
    },
    getCurrentFormalization(deliveryFormalizationId){
      return axios
          .get(`v2/delivery-formalization/get-current/${deliveryFormalizationId}`)
          .then(res => res)
          .catch(err => err.response);
    },
    saveDeliveryFormalization(projectId, epicId, formData) {
      return axios
        .post(`v2/project/${projectId}/epic/${epicId}/formalization`, formData)
        .then(res => res.data)
    },
    downloadEvidence(evidenceId) {
      return axios
        .get(`v2/delivery-formalization/evidence/${evidenceId}`)
        .then(res => res)
        .catch(err => err.response);
    },
    sendMessage(formData) {
      return axios
        .post(`v2/delivery-formalization/message`, formData)
        .then(res => res)
        .catch(err => err.response);
    }
  }
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":true,"id":"epic"}},[_c('vv-loading',{attrs:{"is-loading":_vm.isLoading}}),_c('vv-breadcrumbs',{attrs:{"items-breadcrumbs":_vm.breadcrumb}}),_c('v-card',[_c('v-toolbar',{staticClass:"panel-header px-7 py-10 mobile-height-header"},[_c('div',{staticClass:"grid-8 gap-md"},[_c('div',{staticClass:"g-col-1 md:g-col-8"},[_c('label',[_vm._v("ID Iniciativa")]),_c('h2',[_vm._v(_vm._s(_vm.getLabel(_vm.project.id)))])]),_c('div',{staticClass:"g-col-5 md:g-col-8"},[_c('label',[_vm._v("Nome da Iniciativa")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h2',_vm._g(_vm._b({staticClass:"project-name"},'h2',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getLabel(_vm.project.name))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel(_vm.project.name)))])])],1),(_vm.project.id)?_c('set-project-step',{attrs:{"statusWorkflowEncerramento":_vm.statusWorkflowEncerramento,"id":_vm.project.id},on:{"setErrosChangeStep":_vm.setErrosChangeStep}}):_vm._e()],1)]),(_vm.currentStep == _vm.CONST_PROJECT_STEPS.CONGELADA)?_c('v-alert',{staticClass:"pa-2 mt-6 mx-7",attrs:{"type":"error","outlined":"","icon":"mdi-alert-outline","id":"currentStepAlert"}},[_vm._v(" Iniciativas congeladas não permitem a edição de nenhum campo ou épico e os workflows pendentes de aprovação ficam congelados. ")]):_vm._e(),_c('v-tabs',{staticClass:"options-tabs px-7",model:{value:(_vm.loadingTab),callback:function ($$v) {_vm.loadingTab=$$v},expression:"loadingTab"}},[_c('v-tab',{on:{"click":function($event){return _vm.changeUri(_vm.project.id)}}},[_vm._v("Iniciativa")]),_c('v-tab',{attrs:{"disabled":!_vm.ableEpicTab},on:{"click":function($event){return _vm.changeUri(_vm.project.id, 'visualizar')}}},[_vm._v(" Macro Temas "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dense":"","color":"#DBDCDC"}},'v-icon',attrs,false),on),[_vm._v("mdi-eye-outline")])]}}])},[_c('span',[_vm._v("Apenas visualização")])])],1),_c('v-tab',{attrs:{"disabled":!_vm.ableEpicTab},on:{"click":function($event){return _vm.changeUri(_vm.project.id, 'epicos')}}},[_vm._v("Entregáveis")]),_c('v-tab',{attrs:{"disabled":!_vm.ableEpicTab},on:{"click":function($event){return _vm.changeUri(_vm.project.id, 'change-request')}}},[_vm._v(" Change Requests "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dense":"","color":"#DBDCDC"}},'v-icon',attrs,false),on),[_vm._v("mdi-eye-outline")])]}}])},[_c('span',[_vm._v("Apenas visualização")])])],1),_c('v-tab',{attrs:{"disabled":!_vm.ableEpicTab},on:{"click":function($event){return _vm.changeUri(_vm.project.id, 'formalizacao')}}},[_vm._v(" Formalização de entrega "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dense":"","color":"#DBDCDC"}},'v-icon',attrs,false),on),[_vm._v("mdi-eye-outline")])]}}])},[_c('span',[_vm._v("Apenas visualização")])])],1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.loadingTab),callback:function ($$v) {_vm.loadingTab=$$v},expression:"loadingTab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('project',{ref:"projectComponent",attrs:{"id":_vm.project.id,"name":_vm.project.name},on:{"currentStep":function($event){_vm.currentStep = $event},"statusWorkflowEncerramento":function($event){_vm.statusWorkflowEncerramento = $event},"updateBreadcrumb":_vm.updateInsideBreadcrumb}})],1)],1)],1),_c('v-tab-item',[_c('list-sub-projects')],1),_c('v-tab-item',[_c('list-epic',{attrs:{"create-epic-permission":_vm.listEpicOptions,"project":_vm.project}})],1),_c('v-tab-item',[_c('list-change-request',{attrs:{"project":_vm.project}})],1),_c('v-tab-item',[_c('list-delivery-formalization')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
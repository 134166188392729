import VvLoading from "@/components/VvLoading";
import Breadcrumbs from "@/components/common/VvBreadcrumbs";
import TextInput from "@/components/common/VvTextInput";
import faqServiceFactory from "./../../services/faqService";

const FaqService = faqServiceFactory();

export default {
    name: "FAQ",
    components: {
        VvLoading,
        TextInput,
        Breadcrumbs,
    },
    data: () => ({
        isLoading: false,
        textSearch: "",
        lastTextSearch: "",
        timeCount: 3,
        currentCount: 0,
        searchLoading: false,
        breadcrumb: [
            {
                text: 'Ajuda',
                disabled: true,
            },
        ],
        treeMenu: [
            {
                id: 1,
                name: 'CARREGANDO...',
                children: [],
            },
        ],
        article: {
            id: 0,
            name: "",
            body: "<p></p>",
        },
        searchResult: [],
    }),
    watch: {
        textSearch() {
            this.currentCount = 0;
        },
    },
    methods: {
        async loadMenu() {
            try {
                this.isLoading = true;
                const resultMenu = await FaqService.loadTreeMenu();
                this.treeMenu = resultMenu.menu.children;
            } catch (error) {
                this.$showError(error);
            } finally {
                this.isLoading = false;
            }
        },
        async callArticle(idArticle, keyword = null) {
            if (idArticle.length > 1) {
                const countItens = idArticle.length;
                idArticle[0] = idArticle[countItens-1];
            }
            if (typeof idArticle[0] !== 'undefined') {
                this.isLoading = true;
                try {
                    this.article.name = "";
                    this.article.body = "";
                    const resultArticle = await FaqService.loadArticle(idArticle[0], keyword);
                    this.article = resultArticle.article;
                    this.$nextTick(() => {
                        const cellColor = document.getElementsByClassName("confluenceTd")
                        for (let indexCell = 0; indexCell < cellColor.length; indexCell++) {
                            const hexColor = document.getElementsByClassName("highlight-"+cellColor[indexCell].dataset.highlightColour)
                            for (let indexHC = 0; indexHC < hexColor.length; indexHC++) {
                                hexColor[indexHC].style.backgroundColor = cellColor[indexCell].dataset.highlightColour;
                                hexColor[indexHC].style.color = "#FFFFFF";
                            }
                        }
                    })
                } catch (error) {
                    this.article.id = 0;
                    this.$showError(error);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async callEndpointSearch() {
            try {
                this.searchLoading = true;
                const search = await FaqService.searchTerm(this.textSearch);
                this.searchResult = search.results;
                this.pushCacheSearchDoc(this.textSearch);
            } catch (error) {
                const { response: { data: { message } = {} } = {} } = error;
                this.$showError(message || "Termo de busca inválido!");
                this.searchResult = [];
            } finally {
                this.searchLoading = false;
            }
        },
        searchWord() {
            this.textSearch = this.getCacheSearchDoc("Search Documentation");
            setInterval(() => {
                this.currentCount++;
                if (this.textSearch.length > 3) {
                    if (this.timeCount === this.currentCount) {
                        this.callEndpointSearch();
                        this.lastTextSearch = this.textSearch;
                    }
                }
                if (this.textSearch.length === 0) {
                    if (this.timeCount === this.currentCount) {
                        this.searchResult = [];
                        this.lastTextSearch = this.textSearch;
                        this.clearCacheSearchDoc();
                    }
                }
                return false;
            }, 1000);
        },
    },
    mounted() {
        this.loadMenu();
        this.searchWord();
    },
}
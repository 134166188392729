import axios from "@/plugins/axios";

export default function auditServiceFactory() {
    return {
        loadModules() {
            return axios
                .get(`v2/audit/modules`)
                .then(res => res.data)
        },
        loadOptions() {
            return axios
                .get(`v2/audit/options/epic`)
                .then(res => res.data)
        },
        generateExcel(payload) {
            return axios
                .post(`v2/audit/`, payload)
                .then(res => res.data)
        }
    }
}
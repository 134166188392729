import VvLoading from "@/components/VvLoading";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";
import SingleSelect from "@/components/common/VvSingleSelect";
import ConfirmationDialog from "@/components/common/VvConfirmationDialog";
import AuditEpics from "../../components/Epics/Epics.vue";
import auditServiceFactory from "../../services/auditService";

const auditService = auditServiceFactory();

export default {
    name: "Modules",
    components: {
        VvLoading,
        VvBreadcrumbs,
        SingleSelect,
        ConfirmationDialog,
        AuditEpics,
    },
    data: () => ({
        isLoading: false,
        modulesId: null,
        oldModuleId: null,
        optionsModules: [],
        breadcrumb: [
            {
                text: 'Auditoria',
                disabled: false,
            },
        ],
        dialog: {
            show: false,
        },
    }),
    methods: {
        async loadModules() {
            this.isLoading = true;
            try {
                let result = await auditService.loadModules();
                this.optionsModules = result;
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false;
            }
        },
        loadFilters() {
            if (this.oldModuleId !== null) {
                this.dialog.show = true;
            } else {
                this.oldModuleId = this.modulesId;
            }
        },
        reloadFilters() {
            this.oldModuleId = null;
            this.loadFilters();
        },
    },
    mounted() {
        this.loadModules();
    },
}
<template>
<v-form ref="form">
  <v-card flat>
    <div v-if="isEditing">
    <v-card>
      <v-toolbar class="vv-ht-115" flat color="toolbarColor" height="75px" dark>
        <v-row class="sizeFont">
          <v-col cols="4" md="2">
            <label>ID Dor</label>
            <h2>{{ problem.id }}</h2>
          </v-col>
          <v-col cols="5" md="6">
            <label>Titulo da Dor</label>
            <h2>{{ cacheProblem.title }}</h2>
          </v-col>
          </v-row>
      </v-toolbar>
    </v-card>
  </div>
    <div class="form-container">
      <h2 class="title-form" v-if="!isEditing">Cadastro Dor</h2>
      <div class="grid-12 sm:grid-1 gap-lg">
        <div class="g-col-1">
          <label for="id">ID Dor</label>
          <div class="edit-disabled" v-if="problem.id">
            <v-chip label>{{ problem.id }}</v-chip>
          </div>
          <div v-else>
            <div class="v-input v-text-field v-text-field--is-booted">
              <div class="v-input__control">
                <div class="v-input__slot">
                  <div class="v-text-field__slot">
                    <input disabled id="id" name="id" type="text" />
                    <v-icon style="cursor: default">mdi-lock</v-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="g-col-3">
            <vv-multiple-select
              label="ID ideia"
              v-model="problem.ideas"
              :items="listOptions.ideas"
              item-text="title"
              item-value="id"
            :disabled="isNotEditable"
            >
              <template #chip="scope">
                {{ scope.id }} - {{ scope.title }}
              </template>
              <template #cell="scope">
                {{ scope.id }} - {{ scope.title }}
              </template>
            </vv-multiple-select>
        </div>
        <div class="g-col-6">
          <label for="titulo-dor">Titulo da Dor</label>
          <v-text-field 
            v-model="problem.title"
            counter="150"
            :disabled="isNotEditable"
            required
            :rules="[
              val => !!val || 'Campo obrigatório',
              val => (!!val && val.length <= 150 ) || 'Limite de caracteres de 150 excedido'
            ]"
            class="fixed-label"
          />
        </div>
        <div class="g-col-2">
          <label for="status-dor">Status Dor</label>
          <v-select
              v-model="problem.statusProblem"
              :items="listOptions.statusProblem"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              :disabled="!isMaster || !isEditing"
              :rules="[
                  val => !!val || 'Campo obrigatório'
              ]"
              class="fixed-label "
            >
            
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  label
                  :style="
                    'background-color: ' +
                    statusColor(item.id, true, 'bg') +
                    '; color: ' +
                    statusColor(item.id, true, 'fc') +
                    '; width: 90%'
                  "
                  class="fixed-label"
                >
                  {{ item.name }}
                </v-chip>
              </template>
              <template #item="{ item }">
                <div
                  tabindex="0"
                  role="option"
                  class="v-list-item v-list-item--link"
                  :style="
                    'background-color: ' +
                    statusColor(item.id, true, 'bg') +
                    '; color: ' +
                    statusColor(item.id, true, 'fc')
                  "
                >
                  <div class="v-list-item__content" v-if="item.active">
                    <div class="v-list-item__title">{{ item.name }}</div>
                  </div>
                </div>
              </template>
            </v-select>
        </div>
        <div class="g-col-6">
          <label for="description">Descrição da Dor</label>
          <v-textarea
              name="description"
              v-model="problem.description"
              filled
              :counter="255"
              :disabled="isNotEditable"
              :rules="[
                  val => !!val || 'Campo obrigatório',
                  val => (!!val && val.length <= 255 ) || 'Limite de caracteres de 255 excedido'
                ]"
              class="scroll-style fixed-label"
          />
        </div>
        <div class="area-responsible g-col-6">
          <div class="grow">
            <label for="area-negocio">Área de negócio responsável</label>
            <v-autocomplete
              v-model="problem.areaId"
              :items="listOptions.impactedAreas"
              item-text="name"
              item-value="id"
              append-icon="mdi-chevron-down"
              :disabled="isNotEditable"
              :rules="[
                  val => !!val || 'Campo obrigatório'
              ]"
              class="fixed-label"
            />
          </div>
        </div>
        <div class="g-col-2">
          <label for="frequencia-ocorrencia">Frequência da Dor</label>
          <v-select
            v-model="problem.frequencyProblem"
            :items="listOptions.frequencyProblem"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :disabled="isNotEditable"
            required
            :rules="[
                val => !!val || 'Campo obrigatório'
            ]"
            class="fixed-label"
          />
        </div>
        <div class="g-col-2">
          <label for="urgencia-resolucao">Urgência de resolução</label>
          <v-select
            v-model="problem.urgentResolution"
            :items="listOptions.urgentResolution"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :disabled="isNotEditable"
            required
            :rules="[
                val => !!val || 'Campo obrigatório'
            ]"
            class="fixed-label"
          />
        </div>
        <div class="g-col-2">
          <label for="impacto-dor" >Impacto da Dor</label>
          <v-select
            v-model="problem.impactProblem"
            :items="listOptions.problemImpact"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            :disabled="isNotEditable"
            required
            :rules="[
                val => !!val || 'Campo obrigatório'
            ]"
            class="fixed-label"
          />
        </div>
        <div class="g-col-6 g-row-2">
          <label for="description-impacted">Descrição do impacto</label>
          <v-textarea
            name="description-impacted"
            v-model="problem.impactDescription"
            :disabled="isNotEditable"
            :counter="255"
            filled
            required
            :rules="[
                val => !!val || 'Campo obrigatório',
                val => (!!val && val.length <= 255 ) || 'Limite de caracteres de 255 excedido'
            ]"
            class="scroll-style "
          />
        </div>
        <div class="main-impacts g-col-6">
          <label for="principal-impacted">Principais impactados</label>
          <v-autocomplete
            placeholder="Selecione o(os) impactados"
            v-model="problem.mainImpacted"
            :items="listOptions.mainImpacted"
            item-text="name"
            item-value="id"
            chips
            multiple
            append-icon="mdi-chevron-down"
            :disabled="isNotEditable"
            :rules="[
                val => (!!val && val.length > 0) || 'Campo obrigatório'
            ]"
            
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                @click="select"
              >
                {{ item.name }}
                <v-icon
                  @click="removeMainImpacted(item)"
                  size="16"
                  >mdi-close
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </div>
        <div class="g-col-6">
          <label for="root_cause_id">Causa raiz da Dor é conhecida?</label>
          <v-switch
            v-model="problem.rootCause"
            class="manual-line switch"
            color="secondary"
            :label="problem.rootCause ? 'Sim' : 'Não'"
            inset
            :disabled="isNotEditable"
            @change="clearRootCauseDetail"
          />
        </div>
        <div class="g-col-6">
          <label for="detalhe-causa-raiz">Detalhes causa raiz</label>
          <v-text-field
            v-model="problem.rootCauseDetail"
            :counter="255"
            :disabled="isNotEditable || !problem.rootCause"
            :rules="[
                val => (!problem.rootCause || (problem.rootCause && !!val)) || 'Campo obrigatório',
                val => (!problem.rootCause || (problem.rootCause && !!val && val.length <= 255)) || 'Limite de caracteres de 255 excedido'
            ]"
            class="fixed-label"
          />
        </div>
        <div class="g-col-12">
          <label for="suggestions">Sugestão de correções</label>
          <v-textarea
            name="suggestions"
            v-model="problem.correctionSuggestion"
            filled
            :disabled="isNotEditable"
            class="scroll-style fixed-label"
          />
        </div>
      </div>
    </div>
  </v-card>

  <v-row class="mt-2">
    <v-col cols="12" class="align-end" align="end">
      <v-btn
        @click="clear()"
        color="default"
        class="btn-cancel-problem mr-4"
        :disabled="isNotEditable"
      >
        Cancelar
      </v-btn>
      <v-btn
        @click="goToIdea(false)"
        color="secondary"
        class="btn-cancel-problem mr-4"
        :disabled="isNotEditable"
      >
        Criar ideia para a Dor
      </v-btn>
      <v-btn
        @click="onConfirmation"
        color="primary"
        class="btn-save-problem"
        :disabled="isNotEditable"
        :loading="isLoading"
      >
        {{ $router.currentRoute.meta.isEditing ? 'Salvar alterações' : 'Cadastrar' }}
      </v-btn>
    </v-col>
  </v-row>

  <vv-alert 
    v-model="alert.show" 
    :message="alert.message"  
    :type="alert.type" 
    :dismissible="alert.dismissible" />

  <vv-confirmation-dialog
   v-model="dialog.show"
   title="Cadastrar Dor"
   message="Você realmente deseja cadastrar essa dor?"
   @confirmed="submit()" />

  <vv-confirmation-dialog
   v-model="dialog.goToIdea"
   title="Criar ideia para a Dor"
   message="Você tem certeza que deseja ser redirecionado para criar ideia?"
   @confirmed="goToIdea(true)" />

  <vv-loading :is-loading="isLoading" />

  <v-dialog v-model="dialog.block" persistent width="600">
    <v-card>
      <div class="pa-6">
        <h5 class="text-h5 text-center font-weight-bold">
          ATENÇÃO
        </h5>
        <p class="pt-2 mb-1">
          O objetivo deste módulo é permitir o cadastro de dores relevantes,
          auxiliando a melhoria contínua de nossos processos e da experiência dos nossos clientes,
          parceiros e colaboradores.
        </p>
        <p>
          Por favor considere se a dor a ser cadastrada possui impacto significativo
          e recorrência antes de efetuar o cadastro.
        </p>
        <div style="font-size: var(--font-size-p)">
          <span class="font-weight-bold">NÃO</span> devem ser cadastrados como dores:
          <ul>
            <li>Bugs de sistema ou itens de sustentação</li>
            <li>Dores ou problemas pontuais/isolados</li>
            <li>Queixas e reclamações pessoais</li>
          </ul>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="primary" @click="dialog.block = false">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-form>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import problemServiceFactory from "@/services/problemService";
import VvAlert from "@/components/common/VvAlert";
import VvConfirmationDialog from "@/components/common/VvConfirmationDialog";
import VvMultipleSelect from "@/components/common/VvMultipleSelect";

const problemService = problemServiceFactory();
const newProblem = {
  id: undefined,
  ideas: [],
  title: "",
  statusProblem: 1,
  description: "",
  frequencyProblem: undefined,
  urgentResolution: undefined,
  impactProblem: undefined,
  impactDescription: "",
  mainImpacted: [],
  rootCause: false,
  rootCauseDetail: "",
  correctionSuggestion: "",
  areaId: "",
  userId: undefined
}

export default {
  name: "Problem",
  components: {
    VvAlert,
    VvLoading,
    VvConfirmationDialog,
    VvMultipleSelect
  },
  props: {
    SavedDemandingAreas: {
      type: Array,
    },
  },
  data: () => ({
    isNotEditable: false, // Formulário não editável 
    isLoading: true, // Mostrar tela de carregamento  
    isEditing: false, // Se está no modo de edição

    dialog: {
      show: false,
      block: false,
      goToIdea: false
    },

    alert: { 
      show: false,
      message: '',
      type: 'info',
      dismissible: false
    },

    cacheProblem: {},
    problem: { ...newProblem },

    listIdeasProblem: [],

    listOptions: { // Lista de opções para os seletores 
      impactedAreas: [],
      statusProblem: [],
      frequencyProblem: [],
      problemImpact: [],
      urgentResolution: [],
      mainImpacted: [],
      ideas: [],
    },
  }),

  computed: {
    isMaster() { // Verifica se o Usuário é Master ou ET 
      const loginUserTypeId = this.$store.getters.type;
      const containMaster = loginUserTypeId.includes(this.CONST_USER_TYPE.USUARIO_MASTER)
      const containEt =  loginUserTypeId.includes(this.CONST_USER_TYPE.GERENTES_ESCRITORIO_TRANSFORMACAO)

      return containMaster || containEt 
    }
  },

  async created() {
    this.loadLists();
    const { isEditing } = this.$router.currentRoute.meta;
    this.isEditing = isEditing;
    const problemId = Number(this.$route.params.id);

    if(isEditing && !Number.isInteger(problemId)) {
      this.isNotEditable = true;
    }
    if(isEditing && Number.isInteger(problemId)) {
      this.show(problemId);
    }

    if (!this.isEditing) {
      this.verifyPermissionToCreate();
    }

    if(!isEditing) {
      this.dialog.block = true
    }
  },

  methods: {
    async loadLists() {
      const result = await problemService.loadLists();
      this.listOptions = result

      this.listOptions.impactedAreas =
        this.listOptions.impactedAreas.filter(
          (impactedAreas) => {
            return impactedAreas.id > 1;
          }
        );
        this.isLoading = false;
    },

    onConfirmation() {
      if(!this.isEditing) {
        this.dialog.show = true;
        return;
      }
      this.submit();
    },

    async submit() {
      const isValid = this.$refs.form.validate();
      if(!isValid) {
        this.alert = {
          show: true,
          message: 'Verifique todos os Campos do Formulário',
          type: 'error',
          dismissible: true
        };
        return;
      }

      const method = this.problem.id ? 'edit' : 'create'
      try {
        this.isLoading = true;
        const response = await problemService[method](this.problem);
        this.alert = {
          show: true,
          message: response.data.message,
          type: 'success',
          dismissible: true
        };

        setTimeout(() => {
            if(!this.isEditing) {
              this.$router.push({ path: `/dor/${response.data.problem.id}` })
            } 
        }, 3000);
        
        
      } catch(error) {
          const message = error.message || error;
          this.alert = {
            show: true,
            message,
            type: 'error',
            dismissible: true
          };
      } finally {
        this.isLoading = false;
      }
    },

    async show(id) {
      try {
        this.isLoading = true

        const problem = await problemService.show(id)
        this.problem = { ...problem }
        this.cacheProblem = { ...problem }
        
        if((this.problem.userId !== this.$store.getters.id) && (!this.isMaster)) {
          this.isNotEditable = true 
        } 
        
      } catch (error) {
        this.alert = {
          show: true,
          message: 'Parece que não encontramos esta Dor!',
          type: 'error',
          dismissible: true
        };
      } finally {
        this.isLoading = false
      }
    },

    clear() {
      if(this.isEditing) {
        this.problem = { ...this.cacheProblem }
      } 
      if(!this.isEditing) {
        this.problem = { ...newProblem }
      } 
      this.$refs.form.resetValidation()
    },

    clearRootCauseDetail(status) {
      if(!status) {
        this.problem.rootCauseDetail = '';
      } 
    },

    statusColor(id, css, type) {
      if (!css) return 'secondary'
      if (type === 'fc') return '#202121'
      switch (id) {
        case 1: return '#faf59a'
        case 2: return '#b8c8fc'
        case 3: return '#aefcf4'
        case 4: return '#aeeac6'
        default: return 'secondary'
      }
    },

    removeMainImpacted(item) {
      if (!this.isNotEditable) {
        this.problem.mainImpacted.splice(
          this.problem.mainImpacted.indexOf(item.id),
          1
        );
        this.problem.mainImpacted = [...this.problem.mainImpacted];
      }
    },

    async verifyPermissionToCreate() {
      this.isLoading = true;
      try {
        this.isNotEditable = !await problemService.verifyPermissionToCreate(this.item, this.ideasToLink);
      } catch ({message}) {
        console.error(message)
        this.alert = {
          show: true,
          message: message || 'Aconteceu algo de errado no Servidor...',
          type: "error",
          dismissible: true,
        };
        return;
      } finally {
        this.isLoading = false;
      }
    },

    goToIdea(redirect = false) {
      if (!redirect) {
        this.dialog.goToIdea = true;
        return;
      }
      this.$router.push(`/ideia/criar/${this.problem.id}`);
    }
  },
};

</script>

<style lang="scss">
  .fixed-label {
    label {
      max-width: 100%;
      transform: translateY(-18px) scale(.75);
    }
  }

  .grow {
    flex: 1;
  }

  .vv-label {
    font-size: 12px;
  }

  .vv-mt-22 {
    margin-top: 22px !important;
  }

  .vv-ht-115 {
    height: 115px !important;
  }

  .form-container {
    padding: 40px;
  }

  .sizeFont {
    h2 {
      font-size: 14px;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
    }
  }

  .area-responsible {
    padding-bottom: 8px;
    display: flex;
    align-items: flex-end;
  }
  @media screen and (max-width: 960px) {
    .main-impacts {
      grid-row: 10/11;
    }
  }
</style>

import DataTableProjects from '../DataTableSubProjects/DataTableSubProjects.vue';
import inceptionServiceFactory from "@/domains/project/services/inceptionService";
import subprojectServiceFactory from "@/domains/subProject/services/subProjectService";
import initiativeServiceFactory from "@/domains/project/services/projectService";
import ModalStrategicImpacts from "@/domains/project/components/ModalStrategicImpacts";

const inceptionService = inceptionServiceFactory();
const subprojectService = subprojectServiceFactory();
const projectService = initiativeServiceFactory();

export default {
    components: {
        DataTableProjects,
        ModalStrategicImpacts
    },
    data() {
        return {
            search: null,
            subProjects: [],
            subProject: {},
            subProjectId: -1,
            options: {}
        }
    },
    watch: {
        subProjects: {
            handler(){
                this.adjustStatus();
            },
            deep: true
        }
    },
    methods: {
        async loadProjects() {
            try {
                this.$showLoading();
                const result = await inceptionService.loadSubProject(this.$route.params.id, false);
                this.subProjects = result.data.subprojects
            } catch(error) {
                this.$showError(error)  
            } finally {
                this.$hideLoading();
            }
        },
        async loadProjectDetails(id) {
            this.subProject = {};
            try {
                this.$showLoading();
                const result = await subprojectService.loadProjectDetails(this.$route.params.id, id);
                this.subProject = result.subproject;
            } catch(error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        async loadOptions() {
            try {
                this.$showLoading();
                const result = await projectService.loadOptions(this.$route.params.id);
                this.options = result;
            } catch(error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        async loadOptionsInception() {
            try {
                this.$showLoading();
                const result = await inceptionService.loadOptions();
                this.optionsInception = result.options;
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        adjustStatus() {
            if (typeof this.optionsInception !== 'undefined') {
                Object.keys(this.subProjects).forEach((keySubProjects) => {
                    Object.keys(this.subProjects[keySubProjects].epics).forEach((keyEpics) => {
                        Object.keys(this.optionsInception.status).forEach((indexStatus) => {
                            if (this.optionsInception.status[indexStatus].id === this.subProjects[keySubProjects].epics[keyEpics].statusId) {
                                this.$set(this.subProjects[keySubProjects].epics[keyEpics], 'statusName', this.optionsInception.status[indexStatus].name)
                            }
                        });
                        Object.keys(this.optionsInception.stage).forEach((indexStage) => {
                            if (this.optionsInception.stage[indexStage].id === this.subProjects[keySubProjects].epics[keyEpics].stageId) {
                                this.$set(this.subProjects[keySubProjects].epics[keyEpics], 'stageName', this.optionsInception.stage[indexStage].name)
                            }
                        });
                    });
                });
            }
        },
    },
    created() {
        this.loadOptionsInception();
        this.loadProjects();
        this.loadOptions();
    },
    mounted() {
        if (typeof this.$route.query.subprojectId !== 'undefined') {
            this.subProjectId = parseInt(this.$route.query.subprojectId);
        }
    },
}
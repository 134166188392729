import axios from "@/plugins/axios";

export default function initiativeServiceFactory() {
  return {
    create(project) {
      const newProject = { ...project };
      delete newProject.id;
      return axios.post("/v2/project", newProject).then((res) => res.data);
    },
    save(project) {
      return axios
        .put(`/v2/project/${project.id}`, project)
        .then((res) => res.data);
    },
    load({ id: projectId }) {
      return axios.get(`/v2/project/${projectId}`).then((res) => res.data);
    },
    loadOptions(projectId) {
      return axios
        .get(`/v2/project/${projectId ?? 0}/options`)
        .then((res) => res.data);
    },
    loadJiraIds({ id: projectId }) {
      return axios
        .get(`/v2/project/${projectId}/link`)
        .then((res) => res.data?.issues);
    },
    loadWorkflow({ id: projectId }) {
      return axios
        .get(`/v2/project/${projectId}/workflow`)
        .then((res) => res.data);
    },
    linkJiraId({ id: projectId }, payload) {
      return axios
        .post(`/v2/project/${projectId}/link`, payload)
        .then((res) => res.data);
    },
    unlinkJiraId({ id: projectId }) {
      return axios
        .delete(`/v2/project/${projectId}/link`)
        .then((res) => res.data);
    },
    getProjectSteps(projectId) {
      return axios
        .get(`/v2/project/${projectId}/step`)
        .then((res) => res.data);
    },
    valideProjectSteps({ id: projectId, stepId: id }) {
      return axios
          .get(`/v2/project/${projectId}/step/${id}/validate`)
          .then((res) => res.data);
    },
    setProjectSteps({ id: projectId, stepId: id }) {
      return axios
        .put(`/v2/project/${projectId}/step`, { id })
        .then((res) => res.data);
    },
    getCurrentStep(projectId) {
      return axios
        .get(`/v2/project/${projectId}/step/get-current`)
        .then((res) => res.data);
    },
    checkInceptionExcludeInvestmentOrder(projectId, investmnetOrderIds) {
      return axios
          .post(`/v2/project/${projectId}/inception/check-exclude-investment-order`, investmnetOrderIds)
          .then((res) => res.data);
    }
  };
}

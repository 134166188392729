import { render, staticRenderFns } from "./DataTableEpics.vue?vue&type=template&id=60519f2a&"
import script from "./DataTableEpics.vue?vue&type=script&lang=js&"
export * from "./DataTableEpics.vue?vue&type=script&lang=js&"
import style0 from "./DataTableEpics.vue?vue&type=style&index=0&id=60519f2a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VAutocomplete,VChip,VDataTable,VIcon,VListItem,VListItemContent,VListItemTitle,VProgressLinear,VRow,VTooltip})

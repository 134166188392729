<template>
  <div id="LinkedEpics" :class="viewMode ? 'mt-10' : ''">
    <h3 class="title-form">• Épicos vinculados</h3>
    <div class="grid-12">
      <div class="g-col-5">
        <label>Busca</label>
          <v-text-field
            v-model="dataTable.search"
            append-icon="mdi-magnify"
          ></v-text-field>
      </div>
      <div class="g-col-7 text-right" v-if="!viewMode">
        <v-btn
          large
          outlined
          text
          color="#5D4EE8"
          class="mr-6 mt-5"
          @click="newEpic()"
          :disabled="canPermission"
        >Cadastrar novo épico</v-btn>
        <v-btn
          large
          outlined
          color="#5D4EE8"
          class="mt-5"
          @click="linkEpic(true)"
          :disabled="canPermission"
        >Vincular épico</v-btn>
      </div>
    </div>
    <v-data-table
      :headers="dataTable.headers"
      :items="epics"
      :items-per-page="epics.length"
      :search="dataTable.search"
      :no-data-text="'Não há nenhum épico vinculado à este macro tema'"
      :no-results-text="'Nenhum épico encontrado'"
      class="data-table-linked-epics"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <p>ID do épico</p>
        <h3>{{ item.id }}</h3>
      </template>

      <template v-slot:item.name="{ item }">
        <p>Nome do épico</p>
        <h3>{{ item.name }}</h3>
      </template>

      <template v-slot:item.statusName="{ item }">
        <p>Status do épico</p>
        <h3>{{ item.statusName }}</h3>
      </template>

      <template v-slot:item.stageName="{ item }">
        <p>Estágio do épico</p>
        <h3>{{ item.stageName }}</h3>
      </template>

      <template v-slot:item.priority="{ item }">
        <p>Opções</p>
        <v-btn
          icon
          color="#6D6E70"
          :disabled="canPermission"
          v-if="viewMode"
          @click="goToEpic(item.id)">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn
          icon
          color="#FF502D"
          :disabled="canPermission"
          v-else
          @click="unLinkEpic(true, item.id)">
          <v-icon>mdi-link-off</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-if="!viewMode"
      v-model="dialog.show"
      width="500">
      <template v-if="dialog.type === 'linkEpic'">
        <v-card class="link-epic-modal">
          <div class="header-link-epic-modal">
            <h3>{{ dialog.title }}</h3>
            <p>{{ dialog.text }}</p>
            <v-btn icon @click="dialog.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="body-link-epic-modal">
            <label>Épicos</label>
            <v-select
              v-model="epicToLink"
              :items="allEpics"
              item-text="name"
              item-value="id"
              :no-data-text="'Nenhum épico encontrado'"
              :no-results-text="'Nenhum épico encontrado'"
            ></v-select>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#6A5AFF" @click="linkEpic()" large style="color: #FFFFFF;">
              Vincular
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <template v-else-if="dialog.type === 'unLinkEpic'">
        <v-card class="link-epic-modal">
          <div class="header-link-epic-modal">
            <h3>{{ dialog.title }}</h3>
            <p>{{ dialog.text }}</p>
            <v-btn icon @click="dialog.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="body-link-epic-modal">
            <label>Macro Temas</label>
            <v-select
              v-model="subProjectIdSelected"
              :items="subProjects"
              item-text="name"
              item-value="id"
            ></v-select>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#6A5AFF" @click="unLinkEpic(false, epicToLink)" large style="color: #FFFFFF;">
              Vincular
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <template v-else>
        <v-card>
          <v-card-title class="">
            {{ dialog.title }}
          </v-card-title>
          <v-card-text>
            {{ dialog.text}}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog.show = false" text large class="my-4 mr-2">
              Fechar
            </v-btn>
            <v-btn color="#6A5AFF" @click="save()" large style="color: #FFFFFF;">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import inceptionServiceFactory from '@/domains/project/services/inceptionService.js';

const InceptionService = inceptionServiceFactory();

export default {
  props: {
    epics: {
      type: Array,
      default: () => []
    },
    subProjects: {
      type: Array,
      default: () => []
    },
    subProjectId: {
      type: Number,
      default: 0
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    canPermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allEpics: [],
      epicToLink: null,
      subProjectIdSelected: null,
      dialog: {
        show: false,
        title: "",
        text: "",
        type: ""
      },
      dataTable: {
        search: null,
        headers: [
          {
            text: 'ID do épico',
            align: 'start',
            value: 'id',
          },
          {
            text: 'Nome do épico',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Status do épico',
            align: 'start',
            value: 'statusName',
          },
          {
            text: 'Estágio do épico',
            align: 'start',
            value: 'stageName',
          },
          {
            text: 'Opções',
            align: '',
            value: 'priority',
          },
        ]
      }
    }
  },
  created() {
    if (!this.viewMode) {
      this.loadEpicList();
    }
  },
  methods: {
    newEpic() {
      this.dialog.title = "Você está saindo da iniciativa";
      this.dialog.text = "Deseja salvar as alterações antes de prosseguir?";
      this.dialog.type = "newEpic";
      this.dialog.show = true;
    },
    linkEpic(dialogAcion = false) {
      if (dialogAcion) {
        this.epicToLink = null;
        this.dialog.title = "Vincular épico";
        this.dialog.text = "Selecione o épico que deseja vincular";
        this.dialog.type = "linkEpic";
        this.dialog.show = true;
      } else {
        this.linkEpicToProject()
      }
    },
    unLinkEpic(dialogAction = false, epicId) {
      this.epicToLink = epicId;
      if (dialogAction) {
        this.subProjectIdSelected = null;
        this.dialog.title = "Desvincular épico";
        this.dialog.text = "Selecione outro macro tema para o épico se vincular";
        this.dialog.type = "unLinkEpic";
        this.dialog.show = true;
      } else {
        this.linkEpicToProject(true)
      }
    },
    async linkEpicToProject(unlink = false) {
      try {
        this.$showLoading();
        const result = await InceptionService.linkEpicToProject(this.$route.params.id, unlink ? this.subProjectIdSelected : this.subProjectId, this.epicToLink);
        this.$showSuccess(result.data.message);
        this.dialog.show = false;
        this.$emit('updateInceptionProjectList', true);
      } catch (error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    },
    async loadEpicList() {
      try {
        this.$showLoading();
        const result = await InceptionService.loadEpicsList(this.$route.params.id);
        this.allEpics = result.data.epics;
      } catch (error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    },
    save() {
      this.$emit('save', {
        redirect: true,
        subProjectId: this.subProjectId
      });
    },
    goToEpic(epicId) {
      this.$router.push(`/projeto/${this.$route.params.id}/epicos?id=${epicId}`);
    }
  }
}
</script>

<style lang="scss">
#LinkedEpics {
  .title-form {
    font-weight: 600;
    font-size: 15px;
    color: #6D6E70;
    margin-bottom: 32px;
  }

  .data-table-linked-epics {
    &.v-data-table {
      .v-data-table__wrapper {
        table {
          thead {
            display: none;
          }

          tbody {
            tr {
              background-color: #F7F7F7;

              td {
                color: #6D6E70 !important;
                font-weight: 500 !important;
                padding: 12px !important;
                height: auto !important;
                border-bottom: 4px solid #ffffff !important;

                p {
                  margin-bottom: 10px;
                  font-weight: 400;
                  font-size: 10px;
                  color: #5D5D5D;
                }

                h3 {
                  font-weight: 600;
                  font-size: 14px;
                  color: #6D6E70;
                }
              }
            }
          }
        }
      }
    }
  }
}

.link-epic-modal {
  .header-link-epic-modal {
    padding: 34px 30px 0px;
    position: relative;

    h3 {
      font-weight: 600;
      font-size: 18px;
      color: #5D5D5D;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #929497;
    }

    button {
      position: absolute;
      right: 30px;
      top: 24px;
    }
  }

  .body-link-epic-modal {
    padding: 0px 30px 34px;
  }

  .v-card__actions {
    padding: 16px 30px !important;
  }
}
</style>
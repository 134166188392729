import VvLoading from "@/components/VvLoading";
import VvFilter from "@/components/VvFilter";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

import subprojectServiceFactory from "@/domains/subProject/services/subProjectService";

const subprojectService = subprojectServiceFactory();

export default {
  name: "Listing",
  components: {
    VvLoading,
    VvFilter,
    VvBreadcrumbs,
  },
  data: () => ({
    isLoading: false,
    search: "",
    headers: [
      {
        text: "ID Macro tema",
        value: "subprojectId",
        align: "center",
      },
      {
        text: "Nome macro tema",
        value: "subprojectName",
        align: "center",
      },
      {
        text: "Área Responsável",
        value: "areaName",
        align: "center",
      },
      {
        text: "Quarter Inicial",
        value: "startQuarter",
        align: "center",
      },
      {
        text: "Quarter Final",
        value: "endQuarter",
        align: "center",
      },
      {
        text: "Tags",
        value: "tags",
        align: "tags",
      },
      {
        text: "Status macro tema",
        value: "statusName",
        align: "statusName",
      },
    ],
    subprojects: [],
    headerProps: {
      sortByText: "Ordenar por",
    },
    footerProps: {
      'items-per-page-text': 'Linhas por página',
      'items-per-page-options': [5, 10, 15, 30, 50, 100],
    },
    options: {
      itemsPerPage: 15,
    },
    serverItemsLength: 0,
    currentPage: 1,
    exportUrl: 'v2/report/subproject',
    filterName: 'subproject',
    fields: [],
    filterOptions: {
      drivers: [],
      status: [],
      areas: [],
      startQuarter: [],
      endQuarter: [],
      tags: [],
    },
    payload: {},
    breadcrumb: [
      {
        text: 'Listagens',
        disabled: true,
      },
      {
        text: 'Macro Temas',
        disabled: false,
      },
    ],
  }),
  watch: {
    options: {
      handler() {
        this.fetchDatatable();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.isLoading = true;
      try {
        const resultList = await subprojectService.loadList(this.payload);
        this.subprojects = resultList.data;
        this.currentPage = resultList.current_page;
        this.serverItemsLength = resultList.total;
      } catch (error) {
        console.error(error.message);
        this.$showError(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    redirectToSubproject(item) {
      const url = `/projeto/${item.projectId}/visualizar?subprojectId=${item.subprojectId}`;
      this.$router.push(url);
    },
    async mountValuesFilterFields() {
      try {
        const resultOptions = await subprojectService.loadOptionsProjects();
        this.filterOptions.status = resultOptions.options.status;
        this.filterOptions.drivers = resultOptions.options.drivers;
        this.filterOptions.areas = resultOptions.options.areas;
        this.filterOptions.quarters = resultOptions.options.quarters;
        this.filterOptions.tags = resultOptions.options.tags;
      } catch (error) {
        console.error(error);
        this.$showError(error);
      } finally {
        this.createFilterFields();
      }
    },
    filter(payload) {
      this.payload = payload;
      this.setPagination(payload.page);
      this.initialize();
    },
    reset() {
      this.payload = {};
      this.setPagination();
      this.initialize();
    },
    async fetchDatatable() {
      this.isLoading = true;
      this.setPagination();
      this.initialize();
    },
    setPagination(page) {
      this.payload['page'] = (page) ? page : this.options.page;
      this.payload['pageSize'] = this.options.itemsPerPage;
      this.payload['sortBy'] = this.options.sortBy[0];
      this.payload['sortDesc'] = 0;

      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.payload['sortDesc'] = this.options.sortDesc[0] ? 1 : 0;
      }
    },
    createFilterFields(){
      this.fields = [
        {
          "type": "number",
          "label": "ID macro tema",
          "sm": "4",
          "id": "subprojectId",
          "name": "subprojectId",
          "payloadBackend": "subprojectId[eq]",
          "items": [],
        },
        {
          "type": "text",
          "label": "Nome macro tema",
          "sm": "4",
          "id": "subprojectName",
          "name": "subprojectName",
          "payloadBackend": "subprojectName[like]",
          "items": [],
        },
        {
          "type": "multiautocomplete",
          "label": "Área Responsável",
          "sm": "4",
          "id": "areas",
          "name": "areas",
          "payloadBackend": "project[areasId][or]",
          "items": this.filterOptions.areas,
        },
        {
          "type": "select",
          "label": "Quarter Inicial",
          "sm": "4",
          "id": "starterQuarter",
          "name": "starterQuarter",
          "payloadBackend": "startQuarterId[or][]",
          "items": this.filterOptions.quarters,
        },
        {
          "type": "select",
          "label": "Quarter Final",
          "sm": "4",
          "id": "endQuarter",
          "name": "endQuarter",
          "payloadBackend": "endQuarterId[or][]",
          "items": this.filterOptions.quarters,
        },
        {
          "type": "multiautocomplete",
          "label": "Tags",
          "sm": "4",
          "id": "tags",
          "name": "tags",
          "payloadBackend": "tags[tagsId][or]",
          "items": this.filterOptions.tags,
        },
        {
          "type": "multiautocomplete",
          "label": "Status macro tema",
          "sm": "4",
          "id": "subprojectStatus",
          "name": "subprojectStatus",
          "payloadBackend": "status[id][or]",
          "items": this.filterOptions.status,
        },
      ];

      const fieldsStore = this.getGlobalFilters(this.filterName);
      if (fieldsStore !== undefined) {
        let payload = {};
        this.fields = fieldsStore;
        this.fields.map(field => {
          if (field.value) {
            payload[field.payloadBackend] = field.value;
          }
        });
        if (Object.keys(payload).length) {
          payload.page = 1;
          this.filter(payload);
        }
      }
    },
  },
  mounted() {
    this.mountValuesFilterFields();
  }
}
import notificationServiceFactory from "../../services/notificationService";

const notificationService = notificationServiceFactory();

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: () => ({
                id: null,
                isRead: false,
                moduleId: null,
                moduleName: "",
                notificationTypeId: null,
                notificationTypeName: "",
                message: "",
                route: "",
                hasDownload: false,
                createdAt: ""
            })
        },
        origin: {
            type: String,
            default: ''
        }
    },
    methods: {
        async changeNotificationStatus(status, data) {
            if (this.origin === 'page') {
                this.$emit('changeNotificationStatus', status, data.id);
                this.$emit('input', false);
            } else {
                let payload = {
                    status: status,
                    notifications: [ data.id ]
                };
    
                try {
                    this.$showLoading();
                    await notificationService.changeNotificationStatus(payload);
                    this.$showSuccess('Notificação atualizada com sucesso!');
                } catch (error) {
                    this.$showError(error);
                } finally {
                    this.$hideLoading();
                    this.$emit('input', false);
                }
            }
        },
        async deleteNotifications(data) {
            if (this.origin === 'page') {
                this.$emit('deleteNotifications', data.id);
                this.$emit('input', false);
            } else {
                let payload = {
                    notifications: [ data.id ]
                };
    
                try {
                    this.$showLoading();
                    await notificationService.deleteNotifications(payload);
                    this.$showSuccess('Notificação excluída com sucesso!');
                } catch (error) {
                    this.$showError(error);
                } finally {
                    this.$emit('input', false);
                    this.$hideLoading();
                }
            }
        }
    }
}
<template>
  <v-container :fluid="true">

    <vv-loading :is-loading="isLoading"></vv-loading>

        <vv-breadcrumbs :items-breadcrumbs="breadcrumb" />

          <vv-filter :export-api="exportProject" :filter-name="filterName" :fields="fields" @filter="filter" @reset="reset" :notification-export="true"></vv-filter>

          <div class="iniciativas-vision-card grid-12">

            <h2 class="title-form g-col-11">Listagem Iniciativas</h2>

            <v-btn
                outlined
                class="btn-cadastrar g-col-1"
                color="primary"
                @click="createItem(urlCreate)"
                :disabled="!canCreateInitiative"
            >
              <v-icon small>mdi-plus</v-icon>
              Cadastrar
            </v-btn>

            <v-data-table
                id="listDataTable"
                sort-by="project_id"
                :headers="headers"
                :items="projects"
                :search="search"
                :mobile-breakpoint="0"
                :header-props="headerProps"
                :footer-props="footerProps"
                class="cursor icon-down g-col-12"
                @click:row="editItem"
                :options.sync="options"
                :server-items-length="serverItemsLength"
                :page.sync =  currentPage
            >

              <template #footer.page-text="props">
                {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
              </template>

              <template v-slot:no-data>
                <v-btn
                    class="ma-2"
                    outlined
                    color="primary"
                    :loading="isLoading"
                    @click="initialize"
                >
                  Recarregar
                </v-btn>
              </template>
            </v-data-table>
          </div>

    <v-dialog
        v-model="dialogMessage"
        hide-overlay
        persistent
        width="500"
    >
      <v-alert
          dense
          :type="typeMessage"
          style="margin: 0;"
      >
        <span v-html="textMessage"></span>
      </v-alert>
    </v-dialog>

  </v-container>
</template>

<style lang="scss" scoped>

.iniciativas-vision-card {
  background-color: white;
  padding: 24px 30px;
  border-radius: 6px;
  margin-top: 20px;


  h2 {
    color: #5D5D5D;
    font-size: 18px;
    font-weight: bold;
  }

  .title_colunm{
    font-weight: bold !important;
    text-align: center !important;
  }
}
.btn-cadastrar {
  min-width: 172px !important;
  max-width: 172px !important;
  margin-left: 8px;
}
.not-allowed {
  cursor: not-allowed;
}
</style>

<script>
import VvLoading from '@/components/VvLoading'
import VvFilter from '@/components/VvFilter'
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";

export default {
  components: {
    VvLoading,
    VvBreadcrumbs,
    VvFilter
  },
  data: () => ({
    payload: {},
    isLoading: true,
    urlCreate: '/projeto/criar',
    dialogMessage: false,
    typeMessage: 'success',
    textMessage: '',
    search: '',
    listProjectUrl: 'v2/project',
    exportProject: 'v2/report/project',
    headerProps: {
      sortByText: "Ordenar por"
    },
    footerProps: {
      'items-per-page-text': 'Linhas por página',
      'items-per-page-options': [5, 10, 15, 30, 50, 100]
    },
    options: {
      itemsPerPage: 15,
    },
    serverItemsLength: 0,
    headers: [
      {text: 'ID Iniciativa Jira', value: 'idJira', align: 'center'},
      {text: 'ID Iniciativa', value: 'projectId', align: 'center'},
      {text: 'Nome da Iniciativa', value: 'name', width: '50%', class:'cabecalho'},
      {text: 'Área responsável', value: 'areaName', class:'cabecalho'},
      {text: 'Ordem de Investimento', value: 'investmentOrderConcat', align: 'center', class:'cabecalho'},
      {text: 'Etapa da Iniciativa', value: 'currentStepName', class:'cabecalho'},
    ],
    projects: [],
    filterOptions: {
      steps: [],
      projectTypes: [],
      areas: [],
      specialProject: [],
      impacts: [],
      drivers: [],
      inceptionDrivers: [],
    },
    objectIndex: -1,
    objectItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
    fields:[],
    currentPage: 1,
    project_types: [],
    responsible_areas: [],
    workflow_status: [],
    special_projects: [],
    filterName: 'project',
    breadcrumb: [
      {
        text: 'Listagens',
        disabled: true,
      },
      {
        text: 'Iniciativas',
        disabled: false,
      },
    ],
    canCreateInitiative: false
  }),

  watch: {
    dialogMessage(val) {
      if (!val) return

      setTimeout(() => (
          this.dialogMessage = false, this.textMessage = '', this.typeMessage = 'success'
      ), 2500)
    },

    options: {
      handler() {
        this.fetchDatatable()
      },
      deep: true,
    },
  },
  created() {
    this.mountValuesFilterFields()
  },

  methods: {
    initialize() {
      this.isLoading = true;
      if(this.$store.getters.userToken) {
        this.axios.get(this.listProjectUrl, {
              params: this.payload
            })
            .then((response) => {
              this.projects = response.data.data;
              this.currentPage = response.data.current_page;
              this.serverItemsLength = response.data.total;
            })
            .catch((error) => {
              let messagesError = '<ul>';
              Object.keys(error.response.data).forEach((key) => {
               messagesError += `<li>${error.response.data[key]} </li>`;
              });
              messagesError += '</ul>';

              this.dialogMessage = true
              this.textMessage = messagesError
              this.typeMessage = 'error'
            })
            .finally(() => {
              this.isLoading = false
            })
      } else {
        setTimeout(() => {
          this.initialize();
        }, 100)
      }
    },

    formatDate(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');

      return `${day}/${month}/${year}`;
    },

    mountValuesFilterFields() {
      this.axios
          .get("v2/options/project-list")
          .then((response) => {
            this.filterOptions = response.data.options;
            this.canCreateInitiative = response.data.canCreateInitiative;
            this.createFilterFields();
          })
          .catch((error) => {
              console.log(error);
              this.dialogMessage = true
              this.textMessage = error
              this.typeMessage = 'error'
          });
    },
    editItem(item) {
      let url = `/projeto/${item.projectId}`;
      this.$router.push(url);
    },
    createFilterFields(){
      this.fields = [
        {
          "type": "text",
          "label": "ID Iniciativa Jira",
          "sm": "4",
          "md": "4",
          "id": "idJira",
          "name": "idJira",
          "payloadBackend": "idJira[eq]",
          "items": []
        },
          {
            "type": "number",
            "label": "ID Iniciativa",
            "sm": "4",
            "id": "projectId",
            "name": "projectId",
            "payloadBackend": "projectId[eq]",
            "items": []
          },
          {
            "type": "text",
            "label": "Nome Iniciativa",
            "sm": "4",
            "id": "name",
            "name": "name",
            "payloadBackend": "name[like]",
            "items": []
          },
          {
            "type": "multiautocomplete",
            "label": "Etapa da Iniciativa",
            "sm": "4",
            "id": "stepId",
            "name": "stepId",
            "payloadBackend": "currentStepId[or]",
            "items": this.filterOptions.steps
          },
          {
            "type": "multiautocomplete",
            "label": "Área Responsável",
            "sm": "4",
            "id": "areaId",
            "name": "areaId",
            "payloadBackend": "areaId[or]",
            "items": this.filterOptions.areas
          },
          {
            "type": "number",
            "label": "Ordem de Investimento",
            "sm": "4",
            "id": "investmentOrder",
            "name": "investmentOrder",
            "payloadBackend": "investmentOrder[investmentOrder][eq]",
            "items": []
          },
        ];

      let fieldsStore = this.getGlobalFilters(this.filterName);
      if (fieldsStore !== undefined) {
        this.fields = fieldsStore;

        let payload = {};

        this.fields.map(field => {
          if (field.value) {
            payload[field.payloadBackend] = field.value
          }
        });
        if (Object.keys(payload).length) {
          payload.page = 1;
          this.filter(payload);
        }
      }
    },

    filter(payload) {
      this.payload = payload;
      this.setPagination(payload.page);
      this.initialize();
    },

    reset() {
      this.payload = {};
      this.setPagination();
      this.initialize();
    },

    fetchDatatable: async function() {
      this.isLoading = true;
      this.setPagination();
      this.initialize();
    },

    createItem(urlCreate) {
      this.$router.push(urlCreate);
    },

    setPagination(page) {
      this.payload['page'] = (page) ? page : this.options.page;
      this.payload['pageSize'] = this.options.itemsPerPage;
      this.payload['sortBy'] = this.options.sortBy[0];
      this.payload['sortDesc'] = 0;

      if (this.options.sortBy.length && this.options.sortDesc.length) {
        this.payload['sortDesc'] = this.options.sortDesc[0] ? 1 : 0;
      }
    }
  },
}
</script>



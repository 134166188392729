<template>
  <v-container>
    <vv-loading :is-loading="isLoading"></vv-loading>
    <header-view></header-view>

    <v-card>
      <v-card-title>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="list.headers"
          :items="list.projects.data"
          :search="search"
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
          no-data-text="Nenhum Registro Encontrado"
          class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <div class="d-flex">
            <v-btn
                class="ma-1 pa-0"
                text
                color="warning"
                min-width="40"
                @click="$router.push(`/projeto/${item.id}`)"
            >
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
            <v-btn
                class="ma-1 pa-0"
                text
                :disabled="checkStatus(item.workflow_id)"
                color="secondary"
                min-width="40"
                @click="modalApproved(item)"
            >
              <v-icon> mdi-check-circle-outline </v-icon>
            </v-btn>
            <v-btn
                class="ma-1 pa-0"
                text
                :disabled="checkStatus(item.workflow_id)"
                color="error"
                min-width="40"
                @click="modalDisapproved(item)"
            >
              <v-icon> mdi-close-circle-outline </v-icon>
            </v-btn>
            <v-btn
                class="ma-1 pa-0"
                text
                :disabled="checkStatus(item.workflow_id, 'doubt', item.user_requester)"
                color="info"
                min-width="40"
                @click="modalDoubt(item)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!-- Modal de Aprovação -->
    <v-dialog v-model="dialog.approved" width="500" persistent>
      <v-card>
        <v-card-title> Modal de Aprovação </v-card-title>

        <v-card-text class="mt-5 mb-3">
          Você realmente deseja aprovar essa TAP?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click="closeModal"> Fechar </v-btn>
          <v-btn color="primary" @click="approved"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de Reprovação -->
    <v-dialog v-model="dialog.disapproved" width="500" persistent>
      <v-card>
        <v-card-title> Modal de Reprovação </v-card-title>

        <v-card-text class="mt-3">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    name="input-7-1"
                    label="Justificativa da reprovação da TAP"
                    v-model="justify_disapproved"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click="closeModal"> Fechar </v-btn>
          <v-btn color="error" @click="disapproved"> Reprovar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de Dúvidas -->
    <v-dialog v-model="dialog.doubt" width="500" persistent>
      <v-card>
        <v-card-title> Modal de Dúvidas </v-card-title>

        <v-card-text class="mt-3">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    name="input-7-1"
                    counter
                    label="Escreva a sua dúvida sobre a TAP"
                    v-model="question"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" text @click="closeModal"> Fechar </v-btn>
          <v-btn color="info" @click="doubt"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de erro -->
    <v-dialog v-model="dialog.error" width="500" persistent>
      <v-card>
        <v-card-title> Modal de Erro </v-card-title>

        <v-card-text class="mt-5 mb-3">
          {{ error.message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeModal"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <vv-priority
        v-if="modal_item_area_open"
        @changePriority="reloadWorkflow"
        :area_name="modal_item_area_name"
        :area_id="modal_item_area_id"
        :showButton="false"
    ></vv-priority>
  </v-container>
</template>
<script>
import HeaderView from "@/components/HeaderView";
import VvLoading from "@/components/VvLoading";
import VvPriority from "@/components/VvPriority";

export default {
  components: {
    HeaderView,
    VvLoading,
    VvPriority,
  },
  data: () => ({
    isLoading: true,
    search: "",
    dialog: {
      approved: false,
      disapproved: false,
      doubt: false,
      error: false,
    },
    error: {
      message: "",
    },
    modal_item: 0,
    modal_item_area_id: 0,
    modal_item_area_name: "",
    modal_item_area_open: false,
    justify_disapproved: "",
    question: "",
    list: {
      headers: [
        {
          text: "ID Iniciativa",
          value: "id",
          sortable: false,
          align: "center",
        },
        {
          text: "Nome do Macro Tema",
          value: "project_name",
          sortable: false,
          align: "center",
        },
        {
          text: "Área Responsável",
          value: "area_name",
          sortable: false,
          align: "center",
        },
        {
          text: "Prioridade",
          value: "priority",
          sortable: true,
          align: "center",
        },
        {
          text: "Solicitante",
          value: "user_name",
          sortable: false,
          align: "center",
        },
        {
          text: "Data de Criação",
          value: "created_at",
          sortable: false,
          align: "center",
        },
        {
          text: "Aprovado Gestor",
          value: "approvedManager",
          sortable: false,
          align: "center",
        },
        {
          text: "Aprovado Diretor",
          value: "approvedDirector",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "20%",
          align: "center",
        },
      ],
      projects: [],
    },
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize: function () {
      this.axios
          .get("workflow", {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.list.projects = response.data;
          })
          .catch((error) => {
            this.dialogMessage = true;
            this.textMessage = error;
            this.typeMessage = "error";
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    modalApproved(item) {
      this.dialog.approved = true;
      this.modal_item = item.id;
      this.modal_item_area_id = item.area_id;
      this.modal_item_area_name = item.area_name;
    },
    approved() {
      if (
          this.modal_item > 0 &&
          this.modal_item_area_name != "" &&
          this.modal_item_area_id > 0
      ) {
        this.isLoading = true;
        let submit = { project_id: this.modal_item };
        this.axios
            .post("workflow/pass", submit)
            .then((response) => {
              if (response.data.workflow_id === this.CONST_WORKFLOW.APROVADO_DIRETOR) {
                this.modal_item_area_open = true;
              } else {
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
              console.log("Aprovado", response);
            })
            .catch((error) => {
              this.error.message = error.response.data.message;
              this.dialog.error = true;
            })
            .finally(() => {
              this.isLoading = false;
            });
      }
      this.closeModal();
    },

    modalDisapproved(item) {
      this.dialog.disapproved = true;
      this.modal_item = item.id;
    },
    disapproved() {
      if (this.justify_disapproved.trim() !== "") {
        if (this.modal_item > 0) {
          this.isLoading = true;
          let submit = {
            project_id: this.modal_item,
            message: this.justify_disapproved,
          };
          this.axios
              .post("workflow/fail", submit)
              .then((response) => {
                setTimeout(() => {
                  window.location.reload();
                }, 500);
                console.log("Reprovado", response);
              })
              .catch((error) => {
                this.error.message = error.response.data.message;
                this.dialog.error = true;
              })
              .finally(() => {
                this.isLoading = false;
              });
        }
        this.closeModal();
      }
    },

    modalDoubt(item) {
      this.dialog.doubt = true;
      this.modal_item = item.id;
    },
    doubt() {
      if (this.question.trim() !== "") {
        if (this.modal_item > 0) {
          this.isLoading = true;
          let submit = {
            project_id: this.modal_item,
            message: this.question,
          };
          let endpoint = `project/${submit.project_id}/message`;
          this.axios
              .post(endpoint, submit)
              .then((response) => {
                setTimeout(() => {
                  window.location.reload();
                }, 500);
                console.log("Duvida enviada", response);
              })
              .catch((error) => {
                this.error.message = error.response.data.message;
                this.dialog.error = true;
              })
              .finally(() => {
                this.isLoading = false;
              });
        }
      }
      this.closeModal();
    },

    closeModal() {
      this.dialog.approved = false;
      this.dialog.disapproved = false;
      this.dialog.doubt = false;
      this.dialog.error = false;
      this.justify_disapproved = "";
      this.question = "";
      this.error.message = "";
      this.modal_item = 0;
      this.modal_item_area_name = "";
    },

    reloadWorkflow() {
      setTimeout(() => {
        this.modal_item_area_id = 0;
        window.location.reload();
      }, 2500);
    },

    checkStatus(id, action = null, user = -1) {
      if(action == 'doubt' && (this.$store.getters.id == user)) return true;
      if (this.$store.getters.type.indexOf(this.CONST_USER_TYPE.USUARIO_MASTER) > -1) {
        if (id > this.CONST_WORKFLOW.TAP_PREENCHIMENTO && id < this.CONST_WORKFLOW.APROVADO_DIRETOR) {
          return false;
        }
        return true;
      } else {
        if (
            this.$store.getters.type.indexOf(this.CONST_USER_TYPE.STAKEHOLDER) > -1 &&
            id == this.CONST_WORKFLOW.APROVADO_GESTOR
        ) {
          return false;
        }
        if (this.$store.getters.type.indexOf(this.CONST_USER_TYPE.PONTOS_FOCAIS) > -1 && id == this.CONST_WORKFLOW.APROVADO_GESTOR) {
          return true;
        }
        if (id > this.CONST_WORKFLOW.TAP_PREENCHIMENTO && id < this.CONST_WORKFLOW.APROVADO_DIRETOR) return false;
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table tr:nth-child(even) {
  background: #efefef;
}

.v-dialog > .v-card > .v-card__title {
  font-size: 1.2rem;
}

.v-data-table {
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            &:not(.v-data-table__mobile-row) {
              &:not(:first-child) {
                border-left: thin solid rgba(0, 0, 0, 0.12);
              }
            }
          }
        }
      }
    }
  }
}
</style>

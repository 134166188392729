<template>
  <div class="set-project-step">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex justify-content">
          <div v-if="statusWorkflowEncerramento != ''">
            <label>
              Workflow Encerramento<br>
              <v-btn
                depressed
                dark
                class="mt-2 mr-6 btn-workflow-pendente"
                :class="statusWorkflowClass(statusWorkflowEncerramento)"
              >
                {{ statusWorkflowEncerramento }}
              </v-btn>
            </label>
          </div>
          <div>
            <label>
              Etapa da Iniciativa 
              <v-tooltip right max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="grey"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="mr-1"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>O Encerramento só será habilitado quando todos os épicos estiverem concluídos e/ou cancelados</span>
              </v-tooltip><br>
              <v-btn
                color="#E2DEFF"
                depressed
                dark
                :loading="loading"
                v-bind="attrs"
                v-on="on"
                class="mt-2"
                style="color: #5D4EE8"
              >
                {{ selected }}
                <v-icon right dark>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </label>
          </div>
        </div>
        
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in orderedItems"
          :key="index"
          class="menu-item"
          @click="validateStep(item)"
          :disabled="item.disabled"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="pa-3" v-bind="attrs" v-on="on">
                {{ item.name }}
                <v-icon v-if="item.active" right style="color: #5D4EE8">
                  mdi-check
                </v-icon>
              </div>
            </template>
            <span> {{ item.description }} </span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-menu>

    <vv-confimation-dialog
        v-model="confirmationModal.enable"
        :title="confirmationModal.title"
        :message="confirmationModal.message"
        :boud-message="confirmationModal.boudMessage"
        @confirmed="setStep(confirmationModal.step)"
        @denied="confirmationModal.enable=false" />
  </div>
</template>

<script>
import ProjectService from "../services/projectService";
import VvConfimationDialog from "../../../components/common/VvConfirmationDialog";
const {
  getProjectSteps,
  setProjectSteps,
  valideProjectSteps
} = ProjectService();

const sortBy = (by, cresc = true) => (a, b) => {
  const before = a[by];
  const next = b[by];
  if (before < next) return cresc ? -1 : 1;
  if (before > next) return cresc ? 1 : -1;
  return 0;
};

export default {
  name: "SetProjectStep",
  components: {VvConfimationDialog},
  comments: {
    VvConfimationDialog
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    statusWorkflowEncerramento: {
      type: String,
      default: ''
    },
    idJiraField: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    confirmationModal: {
      enable: false,
      title: "",
      message: "",
      boudMessage: "",
      isJiraAlert: false,
      step: {},
    },
    selected: "",
    items: [],
    loading: false,
  }),
  computed: {
    orderedItems() {
      const newItems = [...this.items];
      return newItems.sort(sortBy("order"));
    },
  },
  methods: {
    statusWorkflowClass(status) {
      status = status.toLowerCase(status);
      if (status.includes('aprovado')) {
        return 'btn-workflow-aprovado';
      } else if (status.includes('reprovado')) {
        return 'btn-workflow-reprovado';
      } else if (status.includes('pendente')) {
        return 'btn-workflow-pendente';
      }
    },
    async loadSteps() {
      try {
        this.loading = true;
        const { steps } = await getProjectSteps(this.id);
        this.items = steps;
        this.loadSelected();
      } catch (error) {
        this.$showError(error);
      } finally {
        this.loading = false;
      }
    },
    async validateStep({ active, id: stepId, name: name }) {
      try {
        this.loading = true;

        const validateResult = await valideProjectSteps({ id: this.id, stepId });
        if (validateResult.success) {
          this.confirmationModal.step = { active, id: stepId, name: name };
          this.confirmationModal.enable = true;
          if (typeof validateResult.alertIdJira !== 'undefined') {
            this.confirmationModal.isJiraAlert = validateResult.alertIdJira;
          }
        }
        if (stepId === this.CONST_PROJECT_STEPS.ENCERRAMENTO) {
          this.confirmationModal.title = "Alterar etapa";
          this.confirmationModal.message = "\nVocê esta submetendo a iniciativa para aprovações e encerramento. Enquanto o fluxo estiver ativo, não serão permitidas edições. Deseja continuar?";
        } else if (stepId === this.CONST_PROJECT_STEPS.CONGELADA) {
          this.confirmationModal.title = "Alterar etapa";
          this.confirmationModal.message = "\nIniciativas congeladas não permitem a edição de nenhum campo e suspendem todos os workflows ativos. Deseja continuar?";
        } else {
          this.confirmationModal.title = "Confirmação de mudança de Etapa da Iniciativa";
          this.confirmationModal.message = "\nTem certeza que deseja mover a Iniciativa para a Etapa " + name + "?";
          if (stepId === this.CONST_PROJECT_STEPS.DISCOVERY && this.confirmationModal.isJiraAlert) {
            this.confirmationModal.boudMessage = '\nVocê está salvando uma iniciativa sem ID do Jira.\n' +
                'Uma nova iniciativa será criada no Jira e associada a essa Iniciativa. Tem certeza que deseja continuar?\n'
          }
        }
      } catch (error) {
        if (typeof error.response.data.success != 'undefined') {
          if (!error.response.data.success) {
            this.$showError("Confira os campos do Formulário");
            this.$emit('setErrosChangeStep', error.response.data.errors, stepId);
          }
        } else {
          this.$showError(error.response.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async setStep(step) {
      const stepId = step.id
      try {
        this.loading = true;
        const { steps } = await setProjectSteps({ id: this.id, stepId });
        this.items = steps;
        this.$showSuccess("As Etapas foram atualizadas com sucesso!");
        this.$router.go();
      } catch (error) {
        this.$showError(error);
      } finally {
        this.loading = false;
      }
    },
    loadSelected() {
      let selected = "";
      this.items.forEach((item) => {
        if (item.active) {
          selected = item.name;
        }
      });
      this.selected = selected;
    },
  },
  mounted() {
    this.loadSteps();
  },
};
</script>

<style lang="scss">
.set-project-step {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 10px;

  @media(max-width: 1264px) {
    position: inherit;
  }
}
.menu-item {
  cursor: pointer;
  user-select: none;
}

.btn-workflow-pendente {
  background-color: #FFFAD9 !important;
  color: #837A28 !important;
}

.btn-workflow-aprovado {
  background-color: #B2DEB2 !important;
  color: #699F10 !important;
}

.btn-workflow-reprovado {
  background-color: #FFE8D5 !important;
  color: #FF502D !important;
}
</style>

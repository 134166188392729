export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        inactivationModalType: {
            type: String,
            default: 'journey'
        },
        inactivationModalData: {
            type: Object,
            default: () => ({})
        },
        inactivationModalOptions: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            lv1Id: null,
            optionsLv2: [],
            lv2Id: null,
            optionsLv3: [],
            fromTo: {}
        }
    },
    methods: {
        getOptionsLv2() {
            switch (this.inactivationModalType) {
                case 'journey':
                    return this.inactivationModalOptions.map(journey => {
                        if (journey.id === this.fromTo.newLv1Id) {
                            this.optionsLv2 = journey.tribes
                        }
                    })
                case 'tribe':
                    this.inactivationModalOptions.tribes.map(tribe => {
                        if (tribe.id === this.fromTo.newLv1Id) {
                            this.optionsLv2 = tribe.squads
                        }
                    })
            }
        },
        getOptionsLv3(id, index) {
            if (this.inactivationModalType === 'journey') {
                this.inactivationModalOptions.map(journey => {
                    journey.tribes.map(tribe => {
                        if (tribe.id === id) {
                            this.optionsLv3[index] = tribe.squads
                        }
                    })
                })
            }
        },
        createData(type, data) {
            switch (type) {
                case 'journey':
                    this.fromTo = {
                        currentLv1Id: data.id,
                        newLv1Id: null,
                        lv2: []
                    }
                    data.tribes.map((tribe, tribeIndex) => {
                        this.fromTo.lv2.push({
                            currentLv2Id: tribe.id,
                            newLv2Id: null,
                            lv3: []
                        })
                        tribe.squads.map(squad => {
                            this.fromTo.lv2[tribeIndex].lv3.push({
                                currentLv3Id: squad.id,
                                newLv3Id: null
                            })
                        })
                    })
                    break;
                case 'tribe':
                    this.fromTo = {
                        currentLv1Id: data.id,
                        newLv1Id: null,
                        lv2: []
                    }
                    data.squads.map((squad) => {
                        this.fromTo.lv2.push({
                            currentLv2Id: squad.id,
                            newLv2Id: null
                        })
                    })
                    break;
                case 'squad':
                    this.fromTo = {
                        currentLv1Id: data.id,
                        newLv1Id: null
                    }
                    break;
            }
        },
        save() {
            let fromTo = {}
            switch (this.inactivationModalType) {
                case 'journey':
                    fromTo = {
                        journey: [{
                            currentJourneyId: this.fromTo.currentLv1Id,
                            newJourneyId: this.fromTo.newLv1Id,
                            tribes: []
                        }]
                    };
                    this.fromTo.lv2.map((tribe, tribeIndex) => {
                        fromTo.journey[0].tribes.push({
                            currentTribeId: tribe.currentLv2Id,
                            newTribeId: tribe.newLv2Id,
                            squads: []
                        })
                        tribe.lv3.map(squad => {
                            fromTo.journey[0].tribes[tribeIndex].squads.push({
                                currentSquadId: squad.currentLv3Id,
                                newSquadId: squad.newLv3Id
                            })
                        })
                    })
                    break;
                case 'tribe':
                    fromTo = {
                        tribe: [{
                            currentTribeId: this.fromTo.currentLv1Id,
                            newTribeId: this.fromTo.newLv1Id,
                            squads: []
                        }]
                    };
                    this.fromTo.lv2.map((squad) => {
                        fromTo.tribe[0].squads.push({
                            currentSquadId: squad.currentLv2Id,
                            newSquadId: squad.newLv2Id
                        })
                    })
                    break;
                case 'squad':
                    fromTo = {
                        squad: [{
                            currentSquadId: this.fromTo.currentLv1Id,
                            newSquadId: this.fromTo.newLv1Id
                        }]
                    };
            }
            this.$emit('save', fromTo, this.inactivationModalType)
        }
    },
    computed: {
        showLv2() {
            return this.inactivationModalType === 'journey' || this.inactivationModalType === 'tribe';
        },
        showLv3() {
            return this.inactivationModalType === 'journey';
        },
        typeNameLv1() {
            switch (this.inactivationModalType) {
                case 'journey':
                    return 'Jornada';
                case 'tribe':
                    return 'Tribo';
                case 'squad':
                    return 'Squad';
            }
        },
        typeNameLv2() {
            switch (this.inactivationModalType) {
                case 'journey':
                    return 'Tribo';
                case 'tribe':
                    return 'Squad';
            }
        },
        content() {
            switch (this.inactivationModalType) {
                case 'journey':
                    return this.inactivationModalData.tribes;
                case 'tribe':
                    return this.inactivationModalData.squads;
            }
        },
        optionsLv1() {
            switch (this.inactivationModalType) {
                case 'journey':
                    return this.inactivationModalOptions;
                case 'tribe':
                    this.inactivationModalOptions.tribes.map((tribe, index) => {
                        if (tribe.id === this.inactivationModalData.id) {
                            this.inactivationModalOptions.tribes.splice(index, 1)
                        }
                    })
                    return this.inactivationModalOptions.tribes;
                case 'squad':
                    this.inactivationModalOptions.squads.map((squad, index) => {
                        if (squad.id === this.inactivationModalData.id) {
                            this.inactivationModalOptions.squads.splice(index, 1)
                        }
                    })
                    return this.inactivationModalOptions.squads;
            }
        },
        headerText() {
            switch (this.inactivationModalType) {
                case 'journey':
                    return 'Para realizar esta inativação, é necessário selecionar os novos responsáveis pelos épicos vinculados à Jornada inativada.';
                case 'tribe':
                    return 'Para realizar esta inativação, é necessário selecionar os novos responsáveis pelos épicos vinculados à Tribo inativada.';
                case 'squad':
                    return 'Para realizar esta inativação, é necessário selecionar o novo responsável pelos épicos vinculados à Squad inativada.';
            }
        },
        noDataTextLv2() {
            switch (this.inactivationModalType) {
                case 'journey':
                    return 'Selecione uma jornada';
                case 'tribe':
                    return 'Selecione uma tribo';
            }
        }
    }
}
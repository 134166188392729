<template>
  <div>
    <label>{{ label }}</label>
    <v-autocomplete
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
      :items="toDisableAll ? list : ordered"
      :item-text="itemText"
      :item-value="itemValue"
      :disabled="disabled"
      multiple
      append-icon="mdi-chevron-down"
      :no-data-text="noDataText"
      @input="$emit('input', $event)"
    >
      <template #selection="{ attrs, item, selected, index }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          :close="!disabled"
          :disabled="disabled"
          @click:close="remove(index)"
        >
          <slot v-if="$scopedSlots.chip" name="chip" v-bind="item" />
          <span v-else>{{ item[itemText] }}</span>
        </v-chip>
      </template>
      <template #item="{ item }">
        <div
          class="d-flex"
          style="width: 100%"
          :class="{ divider: $scopedSlots.cell }"
          @click="itemSelected(item)"
        >
          <div class="ml-4 d-flex itens-center">
            <v-icon>
              {{
                value.includes(item[itemValue])
                  ? "mdi-checkbox-marked"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
          </div>
          <v-list-item-content style="max-width: 480px">
            <slot v-if="$scopedSlots.cell" name="cell" v-bind="item" />
            <span v-else>{{ item[itemText] }}</span>
          </v-list-item-content>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
const sortBy = (by, cresc = true) => (a, b) => {
  const before = a[by]?.toLowerCase();
  const next = b[by]?.toLowerCase();
  if (before < next) return cresc ? -1 : 1;
  if (before > next) return cresc ? 1 : -1;
  return 0;
};

export default {
  name: "MultipleSelect",
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    toDisableAll: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    noDataText: {
      type: String,
      default: 'title'
    },
    showInactive: {
      type: Boolean,
    },
    autoOrder: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    filteredItems() {
      return this.items
        .filter((item) => item.active !== false || this.value.includes(item[this.itemValue]));
    },
    list() {
      const list = this.showInactive ? this.items : this.filteredItems;
      const items = list.map((el) => ({
        ...el,
        disabled: this.value.includes(this.toDisableAll)
            && el[this.itemValue] !== this.toDisableAll,
      }));
      const toDisable = items
        .find((el) => el[this.itemValue] === this.toDisableAll);
      if(!toDisable) return items;
      return [
        toDisable, 
        ...items.filter((el) => el[this.itemValue] !== this.toDisableAll),
      ];
    },
    ordered() {
      if (!this.autoOrder) {
        return this.items;
      }
      const items = this.showInactive ? this.items : this.filteredItems;
      const onTop = items
        .filter((el) => this.value.includes(el[this.itemValue]))
        .sort(sortBy(this.itemText));
      const onBottom = items
        .filter((el) => !this.value.includes(el[this.itemValue]))
        .sort(sortBy(this.itemText));
      return [
        ...onTop, 
        ...onBottom,
      ];
    },
  },
  methods: {
    remove(index) {
      const newValue = [...this.value];
      newValue.splice(index, 1);
      this.$emit("input", newValue);
    },
    itemSelected(item) {
      const isSelected = this.value
        .includes(item[this.itemValue]);
      this.$emit("selected", item);
      if (isSelected) return;
      if (item[this.itemValue] === this.toDisableAll) {
        const toEmit = this.value
          .filter((el) => el[this.itemValue] === item[this.itemValue]);
        this.$emit("input", toEmit);
      }
    },
  },
};
</script>

<style scoped>
.divider {
  border-bottom: 1px solid #ddd;
  width: 100%;
}
</style>

<template>
    <div class="alert-container" v-if="value">     
        <v-alert 
            persistent 
            :dismissible="dismissible" 
            dense 
            :type="type"
            @input="close" >
            <div class="container">    
                <div class="message" v-if="typeof message === 'string'">
                    {{ message }}
                </div>
                <div class="message" v-else>
                    <div v-for="(label,id) in message" :key="id" >
                        {{ label }}
                    </div>
                </div>
            </div>
        </v-alert>
    </div>
</template>

<script>
export default {
    name: 'VvAlert',
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        message: {
            type: [String, Array],
            default: '',
            required: true
        },
        type: {
            type: String,
            default: 'info'
        },
        dismissible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('input', false)
        }
    }
}
</script>

<style scoped>
.alert-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}

.alert-container .v-btn__content {
    margin-top: -11px important!;
}

.vv-alert-h {
    height: 40px;
}

.container {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    padding-block: 0;
}
.message {
    flex: 1;
}
</style>
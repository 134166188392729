import ClosureDetails from "../ClosureDetails/ClosureDetails.vue";
import closuresServiceFactory from "../../services/closureService";
import mixin from "@/plugins/mixin";

const closureService = closuresServiceFactory();

export default {
  name: 'Closure',
  components: {
    ClosureDetails
  },
  mixins: {
    mixin
  },
  data: () => ({
    closureId: null,
    headers: [
      {
        text: "Solicitante",
        align: "start",
        value: "requesterUser"
      },
      {
        text: "Área responsável",
        align: "start",
        value: "area",
        width: "157px"
      },
      {
        text: "Data da solicitação",
        align: "start",
        value: "date",
        width: "170px"
      },
      {
        text: "Status workflow",
        align: "start",
        value: "status",
        width: "146px"
      },
      {
        text: "Status ponto focal",
        align: "start",
        value: "focalPointStatus",
        width: "162px"
      },
      {
        text: "Status stakeholder",
        align: "start",
        value: "stakeholderStatus",
        width: "164px"
      },
      {
        text: "",
        value: "expandButton",
      },
    ],
    closures: [],
    closure: {},
    expandedClosure: [],
    toggleById: null,
  }),
  methods: {
    openDetails(row, slot) {
      if (!slot.isExpanded) {
        this.closure = {};
        this.detailClosures(row.id);
      }
      this.toggleButton(row.id);
      slot.expand(!slot.isExpanded);
    },
    toggleButton(id) {
      if (this.toggleById === id) {
        this.toggleById = null;
      } else {
        this.toggleById = id;
      }
    },
    async listClosures() {
      try {
        this.$showLoading();
        const result = await closureService.listClosures(this.$route.params.id);
        this.closures = result.data.finalizations;
      } catch (error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    },
    async detailClosures(id, byRoute = false) {
      try {
        this.$showLoading();
        const result = await closureService.detaiClosures(this.$route.params.id, id);
        this.closure = result.data.detail;
        if (byRoute) {
          this.toggleButton(id);
        }
      } catch (error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    },
    treatClass(className) {
      className = className.toLowerCase();
      return className.replace(' ', '-');
    }
  },
  watch: {
    async closures() {
      const isOnExpandedClosures = this.expandedClosure
        .map(({ id }) => id)
        .includes(this.closureId);

      const closure = this.closures
        .find(({ id }) => id === this.closureId);

      if (isNaN(this.closureId) || this.closureId === null || isOnExpandedClosures === true)
        return;

      this.expandedClosure.push(closure);
      this.detailClosures(this.closureId, true);
    }
  },
  mounted() {
    this.listClosures();
    if (typeof this.$router.currentRoute.query.closureId !== "undefined") {
      this.closureId = parseInt(this.$router.currentRoute.query.closureId);
    }
  }
}
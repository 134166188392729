<template>
  <div id="formDF">
    <vv-loading :is-loading="isLoading"></vv-loading>
    <div class="grid-12 gap-md">
      <div class="g-col-12">
        <label>Descrição épico</label>
        <v-textarea
            counter
            :disabled="true"
            filled
            class="scroll-style"
            v-model="item.epicDescription"
            :error-messages="getError('epicDescription')"
            @input="clearError('epicDescription')"
        ></v-textarea>
      </div>
      <div class="g-col-12">
        <label>Entregável (realizado)</label>
        <v-text-field
            :value="itemDeliveryDoneValue ?
              formattedDateForBr(itemDeliveryDoneValue) :
              moment().format('DD/MM/YYYY')"
            readonly
            disabled
            append-icon="mdi-calendar"
            :error-messages="getError('deliveryDone')"
            @input="clearError('deliveryDone')"
        ></v-text-field>
      </div>
      <div class="g-col-12">
        <h3 class="pb-6">Formalização</h3>
        <label>Comentários Sobre a Entrega</label>
        <v-textarea
            counter
            :disabled="false"
            filled
            class="scroll-style"
            v-model="form.comment"
            :error-messages="getError('comment')"
            @input="clearError('comment')"
        ></v-textarea>
      </div>
      <div class="g-col-12">
        <vv-file-input
            label="Evidencias"
            :formats="['pdf', 'txt', 'xls', 'xlsx', 'ppt', 'pptx', 'jpeg', 'jpg', 'png', 'zip']"
            v-model="form.evidence"
            ref="vvFileInput"
            :errors="getError('evidence')"
            @input="clearError('evidence')"
        ></vv-file-input>
      </div>
      <div class="g-col-6">
        <label>{{item.epicTypeId === CONST_TYPE_EPICS.NEGOCIO ? 'Ponto focal iniciativa': 'Ponto Focal da Área Negócio (Iniciativa)'}}</label>
        <v-autocomplete
            :disabled="false"
            v-model="form.focalPointResponsibleArea"
            :items="focalPointsTap"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            no-data-text="Sem ponto focal para aprovação"
            :error-messages="getError('focalPointResponsibleArea')"
            @input="clearError('focalPointResponsibleArea')"
        ></v-autocomplete>
      </div>
      <div class="g-col-6" v-if="!verifySameArea()">
        <label>{{item.epicTypeId === CONST_TYPE_EPICS.NEGOCIO ? 'Ponto focal épico': 'Ponto Focal da Área Negócio (Entrega)'}}</label>
        <v-autocomplete
            :disabled="verifySameArea()"
            v-model="epicFocalPointOrDemandingAreaForPayload"
            :items="item.epicTypeId === CONST_TYPE_EPICS.NEGOCIO ? item.focalPointsOptions : option.focalPointsResponsibleArea"
            item-text="name"
            item-value="id"
            append-icon="mdi-chevron-down"
            no-data-text="Sem ponto focal para aprovação"
            :error-messages="getError('epicFocalPointId') || getError('focalPointDemandingArea')"
            @input="clearError('focalPointResponsibleArea') || clearError('focalPointDemandingArea')"
        ></v-autocomplete>
      </div>
    </div>
    <vv-confimation-dialog
        :value="confirmationDialog.value"
        :title="confirmationDialog.title"
        :message="confirmationDialog.message"
        @confirmed="saveDeliveryFormalization"
        @denied="confirmationDialog.value=false"
    ></vv-confimation-dialog>
    <vv-alert
        v-model="alert.show"
        :message="alert.message"
        :type="alert.type"
        :dismissible="alert.dismissible"
    ></vv-alert>
  </div>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import VvFileInput from "@/components/common/VvFileInput";
import VvConfimationDialog from "@/components/common/VvConfirmationDialog";
import VvAlert from "@/components/common/VvAlert";
import deliveryFormalizationServiceFactory from "@/domains/deliveryFormalization/services/deliveryFormalizationService";

const deliveryFormalizationService = deliveryFormalizationServiceFactory();

export default {
  name: "FormDeliveryFormalization",
  components: {
    VvLoading,
    VvFileInput,
    VvConfimationDialog,
    VvAlert,
  },
  props: {
    item: {
      type: Object,
    },
    options: {
      type: Object,
    },
    focalPointsTap: {
      type: Array,
    },
    projectArea: {
      type: Number,
    },
  },
  data: () => ({
    moment: require("moment"),
    isLoading: false,
    form: {
      epicDescription: null,
      comment: null,
      assistedOperation: false,
      baseline: null,
      assistedOperationBaseline: null,
      evidence: [],
      focalPointDemandingArea: '',
      focalPointResponsibleArea: null,
      epicFocalPointId: null,
      epicFocalPointOrDemandingAreaForPayload: null
    },
    errors: {},
    option: {
      focalPointsResponsibleArea: []
    },
    confirmationDialog: {
      value: false,
      title: 'Concluir entrega',
      message: 'Quando o entregável for aprovado na formalização de entrega, estando com status e estágio concluídos, ele não receberá mais atualizações do Jira. \n\nVocê realmente deseja submeter a entrega?',
    },
    alert: {
      show: false,
      message: '',
      type: 'info',
      dismissible: false,
    },
    errorList: {}
  }),
  watch: {
    watch_comment() { this.errors.comment = []; },
    watch_evidence() { this.errors.evidence = []; },
    watch_assistedOperation() { this.errors.assistedOperation = []; },
    watch_assistedOperationBaseline() { this.errors.assistedOperationBaseline = []; },
    watch_focalPointDemandingArea() { this.errors.focalPointDemandingArea = []; },
    watch_focalPointResponsibleArea() { this.errors.focalPointResponsibleArea = []; },
    watch_epicFocalPointId() { this.errors.epicFocalPointId = []; },
    epicFocalPointOrDemandingAreaForPayload() { this.errors.epicFocalPointOrDemandingAreaForPayload = []; },
  },
  computed: {
    itemDeliveryDoneValue() {
      return this.item.deliveryDone?.value;
    },
    watch_comment() { return this.form.comment },
    watch_evidence() { return this.form.evidence },
    watch_assistedOperation() { return this.form.assistedOperation },
    watch_assistedOperationBaseline() {  return this.form.assistedOperationBaseline },
    watch_focalPointDemandingArea() { return this.form.focalPointDemandingArea },
    watch_focalPointResponsibleArea() { return this.form.focalPointResponsibleArea },
    watch_epicFocalPointId() { return this.form.epicFocalPointId },

    epicFocalPointOrDemandingAreaForPayload: { 
      get() {
        return this.form.epicFocalPointOrDemandingAreaForPayload;
      },
      set(newValue) {
        if (this.item.epicTypeId === this.CONST_TYPE_EPICS.NEGOCIO) {
          return this.form.epicFocalPointId = newValue;
        }
        return this.form.focalPointDemandingArea = newValue;
      }
    },
  },
  mounted() {
    this.focalPointsDemandingArea();
  },
  methods: {
    closeModal() {
      this.form.epicDescription = null;
      this.form.comment = null;
      this.form.assistedOperation = false;
      this.form.baseline = null;
      this.form.assistedOperationBaseline = null;
      this.form.evidence = [];
      this.form.focalPointDemandingArea = '';
      this.form.focalPointResponsibleArea = null;
      this.form.epicFocalPointId = null;
      this.$refs.vvFileInput.clearAllFiles();
      this.$emit('deliveryFormalizationsSaved')
    },
    async saveDeliveryFormalization() {
      this.confirmationDialog.value = false;
      this.isLoading = true;

      let formData = new FormData();

      formData.append('epicDescription', this.form.epicDescription);
      formData.append('deliveryDone', this.itemDeliveryDoneValue);
      formData.append('comment', this.form.comment);
      formData.append('assistedOperation', this.form.assistedOperation ? 1 : 0);
      formData.append('baseline', this.form.assistedOperationBaseline);
      formData.append('focalPointDemandingArea', this.form.focalPointDemandingArea);
      if (this.form.epicFocalPointId !== '' && this.form.epicFocalPointId !== null) {
        formData.append('epicFocalPointId', this.form.epicFocalPointId);
      }
      formData.append('focalPointResponsibleArea', this.form.focalPointResponsibleArea);
      this.form.evidence.forEach(evidence => {
        formData.append('evidence[]', evidence);
      });
      let projectId;
      let deliveryFormalizationId;
      try {
        const resultSaveDeliveryFormalization = await deliveryFormalizationService.saveDeliveryFormalization(this.$route.params.id, this.item.id, formData);
        projectId = resultSaveDeliveryFormalization.projectId
        deliveryFormalizationId = resultSaveDeliveryFormalization.deliveryFormalizationId

        this.$showSuccess(resultSaveDeliveryFormalization.message)

        setTimeout(() => {
          this.$router.push(`/projeto/${projectId}/formalizacao/${deliveryFormalizationId}`);
          this.closeModal();
        }, 3000);
      } catch(error) {
        let message = error;
        if (error.response.status === 422) {
          message = "Verifique os campos";
        }
        this.$showError(message);
        this.setErrorList(error);
        this.isLoading = false;
      } finally {
        this.alert.show = false;
        this.isLoading = false;
      }
    },
    setErrorList(error) {
      const {
          response: { data: errors },
      } = error;
      this.errorList = errors;
      console.log(this.errorList)
    },
    getError(param) {
      return this.errorList[param];
    },
    clearError(param) {
      delete this.errorList[param];
    },
    verifySameArea() {
      if (this.item.epicTypeId === this.CONST_TYPE_EPICS.NEGOCIO) {
          return false;
      }
      if (this.item.demandingArea === this.CONST_DEFAULT_AREA) return true;
      if ((this.projectArea === this.item.demandingArea)) return true
      return false
    },
    async focalPointsDemandingArea() {
      if(!this.verifySameArea()) {
        const resultFocalPointsDemandingArea = await deliveryFormalizationService.getFocalPointsDemandingArea(this.item.id);
        this.option.focalPointsResponsibleArea = resultFocalPointsDemandingArea;
      }
    },
    verifyAssistedOperation(){
      if(this.form.assistedOperation){
        return false;
      }
      this.form.assistedOperationBaseline = null;
      return true;
    }
  },
}
</script>

<style lang="scss" scoped>
#formDF {
  padding: 0 14px;
}
</style>
<template>
  <div class="vv-multiple-swicth">
    <tabs v-model="tabSelected">
      <v-tab v-for="(tab, index) in orderedList" :key="index">
        {{ tab.name }}
      </v-tab>
    </tabs>
    <div class="px-4 py-7 grey lighten-5">
      <v-tabs-items v-model="tabSelected" class="grey lighten-5">
        <v-tab-item v-for="(tab, tabIndex) in orderedList" :key="tabIndex">
          <div v-if="tab.items.length > 0">
            <v-switch
              v-for="(item, itemIndex) in tab.items"
              :key="itemIndex"
              v-model="item.value"
              @change="onToggle($event, item)"
              :label="item.name"
              :disabled="disabled"
              color="secondary"
              hide-details
              inset
            />
          </div>
          <div v-else>
            <span class="d-block text-center">Nenhum item foi marcado no Cadastro para {{ tab.name }}.</span>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/common/VvTabs.vue";

export default {
  name: "VvMultipleSwitchs",
  components: {
    Tabs,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    }
  },
  data: () => ({
    tabSelected: 0,
  }),
  computed: {
    selectedList() {
      return this.items.map((item) => {
        const value = this.value.includes(item.id);
        return {
          ...item,
          value,
        };
      });
    },

    orderedList() {
      const sortBy = (by, cresc = true) => (a, b) => {
        const before = a[by];
        const next = b[by];
        if (before < next) return cresc ? -1 : 1;
        if (before > next) return cresc ? 1 : -1;
        return 0;
      };
      const newGroup = [...this.groups];
      return newGroup.sort(sortBy("order")).map((group) => {
        const { id: groupId } = group;
        const groupItems = this.selectedList.filter(
          ({ driversCategoryId }) => groupId === driversCategoryId
        );
        return {
          ...group,
          items: groupItems,
        };
      });
    },
  },
  methods: {
    onToggle(value, item) {
      item.value = value;
      const toEmit = this.selectedList
        .filter(({ value }) => value)
        .map(({ id }) => id);
      this.$emit("input", toEmit);
    },
  },
};
</script>

<style lang="scss">
.vv-multiple-swicth {
  & .vv-tabs .v-tab--active {
    background-color: #fafafa !important;
    &::after {
      background-color: #fafafa !important;
    }
  }
  & .v-input--hide-details > .v-input__control > .v-input__slot {
    margin-bottom: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  & .v-input--selection-controls {
    margin-bottom: 16px;
    padding-top: 4px;
  }
  & .v-input--switch__thumb {
    background-color: white !important;
  }
}
</style>

<template>
  <div id="InceptionCollapses">
    <v-expansion-panels accordion v-model="opened">
      <v-expansion-panel>
        <v-expansion-panel-header>Planejamento Financeiro e Indicadores de performance</v-expansion-panel-header>
        <v-expansion-panel-content>
          <financial-planning
            v-model="value"
            :options="optionsInception"
            :permissions="permissions"
            :errorListInception.sync="errorList"
            :driversCategoryInception="driversCategoryInception"
            :hasPreviusBcFile="hasPreviusBcFile"
            ref="financialPlanning"
            @onDisabledInvestmentOrder="onDisabledInvestmentOrder"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <inception-project-list
      :subprojects="subprojects"
      :can-permission="!permissions.canEditInception"
      @save="save"
      @updateInceptionProjectList="updateInceptionProjectList"
    />
  </div>
</template>

<script>
import inceptionServiceFactory from '@/domains/project/services/inceptionService.js';
import FinancialPlanning from "@/domains/project/components/FinancialPlanning";
import InceptionProjectList from "@/domains/project/components/InceptionProjectList";

const InceptionService = inceptionServiceFactory();

export default {
  components: {
    FinancialPlanning,
    InceptionProjectList
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    permissions: {
      type: Object,
      default: () => ({})
    },
    driversCategoryInception: {
      type: Array,
      default: () => []
    },
    errorListInception: {
      type: Object,
      default: () => ({})
    },
    hasPreviusBcFile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      opened: 0,
      optionsInception: {},
      subprojects: [],
    }
  },
  computed: {
    errorList: {
      get() {
        return this.errorListInception;
      },
      set(value) {
        this.$emit('update:errorListInception', value);
      }
    }
  },
  methods: {
    async loadOptionsInception() {
      try {
        this.$showLoading();
        const result = await InceptionService.loadOptions();
        this.optionsInception = result.options;
      } catch (error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    },
    async loadSubProject() {
      try {
        this.$showLoading();
        const result = await InceptionService.loadSubProject(this.$route.params.id);
        this.subprojects = result.data.subprojects;
        this.adjustStatus();
      } catch (error) {
        this.$showError(error);
      } finally {
        this.$hideLoading();
      }
    },
    adjustStatus() {
      Object.keys(this.subprojects).forEach((keySubProjects) => {
        Object.keys(this.subprojects[keySubProjects].epics).forEach((keyEpics) => {
          Object.keys(this.optionsInception.status).forEach((indexStatus) => {
            if (this.optionsInception.status[indexStatus].id === this.subprojects[keySubProjects].epics[keyEpics].statusId) {
              this.$set(this.subprojects[keySubProjects].epics[keyEpics], 'statusName', this.optionsInception.status[indexStatus].name)
            }
          });
          Object.keys(this.optionsInception.stage).forEach((indexStage) => {
            if (this.optionsInception.stage[indexStage].id === this.subprojects[keySubProjects].epics[keyEpics].stageId) {
              this.$set(this.subprojects[keySubProjects].epics[keyEpics], 'stageName', this.optionsInception.stage[indexStage].name)
            }
          });
        });
      });
    },
    save(value) {
      this.$emit('save', value);
    },
    updateInceptionProjectList() {
      this.loadSubProject();
    },
    onSave() {
      this.$refs.financialPlanning.onSave();
    },
    onDisabledInvestmentOrder(investmentOrderItem, action) {
      this.$emit('onDisabledInvestmentOrder', investmentOrderItem, action)
    },
  },
  async mounted() {
    await this.loadOptionsInception();
    this.loadSubProject();
  },
};
</script>

<style lang="scss">
  #InceptionCollapses {
    .v-expansion-panel-header {
      font-size: 16px;
      color: #202121;
      font-weight: 600;
      padding: 20px 0px;

      &.v-expansion-panel-header--active {
        min-height: auto !important;
      }
    }

    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding: 2px 0px 16px;
      }
    }
  }
</style>
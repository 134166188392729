import GLOBAL_CONSTANTS from "@/plugins/global-constants";

export default function epicRulesFactory() {
  const { 
    CONST_CHANGE_REQUEST_STATUS: {
      PENDENTE_ALTERACAO 
    }
   } = GLOBAL_CONSTANTS;

  return {
    canResponse(item, userId) {
      return item.statusId === PENDENTE_ALTERACAO && userId === item.userId;
    }
  }
}
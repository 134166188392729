<template>
  <div id="InceptionProjectsList">
    <div class="projects-title">
      <h2>Macro Temas</h2>
      <p>Listagem de macro temas disponíveis para inception (aprovados na Gestão de macro temas)</p>
    </div>
    <v-data-table
      :headers="headers"
      :items="subprojects"
      :single-expand="true"
      :mobile-breakpoint="0"
      :no-data-text="'Não há macro temas cadastrados ou aprovados'"
      :no-results-text="'Não foi encontrado nenhum macro tema na sua busca'"
      hide-default-footer
      item-key="id"
      show-expand
      dense
      class="data-table-inception-project-list"
      @click:row="openDetails"
    >
      <template v-slot:item.statusId>
        <p class="project-status">Aprovado</p>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <linked-epics
              :epics="item.epics"
              :subProjectId="item.id"
              :subProjects="subprojects"
              :can-permission="canPermission"
              @save="save"
              @updateInceptionProjectList="updateInceptionProjectList"
          ></linked-epics>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import LinkedEpics from './LinkedEpics.vue';

export default {
  components: {
    LinkedEpics
  },
  props: {
    subprojects: {
      type: Array,
      default: () => []
    },
    canPermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "Nome",
          align: "start",
          value: "name",
        },
        {
          text: "Pontuação",
          align: "start",
          value: "score",
        },
        {
          text: "Épicos envolvidos",
          align: "start",
          value: "epicCount",
        },
        {
          text: "Status",
          align: "start",
          value: "statusId",
        },
        { 
          text: '', 
          value: 'data-table-expand' 
        }
      ],
      toggleById: null
    }
  },
  methods: {
    openDetails(row, slot) {
      this.toggleButton(row.id)
      slot.expand(!slot.isExpanded);
    },
    toggleButton(id) {
      if (this.toggleById === id) {
        this.toggleById = null;
      } else {
        this.toggleById = id;
      }
    },
    save(value) {
      this.$emit('save', value);
    },
    updateInceptionProjectList() {
      this.$emit('updateInceptionProjectList', true);
    }
  }
}
</script>

<style lang="scss">
  #InceptionProjectsList {
    margin-top: 35px;

    .projects-title {
      h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 3px;
        color: #202121;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: #929497;
      }
    }

    .data-table-inception-project-list {
      cursor: pointer;

      &.v-data-table {
        .v-data-table__wrapper {
          table {
            thead {
              background-color: #f4f5f5;
              height: 34px;

              tr {
                th {
                  span {
                    font-weight: 400;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  height: 83px;
                  color: #6D6E70;
                  font-weight: 500;
                }

                &.v-data-table__expanded__content {
                  box-shadow: none;
                  cursor: auto;

                  td {
                    padding: 51px 0px;
                  }
                }
              }
            }
          }
        }
      }

      .project-status {
        font-weight: 500;
        font-size: 14px;
        color: #699F10;
        margin-bottom: 0px;
      }

      .approver {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        margin-bottom: 0px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &.pendente {
          color: #929497;
        }

        &.aprovado {
          color: #6DA151;
        }

        &.reprovado {
          color: #FF502D;
        }

        @media (max-width: 1903px) {
          &.transformation-manager {
            max-width: 103.06px;
          }

          &.vsm {
            max-width: 79.19px;
          }

          &.focal-point {
            max-width: 125.16px;
          }
        }
      }

      .workflow-status {
        padding: 8px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        border-radius: 34px;
        width: fit-content;

        &.pendente {
          color: #6D6E70;
          background-color: #DBDCDC;
        }

        &.aprovado {
          color: #6DA151;
          background-color: #B2DEB2;
        }

        &.recusado {
          color: #FF502D;
          background: rgba(255, 80, 45, 0.25);
        }
      }

      .epic-name {
        margin-bottom: 0px !important;

        @media (max-width: 1903px) {
          width: 222.94px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
</style>
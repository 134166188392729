import VvLoading from "@/components/common/VvLoading";
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";
import VvNewFilter from "@/components/common/VvNewFilter";
import epicListServiceFactory from "../../services/epicListService.js";
import DataTableConfigs from "../../configs/DataTableConfigs.js";
import FilterConfigs from "../../configs/FilterConfigs.js";

const epicListService = epicListServiceFactory();
const DataTableConfig = DataTableConfigs();
const FilterConfig = FilterConfigs();

export default {
    components: {
        VvLoading,
        VvBreadcrumbs,
        VvNewFilter
    },
    data() {
        return {
            loading: false,
            loadingOptions: false,
            breadcrumb: [
                {
                    text: 'Listagens',
                    disabled: true,
                },
                {
                    text: 'Entregáveis',
                    disabled: false,
                },
            ],
            filterName: 'epicList',
            fields: [],
            headersTable: [],
            headerProps: {
                sortByText: "Ordenar por",
            },
            footerProps: {
                "items-per-page-text": "Linhas por página",
                "items-per-page-options": [5, 10, 15, 30, 50, 100],
            },
            moment: require("moment"),
            epics: [],
            options: {
                itemsPerPage: 15,
            },
            serverItemsLength: 0,
            currentPage: 0,
            payload: {},
            gridOptions: {}
        }
    },
    methods: {
        async getOptions() {
            this.headersTable = DataTableConfig.headersTable();
            try {
                this.loadingOptions = true;
                const result = await epicListService.getFilterOptions();
                this.gridOptions = result;
                this.loadFilter();
                this.fields.map((field) => {
                    if (field.name === "interestedAreas") {
                        field.items = result.responsibleAreas
                    } else if (field.name === "demandingArea") {
                        field.items = result.responsibleAreas
                    } else if (field.name === "squads") {
                        field.items = result.squads.filter(squad => squad !== null);
                    } else if (field.name === "quarter") {
                        field.items = result.quarters
                    } else {
                        field.items = typeof result[field.name] !== 'undefined' ? result[field.name] : field.items;
                    }
                });
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loadingOptions = false;
            }
        },
        async getEpics() {
            try {
                this.loading = true;
                const result = await epicListService.getEpics(this.payload);
                this.epics = result.data;
                this.currentPage = result.current_page;
                this.serverItemsLength = result.total;
            } catch (error) {
                this.$showError(error);
            } finally {
                this.loading = false;
            }
        },
        loadFilter() {
            this.fields = FilterConfig.loadFields();

            let fieldsStore = this.getGlobalFilters(this.filterName);
            if (typeof fieldsStore !== 'undefined') {
                this.fields = fieldsStore;
                let payload = {};
                this.fields.map(field => {
                    if (field.value) {
                        payload[field.payloadBackend] = field.value;
                    }
                });
                if (Object.keys(payload).length) {
                    payload.page = 1;
                    this.filter(payload);
                }
            } else {
                this.resetFilter();
            }
        },
        filter(payload) {
            this.payload = payload;
            this.setPagination(payload.page);
            this.getEpics();
        },
        resetFilter() {
            this.payload = {}
            this.setPagination(1);
            this.getEpics();
        },
        setPagination(page) {
            this.payload['page'] = (page) ? page : this.options.page;
            this.payload['page_size'] = this.options.itemsPerPage;
            this.payload['sortBy'] = this.options.sortBy[0];
            this.payload['sortDesc'] = 0;

            if (this.options.sortBy.length && this.options.sortDesc.length) {
                this.payload['sortDesc'] = this.options.sortDesc[0] ? 1 : 0;
            }
        },
        fetchDatatable() {
            this.setPagination();
            this.getEpics();
        },
        rowClicked(project) {
            let url = `/projeto/${project.projectId}/epicos?id=${project.epicId}`;
            let legacyStage = [
                this.CONST_STAGE.CONCLUIDO,
                this.CONST_STAGE.CANCELADO,
            ];
            if (legacyStage.includes(project.stageId)) {
                url = url + '&legacy=1';
            }
            this.$router.push(url);
        },
        statusAtualColor(id, css, type) {
            let color = "";
            if (type == "fc") {
              color = css ? "rgba(93, 93, 93, 0.8) " : "gray";
              if (id == 5 || id == 2 || id == 9) {
                color = css ? "rgba(20, 146, 0, 0.8)" : "secondary";
              } else if (id == 1 || id == 3 || id == 11) {
                color = css ? "rgba(157, 157, 157, 0.8) " : "grayLight";
              } else if (id == 8) {
                color = css ? "rgba(8, 123, 188, 0.8) " : "info";
              } else if (id == 7) {
                color = css ? "rgba(93, 93, 93, 0.8) " : "gray";
              } else if (id == 4 || id == 12) {
                color = css ? "rgba(207, 30, 30, 0.8) " : "error";
              } else if (id == 10 || id == 6) {
                color = css ? "rgba(255, 183, 0, 0.8) " : "warningLight";
              }
            } else {
              color = css ? "rgba(255, 255, 255, 0.8) " : "white";
              if (id == 5 || id == 2 || id == 9) {
                color = css ? "rgba(20, 146, 0, 0.3)" : "secondary";
              } else if (id == 1 || id == 3 || id == 11) {
                color = css ? "rgba(157, 157, 157, 0.3) " : "grayLight";
              } else if (id == 8) {
                color = css ? "rgba(8, 123, 188, 0.3) " : "info";
              } else if (id == 7) {
                color = css ? "rgba(93, 93, 93, 0.3) " : "gray";
              } else if (id == 4 || id == 12) {
                color = css ? "rgba(207, 30, 30, 0.3) " : "error";
              } else if (id == 10 || id == 6) {
                color = css ? "rgba(255, 183, 0, 0.3) " : "warningLight";
              }
            }
            return color;
          },
        statusAtualColorStage(id, css, type) {
            let color = "";
            if (type == "fc") {
                color = css ? "rgba(93, 93, 93, 0.8)" : "text-gray";
                if (id == 8) {
                    color = css ? "rgba(20, 146, 0, 0.8)" : "text-secondary";
                } else if (id == 1 || id == 4 || id == 7) {
                    color = css ? "rgba(157, 157, 157, 0.8)" : "text-grayLight";
                } else if (id == 5 || id == 3 || id == 2) {
                    color = css ? "rgba(8, 123, 188, 0.8)" : "text-info";
                } else if (id == 0) {
                    color = css ? "rgba(207, 30, 30, 0.8)" : "text-error";
                } else if (id == 6) {
                    color = css ? "rgba(255, 183, 0, 0.8)" : "text-warningLight";
                }
            } else {
                color = css ? "rgba(93, 93, 93, 0.3)" : "gray";
                if (id == 8) {
                    color = css ? "rgba(20, 146, 0, 0.3)" : "secondary";
                } else if (id == 1 || id == 4 || id == 7) {
                    color = css ? "rgba(157, 157, 157, 0.3)" : "grayLight";
                } else if (id == 5 || id == 3 || id == 2) {
                    color = css ? "rgba(8, 123, 188, 0.3)" : "info";
                } else if (id == 0) {
                    color = css ? "rgba(207, 30, 30, 0.3)" : "error";
                } else if (id == 6) {
                    color = css ? "rgba(255, 183, 0, 0.3)" : "warningLight";
                }
            }
            return color;
        },
        getName(id, object) {
            let name;
            if (typeof this.gridOptions[object] !== 'undefined') {
                name = this.gridOptions[object].filter(stage => stage.id === id);
                return name[0].name;
            }
        },
        convertMount(valor) {
            let mes = [
              "",
              "Jan",
              "Fev",
              "Mar",
              "Abr",
              "Mai",
              "Jun",
              "Jul",
              "Ago",
              "Set",
              "Out",
              "Nov",
              "Dez",
            ];
            return mes[parseInt(valor.split("-")[1])];
        },
    },
    watch: {
        options: {
            handler() {
                this.fetchDatatable();
            },
            deep: true,
        }
    },
    created() {
        this.getOptions();
    }
}
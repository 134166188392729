<template>
  <v-container>
    <loading :is-loading="isLoading"></loading>
    <h3 class="mt-5">Business Model Canvas - ID da Iniciativa: {{ initiativeId }}</h3>
    <p class="mb-8">Última alteração: {{ canvas.lastUpdated }}</p>
    <div class="grid-5 gap-md">
      <div class="g-col-2 grid-2 gap-md canvas-border pa-3">
        <div class="g-row-2">
          <textarea-input
              v-model="canvas.mainPartners"
              label="Parceiros Principais"
              class="height-textarea-canvas"
              readonly
              no-resize
          />
        </div>
        <div>
          <textarea-input
              v-model="canvas.mainActivities"
              label="Atividades Principais"
              readonly
              no-resize
          />
        </div>
        <div>
          <textarea-input
              v-model="canvas.mainFeatures"
              label="Recursos Principais"
              readonly
              no-resize
          />
        </div>
      </div>
      <div class="canvas-border pa-3">
        <textarea-input
            v-model="canvas.valueProposal"
            label="Proposta de Valor"
            class="height-textarea-canvas"
            readonly
            no-resize
        />
      </div>
      <div class="g-col-2 grid-2 gap-md canvas-border pa-3">
        <div>
          <textarea-input
              v-model="canvas.customerRelationship"
              label="Relacionamento com o cliente"
              readonly
              no-resize
          />
        </div>
        <div class="g-row-2">
          <textarea-input
              v-model="canvas.customersSegment"
              label="Segmento de clientes"
              class="height-textarea-canvas"
              readonly
              no-resize
          />
        </div>
        <div>
          <textarea-input
              v-model="canvas.channels"
              label="Canais"
              readonly
              no-resize
          />
        </div>
      </div>
    </div>
    <div class="mt-3 grid-2 gap-md canvas-border pa-3">
      <div>
        <textarea-input
            v-model="canvas.structureCostsSourceRevenues"
            label="Estrutura de custos e Fonte de receitas"
            readonly
            no-resize
        />
      </div>
      <div>
        <textarea-input
            v-model="canvas.benefitsKpi"
            label="Benefícios e KPI"
            readonly
            no-resize
        />
      </div>
    </div>
    <div class="text-right mt-9 btn-print">
      <v-btn @click="closeWindow" color="#929497" outlined class="mr-5">Fechar</v-btn>
      <v-btn @click="printPage" color="primary">Baixar ou imprimir</v-btn>
    </div>
  </v-container>
</template>

<script>
import TextareaInput from "@/components/common/VvTextareaInput";
import Loading from "@/components/VvLoading";

import subprojectServiceFactory from "@/domains/subProject/services/subProjectService";
const subprojectService = subprojectServiceFactory();

export default {
  name: "CanvasModel",
  components: {
    TextareaInput,
    Loading,
  },
  data: () => ({
    isLoading: false,
    initiativeId: 0,
    canvas: {
      mainPartners: null,
      mainActivities: null,
      mainFeatures: null,
      valueProposal: null,
      customerRelationship: null,
      customersSegment: null,
      channels: null,
      structureCostsSourceRevenues: null,
      benefitsKPI: null,
    },
  }),
  methods: {
    printPage() {
      print();
    },
    closeWindow() {
      close();
    },
    async loadCanvasProject() {
      try {
        this.isLoading = true;
        const resultCanvas = await subprojectService.loadCanvas(this.initiativeId);
        this.canvas = resultCanvas.canvas;
      } catch (error) {
        const { response: { data: { message } = {} } = {} } = error;
        this.$showError(message || "Verifique os Campos marcados!");
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.initiativeId = this.$route.params.initiativeId;
    this.loadCanvasProject();
  },
}
</script>

<style scoped>

</style>
<template>
  <v-container :fluid="true" class="px-3" id="listDeliveryFormalization">
    <vv-loading :is-loading="isLoading"></vv-loading>
    <div class="grid-2">
      <div>
        <label for="search-delivery-formalization">Busca</label>
        <v-text-field
          id="search-delivery-formalization"
          placeholder="id, nome, área"
          append-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </div>
    </div>
    <data-table-delivery-formalizations 
      :deliveryFormalizations.sync="deliveryFormalizations"
      :search="search"
      :loadingDeliveryFormalizations="loadingDeliveryFormalizations"
      @loading="isLoading = $event"
      @error="errorAlert = $event"
    ></data-table-delivery-formalizations>
    <v-dialog v-model="errorAlert" hide-overlay width="500">
      <v-alert type="error">Erro! Tente novamente</v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import VvLoading from "@/components/VvLoading";
import DataTableDeliveryFormalizations from './DataTableDeliveryFormalizations.vue';
import deliveryFormalizationServiceFactory from "@/domains/deliveryFormalization/services/deliveryFormalizationService";

const deliveryFormalizationService = deliveryFormalizationServiceFactory();

export default {
  name: "ListDeliveryFormalization",
  components: {
    VvLoading,
    DataTableDeliveryFormalizations
  },
  data: () => ({
    isLoading: false,
    deliveryFormalizations: [],
    search: '',
    loadingDeliveryFormalizations: false,
    errorAlert: false,
  }),
  created() {
    this.loadDeliveryFormalizationList();
  },
  methods: {
    async loadDeliveryFormalizationList() {
      this.isLoading = true;
      this.loadingDeliveryFormalizations = true;
      const result = await deliveryFormalizationService.loadList(this.$route.params.id);
      if (result.status === 200) {
        this.deliveryFormalizations = result.data.deliveryFormalizations;
      } else {
        this.errorAlert = true
      }
      this.isLoading = false;
      this.loadingDeliveryFormalizations = false;
    }
  }
}
</script>

<style lang="scss">
.v-dialog {
  box-shadow: none;
}
</style>
import axios from "@/plugins/axios";
import { map, getFormData, toParams, mapParams } from "./utils";

const optionsMap = [
    ["projectTypes", "project_types"],
    ["targetAudiences", "target_audiences"],
    ["estimatedDurations", "estimated_durations"],
    ["mainStrategicDirected", "main_strategic_directed"],
    "areas",
    "users",
    "criteria",
];

const ideaMap = [
    "title",
    ["projectTypeId", "project_type_id"],
    ["descriptionProblem", "description_problem"],
    ["descriptionIdea", "description_idea"],
    ["expectedFinancialBenefit", "expected_financial_benefit"],
    ["mainStrategicDirected", "main_strategic_directed"],
    ["ideasTargetAudiences", "ideas_target_audiences"],
    ["estimatedDurationId", "estimated_duration_id"],
    ["possibleAccelerationByStartups", "possible_acceleration_by_startups"],
    "recommendation",
    ["collectiveIdea", "collective_idea"],
    ["ideasUsers", "ideas_users"],
    ["responsibleArea", "responsible_area"],
    ["areasIdeas", "areas_ideas"],
    ["classificationsIdeas", "classifications_ideas"],
    "attachments",
    "problems",
    ["projectId", "project_id"],
    ["epicId", "epic_id"],
    ["campaignId", "campaign_id"],
    ["attachmentsUpload", "attachments_upload"],
    ["ownerUserId", "owner_user_id"],
    ["campaignId", "campaign_id"],
    "projects",
    "ideas",
    "id",
    "draft",
];

const listMap = [
    "id",
    "title",
    "problems",
    ["projectId", "project_id"],
    ["epicId", "epic_id"],
    ["campaignId", "campaign_id"],
    ["campaignName", "campaign_name"],
    ["statusWorkflow","status_workflow"],
    ["responsibleArea", "responsible_area"],
    ["score", "points"],
    ["classificationsIdeas", "classifications_ideas"],
    ["weightedScore", "weighted_points"],
    ["projectByEpic", "project_by_epic"],
    "likes",
];

const likesMap = [["user_liked", "userLiked"], "counts"];

const workflowMap = [
    "id",
    "title",
    "campaign",
    ["descriptionProblem", "description_problem"],
    ["descriptionIdea", "description_idea"],
    ["targetAudiences", "target_audiences"],
    ["estimatedDurations", "estimated_durations"],
    ["responsibleArea", "responsible_area"],
    ["workflowId", "workflow_id"],
    ["workflowName", "workflow_name"],
    "points",
    ["approvalLevel", "approval_level"],
]

const paginationMap = [
    "data",
    ["currentPage", "current_page"],
    ["firstPageUrl", "first_page_url"],
    "from",
    ["lastPage", "last_page"],
    ["lastPageUrl", "last_page_url"],
    "links",
    ["nextPageUrl", "next_page_url"],
    "path",
    ["perPage", "per_page"],
    ["prevPageUrl", "prev_page_url"],
    "to",
    "total",
]

export default function ideaServiceFactory() {
    return {
        loadLists(id) {
            return axios
                .get(`idea/options/${id}`)
                .then((res) => res.data)
                .then((res) => map(res).from(optionsMap));
        },

        create(payload, draft) {
            if(payload.epicId !== null){
                payload.projectId = null
            }
            const { classificationsIdeas } = payload;
            return axios.post(
                "idea",
                getFormData(
                    map({
                        ...payload,
                        classificationsIdeas: classificationsIdeas.filter((el) => !!el),
                        draft: draft,
                    }).from(ideaMap)
                ),
                {
                    headers: {
                        "Content-Type": "multipart/form-data;",
                    },
                }
            );
        },

        edit(payload, draft) {
            if(payload.epicId !== null){
                payload.projectId = null
            }
            const { classificationsIdeas } = payload;
            return axios.post(
                `idea/${payload.id}?_method=PUT`,
                getFormData(
                    map({
                        ...payload,
                        classificationsIdeas: classificationsIdeas.filter((el) => !!el),
                        draft,
                    }).from(ideaMap)
                ),
                {
                    headers: {
                        "Content-Type": "multipart/form-data;",
                    },
                }
            );
        },

        show(id) {
            return axios
                .get(`idea/${id}`)
                .then((res) => res.data)
                .then((res) => map(res).from(ideaMap));
        },

        getFile(id) {
            return axios.get(`idea/attachment/${id}`).then((res) => res.data);
        },

        like(ideaId) {
            return axios
                .post("idea/like", { ideaId })
                .then((res) => res.data);
        },

        listCriteria() {
            return axios.get("criteria/list").then((res) => res.data);
        },

        list(payload) {
            const params = toParams(payload) || "";
            const paramsMapped = mapParams(params).from(listMap);
            return axios
                .get(`idea${paramsMapped}`)
                .then((res) => res.data)
                .then((res) => {
                    res.data = res.data.map(item => map(item).from(listMap))
                    return res;
                })
                .then((res) => {
                    res.data = res.data.map((item) => {
                        const {
                            likes, 
                            problems,
                            projectByEpic,
                            projectId,
                        } = item;
                        const newItem = {
                            ...item,
                            likes: map(likes).from(likesMap),
                            problems: problems ? problems.split(",").map((el) => Number(el)) : [],
                            projectId: projectByEpic || projectId,
                        }
                        return newItem;
                    })
                    return res;
                })
        },

        listLinkedProblems(id) {
            return axios
                .get(`idea/listProblemsLinkedIdea/${id}`)
                .then(res => res.data);
        },

        listWholikedIdeia(id) {
            return axios
                .get(`idea/listUsersWholikeIdea/${id}`)
                .then(res => res.data);
        },

        linkProblems( ideaId, problemsId ) {
            const data = { 
                idea_id: ideaId,
                problems_id: problemsId
            };
            
            return axios
                .post(`idea/linkProblemsToTheIdea`, data)
                .then(res => res.data)
        },

        listWorkflowFirstApproval(pagination) {
            const params = toParams(pagination) || "";
            const paramsMapped = mapParams(params).from(workflowMap);
            return axios
                .get(`/idea/workflow/first-approval-list${paramsMapped}`)
                .then(res => res.data)
                .then((res) => map(res).from(paginationMap))
                .then((res) => {
                    res.data = res.data.map((item) => map(item).from(workflowMap))
                    return res
                });
        },

        listWorkflowSecondApproval(pagination) {
            const params = toParams(pagination) || "";
            const paramsMapped = mapParams(params).from(workflowMap);
            return axios
                .get(`/idea/workflow/second-approval-list${paramsMapped}`)
                .then(res => res.data)
                .then((res) => map(res).from(paginationMap))
                .then((res) => {
                    res.data = res.data.map((item) => map(item).from(workflowMap))
                    return res
                });
        },
        
        workflowApproval(idea_id) {
            return axios
                .post("idea/workflow/pass", { idea_id })
                .then((res) => res.data);
        },

        workflowDisapproval(payload) {
            return axios
                .post("idea/workflow/fail", payload)
                .then(res => res.data);
        },

        listWorkflowHistory(id) {
            return axios
                .get(`idea/workflow/history/${id}`)
                .then(res => res.data);
        },

        listProjects(id) {
            return axios
                .get(`project/list-projects/${id}`)
                .then((res) => res.data)
        },

        listEpisByProject(project_id, epic_id) {
            return axios
                .get(`project/${project_id}/epic/list-epics/${epic_id}`)
                .then((res) => res.data)
        },

        loadListIdeas(id) {
            return axios
                .get(`options/tap/${id}`)
                .then((res) => res.data.options.ideas)
        },

        listIdeaLinks(id) {
            return axios
                .get(`idea/listProjectEpicLinkedToIdea/${id}`)
                .then((res) => res.data)
        },

        linkProject( ideaId, projectId ) {
            const data = { 
                idea_id: ideaId,
                project_id: projectId
            };
            
            return axios
                .post(`idea/linkIdeaToTheProject`, data)
                .then(res => res.data)
        },

        linkEpic( ideaId, epicId ) {
            const data = { 
                idea_id: ideaId,
                epic_id: epicId
            };
            
            return axios
                .post(`idea/linkIdeaToTheEpic`, data)
                .then(res => res.data)
        },
    };
}

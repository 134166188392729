<template>
  <v-form ref="project">
    <stepper
      v-if="project.steps.length > 0"
      v-model="step"
      :items="project.steps"
      :hasFinalization="project.hasFinalization"
      class="my-5"
    />
    <v-progress-linear v-else indeterminate color="primary" class="mb-4 mt-6" />
    <v-alert type="error" outlined class="pa-2 mt-6" icon="mdi-alert-outline" id="validateTreeByProject" v-if="validateTreeByProject">
      Há inconsistências na árvore de negócio da iniciativa.
    </v-alert>
    <v-tabs-items v-model="step" touchless>
      <v-tab-item>
        not show because step never get value 0
      </v-tab-item>
      <v-tab-item>
        <project-data
          ref="projectData"
          v-model="project"
          :options="options"
          :is-editing="isEditing"
          :disabled="createPermission"
          :error-list.sync="errorList"
        />
        <strategic-impacts
          ref="strategicimpacts"
          v-model="project"
          :items.sync="options.impacts"
          :impact-types="options.impactsType"
          :disabled="createPermission"
        />
        <guide-objectives
          v-model="project"
          :items="options.drivers"
          :groups="options.driversCategory"
          :disabled="createPermission"
        />
        <history-workflow
          v-model="project"
          v-if="isEditing && project.hasWorkflow"
        />
      </v-tab-item>
      <v-tab-item>
        <project-list
            v-model="subprojects"
            :iniciative-name="name"
            :initiative-id="id"
            :currentStep="currentStep"
            :origin-subproject="originSubproject"
            :options="options"
            :drivers="project.drivers"
            :auto-open-modal-impacts="autoOpenModalImpacts"
            :error-change-step="errorList"
            :error-list.sync="errorsSubprojects.subproject"
            :permissionMigrate="permissionMigrate"
            @updateOriginSubproject="updateOriginSubproject"
            @createNewProject="createNewProject"
            @removeNewProject="removeNewProject"
            @addId="addId"
            @lastUpdateCanvas="dateCanvas"
            @canEditCanvas="canEditCanvas"
            @hasChange="hasChange"
            ref="projectList"
            class="mt-16"
        />
      </v-tab-item>
      <v-tab-item>
        <inception-collapses
          v-model="inception"
          :permissions="permissionsInception"
          :errorListInception.sync="errorListInception"
          :driversCategoryInception="driversCategoryInception"
          :hasPreviusBcFile="hasPreviusBcFile"
          @save="save"
          @onDisabledInvestmentOrder="onDisabledInvestmentOrder"
          ref="inceptionCollapses"
        />
      </v-tab-item>
      <v-tab-item>
        <closure />
      </v-tab-item>
    </v-tabs-items>

    <modal-result-projects
        :show="modalSubprojects.show"
        :success="modalSubprojects.success"
        :error="modalSubprojects.error"
        :total="modalSubprojects.total"
        @confirmed="modalSubprojects.show=false"
    />

    <project-footer
      v-if="!createPermission"
      :is-editing="isEditing"
      :disabled="!canSave"
      @cancel="onCancel"
      @create="onSubmit"
      @save="beforeSave"
    >
      <template v-slot:center>
        <div :class="(subprojectsChanges.length > 0) ? 'count-change-subproject' : 'no-changes-subproject'">
          <v-tooltip top v-if="subprojectsChanges.length > 0">
            <template v-slot:activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on"
            ><v-icon color="primary">mdi-eye</v-icon>
              {{ subprojectsChanges.length }} {{ (subprojectsChanges.length > 1) ? 'Macro Temas foram alterados' : 'Macro Tema foi alterado' }}</span>
            </template>
            <span v-for="subprojectId in subprojectsChanges" :key="subprojectId">ID: {{ subprojectId }}<br></span>
          </v-tooltip>
        </div>
      </template>
    </project-footer>

    <modal-error :errorsModal="errorsModal" @closeModalError="closeModalError"></modal-error>

    <inception-investment-order-delete-modal
        :epicList="this.inceptionExcludesInvestmentEpicList"
        :visible="this.inceptionExcludesInvestmentModel"
        @onConfirm="onSubmit"
        @onClose="onCloseInceptionExcludeInvestmentOrder"
    />
  </v-form>
</template>

<script>
import Stepper from "../../../components/common/VvStepper.vue";
import HistoryWorkflow from "../components/HistoryWorkflow.vue";
import ProjectData from "../components/ProjectData.vue";
import GuideObjectives from "../components/GuideObjectives.vue";
import StrategicImpacts from "../components/StrategicImpacts.vue";
import ProjectFooter from "../../../components/common/VvGeneralFooter.vue";
import ErrorMessageHandler from "../mixins/ErrorMessageHandler";
import ProjectList from "@/domains/project/components/ProjectList";
import ModalResultProjects from "@/domains/project/components/ModalResultProjects";
import ModalError from "@/domains/project/components/ModalError";
import InceptionCollapses from "@/domains/project/components/InceptionCollapses";
import Closure from "@/domains/project/components/Closure/Closure.vue";
import InceptionInvestmentOrderDeleteModal from "@/domains/project/components/inceptionInvestmentOrderDeleteModal/inceptionInvestmentOrderDeleteModal.vue";
import {clone} from "@/services/utils";

import ProjectService from "../services/projectService";
const { load: loadProject, loadOptions, create, save, getCurrentStep, checkInceptionExcludeInvestmentOrder } = ProjectService();

import inceptionServiceFactory from '@/domains/project/services/inceptionService.js';
import subprojectServiceFactory from "@/domains/subProject/services/subProjectService";
const subprojectService = subprojectServiceFactory();
const InceptionService = inceptionServiceFactory();

export default {
  name: "Project",
  components: {
    Stepper,
    HistoryWorkflow,
    ProjectData,
    GuideObjectives,
    StrategicImpacts,
    ProjectFooter,
    ProjectList,
    ModalResultProjects,
    InceptionCollapses,
    ModalError,
    Closure,
    InceptionInvestmentOrderDeleteModal
  },
  mixins: [ErrorMessageHandler],
  props: {
    id: {
      type: Number,
    },
    name: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: {
      project: false,
    },
    step: 1,
    errorList: {},
    errorListInception: {},
    canSave: false,
    createPermission: false,
    permissionMigrate: false,
    validateTreeByProject: false,
    project: {
      id: null,
      name: "",
      idJira: "",
      ideaId: null,
      areasId: null,
      projectType: null,
      problemSolved: "",
      objective: "",
      scope: "",
      premises: "",
      processChange: false,
      itArea: [],
      impactedAreas: [],
      stakeholdersId: null,
      strategicIndicators: null,
      focalPoints: [],
      steps: [],
      drivers: [],
      impacts: [],
      strategicIndicatorsId: null,
    },
    cloneProject: {
      id: null,
      name: "",
      idJira: "",
      ideaId: null,
      areasId: null,
      projectType: null,
      problemSolved: "",
      objective: "",
      scope: "",
      premises: "",
      processChange: false,
      itArea: [],
      impactedAreas: [],
      stakeholdersId: null,
      strategicIndicators: null,
      focalPoints: [],
      steps: [],
      drivers: [],
      impacts: [],
      strategicIndicatorsId: null,
    },
    changeProject: false,
    options: {
      projectTypes: [],
      itAreas: [],
      responsibleAreas: [],
      ideas: [],
      steps: [],
      impactedAreas: [],
      otherInvolvedAreas: [],
    },
    inceptionExcludesInvestmentOrder: [],
    inceptionExcludesInvestmentEpicList: [],
    inceptionExcludesInvestmentModel: false,
    inception: {
      opex: null,
      capex: null,
      bcFlag: null,
      bcStatusId: null,
      bcFileName: null,
      estimatedCost: null,
      investmentOrder: null,
      inceptionDrivers: []
    },
    hasPreviusBcFile: false,
    cloneInception: {
      opex: null,
      capex: null,
      bcFlag: null,
      bcStatusId: null,
      bcFileName: null,
      estimatedCost: null,
      investmentOrder: null,
      inceptionDrivers: []
    },
    changeInception: false,

    permissionsInception: {
      canEditInception: false,
      canEditIndicators: false,
      canEditInceptionInvestmentOrder: false
    },

    driversCategoryInception: [],

    workflow: {},

    subprojects: [],
    subprojectsChanges: [],
    errorsSubprojects: [],
    originSubproject: [],
    newSubprojectId: 1,
    dateBusinessCanvas: '',
    editBusinessCanvas: false,
    autoOpenModalImpacts: false,
    modalSubprojects: {
      show: false,
      success: 0,
      error: 0,
      total: 0,
    },
    errorMessage: '',
    errorsModal: {
      show: false,
      project: false,
      discovery: {
        success: 0,
        error: 0,
        total: 0,
      },
      inception: false
    },
    currentStep: null
  }),
  computed: {
    isEditing() {
      return !!this.id;
    },
    projectChanges() {
      return JSON.stringify(this.project) !== JSON.stringify(this.cloneProject);
    },
    inceptionChanges() {
      return JSON.stringify(this.inception) !== JSON.stringify(this.cloneInception);
    },
    formInception() {
      let formData = new FormData();
      Object.keys(this.inception).forEach((key) => {
        if (key === "inceptionDrivers") {
          for (let index = 0; index < this.inception.inceptionDrivers.length; index++) {
            formData.append("inceptionDrivers["+index+"]", this.inception.inceptionDrivers[index])
          }
        } else if (key === "projectIndicators") {
          for (let index = 0; index < this.inception.projectIndicators.length; index++) {
            Object.keys(this.inception.projectIndicators[index]).forEach((keyChildren) => {
              if (keyChildren === "plannings") {
                for (let indexChildren = 0; indexChildren < this.inception.projectIndicators[index].plannings.length; indexChildren++) {
                  Object.keys(this.inception.projectIndicators[index].plannings[indexChildren]).forEach((keySecondChildren) => {
                    if (this.inception.projectIndicators[index].plannings[indexChildren][keySecondChildren] !== null) {
                      formData.append(
                        "projectIndicators["+index+"][plannings]["+indexChildren+"]["+keySecondChildren+"]",
                        this.inception.projectIndicators[index].plannings[indexChildren][keySecondChildren]
                      );
                    }
                  });
                }
              } else {
                formData.append("projectIndicators["+index+"]["+keyChildren+"]", this.inception.projectIndicators[index][keyChildren]);
              }
            });
          }
        } else if (key == "bcFile") {
          if (this.inception[key] !== "null" || this.inception[key] !== null) {
            formData.append(key, this.inception[key]);
          }
        } else if (key == 'investmentOrder') {
          for (let investmentOrderIndex = 0; investmentOrderIndex < this.inception[key].length; investmentOrderIndex++) {
            formData.append("investmentOrder[" + investmentOrderIndex + "][id]", this.inception[key][investmentOrderIndex].id);

            let investmentOrder = null;
            if (this.inception[key][investmentOrderIndex].investmentOrder != '') {
              investmentOrder = this.inception[key][investmentOrderIndex].investmentOrder;
            }

            formData.append("investmentOrder[" + investmentOrderIndex + "][investmentOrder]", investmentOrder);
            formData.append("investmentOrder[" + investmentOrderIndex + "][active]", (this.inception[key][investmentOrderIndex].active) ? 1 : 0);
          }
        } else {
          formData.append(key, this.inception[key] === "" ? null : this.inception[key]);
        }
      });
      return formData;
    }
  },
  watch: {
    async id(value) {
      const id = Number(value);
      if (isNaN(id)) return;
      this.$showLoading();
      await this.loadOptions();
      await this.loadProject(id);
      this.$hideLoading();
    },
    currentStep(step) {
      if (step >= this.CONST_PROJECT_STEPS.INCEPTION) {
        this.loadInception();
      }
    },
    projectChanges(value) {
      this.changeProject = value;
      this.updateCanSave();
    },
    inceptionChanges(value) {
      this.changeInception = value;
      this.updateCanSave();
    },
    step(value) {
      this.$emit('updateBreadcrumb', value);
    },
  },
  methods: {
    canEditCanvas(resultPermission) {
      this.editBusinessCanvas = resultPermission;
    },
    closeModalError() {
      this.errorsModal = {
        show: false,
        project: false,
        discovery: {
          success: 0,
          error: 0,
          total: 0,
        },
        inception: false
      };
    },
    save(value) {
      this.onSubmit(value.redirect, value.subProjectId);
    },
    async loadProject(id) {
      if (!id) return;
      this.$showLoading();
      try {
        const { createPermission, permissionMigrate, validateTreeByProject, project } = await loadProject({ id });
        this.project = { ...this.project, ...project };
        this.$emit('statusWorkflowEncerramento', this.project.statusWorkflowEncerramento);
        this.createPermission = !createPermission;
        this.permissionMigrate = !permissionMigrate;
        this.validateTreeByProject = !validateTreeByProject;
        this.setImpacts();
      } catch (error) {
        this.$showError(error);
      } finally {
        this.cloneProject = clone(this.project);
        this.$hideLoading();
      }
    },
    async loadOptions() {
      try {
        const result = await loadOptions(this.id);
        this.options = { ...this.options, ...result };
        if (this.project.impacts.length > 0) this.setImpacts();
      } catch (error) {
        this.$showError(error);
      }
    },
    onDisabledInvestmentOrder(investmentOrderItem, action) {
      if (action == 'disabled') {
        if (investmentOrderItem.active == false) {
          if (!this.inceptionExcludesInvestmentOrder.includes(investmentOrderItem.id)) {
            this.inceptionExcludesInvestmentOrder.push(investmentOrderItem.id);
          }
        } else {
          if (this.inceptionExcludesInvestmentOrder.includes(investmentOrderItem.id)) {
            const itemIndex = this.inceptionExcludesInvestmentOrder.indexOf(investmentOrderItem.id);
            this.inceptionExcludesInvestmentOrder.splice(itemIndex, 1);
          }
        }
      } else {
        if (!this.inceptionExcludesInvestmentOrder.includes(investmentOrderItem.id)) {
          this.inceptionExcludesInvestmentOrder.push(investmentOrderItem.id);
        }
      }
    },
    async beforeSave() {
      if (this.inceptionExcludesInvestmentOrder.length > 0) {
        this.$showLoading();
        const inceptionExcludesInvestmentOrderData = {
          investmentOrder: this.inceptionExcludesInvestmentOrder
        };
        this.inceptionExcludesInvestmentEpicList = await checkInceptionExcludeInvestmentOrder(this.project.id, inceptionExcludesInvestmentOrderData);
        if (this.inceptionExcludesInvestmentEpicList.length == 0) {
          this.onSubmit();
        } else {
          this.inceptionExcludesInvestmentModel = true;
        }
        this.$hideLoading();
      } else {
        this.onSubmit();
      }
    },
    onCloseInceptionExcludeInvestmentOrder() {
      this.inceptionExcludesInvestmentModel = false;
    },
    async onSubmit(redirect = false, subProjectId = null) {
      if (typeof this.$refs.inceptionCollapses !== 'undefined') {
        this.$refs.inceptionCollapses.onSave();
      }
      const method = this.isEditing ? save : create;
      try {
        this.$showLoading();

        if (this.changeProject) {
          const {
            project: {openImpacts, score, id},
          } = await method(this.project);
          this.project.score = score;
          this.project.id = id;
          this.cloneProject = clone(this.project);

          if (openImpacts) {
            setTimeout(() => {
              this.autoOpenModalImpacts = openImpacts;
              this.step = this.CONST_PROJECT_STEPS.DISCOVERY;
            }, 1000);
          } else {
            this.autoOpenModalImpacts = false;
          }
        }
      } catch (error) {
        this.errorsModal.project = true;
        const { response: { data: { message } = {} } = {} } = error;
        if (typeof message !== "undefined") {
          this.$showError(message || "Verifique os Campos marcados!");
        }
        this.setErrorList(error);
      } finally {
        try {
          const hasNewSubproject = JSON.stringify(this.subprojects) !== JSON.stringify(this.originSubproject);
          if (this.subprojectsChanges.length > 0 || hasNewSubproject) {
            await this.saveAllSubprojects();
          }
        } catch (error) {
          console.error("Verifica o catch no salvamento do macro tema.", error)
        } finally {
          try {
            if (this.changeInception) {
              await this.editInception();
            }
            this.canSave = false;
          } finally {
            this.$hideLoading();
            if (!this.errorsModal.project && !this.errorsModal.inception && this.errorsModal.discovery.error === 0) {
              this.$showSuccess('Salvamento concluído');
              if (redirect) {
                setTimeout(() => {
                  this.$router.push(`/projeto/${this.$route.params.id}/epicos?frominception=${subProjectId}`);
                }, 1000);
              } else {
                if (this.isEditing) {
                  setTimeout(() => {
                    this.$router.push(`/projeto/${this.project.id}?step=${this.step}&openModalImpacts=${this.autoOpenModalImpacts}`); //direciona para o step atual
                    this.$router.go(); //atualiza a tela
                  }, 1000);
                } else {
                  setTimeout(() => {
                    this.$router.push(`/projeto/${this.project.id}?openModalImpacts=${this.autoOpenModalImpacts}`);
                  }, 1000);
                }
              }
            } else {
              this.errorsModal.show = true;
              if (this.errorMessage.length > 0) {
                this.$showError(this.errorMessage);
              }
              this.updateCanSave();
            }
          }
        }
      }
    },
    onCancel() {
      this.$router.push("/projetos");
    },
    createNewProject() {
      const newProject = {
        id: this.newSubprojectId,
        new: true,
        name: "",
        score: null,
        drivers: [],
        directions: "",
        statusId: null,
        editPermission: true,
        delivery: "",
        problems: "",
        macroScope: "",
        acceptanceCriteria: "",
        plataformModernization: false,
        products: [],
        systems: [],
        outScope: "",
        premises: "",
        restrictions: "",
      };
      this.subprojects.push(newProject);
      this.newSubprojectId++;
      this.$refs.projectList.openNewProject(newProject);
      this.updateCanSave();
    },
    removeNewProject(id) {
      this.subprojects.splice(id, 1);
      this.updateCanSave();
    },
    addId(id) {
      this.newSubprojectId = id;
    },
    updateOriginSubproject(id) {
      if (typeof id !== 'undefined') {
        const original = this.originSubproject.map((subprojectItem, index) => {
          if (subprojectItem.id === id) {
            return clone(this.subprojects[index]);
          }
          return subprojectItem;
        });
        this.originSubproject = original;
      } else {
        this.originSubproject = clone(this.subprojects);
      }
    },
    setImpacts() {
      this.$refs.strategicimpacts.setValues(this.project.impacts);
    },
    async saveAllSubprojects() {
      const listSubprojects = this.subprojects;
      const originSubproject = this.originSubproject;
      this.errorMessage = "";

      const subprojectsFilter = (subproject) => typeof subproject.new !== 'undefined' || this.subprojectsChanges.includes(subproject.id);
      const createOrUpdateSubproject = listSubprojects.filter(subprojectsFilter);

      const resultSaveSubprojects = await subprojectService.saveProjects(this.$route.params.id, createOrUpdateSubproject);

      resultSaveSubprojects.forEach((result) => {
        this.autoOpenModalImpacts = (typeof result.error === 'undefined') ? result.response.data.openImpacts : false;
        if (this.errorMessage.length === 0 && typeof result.error !== 'undefined' && typeof result.error.response.data.message !== 'undefined') {
          this.errorMessage = result.error.response.data.message;
        }
      });

      const responseCount = resultSaveSubprojects.reduce((accumulator, {error, response, id}) => {
        if (typeof error !== 'undefined') {
          accumulator.errorCount++;
          accumulator.errorList.push({id, error: error.response?.data});
          accumulator.errorStatuses.push(error.response.status);
        }
        if (typeof response !== 'undefined') {
          accumulator.successCount++;
          accumulator.successList.push({id, response: response.data.subproject});
        }
        return accumulator
      }, {successCount: 0, errorCount: 0, successList: [], errorList: [], errorStatuses: []});

      const {
        successCount,
        errorCount,
        errorList,
        successList,
        errorStatuses,
      } = responseCount;
      const errorIds = errorList.map(({id}) => id);
      const successIds = successList.map(({id}) => id);

      if (errorStatuses.includes(403)) {
        this.alert = {
          show: true,
          message: 'Usuário sem permissão de inserir Macro Temas',
          type: 'error',
          dismissible: false,
        }
        setTimeout(() => {
          this.alert.show = false;
        }, 3000);
      }

      if (errorCount > 0) {
        this.errorsModal.discovery.success = successCount;
        this.errorsModal.discovery.error = errorCount;
        this.errorsModal.discovery.total = successCount + errorCount;

        const findErrorBy = (id) => errorList.find((el) => el.id === id);
        const getMappedErrors = (arr = []) => Array(arr.length).fill()
            .map((_, index) => {
              const id = arr[index].id;
              return errorIds.includes(id) ? findErrorBy(id) : {}
            });
        this.errorsSubprojects = {
          subproject: getMappedErrors(listSubprojects),
        };
      }
      if (successCount > 0 && errorCount === 0) {

        this.alert = {
          show: true,
          message: 'Salvamento concluído',
          type: 'success',
          dismissible: false,
        }
        setTimeout(() => {
          this.alert.show = false;
        }, 3000);
      }

      const findNewSubprojectBy = (id) => successList.find((subproject) => subproject.id === id);
      this.subprojectsChanges = this.subprojectsChanges.filter((id) => !successIds.includes(id));
      if (successIds.length > 0) {
        this.subprojects = listSubprojects
            .map((subproject) => {
              if (!subproject.new || !successIds.includes(subproject.id)) return subproject;
              const data = clone(findNewSubprojectBy(subproject.id).response);
              return data;
            });
        this.originSubproject = originSubproject
            .map((subproject, index) => {
              if (!successIds.includes(subproject.id)) return subproject;
              const listSubproject = listSubprojects[index];
              const data = clone(findNewSubprojectBy(subproject.id).response || listSubproject);
              data.loaded = listSubproject.loaded;
              return data;
            });
      }
    },
    hasChange(change) {
      const {id, hasChange} = change;
      const index = this.subprojectsChanges.findIndex((index) => id === index);
      if (hasChange) {
        this.subprojectsChanges = Array.from(new Set([...this.subprojectsChanges, id]));
      } else {
        this.subprojectsChanges.splice(index, 1);
      }
      this.updateCanSave();
    },
    dateCanvas(date) {
      this.dateBusinessCanvas = date
    },
    async loadInception() {
      if (this.isEditing) {
        try {
          this.$showLoading();
          const inception = await InceptionService.loadInception(this.$route.params.id);
          this.inception = inception.inception;
          this.hasPreviusBcFile = inception.inception.bcFile === null ? false : true;
          if (!Array.isArray(this.inception.inceptionDrivers)) {
            let inceptionDrivers = [];
            Object.keys(this.inception.inceptionDrivers).forEach((key) => {
              for (let index = 0; index < this.inception.inceptionDrivers[key].length; index++) {
                inceptionDrivers.push(this.inception.inceptionDrivers[key][index])
              }
            });
            this.inception.inceptionDrivers = inceptionDrivers;
          }
          this.permissionsInception = inception.permissions;
          this.driversCategoryInception = inception.driversCategory;
          this.cloneInception = clone(this.inception);
        } catch(error) {
          this.$showError(error);
        } finally {
          this.$hideLoading();
        }
      }
    },
    async editInception() {
      try {
        this.$showLoading();
        this.inception.bcFlag = this.inception.bcFlag ? 1 : 0;
        this.inception.capex = this.inception.capex ? 1 : 0;
        this.inception.opex = this.inception.opex ? 1 : 0;
        await InceptionService.editInception(this.$route.params.id, this.formInception);
        this.cloneInception = clone(this.inception);
      } catch(error) {
         this.errorsModal.inception = true;
         this.cloneInception = clone(this.inception);
         this.setErrorListInception(error);
      } finally {
        this.$hideLoading();
      }
    },
    updateCanSave() {
      const hasNewSubproject = JSON.stringify(this.subprojects) !== JSON.stringify(this.originSubproject);
      if (this.changeProject || (this.subprojectsChanges.length > 0 || hasNewSubproject) || this.changeInception) {
        this.canSave = true;
      } else {
        this.canSave = false;
      }
    },
    async loadSteps() {
      if (this.isEditing) {
        try {
          const step = await getCurrentStep(this.id);
          this.currentStep = step.stepId;
          this.$emit('currentStep', step.stepId);
        } catch (error) {
          this.$showError(error);
        }
      }
    },
    atualStep() {
      if (typeof this.$router.currentRoute.query.step !== "undefined") {
        if (this.currentStep >= parseInt(this.$router.currentRoute.query.step)) {
          this.step = parseInt(this.$router.currentRoute.query.step);
        }
      }
    },
    setStepErrors(errors, step) {
      switch (step) {
        case this.CONST_PROJECT_STEPS.DISCOVERY:
        case this.CONST_PROJECT_STEPS.INCEPTION:
          this.errorList = errors;
          this.step = step - 1;
          break;
        case this.CONST_PROJECT_STEPS.BACKLOG:
        case this.CONST_PROJECT_STEPS.EM_PRENCHIMENTO:
          this.errorListInception = errors;
          this.step = this.CONST_PROJECT_STEPS.INCEPTION;
          break;
        default:
          console.error('Estapa destino inválida.');
          return;
      }
    },
  },
  async created() {
    if (!this.isEditing) await this.loadOptions();
    const { steps } = this.options;
    steps[0].active = true;
    this.project.steps = steps;
    await this.loadSteps();
    this.atualStep();
  },
  mounted() {
    this.autoOpenModalImpacts = false;
    if (typeof this.$route.query.openModalImpacts !== 'undefined' && this.$route.query.openModalImpacts === 'true') {
      this.autoOpenModalImpacts = true;
    }
  }
};
</script>

<style scoped>
.title-form {
  font: normal normal bold 18px/27px Poppins;
  color: #5d5d5d;
  margin-block: 24px;
}

.count-change-subproject {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: var(--text-primary);
}
.no-changes-subproject {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}
</style>

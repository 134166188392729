import { render, staticRenderFns } from "./ListDeliveryFormalization.vue?vue&type=template&id=393a5e91&"
import script from "./ListDeliveryFormalization.vue?vue&type=script&lang=js&"
export * from "./ListDeliveryFormalization.vue?vue&type=script&lang=js&"
import style0 from "./ListDeliveryFormalization.vue?vue&type=style&index=0&id=393a5e91&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VContainer,VDialog,VTextField})

<template>
  <v-dialog
    v-model="showDialog"
    :persistent="hasChanges"
    width="717">
    <v-card>
      <div class="modal-layout">
        <div class="modal-header">
          <h3>Mais detalhes</h3>
          <v-btn :disabled="hasChanges" icon @click="showDialog = false">
            <v-icon size="20px">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="modal-details grid-12">
          <div class="g-col-3 modal-detail">
            <p>Direcionador</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on">{{ data.driversCategoryName }}</p>
              </template>
              <span>{{ data.driversCategoryName }}</span>
            </v-tooltip>
          </div>
          <div class="g-col-3 modal-detail">
            <p>KPI</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on">{{ data.kpi }}</p>
              </template>
              <span>{{ data.kpi }}</span>
            </v-tooltip>
          </div>
          <div class="g-col-3 modal-detail">
            <p>Unidade de medida</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on">{{ data.unitMeasure }}</p>
              </template>
              <span>{{ data.unitMeasure }}</span>
            </v-tooltip>
          </div>
          <div class="g-col-5 modal-detail">
            <p>Fonte da informação (sistema)</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on">{{ data.sourceInformation }}</p>
              </template>
              <span>{{ data.sourceInformation }}</span>
            </v-tooltip>
          </div>
          <div class="g-col-5 modal-detail">
            <p>Responsável pela atualização</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on">{{ data.responsibleUpdating }}</p>
              </template>
              <span>{{ data.responsibleUpdating }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="modal-form">
          <div class="modal-form-header grid-10">
            <div class="g-col-5">Planejado</div>
            <div class="g-col-5">Realizado</div>
          </div>
          <div class="modal-form-frequency">
            Frequência: {{ data.projectIndicatorsFrequencyName }}
          </div>
          <div class="modal-form-body">
            <div class="modal-form-body-add-button">
              <v-btn
                plain
                large
                color="#5A6BFF"
                class="px-0"
                :disabled="canEditInception"
                @click="addFrequency()">
                + Adicionar
              </v-btn>
            </div>
            <div class="modal-form-body-period">
              <template v-if="data.projectIndicatorsFrequencyId === CONST_PROJECT_INDICATORS_FREQUENCY.MENSAL">
                <div v-for="(planning, i) in clonePlannings" class="grid-10 gap-xl row-gap-none" :key="i">
                  <div class="g-col-10" v-if="clonePlannings[i > 0 ? i - 1 : 0].year < planning.year || i === 0">
                    <h3>• {{ planning.year }}</h3>
                  </div>
                  <div class="g-col-5">
                    <label>{{ convertMonth(planning.month) }}/{{ planning.year }}</label>
                    <v-text-field 
                      v-model="planning.planned" 
                      :disabled="planning.disabledPlanned"
                      :error-messages="getError('plannings.'+i+'.planned')"
                      @input="clearErrorModalInception('plannings.'+i+'.planned')" />
                  </div>
                  <div class="g-col-5">
                    <label>{{ convertMonth(planning.month) }}/{{ planning.year }}</label>
                    <v-text-field 
                      v-model="planning.done" 
                      :disabled="planning.disabledDone"
                      :error-messages="getError('plannings.'+i+'.done')"
                      @input="clearErrorModalInception('plannings.'+i+'.done')" />
                  </div>
                </div>
              </template>
              <template v-else-if="data.projectIndicatorsFrequencyId === CONST_PROJECT_INDICATORS_FREQUENCY.TRIMESTRAL">
                <div v-for="(planning, i) in clonePlannings" class="grid-10 gap-xl row-gap-none" :key="i">
                  <div class="g-col-10" v-if="clonePlannings[i > 0 ? i - 1 : 0].year < planning.year || i === 0">
                    <h3>• {{ planning.year }}</h3>
                  </div>
                  <div class="g-col-5">
                    <label>{{ planning.quarter }}/{{ planning.year }}</label>
                    <v-text-field 
                      v-model="planning.planned" 
                      :disabled="planning.disabledPlanned"
                      :error-messages="getError('plannings.'+i+'.planned')"
                      @input="clearErrorModalInception('plannings.'+i+'.planned')" />
                  </div>
                  <div class="g-col-5">
                    <label>{{ planning.quarter }}/{{ planning.year }}</label>
                    <v-text-field 
                      v-model="planning.done" 
                      :disabled="planning.disabledDone"
                      :error-messages="getError('plannings.'+i+'.done')"
                      @input="clearErrorModalInception('plannings.'+i+'.done')" />
                  </div>
                </div>
              </template>
              <template v-else-if="data.projectIndicatorsFrequencyId === CONST_PROJECT_INDICATORS_FREQUENCY.ANUAL">
                <div v-for="(planning, i) in clonePlannings" class="grid-10 gap-xl row-gap-none" :key="i">
                  <div class="g-col-10">
                    <h3>• {{ planning.year }}</h3>
                  </div>
                  <div class="g-col-5">
                    <v-text-field 
                      v-model="planning.planned" 
                      :disabled="planning.disabledPlanned"
                      :error-messages="getError('plannings.'+i+'.planned')"
                      @input="clearErrorModalInception('plannings.'+i+'.planned')" />
                  </div>
                  <div class="g-col-5">
                    <v-text-field 
                      v-model="planning.done" 
                      :disabled="planning.disabledDone"
                      :error-messages="getError('plannings.'+i+'.done')"
                      @input="clearErrorModalInception('plannings.'+i+'.done')" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          text
          v-if="hasChanges"
          @click="showDialog = false">
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          large
          :disabled="!hasChanges"
          @click="editPlanning()">
          Salvar planejamento
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {clone} from "@/services/utils";
import ErrorMessageHandler from "../mixins/ErrorMessageHandler";
import inceptionServiceFactory from '../services/inceptionService.js';

const inceptionService = inceptionServiceFactory();

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    canEditIndicators: {
      type: Boolean,
      default: true
    },
    canEditInception: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => ({})
    },
    plannings: {
      type: Array,
      default: () => []
    },
  },
  mixins: [ErrorMessageHandler],
  watch: {
    dialog(value) {
      this.showDialog = value;
    },
    showDialog(value) {
      if (value === false) {
        this.$emit('closeModal', true);
      }
    },
    plannings() {
      this.clonePlannings = clone(this.plannings);
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.plannings) !== JSON.stringify(this.clonePlannings);
    }
  },
  data: () => ({
    showDialog: false,
    clonePlannings: [],
    errorList: {}
  }),
  methods: {
    addFrequency() {
      let date = new Date();
      if (this.data.projectIndicatorsFrequencyId === this.CONST_PROJECT_INDICATORS_FREQUENCY.MENSAL) {
         if (this.clonePlannings.length > 0) {
          let month = this.clonePlannings[this.clonePlannings.length - 1].month + 1 <= 12 ? this.clonePlannings[this.clonePlannings.length - 1].month + 1 : 1;
          this.clonePlannings.push({
            month: month,
            year: this.clonePlannings[this.clonePlannings.length - 1].month + 1 > 12 ? this.clonePlannings[this.clonePlannings.length - 1].year + 1 : this.clonePlannings[this.clonePlannings.length - 1].year,
            planned: null,
            done: null
          });
        } else {
          this.clonePlannings.push({
            month: date.getMonth() === 0 ? 12 : date.getMonth(),
            year: date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear(),
            planned: null,
            done: null
          });
        }
      } else if (this.data.projectIndicatorsFrequencyId === this.CONST_PROJECT_INDICATORS_FREQUENCY.TRIMESTRAL) {
        if (this.clonePlannings.length > 0) {
          let quarter = this.clonePlannings[this.clonePlannings.length - 1].quarter.split("Q");
          let sumQuarter = parseInt(quarter[1]) + 1
          this.clonePlannings.push({
            quarter: sumQuarter <= 4 ? "Q"+sumQuarter : "Q1",
            year: sumQuarter > 4 ? this.clonePlannings[this.clonePlannings.length - 1].year + 1 : this.clonePlannings[this.clonePlannings.length - 1].year,
            planned: null,
            done: null
          });
        } else {
          let month = date.getMonth() + 1;
          let quarter;
          let year = date.getFullYear();
          if (month >= 1 && month <= 3) {
            quarter = "Q4";
            year = date.getFullYear() - 1;
          } else if (month >= 4 && month <= 6) {
            quarter = "Q1";
          } else if (month >= 7 && month <= 9) {
            quarter = "Q2";
          } else if (month >= 10 && month <= 12) {
            quarter = "Q3";
          }
          this.clonePlannings.push({
            quarter: quarter,
            year: year,
            planned: null,
            done: null
          });
        }
      } else if (this.data.projectIndicatorsFrequencyId === this.CONST_PROJECT_INDICATORS_FREQUENCY.ANUAL) {
        if (this.clonePlannings.length > 0) {
          let year = this.clonePlannings[this.clonePlannings.length - 1].year + 1;
          this.clonePlannings.push({
            year: year,
            planned: null,
            done: null
          });
        } else {
          this.clonePlannings.push({
            year: date.getFullYear() - 1,
            planned: null,
            done: null
          });
        }
      }
    },
    async editPlanning() {
      try {
        this.$showLoading();
        await inceptionService.editInceptionPlannings(this.$route.params.id, this.data.id, { plannings: this.clonePlannings });
        this.$showSuccess('Planejamento salvo com sucesso!');
        this.showDialog = false
      } catch (error) {
        this.setErrorList(error);
      } finally {
        this.$hideLoading();
      }
    },
    convertMonth(month) {
      let months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
      return months[month - 1];
    }
  }
}
</script>

<style>

</style>
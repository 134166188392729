<template>
  <expand title="Impactos Estratégicos" v-model="expanded">
    <v-progress-linear
      v-if="items.length === 0"
      indeterminate
      color="primary"
      class="mb-4 mt-6"
    />
    <div v-for="type in impactTypes" :key="type.id">
      <div class="my-4 font-weight-bold">{{ type.name }}</div>
      <div class="grid-4 md:grid-2 sm:grid-1 gap-md mb-4">
        <height-score
          v-for="item in filterItems(type.id)"
          v-model="item.value"
          :key="item.id"
          :label="item.name"
          :options="type.values"
          :disabled="disabled"
          @input="updateImpacts"
        />
      </div>
    </div>
    <v-card outlined v-if="project.score">
      <v-list-item three-line>
        <v-list-item-content class="text-center">
          <v-list-item-title class="mb-1">
            Pontuação Iniciativa
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-h5 font-weight-bold"
            style="color: #6a5aff"
          >
            {{ project.score }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </expand>
</template>

<script>
import Expand from "@/components/common/VvExpand.vue";
import HeightScore from "@/components/common/VvHeightScore.vue";

export default {
  name: "StrategicImpacts",
  components: {
    Expand,
    HeightScore,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    impactTypes: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
  },
  data: () => ({
    expanded: true,
  }),
  computed: {
    project: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    impacts: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit("update:items", value);
      },
    },
  },
  methods: {
    filterItems(typeId) {
      return this.impacts.filter(
        ({ impactsTypeId }) => typeId === impactsTypeId
      );
    },
    updateImpacts() {
      this.project.impacts = this.impacts.map(({ id, value: valueId }) => ({
        id,
        valueId,
      }));
    },
    setValues(impacts) {
      const findImpact = (impactId) =>
        impacts.find(({ id }) => id === impactId);
      this.impacts = this.impacts.map((item) => {
        const newValue = findImpact(item.id)?.valueId;
        return {
          ...item,
          value: newValue ?? item.value,
        };
      });
    },
  },
};
</script>

export default {
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            squad: {
                squadKey: null,
                tribeId: null,
                newTribeId: null,
            },
            tribeCurrent: [],
            newTribe: []
        }
    },

    methods: {
        handleOptions(id) {
            this.newTribe = [];
            this.squad.newTribeId = null;

            this.options.squads.map(squad => {
                if (squad.id === id) {
                    squad.tribe.map(tribe => {
                        this.squad.tribeId = tribe.id
                        this.tribeCurrent = [tribe];

                        let filterTribe = this.options.tribes.filter(newTribe => newTribe.id !== tribe.id)
                        this.newTribe = filterTribe;
                    })
                }
            })
        },
        closeModal() {
            this.$emit("close");
        },
        clear() {
            this.squad = {
                squadKey: null,
                tribeId: null,
                newTribeId: null,
            }
        },
        save() {
            this.$emit("save", this.squad)
        }
    },

}
export const isObject = el => el?.constructor?.name === 'Object'
export const isArray = el => Array.isArray(el)

export const camelToSnakeCase = str => str
    .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const mapString = (str) => ({
    from: map => {
        const newString = map
            .find(item => isArray(item) && (item[0] === str || item[1] === str))
            ?.reduce((accumulator,current) => accumulator = current !== str ? current : accumulator)
        return newString || str
    }
})

export const map = (obj) => ({
    from: map => Object.fromEntries(Object.entries(obj)
        .map(el => {
            el[0] = mapString(el[0]).from(map)
            return el
        }
    ))
})

export const mapParams = (str) => ({
    from: map => str
    .split('&')
    .map(el => el.split('='))
    .map(([key,value]) => {
        if(isNaN(value)) {
            return `${key}=${mapString(value).from(map)}`
        }
        return `${key}=${value}`
    })
    .join('&')
})

export const flatten = (obj, parent, result = {}) => {
    for(let key in obj){
        const propName = parent ? `${parent}[${key}]` : key;
        if(isObject(obj[key]) || isArray(obj[key])){
            flatten(obj[key], propName, result);
        } else {
            result[propName] = obj[key];
        }
    }
    return Object.fromEntries(Object.entries(result)
        .filter(el => el[1] != null)
    );
}

export const getFormData = object => {
    const data = flatten(object);
    return Object.keys(data)
    .reduce((formData,key) => {
        formData.append(key, data[key])
        return formData;
    }, new FormData());
}

export const toParams = obj => {
    if(!obj) return ''
    return Object.entries(obj)
    .filter(([,value]) => !!value)
    .reduce((accumulator, [ key ,value ]) => {
        if(isArray(value)) {
            value.forEach(el => accumulator.push([`${key}[]`, el]))
        }
        else if(isObject(value)){
            Object.entries(flatten(value))
                .forEach(([elKey, elValue]) => accumulator.push([`${key}[${elKey}]`, elValue]))
        }
        else {
            accumulator.push([key, value])
        }
        return accumulator
    },[])
    .map((el,id) => id > 0 ? `&${el[0]}=${el[1]}`: `${el[0]}=${el[1]}`)
    .join('')
    .replace (/^/,'?')
}

export const getUrlParam = param => new URLSearchParams(`?${document.location.href.split('?')[1]}`).get(param);

export const clone = (el) => JSON.parse(JSON.stringify(el))
import VvBreadcrumbs from "@/components/common/VvBreadcrumbs";
import NotificationDialog from "../../components/NotificationDialog/NotificationDialog.vue";
import notificationServiceFactory from "../../services/notificationService";

const notificationService = notificationServiceFactory();

export default {
    components: {
        VvBreadcrumbs,
        NotificationDialog
    },
    data() {
        return {
            isLoading: false,
            breadcrumb: [
                {
                    text: 'Notificações',
                    disabled: true,
                },
                {
                    text: 'Todas as notificações',
                    disabled: false,
                }
            ],
            notifications: [],
            currentPage: null,
            serverItemsLength: null,
            headerProps: {
                sortByText: "Ordenar por",
            },
            footerProps: {
                'items-per-page-text': 'Linhas por página',
                'items-per-page-options': [5, 10, 15, 30, 50, 100],
            },
            options: {
                itemsPerPage: 5,
            },
            headers: [
                {
                    text: "Módulo",
                    value: "moduleName",
                },
                {
                    text: "Tipo",
                    value: "notificationTypeName",
                },
                {
                    text: "Notificação",
                    value: "message",
                },
                {
                    text: "Hora e data",
                    value: "createdAt",
                },
                { 
                    text: 'Opções', 
                    value: 'actions', 
                    sortable: false 
                },
            ],
            payload: {},
            notificationDialog: {
                show: false,
                data: {}
            },
            selected: [],
            listOnlyRead: true
        }
    },
    methods: {
        async loadNotifications(fetched = false) {
            this.$showLoading();
            try {
                if (!fetched) {
                    if (!this.listOnlyRead) {
                        this.payload['isRead[eq]'] = 0;
                    } else {
                        delete this.payload['isRead[eq]'];
                    }
                }
                const result = await notificationService.loadNotifications(this.payload);
                this.notifications = result.data;
                this.currentPage = result.current_page;
                this.serverItemsLength = result.total;
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        async fetchDatatable() {
            this.setPagination();
            this.loadNotifications(true);
        },
        setPagination(page) {
            this.payload['page'] = (page) ? page : this.options.page;
            this.payload['pageSize'] = this.options.itemsPerPage;
            this.payload['sortBy'] = this.options.sortBy[0];
            this.payload['sortDesc'] = 1;
      
            if (this.options.sortBy.length && this.options.sortDesc.length) {
                this.payload['sortDesc'] = this.options.sortDesc[0] ? 1 : 0;
            }
        },
        openNotification(data) {
            this.notificationDialog = {
                show: true,
                data: data
            }
            this.changeNotificationStatus(true, data.id)
        },
        pureIds(id = null) {
            let ids = [];

            if (id === null) {
                this.selected.map(selected => {
                    ids.push(selected.id);
                });
            } else {
                ids.push(id);
            }

            return ids;
        },
        async changeNotificationStatus(isRead, id = null) {
            let ids = this.pureIds(id);

            let payload = {
                status: isRead,
                notifications: ids
            };

            try {
                this.$showLoading();
                await notificationService.changeNotificationStatus(payload);
                this.loadNotifications();
                this.selected = [];
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
        async deleteNotifications(id = null) {
            let ids = this.pureIds(id);

            let payload = {
                notifications: ids
            };

            try {
                this.$showLoading();
                await notificationService.deleteNotifications(payload);
                this.loadNotifications();
                this.selected = [];
            } catch (error) {
                this.$showError(error);
            } finally {
                this.$hideLoading();
            }
        },
    },
    watch: {
        options: {
            handler() {
                this.fetchDatatable();
            },
            deep: true,
        },
        listOnlyRead() {
            this.loadNotifications();   
        }
    },
    mounted() {
        this.loadNotifications();
    }
}
import Vue from 'vue';
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        darkTheme: false,
        user: {
            authenticated: false,
            data: {},
            id: '',
            user_types_id: null,
            types: [],
            tree_types: [],
            delegations_types: []
        },
        userEmulated: {
            isEmuled: false,
            user: {}
        },
        reservedUsersToEmulation: [],
        filters: [],
        epics: {},
        canvas: {}, //TODO: remover no card CVV-2052
        searchDoc: [],
    },
    mutations: {
        startUserEmulation(state, user) {
            state.userEmulated.isEmuled = true;
            state.userEmulated.user = user;
        },
        stopUserEmulation(state) {
            state.userEmulated.isEmuled = false;
            state.userEmulated.user = {};
        },
        addUserToReservedList(state, user) {
            state.reservedUsersToEmulation.push(user);
        },
        clearReservedUsers(state) {
            state.reservedUsersToEmulation = [];
        },
        id(state, data){
            state.user.id = data;
        },
        type(state, data){
            state.user.user_types_id = data;
        },
        types(state, data){
            state.user.types = data;
        },
        tree_types(state, data){
            state.user.tree_types = data;
        },
        delegations_types(state, data){
            state.user.delegations_types = data;
        },
        login(state, userData) {
            state.user.authenticated = true;
            state.user.data = userData;
            window.localStorage.state = state;
        },
        logoff(state) {
            state.user.authenticated = false;
            state.user.data = '';
            window.localStorage.state = state;
        },
        setFilterFields(state, filterData) {
            state.filters.forEach(function(filter, key) {
                if (filter.key === filterData.filterName) {
                    state.filters.splice(key, 1);
                    return;
                }
            });

            let date = Date.now();

            let filterPayload = {
                'key': filterData.filterName,
                'fields': filterData.fields,
                'date': date
            };

            state.filters.push(filterPayload)
        },
        setEpics(state, data) {
            state.epics = data;
        },
        setCanvas(state, data) {    //TODO: remover no card CVV-2052
            state.canvas = data;
        },
        darkTheme(state, value) {
            state.darkTheme = value;
        },
        setSearchDocs(state, keywords) {
            state.searchDoc = [];
            const date = Date.now();
            const searchPayload = {
                'key': keywords.searchName,
                'keywords': keywords.keywords,
                'date': date,
            };
            state.searchDoc.push(searchPayload);
        },
        clearCacheSearch(state) {
            state.searchDoc = [];
        },
    },
    getters: {
        isEmulated: state => {
            return state.userEmulated.isEmuled;
        },
        userEmuled: state => {
            return state.userEmulated.user;
        },
        reservedUserList: state => {
            return state.reservedUsersToEmulation;
        },
        userOriginal: state => {
            return state.user.data;
        },
        id: state => {
            if (!state.userEmulated.isEmuled) {
                return state.user.id;
            }
            return state.userEmulated.user.id;
        },
        type: state => {
            if (!state.userEmulated.isEmuled) {
                return state.user.user_types_id;
            }
            return state.userEmulated.user.types;
        },
        types: state => {
            return state.user.types;
        },
        tree_types: state => {
            return state.user.tree_types;
        },
        delegations_types: state => {
            return state.user.delegations_types;
        },
        login: state => {
            return state.user.authenticated;
        },
        userAuth: state => {
            if (!state.userEmulated.isEmuled) {
                return state.user.data;
            }
            return state.userEmulated;
        },
        userPhoto: state => {
            try {
                if (!state.userEmulated.isEmuled) {
                    return URL.createObjectURL(state.user.data.graph.photo);
                }
                return 'https://i.pinimg.com/originals/0c/3b/3a/0c3b3adb1a7530892e55ef36d3be6cb8.png';
            } catch (e) {
                return 'https://i.pinimg.com/originals/0c/3b/3a/0c3b3adb1a7530892e55ef36d3be6cb8.png';
            }
        },
        userToken: state => {
            return state.user.data.accessToken;
        },
        userAdmin: state => {
            return state.user.data.isAdmin;
        },
        getFilterFields: state => {
            return state.filters;
        },
        epics: state => {
            return state.epics;
        },
        getCanvas: state => {   //TODO: remover no card CVV-2052
            return state.canvas;
        },
        darkTheme: state => {
            return state.darkTheme;
        },
        getSearchDocs: state => {
            return state.searchDoc;
        },
    },
    plugins: [createPersistedState()],
});
import axios from "@/plugins/axios";

export default function quartersServiceFactory() {
  return {
    loadAllQuarters() {
      return axios
          .get(`v2/quarter`)
          .then(res => res.data)
          .catch(err => err.response);
    },
    saveQuarters(quarterObj) {
      return axios
          .put(`v2/quarter`, quarterObj)
          .then(res => res)
          .catch(err => err.response);
    },
    createNewQuarters(newYears) {
      return axios
          .post(`v2/quarter`, newYears)
          .then(res => res)
          .catch(err => err.response);
    }
  };
}
